import {
  GridCellProps,
  GridColumn,
  GridHeaderCellProps,
} from '@progress/kendo-react-grid';
import { Tooltip } from '@progress/kendo-react-tooltip';
import { PageSectionHeading } from 'Components/Display';
import { AddButton } from 'Components/Form';
import {
  DateCell,
  EditDeleteCell,
  FilterBarMainGrid,
  GridButtonSection,
} from 'Components/Grid';
import { SecureComponent } from 'Components/Security';
import { FormProvider, GridProvider } from 'Context';
import {
  defaultGridProps,
  FilterBarFilterType,
  FilterConfig,
  IFilterConfig,
  StaticFilterConfig,
} from 'Models';
import { Permission } from 'Models/Templates/Permission/Permission';
import { ENDPOINTS } from 'Models/Templates/Routes/Routes';
import { addEditRoute, AddEditSubRoute, ROUTES } from 'Navigation';
import React, { HTMLProps } from 'react';
import { buildRoute, EMPTY_GUID, useGridSearch } from 'Utilities';

class NoteSearch {
  id?: string;
  searchKey?: string;
  referralId?: string;
}

export enum NotesGridIds {
  DetailedSearch = 'detailedSearch',
}

export const Notes: React.FC<NotesFilteredGridProps> = ({ id, ...props }) => {
  return (
    <GridProvider entityName="Note">
      <PageSectionHeading>Call Notes</PageSectionHeading>
      <NotesFilteredGrid id={id} />
    </GridProvider>
  );
};

export type NotesFilteredGridProps = HTMLProps<NotesFilteredGridProps> & {
  id?: string;
};

export const NotesFilteredGrid: React.FC<NotesFilteredGridProps> = ({
  id,
  ...props
}) => {
  const noteSearch = new NoteSearch();
  noteSearch.referralId = id;
  return (
    <FormProvider formModel={noteSearch}>
      <NotesGrid id={id} />
    </FormProvider>
  );
};

export const NotesGrid: React.FC<NotesFilteredGridProps> = ({
  id,
  ...props
}) => {
  const filters: IFilterConfig[] = [
    new FilterConfig(
      'searchKey',
      FilterBarFilterType.SearchText,
      'Title, Contact'
    ),
    new StaticFilterConfig('referralId'),
  ];
  const [gridToggler] = useGridSearch();

  let endpoint = ENDPOINTS.notes.read;

  return (
    <React.Fragment>
      <SecureComponent permissions={[Permission.AddNote]}>
        <GridButtonSection>
          <AddButton
            url={buildRoute(
              ROUTES.ADD_EDIT,
              AddEditSubRoute.Notes,
              EMPTY_GUID,
              id
            )}
          >
            New Call Note
          </AddButton>
        </GridButtonSection>
      </SecureComponent>
      <Tooltip openDelay={100} position="bottom" anchorElement="target">
        <FilterBarMainGrid
          isMainGrid
          filterBarProps={{
            filterConfigurations: filters,
            filterValues: { referralId: gridToggler.referralId },
          }}
          refreshToggle={gridToggler}
          readEndpoint={endpoint}
          {...defaultGridProps}
        >
          <GridColumn
            field="noteDate"
            title="Note Date"
            cell={dotNetDateColumn}
          />
          <GridColumn field="title" title="Title" />
          <GridColumn field="contactName" title="Contact" />
          <GridColumn
            field="description"
            title="Description"
            cell={descriptionCell}
            headerCell={descriptionHeader}
          />
          <GridColumn
            field="contactDate"
            title="Contact Date"
            cell={dotNetDateColumn}
          />
          <GridColumn
            field="id"
            width="120"
            title="Actions"
            cell={ActionsCell}
          />
        </FilterBarMainGrid>
      </Tooltip>
    </React.Fragment>
  );
};

const dotNetDateColumn: React.FC<GridCellProps> = (props: GridCellProps) => {
  return <DateCell {...props} dateFormat={'MM/DD/YYYY'} />;
};

const ActionsCell: React.FC<GridCellProps> = (props: GridCellProps) => {
  return (
    <EditDeleteCell
      editRoute={addEditRoute(AddEditSubRoute.Notes)}
      editPermissions={[Permission.EditNote]}
      deleteEndpoint={ENDPOINTS.notes.base}
      deletePermissions={[Permission.DeleteNote]}
      {...props}
    />
  );
};

const descriptionCell: React.FC<GridCellProps> = (props: GridCellProps) => {
  return (
    <td title={props.dataItem.description}>{props.dataItem.description}</td>
  );
};
const descriptionHeader: React.FC<GridHeaderCellProps> = (
  props: GridHeaderCellProps
) => {
  return (
    <a className="k-link">
      <span title={props.title}>{props.title}</span>
    </a>
  );
};
