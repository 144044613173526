import { Referral } from 'Models';

export class Appointment {
  id?: string;
  referral?: Referral;
  writer?: string;
  professional?: string;
  appointmentDate?: string;
  appointmentStatus?: string;
}

export class ColumnSelection {
  constructor(
    label: string,
    field: string,
    checked: boolean,
    column: number,
    index: number
  ) {
    this.label = label;
    this.field = field;
    this.checked = checked;
    this.columnNumber = column;
    this.index = index;
  }
  label: string;
  field: string;
  checked: boolean;
  columnNumber: number;
  index: number;
}

export const DefaultColumnsSelection = [
  new ColumnSelection('Client', 'clientName', true, 1, 0),
  new ColumnSelection('Referral Date', 'referralDate', true, 2, 1),
  new ColumnSelection('Report Due Date', 'reportDueDate', false, 3, 2),
  new ColumnSelection('Client Due Date', 'referralDueDate', true, 4, 3),
  new ColumnSelection('Claimant Name', 'claimantName', true, 5, 4),
  new ColumnSelection('Claim Number', 'claimNumber', true, 6, 5),
  new ColumnSelection('Benefit State', 'benefitState', true, 7, 6),
  new ColumnSelection('Referral Number', 'referralNumber', true, 1, 7),
  new ColumnSelection('Status', 'status', true, 2, 8),
  new ColumnSelection('Type of EVAL', 'typeOfEval', true, 3, 9),
  new ColumnSelection('Appointment Date', 'appointmentDate', false, 4, 10),
  new ColumnSelection('Treating Physician', 'treatingPhysician', false, 5, 11),
  new ColumnSelection('Physician Specialty', 'physicianSpecialty', false, 6, 12),
  new ColumnSelection('Insurance Carrier', 'insuranceCarrier', false, 7, 13),
  new ColumnSelection('Referral Source', 'referralSource', false, 1, 14),
  new ColumnSelection('Adjuster Name', 'adjusterName', false, 2, 15),
  new ColumnSelection('Doctor', 'doctor', false, 3, 16),
  new ColumnSelection('Date Closed', 'closedDate', false, 4, 17),
  new ColumnSelection('Check Medicals Date', 'checkMedicalsDate', false, 5, 18),
  new ColumnSelection('Stradix Number', 'stradixNumber', true, 6, 19),
];

export const DefaultColumnsSelectionClosedReferrals = [
  new ColumnSelection('Client', 'clientName', true, 1, 0),
  new ColumnSelection('Referral Date', 'referralDate', true, 2, 1),
  new ColumnSelection('Report Due Date', 'reportDueDate', false, 3, 2),
  new ColumnSelection('Client Due Date', 'referralDueDate', false, 4, 3),
  new ColumnSelection('Claimant Name', 'claimantName', true, 5, 4),
  new ColumnSelection('Claim Number', 'claimNumber', true, 6, 5),
  new ColumnSelection('Benefit State', 'benefitState', true, 7, 6),
  new ColumnSelection('Referral Number', 'referralNumber', true, 1, 7),
  new ColumnSelection('Status', 'status', true, 2, 8),
  new ColumnSelection('Type of EVAL', 'typeOfEval', true, 3, 9),
  new ColumnSelection('Appointment Date', 'appointmentDate', false, 4, 10),
  new ColumnSelection('Treating Physician', 'treatingPhysician', false, 5, 11),
  new ColumnSelection('Physician Specialty', 'physicianSpecialty', false, 6, 12),
  new ColumnSelection('Insurance Carrier', 'insuranceCarrier', false, 7, 13),
  new ColumnSelection('Referral Source', 'referralSource', false, 1, 14),
  new ColumnSelection('Adjuster Name', 'adjusterName', false, 2, 15),
  new ColumnSelection('Doctor', 'doctor', false, 3, 16),
  new ColumnSelection('Date Closed', 'closedDate', true, 4, 17),
  new ColumnSelection('Check Medicals Date', 'checkMedicalsDate', false, 5, 18),
  new ColumnSelection('Stradix Number', 'stradixNumber', true, 6, 19),
];

export const DefaultColumnsSelectionClosedOpenReferralsDoctor = [
  new ColumnSelection('Client', 'clientName', true, 1, 0),
  new ColumnSelection('Referral Date', 'referralDate', true, 2, 1),
  new ColumnSelection('Report Due Date', 'reportDueDate', true, 3, 2),
  new ColumnSelection('Client Due Date', 'referralDueDate', false, 4, 3),
  new ColumnSelection('Claimant Name', 'claimantName', true, 5, 4),
  new ColumnSelection('Claim Number', 'claimNumber', true, 6, 5),
  new ColumnSelection('Benefit State', 'benefitState', true, 7, 6),
  new ColumnSelection('Referral Number', 'referralNumber', true, 1, 7),
  new ColumnSelection('Status', 'status', true, 2, 8),
  new ColumnSelection('Type of EVAL', 'typeOfEval', true, 3, 9),
  new ColumnSelection('Appointment Date', 'appointmentDate', false, 4, 10),
  new ColumnSelection('Treating Physician', 'treatingPhysician', false, 5, 11),
  new ColumnSelection('Physician Specialty', 'physicianSpecialty', false, 6, 12),
  new ColumnSelection('Insurance Carrier', 'insuranceCarrier', false, 7, 13),
  new ColumnSelection('Referral Source', 'referralSource', false, 1, 14),
  new ColumnSelection('Adjuster Name', 'adjusterName', false, 2, 15),
  new ColumnSelection('Doctor', 'doctor', false, 3, 16),
  new ColumnSelection('Date Closed', 'closedDate', true, 4, 17),
  new ColumnSelection('Check Medicals Date', 'checkMedicalsDate', false, 5, 18),
  new ColumnSelection('Stradix Number', 'stradixNumber', true, 6, 19),
];