import { useMsal } from '@azure/msal-react';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
//icons
import SettingsIcon from '@mui/icons-material/Settings';
import { NavigationPillBar } from 'Components/Navigation';
import { MultiModalProvider, useGlobalState } from 'Context';
import { NotificationModel } from 'Models';
import { ENDPOINTS } from 'Models/Templates/Routes/Routes';
import {
  MAIN_NAV_CONFIG,
  NavConfigItem,
  ROUTES,
  SETTINGS_NAV_CONFIG,
  useNavigation,
} from 'Navigation';
import React, { Fragment } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { Link, useHistory } from 'react-router-dom';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';
import { buildRoute, HOTKEYS, useApiWorker, useToggler } from 'Utilities';
import logo from '../../Assets/Logo.svg';
import './Header.scss';

export enum HeaderModal {
  NotificationsDrawer = 'NotificationsDrawer',
}

type HeaderProps = {
  [x: string]: any;
};

export const Header: React.FC<HeaderProps> = () => {
  const { environmentName } = useGlobalState();
  let history = useHistory();

  const redirectToDetailedSearch = () => {
    history.push(buildRoute(ROUTES.DETAILED_SEARCH));
  };

  useHotkeys(HOTKEYS.DETAILED_SEARCH, redirectToDetailedSearch);

  return (
    <div className="header__content flex-vertical-center full-size flex-normal">
      <div className="color-success font-secondary site-logo-container">
        <img src={logo} alt="Stradix" className="site-logo" />
      </div>
      <NavigationPillBar pills={MAIN_NAV_CONFIG} />
      <HeaderToolbar />
    </div>
  );
};

const HeaderToolbar: React.FC = () => {
  return (
    <MultiModalProvider>
      <div className="ml-auto color-light flex-normal flex-vertical-center toolbar-buttons">
        <HeaderDropdowns />
      </div>
    </MultiModalProvider>
  );
};

const HeaderDropdowns: React.FC = () => {
  return (
    <Fragment>
      {/* <NotificationsButton /> */}
      <SettingsDropdown />
      <AccountDropdown />
    </Fragment>
  );
};

export type NotificationsLocalState = {
  notifications: NotificationModel[];
  page: number;
  skip: number;
};

const SettingsDropdown: React.FC = () => {
  const [settingsIsOpen, toggleSettingsMenu] = useToggler(false);
  const { navConfig } = useNavigation(SETTINGS_NAV_CONFIG);

  return (
    <Dropdown isOpen={settingsIsOpen} toggle={toggleSettingsMenu}>
      <DropdownToggle className="bg-transparent border-none main-menu-item">
        <SettingsIcon />
      </DropdownToggle>
      <DropdownMenu
        className="animate-fade-in card-shadow color-secondary"
        right
      >
        <DropdownItem header className="color-secondary">
          Admin
        </DropdownItem>
        {navConfig.map((pill: NavConfigItem, index: any) => (
          <Link to={pill.fullRoute || '/'} key={index}>
            <DropdownItem className="flex-vertical-center">
              <div className="dropdown-icon">{pill.icon}</div>
              {pill.label}
            </DropdownItem>
          </Link>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
};

const AccountDropdown: React.FC = () => {
  const { currentUser } = useGlobalState();
  const [isDropdownOpen, toggleDropdown] = useToggler();
  const API = useApiWorker();
  const editProfileLink = '/AddEdit/User/' + currentUser?.id;
  const { instance } = useMsal();

  const userLogOut = async () => {
    await API.get(ENDPOINTS.users.endpointString('logout')); 
    sessionStorage.clear();
    await instance.logoutRedirect();
  };

  return (
    <Fragment>
      <Dropdown
        isOpen={isDropdownOpen}
        className="mr-small"
        toggle={toggleDropdown}
      >
        <DropdownToggle className="bg-transparent border-none main-menu-item">
          <AccountCircleIcon />
        </DropdownToggle>
        <DropdownMenu className="animate-fade-in card-shadow" right>
          <DropdownItem header>
            <span color="secondary">
              {currentUser?.firstName} {currentUser?.lastName}
            </span>
          </DropdownItem>
          <Link to={editProfileLink}>
            <DropdownItem className="flex-vertical-center">
              <AccountCircleIcon className="mr-small color-primary" />
              Edit Profile
            </DropdownItem>
          </Link>
          <DropdownItem className="flex-vertical-center" onClick={userLogOut}>
            <ExitToAppIcon className="mr-small color-primary" />
            Log Out
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
      {/* <span className="color-primary mr-small">
        Hello, {currentUser?.firstName}
      </span> */}
    </Fragment>
  );
};
