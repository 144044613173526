import './LoadingSection.scss';
import React, { Fragment } from 'react';
import { CircularProgress } from '@mui/material';
import {
  fullClassName,
  getPositionClass,
  getColorClass,
  toggledClass,
} from 'Utilities';
import { AsyncStatus, Position, ColorTheme } from 'Models/SharedModels';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Render } from './Render';
import { ButtonStyle, IconButton } from 'Components/Form';
import RefreshIcon from '@mui/icons-material/Refresh';

type LoadingSectionProps = LoadingIndicatorProps & {
  isLoading: boolean;
  wrapperClass?: string;
  theme?: ColorTheme;
  indicatorPosition?: Position;
};

export const LoadingSection: React.FC<LoadingSectionProps> = ({
  isLoading,
  wrapperClass,
  children,
  ...indicatorProps
}) => {
  return (
    <Fragment>
      {isLoading && (
        <div
          className={fullClassName(
            'animate-slide-down position-relative',
            wrapperClass || ''
          )}
        >
          <div className="full-size flex-center loading-wrapper animate-fade-in">
            <LoadingIndicator {...indicatorProps} />
          </div>
        </div>
      )}
      {!isLoading && children}
    </Fragment>
  );
};

export const LoadingMaskSection: React.FC<LoadingSectionProps> = ({
  isLoading,
  wrapperClass,
  children,
  indicatorPosition,
  ...indicatorProps
}) => {
  const positionClass = getPositionClass(indicatorPosition);

  return isLoading ? (
    <div className={fullClassName('position-relative full-size', wrapperClass)}>
      <div
        className={fullClassName(
          'position-absolute full-size loading-mask',
          positionClass
        )}
      >
        <LoadingIndicator {...indicatorProps} />
      </div>
      {children}
    </div>
  ) : (
    <Fragment>{children}</Fragment>
  );
};

export function getGroupLoadingStatus(statuses: AsyncStatus[]) {
  if (statuses.some((x) => x === AsyncStatus.Pending)) {
    return AsyncStatus.Pending;
  } else if (
    statuses.every((x) => x === AsyncStatus.Success || x === AsyncStatus.Error)
  ) {
    return AsyncStatus.Success;
  } else {
    return AsyncStatus.Idle;
  }
}

export type AsyncStatusesLoadingSectionProps =
  Partial<AsyncLoadingSectionProps> & {
    loadingStatuses: AsyncStatus[];
  };

export const AsyncStatusesLoadingSection: React.FC<AsyncStatusesLoadingSectionProps> =
  ({ children, loadingStatuses, ...loadingSectionProps }) => {
    const status = getGroupLoadingStatus(loadingStatuses);

    return (
      <AsyncLoadingSection
        loadingStatus={status}
        useMask
        noIdleLoading
        noLabel
        {...loadingSectionProps}
      >
        {children}
      </AsyncLoadingSection>
    );
  };

export type AsyncLoadingSectionProps = LoadingIndicatorProps & {
  loadingStatus: AsyncStatus;
  indicatorPosition?: Position;
  theme?: ColorTheme;
  onRefresh?: () => void;
  fullView?: boolean;
  noIdleLoading?: boolean;
  useMask?: boolean;
  wrapperClass?: string;
  noErrorDisplay?: boolean;
  smallIndicator?: boolean;
};

export const AsyncLoadingSection: React.FC<AsyncLoadingSectionProps> = ({
  children,
  loadingStatus,
  indicatorPosition,
  theme,
  onRefresh,
  fullView,
  noIdleLoading,
  useMask,
  wrapperClass,
  noErrorDisplay,
  smallIndicator,
  indicatorSize = smallIndicator ? 20 : 40,
  ...loadingIndicatorProps
}) => {
  const positionClass = getPositionClass(indicatorPosition);
  const colorClass = getColorClass(theme);
  const buttonColorClass =
    theme === ColorTheme.Dark ? 'color-primary-light' : 'color-primary';
  const errorIconClass = theme === ColorTheme.Light ? 'color-danger' : '';
  const allowRefresh = onRefresh !== undefined;

  if (noIdleLoading && loadingStatus === AsyncStatus.Idle) {
    return <Fragment>{children}</Fragment>;
  }

  switch (loadingStatus) {
    case AsyncStatus.Idle:
    case AsyncStatus.Pending:
      return useMask ? (
        <LoadingMaskSection
          isLoading={true}
          wrapperClass={wrapperClass}
          theme={theme}
          indicatorPosition={indicatorPosition}
          indicatorSize={indicatorSize}
          {...loadingIndicatorProps}
        >
          {children}
        </LoadingMaskSection>
      ) : (
        <div
          className={fullClassName(
            'animate-fade-in',
            positionClass,
            colorClass,
            toggledClass('full-size', !fullView),
            toggledClass('full-view-size', fullView),
            wrapperClass
          )}
        >
          <LoadingIndicator
            theme={theme}
            indicatorSize={indicatorSize}
            {...loadingIndicatorProps}
          />
        </div>
      );
    case AsyncStatus.Error:
      return noErrorDisplay ? (
        <Fragment>{children}</Fragment>
      ) : (
        <div
          className={fullClassName(
            'animate-fade-in',
            positionClass,
            colorClass,
            toggledClass('full-size', !fullView),
            toggledClass('full-view-size', fullView)
          )}
        >
          <div className="flex-column flex-vertical-center">
            <div className="flex-center">
              <ErrorOutlineIcon
                className={fullClassName('mr-2', errorIconClass)}
              />
              <span>Error Loading Data</span>
            </div>
            <Render condition={allowRefresh}>
              <IconButton
                iconElement={RefreshIcon}
                buttonStyle={ButtonStyle.Bare}
                onClick={onRefresh}
                className={fullClassName('mt-2', buttonColorClass)}
              >
                Try Again
              </IconButton>
            </Render>
          </div>
        </div>
      );
    default:
      return <Fragment>{children}</Fragment>;
  }
};

export type LoadingIndicatorProps = {
  loadingText?: string;
  noLabel?: boolean;
  theme?: ColorTheme;
  indicatorSize?: number | string;
  noPaddingMargin?: boolean;
};

export const LoadingIndicator: React.FC<LoadingIndicatorProps> = ({
  loadingText,
  noLabel,
  theme = ColorTheme.Light,
  indicatorSize,
  noPaddingMargin,
}) => {
  const label = loadingText || 'Loading...';
  const colorClass =
    theme === ColorTheme.Dark ? 'color-light' : 'color-primary';

  return (
    <div className="flex-center loading-indicator">
      <CircularProgress
        size={indicatorSize}
        className={fullClassName(
          toggledClass(
            indicatorSize && indicatorSize < 20 ? 'mr-tiny' : 'mr-3',
            !noPaddingMargin
          ),
          colorClass
        )}
      />
      <Render condition={!noLabel || loadingText === ''}>
        <span>{label}</span>
      </Render>
    </div>
  );
};
