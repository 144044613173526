import { InlineGridItem } from './GridModels';

export class Role {
  constructor(name?: string, permissions?: any, id = '') {
    this.name = name;
    this.permissionsList = permissions;
    this.id = id;
  }
  permissionsList?: any;
  name?: string;
  isNurse?: boolean;
  isWriter?: boolean;
  isDoctor?: boolean;
  isMedicalDirector?: boolean;
  isReviewer?: boolean;
  id?: string;
}

export class SignatureModel {
  signature?: string;
  userId?: string;
}

export class UserItem extends InlineGridItem {
  name?: string;
  id?: string;
}
