import { AdminSubHeaderMainLayout } from 'Components/Display';
import { useEntity } from 'Context';
import { ROUTES } from 'Navigation';
import React, { Fragment } from 'react';
import { ViewReferralsRequestBreadcrumbs } from '.';

export const ReferralsRequestMain: React.FC = () => {
  const entity = useEntity();

  return (
    <Fragment>
      <ViewReferralsRequestBreadcrumbs />
      <AdminSubHeaderMainLayout
        subRoute={ROUTES.REFERRALSREQUEST}
        title={(entity?.stradixNumber ? `#${entity?.stradixNumber} - ` : '') + entity.name + ' - Referral Request'}
      />
    </Fragment>
  );
};
