import "./NavigationItem.scss";
import React from "react";
import { fullClassName, toggledClass } from "Utilities";
import { NavConfigItem, ROUTES } from "Navigation";
import { Render } from "Components/Display";
import { Link, useRouteMatch } from "react-router-dom";

export type NavigationItemProps = NavConfigItem &
  React.HTMLProps<HTMLDivElement> & {
    index: number;
    withNumber?: boolean;
  };

export const NavigationItem: React.FC<NavigationItemProps> = ({
  label,
  active,
  icon,
  fullRoute,
  index,
  withNumber,
}) => {
  const { url } = useRouteMatch();

  return label && label !== "" ? (
    <Link
      className={
        "navbar__item animate-slide-in" + toggledClass("active", active)
      }
      to={fullRoute ? `${url}${fullRoute}` : ROUTES.UNAUTHORIZED}
    >
      <div
        className={fullClassName(
          "nav-label-container flex-vertical-center header-size-tiny"
        )}
      >
        <Render condition={false}>
          <div className="navbar__item--icon">{icon}</div>
        </Render>
        {label}
      </div>
      <Render condition={withNumber}>
        <div className="navigation-index">{index}</div>
      </Render>
    </Link>
  ) : null;
};
