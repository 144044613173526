import {
  GridCellProps,
  GridColumn as Column,
} from '@progress/kendo-react-grid';
import {
  AdminSubHeaderMainLayout,
  CollapsibleAuditLog,
} from 'Components/Display';
import { EnumerationCell, InlineGrid as Grid } from 'Components/Grid';
import { GridProvider, useEnums } from 'Context';
import { defaultGridProps } from 'Models/GridModels';
import { Permission } from 'Models/Templates/Permission/Permission';
import { ENDPOINTS } from 'Models/Templates/Routes/Routes';
import React from 'react';

export const EvaluationTypes: React.FC = () => {
  const { entityEventType } = useEnums();
  return (
    <AdminSubHeaderMainLayout title="Evaluation Types">
      <GridProvider entityName="Evaluation Type">
        <Grid
          updateEndpoint={ENDPOINTS.evaltypes.update}
          updatePermission={Permission.EditEvalTypes}
          createEndpoint={ENDPOINTS.evaltypes.create}
          createPermission={Permission.AddEvalTypes}
          deleteEndpoint={ENDPOINTS.evaltypes.destroy}
          deletePermission={Permission.DeleteEvalTypes}
          readEndpoint={ENDPOINTS.evaltypes.read}
          sortable={true}
          {...defaultGridProps}
        >
          <Column field="title" title="Title" />
          <Column
            field="evaluationType"
            title="Type"
            cell={EvaluationTypeDropDownCell}
          />
        </Grid>
      </GridProvider>
      <CollapsibleAuditLog
        title="Audit Log"
        admin
        entityEventType={entityEventType.EvalType?.value}
      />
    </AdminSubHeaderMainLayout>
  );
};

const EvaluationTypeDropDownCell: React.FC<GridCellProps> = (
  props: GridCellProps
) => {
  const { evaluationType } = useEnums();

  return <EnumerationCell coreEnum={evaluationType} {...props} />;
};
