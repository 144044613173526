import { AdminSubHeaderMainEditLayout } from '../../Components/Display';
import { useEntity } from '../../Context';
import { addEditRoute, AddEditSubRoute, ROUTES } from '../../Navigation';
import React from 'react';
import { ViewClaimBreadcrumbs } from '.';
import { Permission } from 'Models/Templates/Permission/Permission';

export const ClaimMain: React.FC = () => {
  const entity = useEntity();

  return (
    <React.Fragment>
      <ViewClaimBreadcrumbs />
      <AdminSubHeaderMainEditLayout
        subRoute={ROUTES.CLAIM}
        title={entity.claimNumber + ' - Claim'}
        route={addEditRoute(AddEditSubRoute.Claims, entity.entityId)}
        editPermissions={[Permission.EditClaim]}
      />
    </React.Fragment>
  );
};
