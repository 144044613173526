import {
  GridCellProps,
  GridColumn as Column,
  GridRowProps,
} from '@progress/kendo-react-grid';
import { LightPageHeader } from 'Components/Display';
import {
  DateCell,
  DateLinkCell,
  FilterBarMainGrid,
  LinkCell,
} from 'Components/Grid';
import { FormProvider, GridProvider, useEnums, useGlobalContext, useGridState } from 'Context';
import { ColumnSelectorProvider, useColumnSelectorState } from 'Context/ColumnSelectorProvider';
import {
  defaultGridProps,
  FilterBarFilterType,
  FilterConfig,
  IFilterConfig,
} from 'Models';
import { DefaultColumnsSelectionClosedReferrals } from 'Models/ColumnSelection';
import { ENDPOINTS } from 'Models/Templates/Routes/Routes';
import {
  AppointmentSubRoute,
  ClaimantsSubRoute,
  ClaimsSubRoute,
  ROUTES,
} from 'Navigation';
import React, { Fragment, useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import {
  DEFAULT_DATETIME24H_FORMAT,
  DEFAULT_DATE_FORMAT,
  buildEndpoint,
  useGridSearch,
  useHasPermissions,
} from 'Utilities';

class ClosedReferralsSearch {
  searchKey?: string;
  id?: string;
  client?: string;
  benefitState?: string;
  appointmentDate?: Date;
}

export enum ClosedReferralsGridIds {
  DetailedSearch = 'detailedSearch',
}

export const ClosedReferrals: React.FC = () => {
  const style = {
    marginTop: '0.5em',
    display: 'block',
  };
  return (
    <ColumnSelectorProvider
      columns={DefaultColumnsSelectionClosedReferrals}
      columnSelectorName='closedReferralsColumns'
    >
      <GridProvider entityName="Referral">
        <LightPageHeader tall noBorder className="page-header-shadow">
          <Row>
            <Col>
              <h1>Closed Referrals</h1>
            </Col>
          </Row>
        </LightPageHeader>
        <br />
        <Row>
          <Col>
            <ClosedTotalHeader />
          </Col>
        </Row>
        <FilterBarDetailedSearchClosedReferralsGrid />
      </GridProvider>
    </ColumnSelectorProvider>
  );
};

export const ClosedTotalHeader: React.FC = () => {
  const gridState = useGridState();

  let closedTotals = `${gridState.total || 0}`;

  return (
    <Fragment>
      <div className="d-flex flex-row-reverse m-3">
        Grayed out Rows = Not Reviewed Closed Referrals.
      </div>
      <h6>{closedTotals} Appointment(s) Closed</h6>
    </Fragment>
  );
};

export const FilterBarDetailedSearchClosedReferralsGrid: React.FC = () => {
  const ClosedReferralSearch = new ClosedReferralsSearch();

  return (
    <FormProvider formModel={ClosedReferralSearch}>
      <ClosedReferralsGrid />
    </FormProvider>
  );
};

const ClosedReferralsGrid: React.FC = () => {
  const { state } = useEnums();
  const globalContext = useGlobalContext();
  const userdata = globalContext[0]?.currentUser;
  const columnSelectorState = useColumnSelectorState()

  const [ exportEndpoint, setExportEndpoint ] = useState(buildEndpoint(
    ENDPOINTS.closedcases.endpointString('ExportToCsv') + `${getSelectedColumnsQuery(columnSelectorState)}`
  ));

  useEffect(() => {
    setExportEndpoint(buildEndpoint(
      ENDPOINTS.closedcases.endpointString('ExportToCsv') + `${getSelectedColumnsQuery(columnSelectorState)}`
    ));
  }, [columnSelectorState]);

  let searchBarHint: string =
'Stradix Number, Claim Number, Claimant, Referral Number, Doctor'

  if (userdata != null && userdata.isAdmin) {
    searchBarHint = searchBarHint + ', External Adjuster';
  }

  const filters: IFilterConfig[] = [
    new FilterConfig(
      'searchKey',
      FilterBarFilterType.SearchText,
      searchBarHint
    ),
    new FilterConfig(
      'clients',
      FilterBarFilterType.AsyncDropdown,
      'Client',
      {
        readEndpoint: ENDPOINTS.clients.dropdownItems
      },
      (value) => value.name,
      true
    ),
    new FilterConfig(
      'benefitStates',
      FilterBarFilterType.EnumDropdown,
      'Benefit State',
      { data: state },
      (value => value.displayName),
      true
    ),
    new FilterConfig(
      'appointmentDate',
      FilterBarFilterType.DatePicker,
      'Appointment Date'
    ),
    new FilterConfig(
      'reviewers',
      FilterBarFilterType.AsyncDropdown,
      'Reviewer',
      {
        readEndpoint:
        `${ENDPOINTS.users.endpointString('DropdownItems')}?IsReviewer=true`,
        useQuery: true
      },
      (value) => value.name,
      true
    )
  ];

  const [gridToggler] = useGridSearch();

  return (
    <React.Fragment>
      <FilterBarMainGrid
        isMainGrid
        filterBarProps={{
          filterConfigurations: filters,
          filterValues: {},
          isColumnSelectorEnabled: true,
        }}
        refreshToggle={gridToggler}
        readEndpoint={ENDPOINTS.closedcases.read}
        rowRender={rowRender}
        exportEndpoint={buildEndpoint(
          exportEndpoint
        )}
        enableGridRefresh={true}
        {...defaultGridProps}
      >
        { columnSelectorState.columns.find(x => x.field === 'stradixNumber')?.checked && <Column field="stradixNumber" cell={referralNumberCell} title="Stradix Number" width='auto' />}
        { columnSelectorState.columns.every(x => !x.checked) && <Column field="id" title=" " className='hidden' />}
        { columnSelectorState.columns.find(x => x.field === 'clientName')?.checked && <Column field="client" title="Client" />}
        { columnSelectorState.columns.find(x => x.field === 'referralDate')?.checked && <Column field="referralDate" title="Referral Date" cell={dateFormatCell} />}
        { columnSelectorState.columns.find(x => x.field === 'reportDueDate')?.checked && <Column field="reportDueDate" title="Report Due Date" cell={dateFormatCell} />}
        { columnSelectorState.columns.find(x => x.field === 'referralDueDate')?.checked && <Column field="dueDate" title="Client Due Date" cell={dateFormatCell} />}
        { columnSelectorState.columns.find(x => x.field === 'claimantName')?.checked && <Column field="claimant" title="Claimant Name" cell={claimantCell} />}
        { columnSelectorState.columns.find(x => x.field === 'claimNumber')?.checked && <Column field="claimNumber" title="Claim Number" cell={claimNumberCell} />}
        { columnSelectorState.columns.find(x => x.field === 'benefitState')?.checked && <Column field="benefitState" title="Benefit State" />}
        { columnSelectorState.columns.find(x => x.field === 'referralNumber')?.checked && <Column field="referralNumber" title="Referral Number" cell={referralNumberCell} />}
        { columnSelectorState.columns.find(x => x.field === 'status')?.checked && <Column field="status" title="Status" />}
        { columnSelectorState.columns.find(x => x.field === 'typeOfEval')?.checked && <Column field="typeOfEval" title="Type of EVAL" />}
        { columnSelectorState.columns.find(x => x.field === 'appointmentDate')?.checked && <Column field="appointmentDate" title="Appointment Date" cell={linkDateColumn} />}
        { columnSelectorState.columns.find(x => x.field === 'treatingPhysician')?.checked && <Column field="treatingPhysician" title="Treating Physician" />}
        { columnSelectorState.columns.find(x => x.field === 'physicianSpecialty')?.checked && <Column field="physicianSpecialty" title="Physician Specialty" />}
        { columnSelectorState.columns.find(x => x.field === 'insuranceCarrier')?.checked && <Column field="insuranceCarrier" title="Insurance Carrier" />}
        { columnSelectorState.columns.find(x => x.field === 'referralSource')?.checked && <Column field="referralSource" title="Referral Source" />}
        { columnSelectorState.columns.find(x => x.field === 'adjusterName')?.checked && <Column field="adjusterName" title="Adjuster Name" />}
        { columnSelectorState.columns.find(x => x.field === 'doctor')?.checked && <Column field="doctor" title="Doctor" /> }
        { columnSelectorState.columns.find(x => x.field === 'closedDate')?.checked && <Column field="closedDate" title="Date Closed" cell={dateFormatCell} /> }
        { columnSelectorState.columns.find(x => x.field === 'checkMedicalsDate')?.checked && <Column field="checkMedicalsDate" title="Check Medicals Date" cell={dateFormatCell} /> }
      </FilterBarMainGrid>
    </React.Fragment>
  );
};

const linkDateColumn: React.FC<GridCellProps> = (props: GridCellProps) => {
  return (
    <DateLinkCell
      endpoint={ROUTES.APPOINTMENT}
      linkField="id"
      subRoute={AppointmentSubRoute.View}
      dateFormat={DEFAULT_DATE_FORMAT}
      {...props}
    />
  );
};

const claimNumberCell: React.FC<GridCellProps> = (props: GridCellProps) => {
  return (
    <LinkCell
      endpoint={ROUTES.CLAIM}
      linkField="claimId"
      subRoute={ClaimsSubRoute.View}
      {...props}
    ></LinkCell>
  );
};

const claimantCell: React.FC<GridCellProps> = (props: GridCellProps) => {
  return (
    <LinkCell
      endpoint={ROUTES.CLAIMANT}
      linkField="claimantId"
      subRoute={ClaimantsSubRoute.View}
      {...props}
    ></LinkCell>
  );
};

const referralNumberCell: React.FC<GridCellProps> = (props: GridCellProps) => {
  return (
    <LinkCell
      endpoint={ROUTES.REFERRAL}
      linkField="referralId"
      subRoute={ClaimsSubRoute.View}
      {...props}
    ></LinkCell>
  );
};

const dateFormatCell: React.FC<GridCellProps> = (props: GridCellProps) => {
  return <DateCell {...props} dateFormat={DEFAULT_DATE_FORMAT} />;
};

const rowRender = (trElement: JSX.Element, props: GridRowProps) => {
  const reviewed = props.dataItem.reviewed;

  const trProps = {
    className: reviewed ? '' : 'highlight-row-gray',
  };

  return React.cloneElement(
    trElement,
    { ...trProps },
    trElement.props.children
  );
};

const getSelectedColumnsQuery = (columnSelectorState: any) => {
  const selectedColumns = columnSelectorState.columns.filter((x: any) => x.checked).map((x: any) => x.field);
  return '?' + selectedColumns.map((x: any) => `columns=${x}`).join('&');
}