import React from 'react';
import { GridCellProps } from '@progress/kendo-react-grid';
import Tooltip from '@mui/material/Tooltip';

export const TooltipCell: React.FC<GridCellProps> = ({ dataItem, field }) => {
  const value = dataItem[field as string];

  return (
    <td>
      <Tooltip title={value}>
        <span>{value}</span>
      </Tooltip>
    </td>
  );
};
