import { makeStyles } from '@mui/styles';
import {
  CancelBackButton,
  FormSubmitButton,
  TextEditButton,
} from 'Components/Form';
import { SecondaryNavigationPillBar } from 'Components/Navigation';
import { SecureComponent } from 'Components/Security';
import { ActionsDropdownConfig, DropdownType } from 'Models';
import React, { Fragment, HTMLProps, ReactNode } from 'react';
import { fullClassName, toggledClass, useIdParam } from 'Utilities';
import { ActionsDropdown } from './ActionsDropdown';
import { HeaderBulletedList } from './HeaderBulletedList';
import './PageHeaders.scss';
import { Render } from './Render';

type PageHeaderProps = HTMLProps<HTMLDivElement> & {
  title?: string;
  dropdownConfig?: ActionsDropdownConfig;
  tall?: boolean;
};

export const PrimaryHeader: React.FC<PageHeaderProps> = ({
  children,
  title,
  dropdownConfig,
  tall,
  className,
}) => {
  return (
    <div
      className={fullClassName(
        'full-width flex-vertical-center bg-primary-accent page-header page-header-primary',
        toggledClass('tall', tall),
        className
      )}
    >
      {title && <h1>{title}</h1>}
      {children}
      {dropdownConfig && <ActionsDropdown {...dropdownConfig} />}
    </div>
  );
};

export type TaskHeaderProps = PageHeaderProps & {
  saveButtonText?: string;
  disableSaveButton?: boolean;
  customButtons?: ReactNode;
  tall?: boolean;
};

export const TaskHeader: React.FC<TaskHeaderProps> = ({
  children,
  title,
  saveButtonText = 'Save',
  disableSaveButton,
  customButtons,
  className,
  tall,
}) => {
  return (
    <LightPageHeader tall={tall} className={className}>
      <div className="flex-grow">
        {title && <h1>{title}</h1>}
        {children}
      </div>
      {customButtons || (
        <Fragment>
          <CancelBackButton className="mr-3" />
          <FormSubmitButton disabled={disableSaveButton}>
            {saveButtonText}
          </FormSubmitButton>
        </Fragment>
      )}
    </LightPageHeader>
  );
};

export type BulletedHeadingProps = {
  mainBulletItems: (string | undefined)[];
  subBulletItems?: (string | undefined)[];
  smallerMainBullets?: boolean;
};

export const BulletedHeading: React.FC<BulletedHeadingProps> = ({
  mainBulletItems,
  subBulletItems = [],
  smallerMainBullets,
}) => {
  const MainBulletWrapper = smallerMainBullets ? 'h2' : 'h1';

  return (
    <div className="flex-column sub-header-text flex-grow">
      <div className="flex-normal">
        <MainBulletWrapper>
          <HeaderBulletedList header list={mainBulletItems} />
        </MainBulletWrapper>
      </div>
      <HeaderBulletedList list={subBulletItems} />
    </div>
  );
};

export type LightPageHeaderProps = HTMLProps<HTMLDivElement> & {
  tall?: boolean;
  noBorder?: boolean;
};

export const LightPageHeader: React.FC<LightPageHeaderProps> = ({
  tall,
  className,
  children,
  noBorder,
}) => {
  return (
    <div
      className={fullClassName(
        'full-width flex-vertical-center page-header task-page-header bg-light',
        toggledClass('tall', tall),
        toggledClass('no-border', noBorder),
        className
      )}
    >
      {children}
    </div>
  );
};

export type PaymentViewHeaderProps = TaskHeaderProps & {
  allowSave?: boolean;
  titleActionsConfig?: ActionsDropdownConfig;
  caseId?: string;
};

export const PaymentViewHeader: React.FC<PaymentViewHeaderProps> = ({
  className,
  title,
  allowSave,
  titleActionsConfig,
  caseId,
}) => {
  const [id] = useIdParam();
  return (
    <LightPageHeader tall>
      <CancelBackButton className="mr-3" />
      <Render condition={allowSave}>
        <FormSubmitButton>Save</FormSubmitButton>
      </Render>
      {titleActionsConfig && (
        <ActionsDropdown type={DropdownType.Solid} {...titleActionsConfig} />
      )}
    </LightPageHeader>
  );
};

export type SecondaryHeaderProps = PageHeaderProps & {
  parentRoute?: string;
  route?: string;
  editPermissions?: number[];
};

export const SecondaryHeader: React.FC<SecondaryHeaderProps> = ({
  children,
  title,
  parentRoute,
  className,
}) => {
  return (
    <div
      className={fullClassName(
        'flex-column border-bottom-light page-header bg-light overflow-auto',
        className
      )}
    >
      <div className="secondary-header-title">
        {title ? <h2>{title}</h2> : children}
      </div>
      <SecondaryNavigationPillBar parentRoute={parentRoute} />
    </div>
  );
};

export const EditSecondaryHeader: React.FC<SecondaryHeaderProps> = ({
  children,
  title,
  parentRoute,
  className,
  route,
  editPermissions,
}) => {
  const useStyles = makeStyles({
    pencil: {
      marginLeft: 'auto',
    },
  });
  const classes = useStyles();

  return (
    <div
      className={fullClassName(
        'flex-column border-bottom-light page-header bg-light overflow-auto',
        className
      )}
    >
      <div className="secondary-header-title">
        {title ? <h2>{title}</h2> : children}
        <SecureComponent permissions={editPermissions}>
          <TextEditButton className={classes.pencil} url={route} />
        </SecureComponent>
      </div>
      <SecondaryNavigationPillBar parentRoute={parentRoute} />
    </div>
  );
};

export const ViewHeader: React.FC<TaskHeaderProps> = ({
  children,
  title,
  customButtons,
  tall,
  className,
}) => {
  return (
    <LightPageHeader tall={tall}>
      <div className="flex-grow">
        {title && <h1>{title}</h1>}
        {children}
      </div>
      {customButtons || (
        <Fragment>
          <CancelBackButton className="mr-3">Back</CancelBackButton>
        </Fragment>
      )}
    </LightPageHeader>
  );
};
