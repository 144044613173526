import { SubRoutes } from 'Navigation/NavigationTypes';
import { AdminSubRouteConfig } from 'Navigation/NavigationTypes';
import { ClaimantClaims, ViewClaimant } from 'Features/Claimants';
import { Permission } from 'Models/Templates/Permission/Permission';

export enum ClaimantsSubRoute {
  View = '/View',
  Claims = '/Claims',
}

export const ClaimantsSubRoutes: SubRoutes = {
  [ClaimantsSubRoute.View]: new AdminSubRouteConfig(
    ClaimantsSubRoute.View,
    'View',
    ViewClaimant,
    true,
    [Permission.ViewClaimant]
  ),
  [ClaimantsSubRoute.Claims]: new AdminSubRouteConfig(
    ClaimantsSubRoute.Claims,
    'Claims',
    ClaimantClaims,
    false,
    [Permission.ViewClaimant]
  ),
};
