import { GridPagerSettings } from '@progress/kendo-react-grid';

export class GridItem {
  isActive?: boolean;
}

export class GridItemList {
  constructor() {
    this.data = [];
  }
  data: GridItem[];
}

export class InlineGridItem extends GridItem {
  constructor() {
    super();
    this.inEdit = false;
  }
  id?: string;
  inEdit?: boolean;
}

export class InlineGridItemList {
  constructor() {
    this.data = [];
  }
  data: InlineGridItem[];
}

export class InlineGridNoApiItem extends InlineGridItem {
  constructor() {
    super();
  }
  gridItemId?: number;
  isAdd?: boolean;
}

export class InlineGridNoApiItemList {
  constructor() {
    this.data = [];
  }
  data: InlineGridNoApiItem[];
}

export enum GridItemChangeType {
  Save = 'Saved',
  Update = 'Updated',
  Delete = 'Deleted',
  Cancel = 'Canceled',
}

type PageableProps = {
  pageable?: boolean | GridPagerSettings;
  [x: string]: any;
};

export const defaultGridProps: PageableProps = {
  pageable: {
    pageSizes: [10, 25, 50, 100, 200],
    type: 'input',
  },
  pageSize: 25,
  sortable: true,
};

export enum GridColumnWidth {
  Ellipsis = 32.5,
  Tiny = 50,
  Small = 100,
  SmallMed = 150,
  Medium = 200,
  MedLarge = 300,
  Large = 400,
  Larger = 500,
  XL = 600,
  Auto = 'Auto',
  Date = 115,
  AddEdit = 120,
  Currency = 125,
  EditDelete = 120,
}

export enum CellFormat {
  Currency = '{0:c}',
}
