import React from 'react';
import { ENDPOINTS } from 'Models/Templates/Routes/Routes';
import {
  EditDeleteCell,
  MainGrid,
  GridButtonSection,
  CommaSeparatedCell,
  YesNoBooleanCell,
} from 'Components/Grid';
import {
  GridColumn as Column,
  GridCellProps,
} from '@progress/kendo-react-grid';
import { defaultGridProps, GridColumnWidth } from 'Models/GridModels';
import { AddButton } from 'Components/Form';
import { GridProvider, useEnums } from 'Context';
import {
  AdminSubHeaderMainLayout,
  CollapsibleAuditLog,
} from 'Components/Display';
import { SecureComponent } from 'Components/Security';
import { Permission } from 'Models/Templates/Permission/Permission';
import { addEditRoute, AddEditSubRoute } from 'Navigation';

export const LineOfBusiness: React.FC = () => {
  const { entityEventType } = useEnums();

  return (
    <AdminSubHeaderMainLayout title="Line Of Business">
      <GridButtonSection>
        <SecureComponent permissions={[Permission.AddLineOfBusiness]}>
          <AddButton url={addEditRoute(AddEditSubRoute.LineOfBusiness)}>
            New Line of Business
          </AddButton>
        </SecureComponent>
      </GridButtonSection>
      <GridProvider entityName="Line Of Business">
        <MainGrid readEndpoint={ENDPOINTS.lineOfBusiness.read} {...defaultGridProps}>
          <Column field="name" title="Name" width={GridColumnWidth.MedLarge} />
          <Column
            field="services"
            title="Services"
            cell={TatsColumn}
          />
          <Column
            field="tats"
            title="TATs"
            cell={TatsColumn}
          />
          <Column
            field="id"
            title=" "
            cell={ActionsCell}
            width={GridColumnWidth.EditDelete}
          />
        </MainGrid>
      </GridProvider>
      <CollapsibleAuditLog
        title="Audit Log"
        admin
        entityEventType={entityEventType.LineOfBusiness?.value}
      />
    </AdminSubHeaderMainLayout>
  );
};

type collectionObject = {
  id: string
  value: string
  name: string
}

export const TatsColumn: React.FC<GridCellProps> = ({ dataItem, field = "" }) => {
  const values: collectionObject[] = dataItem[field];
  const commaSeparatedValues: string = values.map(t => t.value || t.name).join(", ");

  return (
    <td>{commaSeparatedValues}</td>
  );
};

const ActionsCell: React.FC<GridCellProps> = ({ ...otherProps }) => {
  return (
    <EditDeleteCell
      editRoute={addEditRoute(AddEditSubRoute.LineOfBusiness)}
      editPermissions={[Permission.EditLineOfBusiness]}
      deleteEndpoint={ENDPOINTS.lineOfBusiness.base}
      deletePermissions={[Permission.DeleteLineOfBusiness]}
      {...otherProps}
    />
  );
};
