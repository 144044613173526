import {
  ClosedReferrals,
  OpenReferrals,
  AllClaimants,
  ReviewedReferrals,
  AssignedToWriters,
} from 'Features/CaseManagement';
import { Permission } from 'Models/Templates/Permission/Permission';
import { DropdownSubRouteConfig, SubRoutes } from 'Navigation/NavigationTypes';
import React from 'react';
import MenuBook from '@mui/icons-material/MenuBook';
import Book from '@mui/icons-material/Book';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import BookOnlineIcon from '@mui/icons-material/BookOnline';
import GradingIcon from '@mui/icons-material/Grading';
import { ReferralsRequests } from 'Features/ReferralsRequests';
import { Appointments } from 'Features/Appointments';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';

export enum CaseManagementSubRoute {
  OpenReferrals = '/OpenReferrals',
  ClosedReferrals = '/ClosedReferrals',
  NewReferrals = '/NewReferrals',
  PendingScheduling = '/PendingScheduling',
  AllClaimants = '/AllClaimants',
  ReviewedReferrals = '/ReviewedReferrals',
  AssignedToWriters = '/AssignedToWriters',
}

export const CaseManagementSubRoutes: SubRoutes = {
  [CaseManagementSubRoute.NewReferrals]: new DropdownSubRouteConfig(
    CaseManagementSubRoute.NewReferrals,
    'New Referrals',
    ReferralsRequests,
    [Permission.ViewNewReferrals],
    false,
    {},
    <NoteAddIcon />
  ),
  [CaseManagementSubRoute.PendingScheduling]: new DropdownSubRouteConfig(
    CaseManagementSubRoute.PendingScheduling,
    'Pending Appointments',
    Appointments,
    [Permission.ViewPendingAppointments],
    false,
    {},
    <BookOnlineIcon />
  ),
  [CaseManagementSubRoute.OpenReferrals]: new DropdownSubRouteConfig(
    CaseManagementSubRoute.OpenReferrals,
    'Open Referrals',
    OpenReferrals,
    [Permission.ViewOpenCases],
    true,
    {},
    <MenuBook />
  ),
  [CaseManagementSubRoute.ClosedReferrals]: new DropdownSubRouteConfig(
    CaseManagementSubRoute.ClosedReferrals,
    'Closed Referrals',
    ClosedReferrals,
    [Permission.ViewClosedCases],
    false,
    {},
    <Book />
  ),
  [CaseManagementSubRoute.AllClaimants]: new DropdownSubRouteConfig(
    CaseManagementSubRoute.AllClaimants,
    'All Referrals',
    AllClaimants,
    [Permission.ViewAllClaimants],
    false,
    {},
    <Book />
  ),
  [CaseManagementSubRoute.ReviewedReferrals]: new DropdownSubRouteConfig(
    CaseManagementSubRoute.ReviewedReferrals,
    'Reviewed Referrals',
    ReviewedReferrals,
    [Permission.ViewReviewedReferrals],
    false,
    {},
    <GradingIcon />
  ),
  [CaseManagementSubRoute.AssignedToWriters]: new DropdownSubRouteConfig(
    CaseManagementSubRoute.AssignedToWriters,
    'Assigned To Writers',
    AssignedToWriters,
    [Permission.ViewAssignedToWriters],
    false,
    {},
    <DriveFileRenameOutlineIcon />
  ),
};
