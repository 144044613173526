import { makeStyles } from '@mui/styles';
import { 
  PageCard, 
  PageCardRow, 
  PageCardSection 
} from 'Components/Display';
import { useEntity } from 'Context';
import {
  ClaimantsSubRoute,
  ClaimsSubRoute,
  EntitiesSubRoute,
  entitiesSubRouteName,
  ReferralSubRoute,
  ROUTES,
} from 'Navigation';
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { 
  Breadcrumb, 
  BreadcrumbItem, 
  Col, 
  Row 
} from 'reactstrap';
import { 
  buildRoute, 
  formatDateTimeFromDotNetFormat 
} from 'Utilities';

export const ViewAppointmentBreadcrumbs: React.FC = () => {
  const entity = useEntity();

  let claimantEndpoint = buildRoute(
    ROUTES.CLAIMANT,
    `${entity.claimantId}`,
    ClaimantsSubRoute.View
  );
  let claimEndpoint = buildRoute(
    ROUTES.CLAIM,
    `${entity.claimId}`,
    ClaimsSubRoute.View
  );
  let referralEndpoint = buildRoute(
    ROUTES.REFERRAL,
    `${entity.referralId}`,
    ReferralSubRoute.View
  );
  let appointmentDate = entity.appointmentDate
    ? formatDateTimeFromDotNetFormat(entity.appointmentDate, 'MM-dd-yyyy')
    : '';

  let claimantName = entity != undefined ?
  `${entity.claimantLastName}, ${entity.claimantFirstName}` : "";

  return (
    <Fragment>
      <Breadcrumb tag="nav" listTag="div">
        <BreadcrumbItem>
          <Link to={ROUTES.DASHBOARD}>Home</Link>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <Link to={entitiesSubRouteName(EntitiesSubRoute.Claimants)}>
            Claimants
          </Link>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <Link to={claimantEndpoint}>{claimantName}</Link>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <Link to={claimEndpoint}>{entity.claimNumber}</Link>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <Link to={referralEndpoint}>{entity.referralName}</Link>
        </BreadcrumbItem>
        <BreadcrumbItem>{appointmentDate}</BreadcrumbItem>
      </Breadcrumb>
    </Fragment>
  );
};

export const ViewAppointment: React.FC = () => {
  const entity = useEntity();

  const useStyles = makeStyles({
    root: {
      minWidth: 275,
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
  });

  const classes = useStyles();

  return (
    <Fragment>
      <PageCard className={classes.root}>
        <PageCardRow>
          <PageCardSection title="Information">
            <Row>
              <Col xs="12" lg="4">
                <span className="field-label data-display">Status:</span>
                <span className="field-value">
                  {entity.appointmentStatus
                    ? entity.appointmentStatus.displayName
                    : ''}
                </span>
              </Col>
            </Row>
            <Row>
              <Col xs="12" lg="4">
                <span className="field-label data-display">
                  Appointment Date:
                </span>
                <span className="field-value">
                  {entity.appointmentDate
                    ? formatDateTimeFromDotNetFormat(
                        entity.appointmentDate,
                        'MM/dd/yyyy'
                      )
                    : ''}
                </span>
              </Col>
            </Row>
          </PageCardSection>
        </PageCardRow>
      </PageCard>
    </Fragment>
  );
};
