import { AdminSubRouteConfig, SubRoutes } from 'Navigation/NavigationTypes';
import { ClaimEscalations, ClaimReferrals, ViewClaim } from 'Features/Claims';
import { Permission } from 'Models/Templates/Permission/Permission';

export enum ClaimsSubRoute {
  View = '/View',
  Referrals = '/Referrals',
  Escalations = '/Escalations',
}

export const ClaimsSubRoutes: SubRoutes = {
  [ClaimsSubRoute.View]: new AdminSubRouteConfig(
    ClaimsSubRoute.View,
    'View',
    ViewClaim,
    true,
    [Permission.ViewClaim]
  ),
  [ClaimsSubRoute.Escalations]: new AdminSubRouteConfig(
    ClaimsSubRoute.Escalations,
    'Escalations',
    ClaimEscalations,
    true,
    [Permission.ViewEscalation]
  ),
  [ClaimsSubRoute.Referrals]: new AdminSubRouteConfig(
    ClaimsSubRoute.Referrals,
    'Referrals',
    ClaimReferrals,
    false,
    [Permission.ViewReferral]
  ),
};
