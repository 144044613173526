import React from "react";
import { DateHeaderCellProps } from "@progress/kendo-react-scheduler";
import { fullClassName, toggledClass } from "Utilities";
import { formatDate } from "@telerik/kendo-intl";

export const CustomWeekDayHeaderCell: React.FC<DateHeaderCellProps> = ({
  date,
  className,
}) => {
  var dateToRender = new Date(date);
  var todaysDate = new Date();
  dateToRender.setHours(0, 0, 0, 0);
  todaysDate.setHours(0, 0, 0, 0);
  const isToday = dateToRender.valueOf() === todaysDate.valueOf();

  return (
    <div
      className={fullClassName(
        "k-scheduler-cell k-heading-cell k-week-heading-cell",
        toggledClass("today-cell", isToday),
        className
      )}
    >
      <div className="k-link k-nav-day flex-column">
        <span>{formatDate(date, { weekday: "short" })}</span>
        <span className="primary-font-bold">
          <h3>{formatDate(date, { day: "2-digit" })}</h3>
        </span>
      </div>
    </div>
  );
};
