import { filterBy } from '@progress/kendo-data-query';
import {
  MultiSelect,
  MultiSelectChangeEvent,
  MultiSelectFilterChangeEvent,
  MultiSelectProps,
} from '@progress/kendo-react-dropdowns';
import TagList, {
  TagData,
} from '@progress/kendo-react-dropdowns/dist/npm/MultiSelect/TagList';
import { useToasts } from 'Context';
import { defaultDropdownResponseTransform } from 'Models';
import { AsyncStatus } from 'Models/SharedModels';
import React, { useEffect, useState } from 'react';
import {
  buildEndpoint,
  buildQueryUrl,
  DEFAULT_DROPDOWN_TEXTFIELD,
  DEFAULT_DROPDOWN_VALUEFIELD,
  isArray,
  isNullEmptyOrUndefined,
  useDataFetcher,
} from 'Utilities';

export type DataMultiSelectProps = MultiSelectProps & {
  readEndpoint?: string;
  transformResponseData?: (r: any) => any;
};

export type AsyncDataMultiSelectProps = DataMultiSelectProps & {
  useQuery?: boolean;
  otherParams?: any;
  validateCustomValues?: (newValue: any) => boolean;
  validationMessage?: string;
};

export const AsyncDataMultiSelect: React.FC<AsyncDataMultiSelectProps> = ({
  readEndpoint,
  textField = DEFAULT_DROPDOWN_TEXTFIELD,
  dataItemKey = DEFAULT_DROPDOWN_VALUEFIELD,
  value = [],
  transformResponseData,
  otherParams,
  useQuery,
  validateCustomValues,
  validationMessage = 'Invalid value.',
  onChange,
  ...otherProps
}) => {
  const toast = useToasts();
  const [dropdownItems, setDropdownItems] = useState<any[]>([]);
  const [filterValue, setFilterValue] = useState('');

  const tags: Array<TagData> = value.map((x) => {
    return { text: x[textField], data: [x] };
  });
  var filterQueryObj = { filter: filterValue };

  var url =
    otherParams && useQuery
      ? buildQueryUrl(readEndpoint!, { ...otherParams, ...filterQueryObj })
      : otherParams || useQuery
      ? buildQueryUrl(readEndpoint!, otherParams || { filterValue })
      : buildEndpoint(readEndpoint, filterValue);

  var url =
    otherParams || useQuery
      ? buildQueryUrl(readEndpoint!, otherParams || { filterValue })
      : buildEndpoint(readEndpoint, filterValue);

  const [dropdownData, fetchStatus, { getData }] = useDataFetcher(
    url,
    [],
    transformResponseData || defaultDropdownResponseTransform,
    undefined,
    false
  );

  const filterChange = (e: MultiSelectFilterChangeEvent) => {
    setFilterValue(e.filter!.value);
  };

  const onValueChange = (e: MultiSelectChangeEvent) => {
    if (validateCustomValues && !validateCustomValues(e.value)) {
      toast.error(validationMessage);
    } else {
      onChange && onChange(e);
    }
  };

  useEffect(() => {
    if (dropdownData !== undefined && isArray(dropdownData)) {
      setDropdownItems(dropdownData);
    } else {
      setDropdownItems([]);
    }
  }, [dropdownData]);

  useEffect(() => {
    getData();
  }, [filterValue, otherParams, readEndpoint]);

  return (
    <MultiSelect
      data={dropdownItems}
      textField={textField}
      dataItemKey={dataItemKey}
      value={value}
      tags={tags}
      filterable={true}
      onFilterChange={filterChange}
      loading={fetchStatus === AsyncStatus.Pending}
      onChange={onValueChange}
      {...otherProps}
    />
  );
};

export const DataMultiSelect: React.FC<DataMultiSelectProps> = ({
  readEndpoint,
  data,
  textField = DEFAULT_DROPDOWN_TEXTFIELD,
  dataItemKey = DEFAULT_DROPDOWN_VALUEFIELD,
  filterable,
  value,
  transformResponseData,
  ...otherProps
}) => {
  const [dropdownItems, setDropdownItems] = useState<any[]>(data || []);
  const [dropdownData, fetchStatus] = useDataFetcher(
    readEndpoint!,
    data || [],
    transformResponseData || defaultDropdownResponseTransform,
    undefined,
    readEndpoint !== undefined && readEndpoint.length > 0
  );

  useEffect(() => {
    if (dropdownData !== undefined && isArray(dropdownData)) {
      setDropdownItems(dropdownData);
    } else {
      setDropdownItems([]);
    }
  }, [dropdownData]);

  const filterChange = ({ filter }: any) => {
    const items = [...dropdownItems];
    return filterBy(items, filter);
  };

  return (
    <MultiSelect
      data={dropdownItems}
      textField={textField}
      dataItemKey={dataItemKey}
      value={value}
      filterable={filterable}
      onFilterChange={filterChange}
      loading={fetchStatus === AsyncStatus.Pending}
      {...otherProps}
    />
  );
};

export const EnumMultiSelect: React.FC<DataMultiSelectProps> = ({
  data = {},
  textField,
  dataItemKey,
  ...allProps
}) => {
  return (
    <MultiSelect
      textField="displayName"
      dataItemKey="value"
      data={Object.values(data)}
      {...allProps}
    />
  );
};
