import 'react-datepicker/dist/react-datepicker.css';
import React, { useRef } from 'react';
import { Calendar, CalendarProps } from '@progress/kendo-react-dateinputs';
import { TextButton } from '../Buttons';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { fullClassName, useOnClickOutside, useToggler } from 'Utilities';
import { Popup } from '@progress/kendo-react-popup';

export const PopupCalendar: React.FC<CalendarProps> = ({
  className,
  ...calendarProps
}) => {
  const [isOpen, toggleIsOpen] = useToggler(false);
  var menuToggleRef = useRef<any>(null);
  var menuRef = useRef<any>(null);

  useOnClickOutside([menuRef, menuToggleRef], () => {
    isOpen && toggleIsOpen();
  });

  return (
    <div className={fullClassName(className, 'position-relative')}>
      <div
        className="position-relative"
        ref={(ref) => (menuToggleRef.current = ref)}
      >
        <TextButton onClick={toggleIsOpen}>
          <CalendarTodayIcon />
        </TextButton>
      </div>
      <Popup
        className="calendar-popup-wrap"
        anchor={menuToggleRef.current}
        anchorAlign={{ horizontal: 'left', vertical: 'bottom' }}
        popupAlign={{ horizontal: 'left', vertical: 'top' }}
        show={isOpen}
        animate={false}
        appendTo={document.getElementById('root') || undefined}
      >
        <div className="calendar-popup" ref={(ref) => (menuRef.current = ref)}>
          <Calendar {...calendarProps} />
        </div>
      </Popup>
    </div>
  );
};
