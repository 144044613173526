import {
  AsyncLoadingSection,
  SingleSectionPageCard,
  TaskPageHeaderLayout,
} from 'Components/Display';
import { SmartInputBlock } from 'Components/Form';
import { useEnums } from 'Context';
import { Claim, CoreEnum } from 'Models';
import { InputType } from 'Models/FormModels';
import { ColSize } from 'Models/SharedModels';
import { ENDPOINTS } from 'Models/Templates/Routes/Routes';
import React, { Fragment } from 'react';
import { useParams } from 'react-router-dom';
import { Row } from 'reactstrap';
import {
  useDataFetcher,
  addEditTitle,
  useIdParam,
  useAddEditSuccessHandler,
  defaultDataTransform,
  buildEndpoint,
  buildQueryUrl,
} from 'Utilities';

export const AddEditClaim: React.FC = () => {
  const entityName = 'Claim';
  const [id, isAdd] = useIdParam();
  const handleSuccess = useAddEditSuccessHandler(entityName, isAdd);
  const { claimantid } = useParams<any>();

  const [data, loadingStatus] = useDataFetcher<Claim>(
    buildQueryUrl(buildEndpoint(ENDPOINTS.claims.get), {
      id: id || '',
      claimantId: claimantid,
    }),

    new Claim(),
    defaultDataTransform,
    Claim
  );

  return (
    <AsyncLoadingSection loadingStatus={loadingStatus} useMask>
      <TaskPageHeaderLayout
        title={addEditTitle(entityName, isAdd)}
        formProviderProps={{
          formModel: data,
          endpoint: ENDPOINTS.claims.base,
          handleSuccess: handleSuccess,
        }}
      >
        <ClaimsAddEditFormContent />
      </TaskPageHeaderLayout>
    </AsyncLoadingSection>
  );
};

const ClaimsAddEditFormContent: React.FC = () => {
  type ListObject = {
    textValue?: string;
    dateValue?: Date;
  };


  const { state } = useEnums();
  return (
    <Fragment>
      <SingleSectionPageCard cardTitle="Details" centerContent inputSection>
        <SmartInputBlock name="claimantId" type={InputType.Text} hidden />
        <Row>
          <SmartInputBlock
            name="claimDate"
            label="Claim Date"
            type={InputType.DatePicker}
            colProps={{ xs: ColSize.OneThird }}
            required
          />
          <SmartInputBlock
            name="dateOfInjury"
            label="Date of Injury"
            type={InputType.DatePicker}
            required
            colProps={{ xs: ColSize.OneThird }}
          />

          <SmartInputBlock
            name="claimNumber"
            label="Claim Number"
            type={InputType.Text}
            colProps={{ xs: ColSize.OneThird }}
            required
          />
        </Row>
        <Row>
          <SmartInputBlock
            name="client"
            label="Client"
            type={InputType.AsyncDropDown}
            readEndpoint={ENDPOINTS.clients.dropdownItems}
            colProps={{ xs: ColSize.OneThird }}
            required
          />
          <SmartInputBlock
            name="benefitState"
            label="Benefit State"
            type={InputType.EnumDropdown}
            data={state}
            colProps={{ xs: ColSize.OneThird }}
            required
          />
          <SmartInputBlock
            name="receivedDate"
            label="Received Date"
            type={InputType.DatePicker}
            colProps={{ xs: ColSize.OneThird }}
          />
        </Row>
        <Row>
          <h5>Insurance Carrier Information</h5>
        </Row>
        <Row>
          <SmartInputBlock
            name="insuranceCarrierName"
            label="Insurance Carrier"
            type={InputType.Text}
            colProps={{ xs: ColSize.OneThird }}
          />
          <SmartInputBlock
            name="insuranceCarrierAddress.line1"
            label="Address Line 1"
            type={InputType.Text}
            colProps={{ xs: ColSize.OneThird }}
          />

          <SmartInputBlock
            name="insuranceCarrierAddress.line2"
            label="Address Line 2"
            type={InputType.Text}
            colProps={{ xs: ColSize.OneThird }}
          />
        </Row>
        <Row>
          <SmartInputBlock
            name="insuranceCarrierAddress.city"
            label="City"
            type={InputType.Text}
            colProps={{ xs: ColSize.OneThird }}
          />
          <SmartInputBlock
            name="insuranceCarrierAddress.state"
            label="State"
            type={InputType.EnumDropdown}
            data={state}
            colProps={{ xs: ColSize.OneThird }}
          />

          <SmartInputBlock
            name="insuranceCarrierAddress.zip"
            label="ZIP"
            type={InputType.Text}
            colProps={{ xs: ColSize.OneThird }}
          />
        </Row>
        <SmartInputBlock
          name="claimantId"
          type={InputType.Text}
          hidden
          colProps={{ xs: ColSize.OneThird }}
          required
        />
      </SingleSectionPageCard>
    </Fragment>
  );
};
