import React, { HTMLProps } from 'react';
import { fullClassName } from 'Utilities';

export const DropdownArrowIcon: React.FC<HTMLProps<HTMLSpanElement>> = ({
  className,
  ...otherProps
}) => {
  return <span className={fullClassName('k-icon k-i-arrow-s', className)} />;
};

export const DropdownArrowIconUp: React.FC<HTMLProps<HTMLSpanElement>> = ({
  className,
  ...otherProps
}) => {
  return (
    <span className={fullClassName('k-icon k-i-arrow-60-up', className)} />
  );
};

export const RightArrowIcon: React.FC<HTMLProps<HTMLSpanElement>> = ({
  className,
  ...otherProps
}) => {
  return <span className={fullClassName('k-icon k-i-arrow-e', className)} />;
};
