import {
  AsyncLoadingSection,
  DisplayBlock,
  ModalDrawerButtonRow,
  MultiModalFormDrawer,
  Render,
} from 'Components/Display';
import {
  ButtonStyle,
  FormSubmitButton,
  IconButton,
  ModalFormCancelButton,
  SmartCheckboxList,
  SmartFileUploader,
} from 'Components/Form';
import {
  FormActionTypes,
  useEntity,
  useFormUpdater,
  useGridState,
  useModalFormState,
  useModalHelpers,
} from 'Context';
import { ENDPOINTS } from 'Models/Templates/Routes/Routes';
import React, { useEffect } from 'react';
import { Col, Row } from 'reactstrap';
import { buildEndpoint, useDataFetcher, useIdParam } from 'Utilities';
import { AppointmentDocumentModel, EntityDocumentCombineModel, FileModel, ReferralRequestDocumentModal, ReferralRequestDocumentModel } from 'Models';
import { FormLabel } from 'react-bootstrap';
import CheckCircle from '@mui/icons-material/CheckCircle';

export const ReferralRequestDocumentDrawerFormContent: React.FC = () => {
  const [id] = useIdParam();
  const { entityId, isOpen } = useModalFormState();
  const sendUpdate = useFormUpdater();
  const isAdd = entityId === undefined;
  const addOrEditEndpoint = ENDPOINTS.referralrequestdocuments.endpointString(
    isAdd ? 'add' : 'edit'
  );
  const allowMultiple = true;

  let [referralRequestDocumentModel, fetchStatus] =
    useDataFetcher<ReferralRequestDocumentModel>(
      buildEndpoint(ENDPOINTS.referralrequestdocuments.base, entityId),
      new ReferralRequestDocumentModel(id),
      ({ data }) => data,
      undefined,
      !isAdd && isOpen
    );

  useEffect(() => {
    if (!isAdd) {
      sendUpdate(FormActionTypes.SetValues, referralRequestDocumentModel);
    }
  }, [ReferralRequestDocumentModel, sendUpdate]);

  return (
    <AsyncLoadingSection loadingStatus={fetchStatus} noIdleLoading>
      <Render condition={!isAdd}></Render>
      <SmartFileUploader
        label="Files"
        required
        fileEndpoint={ENDPOINTS.referralrequestdocuments.base}
        isEdit={!isAdd}
        vertical
        withComments
        allowMultiple={allowMultiple}
      />
      <ModalDrawerButtonRow>
        <ModalFormCancelButton />
        <FormSubmitButton alternateEndpoint={addOrEditEndpoint}>
          Save Document
        </FormSubmitButton>
      </ModalDrawerButtonRow>
    </AsyncLoadingSection>
  );
};

export type ReferralRequestDocumentCombineFormProps = {
  endpoint?: string;
  formModel?: EntityDocumentCombineModel;
};

export const ReferralRequestDocumentCombineForm: React.FC<ReferralRequestDocumentCombineFormProps> =
  ({ formModel, ...props }) => {
    const entity = useEntity();
    formModel = new EntityDocumentCombineModel(
      entity.id,
    );
    const { simpleSuccessHandler } = useModalHelpers(
      ReferralRequestDocumentModal.Combine
    );
    const { refreshGrid } = useGridState();
  
    const handleSuccess = () => {
      simpleSuccessHandler('Documents have been combined');
      refreshGrid();
    };

    return (
      <MultiModalFormDrawer
        modalId={ReferralRequestDocumentModal.Combine}
        submitButtonText="Save"
        formModel={formModel}
        endpoint={buildEndpoint(ENDPOINTS.referralrequestdocuments.base,
          'CombineMedicals',
          formModel?.entityId)}
        handleSuccess={handleSuccess}
      >
        <AppointmentDocumentCombineFormContent formModel={formModel} {...props} />
      </MultiModalFormDrawer>
    );
  };

export const AppointmentDocumentCombineFormContent: React.FC<
  ReferralRequestDocumentCombineFormProps
> = ({ formModel, ...props }) => {
  const sendUpdate = useFormUpdater();
  const { isOpen } = useModalHelpers(
    ReferralRequestDocumentModal.Combine
  );

  let [combinableFiles, fetchStatus] =
    useDataFetcher<AppointmentDocumentModel[]>(
      buildEndpoint(
        ENDPOINTS.referralrequestdocuments.base,
        'GetCombinableMedicals',
        formModel?.entityId
      ),
      [],
      ({ data }) => data,
      undefined,
      isOpen
    );

  useEffect(() => {
    sendUpdate(FormActionTypes.SetValues, combinableFiles);
  }, [combinableFiles, sendUpdate]);

  return (
    <AsyncLoadingSection loadingStatus={fetchStatus} noIdleLoading>
      <Row>
        <Col>
          <FormLabel>
            Combinable files
          </FormLabel>
        </Col>
      </Row>
      <Row>
        <Col>
          <SmartCheckboxList
            name="documentsToCombine"
            labelField="fileName"
            checkedField="isSelected"
            groupField='groupName'
            hideCheckAll
          />
        </Col>
      </Row>
      <ModalDrawerButtonRow>
        <ModalFormCancelButton />
        <FormSubmitButton>Combine Documents</FormSubmitButton>
      </ModalDrawerButtonRow>
    </AsyncLoadingSection>
  );
};