import { DataResult, State } from "@progress/kendo-data-query";
import { isArray } from "lodash";
import * as React from "react";
import { useForceUpdate, useToggler } from "Utilities";

export interface GridState {
  entityName?: string;
  data?: any[] | undefined | DataResult | null;
  setData: React.Dispatch<
    React.SetStateAction<any[] | undefined | DataResult | null>
  >;
  total: number;
  setTotal: React.Dispatch<React.SetStateAction<number>>;
  refreshValue: boolean;
  refreshGrid: () => void;
  condition: boolean;
  setCondition: (val: boolean) => void;
  state?: State;
  setState: (val: State) => void;
  activeItem?: any;
  setActiveItem: (val: any) => void;
  forceUpdate: () => void;
  search: any;
  setSearch: React.Dispatch<any>;
  setRefreshValue: React.Dispatch<boolean>;
}

const GridStateContext = React.createContext<GridState | undefined>(undefined);

export type GridProviderProps = {
  entityName?: string;
  condition?: boolean;
};

export const GridProvider: React.FC<GridProviderProps> = ({
  entityName,
  condition = true,
  children,
}) => {
  const [refreshValue, toggleRefreshValue, setRefreshValue] = useToggler(false);
  const [gridCondition, setGridCondition] = React.useState(condition);
  const [data, setData] = React.useState<
    any[] | undefined | DataResult | null
  >();
  const [total, setTotal] = React.useState<number>(0);
  const [gridState, setGridState] = React.useState<State>();
  const [activeItem, setActiveItem] = React.useState<any>();
  const [search, setSearch] = React.useState<any>();
  const forceUpdate = useForceUpdate();

  return (
    <GridStateContext.Provider
      value={{
        entityName,
        data,
        setData,
        refreshValue,
        refreshGrid: toggleRefreshValue,
        condition: gridCondition,
        setCondition: setGridCondition,
        state: gridState,
        setState: setGridState,
        activeItem: activeItem,
        setActiveItem: setActiveItem,
        forceUpdate: forceUpdate,
        search,
        setSearch,
        setRefreshValue,
        total,
        setTotal,
      }}
    >
      {children}
    </GridStateContext.Provider>
  );
};

export const useOptionalGridState = () => {
  const gridStateContext = React.useContext(GridStateContext);

  return gridStateContext;
};

export const useGridState = () => {
  const gridStateContext = React.useContext(GridStateContext);

  if (gridStateContext === undefined) {
    throw new Error("useGridState must be used within a GridProvider");
  }

  return gridStateContext;
};
