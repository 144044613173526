import React, { useEffect, useState } from 'react';
import {
  FormFieldConfiguration,
  getValueFromChangeEvent,
  useFormHelpers,
  useFormState,
  useFormStateValue,
} from 'Context';
import { InputBlock, InputBlockProps } from 'Components/Form';
import {
  fullClassName,
  isSizeableInput,
  isTextInput,
  toggledClass,
  useDebounce,
} from 'Utilities';
import { InputSize } from 'Models';
import { isEqual } from 'lodash';

export type SmartInputBlockProps = InputBlockProps & {
  saveOnDebounce?: boolean;
  debounceLength?: number;
  onChangeOverride?: (e: any) => void;
  otherErrorNames?: string[];
  onDebounce?: (debouncedValue?: any) => void;
  prompt?: string;
  width?: number | string;
  fieldConfiguration?: Partial<FormFieldConfiguration>;
  ignoreFieldConfiguration?: boolean;
};

export const SmartInputBlock: React.FC<SmartInputBlockProps> = ({
  name,
  onChange,
  onChangeOverride,
  errors,
  value,
  debounceLength,
  saveOnDebounce = debounceLength !== undefined,
  type,
  otherErrorNames = [],
  onDebounce,
  prompt,
  style,
  width,
  className,
  fieldConfiguration,
  label,
  restrictValues,
  ignoreFieldConfiguration,
  colProps,
  ...otherProps
}) => {
  const { formErrors, handleChange, debounceTextInputs } = useFormState();
  const { setFieldConfiguration } = useFormHelpers();
  const [formValue, setFormValue] = useFormStateValue(name);

  // for save on debounce
  const [currentValue, setCurrentValue] = useState(formValue);
  const debouncedValue = useDebounce(currentValue, debounceLength || 200);
  const shouldSaveOnDebounce =
    saveOnDebounce || (debounceTextInputs && isTextInput(type));
  const defaultWidth =
    isSizeableInput(type) && colProps === undefined ? 200 : undefined;

  const lowerName = name.toLowerCase();
  const allErrorNames = [
    lowerName,
    ...otherErrorNames.map((n) => n.toLowerCase()),
  ];
  const allErrors = [
    ...allErrorNames
      .map((n) => formErrors[n])
      .reduce((acc, val) => acc.concat(val), [])
      .filter((x) => x !== undefined),
  ];

  const changeValue = (e: React.ChangeEvent<HTMLInputElement> | any) => {
    onChange && onChange(e);

    if (onChangeOverride) {
      onChangeOverride(e);
    } else if (shouldSaveOnDebounce) {
      const value = getValueFromChangeEvent(e, type);
      setCurrentValue(value);
    } else {
      handleChange && handleChange(e, name, type);
    }
  };

  const changeHandler = (e: React.ChangeEvent<HTMLInputElement> | any) => {
    if (prompt) {
      if (window.confirm(prompt)) {
        changeValue(e);
      }
    } else {
      changeValue(e);
    }
  };

  useEffect(() => {
    if (shouldSaveOnDebounce && debouncedValue !== formValue) {
      setFormValue(debouncedValue);

      onDebounce && onDebounce(debouncedValue);
    }
  }, [debouncedValue]);

  // for edits - async loaded values need to be
  // set for debounced inputs
  useEffect(() => {
    if (shouldSaveOnDebounce && !isEqual(currentValue, formValue)) {
      setCurrentValue(formValue);
    }
  }, [formValue]);

  useEffect(() => {
    if (!ignoreFieldConfiguration) {
      setFieldConfiguration({
        name,
        type,
        label,
        restrictValues,
        ...fieldConfiguration,
      });
    }
  }, []);

  return (
    <InputBlock
      name={name}
      onChange={changeHandler}
      errors={allErrors}
      type={type}
      value={shouldSaveOnDebounce ? currentValue : formValue}
      style={{
        width: width || defaultWidth,
        ...style,
      }}
      className={fullClassName(
        className,
        toggledClass('width-set', width !== undefined)
      )}
      width={width}
      label={label}
      restrictValues={restrictValues}
      colProps={colProps}
      {...otherProps}
    />
  );
};
