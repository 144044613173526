import {
  GridCellProps,
  GridColumn as Column,
} from '@progress/kendo-react-grid';
import {
  DateCell,
  EditDeleteScheduleCell,
  FilterBarMainGrid,
  LinkCell,
} from 'Components/Grid';
import { FormProvider, GridProvider, useEnums } from 'Context';
import {
  defaultGridProps,
  FilterBarFilterType,
  FilterConfig,
  IFilterConfig,
  StaticFilterConfig,
} from 'Models';
import { Permission } from 'Models/Templates/Permission/Permission';
import { ENDPOINTS } from 'Models/Templates/Routes/Routes';
import {
  addEditRoute,
  AddEditSubRoute,
  ClaimantsSubRoute,
  ClaimsSubRoute,
  ReferralSubRoute,
  ROUTES,
} from 'Navigation';
import React, { Fragment, HTMLProps, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem, Col, Row } from 'reactstrap';
import { EMPTY_GUID, buildEndpoint, useGridSearch } from 'Utilities';
import { LightPageHeader } from '../../Components/Display';
import Warning from '@mui/icons-material/Warning';
import { Tooltip } from '@mui/material';
import { DefaultColumnsSelection } from 'Models/ColumnSelection';
import { ColumnSelectorProvider, useColumnSelectorState } from 'Context/ColumnSelectorProvider';

export const Appointments: React.FC = () => {
  return (
    <Fragment>
      <Breadcrumb tag="nav" listTag="div">
        <BreadcrumbItem>
          <Link to={ROUTES.DASHBOARD}>Home</Link>
        </BreadcrumbItem>
        <BreadcrumbItem>Pending Appointments</BreadcrumbItem>
      </Breadcrumb>
      <LightPageHeader tall noBorder className="page-header-shadow">
        <h1>Pending Appointments</h1>
      </LightPageHeader>
      <br />
      <Row>
        <Col></Col>
      </Row>
      <AppointmentsFilteredGrid id={EMPTY_GUID} />
    </Fragment>
  );
};

class AppointmentSearch {
  searchKey?: string;
  referralId?: string;
  client?: string;
  benefitState?: string;
}

export enum AppointmentsGridIds {
  DetailedSearch = 'detailedSearch',
}

export type AppointmentsFilteredGridProps =
  HTMLProps<AppointmentsFilteredGridProps> & {
    id?: string;
  };

export const AppointmentsFilteredGrid: React.FC<
  AppointmentsFilteredGridProps
> = ({ id, ...props }) => {
  const appointmentSearch = new AppointmentSearch();
  appointmentSearch.referralId = id;
  return (
    <FormProvider formModel={appointmentSearch}>
      <ColumnSelectorProvider
        columns={DefaultColumnsSelection}
        columnSelectorName='appointmentColumns'
      >
        <GridProvider entityName="Appointment">
          <AppointmentsGrid id={id} />
        </GridProvider>
      </ColumnSelectorProvider>
    </FormProvider>
  );
};

export const AppointmentsGrid: React.FC<AppointmentsFilteredGridProps> = ({
  id,
  ...props
}) => {
  const { state } = useEnums();
  const columnSelectorState = useColumnSelectorState()

  const [ exportEndpoint, setExportEndpoint ] = useState(buildEndpoint(
    ENDPOINTS.appointments.endpointString('ExportToCsv') + `${getSelectedColumnsQuery(columnSelectorState)}`
  ));

  useEffect(() => {
    setExportEndpoint(buildEndpoint(
      ENDPOINTS.appointments.endpointString('ExportToCsv') + `${getSelectedColumnsQuery(columnSelectorState)}`
    ));
  }, [columnSelectorState]);

  const filters: IFilterConfig[] = [
    new FilterConfig(
      'searchKey',
      FilterBarFilterType.SearchText,
      'Stradix Number, Claim Number, Claimant, Referral Number, Doctor'
    ),
    new FilterConfig(
      'clients',
      FilterBarFilterType.AsyncDropdown,
      'Client',
      {
        readEndpoint: ENDPOINTS.clients.dropdownItems
      },
      (value) => value.name,
      true
    ),
    new FilterConfig(
      'benefitStates',
      FilterBarFilterType.EnumDropdown,
      'Benefit State',
      { data: state },
      (value => value.displayName),
      true
    ),
    new FilterConfig(
      'appointmentDate',
      FilterBarFilterType.DatePicker,
      'Appointment Date'
    ),
    new FilterConfig('referralDateFrom', FilterBarFilterType.DatePicker, 'Referral Date From'),
    new FilterConfig('referralDateTo', FilterBarFilterType.DatePicker, 'Referral Date To'),
    new StaticFilterConfig('referralId'),
  ];
  const [gridToggler] = useGridSearch();

  let endpoint = ENDPOINTS.appointments.read;

  return (
    <React.Fragment>
      <FilterBarMainGrid
        isMainGrid
        filterBarProps={{
          filterConfigurations: filters,
          filterValues: { referralId: gridToggler.referralId },
          isColumnSelectorEnabled: true,
        }}
        refreshToggle={gridToggler}
        readEndpoint={endpoint}
        exportEndpoint={exportEndpoint}
        enableGridRefresh={true}
        {...defaultGridProps}
      >
        <Column
          field='rejected'
          title={' '}
          width='50'
          cell={RejectionCell}
        />
        
        { columnSelectorState.columns.find(x => x.field === 'stradixNumber')?.checked && <Column field="stradixNumber" cell={ReferralNumberCell} title="Stradix Number" width='auto' />}
        { columnSelectorState.columns.find(x => x.field === 'clientName')?.checked && <Column field="clientName" title="Client" />}
        { columnSelectorState.columns.find(x => x.field === 'referralDate')?.checked && <Column field="referralDate" title="Referral Date" cell={dateFormatCell} />}
        { columnSelectorState.columns.find(x => x.field === 'reportDueDate')?.checked && <Column field="reportDueDate" title="Report Due Date" cell={dateFormatCell} />}
        { columnSelectorState.columns.find(x => x.field === 'referralDueDate')?.checked && <Column field="referralDueDate" title="Client Due Date" cell={dateFormatCell} />}
        { columnSelectorState.columns.find(x => x.field === 'claimantName')?.checked && <Column field="claimantName" title="Claimant Name" cell={ClaimantCell} />}
        { columnSelectorState.columns.find(x => x.field === 'claimNumber')?.checked && <Column field="claimNumber" title="Claim Number" cell={ClaimCell} />}
        { columnSelectorState.columns.find(x => x.field === 'benefitState')?.checked && <Column field="benefitState" title="Benefit State" />}
        { columnSelectorState.columns.find(x => x.field === 'referralNumber')?.checked && <Column field="referralNumber" title="Referral Number" cell={ReferralNumberCell} />}
        { columnSelectorState.columns.find(x => x.field === 'status')?.checked && <Column field="appointmentStatus.displayName" title="Status" />}
        { columnSelectorState.columns.find(x => x.field === 'typeOfEval')?.checked && <Column field="typeOfEval" title="Type of EVAL" />}
        { columnSelectorState.columns.find(x => x.field === 'appointmentDate')?.checked && <Column field="appointmentDate" title="Appointment Date" cell={dateFormatCell} />}
        { columnSelectorState.columns.find(x => x.field === 'treatingPhysician')?.checked && <Column field="treatingPhysician" title="Treating Physician" />}
        { columnSelectorState.columns.find(x => x.field === 'physicianSpecialty')?.checked && <Column field="physicianSpecialty" title="Physician Specialty" />}
        { columnSelectorState.columns.find(x => x.field === 'insuranceCarrier')?.checked && <Column field="insuranceCarrier" title="Insurance Carrier" />}
        { columnSelectorState.columns.find(x => x.field === 'referralSource')?.checked && <Column field="referralSource" title="Referral Source" />}
        { columnSelectorState.columns.find(x => x.field === 'adjusterName')?.checked && <Column field="adjusterName" title="Adjuster Name" />}
        { columnSelectorState.columns.find(x => x.field === 'doctor')?.checked && <Column field="professionalName" title="Doctor" />}
        { columnSelectorState.columns.find(x => x.field === 'closedDate')?.checked && <Column field="closedDate" title="Date Closed" cell={dateFormatCell} /> }
        { columnSelectorState.columns.find(x => x.field === 'checkMedicalsDate')?.checked && <Column field="checkMedicalsDate" title="Check Medicals Date" cell={dateFormatCell} /> }
        <Column field="id" width="120" title="Actions" cell={ActionsCell} />
      </FilterBarMainGrid>
    </React.Fragment >
  );
};

const ReferralNumberCell: React.FC<GridCellProps> = (props: GridCellProps) => {
  return (
    <LinkCell
      endpoint={ROUTES.REFERRAL}
      subRoute={ReferralSubRoute.View}
      linkField="referralId"
      {...props}
    ></LinkCell>
  );
};


const ClaimantCell: React.FC<GridCellProps> = (props: GridCellProps) => {
  return (
    <LinkCell
      endpoint={ROUTES.CLAIMANT}
      subRoute={ClaimantsSubRoute.View}
      linkField="claimantId"
      {...props}
    ></LinkCell>
  );
};

const ClaimCell: React.FC<GridCellProps> = (props: GridCellProps) => {
  return (
    <LinkCell
      endpoint={ROUTES.CLAIM}
      subRoute={ClaimsSubRoute.View}
      linkField="claimId"
      {...props}
    ></LinkCell>
  );
};

const ActionsCell: React.FC<GridCellProps> = (props: GridCellProps) => {
  return (
    <EditDeleteScheduleCell
      editRoute={addEditRoute(AddEditSubRoute.Appointment)}
      editPermissions={[Permission.EditAppointment]}
      deleteEndpoint={ENDPOINTS.appointments.base}
      deletePermissions={[Permission.DeleteAppointment]}
      scheduleRoute={addEditRoute(AddEditSubRoute.Appointment)}
      schedulePermissions={[Permission.EditAppointment]}
      {...props}
    />
  );
};

const RejectionCell: React.FC<GridCellProps> = (props: GridCellProps) => {
  return (
    <td>
      <div className="flex-vertical-right flex-end">
        <span>
          {props.dataItem.rejected ? (
            <>

              <Tooltip title={`Rejected by ${props.dataItem.rejectedBy} - ${props.dataItem.rejectionReason}`} placement="right">
                <Warning />
              </Tooltip>
            </>
          ) : null}
        </span>
      </div>
    </td>
  );
};

const dateFormatCell: React.FC<GridCellProps> = (props: GridCellProps) => {
  return <DateCell {...props} dateFormat={'MM/DD/YYYY'} />;
};

const getSelectedColumnsQuery = (columnSelectorState: any) => {
  const selectedColumns = columnSelectorState.columns.filter((x: any) => x.checked).map((x: any) => x.field);
  return '?' + selectedColumns.map((x: any) => `columns=${x}`).join('&');
}