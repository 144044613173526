import { SecureComponent } from 'Components/Security';
import { NavConfigItem, useNavigation } from 'Navigation';
import React, { HTMLProps } from 'react';
import { NavigationPill } from '.';
import './NavigationSidebarTabs.scss';

export type NavigationSidebarTabsProps = HTMLProps<HTMLDivElement> & {
  config: NavConfigItem[];
  withNumbers?: boolean;
};

export const NavigationSidebarTabs: React.FC<NavigationSidebarTabsProps> = ({
  config,
  withNumbers,
  ...others
}) => {
  const { navConfig } = useNavigation(config);
  return (
    <div className={'navbar__content flex-normal'} {...others}>
      {navConfig.map((pill: NavConfigItem, index: any) => (
        <SecureComponent permissions={pill.permissions} key={index}>
          <NavigationPill {...pill} />
        </SecureComponent>
      ))}
    </div>
  );
};
