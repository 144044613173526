import * as React from 'react';
import { ColumnSelection } from 'Models/ColumnSelection';
import { useContext } from 'react';

export interface ColumnSelectorState {
  columns: ColumnSelection[];
  columnSelectorName?: string;
}

const ColumnSelectorStateContext = React.createContext<ColumnSelectorState>({ columns: [], columnSelectorName: ''});
const ColumnSelectorUpdateContext = React.createContext<(arg0: ColumnSelectorState) => void>(() => {});

export function useColumnSelectorState() {
  return useContext(ColumnSelectorStateContext);
}

export function useColumnSelectorUpdater() {
  return useContext(ColumnSelectorUpdateContext);
}

export type ColumnSelectorProviderProps = {
  columns?: ColumnSelection[];
  columnSelectorName?: string;
};

export const ColumnSelectorProvider: React.FC<ColumnSelectorProviderProps> = ({
  columns = [],
  columnSelectorName,
  children
}) => {
  
  const storageKey = `${columnSelectorName}`;
  
  const storedState = sessionStorage.getItem(storageKey);
  const initialState = storedState ? JSON.parse(storedState) : { columns, columnSelectorName };
  const [state, setState] = React.useState<ColumnSelectorState>(initialState);

  React.useEffect(() => {
    sessionStorage.setItem(storageKey, JSON.stringify(state));
  }, [storageKey, state]);

  const updateColumns = (newState: ColumnSelectorState) => {
    setState(newState);
  };

  return (
    <ColumnSelectorStateContext.Provider value={state}>
      <ColumnSelectorUpdateContext.Provider value={updateColumns}>
        {children}
      </ColumnSelectorUpdateContext.Provider>
    </ColumnSelectorStateContext.Provider>
  );
};
