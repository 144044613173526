import { ActionsDropdownConfig, DropdownType } from 'Models';
import React, { HTMLProps } from 'react';
import { fullClassName, toggledClass } from 'Utilities';
import { ActionsDropdown } from './ActionsDropdown';

export type HeadingProps = HTMLProps<HTMLHeadingElement> & {
  name?: string;
  noPaddingMargin?: boolean;
  titleButton?: any;
  titleActionsConfig?: ActionsDropdownConfig;
};

export const PageHeading: React.FC<HeadingProps> = ({
  name,
  children,
  className,
  noPaddingMargin,
  ...otherProps
}) => {
  return (
    <h4
      className={fullClassName(
        'color-primary-dark font-primary-bold  flex-vertical-center',
        toggledClass('mb-3', !noPaddingMargin),
        className
      )}
      {...otherProps}
    >
      {name ? name : children}
    </h4>
  );
};

export type PageSectionHeadingProps = HeadingProps & {
  leftTitleButton?: any;
  isLarge?: boolean;
};

export const PageSectionHeading: React.FC<PageSectionHeadingProps> = ({
  name,
  children,
  className,
  isLarge,
  titleActionsConfig,
  titleButton,
  noPaddingMargin,
  leftTitleButton,
  ...otherProps
}) => {
  const HeadingWrapper = isLarge ? 'h1' : 'h3';

  return (
    <div
      className={fullClassName(
        'full-width flex-vertical-center',
        toggledClass('mb-regular', !noPaddingMargin),
        className
      )}
    >
      <HeadingWrapper
        className={fullClassName(
          'color-primary-dark font-primary-bold flex-grow',
          toggledClass('flex-vertical-center', leftTitleButton !== undefined)
        )}
        {...otherProps}
      >
        {name ? name : children}
        {leftTitleButton}
      </HeadingWrapper>
      {titleActionsConfig && (
        <ActionsDropdown
          type={DropdownType.Solid}
          isSmall={!isLarge}
          useButtonPadding
          {...titleActionsConfig}
        />
      )}
      {titleButton}
    </div>
  );
};

export const FormSectionHeading: React.FC<HeadingProps> = ({
  name,
  children,
  className,
  noPaddingMargin,
  ...otherProps
}) => {
  return (
    <h4
      className={fullClassName(
        'color-primary-dark-accent',
        toggledClass('mb-3', !noPaddingMargin),
        className
      )}
      {...otherProps}
    >
      {name ? name : children}
    </h4>
  );
};

export const SmallSectionHeading: React.FC<HeadingProps> = ({
  name,
  children,
  className,
  noPaddingMargin,
  ...divProps
}) => {
  return (
    <div
      className={fullClassName(
        'font-secondary-bold font-size-regular flex-vertical-center',
        toggledClass('mb-small', !noPaddingMargin),
        className
      )}
      {...divProps}
    >
      {name}
      {children}
    </div>
  );
};
