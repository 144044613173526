import "./CustomRadioGroup.scss";
import React from "react";
import { RadioGroup, RadioGroupProps } from "@progress/kendo-react-inputs";
import { CoreEnum, NamedCoreEnum } from "Models";

export type CustomRadioGroupProps = RadioGroupProps & {
  data: NamedCoreEnum;
  value: CoreEnum;
};

export const CustomRadioGroup: React.FC<CustomRadioGroupProps> = ({
  data,
  value,
  ...radioProps
}) => {
  const enumList = Object.values(data).map((x) => {
    return { label: x.displayName, value: x.value };
  });

  return (
    <div className="radio-wrapper">
      <RadioGroup
        data={enumList}
        value={value ? value?.value : null}
        {...radioProps}
      />
    </div>
  );
};

export type YesNoBooleanRadioGroupProps = RadioGroupProps & {
  value: number | null;
};

export const YesNoBooleanRadioGroup: React.FC<YesNoBooleanRadioGroupProps> = ({
  data,
  value,
  ...radioProps
}) => {
  const yesNoData = [
    { label: "Yes", value: true },
    { label: "No", value: false },
  ];

  return (
    <div className="radio-wrapper">
      <RadioGroup data={yesNoData} value={value} {...radioProps} />
    </div>
  );
};
