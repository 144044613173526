import React from "react";
import ColumnIcon from '@mui/icons-material/ViewColumn';
import { Col } from "reactstrap";
import { makeStyles } from '@mui/styles';
import { InputType } from "Models";
import { InputBlock } from "Components/Form";
import { CheckboxChangeEvent } from "@progress/kendo-react-inputs";
import { useColumnSelectorState, useColumnSelectorUpdater } from "Context/ColumnSelectorProvider";

export type ColumnSelectorProps = {};

export const ColumnSelector: React.FC<ColumnSelectorProps> = ({ ...filterBarProps }) => {
  const columnSelectorState = useColumnSelectorState();
  const columnSelectorUpdater = useColumnSelectorUpdater();
  const columns = columnSelectorState.columns;

  const useStyles = makeStyles({
    pageHeader: {
      justifyContent: 'center',
      display: 'flex',
    },
    col: {
      width: '100%',
    },
    checksDiv: {
      margin: 5,
    },
    customChecks: {
      padding: 0,
      margin: 5,
    },
  });
  const classes = useStyles();
  const gridColumns = [1, 2, 3, 4, 5, 6, 7];

  return (
    <>
      <div className="collapsible-filter-bar">
        <input type="checkbox" id="collapsible-toggle-columns" className="collapsible-toggle" />
        <label htmlFor="collapsible-toggle-columns" className="collapsible-label">
          <ColumnIcon className="filter-bar-search-icon" />&nbsp;
          <b>Columns Selection</b>&nbsp;&nbsp;
          <span className="icon"></span>
        </label>
        <div className={`collapsible-content ${classes.pageHeader}`}>
          {gridColumns.map((column) => {
            return (
              <div className={classes.checksDiv} key={`column_${column}`}>
                <Col className={classes.col}>
                  {columns
                    .filter((x) => x.columnNumber === column)
                    .map((field, index) => {
                      return (
                        <InputBlock
                          key={field.index}
                          name={`selectedIndexes[${field.index}]`}
                          label={field.label}
                          type={InputType.Checkbox}
                          className={classes.customChecks}
                          value={field.checked}
                          onChange={(e: CheckboxChangeEvent) => {
                            const newColumns = columns.map((x) => {
                              if (x.field === field.field) {
                                return { ...x, checked: e.value };
                              }
                              return x;
                            });
                            columnSelectorUpdater({...columnSelectorState, columns: newColumns });
                          }}
                        />
                      );
                    })}
                </Col>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};