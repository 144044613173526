import {
  GridProvider,
  useGridState,
  useMultiGridHelpers,
  useMultiGridState,
} from 'Context';
import { isEqual } from 'lodash';
import { SUBROUTES_MAP, useSubNavigation, useSubRoutes } from 'Navigation';
import React, {
  Fragment,
  HTMLProps,
  ReactNode,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Link } from 'react-router-dom';
import {
  buildRoute,
  fullClassName,
  isNullOrUndefined,
  toggledClass,
} from 'Utilities';
import { HideableWrapper } from './LayoutComponents';
import './TabStrip.scss';

export interface TabConfig {
  label: any;
  value: any;
  hidden?: boolean;
  url?: string;
  isActive?: boolean;
}

export enum TabStripType {
  Large = 'large',
  Small = 'small',
}

export type TabStripProps = HTMLProps<HTMLDivElement> & {
  tabs: TabConfig[];
  type?: TabStripType;
  onTabChange?: (value?: any) => void;
};

export const TabStrip: React.FC<TabStripProps> = ({
  tabs,
  type,
  onTabChange,
  className,
  ...wrapperProps
}) => {
  const filteredTabs = tabs.filter((t) => !t.hidden);
  const [activeTab, setActiveTab] = useState<TabConfig | undefined>(
    filteredTabs.find((t) => !isNullOrUndefined(t.value))
  );

  const getTabStripClass = () => {
    switch (type) {
      case TabStripType.Large:
        return 'tab-strip-lg';
      case TabStripType.Small:
      default:
        return 'tab-strip-sm';
    }
  };

  useEffect(() => {
    onTabChange && onTabChange(activeTab?.value);
  }, [activeTab]);

  return (
    <div
      className={fullClassName('tab-strip', getTabStripClass(), className)}
      {...wrapperProps}
    >
      {filteredTabs.map((tab, index) => (
        <TabStripTab
          key={index}
          isActive={tab.isActive || isEqual(tab.value, activeTab?.value)}
          onClick={() => setActiveTab(tab)}
          {...tab}
        />
      ))}
    </div>
  );
};

export type TabStripTabProps = TabConfig & {
  isActive: boolean;
  onClick: () => void;
};

export const TabStripTab: React.FC<TabStripTabProps> = ({
  url,
  ...tabProps
}) => {
  return url ? (
    <Link className="full-height" to={url}>
      <TabStripTabContent {...tabProps} />
    </Link>
  ) : (
    <TabStripTabContent {...tabProps} />
  );
};

export const TabStripTabContent: React.FC<TabStripTabProps> = ({
  isActive,
  label,
  onClick,
}) => {
  return (
    <button
      className={fullClassName(
        'tab-strip-tab',
        toggledClass('active', isActive)
      )}
      onClick={onClick}
    >
      {label}
      <div className="active-underline" />
    </button>
  );
};

export type NavigationTabStripProps = Partial<TabStripProps> & {
  parentRoute: string;
};

export const NavigationTabStrip: React.FC<NavigationTabStripProps> = ({
  parentRoute,
  ...props
}) => {
  const navConfig = useSubNavigation(parentRoute);
  const mapSubRoutes = useSubRoutes();

  const tabs = navConfig.map(({ label, route, showInDropdown, active }) => {
    return {
      label,
      value: route,
      hidden: showInDropdown,
      url: buildRoute(parentRoute, route),
      isActive: active,
    } as TabConfig;
  });

  return (
    <Fragment>
      <TabStrip {...props} tabs={tabs} className="mb-regular" />
      {mapSubRoutes(SUBROUTES_MAP[parentRoute])}
    </Fragment>
  );
};

export type GridTabConfig = {
  gridId: string;
  title: string;
  content: ReactNode;
};

export type MultiGridTabStripProps = Partial<TabStripProps> & {
  gridTabConfigs: GridTabConfig[];
  showGridTotals?: boolean;
};

export const MultiGridTabStrip: React.FC<MultiGridTabStripProps> = ({
  gridTabConfigs,
  showGridTotals,
  className,
  onTabChange: propsOnTabChange,
  ...tabStripProps
}) => {
  const { grids } = useMultiGridState();
  const [currentTab, setCurrentTab] = useState(gridTabConfigs[0].gridId);
  const tabs: TabConfig[] = gridTabConfigs.map(({ gridId, title }) => {
    return {
      label: `${title}${
        showGridTotals ? ` (${grids?.[gridId]?.total || 0})` : ''
      }`,
      value: gridId,
    };
  });

  const onTabChange = (tabValue: string) => {
    propsOnTabChange && propsOnTabChange(tabValue);
    setCurrentTab(tabValue);
  };

  return (
    <Fragment>
      <TabStrip
        {...tabStripProps}
        onTabChange={onTabChange}
        className={fullClassName(className, 'mb-regular')}
        tabs={tabs}
      />
      <MultiGridTabContentRenderer
        gridTabConfigs={gridTabConfigs}
        currentTab={currentTab}
      />
    </Fragment>
  );
};

export type MultiGridTabContentRendererProps = {
  gridTabConfigs: GridTabConfig[];
  currentTab: string;
};

export const MultiGridTabContentRenderer: React.FC<MultiGridTabContentRendererProps> =
  ({ gridTabConfigs, currentTab }) => {
    return (
      <Fragment>
        {gridTabConfigs?.map((config) => (
          <HideableWrapper
            key={config.gridId}
            isHidden={currentTab !== config.gridId}
          >
            <GridProvider>
              <MultiGridTabContent {...config} />
            </GridProvider>
          </HideableWrapper>
        ))}
      </Fragment>
    );
  };

export const MultiGridTabContent: React.FC<GridTabConfig> = ({
  gridId,
  content,
}) => {
  const gridState = useGridState();
  const memoizedGridState = useMemo(() => {
    return gridState;
  }, [
    gridState.condition,
    gridState.data,
    gridState.total,
    gridState.search,
    gridState.entityName,
    gridState.activeItem,
    gridState.refreshValue,
  ]);

  const { grids } = useMultiGridState();
  const { registerGrid } = useMultiGridHelpers();

  useEffect(() => {
    if (!isEqual(grids?.[gridId], gridState)) {
      registerGrid(gridId, gridState);
    }
  }, [memoizedGridState]);

  return <Fragment>{content}</Fragment>;
};
