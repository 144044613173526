import { User } from './SharedModels';

export class AuditReport{
    constructor(id?: string){
        this.appointmentReportBundleId = id;
    }
    appointmentReportBundleId?: string;
    title?: string;
    physicianReviewer?: User;
    lastQaDate?: Date;
    p2PComp?: number;
    p2PDoc?: number;
    summary?: number;
    rationale?: number;
    guidelines?: number;
    useOfGuides?: number;
    appeal?: number;
    formatting?: number;
    notes?: string;

}