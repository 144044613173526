import React from "react";
import "swagger-ui-react/swagger-ui.css";
import SwaggerUI from "swagger-ui-react";
import { useGlobalState } from "Context";
import { buildEndpoint, buildRoute } from "Utilities";
import { ENDPOINTS } from "Models/Templates/Routes/Routes";

export const Swagger: React.FC = () => {
  const { baseUrl } = useGlobalState();

  return (
    <SwaggerUI
      url={baseUrl + buildEndpoint(ENDPOINTS.swaggerv1.base, "swagger.json")}
    />
  );
};
