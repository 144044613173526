import './ActionsDropdown.scss';
import React, { Fragment, HTMLProps } from 'react';
import {
  ActionsDropdownConfig,
  ActionsDropdownItem,
  DropdownType,
  DropdownItemModalConfig,
} from 'Models';
import {
  buildRoute,
  fullClassName,
  isNullEmptyOrUndefined,
  toggledClass,
  useToggler,
} from 'Utilities';
import {
  Dropdown,
  DropdownItem,
  DropdownItemProps,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';
import {
  PrimaryButton,
  SecondaryButton,
  TextIconButton,
} from 'Components/Form';
import { Link, useRouteMatch } from 'react-router-dom';
import { SecureComponent } from 'Components/Security';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Render } from './Render';
import { DropdownArrowIcon, RightArrowIcon } from './KendoIcons';
import {
  LocalStateProvider,
  MultiModalProvider,
  useLocalState,
  useModalHelpers,
} from 'Context';
import { flatMap } from 'lodash';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { EnumTypeSample } from 'Navigation';

export type ActionsDropdownProps = ActionsDropdownConfig &
  HTMLProps<HTMLDivElement> & {
    isSmall?: boolean;
    useButtonPadding?: boolean;
  };

type DropdownLocalState = {
  caseType?: EnumTypeSample;
};

export const ActionsDropdown: React.FC<ActionsDropdownProps> = ({
  items,
  caseType,
  className,
  useButtonPadding,
  ...otherProps
}) => {
  const [isOpen, toggleIsOpen] = useToggler(false);

  return (
    <MultiModalProvider>
      <MultiModalRenderer items={items} />
      <SecureComponent
        permissions={items
          .map((x) => x.permissions || [])
          .reduce((acc, val) => acc.concat(val), [])}
      >
        <Dropdown isOpen={isOpen} toggle={toggleIsOpen} className={className}>
          <DropdownToggle
            className={fullClassName(
              'bg-transparent border-none actions-dropdown-toggle',
              toggledClass('button-padding', useButtonPadding)
            )}
            tag={'div'}
          >
            <ActionsDropdownButton {...otherProps} />
          </DropdownToggle>
          <DropdownMenu
            className="animate-fade-in card-shadow-dark color-secondary actions-dropdown"
            modifiers={{ preventOverflow: { boundariesElement: 'window' } }}
            right
          >
            <ActionDropdownItemsList items={items} />
          </DropdownMenu>
        </Dropdown>
      </SecureComponent>
    </MultiModalProvider>
  );
};

export type MultiModalRendererProps = {
  items: ActionsDropdownItem[];
};

export const MultiModalRenderer: React.FC<MultiModalRendererProps> = ({
  items,
}) => {
  return (
    <Fragment>
      {items.map(({ modalConfig, childItems }, index) => (
        <Fragment key={modalConfig?.modalId || index}>
          {modalConfig && modalConfig.modalComponent}
          {childItems?.length && <MultiModalRenderer items={childItems} />}
        </Fragment>
      ))}
    </Fragment>
  );
};

type CustomActionsDropdownProps = ActionsDropdownProps & {
  children: any;
};

export const CustomActionsDropdown: React.FC<CustomActionsDropdownProps> = ({
  children,
  className,
  ...otherProps
}) => {
  const [isOpen, toggleIsOpen] = useToggler(false);

  return (
    <MultiModalProvider>
      <Dropdown isOpen={isOpen} toggle={toggleIsOpen}>
        <DropdownToggle
          className={fullClassName(
            'bg-transparent border-none actions-dropdown-toggle',
            className
          )}
          tag={'div'}
        >
          <ActionsDropdownButton {...otherProps} />
        </DropdownToggle>
        <DropdownMenu
          className="animate-fade-in card-shadow color-secondary actions-dropdown"
          modifiers={{ preventOverflow: { boundariesElement: 'window' } }}
          right
        >
          {children}
        </DropdownMenu>
      </Dropdown>
    </MultiModalProvider>
  );
};

export type ActionsDropdownButtonProps = Partial<ActionsDropdownProps>;

export const ActionsDropdownButton: React.FC<ActionsDropdownButtonProps> = ({
  type,
  name,
  isSmall,
}) => {
  const smallClass = isSmall ? 'small-button' : '';

  switch (type) {
    case DropdownType.Ellipsis:
      return <TextIconButton icon={MoreVertIcon} />;
    case DropdownType.Solid:
      return (
        <PrimaryButton className={fullClassName('solid-button', smallClass)}>
          {name}
          <DropdownArrowIcon className={fullClassName('ml-2 color-primary')} />
        </PrimaryButton>
      );
    case DropdownType.PrimarySolid:
      return (
        <PrimaryButton className={fullClassName(smallClass)}>
          {name}
          <DropdownArrowIcon className={fullClassName('ml-2')} />
        </PrimaryButton>
      );
    case DropdownType.PrimaryOutline:
      return (
        <SecondaryButton className={fullClassName(smallClass)}>
          {name}
          <DropdownArrowIcon className={fullClassName('ml-2')} />
        </SecondaryButton>
      );
    case DropdownType.Outline:
    default:
      return (
        <SecondaryButton className={fullClassName('color-light', smallClass)}>
          {name}
          <DropdownArrowIcon className={fullClassName('ml-2')} />
        </SecondaryButton>
      );
  }
};

export type ActionDropdownItemsListProps = {
  items: ActionsDropdownItem[];
};

export const ActionDropdownItemsList: React.FC<
  ActionDropdownItemsListProps
> = ({ items }) => {
  return (
    <Fragment>
      {items.map((item, index) => (
        <ActionsDropdownItemDisplay key={index} {...item} />
      ))}
    </Fragment>
  );
};

export const ActionsDropdownItemDisplay: React.FC<ActionsDropdownItem> = ({
  childItems = [],
  permissions = flatMap(childItems.map((c) => c.permissions || [])),
  condition = true,
  ...itemProps
}) => {
  return (
    <Render condition={condition}>
      <SecureComponent permissions={permissions}>
        <ActionsDropdownItemRenderer childItems={childItems} {...itemProps} />
      </SecureComponent>
    </Render>
  );
};

export const ActionsDropdownItemRenderer: React.FC<ActionsDropdownItem> = ({
  url: itemUrl,
  onClick,
  divWrapper,
  name,
  isUrlRelative,
  childItems = [],
  modalConfig,
  isDisabled,
  openInNewTab,
}) => {
  const ItemWrapper = divWrapper ? 'div' : DropdownItem;
  const hasChildItems = childItems.length > 0;
  const itemProps = divWrapper
    ? ({
        className: fullClassName('dropdown-item cursor-pointer'),
        disabled: isDisabled,
      } as HTMLProps<HTMLDivElement>)
    : ({
        disabled: isDisabled,
      } as DropdownItemProps);

  const { url } = useRouteMatch();
  const { setUpAdd, setUpEdit } = useModalHelpers(modalConfig?.modalId || name);

  const handleModalFormToggle = () => {
    if (modalConfig?.entityId) {
      setUpEdit(modalConfig.entityId, modalConfig.modalItem);
    } else {
      setUpAdd(modalConfig?.modalItem);
    }
  };

  return (
    <div className="position-relative dropdown-item-wrap full-width">
      {modalConfig ? (
        <ItemWrapper onClick={handleModalFormToggle} {...itemProps}>
          <DropdownItemContent name={name} hasChildItems={hasChildItems} />
        </ItemWrapper>
      ) : itemUrl === undefined ? (
        <ItemWrapper onClick={onClick} {...itemProps}>
          <DropdownItemContent name={name} hasChildItems={hasChildItems} />
        </ItemWrapper>
      ) : (
        <Link
          to={isUrlRelative ? buildRoute(url, itemUrl) : itemUrl}
          target={openInNewTab ? '_blank' : undefined}
        >
          <ItemWrapper {...itemProps}>
            <DropdownItemContent name={name} hasChildItems={hasChildItems} />
          </ItemWrapper>
        </Link>
      )}
      <Render condition={childItems.length > 0}>
        <div className="sub-dropdown animate-fade-in card-shadow-dark color-secondary actions-dropdown bg-light">
          <ActionDropdownItemsList items={childItems} />
        </div>
      </Render>
    </div>
  );
};

const DropdownItemContent: React.FC<{
  name: string;
  hasChildItems?: boolean;
}> = ({ name, hasChildItems }) => {
  return (
    <div
      className={fullClassName(
        'position-relative flex-vertical-center',
        toggledClass('pr-large', hasChildItems)
      )}
    >
      {name}
      {hasChildItems && (
        <ArrowRightIcon
          className="position-absolute"
          style={{ right: 0, fontSize: 20 }}
        />
      )}
    </div>
  );
};

export type ModalFormWrapperProps = {
  config: DropdownItemModalConfig;
};

const checkCaseTypes = (
  caseTypes: EnumTypeSample[] = [],
  caseType?: EnumTypeSample
) => {
  return caseTypes.length === 0 || (caseType && caseTypes.includes(caseType));
};
