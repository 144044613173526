import {
  GridCellProps,
  GridColumn as Column,
} from '@progress/kendo-react-grid';
import React, { HTMLProps } from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { LightPageHeader } from '../../Components/Display';
import { AddButton } from '../../Components/Form';
import {
  DateCell,
  EditDeleteCell,
  FilterBarMainGrid,
  GridButtonSection, LinkCell,
} from '../../Components/Grid';
import { SecureComponent } from '../../Components/Security';
import { FormProvider, GridProvider } from '../../Context';
import { Permission } from '../../Models/Templates/Permission/Permission';
import {
  AddEditSubRoute,
  ROUTES,
  addEditRoute,
} from '../../Navigation';
import {
  buildRoute,
  DEFAULT_DATETIME24H_FORMAT,
  EMPTY_GUID,
  useGridSearch,
  useHasPermissions,
} from '../../Utilities';
import { FilterBarFilterType, FilterConfig, IFilterConfig, defaultGridProps } from 'Models';
import { ENDPOINTS } from 'Models/Templates/Routes/Routes';

class ReferralsRequestSearch {
  searchKey?: string;
  id?: string;
}

export enum ReferralsRequestGridIds {
  DetailedSearch = 'detailedSearch',
}

export const ReferralsRequestsCommercial: React.FC = () => {
  return (
    <GridProvider entityName="Commercial Referral Request">
      <Breadcrumb tag="nav" listTag="div">
        <BreadcrumbItem>
          <Link to={ROUTES.DASHBOARD}>Home</Link>
        </BreadcrumbItem>
        <BreadcrumbItem>New Referrals</BreadcrumbItem>
      </Breadcrumb>
      <LightPageHeader tall noBorder className="page-header-shadow">
        <h1>New Commercial Referrals</h1>
      </LightPageHeader>
      <ReferralsRequestsFilteredGrid />
    </GridProvider>
  );
};

export type ReferralsRequestsFilteredGridProps =
  HTMLProps<ReferralsRequestsFilteredGridProps> & {
    id?: string;
  };

export const ReferralsRequestsFilteredGrid: React.FC<
  ReferralsRequestsFilteredGridProps
> = ({ id }) => {
  const referralSearch = new ReferralsRequestSearch();
  referralSearch.id = id;

  return (
    <FormProvider formModel={referralSearch}>
      <ReferralsRequestsGrid id={id} />
    </FormProvider>
  );
};

const ReferralsRequestsGrid: React.FC<ReferralsRequestsFilteredGridProps> = ({
  id}) => {
  const [gridToggler] = useGridSearch();
  const endpoint = ENDPOINTS.referralsRequestsCommercial.read;
  const filters: IFilterConfig[] = [
    new FilterConfig(
      'searchKey',
      FilterBarFilterType.SearchText,
      'Search by Stradix Number'
    )
  ];

  return (
    <React.Fragment>
      <SecureComponent permissions={[Permission.AddReferralsRequestCommercial]}>
        <GridButtonSection>
          <AddButton
            url={buildRoute(
              ROUTES.ADD_EDIT,
              AddEditSubRoute.ReferralsRequestCommercial,
              EMPTY_GUID,
              id
            )}
          >
            New Referral Request
          </AddButton>
        </GridButtonSection>
      </SecureComponent>

      <FilterBarMainGrid
        isMainGrid
        filterBarProps={{
          filterValues: {},
          filterConfigurations: filters
        }}
        refreshToggle={gridToggler}
        readEndpoint={endpoint}
        {...defaultGridProps}
        >
        <Column field="stradixNumber" title="Stradix Number" />
        <Column field="name" title="Claimant Name" />
        <Column field="clientName" title="Client" />
        <Column field="service" title="Service" />
        <Column field="claimNumber" title="Claim Number" />
        <Column field="reviewerSpeciality" title="Requested Specialty" />
        <Column field="dueDate" title="Client Due Date" cell={dotNetDateColumn} />
        <Column field="referralDate" title="Referral Date" cell={dotNetDateColumn} />
        <Column field="statusName" title="Status" cell={ConvertCell}/>
        <Column field="id" width="80" title="Actions" cell={ActionsCellEdit} /> 
      </FilterBarMainGrid>
    </React.Fragment>
  );
};

const dotNetDateColumn: React.FC<GridCellProps> = (props: GridCellProps) => {
  return <DateCell {...props} dateFormat={DEFAULT_DATETIME24H_FORMAT} />;
};

const ConvertCell: React.FC<GridCellProps> = (props: GridCellProps) => {
  const hasPermission = useHasPermissions();
  const status = props.dataItem?.status;
  const convertText = (text: string) => 'Convert to Claimant';

  let content;

  if (
    status === 'Pending' &&
    hasPermission([Permission.ConvertReferralRequest])
  ) {
    content = (
      <LinkCell
        {...props}
        endpoint={buildRoute(ROUTES.REFERRALREQUESTPROPOSALSCOMMERCIAL)}
        linkField="id"
        field="status"
        convertFunction={convertText}
      >
        Convert to Claimant
      </LinkCell>
    );
  } else {
    content = <td>{status}</td>;
  }

  return content;
};


const ActionsCellEdit: React.FC<GridCellProps> = (props: GridCellProps) => {
  return (
      <EditDeleteCell
        editRoute={addEditRoute(AddEditSubRoute.ReferralsRequestCommercial)}
        editPermissions={[Permission.EditReferralsRequest]}
        deleteEndpoint={ENDPOINTS.referralsRequests.base}
        deletePermissions={[Permission.DeleteReferralsRequest]}
        {...props}
      ></EditDeleteCell>
  );
};