import React, { Fragment } from "react";
import { useSubRoutes, SUBROUTES_MAP, ROUTES } from "Navigation";

export const UserManagementMain: React.FC = () => {
  const mapSubRoutes = useSubRoutes();

  return (
    <Fragment>{mapSubRoutes(SUBROUTES_MAP[ROUTES.USER_MANAGEMENT])}</Fragment>
  );
};
