import React, { Fragment } from 'react';
import { GridProvider, ModalFormProvider, useEntity } from 'Context';
import {
  ReviewerDocumentModal,
  ReviewerDocumentModel,
  ReviewerIdGridSearchModel,
} from 'Models/ReviewerFormDocumentModel';
import { ENDPOINTS } from 'Models/Templates/Routes/Routes';
import {
  ActionsDropdownConfig,
  defaultGridProps,
  GridColumnWidth,
  ReviewerBoardCertificationDocumentModal,
  ReviewerBoardCertificationDocumentModel,
  ReviewerIdBoardCertificationGridSearchModel,
} from 'Models';
import { Permission } from 'Models/Templates/Permission/Permission';
import { PageSectionHeading } from 'Components/Display';
import {
  AsyncGrid,
  DateCell,
  FileDownloadCell,
  getFormattedDisplayCell,
} from 'Components/Grid';
import { GridCellProps, GridColumn } from '@progress/kendo-react-grid';
import { ReviewerDocumentDrawerForm } from './ReviewerDocumentDrawerForm';
import { ReviewerDocumentActionCell } from './ReviewerDocumentActionCell';
import {
  ReviewerIdLicenseGridSearchModel,
  ReviewerLicenseDocumentModal,
  ReviewerLicenseDocumentModel,
} from 'Models/ReviewerLicenseDocumentModel';
import {
  ReviewerBoardCertificationDocumentActionCell,
  ReviewerBoardCertificationDocumentDrawerForm,
  ReviewerLicenseDocumentDrawerForm,
  ReviewerLicenseDocumentsActionCell,
} from '.';

export type ReviewerDocumentProps = {
  reviewerId?: string;
};

export const ReviewerDocumentList: React.FC<ReviewerDocumentProps> = ({
  reviewerId,
}) => {
  return (
    <Fragment>
      <GridProvider entityName="Reviewer Forms Documents">
        <ReviewerDocumentModalWrapper reviewerId={reviewerId} />
      </GridProvider>
    </Fragment>
  );
};

const ReviewerDocumentModalWrapper: React.FC<ReviewerDocumentProps> = ({
  reviewerId,
}) => {
  var formModel = new ReviewerDocumentModel(reviewerId);

  return (
    <ModalFormProvider
      formModel={formModel}
      endpoint={ENDPOINTS.reviewercredentialingforms.base}
    >
      <ReviewerDocumentGridWithModalDrawer reviewerId={reviewerId} />
    </ModalFormProvider>
  );
};

const ReviewerDocumentGridWithModalDrawer: React.FC<ReviewerDocumentProps> = ({
  reviewerId,
}) => {
  const searchModel: ReviewerIdGridSearchModel = new ReviewerIdGridSearchModel(
    reviewerId
  );

  var formModel = new ReviewerDocumentModel(reviewerId);

  const actionsConfig: ActionsDropdownConfig = {
    name: 'Actions',
    items: [
      {
        name: 'Upload Form/Document',
        permissions: [Permission.UploadReviewerCredentialingForm],
        modalConfig: {
          modalId: ReviewerDocumentModal.Documents,
          modalComponent: (
            <ReviewerDocumentDrawerForm
              endpoint={ENDPOINTS.reviewercredentialingforms.base}
              formModel={formModel}
              reviewerId={reviewerId}
            />
          ),
        },
      },
    ],
  };

  return (
    <Fragment>
      <PageSectionHeading titleActionsConfig={actionsConfig}>
        Forms Or Documents
      </PageSectionHeading>
      <AsyncGrid
        readEndpoint={ENDPOINTS.reviewercredentialingforms.read}
        search={searchModel}
        withCardWrapper
        {...defaultGridProps}
      >
        <GridColumn field="title" title="Title" />
        <GridColumn
          field="fileName"
          title="File Name"
          cell={FileNameDownloadCell}
          width={GridColumnWidth.MedLarge}
        />
        <GridColumn
          field="size"
          title="File Size"
          cell={getFormattedDisplayCell('fileSizeDisplay')}
        />
        <GridColumn
          field="uploadDate"
          title="Date Uploaded"
          cell={getFormattedDisplayCell('uploadDateDisplay')}
        />
        <GridColumn field="uploadedBy" title="Uploaded By" />
        <GridColumn
          field="id"
          title=" "
          cell={(props: GridCellProps) => (
            <ReviewerDocumentActionCell {...props} />
          )}
          width={GridColumnWidth.Ellipsis}
        />
      </AsyncGrid>
    </Fragment>
  );
};

const FileNameDownloadCell: React.FC<GridCellProps> = ({ ...props }) => {
  return (
    <FileDownloadCell
      fileControllerEndpoint={ENDPOINTS.reviewercredentialingforms.base}
      {...props}
    />
  );
};

const FileNameLicenseDownloadCell: React.FC<GridCellProps> = ({ ...props }) => {
  return (
    <FileDownloadCell
      fileControllerEndpoint={ENDPOINTS.reviewerLicenseDocuments.base}
      {...props}
    />
  );
};

export const ReviewerLicenseDocumentList: React.FC<ReviewerDocumentProps> = ({
  reviewerId,
}) => {
  return (
    <Fragment>
      <GridProvider entityName="State License Number">
        <ReviewerLicenseDocumentModalWrapper reviewerId={reviewerId} />
      </GridProvider>
    </Fragment>
  );
};

const ReviewerLicenseDocumentModalWrapper: React.FC<ReviewerDocumentProps> = ({
  reviewerId,
}) => {
  var formModel = new ReviewerLicenseDocumentModel(reviewerId);
  return (
    <ModalFormProvider
      formModel={formModel}
      endpoint={ENDPOINTS.reviewerLicenseDocuments.base}
    >
      <ReviewerLicenseDocumentGridWithModalDrawer reviewerId={reviewerId} />
    </ModalFormProvider>
  );
};

const ReviewerLicenseDocumentGridWithModalDrawer: React.FC<
  ReviewerDocumentProps
> = ({ reviewerId }) => {
  const searchModel: ReviewerIdLicenseGridSearchModel =
    new ReviewerIdLicenseGridSearchModel(reviewerId);

  var formModel = new ReviewerLicenseDocumentModel(reviewerId);

  const actionsConfig: ActionsDropdownConfig = {
    name: 'Actions',
    items: [
      {
        name: 'Upload License Document',
        permissions: [Permission.UploadReviewerLicenseDocument],
        modalConfig: {
          modalId: ReviewerLicenseDocumentModal.LicenseDocuments,
          modalComponent: (
            <ReviewerLicenseDocumentDrawerForm
              endpoint={ENDPOINTS.reviewerLicenseDocuments.base}
              formModel={formModel}
              reviewerId={reviewerId}
            />
          ),
        },
      },
    ],
  };

  return (
    <Fragment>
      <PageSectionHeading titleActionsConfig={actionsConfig} />
      <AsyncGrid
        readEndpoint={ENDPOINTS.reviewerLicenseDocuments.read}
        search={searchModel}
        withCardWrapper
        {...defaultGridProps}
      >
        <GridColumn field="title" title="Title" />
        <GridColumn field="licenseNumber" title="License Number" />
        <GridColumn
          field="expirationDate"
          title="Expiration Date"
          cell={(props: GridCellProps) => <DateCell {...props} />}
        />
        <GridColumn
          field="File Name"
          title="File Name"
          cell={FileNameLicenseDownloadCell}
        />
        <GridColumn
          field="uploadDate"
          title="Date Uploaded"
          cell={getFormattedDisplayCell('uploadDateDisplay')}
        />
        <GridColumn field="uploadedBy" title="Uploaded By" />
        <GridColumn
          field="id"
          title=" "
          cell={(props: GridCellProps) => (
            <ReviewerLicenseDocumentsActionCell {...props} />
          )}
          width={GridColumnWidth.Ellipsis}
        />
      </AsyncGrid>
    </Fragment>
  );
};

const FileNameBoardCertificationDownloadCell: React.FC<GridCellProps> = ({
  ...props
}) => {
  return (
    <FileDownloadCell
      fileControllerEndpoint={ENDPOINTS.reviewerBoardCertificationDocument.base}
      {...props}
    />
  );
};

export const ReviewerBoardCertificationDocumentList: React.FC<
  ReviewerDocumentProps
> = ({ reviewerId }) => {
  return (
    <Fragment>
      <GridProvider entityName="Board Certification">
        <ReviewerBoardCertificationDocumentModalWrapper
          reviewerId={reviewerId}
        />
      </GridProvider>
    </Fragment>
  );
};

const ReviewerBoardCertificationDocumentModalWrapper: React.FC<
  ReviewerDocumentProps
> = ({ reviewerId }) => {
  var formModel = new ReviewerBoardCertificationDocumentModel(reviewerId);
  return (
    <ModalFormProvider
      formModel={formModel}
      endpoint={ENDPOINTS.reviewerBoardCertificationDocument.base}
    >
      <ReviewerBoardCertificationDocumentGridWithModalDrawer
        reviewerId={reviewerId}
      />
    </ModalFormProvider>
  );
};

const ReviewerBoardCertificationDocumentGridWithModalDrawer: React.FC<
  ReviewerDocumentProps
> = ({ reviewerId }) => {
  const searchModel: ReviewerIdBoardCertificationGridSearchModel =
    new ReviewerIdBoardCertificationGridSearchModel(reviewerId);

  var formModel = new ReviewerBoardCertificationDocumentModel(reviewerId);

  const actionsConfig: ActionsDropdownConfig = {
    name: 'Actions',
    items: [
      {
        name: 'Upload Board Certification Document',
        permissions: [Permission.UploadBoardCertificationDocument],
        modalConfig: {
          modalId:
            ReviewerBoardCertificationDocumentModal.CertificationDocuments,
          modalComponent: (
            <ReviewerBoardCertificationDocumentDrawerForm
              endpoint={ENDPOINTS.reviewerBoardCertificationDocument.base}
              formModel={formModel}
              reviewerId={reviewerId}
            />
          ),
        },
      },
    ],
  };

  return (
    <Fragment>
      <PageSectionHeading titleActionsConfig={actionsConfig} />
      <AsyncGrid
        readEndpoint={ENDPOINTS.reviewerBoardCertificationDocument.read}
        search={searchModel}
        withCardWrapper
        {...defaultGridProps}
      >
        <GridColumn field="title" title="Title" />
        <GridColumn field="certificationNumber" title="Certification Number" />
        <GridColumn
          field="expirationDate"
          title="Expiration Date"
          cell={(props: GridCellProps) => <DateCell {...props} />}
        />
        <GridColumn
          field="File Name"
          title="File Name"
          cell={FileNameBoardCertificationDownloadCell}
        />
        <GridColumn
          field="uploadDate"
          title="Date Uploaded"
          cell={getFormattedDisplayCell('uploadDateDisplay')}
        />
        <GridColumn field="uploadedBy" title="Uploaded By" />
        <GridColumn
          field="id"
          title=" "
          cell={(props: GridCellProps) => (
            <ReviewerBoardCertificationDocumentActionCell {...props} />
          )}
          width={GridColumnWidth.Ellipsis}
        />
      </AsyncGrid>
    </Fragment>
  );
};
