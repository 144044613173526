import {
  GridCellProps,
  GridColumn as Column,
} from '@progress/kendo-react-grid';
import {
  AsyncLoadingSection,
  DisplayBlock,
  ModalDrawerButtonRow,
  ModalFormDrawer,
  MultiGridTabStrip,
} from 'Components/Display';
import {
  AddButton,
  ModalFormCancelButton,
} from 'Components/Form';

import {
  GridButtonSection,
  FilterBarMainGrid,
  EditDeleteCell,
  DateCell,
  LinkCell,
  ActionsEllipsisCell,
  TimeStampCell,
} from 'Components/Grid';
import { SecureComponent } from 'Components/Security';
import {
  GridProvider,
  ModalFormProvider,
  useEntity,
  useFormUpdater,
  useModalFormState,
} from 'Context';
import {
  IFilterConfig,
  FilterConfig,
  FilterBarFilterType,
  defaultGridProps,
  ClaimEscalation,
  ActionsDropdownConfig,
  StaticFilterConfig,
} from 'Models';
import { Permission } from 'Models/Templates/Permission/Permission';
import { ENDPOINTS } from 'Models/Templates/Routes/Routes';
import moment from 'moment';
import {
  ROUTES,
  AddEditSubRoute,
  addEditRoute,
  ClaimsSubRoute,
} from 'Navigation';
import React, { HTMLProps } from 'react';
import { Row } from 'reactstrap';
import {
  useGridSearch,
  buildRoute,
  EMPTY_GUID,
  buildEndpoint,
  useDataFetcher,
} from 'Utilities';

export const ClaimEscalations: React.FC = () => {
  return <Escalations />;
};
export enum ClaimEscalationsGridIds {
  DetailedSearch = 'detailedSearch',
}

class EscalationsSearch {
  searchKey?: string;
  id?: string;
  caseDueDate?: string[];
  claimId?: string;
}

export const Escalations: React.FC = () => {
  return (
    <GridProvider entityName="Claim Escalation">
      <EscalationsFilteredGrid />
    </GridProvider>
  );
};

export type EscalationsFilteredGridProps =
  HTMLProps<EscalationsFilteredGridProps> & {
    id?: string;
  };

export const EscalationsFilteredGrid: React.FC<
  EscalationsFilteredGridProps
> = ({ id, ...props }) => {
  const escalationsSearch = new EscalationsSearch();

  const entity = useEntity();

  escalationsSearch.claimId = entity.id;
  return (
    <ModalFormProvider formModel={escalationsSearch}>
      <EscalationsGrid id={entity.id} />
    </ModalFormProvider>
  );
};

const EscalationsGrid: React.FC<EscalationsFilteredGridProps> = ({
  id,
  ...props
}) => {
  const filters: IFilterConfig[] = [
    new FilterConfig(
      'searchKey',
      FilterBarFilterType.SearchText,
      'title, description, etc.'
    ),
    new FilterConfig(
      'caseDueDate',
      FilterBarFilterType.DatePicker,
      'Due date.'
    ),
    new StaticFilterConfig('claimId'),
  ];
  const [gridToggler] = useGridSearch();

  let endpoint = ENDPOINTS.claimescalations.read;

  return (
    <React.Fragment>
      <SecureComponent permissions={[Permission.AddClaim]}>
        <GridButtonSection>
          <AddButton
            url={buildRoute(
              ROUTES.ADD_EDIT,
              AddEditSubRoute.ClaimEscalation,
              EMPTY_GUID,
              id
            )}
          >
            New Escalation
          </AddButton>
        </GridButtonSection>
      </SecureComponent>
      <FilterBarMainGrid
        isMainGrid
        filterBarProps={{
          filterConfigurations: filters,
          filterValues: { claimId: gridToggler.claimId },
        }}
        refreshToggle={gridToggler}
        readEndpoint={endpoint}
        {...defaultGridProps}
      >
        <Column field="title" title="Title" />
        <Column
          field="caseDueDate"
          title="Case Due Date"
          cell={TimeStampCell}
        />
        <Column field="escalatedByName" title="Escalated by" />
        <Column field="escalationDescription" title="Description" />
        <Column field="id" width="80" title="Actions" cell={ActionsCell} />
        <Column
          field="id"
          width="50"
          title=" "
          cell={AppointmentDocumentActionCell}
        />
      </FilterBarMainGrid>
      <ModalFormDrawer width={500} title="Escalation">
        <SingleEscalationDrawerFormContent />
      </ModalFormDrawer>
    </React.Fragment>
  );
};

export const SingleEscalationDrawerFormContent: React.FC = () => {
  const { entityId, isOpen } = useModalFormState();

  const isAdd = entityId === undefined;

  let [claimEscalationModel, fetchStatus] = useDataFetcher<ClaimEscalation>(
    buildEndpoint(ENDPOINTS.claimescalations.getInfoModel, entityId),
    new ClaimEscalation(),
    ({ data }) => data,
    undefined,
    !isAdd && isOpen
  );

  return (
    <AsyncLoadingSection loadingStatus={fetchStatus} noIdleLoading>
      <Row>
        <DisplayBlock value={claimEscalationModel?.title} title="Title" />
        <DisplayBlock
          value={
            claimEscalationModel?.caseDueDate
              ? moment(claimEscalationModel?.caseDueDate).format(
                  'YYYY-MM-DD HH:mm'
                )
              : ''
          }
          title="Due Date"
        />
      </Row>
      <Row>
        <DisplayBlock value={claimEscalationModel?.cptCode} title="CPTCode" />
        <DisplayBlock
          value={claimEscalationModel?.diagnosis}
          title="Diagnosis"
        />
      </Row>
      <Row>
        <DisplayBlock
          value={claimEscalationModel?.escalatedByEmail}
          title="Escalated by email"
        />
        <DisplayBlock
          value={claimEscalationModel?.escalatedByName}
          title="Escalated by name"
        />
      </Row>
      <Row>
        <DisplayBlock
          value={claimEscalationModel?.escalationDescription}
          title="Description"
        />
      </Row>
      <Row>
        <DisplayBlock value={claimEscalationModel?.quantity} title="Quantity" />
        <DisplayBlock
          value={claimEscalationModel?.clientInstructions}
          title="Client Instruction"
        />
      </Row>
      <Row>
        <DisplayBlock value={claimEscalationModel?.icdCode} title="ICD Code" />
      </Row>

      <ModalDrawerButtonRow>
        <ModalFormCancelButton title="Close" />
      </ModalDrawerButtonRow>
    </AsyncLoadingSection>
  );
};

const ActionsCell: React.FC<GridCellProps> = (props: GridCellProps) => {
  return (
    <EditDeleteCell
      editRoute={addEditRoute(AddEditSubRoute.ClaimEscalation)}
      editPermissions={[Permission.EditEscalation]}
      deleteEndpoint={ENDPOINTS.claimescalations.base}
      deletePermissions={[Permission.DeleteEscalation]}
      {...props}
    >
    </EditDeleteCell>
  );
};

export const AppointmentDocumentActionCell: React.FC<GridCellProps> = ({
  dataItem,
  field = '',
}) => {
  const { toggle, setEntityId } = useModalFormState();

  const value = dataItem[field];

  const viewItem = () => {
    setEntityId(value);
    toggle();
  };

  const actionsConfig: ActionsDropdownConfig = {
    items: [
      {
        name: 'View',
        permissions: [Permission.ViewEscalation],
        onClick: viewItem,
      },
    ],
  };

  return (
    <ActionsEllipsisCell actionsConfig={actionsConfig} dataItem={dataItem} />
  );
};
