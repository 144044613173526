import React from 'react';
import { ROUTES } from 'Navigation';
import { ProviderProps, LayoutRoute } from './LayoutRoute';
import { SidebarSubLayout, SubHeaderLayoutProps } from 'Components/SubLayouts';
import { RouteProps } from 'react-router-dom';

type SidebarLayoutRouteProps = SubHeaderLayoutProps &
  RouteProps &
  ProviderProps & {
    component: React.FC<any>;
    route: ROUTES;
    layoutProps?: any;
  };

export const SidebarLayoutRoute: React.FC<SidebarLayoutRouteProps> = ({
  ...allProps
}) => {
  return <LayoutRoute layoutComponent={SidebarSubLayout} {...allProps} />;
};
