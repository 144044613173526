import "./EasyPopup.scss";
import React, { HTMLProps, useRef } from "react";
import { fullClassName, useOnClickOutside } from "Utilities";
import { Align, Popup } from "@progress/kendo-react-popup";
import { Render } from "./Render";

export type EasyPopupProps = HTMLProps<HTMLDivElement> & {
  togglerRef: React.MutableRefObject<any>;
  customPopupAlign?: Align;
  customAnchorAlign?: Align;
  animate?: boolean;
  isOpen: boolean;
  toggleIsOpen: () => void;
  caret?: boolean;
  contentWrapperClassName?: string;
  noCloseOnClickOutside?: boolean;
};

export const EasyPopup: React.FC<EasyPopupProps> = ({
  togglerRef,
  customAnchorAlign = { horizontal: "right", vertical: "top" },
  customPopupAlign = { horizontal: "left", vertical: "top" },
  animate = true,
  children,
  className,
  isOpen,
  toggleIsOpen,
  caret,
  contentWrapperClassName,
  noCloseOnClickOutside,
  ...contentWrapperProps
}) => {
  var menuRef = useRef<any>(null);

  useOnClickOutside([menuRef, togglerRef], () => {
    if (!noCloseOnClickOutside) {
      isOpen && toggleIsOpen();
    }
  });

  return (
    <Popup
      className={fullClassName("easy-popup-wrap", className)}
      anchor={togglerRef.current}
      anchorAlign={customAnchorAlign}
      popupAlign={customPopupAlign}
      show={isOpen}
      animate={animate}
      appendTo={document.getElementById("root") || undefined}
    >
      <div
        className={fullClassName(
          "easy-popup-content-wrap",
          contentWrapperClassName
        )}
        ref={(ref) => (menuRef.current = ref)}
        {...contentWrapperProps}
      >
        {children}
        <Render condition={caret !== undefined}>
          <div className="popup-caret" />
        </Render>
      </div>
    </Popup>
  );
};
