import {
  AsyncLoadingSection,
  PageCard,
  PageCardRow,
  PageCardSection,
  SingleSectionPageCard,
  TaskPageHeaderLayout,
} from 'Components/Display';
import { SmartCheckboxList, SmartInputBlock } from 'Components/Form';
import { InputType } from 'Models/FormModels';
import { ColSize, ManagedReviewer } from 'Models';
import { ENDPOINTS } from 'Models/Templates/Routes/Routes';
import React, { Fragment } from 'react';
import { Row } from 'reactstrap';
import {
  addEditTitle,
  buildRoute,
  defaultDataTransform,
  useAddEditSuccessHandler,
  useDataFetcher,
  useIdParam,
} from 'Utilities';

export const AddEditManagedReviewer: React.FC = () => {
  const entityName = 'ManagedReviewers';
  const [id, isAdd] = useIdParam();
  const handleSuccess = useAddEditSuccessHandler(entityName, isAdd);
  const [data, loadingStatus] = useDataFetcher<ManagedReviewer>(
    buildRoute(ENDPOINTS.managedreviewers.base, id),
    new ManagedReviewer(),
    defaultDataTransform,
    ManagedReviewer
  );

  return (
    <AsyncLoadingSection loadingStatus={loadingStatus} useMask>
      <TaskPageHeaderLayout
        title={addEditTitle(entityName, isAdd)}
        formProviderProps={{
          formModel: data,
          endpoint: ENDPOINTS.managedreviewers.base,
          handleSuccess: handleSuccess,
        }}
      >
        <ManagedReviewersAddEditFormContent />
      </TaskPageHeaderLayout>
    </AsyncLoadingSection>
  );
};

const ManagedReviewersAddEditFormContent: React.FC = () => {
  return (
    <Fragment>
      <SingleSectionPageCard cardTitle="Details" centerContent inputSection>
        <Row>
          <SmartInputBlock
            name="doctorAdmin"
            label="Doctor Administrator"
            type={InputType.AsyncDropDown}
            useQuery
            otherparams={{ isDoctorAdmin: true }}
            readEndpoint={ENDPOINTS.users.dropdownItems}
            colProps={{ xs: ColSize.Half }}
          />
          <SmartInputBlock
            name="managedReviewer"
            label="Managed Reviewer"
            type={InputType.AsyncDropDown}
            useQuery
            otherparams={{ isReviewer: true }}
            readEndpoint={ENDPOINTS.users.dropdownItems}
            colProps={{ xs: ColSize.Half }}
          />
        </Row>
      </SingleSectionPageCard>
    </Fragment>
  );
};
