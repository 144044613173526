import * as React from 'react';
import {
  useFormSubmitter,
  setPropertyByString,
  getPropertyByString,
  useSafeStateUpdate,
  isNullOrUndefined,
  isNullEmptyOrUndefined,
  toFormDateTimeFormat,
  setDateOnDateTimeString,
  setTimeOnDateTimeString,
  BLANK_FIELD,
} from 'Utilities';
import { FormErrors, FormStatus, InputType } from 'Models/FormModels';
import moment from 'moment';
import { AxiosRequestConfig } from 'axios';
import { CoreEnum, DropdownItemModel } from 'Models';
import { isEqual } from 'lodash';

export interface FormFieldConfiguration {
  type: InputType;
  name: string;
  dependentFieldNames?: string[];
  label?: string;
  useValueAsDisplay?: boolean;
  ignore?: boolean;
  valueDisplayRender?: (
    value: any,
    fieldConfiguration?: FormFieldConfiguration,
    formValues?: any
  ) => string;
  chipRenderCondition?: (
    value: any,
    fieldConfiguration?: FormFieldConfiguration,
    formValues?: any
  ) => boolean;
  clearField?: (fieldName: string) => void;
  restrictValues?: (value: any) => any;
}

export interface FormConfiguration {
  [field: string]: FormFieldConfiguration;
}

export interface FormState {
  formStatus: FormStatus;
  formErrors: FormErrors;
  formValues: any;
  handleChange?: (
    e: React.ChangeEvent<HTMLInputElement> | any,
    name: string,
    type: InputType
  ) => void;
  submitData: (
    data: any,
    alternateEndpoint?: string,
    alternateHandleSuccess?: (r: any) => any,
    alternateHandleError?: (r: any) => any
  ) => Promise<any>;
  formResponse?: any;
  confirmationConfig?: ConfirmationConfig;
  setConfirmationConfig: (config: ConfirmationConfig) => void;
  formConfiguration?: FormConfiguration;
  debounceTextInputs?: boolean;
}

export enum FormActionTypes {
  SetFormStatus = 'setFormStatus',
  SetValues = 'setValues',
  UpdateValue = 'updateValue',
  SetErrors = 'setErrors',
  SetHandleChange = 'setHandleChange',
  SetSubmitData = 'setSubmitData',
  SetFormResponse = 'setFormResponse',
  ClearErrors = 'clearErrors',
  SetFieldConfiguration = 'setFieldConfiguration',
}

export type FormAction =
  | { type: FormActionTypes.ClearErrors }
  | { type: FormActionTypes.SetFormStatus; payload: FormStatus }
  | { type: FormActionTypes.SetValues; payload: any }
  | {
      type: FormActionTypes.UpdateValue;
      payload: { field: string; value: any; inputType: InputType };
    }
  | { type: FormActionTypes.SetErrors; payload: FormErrors }
  | {
      type: FormActionTypes.SetHandleChange;
      payload: (
        e: React.ChangeEvent<HTMLInputElement>,
        name: string,
        inputType: InputType
      ) => void;
    }
  | {
      type: FormActionTypes.SetSubmitData;
      payload: (data: any) => Promise<any>;
    }
  | { type: FormActionTypes.SetFormResponse; payload: any }
  | {
      type: FormActionTypes.SetFieldConfiguration;
      payload: FormFieldConfiguration;
    };
type FormDispatch = (action: FormAction) => void;

const FormStateContext = React.createContext<FormState | undefined>(undefined);
const FormDispatchContext = React.createContext<FormDispatch | undefined>(
  undefined
);

const handleUpdateValue = (
  state: FormState,
  payload: { field: string; value: any; inputType?: InputType }
) => {
  var tempValues: any = { ...state.formValues };
  var trueValue = payload.value;
  var formValue = getFieldValue(tempValues, payload.field);
  var fieldConfig: FormFieldConfiguration | undefined =
    state.formConfiguration?.[payload.field];

  var trueType = payload.inputType || fieldConfig?.type;

  switch (trueType) {
    case InputType.DatePicker:
      if (
        !isNullEmptyOrUndefined(formValue) &&
        !isNullEmptyOrUndefined(payload.value)
      ) {
        trueValue = setDateOnDateTimeString(payload.value, formValue);
      }
      break;
    case InputType.TimePicker:
      if (
        !isNullEmptyOrUndefined(formValue) &&
        !isNullEmptyOrUndefined(payload.value)
      ) {
        trueValue = setTimeOnDateTimeString(payload.value, formValue);
      }
      break;
    case InputType.AsyncDropDown:
    case InputType.DropDown:
      if (
        payload.value &&
        (payload.value as DropdownItemModel).name === BLANK_FIELD
      ) {
        trueValue = null;
      }
      break;
    case InputType.EnumDropdown:
      if ((payload.value as CoreEnum).displayName === BLANK_FIELD) {
        trueValue = null;
      }
      break;
  }

  if (fieldConfig && fieldConfig.restrictValues) {
    trueValue = fieldConfig.restrictValues(trueValue);
  }

  setPropertyByString(tempValues, payload.field, trueValue);
  return { ...state, formValues: tempValues };
};

function formReducer(state: FormState, action: FormAction): FormState {
  switch (action.type) {
    case FormActionTypes.SetFormStatus: {
      return { ...state, formStatus: action.payload };
    }
    case FormActionTypes.SetValues: {
      return { ...state, formValues: action.payload };
    }
    case FormActionTypes.UpdateValue: {
      return handleUpdateValue(state, action.payload);
    }
    case FormActionTypes.SetErrors: {
      return { ...state, formErrors: action.payload };
    }
    case FormActionTypes.SetHandleChange: {
      return { ...state, handleChange: action.payload };
    }
    case FormActionTypes.SetSubmitData: {
      return { ...state, submitData: action.payload };
    }
    case FormActionTypes.SetFormResponse: {
      return { ...state, formResponse: action.payload };
    }
    case FormActionTypes.ClearErrors: {
      return { ...state, formErrors: {} };
    }
    case FormActionTypes.SetFieldConfiguration: {
      return {
        ...state,
        formConfiguration: {
          ...state.formConfiguration,
          [action.payload.name]: action.payload,
        },
      };
    }
    default: {
      throw new Error('Invalid action type');
    }
  }
}

export type ConfirmationConfig = {
  shouldDisplay?: boolean;
  confirmationLanguage?: string;
};

export const getValueFromChangeEvent = (
  e: React.ChangeEvent<HTMLInputElement> | any,
  type: InputType
) => {
  switch (type) {
    case InputType.DatePicker:
    case InputType.TimePicker:
    case InputType.DateTimePicker:
    case InputType.OldDatePicker:
    case InputType.OldTimePicker: {
      if (isNullOrUndefined(e)) {
        return e;
      }
      return toFormDateTimeFormat(e);
    }
    case InputType.YesNoBooleanRadioGroup: {
      return e.value !== undefined ? e.value : null;
    }
    case InputType.EnumRadioGroup: {
      return {
        value: e.value !== undefined ? e.value : null,
        displayName: e.displayName !== undefined ? e.displayName : undefined,
      };
    }
    case InputType.Checkbox: {
      return e.value;
    }
    case InputType.TextArea: {
      return e.value;
    }
    case InputType.RichText: {
      return `${e.html}`;
    }
    case InputType.BoolDropDown: {
      const { target } = e;
      var { value } = target;
      return e.value;
    }
    default: {
      const { target } = e;
      var { value } = target;
      return value;
    }
  }
};

export type FormProviderProps = {
  formModel?: any;
  endpoint?: string;
  handleSuccess?: (r: any) => void;
  handleError?: (r: any) => void;
  transformResponse?: (r: any) => any;
  isBlobResponse?: boolean;
  customAxiosConfig?: AxiosRequestConfig;
  confirmationConfig?: ConfirmationConfig;
  debounceTextInputs?: boolean;
};

export const FormProvider: React.FC<FormProviderProps> = ({
  formModel: values = null,
  endpoint,
  children,
  isBlobResponse,
  customAxiosConfig,
  handleSuccess,
  handleError,
  transformResponse,
  confirmationConfig,
  debounceTextInputs,
}) => {
  const [confirmationConfigStateValue, setConfirmationConfigStateValue] =
    React.useState<ConfirmationConfig | undefined>(confirmationConfig);
  const [submitData, status, { response, errors }] = useFormSubmitter(
    endpoint!,
    handleSuccess,
    handleError,
    transformResponse,
    isBlobResponse ? { responseType: 'blob' } : customAxiosConfig,
    confirmationConfigStateValue
  );
  const [state, dispatch] = React.useReducer(formReducer, {
    formValues: values,
    formErrors: {},
    formStatus: FormStatus.Initial,
    submitData: submitData,
    confirmationConfig: confirmationConfigStateValue,
    setConfirmationConfig: setConfirmationConfigStateValue,
    debounceTextInputs,
  });

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement> | any,
    name: string,
    inputType: InputType
  ) => {
    const value = getValueFromChangeEvent(e, inputType);
    dispatch({
      type: FormActionTypes.UpdateValue,
      payload: { field: name, value, inputType },
    });
  };

  React.useEffect(() => {
    dispatch({ type: FormActionTypes.SetHandleChange, payload: handleChange });
  }, []);

  React.useEffect(() => {
    dispatch({ type: FormActionTypes.SetSubmitData, payload: submitData });
  }, [submitData]);

  React.useEffect(() => {
    if (!isEqual(state.formValues, values)) {
      dispatch({ type: FormActionTypes.SetValues, payload: values });
    }
  }, [values]);

  React.useEffect(() => {
    dispatch({ type: FormActionTypes.SetErrors, payload: errors });
  }, [errors]);

  React.useEffect(() => {
    dispatch({ type: FormActionTypes.SetFormStatus, payload: status });
  }, [status]);

  React.useEffect(() => {
    dispatch({ type: FormActionTypes.SetFormResponse, payload: response });
  }, [response]);

  return (
    <FormStateContext.Provider value={state}>
      <FormDispatchContext.Provider value={dispatch}>
        {children}
      </FormDispatchContext.Provider>
    </FormStateContext.Provider>
  );
};

export const useFormState = () => {
  const formStateContext = React.useContext(FormStateContext);
  if (formStateContext === undefined) {
    throw new Error('useFormState must be used within a FormProvider');
  }
  return formStateContext;
};

export const useFormDispatch = () => {
  const formDispatchContext = React.useContext(FormDispatchContext);
  if (formDispatchContext === undefined) {
    throw new Error('useFormDispatch must be used within a FormProvider');
  }
  return formDispatchContext;
};

export const useFormContext = () => {
  const state = useFormState();
  const dispatch = useFormDispatch();
  const safeStateUpdate = useSafeStateUpdate();

  const sendUpdate = React.useCallback(
    (type: FormActionTypes, payload?: any) => {
      safeStateUpdate(() => {
        dispatch({ type, payload } as FormAction);
      });
    },
    [dispatch]
  );

  return [state, sendUpdate] as const;
};

export const useFormUpdater = () => {
  const safeStateUpdate = useSafeStateUpdate();
  const dispatch = useFormDispatch();

  return React.useCallback(
    (type: FormActionTypes, payload?: any) => {
      safeStateUpdate(() => {
        dispatch({ type, payload } as FormAction);
      });
    },
    [dispatch]
  );
};

export function getFieldValue(formValues: any, fieldName: string) {
  return isNestedFieldName(fieldName)
    ? getPropertyByString(formValues, fieldName)
    : formValues?.[fieldName];
}

export function isNestedFieldName(fieldName: string) {
  return (
    fieldName.includes('[') ||
    fieldName.includes(']') ||
    fieldName.includes('.')
  );
}

export function useFormStateValue<T = any>(
  name: string,
  initialValue?: T,
  asDate?: boolean
) {
  const { formValues } = useFormState();
  const sendUpdate = useFormUpdater();

  var value = getFieldValue(formValues, name);
  value = value === undefined ? initialValue : value;

  if (asDate && moment(value, moment.ISO_8601).isValid()) {
    value = new Date(value);
  }

  return [
    value as T,
    (value: T) =>
      sendUpdate(FormActionTypes.UpdateValue, { field: name, value }),
  ] as const;
}

export function useFormValueSetter<T = any>(name: string) {
  const sendUpdate = useFormUpdater();

  return (value: T) =>
    sendUpdate(FormActionTypes.UpdateValue, { field: name, value: value });
}

export const useFormHelpers = () => {
  const { formConfiguration } = useFormState();
  const sendUpdate = useFormUpdater();

  const clearFormErrors = () => {
    sendUpdate(FormActionTypes.ClearErrors);
  };

  const setFormValues = (values: any) => {
    sendUpdate(FormActionTypes.SetValues, values);
  };

  const setFieldConfiguration = (config: FormFieldConfiguration) => {
    sendUpdate(FormActionTypes.SetFieldConfiguration, config);
  };

  const clearFormField = (name: string) => {
    sendUpdate(FormActionTypes.UpdateValue, { field: name, value: undefined });
  };

  const clearFormFields = (fieldNames: string[]) => {
    fieldNames.map((fieldName) =>
      sendUpdate(FormActionTypes.UpdateValue, {
        field: fieldName,
        value: undefined,
      })
    );
  };

  const getFieldConfiguration = (name: string) => {
    return formConfiguration?.[name] || ({} as FormFieldConfiguration);
  };

  return {
    clearFormErrors,
    setFormValues,
    setFieldConfiguration,
    clearFormField,
    getFieldConfiguration,
    clearFormFields,
  };
};
