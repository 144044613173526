import { Reviewers } from 'Features/Reviewers';
import { Permission } from 'Models/Templates/Permission/Permission';
import { SubRoutes, DropdownSubRouteConfig } from 'Navigation';

export enum NetworkDevelopmentSubRoute {
  ReviewersListing = '/Reviewers',
}

export const NetworkDevelopmentSubRoutes: SubRoutes = {
  [NetworkDevelopmentSubRoute.ReviewersListing]: new DropdownSubRouteConfig(
    NetworkDevelopmentSubRoute.ReviewersListing,
    'Reviewers',
    Reviewers,
    [Permission.ViewReviewer]
  ),
};
