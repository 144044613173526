import { Calendar, TimePicker } from '@progress/kendo-react-dateinputs';
import { Popup, PopupProps } from '@progress/kendo-react-popup';
import {
  DropdownArrowIcon,
  EasyPopup,
  EasyPopupProps,
  PageHeadingFiltersState,
} from 'Components/Display';
import { TextButton } from 'Components/Form';
import { useLocalState } from 'Context';
import {
  DateDropdownItemModel,
  DropdownItemModel,
  DropDownItemModel,
} from 'Models';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import {
  BLANK_FIELD,
  fullClassName,
  isNullOrUndefined,
  isNullUndefinedOrEmptyGuid,
  toggledClass,
  toStandardDateString,
  toTimeString,
  useOnClickOutside,
  useSimpleDataFetcher,
  useToggler,
} from 'Utilities';
import { DropdownOptions } from './DropdownUtilities';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import ScheduleIcon from '@mui/icons-material/Schedule';

export type PageHeadingFilterConfig = {
  [x: string]: PageHeadingFilter;
};

export type PageHeadingFilter<T = any> = {
  icon?: React.ReactNode;
  type: PageHeadingFilterType;
  name?: string;
  data?: any;
  format?: string;
  readEndpoint?: string;
  initialValue?: T;
};

export type PageHeadingFilterProps = PageHeadingFilter;

export enum PageHeadingFilterType {
  MonthPicker = 'MonthPicker',
  DatePicker = 'DatePicker',
  TimePicker = 'TimePicker',
  Dropdown = 'Dropdown',
}

export type PageHeadingFilterTypeComponentMapType = {
  [x in PageHeadingFilterType]: React.FC<PageHeadingFilter>;
};

export const PageHeadingDatePickerFilter: React.FC<PageHeadingFilterProps> = ({
  type,
  icon = PageHeadingFilterTypeIconMap[type],
  name,
  format,
  initialValue = new Date(),
}) => {
  const [{ onFilterChange }] = useLocalState<PageHeadingFiltersState>();
  const [selectedDate, setSelectedDate] = useState<Date>(initialValue);
  const isMonthPicker = type === PageHeadingFilterType.MonthPicker;
  const topView = isMonthPicker ? 'year' : undefined;
  const bottomView = isMonthPicker ? 'year' : undefined;

  useEffect(() => {
    onFilterChange(name!, selectedDate.toLocaleString("en-US"));
  }, [selectedDate]);

  return (
    <PageHeadingFilterWrapper
      icon={icon}
      value={
        format === undefined
          ? toStandardDateString(selectedDate)
          : moment(selectedDate).format(format)
      }
      closeOnValueChange
    >
      <Calendar
        bottomView={bottomView}
        topView={topView}
        defaultValue={initialValue}
        focusedDate={selectedDate}
        value={selectedDate}
        onChange={(e) => setSelectedDate(e.value)}
        className="popup-calendar"
      />
    </PageHeadingFilterWrapper>
  );
};

export const PageHeadingTimePickerFilter: React.FC<PageHeadingFilterProps> = ({
  type,
  icon = PageHeadingFilterTypeIconMap[type],
  name,
  initialValue = new Date(),
}) => {
  const [{ onFilterChange }] = useLocalState<PageHeadingFiltersState>();
  const [selectedTime, setSelectedTime] = useState<Date | null>(initialValue);
  const [pickerIsOpen, togglePicker] = useToggler();
  const toggleRef = useRef<any>(null);

  useEffect(() => {
    onFilterChange(name!, selectedTime?.toLocaleString("en-US"));
  }, [selectedTime]);

  return (
    <PageHeadingFilterWrapper
      icon={icon}
      value={toTimeString(selectedTime)}
      closeOnValueChange
      togglePopup={togglePicker}
      popupIsOpen={pickerIsOpen}
      withoutPopup
      popupToggleRef={toggleRef}
    >
      <TimePicker
        defaultValue={initialValue}
        show={pickerIsOpen}
        onChange={(e) => setSelectedTime(e.value)}
        className="invisible-time-picker"
        steps={{ minute: 15 }}
        nowButton={false}
        min={new Date(0, 0, 0, 7, 0, 0)}
        max={new Date(0, 0, 0, 19, 0, 0)}
        popupSettings={{ animate: false }}
        smoothScroll={false}
        popup={({ ...props }: any) => (
          <CustomTimePickerPopup
            toggleIsOpen={togglePicker}
            isOpen={pickerIsOpen}
            toggleRef={toggleRef}
            {...props}
          />
        )}
        cancelButton={false}
      />
    </PageHeadingFilterWrapper>
  );
};

type CustomTimePickerPopupProps = PopupProps & {
  isOpen: boolean;
  toggleIsOpen: () => void;
  toggleRef: React.MutableRefObject<any>;
};

export const CustomTimePickerPopup: React.FC<CustomTimePickerPopupProps> = ({
  children,
  isOpen,
  toggleIsOpen,
  toggleRef,
  ...props
}) => {
  var menuRef = useRef<any>(null);

  useOnClickOutside([menuRef, toggleRef], () => {
    isOpen && toggleIsOpen();
  });

  return (
    <Popup appendTo={document.getElementById('root') || undefined} {...props}>
      <div ref={(ref) => (menuRef.current = ref)}>{children}</div>
    </Popup>
  );
};

export const PageHeadingDropdownPickerFilter: React.FC<PageHeadingFilterProps> =
  ({ icon, type, name, initialValue, readEndpoint }) => {
    const [{ onFilterChange }] = useLocalState<PageHeadingFiltersState>();
    if (!readEndpoint) {
      throw new Error(
        `readEndpoint must be defined for dropdown filter: ${name}.`
      );
    }

    const [selectedItem, setSelectedItem] = useState<
      DropdownItemModel | DateDropdownItemModel | null
    >(initialValue);
    const [dropdownData, loadingStatus] = useSimpleDataFetcher<
      (DropDownItemModel | DateDropdownItemModel)[]
    >(readEndpoint, []);
    const [dropdownFilter, setDropdownFilter] = useState('');
    const [filteredOptions, setFilteredOptions] = useState(dropdownData);

    const isItemSelected = (item?: any) => {
      return (
        (!isNullUndefinedOrEmptyGuid((selectedItem as DropdownItemModel)?.id) &&
          (selectedItem as DropdownItemModel)?.id === item?.id) ||
        (!isNullOrUndefined((selectedItem as DateDropdownItemModel)?.name) &&
          (selectedItem as DateDropdownItemModel)?.name === item?.name)
      );
    };

    useEffect(() => {
      onFilterChange(name!, selectedItem);
    }, [selectedItem]);

    useEffect(() => {
      setFilteredOptions(
        dropdownData.filter((item: DropDownItemModel | DateDropdownItemModel) =>
          item?.name?.toLowerCase()?.includes(dropdownFilter.toLowerCase())
        )
      );
    }, [dropdownFilter]);

    useEffect(() => {
      setFilteredOptions(dropdownData || []);
    }, [dropdownData]);

    return (
      <PageHeadingFilterWrapper
        icon={icon}
        value={selectedItem?.name || BLANK_FIELD}
        closeOnValueChange
      >
        <DropdownOptions
          options={filteredOptions}
          dropdownFilter={dropdownFilter}
          setDropdownFilter={setDropdownFilter}
          handleClickOption={(item) => setSelectedItem(item)}
          loadingStatus={loadingStatus}
          isItemSelected={isItemSelected}
        />
      </PageHeadingFilterWrapper>
    );
  };

export type PageHeadingFilterWrapperProps = {
  icon: React.ReactNode;
  value: string;
  children: React.ReactNode;
  closeOnValueChange?: boolean;
  popupProps?: Partial<EasyPopupProps>;
  togglePopup?: () => void;
  popupIsOpen?: boolean;
  withoutPopup?: boolean;
  popupToggleRef?: React.MutableRefObject<any>;
};

export const PageHeadingFilterWrapper: React.FC<PageHeadingFilterWrapperProps> =
  ({
    icon,
    children,
    value,
    closeOnValueChange,
    popupProps,
    togglePopup,
    popupIsOpen,
    withoutPopup,
    popupToggleRef,
  }) => {
    const toggleButtonRef = useRef<any>(null);
    const [isOpen, toggleIsOpen] = useToggler();
    const realIsOpen = popupIsOpen === undefined ? isOpen : popupIsOpen;
    const realToggle = togglePopup === undefined ? toggleIsOpen : togglePopup;
    const realToggleRef = popupToggleRef || toggleButtonRef;

    useEffect(() => {
      if (closeOnValueChange && realIsOpen) {
        realToggle();
      }
    }, [value]);

    return (
      <div className="flex-column position-relative">
        <div
          className="position-relative flex-column"
          ref={(buttonWrapperRef) => {
            realToggleRef.current = buttonWrapperRef;
          }}
        >
          <TextButton
            className={fullClassName(
              'flex-vertical-center header-size-tiny font-primary-bold-important mr-large page-heading-filter-button pb-tiny',
              toggledClass('color-primary-accent', realIsOpen)
            )}
            onClick={realToggle}
          >
            <span className="mr-tiny flex-vertical-center">{icon}</span>
            <span className="no-user-select">{value}</span>
            <DropdownArrowIcon className="ml-tiny" />
          </TextButton>
          {withoutPopup && children}
        </div>
        {!withoutPopup && (
          <EasyPopup
            togglerRef={realToggleRef}
            isOpen={realIsOpen}
            toggleIsOpen={realToggle}
            customAnchorAlign={{ horizontal: 'left', vertical: 'bottom' }}
            customPopupAlign={{ horizontal: 'left', vertical: 'top' }}
            animate={false}
            style={{ padding: 0 }}
            contentWrapperClassName="animate-slide-down-small"
            {...popupProps}
          >
            {children}
          </EasyPopup>
        )}
      </div>
    );
  };

export const PageHeadingFilterTypeComponentMap: PageHeadingFilterTypeComponentMapType =
  {
    [PageHeadingFilterType.DatePicker]: PageHeadingDatePickerFilter,
    [PageHeadingFilterType.MonthPicker]: PageHeadingDatePickerFilter,
    [PageHeadingFilterType.Dropdown]: PageHeadingDropdownPickerFilter,
    [PageHeadingFilterType.TimePicker]: PageHeadingTimePickerFilter,
  };

export const PageHeadingFilterTypeIconMap: {
  [filterType in PageHeadingFilterType]?: React.ReactNode;
} = {
  [PageHeadingFilterType.DatePicker]: <CalendarTodayIcon />,
  [PageHeadingFilterType.MonthPicker]: <CalendarTodayIcon />,
  [PageHeadingFilterType.TimePicker]: <ScheduleIcon />,
};
