import { NamedCoreEnum } from './SharedModels';

export class Client {
  id?: string;
  name?: string;
}

export enum ClientModals {
  EditClaim = 'editClaim',
}
