import { LightPageHeader, MultiGridTabStrip } from 'Components/Display';
import {
  DateCell,
  FilterBar,
  FilteredMainGrid,
  GridButtonSection,
  LinkCell,
} from 'Components/Grid';
import {
  FilterBarProvider,
  FormProvider,
  GridProvider,
  MultiGridProvider,
  useEnums,
  useFilterBarState,
} from 'Context';
import {
  defaultGridProps,
  emptyGuid,
  FilterBarFilterType,
  FilterConfig,
  IFilterConfig,
  User,
} from 'Models';
import { ENDPOINTS } from 'Models/Templates/Routes/Routes';
import React from 'react';
import { buildEndpoint, useApiWorker } from 'Utilities';
import { GridCellProps, GridColumn } from '@progress/kendo-react-grid';
import { ReferralSubRoute, ROUTES } from 'Navigation';
import { Col, Row } from 'reactstrap';
import { PrimaryButton } from 'Components/Form';
import download from 'downloadjs';
import { toast } from 'react-toastify';

class AuditReportSearch {
  constructor() {
    this.searchKey = '';
  }
  searchKey?: string;
  typeOfEval?: string;
  reviewedBy?: User;
  benefitState?: string;
  lastQaDate?: Date;
  completedDate?: Date;
}

export enum AuditReportGridIds {
  DetailedSearch = 'detailedSearch',
}

export const AuditReport: React.FC = () => {
  return (
    <GridProvider entityName="Audit Report">
      <LightPageHeader tall noBorder className="page-header-shadow">
        <Row>
          <Col>
            <h1>Audit Report</h1>
          </Col>
        </Row>
      </LightPageHeader>
      <br />
      <Row>
        <Col></Col>
      </Row>
      <FilterBarDetailedSearchGrid />
    </GridProvider>
  );
};

const FilterBarDetailedSearchGrid: React.FC = () => {
  const auditReportSearch = new AuditReportSearch();

  return (
    <FormProvider formModel={auditReportSearch}>
      <AuditReportGrid />
    </FormProvider>
  );
};

const AuditReportGrid: React.FC = () => {
  const { state } = useEnums();

  const filters: IFilterConfig[] = [
    new FilterConfig(
      'searchKey',
      FilterBarFilterType.SearchText,
      'Name, Claim Number'
    ),
    new FilterConfig(
      'TypeOfEval',
      FilterBarFilterType.AsyncDropdown,
      'Type Of Eval',
      {
        readEndpoint: buildEndpoint(
          ENDPOINTS.clients.endpointString('typeOfEvalDropdownItems'),
          emptyGuid
        ),
      }
    ),
    new FilterConfig(
      'reviewedBy',
      FilterBarFilterType.AsyncDropdown,
      'Reviewed By',
      {
        readEndpoint: buildEndpoint(
          ENDPOINTS.users.endpointString('userByRolDropdownItems'),
          'reviewer'
        ),
      }
    ),
    new FilterConfig(
      'benefitState',
      FilterBarFilterType.EnumDropdown,
      'Benefit State',
      { data: state }
    ),

    new FilterConfig(
      'lastQaDate',
      FilterBarFilterType.DateRange,
      'Last QA Date'
    ),
    new FilterConfig(
      'completedDate',
      FilterBarFilterType.DateRange,
      'Date Completed'
    ),
  ];

  return (
    <React.Fragment>
      <FilterBarProvider filterValues={[]} filterConfigurations={filters}>
        <FilterBar />
        <GridButtonSection>
          <ExportCsvButton />
        </GridButtonSection>
        <FilteredMainGrid
          isMainGrid
          refreshToggle={false}
          readEndpoint={ENDPOINTS.auditreport.read}
          {...defaultGridProps}
        >
          <GridColumn field="claimNumber" title="Claim Number" />
          <GridColumn field="claimant" title="Claimant" cell={claimantCell} />
          <GridColumn field="typeOfEval" title="Type of Eval" />
          <GridColumn
            field="referralDate"
            title="Referral Date"
            cell={DateFormatCell}
          />
          <GridColumn
            field="completedDate"
            title="Date Completed"
            cell={DateFormatCell}
          />
          <GridColumn field="benefitState" title="Benefit state" />
          <GridColumn field="physicianReviewerName" title="Reviewed By" />
          <GridColumn field="client" title="Client" />
        </FilteredMainGrid>
      </FilterBarProvider>
    </React.Fragment>
  );
};

const claimantCell: React.FC<GridCellProps> = (props: GridCellProps) => {
  return (
    <LinkCell
      endpoint={ROUTES.REFERRAL}
      linkField="referralId"
      subRoute={ReferralSubRoute.View}
      {...props}
    ></LinkCell>
  );
};

const DateFormatCell: React.FC<GridCellProps> = (props: GridCellProps) => {
  return <DateCell {...props} dateFormat={'MM/DD/YYYY'} />;
};

class ExportCSVInput {
  search?: AuditReportSearch;
}
const ExportCsvButton: React.FC<ExportCSVInput> = () => {
  const { filterValues } = useFilterBarState();
  const API = useApiWorker();
  const exportToCsvEndpoint = buildEndpoint(
    ENDPOINTS.auditreport.endpointString('ExportToCsv')
  );

  const exportAction = async () => {
    try {
      const { data, headers } = await API.post(
        exportToCsvEndpoint,
        { searchKey: '', ...filterValues },
        {
          responseType: 'blob',
        }
      );

      const mimeType = headers['content-type'];
      var dateStamp = new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        day: '2-digit',
        month: '2-digit',
      })
        .format(new Date())
        .toString()
        .replace(/[\/\\]/g, '');

      var filename = `${'audit_report'}_${dateStamp}.${'csv'}`;
      download(data, filename, mimeType);
    } catch (err) {
      const error = err as any;
      toast.error(
        error ? error?.ErrorMessage : 'Error: Could not download file'
      );
    }
  };
  return (
    <PrimaryButton onClick={exportAction}>{'Export to CSV'}</PrimaryButton>
  );
};
