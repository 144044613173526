import React, { Fragment } from 'react';

type RenderProps = {
  condition?: boolean
}

export const Render: React.FC<RenderProps> = ({condition, children}) => {  
  if(condition || false) {
    return <Fragment>{children}</Fragment>;
  } else {
    return null;
  }
};