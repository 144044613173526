import './CollapsibleSection.scss';
import React, { HTMLProps } from 'react';
import { Collapse } from 'react-collapse';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { fullClassName, toggledClass, useToggler } from 'Utilities';
import { Render } from './Render';
import Delete from '@mui/icons-material/Delete';
import { IconButton, ButtonStyle } from 'Components/Form';
import { Tooltip } from '@progress/kendo-react-tooltip';

type CollapsibleBaseProps = React.HTMLProps<HTMLDivElement> & {
  title?: string;
  defaultClosed?: boolean;
  hideArrow?: boolean;
  noPaddingMargin?: boolean;
};

type CollapsibleSectionProps = CollapsibleBaseProps & {
  removeable?: boolean;
  hideButtons?: boolean;
  onRemove?: () => void;
};

export const CollapsibleSection: React.FC<CollapsibleSectionProps> = ({
  title = '',
  defaultClosed,
  children,
  className,
  removeable,
  onRemove,
  hideArrow,
  hideButtons = false,
}) => {
  const [isOpen, toggleIsOpen] = useToggler(!defaultClosed);

  return (
    <div
      className={fullClassName(
        'collapsible-section',
        className || '',
        toggledClass('open', isOpen)
      )}
    >
      <div className="collapsible-section-header" onClick={toggleIsOpen}>
        <Render condition={!hideArrow}>
          <div className="collapse-arrow-wrapper mr-2">
            <ExpandMoreIcon className="collapse-arrow" />
          </div>
        </Render>
        <span className="font-primary-bold">{title}</span>
        <Render condition={!hideButtons}>
          <IconButton
            buttonStyle={ButtonStyle.Bare}
            iconElement={Delete}
            className="section-delete-button"
            disabled={!removeable}
            onClick={(e) => {
              e.stopPropagation();
              onRemove && onRemove();
            }}
            primary
          />

        </Render>
      </div>
      <Collapse isOpened={isOpen}>
        <div className="px-3 pt-1">{children}</div>
      </Collapse>
    </div>
  );
};

type CollapsibleTextHeaderSectionProps = CollapsibleBaseProps & {
  customHideText?: string;
  customShowText?: string;
};

export const CollapsibleTextHeaderSection: React.FC<CollapsibleTextHeaderSectionProps> =
  ({
    title = '',
    defaultClosed,
    className,
    hideArrow,
    customHideText,
    customShowText,
    children,
    noPaddingMargin,
  }) => {
    const [isOpen, toggleIsOpen] = useToggler(!defaultClosed);
    const showHideText = `${isOpen ? 'Hide' : 'Show'} ${title}`;
    const hideText = customHideText || showHideText;
    const showText = customShowText || showHideText;
    const actualShowHideText = isOpen ? hideText : showText;

    return (
      <div
        className={fullClassName(
          'collapsible-text-header-section',
          className || '',
          toggledClass('open', isOpen)
        )}
      >
        <div
          className={fullClassName(
            'flex-vertical-center collapsible-text-header',
            toggledClass('no-padding-margin', noPaddingMargin)
          )}
          onClick={toggleIsOpen}
        >
          <div>{actualShowHideText}</div>
          <Render condition={!hideArrow}>
            <div className="collapse-arrow-wrapper">
              <ExpandMoreIcon className="collapse-arrow" />
            </div>
          </Render>
        </div>
        <Collapse isOpened={isOpen}>
          <Render condition={isOpen}>{children}</Render>
        </Collapse>
      </div>
    );
  };

export type CollapsibleArrowProps = HTMLProps<HTMLDivElement> & {
  isOpen?: boolean;
};

export const CollapsibleArrow: React.FC<CollapsibleArrowProps> = ({
  isOpen,
  className,
  ...divProps
}) => {
  return (
    <div
      className={fullClassName(
        'collapse-arrow-wrapper',
        className,
        toggledClass('open', isOpen)
      )}
      {...divProps}
    >
      <ExpandMoreIcon className="collapse-arrow" />
    </div>
  );
};
