import Tooltip from '@mui/material/Tooltip';
import {
  GridCellProps,
  GridColumn as Column,
} from '@progress/kendo-react-grid';
import { LightPageHeader } from 'Components/Display/PageHeaders';
import { AddButton } from 'Components/Form';
import {
  EditDeleteCell,
  FilterBarMainGrid,
  GridButtonSection,
  LinkCell,
} from 'Components/Grid';
import { SecureComponent } from 'Components/Security';
import { FormProvider, GridProvider } from 'Context';
import {
  defaultGridProps,
  FilterBarFilterType,
  FilterConfig,
  IFilterConfig,
} from 'Models';
import { Permission } from 'Models/Templates/Permission/Permission';
import { ENDPOINTS } from 'Models/Templates/Routes/Routes';
import { addEditRoute, AddEditSubRoute, ROUTES } from 'Navigation';
import React from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { buildRoute, useGridSearch, useHasPermissions } from 'Utilities';

export enum ReviewerGridIds {
  DetailedSearch = 'detailedSearch',
}

export const Reviewers: React.FC = () => {
  const entityName = 'Reviewer';
  return (
    <GridProvider entityName={entityName}>
      <Breadcrumb tag="nav" listTag="div">
        <BreadcrumbItem>
          <Link to={ROUTES.DASHBOARD}>Home</Link>
        </BreadcrumbItem>
        <BreadcrumbItem>Reviewers</BreadcrumbItem>
      </Breadcrumb>
      <LightPageHeader tall noBorder className="page-header-shadow">
        <h1>Reviewers</h1>
      </LightPageHeader>
      <FilterBarDetailedSearchGrid />
    </GridProvider>
  );
};

class ReviewerSearch {
  searchKey?: string;
}

export const FilterBarDetailedSearchGrid: React.FC = () => {
  const reviewerSearch = new ReviewerSearch();

  return (
    <FormProvider formModel={reviewerSearch}>
      <ReviewersGrid />
    </FormProvider>
  );
};

const ReviewersGrid: React.FC = () => {
  const [gridToggler] = useGridSearch();
  const filters: IFilterConfig[] = [
    new FilterConfig('searchKey', FilterBarFilterType.SearchText, ' Name'),
  ];
  return (
    <React.Fragment>
      <SecureComponent permissions={[Permission.AddReviewer]}>
        <GridButtonSection>
          <AddButton url={addEditRoute(AddEditSubRoute.Reviewer)}>
            New Reviewer
          </AddButton>
        </GridButtonSection>
      </SecureComponent>
      <FilterBarMainGrid
        isMainGrid
        filterBarProps={{
          filterConfigurations: filters,
          filterValues: [],
          isOpen: true,
        }}
        refreshToggle={gridToggler}
        readEndpoint={ENDPOINTS.reviewers.read}
        {...defaultGridProps}
      >
        <Column field="name" title="Name" />
        <Column field="email" title="Email" />
        <Column field="note" title="Notes" />
        <Column field="paCaseId" title="PA Case Id" />
        <Column field="contactNumber" title="Contact Number" />
        <Column field="converted" title="User Status" cell={ConvertCell} />
        <Column
          field="id"
          width="100"
          title="Actions"
          cell={ActionsCell}
          className="flex-end"
          sortable={false}
        />
      </FilterBarMainGrid>
    </React.Fragment>
  );
};

const convertText = (text: string) => 'Convert';

const ConvertCell: React.FC<GridCellProps> = (props: GridCellProps) => {
  const converted = props.dataItem?.converted;
  const hasEmail = props.dataItem?.email ? true : false;
  const tooltipText = 'A Reviewer Email is requiered';
  const hasPermissions = useHasPermissions();

  let content;

  if (converted) {
    content = <td>Converted</td>;
  } else if (hasEmail) {
    content = (
      <LinkCell
        {...props}
        endpoint={buildRoute(ROUTES.REVIEWER_CONVERT)}
        linkField="id"
        field="converted"
        convertFunction={convertText}
      >
        Convert
      </LinkCell>
    );
  } else {
    content = (
      <td>
        <Tooltip title={tooltipText}>
          <Link to={'#'}>Convert</Link>
        </Tooltip>
      </td>
    );
  }
  if (!hasPermissions([Permission.ConvertReviewer]) && !converted) {
    content = <td>Not Converted</td>;
  }
  return content;
};
const ActionsCell: React.FC<GridCellProps> = (props: GridCellProps) => {
  return (
    <EditDeleteCell
      editRoute={addEditRoute(AddEditSubRoute.Reviewer)}
      editPermissions={[Permission.EditReviewer]}
      deleteEndpoint={ENDPOINTS.reviewers.base}
      deletePermissions={[Permission.DeleteReviewer]}
      {...props}
    />
  );
};
