import * as React from "react";
import { useToggler } from "Utilities";

export interface ToggleState {
  togglerValue: boolean;
  toggle: () => void;
}

const ToggleStateContext = React.createContext<ToggleState | undefined>(
  undefined
);

type ToggleProviderProps = {
  initialValue?: boolean;
};

export const ToggleProvider: React.FC<ToggleProviderProps> = ({
  initialValue = true,
  children,
}) => {
  const [togglerValue, toggle] = useToggler(initialValue);

  return (
    <ToggleStateContext.Provider
      value={{
        togglerValue,
        toggle,
      }}
    >
      {children}
    </ToggleStateContext.Provider>
  );
};

export const useToggleState = () => {
  const toggleStateContext = React.useContext(ToggleStateContext);
  if (toggleStateContext === undefined) {
    throw new Error("useToggleState must be used within a ToggleProvider");
  }

  const { togglerValue, toggle } = toggleStateContext;

  return [togglerValue, toggle] as const;
};
