import { SubRoutes } from "Navigation/NavigationTypes";
import { AdminSubRouteConfig } from "Navigation/NavigationTypes";
import {
  ExamplesCalendar,
  ExamplesForm,
  ExamplesGrid,  
} from "Features/Examples";
import { ExamplesCard} from 'Features/Examples/ExamplesCard';

export enum ExamplesSubRoute {
  Form = "/Form",
  Grid = "/Grid",
  Calendar = "/Calendar",
  Card= "/Card"
}

export const ExamplesSubRoutes: SubRoutes = {
  [ExamplesSubRoute.Form]: new AdminSubRouteConfig(
    ExamplesSubRoute.Form,
    "Form",
    ExamplesForm,
    true
  ),
  [ExamplesSubRoute.Grid]: new AdminSubRouteConfig(
    ExamplesSubRoute.Grid,
    "Grid",
    ExamplesGrid
  ),
  [ExamplesSubRoute.Calendar]: new AdminSubRouteConfig(
    ExamplesSubRoute.Calendar,
    "Calendar",
    ExamplesCalendar
  ),
  [ExamplesSubRoute.Card]: new AdminSubRouteConfig(
    ExamplesSubRoute.Card,
    "Card",
    ExamplesCard
  ),
};
