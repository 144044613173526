export class NewReferralsResponse {
    newReferrals?: number;
  }
  
export class NewReferralsGraphData {
    monthDayCategories: Date[] = [];
    newReferralsSeries: number[] = [];
    convertedReferralsSeries: number[] = [];
  }

export class CompletedReferralsGraphData {
monthDayCategories: Date[] = [];
completedReferralsSeries: number[] = [];
openReferralsSeries: number[] = [];
}