import React, { Fragment } from 'react';
import {
  useDataFetcher,
  buildRoute,
  EMPTY_GUID,
  useGridSearch,
} from 'Utilities';
import { ENDPOINTS } from 'Models/Templates/Routes/Routes';
import { AsyncGrid } from 'Components/Grid';
import { CustomCell } from 'Components/Grid/DisplayCells';
import {
  GridColumn as Column,
  GridCellProps,
} from '@progress/kendo-react-grid';
import { defaultGridProps } from 'Models/GridModels';
import { InputType } from 'Models/FormModels';
import { SmartInputBlock, ButtonStyle, IconButton } from 'Components/Form';
import { useParams } from 'react-router-dom';
import { SecureComponent } from 'Components/Security';
import { Permission } from 'Models/Templates/Permission/Permission';
import { ROUTES } from 'Navigation';
import PageviewIcon from '@mui/icons-material/Pageview';
import { Alert, Row } from 'reactstrap';
import { DisplayBlock } from 'Components/Display';
import { toLocalDateTime } from 'Utilities/DateTime';
import format from 'xml-formatter';
import xmldecode from 'xmldecode';
import { FormProvider } from 'Context';

class ElmahErrorSearch {
  searchKey?: string;
}

export const ElmahErrors: React.FC = () => {
  const searchModel = new ElmahErrorSearch();

  return (
    <FormProvider formModel={searchModel}>
      <ElmahErrorsSearchFields />
      <ElmahErrorsGrid />
    </FormProvider>
  );
};

const ElmahErrorsSearchFields: React.FC = () => {
  return (
    <Fragment>
      <SmartInputBlock
        name="searchKey"
        label="Search"
        type={InputType.Text}
        placeholder="Message, Type"
      />
    </Fragment>
  );
};

const ElmahErrorsGrid: React.FC = () => {
  const [debounceSearchModel, gridToggler] = useGridSearch();

  return (
    <Fragment>
      <AsyncGrid
        refreshToggle={gridToggler}
        readEndpoint={ENDPOINTS.elmaherrors.read}
        search={debounceSearchModel}
        {...defaultGridProps}
      >
        <Column
          field="timeUtcWithOffset"
          title="Time"
          cell={LocalDateTimeCell}
          width={250}
        />
        <Column field="source" title="Source" width={200} />
        <Column field="type" title="Type" width={300} />
        <Column field="user" title="User" width={200} />
        <Column field="message" title="Message" />
        <Column field="errorId" title=" " cell={ActionsCell} width={100} />
      </AsyncGrid>
    </Fragment>
  );
};

const ActionsCell: React.FC<GridCellProps> = (props: GridCellProps) => {
  const value = props.dataItem[props.field || ''];
  return (
    <td>
      <SecureComponent permissions={[Permission.ViewErrorLog]}>
        <IconButton
          iconElement={PageviewIcon}
          buttonStyle={ButtonStyle.Bare}
          primary
          url={buildRoute(ROUTES.ELMAH, value)}
        />
      </SecureComponent>
    </td>
  );
};

const LocalDateTimeCell: React.FC<GridCellProps> = (props: GridCellProps) => (
  <CustomCell
    field="timeUtcWithOffset"
    convertFunction={toLocalDateTime}
    {...props}
  />
);

class ElmahErrorModel {
  application?: string;
  host?: string;
  type?: string;
  source?: string;
  message?: string;
  user?: string;
  timeUtcWithOffset?: string;
  allXml?: string;
}

export const ElmahError: React.FC = () => {
  const { id = EMPTY_GUID } = useParams<any>();
  const [data] = useDataFetcher<ElmahErrorModel>(
    buildRoute(ENDPOINTS.elmaherrors.base, id),
    new ElmahErrorModel(),
    (response) => {
      return response?.data;
    },
    ElmahErrorModel
  );
  const timeLocal =
    data?.timeUtcWithOffset === undefined
      ? ''
      : toLocalDateTime(data?.timeUtcWithOffset);

  return (
    <Fragment>
      <h1>{data?.type}</h1>
      <h6>{data?.message}</h6>
      <hr />
      <Row>
        <DisplayBlock title="Time" value={timeLocal} />
        <DisplayBlock title="User" value={data?.user} />
        <DisplayBlock title="Application" value={data?.application} />
        <DisplayBlock title="Source" value={data?.source} />
        <DisplayBlock title="Host" value={data?.host} />
      </Row>
      <Alert color="warning">
        <p style={{ whiteSpace: 'break-spaces', wordBreak: 'break-word' }}>
          {data?.allXml === undefined ? '' : format(xmldecode(data?.allXml))}
        </p>
      </Alert>
    </Fragment>
  );
};
