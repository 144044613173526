import { makeStyles } from '@mui/styles';
import {
  GridCellProps,
  GridColumn as Column,
} from '@progress/kendo-react-grid';
import { LightPageHeader } from 'Components/Display';
import { PrimaryButton, SmartInputBlock } from 'Components/Form';
import {
  DateCell,
  FilterBar,
  FilteredMainGrid,
  GridButtonSection,
} from 'Components/Grid';
import {
  FilterBarProvider,
  FormProvider,
  GridProvider,
  useFilterBarState,
  useFormState,
  useGlobalContext,
} from 'Context';
import download from 'downloadjs';
import {
  defaultGridProps,
  FilterBarFilterType,
  FilterConfig,
  IFilterConfig,
  InputType,
  InvoicesChecksDefinition,
  InvoicesSearch,
  StaticFilterConfig,
} from 'Models';
import { Permission } from 'Models/Templates/Permission/Permission';
import { ENDPOINTS } from 'Models/Templates/Routes/Routes';
import React, { Fragment } from 'react';
import { toast } from 'react-toastify';
import { Col, Row } from 'reactstrap';
import {
  buildEndpoint,
  useApiWorker,
  useGridSearch,
  useHasPermissions,
} from 'Utilities';

export enum InvoicesGridIds {
  DetailedSearch = 'detailedSearch',
}

export const Invoices: React.FC = () => {
  return (
    <GridProvider entityName="Invoices">
      <LightPageHeader tall noBorder className="page-header-shadow">
        <Row>
          <Col>
            <h1>Invoices</h1>
          </Col>
        </Row>
      </LightPageHeader>
      <br />
      <Row>
        <Col></Col>
      </Row>
      <FilterBarDetailedSearchGrid />
    </GridProvider>
  );
};

export const InvoicesChecksFilters: React.FC = () => {
  const useStyles = makeStyles({
    pageHeader: {
      alignItems: 'center',
      justifyContent: 'center',
      display: 'flex',
    },
    col: {
      width: '100%',
    },
    checksDiv: {
      margin: 5,
    },
    customChecks: {
      padding: 0,
      margin: 5,
    },
  });
  const classes = useStyles();
  const columns = [1, 2, 3, 4, 5, 6, 7];

  //Dynamic checks render from "InvoicesChecksDefinition" array
  //Each check has a defaultChecked property to check/uncheck initial value
  return (
    <Fragment>
      <LightPageHeader
        tall
        noBorder
        className={`page-header-shadow ${classes.pageHeader}`}
      >
        {columns.map((column) => {
          return (
            <div className={classes.checksDiv} key={`column_${column}`}>
              <Col className={classes.col}>
                {Object.values(InvoicesChecksDefinition)
                  .filter((x) => x.column === column)
                  .map((field, index) => {
                    return (
                      <SmartInputBlock
                        key={field.index}
                        name={`selectedIndexes[${field.index}]`}
                        label={field.label}
                        type={InputType.Checkbox}
                        className={classes.customChecks}
                      />
                    );
                  })}
              </Col>
            </div>
          );
        })}
      </LightPageHeader>
      <br />
      <Row>
        <Col></Col>
      </Row>
    </Fragment>
  );
};

const FilterBarDetailedSearchGrid: React.FC = () => {
  const invoicesSearch = new InvoicesSearch();

  return (
    <FormProvider formModel={invoicesSearch}>
      <InvoicesChecksFilters />
      <InvoicesGrid />
    </FormProvider>
  );
};

const InvoicesGrid: React.FC = () => {
  const [gridToggler] = useGridSearch();
  const { formValues } = useFormState();
  const filters: IFilterConfig[] = [
    new FilterConfig(
      'searchKey',
      FilterBarFilterType.SearchText,
      'Stradix Number, Claim Number'
    ),
    new FilterConfig('referralDateFrom', FilterBarFilterType.DatePicker, 'Referral Date From'),
    new FilterConfig('referralDateTo', FilterBarFilterType.DatePicker, 'Referral Date To'),
    new FilterConfig(
      'clients',
      FilterBarFilterType.AsyncDropdown,
      'Client',
      {
        readEndpoint: ENDPOINTS.clients.dropdownItems
      },
      (value) => value.name,
      true
    ),
    new FilterConfig('reportDateCompletedFrom', FilterBarFilterType.DatePicker, 'Date Completed From'),
    new FilterConfig('reportDateCompletedTo', FilterBarFilterType.DatePicker, 'Date Completed To'),
    new StaticFilterConfig('selectedIndexes'),
  ];

  const hasPermissions = useHasPermissions();

  const [global, setGlobal] = useGlobalContext();

  const shouldHideReviewedBy =
    global.currentUser?.isDoctor || global.currentUser?.isDoctorAdmin;

  if (!shouldHideReviewedBy) {
    filters.push(
      new FilterConfig(
        'reviewedBy',
        FilterBarFilterType.AsyncDropdown,
        'Reviewed By',
        {
          readEndpoint: buildEndpoint(
            ENDPOINTS.users.endpointString('userByRolDropdownItems'),
            'Professional'
          ),
        }
      )
    );
  }

  const hasManagedReviewerPermissions = hasPermissions([
    Permission.ManagedReviewerFilters,
  ]);
  const hasAdminReviewerFiltersPermissions = hasPermissions([
    Permission.AdminReviewerFilters,
  ]);

  if (hasManagedReviewerPermissions || hasAdminReviewerFiltersPermissions) {
    filters.push(
      new FilterConfig(
        'reviewers',
        FilterBarFilterType.AsyncDropdown,
        'Reviewer Attesting',
        {
          readEndpoint:
            ENDPOINTS.managedreviewers.endpointString('DropdownItems'),
        },
        (value) => value.name,
        true
      )
    );
  }

  //Column is visible if the boolean value is "True" in the current context
  //Report Title column is locked(fixed) by default
  return (
    <React.Fragment>
      <FilterBarProvider filterValues={[]} filterConfigurations={filters}>
        <FilterBar />
        <GridButtonSection>
          <ExportCsvButton />
        </GridButtonSection>
        <FilteredMainGrid
          isMainGrid
          refreshToggle={gridToggler}
          readEndpoint={ENDPOINTS.invoices.read}
          scrollable={'scrollable'}
          {...defaultGridProps}
        >
          {formValues.selectedIndexes[
            InvoicesChecksDefinition.reportTitle.index
          ] && (
            <Column
              field="reportTitle"
              title="Report Title"
              width={'auto'}
              locked={true}
            />
          )}
          {formValues.selectedIndexes[
            InvoicesChecksDefinition.claimantLastName.index
          ] && (
            <Column
              field="claimantLastName"
              title="Claimant Last Name"
              width={'auto'}
            />
          )}
          {formValues.selectedIndexes[
            InvoicesChecksDefinition.claimantFirstName.index
          ] && (
            <Column
              field="claimantFirstName"
              title="Claimant First Name"
              width={'auto'}
            />
          )}
          {formValues.selectedIndexes[
            InvoicesChecksDefinition.claimNumber.index
          ] && (
            <Column field="claimNumber" title="Claim Number" width={'auto'} />
          )}
          {formValues.selectedIndexes[
            InvoicesChecksDefinition.typeOfEval.index
          ] && (
            <Column field="typeOfEval" title="Type Of Eval" width={'auto'} />
          )}
          {formValues.selectedIndexes[
            InvoicesChecksDefinition.reportDueDate.index
          ] && (
            <Column
              field="reportDueDate"
              title="Due Date"
              cell={DateFormatCell}
              width={'auto'}
            />
          )}
          {formValues.selectedIndexes[
            InvoicesChecksDefinition.reportDateCompleted.index
          ] && (
            <Column
              field="reportDateCompleted"
              title="Date Completed"
              cell={DateFormatCell}
              width={'auto'}
            />
          )}
          {formValues.selectedIndexes[
            InvoicesChecksDefinition.doctorsTime.index
          ] && (
            <Column field="doctorsTime" title="Doctor's Time" width={'auto'} />
          )}
          {formValues.selectedIndexes[
            InvoicesChecksDefinition.reportTimeBilled.index
          ] && (
            <Column
              field="reportTimeBilled"
              title="Report Time Billed"
              width={'auto'}
            />
          )}
          {formValues.selectedIndexes[
            InvoicesChecksDefinition.benefitState.index
          ] && (
            <Column field="benefitState" title="Benefit State" width={'auto'} />
          )}
          {formValues.selectedIndexes[
            InvoicesChecksDefinition.reviewedBy.index
          ] && <Column field="reviewedBy" title="Reviewed By" width={'auto'} />}
          {formValues.selectedIndexes[
            InvoicesChecksDefinition.referralNumber.index
          ] && (
            <Column
              field="referralNumber"
              title="Referral Number"
              width={'auto'}
            />
          )}
          {formValues.selectedIndexes[
            InvoicesChecksDefinition.reportDeterminationDate.index
          ] && (
            <Column
              field="reportDeterminationDate"
              title="Determination Date"
              cell={DateFormatCell}
              width={'auto'}
            />
          )}
          {formValues.selectedIndexes[InvoicesChecksDefinition.rush.index] && (
            <Column
              field="rush"
              title="Rush"
              width={'auto'}
              cell={YesNoBoolParse}
            />
          )}
          {formValues.selectedIndexes[
            InvoicesChecksDefinition.contact.index
          ] && <Column field="contact" title="Contact" width={'auto'} />}
          {formValues.selectedIndexes[
            InvoicesChecksDefinition.numberOfRequests.index
          ] && (
            <Column
              field="numberOfRequests"
              title="Number Of Requests"
              width={'auto'}
            />
          )}
          {formValues.selectedIndexes[
            InvoicesChecksDefinition.pageCount.index
          ] && <Column field="pageCount" title="Page Count" width={'auto'} />}
          {formValues.selectedIndexes[
            InvoicesChecksDefinition.reasonsForRequest.index
          ] && (
            <Column
              field="reasonsForRequest"
              title="Reasons For Request"
              width="250"
            />
          )}
          {formValues.selectedIndexes[
            InvoicesChecksDefinition.outcomeOfReview.index
          ] && (
            <Column
              field="outcomeOfReview"
              title="Outcome Of Review"
              width={'auto'}
            />
          )}
          {formValues.selectedIndexes[
            InvoicesChecksDefinition.uRType.index
          ] && <Column field="urType" title="URType" width={'auto'} />}
          {formValues.selectedIndexes[
            InvoicesChecksDefinition.treatingPhysician.index
          ] && (
            <Column
              field="treatingPhysician"
              title="Treating Physician"
              width={'auto'}
            />
          )}
          {formValues.selectedIndexes[
            InvoicesChecksDefinition.daysTAT.index
          ] && <Column field="daysTAT" title="Days TAT" width={'auto'} />}
          {formValues.selectedIndexes[
            InvoicesChecksDefinition.sameDay.index
          ] && <Column field="sameDay" title="Same Day" width={'auto'} />}
          {formValues.selectedIndexes[
            InvoicesChecksDefinition.claimantClient.index
          ] && <Column field="claimantClient" title="Client" width={'auto'} />}
          {formValues.selectedIndexes[
            InvoicesChecksDefinition.employerName.index
          ] && (
            <Column field="employerName" title="Employer Name" width={'auto'} />
          )}
          {formValues.selectedIndexes[
            InvoicesChecksDefinition.insuranceCarrier.index
          ] && (
            <Column
              field="insuranceCarrier"
              title="Insurance Carrier"
              width={'auto'}
            />
          )}
          {formValues.selectedIndexes[
            InvoicesChecksDefinition.referralSource.index
          ] && (
            <Column
              field="referralSource"
              title="Referral Source"
              width={'auto'}
            />
          )}
          {formValues.selectedIndexes[
            InvoicesChecksDefinition.externalAdjuster.index
          ] && (
            <Column
              field="externalAdjuster"
              title="External Adjuster"
              width={'auto'}
            />
          )}
          {formValues.selectedIndexes[
            InvoicesChecksDefinition.adjusterName.index
          ] && (
            <Column field="adjusterName" title="Adjuster Name" width={'auto'} />
          )}
          {formValues.selectedIndexes[
            InvoicesChecksDefinition.physicianSpecialty.index
          ] && (
            <Column
              field="physicianSpecialty"
              title="Physician Specialty"
              width={'auto'}
            />
          )}
          {formValues.selectedIndexes[
            InvoicesChecksDefinition.referralDate.index
          ] && (
            <Column
              field="referralDate"
              title="Referral Date"
              cell={DateFormatCell}
              width={'auto'}
            />
          )}
          {formValues.selectedIndexes[
            InvoicesChecksDefinition.writerDateCompleted.index
          ] && (
            <Column
              field="writerDateCompleted"
              title="Writer Date Completed"
              cell={DateFormatCell}
              width={'auto'}
            />
          )}
          {formValues.selectedIndexes[
            InvoicesChecksDefinition.writer.index
          ] && <Column field="writer" title="Writer" width={'auto'} />}
          {formValues.selectedIndexes[
            InvoicesChecksDefinition.callAttempts.index
          ] && (
            <Column field="callAttempts" title="Call Attempts" width={'auto'} />
          )}
          {/** add stradixNumber column */}
          {formValues.selectedIndexes[
            InvoicesChecksDefinition.stradixNumber.index
          ] && (
            <Column
              field="stradixNumber"
              title="Stradix Number"
              width={'auto'}
            />
          )}
        </FilteredMainGrid>
      </FilterBarProvider>
    </React.Fragment>
  );
};

const DateFormatCell: React.FC<GridCellProps> = (props: GridCellProps) => {
  return <DateCell {...props} dateFormat={'MM/DD/YYYY'} />;
};

const YesNoBoolParse: React.FC<GridCellProps> = (props: GridCellProps) => {
  const value = props.dataItem[props.field as string] as string;
  const boolValue = value.toLowerCase() === 'true';

  let result = boolValue ? 'Yes' : 'No';

  return <td>{result}</td>;
};

class ExportCSVInput {
  search?: InvoicesSearch;
}

const ExportCsvButton: React.FC<ExportCSVInput> = () => {
  const { formValues } = useFormState();
  const { filterValues } = useFilterBarState();
  const API = useApiWorker();
  const exportToCsvEndpoint = buildEndpoint(
    ENDPOINTS.invoices.endpointString('ExportToCsv')
  );

  const exportAction = async () => {
    try {
      const { data, headers } = await API.post(
        exportToCsvEndpoint,
        { searchKey: '', ...formValues, ...filterValues },
        {
          responseType: 'blob',
        }
      );

      const mimeType = headers['content-type'];
      var dateStamp = new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        day: '2-digit',
        month: '2-digit',
      })
        .format(new Date())
        .toString()
        .replace(/[\/\\]/g, '');

      var filename = `${'invoice'}_${dateStamp}.${'csv'}`;
      download(data, filename, mimeType);
    } catch (err) {
      const error = err as any;
      toast.error(
        error ? error?.ErrorMessage : 'Error: Could not download file'
      );
    }
  };
  return (
    <PrimaryButton onClick={exportAction}>{'Export to CSV'}</PrimaryButton>
  );
};
