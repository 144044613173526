import React, { useEffect, useRef, useState } from 'react';
import { GridCellProps } from '@progress/kendo-react-grid';
import {
  fullClassName,
  getNumberOfCharactersPerLine,
  toggledClass,
  useToggler,
} from 'Utilities';
import { TextButton } from 'Components/Form';
import { WrappableCell } from './WrappableCell';
import { ModalDialog } from 'Components/Display';

export type SeeMoreCellProps = GridCellProps & {
  modalTitle?: string;
  renderModalTitle?: (dataItem: any) => any;
};

export const SeeMoreCell: React.FC<SeeMoreCellProps> = ({
  dataItem,
  field = '',
  modalTitle = 'See More',
  renderModalTitle = () => modalTitle,
}) => {
  const containerRef = useRef<any>(null);
  const value: string = dataItem[field] || '';

  const {
    displayValue,
    isCharacterLimitExceeded,
    showText,
    showAll,
    toggleShowAll,
    hasCharacterLimitBeenChecked,
  } = useSeeMoreCell(containerRef, value);

  return (
    <td className="white-space-break">
      <ModalDialog
        toggle={toggleShowAll}
        isOpen={showAll}
        title={modalTitle || renderModalTitle(dataItem)}
        width={600}
        closeOnClickOutside
        closeIcon={true}
      >
        {value}
      </ModalDialog>
      <div
        className={fullClassName(
          'white-space-break full-size',
          toggledClass('invisible', !hasCharacterLimitBeenChecked)
        )}
        ref={(ref) => (containerRef.current = ref)}
      >
        {displayValue}
        {isCharacterLimitExceeded && (
          <SeeMoreDisplay
            showText={showText}
            setToggleShowAll={toggleShowAll}
          />
        )}
      </div>
    </td>
  );
};

export const getSeeMoreCell = (
  modalTitle?: string,
  renderModalTitle?: (dataItem: any) => any
) => {
  return (props: GridCellProps) =>
    props.rowType === 'data' ? (
      <SeeMoreCell
        {...props}
        modalTitle={modalTitle}
        renderModalTitle={renderModalTitle}
      />
    ) : null;
};

const useSeeMoreCell = (
  containerRef: React.MutableRefObject<any>,
  value: string
) => {
  const [hasCharacterLimitBeenChecked, setHasCharacterLimitBeenChecked] =
    useState(false);
  const [showAll, toggleShowAll] = useToggler(false);
  const [numCharactersPerLine, setNumCharactersPerLine] = useState(Infinity);
  const [isCharacterLimitExceeded, setIsCharacterLimitExceeded] =
    useState(false);
  const maxLength = numCharactersPerLine * 2;
  const showText = ' See more';
  const shortenedValue = `${value.substring(
    0,
    maxLength - (showText.length + 3)
  )}...`;

  const displayValue = isCharacterLimitExceeded ? shortenedValue : value;

  const updateNumCharactersPerLine = () => {
    const numCharPerLine = getNumberOfCharactersPerLine(
      containerRef,
      shortenedValue.length
    );
    setNumCharactersPerLine(numCharPerLine);
  };

  useEffect(() => {
    updateNumCharactersPerLine();
  }, []);

  useEffect(() => {
    if (value.length > maxLength) {
      setIsCharacterLimitExceeded(true);
    } else {
      setIsCharacterLimitExceeded(false);
    }
    setHasCharacterLimitBeenChecked(true);
  }, [numCharactersPerLine]);

  return {
    displayValue,
    isCharacterLimitExceeded,
    showText,
    showAll,
    toggleShowAll,
    hasCharacterLimitBeenChecked,
  };
};

const SeeMoreDisplay: React.FC<{
  showText: string;
  setToggleShowAll: () => void;
}> = ({ showText, setToggleShowAll }) => {
  return (
    <TextButton className="font-secondary-important" onClick={setToggleShowAll}>
      {showText}
    </TextButton>
  );
};

export const WrappableGridCell: React.FC<GridCellProps> = ({
  dataItem,
  field = '',
  className,
}) => {
  return <WrappableCell className={className}>{dataItem[field]}</WrappableCell>;
};
