import { makeStyles } from '@mui/styles';
import {
  AsyncLoadingSection,
  SingleSectionPageCard,
  TaskPageHeaderLayout,
} from 'Components/Display';
import {
  defaultFormatName,
  ListSectionProps,
  SmartMultiSectionList,
} from 'Components/Form';
import { SmartInputBlock } from 'Components/Form';
import { useEnums, useFormState } from 'Context';
import {
  ReviewerBoardCertificationDocumentList,
  ReviewerDocumentList,
  ReviewerLicenseDocumentList,
} from 'Features/Reviewers';
import {
  removeAllTabIndicators,
  toggleTabsRequiredIndicator,
} from 'FeatureUtilities/TabUtilities';
import { Reviewer } from 'Models';
import { InputType } from 'Models/FormModels';
import { ColSize } from 'Models/SharedModels';
import { ENDPOINTS } from 'Models/Templates/Routes/Routes';
import React, { Fragment, useEffect, useState } from 'react';
import { Col, Fade, Nav, OverlayTrigger, Tab, Tooltip } from 'react-bootstrap';
import { Row } from 'reactstrap';
import {
  useDataFetcher,
  buildRoute,
  addEditTitle,
  useIdParam,
  defaultDataTransform,
  useAddReviewerSuccessHandler,
  EMPTY_GUID,
} from 'Utilities';

export const AddEditReviewer: React.FC<Reviewer> = () => {
  const entityName = 'Reviewer';
  const [id, isAdd] = useIdParam();

  const handleSuccess = useAddReviewerSuccessHandler(entityName, isAdd);

  const [data, loadingStatus] = useDataFetcher<Reviewer>(
    buildRoute(ENDPOINTS.reviewers.base, id),
    new Reviewer(),
    defaultDataTransform,
    Reviewer
  );

  return (
    <AsyncLoadingSection loadingStatus={loadingStatus} useMask>
      <TaskPageHeaderLayout
        title={addEditTitle(entityName, isAdd)}
        formProviderProps={{
          formModel: data,
          endpoint: ENDPOINTS.reviewers.base,
          handleSuccess: handleSuccess,
        }}
      >
        <ReviewersAddEditFormContent reviewerId={id} />
      </TaskPageHeaderLayout>
    </AsyncLoadingSection>
  );
};

export type ReviewerAddEditProps = {
  reviewerId?: string;
};

const ReviewersAddEditFormContent: React.FC<ReviewerAddEditProps> = ({
  reviewerId,
}) => {
  type ListObject = {
    textValue?: string;
    dateValue?: Date;
  };

  const initialListObjectValue: ListObject = {};
  const [key, setKey] = useState('primary');
  const formState = useFormState();

  useEffect(() => {
    if (formState.formStatus == 'validationError') {
      removeAllTabIndicators();
      toggleTabsRequiredIndicator();
    }
  }, [formState, removeAllTabIndicators, toggleTabsRequiredIndicator]);

  //If the Reviewer exist, the user will be able to upload files
  const [disabledTab, setDisabledTab] = useState(true);
  if (reviewerId != EMPTY_GUID && disabledTab) {
    setDisabledTab(false);
  }

  const { licenseType, physicianSpeciality, contactMethod } = useEnums();

  const useStyles = makeStyles({
    tabContent: {
      marginTop: 20,
    },
  });

  const classes = useStyles();

  return (
    <Fragment>
      <Tab.Container
        transition={Fade}
        id="controlled-tab-example"
        activeKey={key}
        onSelect={(k) => setKey(k as string)}
      >
        <Row>
          <Col>
            <Nav variant="tabs">
              <ReviewerTooltipTopNavItem
                title={'Reviewers Information'}
                eventKey={'primary'}
                tooltipMessage=""
              />
              <ReviewerTooltipTopNavItem
                title={'Contact Information'}
                eventKey={'personal'}
                tooltipMessage=""
              />
              <ReviewerTooltipTopNavItem
                title={'IME Office Locations'}
                eventKey={'ime'}
                tooltipMessage=""
              />
              <ReviewerTooltipTopNavItem
                title={'License Information'}
                eventKey={'license'}
                tooltipMessage={
                  'Reviewer must be saved before attach License Information'
                }
                disabled={disabledTab}
              />
              <ReviewerTooltipTopNavItem
                title={'Credentialing Forms'}
                tooltipMessage={
                  'Reviewer must be saved before attach forms/documents'
                }
                eventKey={'credentialing'}
                disabled={disabledTab}
              />
            </Nav>
            <Tab.Content className={classes.tabContent}>
              <Tab.Pane eventKey="primary">
                <SingleSectionPageCard
                  cardTitle="Details"
                  centerContent
                  inputSection
                >
                  <Row>
                    <SmartInputBlock
                      name="firstName"
                      label="First Name"
                      type={InputType.Text}
                      colProps={{ xs: ColSize.OneThird }}
                      required
                    />
                    <SmartInputBlock
                      name="lastName"
                      label="Last Name"
                      type={InputType.Text}
                      colProps={{ xs: ColSize.OneThird }}
                    />
                    <SmartInputBlock
                      name="paCaseId"
                      label="PA Case Id"
                      type={InputType.Text}
                      colProps={{ xs: ColSize.OneThird }}
                    />
                  </Row>
                  <Row>
                    <SmartInputBlock
                      name="licenseType"
                      label="License Type"
                      type={InputType.EnumDropdown}
                      data={licenseType}
                      colProps={{ xs: ColSize.OneThird }}
                    />

                    <SmartInputBlock
                      name="primarySpecialty"
                      label="Primary Speciality"
                      type={InputType.EnumDropdown}
                      data={physicianSpeciality}
                      colProps={{ xs: ColSize.OneThird }}
                    />
                    <SmartInputBlock
                      name="secondarySpecialty"
                      label="Secondary Speciality"
                      type={InputType.EnumDropdown}
                      data={physicianSpeciality}
                      colProps={{ xs: ColSize.OneThird }}
                    />
                  </Row>
                  <Row>
                    <SmartMultiSectionList
                      listName="interestedProducts"
                      listObjectName="Product"
                      initialValues={initialListObjectValue}
                      className="mb-4"
                    >
                      <InterestedProducts />
                    </SmartMultiSectionList>
                  </Row>
                </SingleSectionPageCard>
              </Tab.Pane>

              <Tab.Pane eventKey="personal">
                <SingleSectionPageCard
                  cardTitle="Contact Information"
                  centerContent
                  inputSection
                >
                  <Row>
                    <SmartInputBlock
                      name="preferredMeansOfContact"
                      label="Preferred Means of Contact"
                      type={InputType.EnumDropdown}
                      data={contactMethod}
                    />
                  </Row>
                  <Row>
                    <SmartInputBlock
                      type={InputType.PhoneNumber}
                      name="contactNumber"
                      label="Contact Number"
                      width={200}
                    />
                    <SmartInputBlock
                      type={InputType.PhoneNumber}
                      name="mobileNumber"
                      label="Mobile Number"
                      width={200}
                    />
                    <SmartInputBlock
                      type={InputType.Text}
                      name="reviewersEmail"
                      label="Reviewer Email"
                      width={200}
                    />
                  </Row>
                  <Row>
                    <SmartInputBlock
                      type={InputType.PhoneNumber}
                      name="faxNumber"
                      label="Fax Number"
                      width={200}
                    />
                    <SmartInputBlock
                      type={InputType.Text}
                      name="contactPerson"
                      label="Contact Person"
                      width={200}
                    />
                    <SmartInputBlock
                      type={InputType.Text}
                      name="contactPersonEmail"
                      label="Contact Person Email"
                      width={200}
                    />
                  </Row>
                </SingleSectionPageCard>
                <SingleSectionPageCard
                  cardTitle="Address"
                  centerContent
                  inputSection
                >
                  <ReviewerAddress />
                </SingleSectionPageCard>
              </Tab.Pane>

              <Tab.Pane eventKey="ime">
                <SingleSectionPageCard
                  cardTitle="IME Location"
                  centerContent
                  inputSection
                >
                  <SmartMultiSectionList
                    listName="imeLocations"
                    listObjectName="IME Location"
                    initialValues={initialListObjectValue}
                    className="mb-4"
                  >
                    <ImeLocation />
                  </SmartMultiSectionList>
                </SingleSectionPageCard>
              </Tab.Pane>

              <Tab.Pane eventKey="license">
                <SingleSectionPageCard
                  cardTitle="Board Certifications"
                  centerContent
                  inputSection
                >
                  <ReviewerBoardCertificationDocumentList
                    reviewerId={reviewerId}
                  />
                </SingleSectionPageCard>
                <SingleSectionPageCard
                  cardTitle="State License Number"
                  centerContent
                  inputSection
                >
                  <ReviewerLicenseDocumentList reviewerId={reviewerId} />
                </SingleSectionPageCard>
                <SmartInputBlock
                  type={InputType.TextArea}
                  name="note"
                  label="Note"
                  colProps={{ xs: ColSize.Half }}
                />
              </Tab.Pane>

              <Tab.Pane eventKey="credentialing">
                <SingleSectionPageCard cardTitle="" centerContent inputSection>
                  <ReviewerDocumentList reviewerId={reviewerId} />
                </SingleSectionPageCard>
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </Fragment>
  );
};

type ReviewerTooltipTopNavItemProps = {
  title?: string;
  tooltipMessage?: string;
  eventKey?: string;
  disabled?: boolean;
};

//Tooltip feature: if the "tooltipMessage" prop is not empty the tooltip will be showing.
const ReviewerTooltipTopNavItem: React.FC<ReviewerTooltipTopNavItemProps> = ({
  title,
  tooltipMessage,
  eventKey,
  disabled,
}) => {
  return (
    <div>
      {tooltipMessage != '' && disabled ? (
        <OverlayTrigger
          key={`${eventKey}-top`}
          placement={'top'}
          overlay={<Tooltip id={`tooltip-top`}>{tooltipMessage}</Tooltip>}
        >
          <Nav.Item>
            <Nav.Link eventKey={eventKey} disabled={disabled}>
              {title}
            </Nav.Link>
          </Nav.Item>
        </OverlayTrigger>
      ) : (
        <Nav.Item>
          <Nav.Link eventKey={eventKey} disabled={disabled}>
            {title}
          </Nav.Link>
        </Nav.Item>
      )}
    </div>
  );
};

export const InterestedProducts: React.FC<ListSectionProps> = ({
  formatInputName = defaultFormatName,
}) => {
  const { productType } = useEnums();

  return (
    <Fragment>
      <Row>
        <SmartInputBlock
          name={formatInputName('product.interestedProduct')}
          label="Interested Products"
          required
          type={InputType.EnumDropdown}
          data={productType}
          colProps={{ xs: ColSize.OneThird }}
        />
        <SmartInputBlock
          type={InputType.Currency}
          name={formatInputName('product.price')}
          label="Product Price"
          required
          width={200}
        />
        <SmartInputBlock
          type={InputType.Number}
          name={formatInputName('product.quantity')}
          label="Quantity"
          required
          width={200}
        />
      </Row>
    </Fragment>
  );
};

export const ReviewerAddress: React.FC<ListSectionProps> = ({
  formatInputName = defaultFormatName,
}) => {
  const { state } = useEnums();

  return (
    <Fragment>
      <Row>
        <SmartInputBlock
          type={InputType.Text}
          name={formatInputName('address.line1')}
          label="Address Line 1"
          width={200}
        />
        <SmartInputBlock
          type={InputType.Text}
          name={formatInputName('address.line2')}
          label="Address Line 2"
          width={200}
        />
      </Row>
      <Row>
        <SmartInputBlock
          type={InputType.Text}
          name={formatInputName('address.city')}
          label="City"
          width={200}
        />
        <SmartInputBlock
          name={formatInputName('address.state')}
          label="State"
          type={InputType.EnumDropdown}
          data={state}
          colProps={{ xs: ColSize.OneThird }}
        />
        <SmartInputBlock
          type={InputType.Text}
          name={formatInputName('address.zip')}
          label="Zip Code"
          width={200}
        />
      </Row>
    </Fragment>
  );
};

export const ImeLocation: React.FC<ListSectionProps> = ({
  formatInputName = defaultFormatName,
}) => {
  const { state } = useEnums();

  return (
    <Fragment>
      <Row>
        <SmartInputBlock
          type={InputType.Text}
          name={formatInputName('address.line1')}
          label="Address Line 1"
          width={200}
        />
        <SmartInputBlock
          type={InputType.Text}
          name={formatInputName('address.line2')}
          label="Address Line 2"
          width={200}
        />
      </Row>
      <Row>
        <SmartInputBlock
          type={InputType.Text}
          name={formatInputName('address.city')}
          label="City"
          width={200}
        />
        <SmartInputBlock
          name={formatInputName('address.state')}
          label="State"
          type={InputType.EnumDropdown}
          data={state}
          colProps={{ xs: ColSize.OneThird }}
        />
      </Row>
      <Row>
        <SmartInputBlock
          type={InputType.Text}
          name={formatInputName('address.zip')}
          label="Zip Code"
          width={200}
        />
      </Row>
    </Fragment>
  );
};
