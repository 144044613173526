import React, { Fragment } from 'react';
import { ENDPOINTS } from 'Models/Templates/Routes/Routes';
import {
  EditDeleteCell,
  MainGrid,
  GridButtonSection,
  CommaSeparatedCell,
  YesNoBooleanCell,
} from 'Components/Grid';
import {
  GridColumn as Column,
  GridCellProps,
} from '@progress/kendo-react-grid';
import { defaultGridProps, GridColumnWidth } from 'Models/GridModels';
import { AddButton } from 'Components/Form';
import { GridProvider, useEnums } from 'Context';
import {
  AdminSubHeaderMainLayout,
  CollapsibleAuditLog,
} from 'Components/Display';
import { SecureComponent } from 'Components/Security';
import { Permission } from 'Models/Templates/Permission/Permission';
import { addEditRoute, AddEditSubRoute } from 'Navigation';

export const Clients: React.FC = () => {
  const { entityEventType } = useEnums();

  return (
    <AdminSubHeaderMainLayout title="Clients">
      <GridButtonSection>
        <SecureComponent permissions={[Permission.AddClient]}>
          <AddButton url={addEditRoute(AddEditSubRoute.Client)}>
            New Client
          </AddButton>
        </SecureComponent>
      </GridButtonSection>
      <GridProvider entityName="Client">
        <MainGrid readEndpoint={ENDPOINTS.clients.read} {...defaultGridProps}>
          <Column field="name" title="Name" width={GridColumnWidth.MedLarge} />
          <Column
            field="evaluationTypes"
            title="Evaluation Types"
            cell={CommaSeparatedCell}
          />
          <Column
            field="isActive"
            title="Active"
            cell={YesNoBooleanCell}
            width="80"
          />
          <Column
            field="id"
            title=" "
            cell={ActionsCell}
            width={GridColumnWidth.EditDelete}
          />
        </MainGrid>
      </GridProvider>
      <CollapsibleAuditLog
        title="Audit Log"
        admin
        entityEventType={entityEventType.Client?.value}
      />
    </AdminSubHeaderMainLayout>
  );
};

const ActionsCell: React.FC<GridCellProps> = ({ ...otherProps }) => {
  return (
    <EditDeleteCell
      editRoute={addEditRoute(AddEditSubRoute.Client)}
      editPermissions={[Permission.EditClient]}
      deleteEndpoint={ENDPOINTS.clients.base}
      deletePermissions={[Permission.DeleteClient]}
      {...otherProps}
    />
  );
};
