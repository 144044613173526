import {
  AsyncLoadingSection,
  PageCard,
  PageCardRow,
  PageCardSection,
  SingleSectionPageCard,
  TaskPageHeaderLayout,
} from 'Components/Display';
import { SmartCheckboxList, SmartInputBlock } from 'Components/Form';
import { InputType } from 'Models/FormModels';
import { User } from 'Models/SharedModels';
import { ENDPOINTS } from 'Models/Templates/Routes/Routes';
import React, { Fragment } from 'react';
import { Row } from 'reactstrap';
import {
  addEditTitle,
  buildRoute,
  defaultDataTransform,
  useAddEditSuccessHandler,
  useDataFetcher,
  useIdParam,
} from 'Utilities';

export const AddEditUser: React.FC = () => {
  const entityName = 'User';
  const [id, isAdd] = useIdParam();
  const handleSuccess = useAddEditSuccessHandler(entityName, isAdd);
  const [data, loadingStatus] = useDataFetcher<User>(
    buildRoute(ENDPOINTS.users.base, id),
    new User(),
    defaultDataTransform,
    User
  );

  return (
    <AsyncLoadingSection loadingStatus={loadingStatus} useMask>
      <TaskPageHeaderLayout
        title={addEditTitle(entityName, isAdd)}
        formProviderProps={{
          formModel: data,
          endpoint: ENDPOINTS.users.base,
          handleSuccess: handleSuccess,
        }}
      >
        <UsersAddEditFormContent />
      </TaskPageHeaderLayout>
    </AsyncLoadingSection>
  );
};

const UsersAddEditFormContent: React.FC = () => {
  return (
    <Fragment>
      <SingleSectionPageCard cardTitle="Details" centerContent inputSection>
        <Row>
          <SmartInputBlock
            name="username"
            label="Username"
            type={InputType.Text}
            required
          />
          <SmartInputBlock
            name="emailAddress"
            label="Email Address"
            type={InputType.Text}
            required
          />
          <SmartInputBlock
            name="phoneNumber"
            label="Phone Number"
            type={InputType.PhoneNumber}
          />
          <SmartInputBlock
            name="firstName"
            label="First Name"
            type={InputType.Text}
            required
          />
          <SmartInputBlock
            name="lastName"
            label="Last Name"
            type={InputType.Text}
            required
          />
        </Row>
      </SingleSectionPageCard>

      <PageCard>
        <PageCardRow>
          <PageCardSection title="Roles">
            <SmartCheckboxList
              name="rolesList"
              labelField="name"
              checkedField="isSelected"
              groupField="groupName"
              hideCheckAll
            />
          </PageCardSection>
        </PageCardRow>
      </PageCard>

      <PageCard>
        <PageCardRow>
          <PageCardSection title="Clients">
            <Row>
              <SmartCheckboxList
                name="clientList"
                labelField="name"
                checkedField="isSelected"
                groupField="groupName"
                hideCheckAll
              />
            </Row>
          </PageCardSection>
        </PageCardRow>
      </PageCard>
    </Fragment>
  );
};
