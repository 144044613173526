import React from "react";
import { useSubRoutes, SUBROUTES_MAP, ROUTES } from "Navigation";

export type SubRoutesContainerProps = {
  route: ROUTES;
};

export const SubRoutesContainer: React.FC<SubRoutesContainerProps> = ({
  route,
}) => {
  const mapSubRoutes = useSubRoutes();

  return <div className="full-size">{mapSubRoutes(SUBROUTES_MAP[route])}</div>;
};
