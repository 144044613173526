import { ViewAppointment } from 'Features/Appointments';
import { Permission } from 'Models/Templates/Permission/Permission';
import { SubRoutes, AdminSubRouteConfig } from 'Navigation';

export enum AppointmentSubRoute {
  View = '/View',
  Documents = '/Documents',
}

export const AppointmentSubRoutes: SubRoutes = {
  [AppointmentSubRoute.View]: new AdminSubRouteConfig(
    AppointmentSubRoute.View,
    'View',
    ViewAppointment,
    true,
    [Permission.ViewAppointment]
  ),
};
