import NoteAddIcon from '@mui/icons-material/NoteAdd';
import {
  Chart,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartCategoryAxisItemProps,
  ChartLegend,
  ChartSeries,
  ChartSeriesItem,
  ChartSeriesItemProps,
  ChartSeriesLabels,
  ChartValueAxis,
  ChartValueAxisItem,
  ChartValueAxisItemProps,
} from '@progress/kendo-react-charts';
import { formatNumber, NumberFormatOptions } from '@telerik/kendo-intl';
import {
  DashboardCard,
  FlexWrapper,
  LightPageHeader,
  MainArea,
} from 'Components/Display';
import { DefaultInputLabel } from 'Components/Form';
import { SecureComponent } from 'Components/Security';
import { SubHeaderLayout } from 'Components/SubLayouts';
import {
  CompletedReferralsGraphData,
  NewReferralsGraphData,
  NewReferralsResponse,
} from 'Models/DashboardModels';
import { Permission } from 'Models/Templates/Permission/Permission';
import { ENDPOINTS } from 'Models/Templates/Routes/Routes';
import { CaseManagementSubRoute, ROUTES } from 'Navigation';
import React from 'react';
import {
  buildEndpoint,
  defaultDataTransform,
  sumProperty,
  useDataFetcher,
} from 'Utilities';
import './Dashboard.scss';
import { DashboardTile } from './DashboardTile';

type SeriesDataObject = {
  category: string;
  value: number;
  color: string;
};

enum ChartColors {
  Primary = '#256fb6',
  PrimaryAccent = '#2e5c88',
  PrimaryLight = '#4bace3',
  Green = '#019A7E',
  GreenLight = '#7EDBCA',
  Purple = '#7B6AAC',
  Yellow = '#FFC857',
}

export const DashboardMain: React.FC = () => {
  return (
    <SubHeaderLayout
      headerComponent={DashboardMainHeader}
      headerProps={{ hasTallHeader: true }}
    >
      <MainArea>
        <FlexWrapper className="flex-gap-large">
          <SecureComponent
            permissions={[Permission.ViewDashboardNewReferralsTile]}
          >
            <NewReferralsTile />
          </SecureComponent>
          <SecureComponent
            permissions={[Permission.ViewDashboardNewReferralsGraph]}
          >
            <NewReferralsGraph />
          </SecureComponent>
          <SecureComponent
            permissions={[Permission.ViewDashboardCompletedReferralsGraph]}
          >
            <CompletedReferralsGraph />
          </SecureComponent>
        </FlexWrapper>
      </MainArea>
    </SubHeaderLayout>
  );
};

export const DashboardMainHeader: React.FC = () => {
  return (
    <LightPageHeader tall noBorder className="page-header-shadow">
      <h1>Dashboard</h1>
    </LightPageHeader>
  );
};

export type DonutSeriesProps = {
  data: SeriesDataObject[];
  valueFormat?: NumberFormatOptions;
  totalLabel: string;
  totalFormat?: NumberFormatOptions;
};

export const DonutSeriesWidget: React.FC<DonutSeriesProps> = ({
  data,
  valueFormat,
  totalLabel,
  totalFormat,
}) => {
  const total = sumProperty(data, 'value');

  const formatValue = (val: number) => {
    return valueFormat ? formatNumber(val, valueFormat) : val;
  };

  const formatTotal = (val: number) => {
    return totalFormat ? formatNumber(val, totalFormat) : formatValue(val);
  };

  const donutCenterRenderer = () => (
    <div className="flex-center flex-column">
      <h1 className="font-primary-bold">{formatTotal(total)}</h1>
      <DefaultInputLabel label={totalLabel} />
    </div>
  );

  return (
    <Chart
      donutCenterRender={donutCenterRenderer}
      style={{ width: '100%', height: 275 }}
    >
      <ChartSeries>
        <ChartSeriesItem
          type="donut"
          data={data}
          categoryField="category"
          field="value"
          holeSize={85}
          connectors={{ padding: 5, color: '#DCDFE1' }}
          padding={30}
        >
          <ChartSeriesLabels
            background="none"
            position="outsideEnd"
            align="column"
            content={(e: SeriesDataObject) =>
              `${e.category}:\n${formatValue(e.value)}`
            }
          />
        </ChartSeriesItem>
      </ChartSeries>
      <ChartLegend visible={false} />
    </Chart>
  );
};

export type BarChartSeriesWidgetProps = {
  categories: string[] | Date[];
  series: number[];
  seriesName: string;
  secondSeries?: number[];
  secondSeriesName: string;
  valuesTitle: string;
  vertical?: boolean;
  categoriesTitle?: string;
  chartSeriesItemProps?: ChartSeriesItemProps;
  chartCategoryAxisItemProps?: ChartCategoryAxisItemProps;
  chartValueAxisItemProps?: ChartValueAxisItemProps;
  chartStyle?: React.CSSProperties;
  stack?: boolean;
};

export const BarChartSeriesWidget: React.FC<BarChartSeriesWidgetProps> = ({
  categories,
  series,
  seriesName,
  secondSeries,
  secondSeriesName,
  valuesTitle,
  vertical,
  categoriesTitle,
  chartSeriesItemProps,
  chartCategoryAxisItemProps,
  chartValueAxisItemProps,
  chartStyle,
  stack,
}) => {
  return (
    <Chart style={{ width: '100%', height: 250, ...chartStyle }}>
      <ChartCategoryAxis>
        <ChartCategoryAxisItem
          majorGridLines={{ visible: false }}
          majorTicks={{ visible: false }}
          minorGridLines={{ visible: false }}
          minorTicks={{ visible: false }}
          categories={categories}
          labels={{ position: 'start', margin: 15 }}
          title={{
            text: categoriesTitle,
            rotation: vertical ? 0 : -90,
            font: '14px NotoSansBold',
          }}
          {...chartCategoryAxisItemProps}
        />
      </ChartCategoryAxis>
      <ChartValueAxis>
        <ChartValueAxisItem
          majorGridLines={{ visible: false }}
          majorTicks={{ visible: false }}
          minorGridLines={{ visible: false }}
          minorTicks={{ visible: false }}
          title={{
            text: valuesTitle,
            padding: 15,
            rotation: vertical ? -90 : 0,
            font: '14px NotoSansBold',
          }}
          {...chartValueAxisItemProps}
        />
      </ChartValueAxis>
      <ChartLegend position="bottom" orientation="horizontal" />
      <ChartSeries>
        <ChartSeriesItem
          type={vertical ? 'column' : 'bar'}
          stack={stack}
          gap={1}
          data={series}
          name={seriesName}
          color={ChartColors.Primary}
          tooltip={{ visible: true }}
          {...chartSeriesItemProps}
        />
        <ChartSeriesItem
          type="bar"
          data={secondSeries}
          name={secondSeriesName}
          tooltip={{ visible: true }}
        />
      </ChartSeries>
    </Chart>
  );
};

const NewReferralsTile: React.FC = () => {
  const GetNewReferralsEndpoint = buildEndpoint(
    ENDPOINTS.dashboard.endpointString('GetNewReferrals')
  );

  const [data] = useDataFetcher<NewReferralsResponse>(
    GetNewReferralsEndpoint,
    new NewReferralsResponse(),
    defaultDataTransform,
    NewReferralsResponse
  );

  return (
    <DashboardTile
      value={data.newReferrals}
      text="New Referrals"
      icon={NoteAddIcon}
      iconSize="large"
      detialsPath={buildEndpoint(
        ROUTES.CASE_MANAGEMENT,
        CaseManagementSubRoute.NewReferrals
      )}
    />
  );
};

const NewReferralsGraph: React.FC = () => {
  const GetNewReferralsEndpoint = buildEndpoint(
    ENDPOINTS.dashboard.endpointString('GetNewReferralsGraphData')
  );

  const [data] = useDataFetcher<NewReferralsGraphData>(
    GetNewReferralsEndpoint,
    new NewReferralsGraphData(),
    defaultDataTransform,
    NewReferralsGraphData
  );

  let chartProps = {
    categories: data.monthDayCategories,
    series: data.newReferralsSeries,
    seriesName: 'New Referrals',
    secondSeries: data.convertedReferralsSeries,
    secondSeriesName: 'Converted',
    valuesTitle: 'Quantity',
    categoriesTitle: 'Date',
  };
  const total =
    sumProperty(data.newReferralsSeries) +
    sumProperty(data.convertedReferralsSeries);

  const maxGraphValue = Math.max(
    ...chartProps.series.map(function (num, idx) {
      return num + chartProps.secondSeries[idx];
    })
  );

  return (
    <DashboardCard
      title="New Referrals in the last 10 days"
      subTitle={`Total: ${total} `}
    >
      <BarChartSeriesWidget
        {...chartProps}
        stack={true}
        vertical
        chartCategoryAxisItemProps={{
          baseUnit: 'days',
          type: 'date',
          labels: { step: 1, rotation: -45 },
        }}
        chartValueAxisItemProps={{
          max: maxGraphValue,
          min: 0,
          labels: { format: '{0:0}' },
          majorUnit: maxGraphValue < 10 ? 1 : 0,
        }}
        chartSeriesItemProps={{ color: ChartColors.Yellow }}
      />
    </DashboardCard>
  );
};

const CompletedReferralsGraph: React.FC = () => {
  const GetNewReferralsEndpoint = buildEndpoint(
    ENDPOINTS.dashboard.endpointString('GetCompletedReferralsGraphData')
  );

  const [data] = useDataFetcher<CompletedReferralsGraphData>(
    GetNewReferralsEndpoint,
    new CompletedReferralsGraphData(),
    defaultDataTransform,
    CompletedReferralsGraphData
  );

  let chartProps = {
    categories: data.monthDayCategories,
    series: data.completedReferralsSeries,
    seriesName: 'Completed',
    secondSeries: data.openReferralsSeries,
    secondSeriesName: 'Open',
    valuesTitle: 'Quantity',
    categoriesTitle: 'Date',
  };
  const maxGraphValue = Math.max(
    ...chartProps.series.map(function (num, idx) {
      return num + chartProps.secondSeries[idx];
    })
  );
  const completedTotal = sumProperty(data.completedReferralsSeries);
  return (
    <DashboardCard
      title="Completed Referrals in the last 10 days"
      subTitle={`Total: ${completedTotal} `}
    >
      <BarChartSeriesWidget
        {...chartProps}
        stack={true}
        vertical
        chartCategoryAxisItemProps={{
          baseUnit: 'days',
          type: 'date',
          labels: { step: 1, rotation: -45 },
        }}
        chartValueAxisItemProps={{
          max: maxGraphValue,
          min: 0,
          labels: { format: '{0:0}' },
          majorUnit: maxGraphValue < 10 ? 1 : 0,
        }}
        chartSeriesItemProps={{ color: ChartColors.Yellow }}
      />
    </DashboardCard>
  );
};
