import { Permission } from 'Models/Templates/Permission/Permission';
import { DropdownSubRouteConfig, SubRoutes } from 'Navigation/NavigationTypes';
import React from 'react';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import { ReferralsRequestsCommercial } from 'Features/ReferralsRequests/ReferralsRequestsCommercial';
import { PendingScheduling } from 'Features/Appointments/PendingScheduling';

export enum CommercialSubRoute {
  NewReferrals = '/NewReferrals',
  PendingScheduling = '/PendingScheduling',
}

export const CommercialSubRoutes: SubRoutes = {
  [CommercialSubRoute.NewReferrals]: new DropdownSubRouteConfig(
    CommercialSubRoute.NewReferrals,
    'New Commercial Referrals',
    ReferralsRequestsCommercial,
    [Permission.AddReferralsRequestCommercial],
    false,
    {},
    <NoteAddIcon />
  ),
  [CommercialSubRoute.PendingScheduling]: new DropdownSubRouteConfig(
    CommercialSubRoute.PendingScheduling,
    'Pending Assignment',
    PendingScheduling,
    [Permission.ViewPendingAppointmentsCommercial],
    false,
    {},
    <NoteAddIcon />
  ),
};
