import React from "react";
import { getValueFromChangeEvent, useFilterBarValue } from "Context";
import { IFilterConfig, InputType } from "Models";
import { InputBlock } from "Components/Form";
import { DropdownFilterWrapper } from "./DropdownFilterWrapper";

export const YesNoBooleanFilter: React.FC<IFilterConfig> = ({
  name,
  inputProps,
  ...filterProps
}) => {
  const [value, setValue] = useFilterBarValue(name);

  return (
    <DropdownFilterWrapper name={name} {...filterProps}>
      <div className="p-small">
        <InputBlock
          name={name}
          type={InputType.YesNoBooleanRadioGroup}
          value={value}
          onChange={(e: any) =>
            setValue(getValueFromChangeEvent(e, InputType.YesNoBooleanRadioGroup))
          }
          noPaddingMargin
          {...inputProps}
        />
      </div>
    </DropdownFilterWrapper>
  );
};
