import { Tooltip } from '@progress/kendo-react-tooltip';
import { SecureComponent } from 'Components/Security';
import { Permission } from 'Models/Templates/Permission/Permission';
import { addEditRoute, AddEditSubRoute } from 'Navigation';
import React, { Fragment, PropsWithChildren, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Col, Row, Table } from 'reactstrap';
import {
  AsyncLoadingSection,
  PageCardSection,
  Render,
  SingleSectionPageCard,
  TaskPageHeaderLayout,
} from '../../Components/Display';
import {
  AddButton,
  DefaultInputLabel,
  SmartFileUploadDrawerInput,
  SmartInputBlock,
  SmartMultiSectionList,
  TextDeleteButton,
} from '../../Components/Form';
import { useEnums, useFormStateValue, useGlobalContext } from '../../Context';
import { BoolDropdownItemModel, DropdownItemModel, ReferralsRequestCommercial } from '../../Models';
import { InputType } from '../../Models/FormModels';
import { ColSize } from '../../Models/SharedModels';
import { ENDPOINTS } from '../../Models/Templates/Routes/Routes';
import {
  addEditTitle,
  buildEndpoint,
  buildQueryUrl,
  defaultDataTransform,
  useAddEditSuccessHandler,
  useApiWorker,
  useDataFetcher,
  useHasPermissions,
  useIdParam,
} from '../../Utilities';

export const AddEditReferralsRequestCommercial: React.FC = () => {
  const entityName = 'Commercial Referrals Request';
  const [id, isAdd] = useIdParam();

  const handleSuccess = useAddEditSuccessHandler(entityName, isAdd);

  const [data, loadingStatus] = useDataFetcher<ReferralsRequestCommercial>(
    buildQueryUrl(buildEndpoint(ENDPOINTS.referralsRequestsCommercial.get), {
      id: id || '',
    }),
    new ReferralsRequestCommercial(),
    defaultDataTransform,
    ReferralsRequestCommercial
  );

  return (
    <AsyncLoadingSection loadingStatus={loadingStatus} useMask>
      <TaskPageHeaderLayout
        title={addEditTitle(entityName, isAdd)}
        formProviderProps={{
          formModel: data,
          endpoint: ENDPOINTS.referralsRequestsCommercial.base,
          handleSuccess: handleSuccess,
        }}
      >
        <ReferralsRequestsAddEditFormContent {...data} />
      </TaskPageHeaderLayout>
    </AsyncLoadingSection>
  );
};

const ReferralsRequestsAddEditFormContent: React.FC<ReferralsRequestCommercial> = ({
  ...data
}) => {
  const globalContext = useGlobalContext();
  const userdata = globalContext[0]?.currentUser;
  const { state, gender, timeFrame, apContact } = useEnums();
  const hasPermission = useHasPermissions();
  const [id, isAdd] = useIdParam();

  const [selectedClient, setClient] = useFormStateValue<
    DropdownItemModel | undefined
  >('client');

  const [selectedLineOfBusiness, setLineOfBusiness] = useFormStateValue<
    DropdownItemModel | undefined
  >('lineOfBusiness');

  const [documents, setDocuments] = useFormStateValue(
    'referralRequestDocuments'
  );

  const [selectedJurisdictionalMatch, setSelectedJurisdictionalMatch] = useFormStateValue<
    BoolDropdownItemModel | undefined
  >('jurisdictionalMatch');

  let lineOfBusinessReadEndpoint = buildEndpoint(
    ENDPOINTS.clients.endpointString('LineOfBusinessDropdownItems'),
    selectedClient?.id
  );

  let servicesReadEndpoint = buildEndpoint(
    ENDPOINTS.clients.endpointString('ServicesDropdownItems'),
    selectedLineOfBusiness?.id
  );

  let tatsReadEndopoint = buildEndpoint(
    ENDPOINTS.clients.endpointString('tatsDropdownItems'),
    selectedLineOfBusiness?.id
  );

  const sameClientAdjustersOnly = !hasPermission([
    Permission.ViewAllReferralSources,
  ]);

  let referralSourceReadEndpoint = buildQueryUrl(
    ENDPOINTS.users.endpointString('DropdownItems'),
    { clientId: selectedClient?.id }
  );

  return (
    <Fragment>
      <SingleSectionPageCard cardTitle="Details" centerContent inputSection>
        <PageCardSection>
          <Row>
            <SmartInputBlock
              name="firstName"
              label="First Name"
              type={InputType.Text}
              required
              colProps={{ xs: ColSize.OneFourth }}
            />
            <SmartInputBlock
              name="lastName"
              label="Last Name"
              type={InputType.Text}
              required
              colProps={{ xs: ColSize.OneFourth }}
            />
            <SmartInputBlock
              name="gender"
              label="Gender"
              required
              type={InputType.EnumDropdown}
              data={gender}
              colProps={{ xs: ColSize.OneFourth }}
            />
            <SmartInputBlock
              name="referralNumber"
              label="Referral Number"
              type={InputType.Text}
              colProps={{ xs: ColSize.OneFourth }}
            />
          </Row>
          <Row>
            <Render condition={userdata?.isInternal}>
              <SmartInputBlock
                name="proxiedBy"
                label="Proxied By"
                type={InputType.AsyncDropDown}
                useQuery
                readEndpoint={ENDPOINTS.users.dropdownItems}
                otherparams={{
                  isAdjuster: sameClientAdjustersOnly,
                  isSameClient: sameClientAdjustersOnly,
                }}
                disabled={true}
                colProps={{ xs: ColSize.OneFourth }}
              />
            </Render>
            <SmartInputBlock
              name="client"
              label="Client"
              required
              type={InputType.AsyncDropDown}
              readEndpoint={ENDPOINTS.clients.dropdownItems}
              otherparams={{hasLineOfBusiness: true}}
              colProps={{ xs: ColSize.OneFourth }}
            />
          </Row>
          <Row>
            <SmartInputBlock
              name="referralSource"
              label="Referral Source"
              required
              type={InputType.DropDown}
              readEndpoint={referralSourceReadEndpoint}
              useQuery
              otherData={selectedClient}
              colProps={{ xs: ColSize.OneFourth }}
              disabled={!userdata?.isInternal}
            />
            <SmartInputBlock
              name="lineOfBusiness"
              label="Line of Business"
              required
              readEndpoint={lineOfBusinessReadEndpoint}
              type={InputType.DropDown}
              colProps={{ xs: ColSize.OneFourth }}
            />
            <SmartInputBlock
              name="service"
              label="Service"
              required
              readEndpoint={servicesReadEndpoint}
              type={InputType.DropDown}
              colProps={{ xs: ColSize.OneFourth }}
            />
            <SmartInputBlock
              name="claimNumber"
              label="Claim/Client Number"
              type={InputType.Text}
              required
              colProps={{ xs: ColSize.OneFourth }}
            />
          </Row>
          <Row>
            <SmartInputBlock
              name="reviewerSpeciality"
              label="Reviewer Specialty Requested"
              type={InputType.Text}
              colProps={{ xs: ColSize.OneFourth }}
            />
            <SmartInputBlock
              name="benefitState"
              label="Benefit State"
              required={selectedJurisdictionalMatch?.value === true}
              type={InputType.EnumDropdown}
              data={state}
              colProps={{ xs: ColSize.OneFourth }}
            />
            <SmartInputBlock
              name="jurisdictionalMatch"
              label="Jurisdictional Match Required"
              type={InputType.BoolDropDown}
              colProps={{ xs: ColSize.OneFourth }}
            />
            <Render condition={selectedJurisdictionalMatch?.value === true}>
              <SmartInputBlock
                name="coSignOk"
                label="Co-Sign Okay"
                required={selectedJurisdictionalMatch?.value === true}
                type={InputType.BoolDropDown}
                colProps={{ xs: ColSize.OneFourth }}
              />
            </Render>
          </Row>
          <Row>
            <SmartInputBlock
              name="dueDate"
              label="Due Date"
              required
              type={InputType.DatePicker}
              colProps={{ xs: ColSize.OneFourth }}
              isNowTime={true}
            />
            <SmartInputBlock
              name="dueDate"
              label="Due Time"
              required
              type={InputType.TimePicker}
              colProps={{ xs: ColSize.OneFourth }}
            />
            <SmartInputBlock
              name="tat"
              label="TAT"
              readEndpoint={tatsReadEndopoint}
              type={InputType.DropDown}
              colProps={{ xs: ColSize.OneFourth }}
            />
            <SmartInputBlock
              name="timeFrame"
              label="Time Frame"
              type={InputType.EnumDropdown}
              data={timeFrame}
              colProps={{ xs: ColSize.OneFourth }}
            />
          </Row>
          <Row>
            <SmartInputBlock
              name="referralDate"
              label="Referral Date"
              type={InputType.DatePicker}
              required
              value={Date.now}
              colProps={{ xs: ColSize.OneThird }}
            />
            <SmartInputBlock
              name="dateOfInjury"
              label="Date of Injury"
              type={InputType.DatePicker}
              value={Date.now}
              colProps={{ xs: ColSize.OneThird }}
            />
            <SmartInputBlock
              name="dateOfBirth"
              label="Date of Birth"
              type={InputType.DatePicker}
              required
              value={Date.now}
              colProps={{ xs: ColSize.OneThird }}
            />
          </Row>
          <Row>
            <SmartInputBlock
              name="specialInstructions"
              label="Comments/Special Instructions"
              type={InputType.TextArea}
              colProps={{ xs: ColSize.OneSixth }}
            />
            <SmartInputBlock
              name="apContact"
              label="AP Contact"
              required
              type={InputType.EnumDropdown}
              data={apContact}
              colProps={{ xs: ColSize.OneSixth }}
            />
            <SmartInputBlock
              name="apName"
              label="Attending Provider"
              type={InputType.Text}
              colProps={{ xs: ColSize.OneSixth }}
            />
            <SmartInputBlock
              name="apPhoneNumber"
              label="AP Phone Number"
              type={InputType.PhoneNumber}
              colProps={{ xs: ColSize.OneSixth }}
            />
            <SmartInputBlock
              name="apFaxNumber"
              label="AP Fax Number"
              type={InputType.PhoneNumber}
              colProps={{ xs: ColSize.OneSixth }}
            />
            <SmartInputBlock
              name="apSpecialty"
              label="AP Specialty"
              type={InputType.Text}
              colProps={{ xs: ColSize.OneSixth }}
            />
          </Row>

          <Row>
            <Col>
              <DefaultInputLabel
                label="Referral Questions"
                required
              />
              <SmartMultiSectionList
                listName="referralQuestions"
                listObjectName="Question"
                initialValues={[]}
              >
                <QuestionSection />
              </SmartMultiSectionList>
            </Col>
          </Row>

          <Row className="mt-5">
            <Render condition={isAdd}>
              <SmartFileUploadDrawerInput
                label="Medical Records"
                fileEndpoint={ENDPOINTS.referralrequestdocuments.base}
                required
                withComments={false}
                fileListName="referralRequestDocuments"
              />
            </Render>
          </Row>

          <Row className="mt-5">
            <Col>
              <ReferralRequestDocumentsMiniList
                documentList={documents}
                stateSetter={setDocuments}
              />
            </Col>
          </Row>
        </PageCardSection>
      </SingleSectionPageCard>
    </Fragment>
  );
};

export type ListSectionProps = {
  formatInputName?: (name: string) => string;
  index?: number;
};

export const defaultFormatName = (name: string) => name;

const QuestionSection: React.FC<ListSectionProps> = ({
  formatInputName = defaultFormatName,
}) => {
  const textName = formatInputName('value');

  return (
    <SmartInputBlock
      type={InputType.Text}
      name={textName}
      label="Question"
      required
      width={200}
    />
  );
};

class MiniListInput {
  documentList: any;
  stateSetter: any;
}

export const ReferralRequestDocumentsMiniList: React.FC<
  PropsWithChildren<MiniListInput>
> = ({ documentList, stateSetter, ...props }) => {
  const [id, isAdd] = useIdParam();
  const list =
    documentList && documentList.length > 0 ? (
      documentList.map((document: any, index: number, state: any) => {
        return (
          <tr key={index}>
            <td>{document.fileName} </td>
            <td>{(document.size / 1024).toFixed(2)} kb</td>
            <Render condition={isAdd}>
              <td>
                <ReferralRequestDeleteBox
                  item={document}
                  index={index}
                  stateSetter={stateSetter}
                  state={state}
                />
              </td>
            </Render>
          </tr>
        );
      })
    ) : (
      <tr>
        <td>No Documents Loaded</td>
      </tr>
    );
  return (
    <Table>
      <tbody>{list}</tbody>
    </Table>
  );
};


const ReferralRequestDeleteBox: React.FC<any> = ({
  item,
  index,
  state,
  stateSetter,
  ...props
}) => {
  const deletePermission = [Permission.DeleteReferralsRequestDocument];
  const API = useApiWorker();
  const deleteItem = async () => {
    const response = await API.post(
      buildEndpoint(
        ENDPOINTS.referralrequestdocuments.endpointString('DeleteTempBlob') +
        '?id=' +
        item.fileId
      )
    );
    if (response.data) {
      toast.success(`Medical Record Removed`);
      let newState = state.filter((doc: any) => doc.fileId != item.fileId);
      stateSetter(newState);
    } else {
      toast.error(`An error occurred removing the Medical Record`);
    }
  };

  return (
    <SecureComponent permissions={deletePermission}>
      <TextDeleteButton onClick={deleteItem} />
    </SecureComponent>
  );
};
