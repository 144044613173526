import { Referral } from 'Models';

export class Appointment {
  id?: string;
  referral?: Referral;
  writer?: string;
  professional?: string;
  appointmentDate?: string;
  appointmentStatus?: string;
}
