import './DateCell.scss';
import React, { Fragment } from 'react';
import { GridCellProps } from '@progress/kendo-react-grid';
import { InputType } from 'Models/FormModels';
import moment from 'moment';
import { GridInput } from './GridInput';
import WarningIcon from '@mui/icons-material/Warning';
import { yellow } from '@mui/material/colors';
import { template } from 'lodash';

export type DateCellProps = GridCellProps & {
  dateFormat?: string;
};

export type WarningDateCellProps = DateCellProps & {
  shouldDisplayField: string;
};

export const DateCell: React.FC<DateCellProps> = (props) => {
  return (
    <td>
      <DateCellContent {...props} />
    </td>
  );
};

export const FileDateCell: React.FC<DateCellProps> = (props) => {
  return (
    <td>
      <FileDateCellContent {...props} />
    </td>
  );
};

export const WarningDateCell: React.FC<WarningDateCellProps> = ({
  shouldDisplayField,
  dataItem,
  ...props
}) => {
  const shouldDisplayWarningIcon = dataItem[shouldDisplayField];

  return (
    <td>
      <DateCellContent {...props} dataItem={dataItem} />
      {shouldDisplayWarningIcon ? (
        <WarningIcon style={{ color: yellow[700] }} />
      ) : (
        <Fragment />
      )}
    </td>
  );
};

export const DateCellContent: React.FC<DateCellProps> = ({
  dataItem,
  field,
  onChange,
  dateFormat,
}) => {
  const value = dataItem[field as string] as Date;

  return (
    <Fragment>
      {dataItem.inEdit ? (
        <GridInput
          dataItem={dataItem}
          name={field as string}
          type={InputType.DatePicker}
          onChange={onChange}
          value={value}
        />
      ) : value ? (
        dateFormat ? (
          moment(value).format(dateFormat)
        ) : (
          moment(value).format('l')
        )
      ) : (
        ''
      )}
    </Fragment>
  );
};

const FileDateCellContent: React.FC<DateCellProps> = ({
  dataItem,
  field,
  onChange,
  dateFormat
}) => {

  let value: Date | undefined;

  switch (field) {
    case "templateFile.uploadDate": {
      value = dataItem.templateFile.uploadDate as Date;
      break;
    }
    case "reportFile.uploadDate": {
      value = dataItem.reportFile.uploadDate as Date;
      break;
    }
    default: {
      break;
    }
  }
  
  return (
    <Fragment>
      {dataItem.inEdit ? (
        <GridInput
          dataItem={dataItem}
          name={field as string}
          type={InputType.DatePicker}
          onChange={onChange}
          value={value}
        />
      ) : value ? (
        dateFormat ? (
          moment(value).format(dateFormat)
        ) : (
          moment(value).format('l')
        )
      ) : (
        ''
      )}
    </Fragment>
  );
};
