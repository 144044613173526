import {
  AsyncLoadingSection,
  PageCard,
  PageCardRow,
  PageCardSection,
  TaskPageHeaderLayout,
} from 'Components/Display';
import { SmartInputBlock, SmartCheckboxList } from 'Components/Form';
import { ENDPOINTS } from 'Models/Templates/Routes/Routes';
import React from 'react';
import { Row } from 'reactstrap';
import {
  useDataFetcher,
  buildRoute,
  useIdParam,
  useAddEditSuccessHandler,
  defaultDataTransform,
  addEditTitle,
} from 'Utilities';
import { Role, InputType } from 'Models';
import { Tooltip } from '@progress/kendo-react-tooltip';


export const AddEditRole: React.FC = () => {
  const entityName = 'User Role';
  const [id, isAdd] = useIdParam();
  const handleSuccess = useAddEditSuccessHandler(entityName, isAdd);
  const [data, fetchStatus] = useDataFetcher<Role>(
    buildRoute(ENDPOINTS.roles.base, id),
    new Role(),
    defaultDataTransform,
    Role
  );

  return (
    <AsyncLoadingSection loadingStatus={fetchStatus} useMask>
      <TaskPageHeaderLayout
        title={addEditTitle(entityName, isAdd)}
        formProviderProps={{
          formModel: data,
          endpoint: ENDPOINTS.roles.base,
          handleSuccess: handleSuccess,
        }}
      >
        <RolesAddEditFormContent />
      </TaskPageHeaderLayout>
    </AsyncLoadingSection>
  );
};

const RolesAddEditFormContent: React.FC = () => {
  return (
    <PageCard>
      <PageCardRow>
        <PageCardSection short>
          <Row>
            <SmartInputBlock
              name="name"
              label="Name"
              type={InputType.Text}
              saveOnDebounce
            />
          </Row>
          <Row>
            <SmartInputBlock
              name="isNurse"
              label="Nurse"
              type={InputType.Checkbox}
              saveOnDebounce
            />
            <SmartInputBlock
              name="isDoctor"
              label="Doctor"
              type={InputType.Checkbox}
              saveOnDebounce
            />
            <SmartInputBlock
              name="isMedicalDirector"
              label="Medical Director"
              type={InputType.Checkbox}
              saveOnDebounce
            />
            <SmartInputBlock
              name="isWriter"
              label="Writer"
              type={InputType.Checkbox}
              saveOnDebounce
            />
            <SmartInputBlock
              name="isReviewer"
              label="Reviewer"
              type={InputType.Checkbox}
              saveOnDebounce
            />
            <SmartInputBlock
              name="isDoctorAdmin"
              label="Doctor Admin"
              type={InputType.Checkbox}
              saveOnDebounce
            />
            <Tooltip anchorElement="target" position="top">
              <div title="Administrators can see all clients regardless of assignation">
                <SmartInputBlock
                  name="isAdmin"
                  label="Administrator"
                  type={InputType.Checkbox}
                  saveOnDebounce
                />
              </div>
            </Tooltip>
            <SmartInputBlock
              name="isInternal"
              label="Internal"
              type={InputType.Checkbox}
              saveOnDebounce
            />
          </Row>
        </PageCardSection>
      </PageCardRow>
      <PageCardRow>
        <PageCardSection title="Permissions">
          <SmartCheckboxList
            name="permissionsList"
            labelField="name"
            checkedField="isSelected"
            groupField="groupName"
            tooltipField="description"
            defaultClosed
          />
        </PageCardSection>
      </PageCardRow>
    </PageCard>
  );
};
