import './CollapsibleSection.scss';
import React from 'react';
import Delete from '@mui/icons-material/Delete';
import { TextIconButton, TextIconButtonProps } from 'Components/Form';
import { Col, Row } from 'reactstrap';
import { fullClassName, isNullEmptyOrUndefined, toggledClass } from 'Utilities';
import { Render } from '.';
import { FormSectionHeading, SmallSectionHeading } from './Headings';
import { FlexWrapper } from './LayoutComponents';

type FormListSectionBaseProps = React.HTMLProps<HTMLDivElement> & {
  noPaddingMargin?: boolean;
};

type FormListSectionProps = FormListSectionBaseProps & {
  removeable?: boolean;
  onRemove?: () => void;
  leftAlignDelete?: boolean;
  isLast?: boolean;
  title?: string;
  deleteByTitle?: boolean;
  noSeparator?: boolean;
  rightAlignDelete?: boolean;
  alignDeleteWithInputRow?: boolean;
  smallTitle?: boolean;
};

export const FormListSection: React.FC<FormListSectionProps> = ({
  children,
  className,
  removeable,
  leftAlignDelete,
  onRemove,
  isLast,
  title,
  deleteByTitle,
  noSeparator,
  rightAlignDelete,
  alignDeleteWithInputRow,
  smallTitle,
}) => {
  const handleDeleteClick = (e: any) => {
    e.stopPropagation();
    onRemove && onRemove();
  };

  const TitleWrapper = smallTitle ? SmallSectionHeading : FormSectionHeading;

  return (
    <div
      className={fullClassName(
        'form-list-section',
        toggledClass('border-bottom-light', !isLast && !noSeparator),
        className
      )}
    >
      <Render condition={!isNullEmptyOrUndefined(title)}>
        <FlexWrapper className="flex-vertical-center mb-regular">
          <TitleWrapper name={title} noPaddingMargin />
          <Render condition={removeable && deleteByTitle}>
            <FormListSectionDeleteButton
              className="ml-twenty"
              onClick={handleDeleteClick}
            />
          </Render>
        </FlexWrapper>
      </Render>
      <Row
        className={fullClassName(
          toggledClass('flex-row-reverse', leftAlignDelete)
        )}
      >
        <Col className={toggledClass('flex-grow', rightAlignDelete)} xs="auto">
          {children}
        </Col>
        <Render condition={removeable && !deleteByTitle}>
          <div className={fullClassName('flex-column flex-center')}>
            <FormListSectionDeleteButton
              className={fullClassName(
                'pr-regular',
                toggledClass('pt-label', alignDeleteWithInputRow)
              )}
              onClick={handleDeleteClick}
            />
          </div>
        </Render>
      </Row>
    </div>
  );
};

export const FormListSectionDeleteButton: React.FC<TextIconButtonProps> = ({
  onClick,
  className,
}) => {
  return (
    <TextIconButton
      icon={Delete}
      className={fullClassName('section-delete-button', className)}
      onClick={onClick}
    />
  );
};
