import {
  GridCellProps,
  GridColumn as Column,
} from '@progress/kendo-react-grid';
import { AddButton } from 'Components/Form/Buttons';
import { SecureComponent } from 'Components/Security';
import {
  addEditRoute,
  AddEditSubRoute,
  GuidelineSubRoute,
  ROUTES,
} from 'Navigation';
import React from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { useGridSearch } from 'Utilities';
import { LightPageHeader } from '../../Components/Display';
import {
  EditDeleteCell,
  FilterBarMainGrid,
  GridButtonSection,
  LinkCell,
} from '../../Components/Grid';
import { FormProvider, GridProvider, useEnums } from '../../Context';
import {
  defaultGridProps,
  FilterBarFilterType,
  FilterConfig,
  IFilterConfig,
} from '../../Models';
import { Permission } from '../../Models/Templates/Permission/Permission';
import { ENDPOINTS } from '../../Models/Templates/Routes/Routes';

export enum GuidelineGridIds {
  DetailedSearch = 'detailedSearch',
}

export const Guidelines: React.FC = () => {
  let guidelineSearch = new GuidelineSearch();

  return (
    <FormProvider formModel={guidelineSearch}>
      <GridProvider entityName="Guideline">
        <Breadcrumb tag="nav" listTag="div">
          <BreadcrumbItem>
            <Link to={ROUTES.DASHBOARD}>Home</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>Guidelines</BreadcrumbItem>
        </Breadcrumb>
        <LightPageHeader tall noBorder className="page-header-shadow">
          <h1>Guidelines</h1>
        </LightPageHeader>
        <GuidelinesGrid />
      </GridProvider>
    </FormProvider>
  );
};

class GuidelineSearch {
  searchKey?: string;
}

const GuidelinesGrid: React.FC = () => {
  const [gridToggler] = useGridSearch();
  const { state } = useEnums();

  const filters: IFilterConfig[] = [
    new FilterConfig('searchKey', FilterBarFilterType.SearchText, 'Title'),
    new FilterConfig(
      'benefitState',
      FilterBarFilterType.EnumDropdown,
      'Benefit State',
      { data: state }
    ),
  ];
  return (
    <React.Fragment>
      <SecureComponent permissions={[Permission.AddGuideline]}>
        <GridButtonSection>
          <AddButton url={addEditRoute(AddEditSubRoute.Guideline)}>
            New Guideline
          </AddButton>
        </GridButtonSection>
      </SecureComponent>
      <FilterBarMainGrid
        isMainGrid
        filterBarProps={{
          filterConfigurations: filters,
          filterValues: [],
        }}
        refreshToggle={gridToggler}
        readEndpoint={ENDPOINTS.guidelines.read}
        {...defaultGridProps}
      >
        <Column field="title" cell={NameCell} title="Title" />
        <Column field="benefitState.displayName" title="Benefit State" />
        <Column field="id" title="Actions" width={80} cell={ActionsCell} />
      </FilterBarMainGrid>
    </React.Fragment>
  );
};

const ActionsCell: React.FC<GridCellProps> = (props: GridCellProps) => {
  return (
    <EditDeleteCell
      editRoute={addEditRoute(AddEditSubRoute.Guideline)}
      editPermissions={[Permission.EditGuideline]}
      deleteEndpoint={ENDPOINTS.guidelines.base}
      deletePermissions={[Permission.DeleteGuideline]}
      {...props}
    />
  );
};

const NameCell: React.FC<GridCellProps> = (props: GridCellProps) => {
  return (
    <LinkCell
      endpoint={ROUTES.GUIDELINE}
      subRoute={GuidelineSubRoute.View}
      linkField="id"
      {...props}
    ></LinkCell>
  );
};
