import { SubRoutes } from 'Navigation/NavigationTypes';
import { AdminSubRouteConfig } from 'Navigation/NavigationTypes';
import {
  UserManagementManagedReviewers,
  UserManagementRoles,
  UserManagementUsers,
} from 'Features/UserManagement';
import { Permission } from 'Models/Templates/Permission/Permission';

export enum UserManagementSubRoute {
  Roles = '/Roles',
  Users = '/Users',
  ManagedReviewers = '/ManagedReviewers',
  NotificationTemplates = '/NotificationTemplates',
}

export const UserManagementSubRoutes: SubRoutes = {
  [UserManagementSubRoute.Roles]: new AdminSubRouteConfig(
    UserManagementSubRoute.Roles,
    'Roles',
    UserManagementRoles,
    true,
    [Permission.ViewRole, Permission.AddRole, Permission.EditRole]
  ),
  [UserManagementSubRoute.Users]: new AdminSubRouteConfig(
    UserManagementSubRoute.Users,
    'Users',
    UserManagementUsers,
    false,
    [Permission.ViewUser, Permission.AddUser, Permission.EditUser]
  ),
  [UserManagementSubRoute.ManagedReviewers]: new AdminSubRouteConfig(
    UserManagementSubRoute.ManagedReviewers,
    'Managed Reviewers',
    UserManagementManagedReviewers,
    false,
    [Permission.ViewManagedReviewer, Permission.AssignManagedReviewer]
  ),
};
