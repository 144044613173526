import React, { useEffect, useRef } from "react";
import { Editor, EditorTools } from "@progress/kendo-react-editor";
import { InputBlockProps } from "Components/Form";
import CSS from "csstype";
import { useDebounce, useToggler } from "Utilities";

/*
  Button options for Rich Text Editor
*/
const {
  Bold,
  Italic,
  Underline,
  Strikethrough,
  Subscript,
  Superscript,
  AlignLeft,
  AlignCenter,
  AlignRight,
  AlignJustify,
  Indent,
  Outdent,
  OrderedList,
  UnorderedList,
  Undo,
  Redo,
  FontSize,
  FontName,
  FormatBlock,
  Link,
  Unlink,
  InsertImage,
  ViewHtml,
  InsertTable,
  AddRowBefore,
  AddRowAfter,
  AddColumnBefore,
  AddColumnAfter,
  DeleteRow,
  DeleteColumn,
  DeleteTable,
  MergeCells,
  SplitCell,
} = EditorTools;

export type RichTextInputProps = InputBlockProps;

export const RichTextInput: React.FC<RichTextInputProps> = ({
  onChange,
  value,
  ...allProps
}) => {
  const lastChangeEvent = useRef<any>(null);
  const [debounceToggle, toggleDebounce] = useToggler(false);
  const debouncedValue = useDebounce(debounceToggle, 100);

  const richTextStyle: CSS.Properties = {
    width: "90%",
    border: "1px solid rgba(0, 0, 0, 0.42)",
  };

  const handleChange = (e: any) => {
    toggleDebounce();
    lastChangeEvent.current = e;
  };

  useEffect(() => {
    onChange &&
    lastChangeEvent &&
    lastChangeEvent.current &&
    onChange(lastChangeEvent.current);
  }, [debouncedValue]);

  return (
    <Editor
      defaultContent={value}
      onChange={handleChange}
      tools={[
        [Bold, Italic, Underline, Strikethrough],
        [Subscript, Superscript],
        [AlignLeft, AlignCenter, AlignRight, AlignJustify],
        [Indent, Outdent],
        [OrderedList, UnorderedList],
        FontSize,
        FontName,
        FormatBlock,
        [Undo, Redo],
        [Link, Unlink, InsertImage, ViewHtml],
        [InsertTable],
        [AddRowBefore, AddRowAfter, AddColumnBefore, AddColumnAfter],
        [DeleteRow, DeleteColumn, DeleteTable],
        [MergeCells, SplitCell],
      ]}
      style={richTextStyle}
      {...allProps}
    />
  );
};
