import CheckCircle from '@mui/icons-material/CheckCircle';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import {
  AsyncLoadingSection,
  DisplayBlock,
  DisplayListBlock,
  ModalDrawerButtonRow,
  MultiModalFormDrawer,
  Render,
} from 'Components/Display';
import {
  ButtonStyle,
  FormSubmitButton,
  IconButton,
  ModalFormCancelButton,
  SmartCheckboxList,
  SmartFileUploader,
  SmartInputBlock,
} from 'Components/Form';
import { SecureComponent } from 'Components/Security';
import {
  FormActionTypes,
  useEntity,
  useFormState,
  useFormUpdater,
  useGridState,
  useModalHelpers,
} from 'Context';
import { ColSize, InputType } from 'Models';
import {
  EntityDocumentCombineModel,
  AppointmentDocumentModal,
  AppointmentDocumentModel,
} from 'Models/AppointmentDocumentModel';
import { Permission } from 'Models/Templates/Permission/Permission';
import { ENDPOINTS } from 'Models/Templates/Routes/Routes';
import React, { Fragment, useEffect, useState } from 'react';
import { FormLabel } from 'react-bootstrap';
import { Col, Label, Row } from 'reactstrap';
import {
  buildEndpoint,
  EMPTY_GUID,
  useDataFetcher,
  useHasPermissions,
} from 'Utilities';

export type AppointmentDocumentModalFormProps = {
  endpoint?: string;
  formModel?: AppointmentDocumentModel;
};

export type AppointmentDocumentCombineModalFormProps = {
  endpoint?: string;
  formModel?: EntityDocumentCombineModel;
};

export const AppointmentDocumentDrawerForm: React.FC<
  AppointmentDocumentModalFormProps
> = ({ formModel, ...props }) => {
  const entity = useEntity();
  formModel = new AppointmentDocumentModel(
    entity.appointment?.id,
    entity.referralDate
  );
  const { simpleSuccessHandler } = useModalHelpers(
    AppointmentDocumentModal.Attachments
  );
  const { refreshGrid } = useGridState();

  const handleSuccess = () => {
    simpleSuccessHandler('Appointment Document has been updated.');
    refreshGrid();
  };

  return (
    <MultiModalFormDrawer
      modalId={AppointmentDocumentModal.Attachments}
      submitButtonText="Save"
      formModel={formModel}
      endpoint={props.endpoint}
      handleSuccess={handleSuccess}
    >
      <AppointmentDocumentDrawerFormContent {...props} />
    </MultiModalFormDrawer>
  );
};

export const AppointmentDocumentDrawerFormContent: React.FC<
  AppointmentDocumentModalFormProps
> = ({ ...props }) => {
  const entity = useEntity();
  const { isOpen, entityId } = useModalHelpers(
    AppointmentDocumentModal.Attachments
  );
  const sendUpdate = useFormUpdater();
  const { formValues } = useFormState();
  const [fileAdd, setIsAdd] = useState(entityId === undefined);
  const isAdd = entityId === undefined;

  const removeTemplateFile = () => {
    setIsAdd(!isAdd);
    sendUpdate(FormActionTypes.UpdateValue, { field: 'fileId', value: null });
  };

  let [appointmentDocumentModel, fetchStatus] =
    useDataFetcher<AppointmentDocumentModel>(
      buildEndpoint(
        ENDPOINTS.appointmentdocuments.base,
        entityId ?? EMPTY_GUID
      ),
      new AppointmentDocumentModel(entity.id),
      ({ data }) => data,
      undefined,
      isOpen
    );

  useEffect(() => {
    if (!isAdd) {
      sendUpdate(FormActionTypes.SetValues, appointmentDocumentModel);
    }
  }, [appointmentDocumentModel, sendUpdate]);

  return (
    <AsyncLoadingSection loadingStatus={fetchStatus} noIdleLoading>
      <Render condition={!fileAdd && (formValues?.fileId || false)}>
        <Row>
          <IconButton
            iconElement={HighlightOffIcon}
            primary
            buttonStyle={ButtonStyle.Bare}
            onClick={removeTemplateFile}
          />
          <DisplayBlock value={formValues?.fileName} title="File" />
        </Row>
      </Render>
      <SmartFileUploader
        label="Files"
        required
        fileEndpoint={ENDPOINTS.appointmentdocuments.base}
        isEdit={!fileAdd}
        vertical
        withComments={false}
        allowMultiple={false}
        renderAdditionalFormComponents={RenderAdditionalFormComponentsProp}
      />
      <ModalDrawerButtonRow>
        <ModalFormCancelButton />
        <FormSubmitButton>Save Document</FormSubmitButton>
      </ModalDrawerButtonRow>
    </AsyncLoadingSection>
  );
};

const RenderAdditionalFormComponentsProp = () => {
  return (
    <Fragment>
      <Col>
        <Row>
          <SmartInputBlock
            name="title"
            label="Title"
            type={InputType.Text}
            colProps={{ xs: ColSize.Full }}
            required
          />
          <SmartInputBlock
            name="pageCount"
            label="Page Count"
            type={InputType.Number}
            colProps={{ xs: ColSize.Full }}
          />
          <SmartInputBlock
            name="documentDate"
            label="Document Date"
            type={InputType.DateTimePicker}
            required
          />
        </Row>
      </Col>
    </Fragment>
  );
};

export const AppointmentDocumentNotesForm: React.FC<
  AppointmentDocumentModalFormProps
> = ({ formModel, ...props }) => {
  const { simpleSuccessHandler } = useModalHelpers(
    AppointmentDocumentModal.Attachments
  );
  const { refreshGrid } = useGridState();
  const entity = useEntity();
  const { isOpen, entityId } = useModalHelpers(AppointmentDocumentModal.Notes);

  let [appointmentDocumentModel, fetchStatus] =
    useDataFetcher<AppointmentDocumentModel>(
      buildEndpoint(
        ENDPOINTS.appointmentdocuments.base,
        entityId ?? EMPTY_GUID
      ),
      new AppointmentDocumentModel(entity.id),
      ({ data }) => data,
      undefined,
      isOpen
    );
  appointmentDocumentModel.isNotes = true;
  const handleSuccess = () => {
    simpleSuccessHandler(
      'Appointment Document Notes have been successfully updated.'
    );
    refreshGrid();
  };

  return (
    <MultiModalFormDrawer
      title={`${formModel?.title ?? "Document's"} Note`}
      modalId={AppointmentDocumentModal.Notes}
      submitPermissions={[Permission.AddEditAppointmentDocumentNote]}
      formModel={appointmentDocumentModel}
      endpoint={props.endpoint}
      handleSuccess={handleSuccess}
    >
      <AppointmentDocumentNotesFormContent {...props} />
    </MultiModalFormDrawer>
  );
};

export const AppointmentDocumentNotesFormContent: React.FC = () => {
  const formState = useFormState();
  const hasPermission = useHasPermissions();
  return (
    <Fragment>
      <Row>
        <Col>
          <SecureComponent
            permissions={[Permission.AddEditAppointmentDocumentNote]}
          >
            <SmartInputBlock
              name="comments"
              label="Notes"
              type={InputType.TextArea}
              colProps={{ xs: ColSize.Full }}
            />
          </SecureComponent>
          <Render
            condition={
              hasPermission([Permission.ViewAppointmentDocumentNote]) &&
              !hasPermission([Permission.AddEditAppointmentDocumentNote])
            }
          >
            <DisplayBlock
              value={formState.formValues?.comments}
              title="Notes"
            />
          </Render>
        </Col>
      </Row>
      <ModalDrawerButtonRow>
        <ModalFormCancelButton />
        <FormSubmitButton>Save Note</FormSubmitButton>
      </ModalDrawerButtonRow>
    </Fragment>
  );
};

export const AppointmentDocumentCombineForm: React.FC<
AppointmentDocumentCombineModalFormProps
> = ({ formModel, ...props }) => {
  const entity = useEntity();
  formModel = new EntityDocumentCombineModel(
    entity.appointment?.id,
  );
  const { simpleSuccessHandler } = useModalHelpers(
    AppointmentDocumentModal.Combine
  );
  const { refreshGrid } = useGridState();

  const handleSuccess = () => {
    simpleSuccessHandler('Documents have been combined');
    refreshGrid();
  };

  return (
    <MultiModalFormDrawer
      modalId={AppointmentDocumentModal.Combine}
      submitButtonText="Save"
      formModel={formModel}
      endpoint={buildEndpoint(ENDPOINTS.appointmentdocuments.base,
        'CombineMedicals',
        formModel?.entityId)}
      handleSuccess={handleSuccess}
    >
      <AppointmentDocumentCombineFormContent formModel={formModel} {...props} />
    </MultiModalFormDrawer>
  );
};

export const AppointmentDocumentCombineFormContent: React.FC<
  AppointmentDocumentCombineModalFormProps
> = ({ formModel, ...props }) => {
  const sendUpdate = useFormUpdater();
  const { isOpen } = useModalHelpers(
    AppointmentDocumentModal.Combine
  );

  let [combinableFiles, fetchStatus] =
    useDataFetcher<AppointmentDocumentModel[]>(
      buildEndpoint(
        ENDPOINTS.appointmentdocuments.base,
        'GetCombinableMedicals',
        formModel?.entityId
      ),
      [],
      ({ data }) => data,
      undefined,
      isOpen
    );

    useEffect(() => {
      sendUpdate(FormActionTypes.SetValues, combinableFiles);
  }, [combinableFiles, sendUpdate]);

  return (
    <AsyncLoadingSection loadingStatus={fetchStatus} noIdleLoading>
      <Row>
        <Col>
          <FormLabel>
            Combinable files
          </FormLabel>
        </Col>
      </Row>
      <Row>
        <Col>
          <SmartCheckboxList
                name="documentsToCombine"
                labelField="fileName"
                checkedField="isSelected"
                groupField='groupName'
                hideCheckAll
              />
        </Col>
      </Row>
      <ModalDrawerButtonRow>
        <ModalFormCancelButton />
        <FormSubmitButton>Combine Documents</FormSubmitButton>
      </ModalDrawerButtonRow>
    </AsyncLoadingSection>
  );
};