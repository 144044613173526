import React from 'react';

//classes
export class SubRoutes {
  [x: string]: SubRouteConfig;
}

export enum EnumTypeSample {
  type1 = 1,
  type2 = 2,
  type3 = 3,
  type4 = 4,
  type5 = 5,
}

export class SubRouteConfig {
  constructor(
    route: string,
    label: string,
    component: React.FC<any>,
    icon?: any,
    isDefault = false,
    permissions?: number[],
    hideInSidebar = false,
    routeProps = {},
    showInDropdown = false
  ) {
    this.route = route;
    this.label = label;
    this.component = component;
    this.icon = icon;
    this.default = isDefault;
    this.permissions = permissions;
    this.hideInSidebar = hideInSidebar;
    this.routeProps = routeProps;
    this.showInDropdown = showInDropdown;
  }

  label: string;
  icon?: any;
  route: string;
  component: React.FC<any>;
  default: boolean;
  hideInSidebar: boolean;
  permissions?: number[];
  routeProps?: any;
  showInDropdown?: boolean;
}

//removes need for passing icon as undefined
export class AdminSubRouteConfig extends SubRouteConfig {
  constructor(
    route: string,
    label: string,
    component: React.FC<any>,
    isDefault = false,
    permissions?: number[],
    hideInSidebar = false,
    routeProps = {}
  ) {
    super(
      route,
      label,
      component,
      undefined,
      isDefault,
      permissions,
      hideInSidebar,
      routeProps
    );
  }
}

export class DropdownSubRouteConfig extends SubRouteConfig {
  constructor(
    route: string,
    label: string,
    component: React.FC<any>,
    permissions?: number[],
    isDefault = false,
    routeProps = {},
    icon?: any
  ) {
    super(
      route,
      label,
      component,
      icon,
      isDefault,
      permissions,
      false,
      routeProps,
      true
    );
  }
}

export class ActionsSubRouteConfig extends SubRouteConfig {
  constructor(route: string, component: React.FC<any>, permissions?: number[]) {
    super(route, '', component, undefined, false, permissions, true, {
      exact: true,
    });
  }
}

//types
export type NavConfigItem = {
  label: string;
  icon?: React.ReactNode;
  route: string;
  fullRoute?: string;
  navItemsUrl?: string;
  permissions?: number[];
  active?: boolean;
  default?: boolean;
  component?: React.FC<any>;
  hideInSidebar?: boolean;
  children?: NavConfigItem[];
  showInDropdown?: boolean;
  subRouteName?: string;
};

export type SubNavConfigMap = {
  [x: string]: NavConfigItem[];
};

export type RouteParameterMap = {
  [x: string]: string;
};

//helper constants
export const ADD_EDIT = 'AddEdit';
export const VIEW = 'View';
