import React from 'react';
import { Link } from 'react-router-dom';
import { GridCellProps } from '@progress/kendo-react-grid';
import { buildRoute, fullClassName, toggledClass } from 'Utilities';
import { DateCellProps } from '../InputCells/DateCell';
import { GridInput } from '../InputCells/GridInput';
import { InputType } from 'Models';
import moment from 'moment';

export type LinkCellProps = {
  endpoint: string;
  linkField: string;
  viewLinkField?: string;
  subRoute?: string;
  openInNewTab?: boolean;
  forceReload?: boolean;
  wrappable?: boolean;
  convertFunction?: any;
  customClassName?: string;
};

export function getLinkCell(props: LinkCellProps) {
  return (gridCellProps: GridCellProps) => (
    <LinkCell {...gridCellProps} {...props} />
  );
}

export const LinkCell: React.FC<GridCellProps & LinkCellProps> = ({
  dataItem,
  field,
  endpoint,
  subRoute,
  linkField,
  openInNewTab,
  viewLinkField,
  forceReload,
  wrappable,
  convertFunction,
}) => {
  const value = dataItem[field as string];
  const linkValue = dataItem[linkField];
  const viewLinkValue =
    viewLinkField === undefined ? '' : dataItem[viewLinkField];

  function refreshPage() {
    setTimeout(() => {
      window.location.reload();
    }, 10);
  }

  return (
    <td
      className={fullClassName(
        'color-primary',
        toggledClass('whitespace-preline', wrappable)
      )}
    >
      {openInNewTab ? (
        <Link
          to={buildRoute(endpoint, linkValue, subRoute, viewLinkValue)}
          target="_blank"
        >
          {convertFunction ? convertFunction(value) : value}
        </Link>
      ) : forceReload ? (
        <Link
          to={buildRoute(endpoint, linkValue, subRoute, viewLinkValue)}
          onClick={refreshPage}
        >
          {convertFunction ? convertFunction(value) : value}
        </Link>
      ) : (
        <Link to={buildRoute(endpoint, linkValue, subRoute, viewLinkValue)}>
          {convertFunction ? convertFunction(value) : value}
        </Link>
      )}
    </td>
  );
};

export const DateLinkCell: React.FC<
  GridCellProps & LinkCellProps & DateCellProps
> = ({
  dataItem,
  field,
  onChange,
  endpoint,
  subRoute,
  linkField,
  openInNewTab,
  viewLinkField,
  forceReload,
  wrappable,
  dateFormat,
  customClassName,
}) => {
  const value = dataItem[field as string];
  const linkValue = dataItem[linkField];
  const viewLinkValue =
    viewLinkField === undefined ? '' : dataItem[viewLinkField];

  function refreshPage() {
    setTimeout(() => {
      window.location.reload();
    }, 10);
  }

  return (
    <td
      className={fullClassName(
        'color-primary',
        toggledClass('whitespace-preline', wrappable),
        customClassName
      )}
    >
      {openInNewTab ? (
        <Link
          to={buildRoute(endpoint, linkValue, subRoute, viewLinkValue)}
          target="_blank"
        >
          {dataItem.inEdit ? (
            <GridInput
              dataItem={dataItem}
              name={field as string}
              type={InputType.DatePicker}
              onChange={onChange}
              value={value}
            />
          ) : value ? (
            dateFormat ? (
              moment(value).format(dateFormat)
            ) : (
              moment(value).format('l')
            )
          ) : (
            ''
          )}
        </Link>
      ) : forceReload ? (
        <Link
          to={buildRoute(endpoint, linkValue, subRoute, viewLinkValue)}
          onClick={refreshPage}
        >
          {dataItem.inEdit ? (
            <GridInput
              dataItem={dataItem}
              name={field as string}
              type={InputType.DatePicker}
              onChange={onChange}
              value={value}
            />
          ) : value ? (
            dateFormat ? (
              moment(value).format(dateFormat)
            ) : (
              moment(value).format('l')
            )
          ) : (
            ''
          )}
        </Link>
      ) : (
        <Link to={buildRoute(endpoint, linkValue, subRoute, viewLinkValue)}>
          {dataItem.inEdit ? (
            <GridInput
              dataItem={dataItem}
              name={field as string}
              type={InputType.DatePicker}
              onChange={onChange}
              value={value}
            />
          ) : value ? (
            dateFormat ? (
              moment(value).format(dateFormat)
            ) : (
              moment(value).format('l')
            )
          ) : (
            ''
          )}
        </Link>
      )}
    </td>
  );
};
