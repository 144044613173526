import {
  AsyncLoadingSection,
  SingleSectionPageCard,
  TaskPageHeaderLayout,
} from 'Components/Display';
import {
  defaultFormatName,
  ListSectionProps,
  SmartInputBlock,
  SmartMultiSectionList,
} from 'Components/Form';
import { useEnums } from 'Context';
import { ColSize, InputType } from 'Models';
import { Guideline } from 'Models/GuidelineModel';
import { ENDPOINTS } from 'Models/Templates/Routes/Routes';
import React, { Fragment } from 'react';
import { useParams } from 'react-router-dom';
import { Row } from 'reactstrap';
import {
  addEditTitle,
  buildEndpoint,
  buildQueryUrl,
  buildRoute,
  defaultDataTransform,
  useAddEditSuccessHandler,
  useDataFetcher,
  useIdParam,
} from 'Utilities';

export const AddEditGuideline: React.FC = () => {
  const entityName = 'Guideline';
  const [id, isAdd] = useIdParam();
  const handleSuccess = useAddEditSuccessHandler(entityName, isAdd);
  const { Guidelineantid } = useParams<any>();
  const [data, loadingStatus] = useDataFetcher<Guideline>(
    buildRoute(ENDPOINTS.guidelines.base, id),
    new Guideline(),
    defaultDataTransform,
    Guideline
  );

  return (
    <AsyncLoadingSection loadingStatus={loadingStatus} useMask>
      <TaskPageHeaderLayout
        title={addEditTitle(entityName, isAdd)}
        formProviderProps={{
          formModel: data,
          endpoint: ENDPOINTS.guidelines.base,
          handleSuccess: handleSuccess,
        }}
      >
        <GuidelinesAddEditFormContent />
      </TaskPageHeaderLayout>
    </AsyncLoadingSection>
  );
};

const GuidelinesAddEditFormContent: React.FC = () => {
  type ListObject = {
    textValue?: string;
    dateValue?: Date;
  };

  const { state } = useEnums();

  const initialListObjectValue: ListObject = {
    textValue: 'Initial Text Value',
    dateValue: new Date(),
  };

  return (
    <Fragment>
      <SingleSectionPageCard cardTitle="Details" centerContent inputSection>
        <SmartInputBlock name="GuidelineantId" type={InputType.Text} hidden />
        <Row>
          <SmartInputBlock
            name="title"
            label="Title"
            type={InputType.Text}
            colProps={{ xs: ColSize.OneThird }}
            required
          />
          <SmartInputBlock
            name="benefitState"
            label="Benefit State"
            type={InputType.EnumDropdown}
            data={state}
          />
        </Row>
        <Row>
          <SmartInputBlock
            name="description"
            label="Description"
            type={InputType.TextArea}
            colProps={{ xs: ColSize.OneThird }}
            required
          />
        </Row>
        <Row>
          <SmartMultiSectionList
            listName="guidelineLink"
            listObjectName="Links"
            initialValues={initialListObjectValue}
          >
            <GuidelineLinks />
          </SmartMultiSectionList>
        </Row>
      </SingleSectionPageCard>
    </Fragment>
  );
};

export const GuidelineLinks: React.FC<ListSectionProps> = ({
  formatInputName = defaultFormatName,
}) => {
  const { state } = useEnums();

  return (
    <Fragment>
      <Row>
        <SmartInputBlock
          type={InputType.Text}
          name={formatInputName('link.title')}
          label="Title"
          required
          width={200}
        />
        <SmartInputBlock
          type={InputType.Text}
          name={formatInputName('link.url')}
          label="URL"
          required
          width={400}
        />
      </Row>
    </Fragment>
  );
};
