import { GridCellProps } from '@progress/kendo-react-grid';
import { ActionsEllipsisCell } from 'Components/Grid';
import { 
  useGridState, 
  useModalFormState, 
  useToasts 
} from 'Context';
import { ActionsDropdownConfig } from 'Models';
import {
  AppointmentReportModal,
  AppointmentReportModel,
} from 'Models/AppointmentReportModel';
import { Permission } from 'Models/Templates/Permission/Permission';
import { ENDPOINTS } from 'Models/Templates/Routes/Routes';
import React from 'react';
import {
  buildEndpoint, 
  useApiWorker 
} from 'Utilities';
import { 
  AppointmentReportNotesForm, 
  AppointmentReportDrawerForm, 
  AppointmentReportGuidelinesForm 
} from '.';

export const AppointmentReportActionCell: React.FC<GridCellProps> = ({
  dataItem,
  field = '',
}) => {
  const API = useApiWorker();
  const toast = useToasts();
  const { refreshGrid } = useGridState();
  const { toggle, setEntityId } = useModalFormState();

  const value = dataItem[field];
  
  const editItem = () => {
    setEntityId(value);
    toggle();
  };

  const deleteItem = async () => {
    if (window.confirm('Are you sure you want to delete report?')) {
      try {
        var deleteResponse = await API.delete(
          buildEndpoint(ENDPOINTS.appointmentsreportsbundles.base, value)
        );
        if (deleteResponse.data.isSuccessStatusCode) {
          toast.success('Report has been removed.');
        } else if (deleteResponse.headers['exception'] === undefined) {
          toast.error('Fail removing report.');
        } else {
          toast.error(deleteResponse.headers['exception']);
        }
        refreshGrid && refreshGrid();
      } catch {
        toast.error('Fail removing report.');
      }
    }
  };

  const actionsConfig: ActionsDropdownConfig = {
    items: [
      {
        name: 'Edit',
        permissions: [
          dataItem.reviewed ? Permission.EditReportAfterReviewed : Permission.EditAppointmentReport,
         
        ],
        modalConfig: {
          modalId: AppointmentReportModal.AttachmentsReport,
          entityId: value,
          modalComponent: (
            <AppointmentReportDrawerForm
              formModel={new AppointmentReportModel(value)}
              endpoint={ENDPOINTS.appointmentsreportsbundles.base}
            />
          ),
        },
      },
      {
        name: 'Notes',
        permissions: [
          Permission.AddEditAppointmentReportNote,
          Permission.ViewAppointmentReportNote,
        ],
        modalConfig: {
          modalId: AppointmentReportModal.Notes,
          entityId: value,
          modalComponent: (
            <AppointmentReportNotesForm
              formModel={new AppointmentReportModel(value)}
              endpoint={ENDPOINTS.appointmentsreportsbundles.base}
            />
          ),
        },
      },
      {
        name: 'Guidelines',
        permissions: [
          Permission.ViewGuideline
        ],
        modalConfig: {
          modalId: AppointmentReportModal.Guidelines,
          entityId: value,
          modalComponent: (
            <AppointmentReportGuidelinesForm
              formModel={new AppointmentReportModel(value)}
              endpoint={ENDPOINTS.appointmentsreportsbundles.base}
            />
          ),
        },
      },
      {
        name: 'Delete',
        onClick: deleteItem,
        permissions: [Permission.DeleteAppointmentReport],
      },
    ],
  };

  return (
    <ActionsEllipsisCell actionsConfig={actionsConfig} dataItem={dataItem} />
  );
};
