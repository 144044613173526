import {
  AdminSubHeaderMainEditLayout,
  PageCard,
  PageCardSection,
} from 'Components/Display';
import { useEntity } from 'Context';
import { addEditRoute, AddEditSubRoute, ROUTES } from 'Navigation';
import React, { Fragment, useEffect } from 'react';
import AccessTime from '@mui/icons-material/AccessTime';
import {
  faUserMd,
  faBusinessTime,
  faBuilding,
} from '@fortawesome/free-solid-svg-icons';
import { ViewReferralBreadcrumbs } from '.';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Row } from 'reactstrap';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { PrimaryButton } from 'Components/Form';
import { makeStyles } from '@mui/styles';
import { ProgressBar } from 'react-bootstrap';
import { SecureComponent } from 'Components/Security';
import { Permission } from 'Models/Templates/Permission/Permission';

export const ReferralMain: React.FC = () => {
  const entity = useEntity();
  const history = useHistory();
  const redirectToAddedit = () => {
    history.push(
      addEditRoute(AddEditSubRoute.Appointment, entity.appointment.id)
    );
  };

  const useStyles = makeStyles({
    progressBar: {
      width: '100%',
      height: 25,
      fontSize: '.9rem',
      marginBottom: '1rem',
    },
    emptyChunk: {
      background: '#e9ecef',
      color: '#b2b1b1',
      borderRightStyle: 'solid',
      borderRightColor: 'white',
      borderRightWidth: 2,
    },
    filledChunk: {
      background: '#28a745',
      borderRightStyle: 'solid',
      borderRightColor: 'white',
      borderRightWidth: 2,
    },
    lastEmptyChunk: {
      background: '#e9ecef',
      color: '#b2b1b1',
    },
    lastFilledChunk: {
      background: '#28a745',
    },
  });

  const classes = useStyles();

  //Value represents "percentage" in ProgressBar
  const [value, setValue] = React.useState(0);

  //Assign Appointment Status value
  let appointmentStatusValue =
    entity.appointment != undefined &&
      entity?.appointment?.appointmentStatus != null
      ? entity?.appointment?.appointmentStatus?.value
      : null;

  //setValue according current Appointment Status
  useEffect(() => {
    switch (appointmentStatusValue) {
      case 1: {
        setValue(25);
        break;
      }
      case 2: {
        setValue(50);
        break;
      }
      case 3: {
        setValue(75);
        break;
      }
      case 4: {
        setValue(50);
        break;
      }
      case 5: {
        setValue(100);
        break;
      }
      case 6: {
        setValue(100);
        break;
      }
      default: {
        break;
      }
    }
  }, [appointmentStatusValue]);

  //ProgressBar JSX Element
  let appointmentStatusBar = (
    <Fragment>
      <ProgressBar className={classes.progressBar}>
        <ProgressBar
          className={value > 0 ? classes.filledChunk : classes.emptyChunk}
          now={25}
          label="Open"
        />
        <ProgressBar
          className={value > 25 ? classes.filledChunk : classes.emptyChunk}
          now={25}
          label="Assigned/Under-Review"
        />
        <ProgressBar
          className={value > 50 ? classes.filledChunk : classes.emptyChunk}
          now={25}
          label="In QA"
        />
        <ProgressBar
          className={
            value > 75 ? classes.lastFilledChunk : classes.lastEmptyChunk
          }
          now={25}
          label="Closed"
        />
      </ProgressBar>
    </Fragment>
  );

  let appointmentDateBlock;
  let referralDueDateBlock;
  let reportDueDateBlock;

  if (entity.appointment?.reportDueDate) {
    reportDueDateBlock = (
      <Fragment>
        <Col className="" xs="12" sm="6" lg="2">
          <table className="text-left">
            <tbody>
              <tr>
                <td>
                  <AccessTime className="color-secondary mr-2 header-size-regular " />
                </td>
                <td>
                  <b>Report Due Date</b>
                  <br />
                  {moment(entity.appointment?.reportDueDate).format(
                    'MM/DD/YYYY hh:mm'
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </Col>
      </Fragment>
    );
  }

  if (entity.appointment?.appointmentDate) {
    appointmentDateBlock = (
      <Fragment>
        <Col className="" xs="12" sm="6" lg="2">
          <table className="text-left">
            <tbody>
              <tr>
                <td>
                  <AccessTime className="color-secondary mr-2 header-size-regular " />
                </td>
                <td>
                  <b>Appointment Date</b>
                  <br />
                  {moment(entity.appointment?.appointmentDate).format(
                    'MM/DD/YYYY hh:mm'
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </Col>
        {
          reportDueDateBlock ? 
          <Col className="" xs="12" sm="6" lg="2">
            {reportDueDateBlock}
          </Col> : null
        }
        <Col className="" xs="12" sm="6" lg="2">
          <table className="text-left">
            <tbody>
              <tr>
                <td>
                  <FontAwesomeIcon
                    icon={faUserMd}
                    className="color-secondary  mr-2  header-size-small "
                  />
                </td>
                <td>
                  <b>Professional</b>
                  <br />
                  {entity.appointment?.professionalName}
                </td>
              </tr>
            </tbody>
          </table>
        </Col>
        <SecureComponent permissions={[Permission.EditAppointment]}>
          <Col className="text-center" xs="12" sm="6" lg="2">
            <PrimaryButton onClick={redirectToAddedit}>
              Re-Schedule
            </PrimaryButton>
          </Col>
        </SecureComponent>
      </Fragment>
    );
  } else {
    appointmentDateBlock = (
      <SecureComponent permissions={[Permission.EditAppointment]}>
        <Col className="text-center" xs="12" sm="6" lg="3">
          <div className="text-wrap">
            Pending Assignment
            <PrimaryButton onClick={redirectToAddedit} className="ml-3">
              Schedule
            </PrimaryButton>
          </div>
        </Col>
      </SecureComponent>
    );
  }

  return (
    <Fragment>
      <ViewReferralBreadcrumbs />
      <div>{appointmentStatusBar}</div>
      <PageCard noBottomMargin className="mb-small">
        <PageCardSection short centerContent className="bg-blue-accent ">
          <Row className="justify-content-center">
            <Col className="text-center table-responsive" xs="12" sm="6" lg="2">
              <table className="text-left">
                <tbody>
                  <tr>
                    <td className="lg-1">
                      <FontAwesomeIcon
                        icon={faBuilding}
                        className="color-secondary  header-size-small mr-3"
                      />
                    </td>
                    <td className="lg-1">
                      <b>Client</b>
                      <br />
                      <div className="text-wrap">{entity.clientName}</div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </Col>
            {referralDueDateBlock}
            {appointmentDateBlock}
          </Row>
        </PageCardSection>
      </PageCard>
      <AdminSubHeaderMainEditLayout
        subRoute={ROUTES.REFERRAL}
        title={
          (entity?.stradixNumber ? `#${entity?.stradixNumber} - ` : '') +
          (entity?.name ?? '') +
          (entity?.name == null ? '' : ' - ') +
          ('Referral')
        }
        route={addEditRoute(AddEditSubRoute.Referral, entity.entityId)}
        editPermissions={[Permission.EditReferral]}
      />
    </Fragment>
  );
};
