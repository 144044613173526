import './CustomCheckbox.scss';
import React from 'react';
import { Checkbox, CheckboxProps } from '@progress/kendo-react-inputs';
import { fullClassName, toggledClass } from 'Utilities';

export type CustomCheckboxProps = CheckboxProps & {
  isListCheckbox?: boolean;
  noTopPadding?: boolean;
  className?: string;
};

export const CustomCheckbox: React.FC<CustomCheckboxProps> = ({
  checked,
  value,
  isListCheckbox,
  noTopPadding,
  className,
  ...checkboxProps
}) => {
  return (
    <div
      className={fullClassName(
        'checkbox-wrapper',
        toggledClass('pt-0', noTopPadding),
        toggledClass('list-checkbox', isListCheckbox),
        className
      )}
    >
      <Checkbox
        checked={value as boolean | null}
        value={value}
        {...checkboxProps}
      />
    </div>
  );
};
