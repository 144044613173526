import { GridCellProps, GridColumn } from '@progress/kendo-react-grid';
import { PageSectionHeading } from 'Components/Display';
import {
  AsyncGrid,
  DateCell,
  FileDateCell,
  ReportsFilesDownloadCell,
} from 'Components/Grid';
import {
  GridProvider,
  ModalFormProvider,
  useEntity,
  useGridState,
  useToasts,
} from 'Context';
import {
  ActionsDropdownConfig,
  AppointmentIdGridSearchModel,
  defaultGridProps,
  GridColumnWidth,
} from 'Models';
import {
  AppointmentReportModal,
  AppointmentReportModel,
  ReportTypeField,
} from 'Models/AppointmentReportModel';
import { Permission } from 'Models/Templates/Permission/Permission';
import { ENDPOINTS } from 'Models/Templates/Routes/Routes';
import React, { Fragment } from 'react';
import { AppointmentReportDrawerForm } from '.';
import { AppointmentReportActionCell } from './AppointmentReportActionCell';
import { Tooltip } from '@progress/kendo-react-tooltip';

export const AppointmentReportList: React.FC = () => {
  const entity = useEntity();

  return (
    <Fragment>
      <GridProvider entityName="Appointment Report">
        <CastAttachmentModalFormWrapper />
      </GridProvider>
    </Fragment>
  );
};

const CastAttachmentModalFormWrapper: React.FC = () => {
  const toast = useToasts();
  const entity = useEntity();
  const { refreshGrid, entityName } = useGridState();

  const handleSuccess = () => {
    toast.success(`${entityName} saved!`);
    refreshGrid();
  };

  var formModel = new AppointmentReportModel(
    entity.appointment?.id,
    entity.referralDate
  );

  return (
    <ModalFormProvider
      formModel={formModel}
      endpoint={ENDPOINTS.appointmentsreportsbundles.base}
      handleSuccess={handleSuccess}
    >
      <AppointmentReportGridWithModalDrawer />
    </ModalFormProvider>
  );
};

const AppointmentReportGridWithModalDrawer: React.FC = () => {
  const entity = useEntity();

  const searchModel: AppointmentIdGridSearchModel =
    new AppointmentIdGridSearchModel(entity.appointment?.id);

  var formModel = new AppointmentReportModel(
    entity.appointment?.id,
    entity.referralDate
  );

  const actionsConfig: ActionsDropdownConfig = {
    name: 'Actions',
    items: [
      {
        name: 'Upload Template',
        permissions: [Permission.UploadAppointmentReportTemplate],
        modalConfig: {
          modalId: AppointmentReportModal.AttachmentsReport,
          modalComponent: (
            <AppointmentReportDrawerForm
              endpoint={ENDPOINTS.appointmentsreportsbundles.base}
              formModel={formModel}
              modal={AppointmentReportModal.AttachmentsReport}
              attachmentType="Template"
            />
          ),
        },
      },
    ],
  };

  return (
    <Fragment>
      <PageSectionHeading titleActionsConfig={actionsConfig}>
        Reports
      </PageSectionHeading>
      <Tooltip
        openDelay={100}
        position="bottom"
        anchorElement="target"
        className="tooltip-max-width"
      >
        <AsyncGrid
          readEndpoint={ENDPOINTS.appointmentsreportsbundles.read}
          search={searchModel}
          withCardWrapper
          {...defaultGridProps}
        >
          <GridColumn
            field="templateFile"
            title="Template"
            cell={ReportsDownloadCell}
            width={GridColumnWidth.MedLarge}
          />
          <GridColumn
            field="reportFile"
            title="Report"
            cell={ReportsDownloadCell}
          />
          <GridColumn field="title" title="Title" />
          <GridColumn
            field="escalationDescription"
            title="Esc. Description"
            cell={EscalationDescription}
          />
          <GridColumn
            cell={DateCell}
            field="completedDate"
            title="Date Completed"
          />
          <GridColumn field="reviewedBy" title="Doctor Completed" />
          <GridColumn
            field="id"
            title=" "
            cell={(props: GridCellProps) => (
              <AppointmentReportActionCell {...props} />
            )}
            width={GridColumnWidth.Ellipsis}
          />
        </AsyncGrid>
      </Tooltip>
    </Fragment>
  );
};

//Component to handle what kind of file is going to be downloaded
export const ReportsDownloadCell: React.FC<GridCellProps> = ({ ...props }) => {
  let endpoint =
    props.field == ReportTypeField.TemplateField
      ? ENDPOINTS.appointmentreportstemplates.base
      : ENDPOINTS.appointmentreports.base;
  return (
    <ReportsFilesDownloadCell fileControllerEndpoint={endpoint} {...props} />
  );
};

const FileDateColumn: React.FC<GridCellProps> = (props: GridCellProps) => {
  return <FileDateCell {...props} dateFormat={'MM/DD/YYYY'} />;
};
const EscalationDescription: React.FC<GridCellProps> = (
  props: GridCellProps
) => {
  const value = props.dataItem[props.field as string];

  return <td title={value}>{value}</td>;
};
