import React from 'react';
import { FilterBar, FilterBarProps } from './FilterBar';
import {
  FilterBarProvider,
  FilterBarProviderProps,
  useDebouncedFilterValues,
  useFilterBarState,
  useGlobalContext,
  useGridState,
} from 'Context';
import { AsyncGrid, AsyncGridProps } from '../AsyncGrid';
import {
  SelectableAsyncGrid,
  SelectableAsyncGridProps,
} from '../SelectableGrid';
import { Render } from 'Components/Display';
import { useApiWorker } from 'Utilities';
import { PrimaryButton } from 'Components/Form';
import download from 'downloadjs';
import { toast } from 'react-toastify';
import { GridButtonSection } from '../InlineGrid';
import { ColumnSelector } from '../ColumnSelector/ColumnSelector';
import { AutoRefresh } from '../AutoRefresh/AutoRefresh';

export type FilterBarGridProps = AsyncGridProps & {
  noInitialLoad?: boolean;
  filterBarProps: FilterBarProps & FilterBarProviderProps;
  isColumnSelectorEnabled?: boolean;
};

export type FilteredGridProps = AsyncGridProps & {
  noInitialLoad?: boolean;
  hasColumnSelector?: boolean;
};

export const FilteredGrid: React.FC<FilteredGridProps> = ({
  children,
  noInitialLoad,
  ...gridProps
}) => {
  const [debouncedFilterValues] = useDebouncedFilterValues(500);
  const { areFiltersApplied = false } = useFilterBarState();
  return (
    <Render condition={!noInitialLoad || areFiltersApplied}>
      <AsyncGrid
        search={debouncedFilterValues}
        condition={!noInitialLoad || areFiltersApplied}
        hideNoRecordsAlert={noInitialLoad && !areFiltersApplied}
        refreshOnSearchChange
        {...gridProps}
      >
        {children}
      </AsyncGrid>
    </Render>
  );
};

export type FilterBarSelectableGridProps = SelectableAsyncGridProps & {
  filterBarProps: FilterBarProps & FilterBarProviderProps;
};

export const FilterBarSelectableGrid: React.FC<FilterBarSelectableGridProps> =
  ({ filterBarProps, ...gridProps }) => {
    return (
      <FilterBarProvider {...filterBarProps}>
        <FilterBar />
        <FilteredSelectableGrid {...gridProps} />
      </FilterBarProvider>
    );
  };

export const FilteredSelectableGrid: React.FC<SelectableAsyncGridProps> = ({
  children,
  ...gridProps
}) => {
  const [debouncedFilterValues] = useDebouncedFilterValues(500);

  return (
    <SelectableAsyncGrid
      search={debouncedFilterValues}
      refreshOnSearchChange
      {...gridProps}
    >
      {children}
    </SelectableAsyncGrid>
  );
};

export const FilterBarMainGrid: React.FC<FilterBarGridProps> = ({
  filterBarProps,
  ...gridProps
}) => {
  const { filterValues, filterConfigurations } = filterBarProps;
  const gridState = useGridState();
  const context = useGlobalContext();
  return (
    filterBarProps.isColumnSelectorEnabled
      ?
      <FilterBarProvider
        filterValues={filterValues}
        filterConfigurations={filterConfigurations}
        isOpen={filterBarProps.isOpen}
      >
        <GridButtonSection>
          {gridProps.enableGridRefresh &&
            <AutoRefresh onRefresh={() => {
              gridState.refreshGrid()
            }} />
          }
          <ExportCsvButton exportEndpoint={gridProps.exportEndpoint} />
        </GridButtonSection>
        {!(context[0]?.currentUser?.isDoctor || context[0]?.currentUser?.isReviewer) && <ColumnSelector />}
        <FilterBar isOpen={filterBarProps.isOpen} />
        <FilteredMainGrid {...gridProps} />
      </FilterBarProvider>
      :
      <FilterBarProvider
        filterValues={filterValues}
        filterConfigurations={filterConfigurations}
      >
        <GridButtonSection>
          {gridProps.enableGridRefresh &&
            <AutoRefresh onRefresh={() => {
              gridState.refreshGrid()
            }} />
          }
          <ExportCsvButton exportEndpoint={gridProps.exportEndpoint} />
        </GridButtonSection>
        <FilterBar isOpen={filterBarProps.isOpen} />
        <FilteredMainGrid {...gridProps} />
      </FilterBarProvider>
  );
};

export const FilteredMainGrid: React.FC<FilteredGridProps> = ({
  children,
  isMainGrid,
  resizable,
  ...gridProps
}) => {
  return (
    <FilteredGrid isMainGrid {...gridProps}>
      {children}
    </FilteredGrid>
  );
};

class ExportCsvProps {
  exportEndpoint?: string
}

const ExportCsvButton: React.FC<ExportCsvProps> = ({ exportEndpoint }) => {
  const { filterValues } = useFilterBarState();

  const API = useApiWorker();
  if (exportEndpoint === undefined) return null;

  const exportAction = async () => {
    try {
      const { data, headers } = await API.post(
        exportEndpoint,
        { ...filterValues },
        {
          responseType: 'blob',
        }
      );

      const mimeType = headers['content-type'];
      var dateStamp = new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        day: '2-digit',
        month: '2-digit',
      })
        .format(new Date())
        .toString()
        .replace(/[\/\\]/g, '');

      var filename = `${'exported'}_${dateStamp}.${'csv'}`;
      download(data, filename, mimeType);
    } catch (err) {
      const error = err as any;
      toast.error(
        error ? error?.ErrorMessage : 'Error: Could not download file'
      );
    }
  };
  return (
    <PrimaryButton onClick={exportAction} className='h-60'>{'Export to CSV'}</PrimaryButton>
  );
};
