import { DropdownItemModel } from "./SharedModels";

export class FileModel {
  id?: string;
  fileId?: string;
  fileName?: string;
  size?: number;
  uploadDate?: Date;
  uploadDateDisplay?: string;
  uploadedBy?: string;
  comments?: string;
  extension?: string;
  attachmentType?: DropdownItemModel;
  attachmentTypeDisplay?: string;
}