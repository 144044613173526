import { 
  GridCellProps, 
  GridColumn 
} from '@progress/kendo-react-grid';
import { 
  PageSectionHeading 
} from 'Components/Display';
import {
  AsyncGrid,
  FileDownloadCell,
  getFormattedDisplayCell,
} from 'Components/Grid';
import { 
  GridProvider, 
  ModalFormProvider, 
  useEntity, 
  useGridState
} from 'Context';
import {
  ActionsDropdownConfig,
  defaultGridProps,
  GridColumnWidth,
} from 'Models';
import {
  AppointmentDocumentModal,
  AppointmentDocumentModel,
  AppointmentIdGridSearchModel,
} from 'Models/AppointmentDocumentModel';
import { 
  Permission 
} from 'Models/Templates/Permission/Permission';
import { 
  ENDPOINTS 
} from 'Models/Templates/Routes/Routes';
import React, { 
  Fragment, useEffect, useMemo, useState 
} from 'react';
import { 
  AppointmentDocumentCombineForm,
  AppointmentDocumentDrawerForm 
} from '.';
import { 
  AppointmentDocumentActionCell 
} from './AppointmentDocumentActionCell';

export const AppointmentDocumentList: React.FC = () => {
  const entity = useEntity();

  return (
    <Fragment>
      <GridProvider entityName="Appointment Document">
        <AppointmentDocumentModalFormWrapper />
      </GridProvider>
    </Fragment>
  );
};

const AppointmentDocumentModalFormWrapper: React.FC = () => {
  const entity = useEntity();

  var formModel = new AppointmentDocumentModel(
    entity.appointment?.id, 
    entity.referralDate
  );

  return (
    <ModalFormProvider
      formModel={formModel}
      endpoint={ENDPOINTS.appointmentdocuments.base}
    >
      <AppointmentDocumentGridWithModalDrawer />
    </ModalFormProvider>
  );
};

const AppointmentDocumentGridWithModalDrawer: React.FC = () => {
  const [isShowingHidden, setIsShowingHidden] = useState(false)
  const entity = useEntity();
  const [searchModel, setSearchModel] = useState(new AppointmentIdGridSearchModel(entity.appointment?.id))
  const { refreshGrid, total } = useGridState();
  const canCombine = useMemo(() => total > 1 && entity.appointment?.appointmentStatus?.displayName !== 'Closed', [total, entity])
  

  var formModel = new AppointmentDocumentModel(
    entity.appointment?.id, 
    entity.referralDate
  );

  const actionsConfig: ActionsDropdownConfig = {
    name: 'Actions',
    items: [
      {
        name: 'Upload Documents',
        permissions: [Permission.AddAppointmentDocument],
        modalConfig: {
          modalId: AppointmentDocumentModal.Attachments,
          modalComponent: (
            <AppointmentDocumentDrawerForm
              endpoint={ENDPOINTS.appointmentdocuments.base}
              formModel={formModel}
            />
          ),
        },
      },
      {
        name: 'Combine Documents',
        permissions: [Permission.CombineAppointmentDocument],
        modalConfig: {
          modalId: AppointmentDocumentModal.Combine,
          modalComponent: (
            <AppointmentDocumentCombineForm
              endpoint={ENDPOINTS.appointmentdocuments.base}
            />
          ),
        },
        condition: canCombine
      },
      {
        name: 'Show Hidden files',
        permissions: [Permission.ViewAppointmentCombinedFiles],
        onClick: () => 
        {
          setIsShowingHidden(true)
          setSearchModel({...searchModel, showHiddenFiles: true})
          refreshGrid()
        },
        condition: !isShowingHidden
      },
      {
        name: 'Hide combined files',
        permissions: [Permission.ViewReferralsRequestCombinedFiles],
        onClick: () => 
        {
          setIsShowingHidden(false)
          setSearchModel({...searchModel, showHiddenFiles: false})
          refreshGrid()
        },
        condition: isShowingHidden
      },
    ],
  };

  return (
    <Fragment>
      <PageSectionHeading titleActionsConfig={actionsConfig}>
        Documents
      </PageSectionHeading>
      <AsyncGrid
        readEndpoint={ENDPOINTS.appointmentdocuments.read}
        search={searchModel}
        withCardWrapper
        {...defaultGridProps}
      >
        <GridColumn
          field="fileName"
          title="File Name"
          cell={FileNameDownloadCell}
          width={GridColumnWidth.MedLarge}
        />
        <GridColumn
          field="size"
          title="File Size"
          cell={getFormattedDisplayCell('fileSizeDisplay')}
        />
        <GridColumn
          field="uploadDate"
          title="Date Uploaded"
          cell={getFormattedDisplayCell('uploadDateDisplay')}
        />
        <GridColumn
          field="id"
          title=" "
          cell={(props: GridCellProps) => (
            <AppointmentDocumentActionCell {...props} />
          )}
          width={GridColumnWidth.Ellipsis}
        />
      </AsyncGrid>
    </Fragment>
  );
};

const FileNameDownloadCell: React.FC<GridCellProps> = ({ ...props }) => {
  return (
    <FileDownloadCell
      fileControllerEndpoint={ENDPOINTS.appointmentdocuments.base}
      {...props}
    />
  );
};
