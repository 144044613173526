import { FileModel } from './FileModels';
import { Reviewer } from './ReviewerModels';

export class ReviewerLicenseDocumentModel extends FileModel {
  constructor(id: string | undefined) {
    super();
    this.reviewerId = id;
  }
  reviewerId?: string;
  reviewer?: Reviewer;
  licenseNumber?: string;
  expirationDate?: Date;
}

export class ReviewerIdLicenseGridSearchModel {
  constructor(id: string | undefined) {
    this.reviewerId = id;
  }
  reviewerId?: string;
}

export enum ReviewerLicenseDocumentModal {
  Forms = 'attachmentsForms',
  LicenseDocuments = 'attachmentsDocument',
}
