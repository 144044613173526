import './CustomRadioGroup.scss';
import React from 'react';
import {
  MaskedTextBox,
  MaskedTextBoxProps,
} from '@progress/kendo-react-inputs';

export type CustomMaskedTextBoxProps = MaskedTextBoxProps & {
  style?: any;
};

export const CustomMaskedTextBox: React.FC<CustomMaskedTextBoxProps> = ({
  style,
  ...props
}) => {
  return (
    <div style={style}>
      <MaskedTextBox width={style?.width} {...props} />
    </div>
  );
};
