import './SubHeaderLayout.scss';
import React, { Fragment } from 'react';
import { fullClassName, toggledClass } from 'Utilities';

export type SubHeaderLayoutProps = {
  headerComponent?: React.FC<any>;
  headerProps?: any;
  headerWrapperClassName?: string;
};

export const SubHeaderLayout: React.FC<SubHeaderLayoutProps> = ({
  headerComponent,
  headerProps,
  children,
  headerWrapperClassName,
}) => {
  const Header = headerComponent || Fragment;

  const { hasTallHeader, hasShortHeader, ...otherHeaderProps } =
    headerProps || {};
  return (
    <div
      className={fullClassName(
        'sub-header-layout full-size',
        toggledClass('with-tall-header', hasTallHeader),
        toggledClass('with-short-header', hasShortHeader)
      )}
    >
      <div
        className={fullClassName(
          'sub-header-layout-header',
          headerWrapperClassName
        )}
      >
        <Header {...otherHeaderProps} />
      </div>
      <div className="sub-header-layout-content">{children}</div>
    </div>
  );
};

export const TallSubHeaderLayout: React.FC<SubHeaderLayoutProps> = ({
  headerComponent,
  headerProps,
  children,
}) => {
  return (
    <SubHeaderLayout
      headerComponent={headerComponent}
      headerProps={{ ...headerProps, hasTallHeader: true }}
    >
      {children}
    </SubHeaderLayout>
  );
};
