import React from "react";
import { GridCellProps } from "@progress/kendo-react-grid";

type CustomCellProps = GridCellProps & {
  convertFunction?: (val: any) => any;
};

export const CustomCell: React.FC<CustomCellProps> = ({
  dataItem,
  field,
  convertFunction,
}) => {
  const value = dataItem[field || ""];
  return <td>{convertFunction ? convertFunction(value) : value}</td>;
};
