import "./Grid.scss";
import { ActionsDropdown } from "Components/Display";
import { ActionsDropdownConfig, DropdownType } from "Models";
import React from "react";
import { fullClassName, toggledClass } from "Utilities";
import pluralize from "pluralize";
import { useGridSelectionsState } from "Context";

export type GridSelectionActionsBarProps = {
  objectName?: string;
  actionsDropdownConfig?: ActionsDropdownConfig;
  actionsDropdown?: React.ReactNode;
};

export const GridSelectionActionsBar: React.FC<GridSelectionActionsBarProps> = ({
  actionsDropdownConfig,
  objectName = "item",
  actionsDropdown,
}) => {
  const { selections } = useGridSelectionsState();
  const selectionsCount = selections.length;

  const selectionsCountText = `${selectionsCount} ${pluralize(
    objectName,
    selectionsCount
  )} selected`;

  const hasSelections = selectionsCount > 0;

  return (
    <div
      className={fullClassName(
        "full-width flex-vertical-center bg-primary px-3 transition-all",
        toggledClass("height-50 opacity-1", hasSelections),
        toggledClass("height-0 opacity-0", !hasSelections)
      )}
    >
      <div className="font-secondary-bold flex-grow">{selectionsCountText}</div>
      <div>
        {actionsDropdownConfig ? (
          <ActionsDropdown
            type={DropdownType.Outline}
            isSmall
            name={actionsDropdownConfig.name || "Bulk Actions"}
            {...actionsDropdownConfig}
          />
        ) : actionsDropdown ? (
          actionsDropdown
        ) : null}
      </div>
    </div>
  );
};
