import React, { useEffect, useRef } from 'react';
import {
  Dialog,
  DialogActionsBar,
  DialogProps,
} from '@progress/kendo-react-dialogs';
import { Render } from './Render';
import {
  SecondaryButton,
  PrimaryButton,
  ModalFormCancelButton,
  FormSubmitButton,
  CancelButton,
  MultiModalCancelButton,
} from 'Components/Form';
import { ToggleableProps } from 'Models';
import { useFormHelpers, useModalFormState, useModalHelpers } from 'Context';
import { fullClassName, toggledClass, useOnClickOutside } from 'Utilities';
import { PageCard } from './PageCard';
import { FlexWrapper } from './LayoutComponents';
import CloseIcon from '@mui/icons-material/Close';
import { Collapse } from 'react-collapse';

export type ModalDialogProps = DialogProps &
  ToggleableProps & {
    isOpen: boolean;
    submitButtonText?: string;
    otherButtons?: any;
    hideCancelButton?: boolean;
    closeOnClickOutside?: boolean;
    largeTitle?: boolean;
  };

export const ModalDialog: React.FC<ModalDialogProps> = ({
  title,
  toggle,
  isOpen,
  children,
  closeOnClickOutside,
  largeTitle,
  className,
  ...otherProps
}) => {
  const dialogRef = useRef<any>(null);

  useOnClickOutside(dialogRef, () => {
    closeOnClickOutside && toggle();
  });

  return (
    <Render condition={isOpen}>
      <Dialog
        title={title}
        onClose={toggle}
        className={fullClassName(
          className,
          toggledClass('modal-large-title', largeTitle)
        )}
        {...otherProps}
      >
        <div ref={(ref) => (dialogRef.current = ref)}>{children}</div>
      </Dialog>
    </Render>
  );
};

export const ModalFormDialog: React.FC<Partial<ModalDialogProps>> = ({
  children,
  submitButtonText,
  otherButtons,
  ...dialogProps
}) => {
  const { toggle, isOpen } = useModalFormState();

  return (
    <ModalDialog
      toggle={toggle}
      isOpen={isOpen}
      minWidth={400}
      {...dialogProps}
    >
      {children}
      <DialogActionsBar>
        <div>
          <ModalFormCancelButton />
          <FormSubmitButton />
        </div>
      </DialogActionsBar>
    </ModalDialog>
  );
};

export type MultiModalDialogProps = Partial<DialogProps> & {
  modalId: string;
};

export const MultiModalDialog: React.FC<MultiModalDialogProps> = ({
  modalId,
  children,
  ...dialogProps
}) => {
  const { toggle, isOpen } = useModalHelpers(modalId);

  return (
    <Render condition={isOpen}>
      <Dialog onClose={toggle} {...dialogProps}>
        {children}
      </Dialog>
    </Render>
  );
};

export type MultiModalCollapsibleCardProps = {
  modalId: string;
};

export const MultiModalCollapsibleCard: React.FC<
  MultiModalCollapsibleCardProps
> = ({ modalId, children }) => {
  const { toggle, isOpen } = useModalHelpers(modalId);

  return (
    <Collapse isOpened={isOpen}>
      <PageCard>
        <FlexWrapper className="height-25 border-top-primary-accent-lg position-relative">
          <div
            className="position-absolute"
            style={{ right: 35, top: 20, zIndex: 100 }}
          >
            <CloseIcon onClick={toggle} className="cursor-pointer" />
          </div>
        </FlexWrapper>
        {children}
      </PageCard>
    </Collapse>
  );
};

export type MultiModalFormDialogProps = Partial<ModalDialogProps> & {
  modalId: string;
  onSubmit?: () => void;
};

export const MultiModalFormDialog: React.FC<MultiModalFormDialogProps> = ({
  children,
  modalId,
  otherButtons,
  submitButtonText,
  onSubmit,
  ...dialogProps
}) => {
  const { toggle, isOpen } = useModalHelpers(modalId);
  const { clearFormErrors } = useFormHelpers();

  useEffect(() => {
    if (!isOpen) {
      clearFormErrors();
    }
  }, [isOpen]);

  return (
    <ModalDialog
      toggle={toggle}
      isOpen={isOpen}
      minWidth={400}
      {...dialogProps}
    >
      {children}
      <DialogActionsBar>
        {otherButtons}
        <div>
          <MultiModalCancelButton modalId={modalId} />
          <FormSubmitButton onClick={onSubmit}>
            {submitButtonText}
          </FormSubmitButton>
        </div>
      </DialogActionsBar>
    </ModalDialog>
  );
};

export type ControlledModalFormDialogProps = ModalDialogProps;

export const ControlledModalFormDialog: React.FC<
  ControlledModalFormDialogProps
> = ({
  children,
  toggle,
  submitButtonText,
  otherButtons = null,
  ...dialogProps
}) => {
  return (
    <ModalDialog minWidth={400} toggle={toggle} {...dialogProps}>
      {children}
      <DialogActionsBar>
        {otherButtons}
        <div>
          <CancelButton onClick={toggle} />
          <FormSubmitButton>{submitButtonText}</FormSubmitButton>
        </div>
      </DialogActionsBar>
    </ModalDialog>
  );
};

export const CashDrawerDialog: React.FC<ModalDialogProps> = ({
  children,
  toggle,
  ...dialogProps
}) => {
  return (
    <ModalDialog
      minWidth={400}
      toggle={toggle}
      closeIcon={false}
      {...dialogProps}
    >
      {children}
      <DialogActionsBar>
        <div>
          <FormSubmitButton>Set Starting Balance</FormSubmitButton>
        </div>
      </DialogActionsBar>
    </ModalDialog>
  );
};

export type ControlledModalDialogProps = ModalDialogProps & {
  primaryButtons: any;
};

export const ControlledModalDialog: React.FC<ControlledModalDialogProps> = ({
  children,
  toggle,
  primaryButtons = null,
  ...dialogProps
}) => {
  return (
    <ModalDialog minWidth={400} toggle={toggle} {...dialogProps}>
      {children}
      <DialogActionsBar>
        <div>
          <CancelButton onClick={toggle} />
          {primaryButtons}
        </div>
      </DialogActionsBar>
    </ModalDialog>
  );
};

export type ModalActionDialogProps = ModalDialogProps & {
  customButtons?: React.ReactNode | React.ReactNodeArray;
  mainButtonText?: string;
  onMainButtonClick?: (e: any) => any;
  onCancel?: () => any;
};

export const ModalActionDialog: React.FC<ModalActionDialogProps> = ({
  toggle,
  children,
  mainButtonText,
  onMainButtonClick,
  onCancel,
  customButtons,
  ...modalDialogProps
}) => {
  return (
    <ModalDialog minWidth={400} toggle={toggle} {...modalDialogProps}>
      {children}
      <DialogActionsBar>
        {customButtons ? (
          customButtons
        ) : (
          <div>
            <CancelButton
              onClick={() => {
                toggle();
                onCancel && onCancel();
              }}
            />
            <PrimaryButton
              onClick={(e: any) => {
                toggle();
                onMainButtonClick && onMainButtonClick(e);
              }}
            >
              {mainButtonText}
            </PrimaryButton>
          </div>
        )}
      </DialogActionsBar>
    </ModalDialog>
  );
};

export type ConfirmationModalProps = ModalDialogProps & {
  confirmText: string;
  confirmButtonText?: string;
  cancelButtonText?: string;
  onConfirm?: () => void;
};

export const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  confirmText,
  confirmButtonText,
  cancelButtonText,
  onConfirm,
  toggle,
  children,
  ...otherProps
}) => {
  const handleConfirm = () => {
    toggle();
    onConfirm && onConfirm();
  };

  return (
    <ModalDialog toggle={toggle} {...otherProps}>
      <div>{confirmText}</div>
      <DialogActionsBar>
        <SecondaryButton onClick={toggle}>
          {cancelButtonText || 'No'}
        </SecondaryButton>
        <PrimaryButton onClick={handleConfirm}>
          {confirmButtonText || 'Yes'}
        </PrimaryButton>
      </DialogActionsBar>
    </ModalDialog>
  );
};
