import React, { Fragment } from 'react';
import { useEntity } from 'Context';
import { AdminSubHeaderMainLayout } from '../../Components/Display';
import { ROUTES } from 'Navigation';
import { ViewAppointmentBreadcrumbs } from './ViewAppointment';
import { formatDateTimeFromDotNetFormat } from 'Utilities';

export const AppointmentMain: React.FC = () => {
    const entity = useEntity();

    return (
        <Fragment>
            <ViewAppointmentBreadcrumbs />
            <AdminSubHeaderMainLayout
                subRoute={ROUTES.APPOINTMENT}
                title={
                    entity.appointmentDate ?
                    formatDateTimeFromDotNetFormat(
                      entity.appointmentDate,
                      'MM/dd/yyyy'
                    ) + ' - Appointment' : ''
                }
            />
        </Fragment>
    );
};
