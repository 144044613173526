export class Guideline {
  id?: string;
  title?: string;
  description?: string;
  benefitState?: BenefitState;
  guidelineLink?: Array<GuidelineLink>
}

export class GuidelineLink {
  link?: LinkObject;
  id?: string;
  isAdd?: boolean;
  hasCustomEntityEvents?: boolean;
}

export class LinkObject {
  title?: string;
  url?: string;
}

export class BenefitState {
  displayName?: string;
  value?: string;
}