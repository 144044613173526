import {
  InteractionRequiredAuthError,
  InteractionType,
} from '@azure/msal-browser';
import { MsalAuthenticationTemplate, useMsal } from '@azure/msal-react';
import ErrorIcon from '@mui/icons-material/Error';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { loginRequest } from 'authConfig';
import Axios from 'axios';
import {
  LoadingSection,
  PageCard,
  PageCardRowSection,
} from 'Components/Display';
import { Layout } from 'Features/Shared/Layout';
import { User } from 'Models/SharedModels';
import { ENDPOINTS } from 'Models/Templates/Routes/Routes';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { isNullEmptyOrUndefined, useApiWorker } from 'Utilities';
import { useToasts } from './ToastProvider';
export interface GlobalState {
  tenant?: string;
  clientId?: string;
  apiEndpoint?: string;
  redirectUri?: string;
  currentUser?: User;
  token?: string;
  versionNumber?: string;
  baseUrl: string;
  isSidebarOpen: boolean;
  isMainSidebarOpen: boolean;
  isUnauthorized?: boolean;
  environmentName?: string;
}

export enum GlobalActionTypes {
  SetUser = 'setUser',
  SetToken = 'setToken',
  ToggleSidebar = 'toggleSidebar',
  ToggleMainSidebar = 'toggleMainSidebar',
  SetConfig = 'setConfig',
  SetUnauthorized = 'setUnauthorized',
}

export type GlobalAction =
  | { type: GlobalActionTypes.ToggleSidebar }
  | { type: GlobalActionTypes.ToggleMainSidebar }
  | { type: GlobalActionTypes.SetUser; payload: User }
  | { type: GlobalActionTypes.SetToken; payload: string }
  | { type: GlobalActionTypes.SetConfig; payload: any }
  | { type: GlobalActionTypes.SetUnauthorized };
type GlobalDispatch = (action: GlobalAction) => void;

const GlobalStateContext = React.createContext<GlobalState | undefined>(
  undefined
);
const GlobalDispatchContext = React.createContext<GlobalDispatch | undefined>(
  undefined
);

function globalReducer(state: GlobalState, action: GlobalAction): GlobalState {
  switch (action.type) {
    case GlobalActionTypes.SetUser: {
      var tempUser = new User();
      Object.assign(tempUser, action.payload);
      return { ...state, currentUser: tempUser, isUnauthorized: false };
    }
    case GlobalActionTypes.SetToken: {
      return { ...state, token: action.payload };
    }
    case GlobalActionTypes.ToggleSidebar: {
      return { ...state, isSidebarOpen: !state.isSidebarOpen };
    }
    case GlobalActionTypes.ToggleMainSidebar: {
      return { ...state, isMainSidebarOpen: !state.isMainSidebarOpen };
    }
    case GlobalActionTypes.SetConfig: {
      return { ...state, ...action.payload };
    }
    case GlobalActionTypes.SetUnauthorized: {
      var user = new User();
      return { ...state, currentUser: user, isUnauthorized: true };
    }
    default: {
      throw new Error('Invalid action type');
    }
  }
}

export const getInitialConfig = () => {
  var configObj: GlobalState =
    process.env.NODE_ENV === 'development'
      ? {
          tenant: process.env.REACT_APP_TENANT,
          clientId: process.env.REACT_APP_CLIENT_ID,
          baseUrl: process.env.REACT_APP_BASE_URL || 'localhost:44378',
          redirectUri: process.env.REACT_APP_REDIRECT_URI,
          environmentName: process.env.REACT_APP_ENVIRONMENT_NAME,
          versionNumber: '0.0.0.0',
          isSidebarOpen: true,
          isMainSidebarOpen: true,
        }
      : {
          tenant: window.TENANT,
          clientId: window.CLIENT_ID,
          baseUrl: window.BASE_URL,
          redirectUri: window.REDIRECT_URI,
          versionNumber: window.VERSION_NUMBER,
          environmentName: window.ENVIRONMENT_NAME,
          isSidebarOpen: true,
          isMainSidebarOpen: true,
        };

  return configObj;
};

export const GlobalProvider: React.FC = ({ children }) => {
  const [state, dispatch] = React.useReducer(globalReducer, {
    ...getInitialConfig(),
  });

  const authRequest = {
    ...loginRequest,
  };

  return (
    <GlobalStateContext.Provider value={state}>
      <GlobalDispatchContext.Provider value={dispatch}>
        <MsalAuthenticationTemplate
          interactionType={InteractionType.Redirect}
          authenticationRequest={authRequest}
          errorComponent={(e) => <GlobalErrorDisplay loginError message={e} />}
        >
          <GlobalProviderContent>{children}</GlobalProviderContent>
        </MsalAuthenticationTemplate>
      </GlobalDispatchContext.Provider>
    </GlobalStateContext.Provider>
  );
};

export const GlobalProviderContent: React.FC = ({ children }) => {
  const { currentUser, isUnauthorized, token } = useGlobalState();
  const dispatch = useGlobalDispatch();
  const toast = useToasts();
  const API = useApiWorker();
  const [userError, setUserError] = React.useState(false);
  const { instance } = useMsal();

  const account = instance.getActiveAccount();
  React.useEffect(() => {
    if (account) {
      instance
        .acquireTokenSilent({
          scopes: [
            process.env.NODE_ENV === 'development'
              ? `${process.env.REACT_APP_CLIENT_ID}`
              : `${window.CLIENT_ID}`,
          ],
          account: account,
        })
        .catch((error) => {
          console.error('AQUIRE TOKEN ERROR: ', error);
          if (error instanceof InteractionRequiredAuthError) {
            // fallback to interaction when silent call fails
            console.log('ATTEMPTING REDIRECT TO RESOLVE');
            instance.acquireTokenRedirect({
              scopes: [
                process.env.NODE_ENV === 'development'
                  ? `${process.env.REACT_APP_CLIENT_ID}`
                  : `${window.CLIENT_ID}`,
              ],
              account: account,
            });
          } else {
            instance.logout();
          }
        })
        .then((response) => {
          if (response) {
            dispatch({
              type: GlobalActionTypes.SetToken,
              payload: `${response.accessToken}`,
            });
          }
        });
    }
  }, []);

  React.useEffect(() => {
    if (isNullEmptyOrUndefined(token)) return;

    API.get(ENDPOINTS.users.endpointString('current'))
      .then(({ data }) => {
        dispatch({ type: GlobalActionTypes.SetUser, payload: data });
      })
      .catch(({ response }) => {
        console.log(response);
        if (response?.status === 401) {
          dispatch({ type: GlobalActionTypes.SetUnauthorized });
        } else {
          setUserError(true);
          toast.error('Error loading user data');
        }
      });
  }, [token]);

  return (
    <LoadingSection
      isLoading={
        (token === undefined || currentUser === undefined) &&
        !userError &&
        !isUnauthorized
      }
      loadingText="Initializing User Session..."
      wrapperClass="full-view-height"
    >
      {isUnauthorized || userError ? (
        <Layout>
          <GlobalErrorDisplay
            userError={userError}
            isUnauthorized={isUnauthorized}
          />
        </Layout>
      ) : (
        children
      )}
    </LoadingSection>
  );
};

interface GlobalErrorDisplayProps {
  userError?: boolean;
  isUnauthorized?: boolean;
  loginError?: boolean;
  message?: any;
}

export const GlobalErrorDisplay = ({
  userError,
  isUnauthorized,
  loginError,
  message,
}: GlobalErrorDisplayProps) => {
  const { instance } = useMsal();
  if (message?.error) {
    console.error(message?.error);
  }
  const errorMessage = loginError
    ? 'Error logging in'
    : isUnauthorized
    ? 'You Are Not Authorized to View This Content'
    : 'Error Loading User Data';

  const userLogOut = async () => {
    await Axios.get(ENDPOINTS.users.endpointString('logout'), {
      headers: {
        Accept: '*/*',
        'Content-Type': 'application/json',
      },
    });
    instance.logout();
  };

  return (
    <PageCard width={600}>
      <PageCardRowSection>
        <div className="flex-center flex-column px-large py-large">
          <ErrorIcon
            className="color-danger mb-regular"
            style={{ width: 50, height: 50 }}
          />
          <h2 className="pb-regular">{errorMessage}</h2>
          {!isUnauthorized && (
            <span className="color-secondary">Try refreshing the page</span>
          )}
          <Link to={'#'} onClick={userLogOut} className={'pt-2'}>
            <ExitToAppIcon className="mr-small color-primary" />
            Log Out
          </Link>
        </div>
      </PageCardRowSection>
    </PageCard>
  );
};

export const useGlobalState = () => {
  const globalStateContext = React.useContext(GlobalStateContext);
  if (globalStateContext === undefined) {
    throw new Error('useGlobalState must be used within a GlobalProvider');
  }
  return globalStateContext;
};

export const useGlobalDispatch = () => {
  const globalDispatchContext = React.useContext(GlobalDispatchContext);
  if (globalDispatchContext === undefined) {
    throw new Error('useGlobalDispatch must be used within a GlobalProvider');
  }
  return globalDispatchContext;
};

export const useGlobalContext = () => {
  const state = useGlobalState();
  const dispatch = useGlobalDispatch();

  return [state, dispatch] as const;
};
