import React, { HTMLProps, useRef } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast, ToastOptions } from 'react-toastify';
import {
  FlexWrapper,
  LoadingIndicator,
  ModalCloseButton,
} from 'Components/Display';
import ErrorIcon from '@mui/icons-material/Error';
import WarningIcon from '@mui/icons-material/Warning';
import InfoIcon from '@mui/icons-material/Info';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { ToastType } from 'Models/SharedModels';
import { fullClassName } from 'Utilities';

const ToastContext = React.createContext<
  | {
      dismiss: (toastId: string) => void;
      add: (content: any, options: ToastOptions) => any;
      error: (content: string | Element) => void;
      errors: (content: string[]) => void;
      success: (content: string) => void;
      info: (content: string) => void;
      warning: (content: string) => void;
    }
  | undefined
>(undefined);

export const ToastProvider: React.FC = ({ children }) => {
  toast.configure();

  const add = (content: any, options?: ToastOptions) => {
    return toast(content, options);
  };

  const addMany = (
    content: string[],
    type: ToastType,
    options?: ToastOptions
  ) => {
    content.forEach((item) => {
      toast(<FormattedToast type={type} content={item} />, options);
    });
  };

  const error = (content: any) => {
    toast.error(<FormattedToast type={ToastType.Error} content={content} />);
  };

  const errors = (content: string[]) => {
    addMany(content, ToastType.Error);
  };

  const success = (content: any) => {
    toast.success(
      <FormattedToast type={ToastType.Success} content={content} />
    );
  };

  const info = (content: any) => {
    toast.info(<FormattedToast type={ToastType.Info} content={content} />);
  };

  const warning = (content: any) => {
    toast.warn(<FormattedToast type={ToastType.Warning} content={content} />);
  };

  return (
    <ToastContext.Provider
      value={{
        add,
        error,
        errors,
        success,
        info,
        warning,
        dismiss: toast.dismiss,
      }}
    >
      {children}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        closeButton={({ closeToast }) => (
          <ModalCloseButton toggle={closeToast} />
        )}
      />
    </ToastContext.Provider>
  );
};

export type FormattedToastProps = Omit<HTMLProps<HTMLDivElement>, 'content'> & {
  type: ToastType;
  content?: any;
  hideIcon?: boolean;
};

export const FormattedToast: React.FC<FormattedToastProps> = ({
  type,
  content,
  hideIcon,
}) => {
  return (
    <div className="flex-vertical-center">
      {!hideIcon && <ToastIcon type={type} className="mr-2" />}
      <div>{content}</div>
    </div>
  );
};

export const ToastIcon: React.FC<FormattedToastProps> = ({
  type,
  className,
}) => {
  switch (type) {
    case ToastType.Error:
      return <ErrorIcon className={fullClassName('color-danger', className)} />;
    case ToastType.Warning:
      return (
        <WarningIcon className={fullClassName('color-warning', className)} />
      );
    case ToastType.Success:
      return (
        <CheckCircleIcon
          className={fullClassName('color-success', className)}
        />
      );
    case ToastType.Info:
      return <InfoIcon className={fullClassName('color-primary', className)} />;
  }
};

export const useToasts = () => {
  const toastContext = React.useContext(ToastContext);
  if (toastContext === undefined) {
    throw new Error('useToasts must be used within a ToastProvider');
  }
  return toastContext;
};

export function useLoadingToast() {
  const toast = useToasts();
  const loadingToastId = useRef<string | null>(null);

  const showToast = (customText?: string) => {
    loadingToastId.current = toast.add(
      <FormattedToast
        type={ToastType.Info}
        hideIcon
        content={
          <FlexWrapper className="flex-vertical-center">
            <LoadingIndicator indicatorSize={20} noLabel />
            {customText || 'Loading - Please Wait...'}
          </FlexWrapper>
        }
      />,
      { autoClose: false }
    );
  };

  const hideToast = () => {
    if (loadingToastId.current !== null) {
      toast.dismiss(loadingToastId.current);
      loadingToastId.current = null;
    }
  };

  return [showToast, hideToast];
}

export function useDownloadingToast() {
  const toast = useToasts();
  const loadingToastId = useRef<string | null>(null);
  const showToast = (customText?: string) => {
    loadingToastId.current = toast.add(
      <FormattedToast
        type={ToastType.Info}
        hideIcon
        content={
          <FlexWrapper className="flex-vertical-center">
            <LoadingIndicator indicatorSize={20} noLabel />
            {customText || 'Downloading File - Please Wait...'}
          </FlexWrapper>
        }
      />,
      { autoClose: false }
    );
  };

  const hideToast = () => {
    if (loadingToastId.current !== null) {
      toast.dismiss(loadingToastId.current);
      loadingToastId.current = null;
    }
  };

  return [showToast, hideToast];
}
