import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { SubLayoutProps } from 'Components/SubLayouts';
import { GlobalActionTypes, useGlobalDispatch, useGlobalState } from 'Context';
import { MAIN_NAV_CONFIG, NavConfigItem } from 'Navigation';
import React from 'react';
import { isMobile } from 'react-device-detect';
import { useHotkeys } from 'react-hotkeys-hook';
import { fullClassName, HOTKEYS, toggledClass } from 'Utilities';
import { NavigationSidebarTabs } from '.';
import './Sidebar.scss';

export const Sidebar: React.FC = () => {
  return <SidebarSubLayoutContent navConfig={MAIN_NAV_CONFIG} />;
};

type SidebarSubLayoutContentProps = Partial<SubLayoutProps> & {
  navConfig: NavConfigItem[];
};

const SidebarSubLayoutContent: React.FC<SidebarSubLayoutContentProps> = ({
  navConfig,
  hasHeader,
  hasTallHeader,
  hasSubHeader,
  hasShortcutNumbers,
  children,
}) => {
  const { isMainSidebarOpen } = useGlobalState();

  return (
    <div
      className={fullClassName(
        'main-sidebar-layout full-size main__menu',
        toggledClass('with-header', hasHeader && !hasTallHeader),
        toggledClass('with-tall-header', hasTallHeader),
        toggledClass('with-sub-header', hasSubHeader)
      )}
    >
      <aside
        className={fullClassName(
          'main__sidebar',
          toggledClass('showing', isMainSidebarOpen)
        )}
      >
        <NavigationSidebarTabs
          withNumbers={hasShortcutNumbers}
          config={navConfig}
        />
        <SidebarToggleButton {...{ isMainSidebarOpen }} />
      </aside>
    </div>
  );
};

type SidebarToggleButtonProps = {
  isMainSidebarOpen: boolean;
};

const SidebarToggleButton: React.FC<SidebarToggleButtonProps> = ({
  isMainSidebarOpen,
}) => {
  const dispatch = useGlobalDispatch();

  const handleClick = () => {
    dispatch({ type: GlobalActionTypes.ToggleMainSidebar });
  };

  useHotkeys(HOTKEYS.TOGGLE_SIDEBAR, handleClick);

  return (
    <div
      className={fullClassName(
        'main-sidebar-toggler',
        toggledClass('close-icon', isMainSidebarOpen),
        toggledClass('mobile-toggler', isMobile)
      )}
      onClick={handleClick}
    >
      <NavigateNextIcon />
    </div>
  );
};
