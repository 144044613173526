export class InvoicesSearch {
  constructor() {
    this.selectedIndexes = [];
    Object.values(InvoicesChecksDefinition).forEach((item) => {
      this.selectedIndexes[item.index] = item.defaultChecked;
    });
  }
  searchKey?: string;
  id?: string;
  client?: string;
  reviewedBy?: string;
  reportDateCompleted?: Date;
  selectedIndexes: boolean[];
}

export class InvoiceCheckDefinition {
  constructor(
    label: string,
    defaultChecked: boolean,
    column: number,
    index: number
  ) {
    this.label = label;
    this.defaultChecked = defaultChecked;
    this.column = column;
    this.index = index;
  }
  label: string;
  defaultChecked: boolean;
  column: number;
  index: number;
}

export const InvoicesChecksDefinition = {
  reportTitle: new InvoiceCheckDefinition('Report Title', true, 1, 0),
  claimantLastName: new InvoiceCheckDefinition(
    'Claimant Last Name',
    true,
    1,
    1
  ),
  claimantFirstName: new InvoiceCheckDefinition(
    'Claimant First Name',
    true,
    1,
    2
  ),
  claimNumber: new InvoiceCheckDefinition('Claim Number', true, 1, 3),
  typeOfEval: new InvoiceCheckDefinition('Type Of Eval', true, 1, 4),
  reportDueDate: new InvoiceCheckDefinition('Report Due Date', true, 2, 5),
  reportDateCompleted: new InvoiceCheckDefinition(
    'Report Date Completed',
    true,
    2,
    6
  ),
  doctorsTime: new InvoiceCheckDefinition("Doctor's Time", false, 2, 7),
  reportTimeBilled: new InvoiceCheckDefinition(
    'Report Time Billed',
    false,
    2,
    8
  ),
  benefitState: new InvoiceCheckDefinition('Benefit State', false, 2, 9),
  reviewedBy: new InvoiceCheckDefinition('Reviewed By', false, 3, 10),
  referralNumber: new InvoiceCheckDefinition('Referral Number', true, 3, 11),
  reportDeterminationDate: new InvoiceCheckDefinition(
    'Report Determination Date',
    true,
    3,
    12
  ),
  rush: new InvoiceCheckDefinition('Rush', false, 3, 13),
  contact: new InvoiceCheckDefinition('Contact', false, 3, 14),
  numberOfRequests: new InvoiceCheckDefinition(
    'Number Of Requests',
    false,
    4,
    15
  ),
  pageCount: new InvoiceCheckDefinition('Page Count', true, 4, 16),
  reasonsForRequest: new InvoiceCheckDefinition(
    'Reasons For Request',
    false,
    4,
    17
  ),
  outcomeOfReview: new InvoiceCheckDefinition(
    'Outcome Of Review',
    false,
    4,
    18
  ),
  uRType: new InvoiceCheckDefinition('UR Type', false, 4, 19),
  treatingPhysician: new InvoiceCheckDefinition(
    'Treating Physician',
    false,
    5,
    20
  ),
  daysTAT: new InvoiceCheckDefinition('Days TAT', false, 5, 21),
  sameDay: new InvoiceCheckDefinition('SameDay', false, 5, 22),
  claimantClient: new InvoiceCheckDefinition('Claimant Client', false, 5, 23),
  employerName: new InvoiceCheckDefinition('Employer Name', false, 5, 24),
  insuranceCarrier: new InvoiceCheckDefinition(
    'Insurance Carrier',
    false,
    6,
    25
  ),
  referralSource: new InvoiceCheckDefinition('Referral Source', true, 6, 26),
  externalAdjuster: new InvoiceCheckDefinition('External Adjuster', true, 6, 27),
  adjusterName: new InvoiceCheckDefinition('Adjuster Name', true, 6, 28),
  physicianSpecialty: new InvoiceCheckDefinition(
    'Physician Specialty',
    true,
    6,
    29
  ),
  referralDate: new InvoiceCheckDefinition('Referral Date', true, 7, 30),
  writerDateCompleted: new InvoiceCheckDefinition(
    'Writer Date Completed',
    true,
    7,
    31
  ),
  writer: new InvoiceCheckDefinition('Writer', true, 7, 32),
  callAttempts: new InvoiceCheckDefinition('Call Attempts', true, 7, 33),
  stradixNumber: new InvoiceCheckDefinition('Stradix Number', true, 1 , 34),
};
