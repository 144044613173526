import { SecureComponent } from 'Components/Security';
import { Permission } from 'Models/Templates/Permission/Permission';
import React, { Fragment } from 'react';
import { AppointmentDocumentList, AppointmentReportList } from '.';

export const AppointmentDocumentsReports: React.FC = () => {
  return (
    <Fragment>
      <SecureComponent permissions={[Permission.ViewAppointmentDocument]}>
        <AppointmentDocumentList />
      </SecureComponent>
      <SecureComponent permissions={[Permission.ViewAppointmentReport]}>
        <AppointmentReportList />
      </SecureComponent>
    </Fragment>
  );
};
