import React, { HTMLProps } from 'react';
import InfoIcon from '@mui/icons-material/Info';
import { fullClassName, toggledClass } from 'Utilities';

export type NoRecordsAlertProps = HTMLProps<HTMLDivElement> & {
  leftAlignLabel?: boolean;
};

export const NoRecordsAlert: React.FC<NoRecordsAlertProps> = ({
  leftAlignLabel,
  className,
  ...otherProps
}) => {
  return (
    <div
      className={fullClassName(
        className,
        'full-width font-secondary-bold no-records-warning height-50',
        toggledClass('flex-vertical-center', leftAlignLabel),
        toggledClass('flex-center', !leftAlignLabel)
      )}
      {...otherProps}
    >
      <div className="flex-vertical-center">
        <InfoIcon className="color-primary mr-2" />
        No Records Available
      </div>
    </div>
  );
};
