import { 
  LightPageHeader 
} from "Components/Display";
import { 
  DateCell, 
  FilterBarMainGrid 
} from "Components/Grid";
import { 
  FormProvider, 
  GridProvider, 
  useEnums
} from "Context";
import { 
  defaultGridProps, 
  FilterBarFilterType, 
  FilterConfig, 
  IFilterConfig 
} from "Models";
import { 
  ENDPOINTS 
} from "Models/Templates/Routes/Routes";
import React from "react";
import { 
  Col, 
  Row 
} from "reactstrap";
import { 
  useGridSearch 
} from "Utilities";
import {
  GridCellProps,
  GridColumn as Column,
} from '@progress/kendo-react-grid';

class MyInvoicesSearch {
  searchKey?: string;
  id?: string;
  reportReviewStatus?: string;
  reportDateCompleted?: Date;
}

export enum MyInvoicesGridIds {
  DetailedSearch = 'detailedSearch',
}

export const MyInvoices: React.FC = () => {
  return (
    <GridProvider entityName="My Invoices">
      <LightPageHeader tall noBorder className="page-header-shadow">
        <Row>
          <Col>
            <h1>My Invoices</h1>
          </Col>
        </Row>
      </LightPageHeader>
      <br />
      <Row>
        <Col></Col>
      </Row>
      <FilterBarDetailedSearchGrid />
    </GridProvider>
  );
};

const FilterBarDetailedSearchGrid: React.FC = () => {
  const myInvoicesSearch = new MyInvoicesSearch();

  return (
    <FormProvider formModel={myInvoicesSearch}>
      <MyInvoicesGrid />
    </FormProvider>
  );
};

const MyInvoicesGrid: React.FC = () => {
  const [gridToggler] = useGridSearch();
  const { reportReviewStatus } = useEnums();

  const filters: IFilterConfig[] = [
    new FilterConfig(
      'searchKey',
      FilterBarFilterType.SearchText,
      'Report Title'
    ),
    new FilterConfig(
      'reportReviewStatus',
      FilterBarFilterType.EnumDropdown,
      'Report Review Status',
      { data: reportReviewStatus }
    ),
    new FilterConfig(
      'reportDateCompleted',
      FilterBarFilterType.DateRange,
      'Date Completed'
    ),
  ];

  return (
    <React.Fragment>
      <FilterBarMainGrid
        isMainGrid
        filterBarProps={{
          filterConfigurations: filters,
          filterValues: [],
        }}
        refreshToggle={gridToggler}
        readEndpoint={ENDPOINTS.myinvoices.read}
        {...defaultGridProps}
      >
        <Column field="reportTitle" title="Report Title" />
        <Column field="claimantName" title="Claimant Name" />
        <Column field="typeOfEval" title="Type Of Eval" />
        <Column field="dateClosed" title="Date Closed" cell={DateFormatCell} />
        <Column field="appointmentDate" title="Appointment Date" cell={DateFormatCell} />
        <Column field="reportDateCompleted" title="Date Completed" cell={DateFormatCell} />
        <Column field="doctorsTime" title="Doctor's Time" />
        <Column field="reportTimeBilled" title="Report Time Billed" />
        <Column field="benefitState" title="Benefit State" />
        <Column field="reviewedBy" title="Reviewed By" />
        <Column field="callAttempts" title="Call Attempts" />
      </FilterBarMainGrid>
    </React.Fragment>
  );
};

const DateFormatCell: React.FC<GridCellProps> = (props: GridCellProps) => {
  return <DateCell {...props} dateFormat={'MM/DD/YYYY'} />;
};