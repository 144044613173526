import { GridProvider, useEntity } from 'Context';
import { ClaimsFilteredGrid } from 'Features/Claims';
import React from 'react';

export const ClaimantClaims: React.FC = () => {
  const entity = useEntity();

  return (
    <GridProvider entityName="Referral">
      <ClaimsFilteredGrid id={entity.id} />
    </GridProvider>
  );
};
