import { PageCard, PageCardSection } from 'Components/Display';
import {
  FormSubmitButton,
  SecondaryButton,
  SmartInputBlock,
} from 'Components/Form';
import { FormProvider, useFormState } from 'Context';
import {
  removeAllTabIndicators,
  toggleTabsRequiredIndicator,
} from 'FeatureUtilities/TabUtilities';
import { AuditReport, InputType } from 'Models';
import { ENDPOINTS } from 'Models/Templates/Routes/Routes';
import { QualityAssuranceSubRoute, ROUTES } from 'Navigation';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import {
  buildRoute,
  defaultDataTransform,
  useAddEditSuccessHandler,
  useDataFetcher,
  useIdParam,
  useToggler,
} from 'Utilities';
import { makeStyles } from '@mui/styles';
import { Fade, Tab, Tabs } from 'react-bootstrap';

export const AddAuditReport: React.FC = () => {
  const [id, isAdd] = useIdParam();
  const entityName = 'Audit Report';

  const handleSuccess = useAddEditSuccessHandler(entityName, isAdd);
  const [data, loadingStatus] = useDataFetcher<AuditReport>(
    buildRoute(ENDPOINTS.auditreport.base, id),
    new AuditReport(id),
    defaultDataTransform,
    AuditReport
  );

  const [showForm, setShowForm] = useToggler(true);

  return (
    <PageCard>
      <FormProvider
        //formModel
        endpoint={ENDPOINTS.auditreport.endpointString('AddAuditReport')}
        formModel={data}
        handleSuccess={handleSuccess}
      >
        <AddAuditReportForm appointmentReportId={id} />
      </FormProvider>
    </PageCard>
  );
};
class AddAuditReportFormProps {
  appointmentReportId?: string;
}

const AddAuditReportForm: React.FC<AddAuditReportFormProps> = () => {
  const [key, setKey] = useState('primary');

  const formState = useFormState();
  const history = useHistory();

  useEffect(() => {
    if (formState.formStatus == 'validationError') {
      removeAllTabIndicators();
      toggleTabsRequiredIndicator();
    }
  }, [formState, removeAllTabIndicators, toggleTabsRequiredIndicator]);

  const useStyles = makeStyles({
    root: {
      marginTop: 'auto',
      marginBottom: '2.1rem',
    },
  });

  const cancelAction = () => {
    history.push(buildRoute(ROUTES.QA, QualityAssuranceSubRoute.AuditQueue));
  };

  return (
    <React.Fragment>
      <PageCardSection title="Add Audit Report">
        <Row>
          <Col>
            <FormSubmitButton className="float-right">Save</FormSubmitButton>
            <SecondaryButton
              onClick={cancelAction}
              className="float-right mr-2"
            >
              Cancel
            </SecondaryButton>
          </Col>
        </Row>
        <Tabs
          transition={Fade}
          id="controlled-tab-example"
          activeKey={key}
          onSelect={(k) => setKey(k as string)}
          className="mb-3"
        >
          <Tab eventKey="primary" title="General">
            <Row>
              <SmartInputBlock
                name="title"
                label="QA on report"
                type={InputType.Text}
                required
              />
            </Row>
            <Row>
              <SmartInputBlock
                name="physicianReviewer"
                label="Physician Reviewer"
                type={InputType.AsyncDropDown}
                useQuery
                otherparams={{ isReviewer: true }}
                readEndpoint={ENDPOINTS.users.dropdownItems}
                required
              />
            </Row>
            <Row>
              <SmartInputBlock
                type={InputType.DatePicker}
                name="lastQaDate"
                label="Last QA Date Director"
              />
            </Row>
          </Tab>
          <Tab eventKey="qa" title="QA Scoring Parameters">
            <Row>
              <SmartInputBlock
                name="p2PComp"
                label="P2P Comp"
                type={InputType.Number}
                restrictValues={(value: number) =>
                  restrictNumberValue(value, 10)
                }
                placeholder="0-10"
                width={200}
              />
              <SmartInputBlock
                name="p2PDoc"
                label="P2P Doc"
                type={InputType.Number}
                restrictValues={(value: number) =>
                  restrictNumberValue(value, 10)
                }
                placeholder="0-10"
                width={200}
              />
              <SmartInputBlock
                name="summary"
                label="Summary"
                type={InputType.Number}
                restrictValues={(value: number) =>
                  restrictNumberValue(value, 15)
                }
                placeholder="0-15"
                width={200}
              />
              <SmartInputBlock
                name="rationale"
                label="Rationale"
                type={InputType.Number}
                restrictValues={(value: number) =>
                  restrictNumberValue(value, 15)
                }
                placeholder="0-15"
                width={200}
              />
            </Row>
            <Row>
              <SmartInputBlock
                name="guidelines"
                label="Guidelines"
                type={InputType.Number}
                restrictValues={(value: number) =>
                  restrictNumberValue(value, 15)
                }
                placeholder="0-15"
                width={200}
              />
              <SmartInputBlock
                name="useOfGuides"
                label="Use of Guides"
                type={InputType.Number}
                restrictValues={(value: number) =>
                  restrictNumberValue(value, 15)
                }
                placeholder="0-15"
                width={200}
              />
              <SmartInputBlock
                name="appeal"
                label="Appeal"
                type={InputType.Number}
                restrictValues={(value: number) =>
                  restrictNumberValue(value, 10)
                }
                placeholder="0-10"
                width={200}
              />
              <SmartInputBlock
                name="formatting"
                label="Formatting"
                type={InputType.Number}
                restrictValues={(value: number) =>
                  restrictNumberValue(value, 10)
                }
                placeholder="0-10"
                width={200}
              />
            </Row>
            <Row>
              <SmartInputBlock
                name="notes"
                label="Notes"
                type={InputType.TextArea}
                width={440}
              />
            </Row>
          </Tab>
        </Tabs>
      </PageCardSection>
    </React.Fragment>
  );
};
export function restrictNumberValue(value: any, upperLimit: number) {
  var restrictedValue = value;
  if (value && value > upperLimit) {
    restrictedValue = upperLimit;
  }
  if (value && value < 0) {
    restrictedValue = 0;
  }

  return restrictedValue;
}
