import { DateTime } from "luxon";
import moment from "moment";
import { isNullOrUndefined, toTimeString } from "./HelperFunctions";

export const FORM_DATE_TIME_FORMAT = "YYYY-MM-DD HH:mm:ss";

export function toLocalDateTime(dateTime: string) {
  var dateTimeFromISO = DateTime.fromISO(dateTime);
  return `${dateTimeFromISO.toLocaleString(
    DateTime.DATE_SHORT
  )} ${dateTimeFromISO.toLocaleString(DateTime.TIME_WITH_SHORT_OFFSET)}`;
}

export function getAge(
  firstDate: string | Date,
  secondDate: string | Date,
  unit: moment.unitOfTime.Diff = "years"
) {
  if (isNullOrUndefined(firstDate) || isNullOrUndefined(secondDate)) {
    return null;
  }

  const age = moment(new Date(firstDate)).diff(
    moment(new Date(secondDate)),
    unit
  );

  return age;
}

export function compareDates(a?: Date | null, b?: Date | null) {
  return (
    a &&
    b &&
    a.getFullYear() === b.getFullYear() &&
    a.getMonth() === b.getMonth() &&
    a.getDay() === b.getDay()
  );
}

export function compareTimes(a?: Date | null, b?: Date | null) {
  return a && b && toTimeString(a) === toTimeString(b);
}

export function setDateOnDateTimeString(date: string, dateTimeString: string) {
  var oldDate = new Date(date);
  const dateTime = new Date(dateTimeString);
  var newDate = new Date(
    oldDate.getFullYear(),
    oldDate.getMonth(),
    oldDate.getDate(),
    dateTime.getHours(),
    dateTime.getMinutes(),
    dateTime.getSeconds()
  );

  return toFormDateTimeFormat(newDate);
}

export function setTimeOnDateTimeString(time: string, dateTimeString: string) {
  var oldTime = new Date(time);
  const dateTime = new Date(dateTimeString);
  var newDate = new Date(
    dateTime.getFullYear(),
    dateTime.getMonth(),
    dateTime.getDate(),
    oldTime.getHours(),
    oldTime.getMinutes(),
    oldTime.getSeconds()
  );

  return toFormDateTimeFormat(newDate);
}

export function copyDate(date: Date) {
  var newDate = new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    0,
    0,
    0,
    0
  );
  return newDate;
}

export function copyTime(date: Date) {
  var newDate = new Date(
    0,
    0,
    0,
    date.getHours(),
    date.getMinutes(),
    date.getSeconds(),
    date.getMilliseconds()
  );
  return newDate;
}

export function toFormDateTimeFormat(dateTime: Date) {
  return moment(dateTime).format(FORM_DATE_TIME_FORMAT);
}

export function getDaysInMonth(month: number, year: number, maxDay?: number) {
  var date = new Date(year, month, 1);
  var days = [];
  while (date.getMonth() === month && (maxDay ? days.length < maxDay : true)) {
    days.push(new Date(date));
    date.setDate(date.getDate() + 1);
  }
  return days;
}
