import {
  GridCellProps,
  GridColumn as Column,
} from '@progress/kendo-react-grid';
import {
  AsyncLoadingSection,
  LightPageHeader,
  MainArea,
  PageCard,
  PageCardRow,
  PageCardSection,
  Render,
  TaskHeaderProps,
  TaskPageHeaderLayoutProps,
} from 'Components/Display';
import {
  ButtonCoreProps,
  CancelButton,
  DefaultInputLabel,
  FormSubmitButton,
  PrimaryButton,
  SecondaryButton,
  SmartInputBlock,
  SmartMultiSectionList,
} from 'Components/Form';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { DateCell, LinkCell, MainGrid } from 'Components/Grid';
import {
  FormProvider,
  GridProvider,
  useEnums,
  useFormState,
  useFormStateValue,
  useGlobalContext,
  useToasts,
} from 'Context';
import {
  removeAllTabIndicators,
  toggleTabsRequiredIndicator,
} from 'FeatureUtilities/TabUtilities';
import {
  BoolDropdownItemModel,
  ColSize,
  defaultGridProps,
  DropdownItemModel,
  InputType,
  Physician,
  ReferralsRequest,
} from 'Models';
import { ENDPOINTS } from 'Models/Templates/Routes/Routes';
import { ClaimantsSubRoute, EntitiesSubRoute, ROUTES } from 'Navigation';
import React, {
  createContext,
  Fragment,
  useContext,
  useEffect,
  useState,
} from 'react';
import { Fade, FormLabel, Tab, Tabs } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import {
  buildEndpoint,
  buildQueryUrl,
  buildRoute,
  defaultDataTransform,
  restrictSsnValue,
  toggledClass,
  useAddClaimantuccessHandler,
  useDataFetcher,
  useDataPoster,
  useIdParam,
  useToggler,
} from 'Utilities';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { makeStyles } from '@mui/styles';
import { PhysiciansAddEditFormContent } from 'Features/AddEdit/AddEditPhysician';
import { SubHeaderLayout } from 'Components/SubLayouts';
import { SecureComponent } from 'Components/Security';
import { Permission } from 'Models/Templates/Permission/Permission';

// #region NewClaimantForm CONTEXT
type NewClaimantFormState = { showPhysicianForm: boolean };

type NewClaimantFormProviderProps = { children: React.ReactNode };

type NewClaimantFormContextType = {
  newClaimantFormState: NewClaimantFormState | undefined;
  setNewClaimantFormState: React.Dispatch<
    React.SetStateAction<NewClaimantFormState | undefined>
  >;
};

const NewClaimantFormContext = createContext<
  NewClaimantFormContextType | undefined
>(undefined);

const NewClaimantFormProvider: React.FC<NewClaimantFormProviderProps> = ({
  children,
}) => {
  const [newClaimantFormState, setNewClaimantFormState] = useState<
    NewClaimantFormState | undefined
  >(undefined);

  return (
    <NewClaimantFormContext.Provider
      value={{
        newClaimantFormState,
        setNewClaimantFormState,
      }}
    >
      {children}
    </NewClaimantFormContext.Provider>
  );
};

const useNewClaimantFormContext = () => {
  const claimantFormContext = useContext(NewClaimantFormContext);
  if (claimantFormContext === undefined)
    throw new Error(
      'useNewClaimantForm must be used within a NewClaimantFormProvider'
    );
  return claimantFormContext;
};

// #endregion

class ConvertProposals {
  searchKey?: string;
}

const useCustomActionSuccessHandler = (
  customToastMessage?: string,
  noToast = false
) => {
  const toast = useToasts();
  let history = useHistory();
  const handleSuccess = (response: any) => {
    let redirectUrl = buildRoute(
      ROUTES.REFERRAL,
      response.data.referralId,
      'View'
    );

    !noToast &&
      toast.success(
        customToastMessage ? customToastMessage : response.data.message
      );

    if (redirectUrl) {
      history.push(redirectUrl);
    } else {
      if (window.location.href.endsWith('#')) {
        history.goBack();
      }
      history.goBack();
    }
  };

  return handleSuccess;
};

export const ReferralsRequestProposaslCommercial: React.FC = () => {
  const [id, isAdd] = useIdParam();
  const entityName = 'ReferralRequestProposalsCommercial';
  const proposalSearch = new ConvertProposals();

  const [showForm, setShowForm] = useToggler(false);
  const handleSuccess = useAddClaimantuccessHandler(entityName, isAdd);
  const history = useHistory();

  const [data] = useDataFetcher<ReferralsRequest>(
    buildRoute(
      ENDPOINTS.referralsRequestsCommercial.endpointString('getConvertModel'),
      id
    ),
    new ReferralsRequest(),
    defaultDataTransform,
    ReferralsRequest
  );

  const cancelAction = () => {
    history.push(
      buildRoute(ROUTES.ENTITIES, EntitiesSubRoute.ReferralsRequests)
    );
  };

  return (
    <NewClaimantFormProvider>
      <PageCard>
        <PageCardRow>
          <PageCardSection
            title="Convert Referral to Claimant"
            className={showForm ? 'hidden' : ''}
          >
            <div
              style={{
                width: '90%',
                whiteSpace: 'normal',
                marginBottom: '30px',
                padding: '10px',
                backgroundColor: '#fff8ec',
                border: 'yellow 1px dashed',
              }}
            >
              The following are records that match the data from the Referral
              Request, you can select one of these to create the referral into
              one of these Claimants or click the Create <b>new Claimant</b>{' '}
              button to create a new Claimant Profile
            </div>
            <FormProvider formModel={proposalSearch}>
              <GridProvider>
                <MainGrid
                  hideNoRecordsAlert
                  withBorder={false}
                  customNoRecordsDisplay="No suitable matches found"
                  isMainGrid
                  readEndpoint={buildRoute(
                    ENDPOINTS.referralsRequestsCommercial.endpointString(
                      'ConvertProposals'
                    ),
                    id
                  )}
                  {...defaultGridProps}
                >
                  <Column
                    field="firstName"
                    title="First Name"
                    cell={nameCell}
                  />
                  <Column field="lastName" title="Last Name" cell={nameCell} />
                  <Column field="associatedClients" title="Client(s)" />
                  <Column field="dob" title="Birthdate" cell={dateCell} />
                  <Column field="ssn" title="SSN" />
                  <Column
                    field="hasMatchingPolicy"
                    title="Policy Match Found"
                    cell={iconCell}
                  />
                  <Column field="id" title="Actions" cell={ActionsCell} />
                </MainGrid>
              </GridProvider>
              <br />
              <PrimaryButton
                onClick={setShowForm}
                className={toggledClass('hidden', showForm)}
              >
                Create New Claimant
              </PrimaryButton>
              <SecondaryButton onClick={cancelAction} className="ml-2">
                Cancel
              </SecondaryButton>
            </FormProvider>
          </PageCardSection>
        </PageCardRow>
        <PageCardRow>
          <FormProvider
            endpoint={ENDPOINTS.referralsRequestsCommercial.endpointString(
              'ConvertToClaimant'
            )}
            formModel={data}
            handleSuccess={handleSuccess}
          >
            <NewClaimantForm
              referralRequestId={id}
              display={showForm}
              visibilityToggler={setShowForm}
            />
          </FormProvider>
        </PageCardRow>
      </PageCard>
    </NewClaimantFormProvider>
  );
};

const dateCell: React.FC<GridCellProps> = (props: GridCellProps) => {
  return <DateCell {...props} />;
};

const iconCell: React.FC<GridCellProps> = (props: GridCellProps) => {
  let checkMark: any;

  if (props.dataItem.hasMatchingPolicy) {
    checkMark = <FontAwesomeIcon icon={faCheck} />;
  }

  return (
    <td>
      <div className="text-center">{checkMark}</div>
    </td>
  );
};

const nameCell: React.FC<GridCellProps> = (props: GridCellProps) => {
  return (
    <LinkCell
      endpoint={ROUTES.CLAIMANT}
      subRoute={ClaimantsSubRoute.View}
      linkField="id"
      openInNewTab
      {...props}
    ></LinkCell>
  );
};

const ActionsCell: React.FC<GridCellProps> = (
  props: GridCellProps,
  proposalId: string
) => {
  let successHandler = useCustomActionSuccessHandler();
  const [postData] = useDataPoster(
    buildEndpoint(ENDPOINTS.referralsRequestsCommercial.endpointString('acceptProposal')),
    successHandler,
    false
  );

  return (
    <td>
      <SecondaryButton
        className="small-button"
        onClick={() =>
          postData({
            id: props.dataItem.id,
            referralsRequestId: props.dataItem.referralRequestId,
          })
        }
      >
        Use this
      </SecondaryButton>
    </td>
  );
};

class NewClaimantFormProps {
  display?: boolean;
  referralRequestId?: string;
  visibilityToggler: any;
}

const NewClaimantForm: React.FC<NewClaimantFormProps> = (
  props: NewClaimantFormProps
) => {
  const globalContext = useGlobalContext();
  const userdata = globalContext[0]?.currentUser;
  const [key, setKey] = useState('primary');
  const { state, apContact, timeFrame, gender } =
    useEnums();

  const dropdownData = [
    { text: 'Standard', value: 'Standard' },
    { text: 'Expedited', value: 'Expedited' },
  ];

  const textField = 'text';
  const dataItemKey = 'value';
  const formState = useFormState();
  const history = useHistory();

  useEffect(() => {
    if (formState.formStatus === 'validationError') {
      removeAllTabIndicators();
      toggleTabsRequiredIndicator();
    }
  }, [formState, removeAllTabIndicators, toggleTabsRequiredIndicator]);

  let hasExternalAdjuster =
    formState.formValues.externalAdjuster != null ? true : false;

  let adjusterName =
    formState.formValues.adjusterName != null
      ? formState.formValues.adjusterName
      : '';

  const useStyles = makeStyles({
    root: {
      marginTop: 'auto',
      marginBottom: '2.1rem',
    },
    infoLabel: {
      display: 'block',
    },
  });

  const classes = useStyles();

  const cancelAction = () => {
    history.push(
      buildRoute(ROUTES.ENTITIES, EntitiesSubRoute.ReferralsRequests)
    );
  };

  const [selectedClient, setClient] = useFormStateValue<
    DropdownItemModel | undefined
  >('claim.client');

  const [selectedLineOfBusiness, setLineOfBusiness] = useFormStateValue<
    DropdownItemModel | undefined
  >('referral.lineOfBusiness');

  const [selectedJurisdictionalMatch, setSelectedJurisdictionalMatch] = useFormStateValue<
    BoolDropdownItemModel | undefined
  >('referral.jurisdictionalMatch');

  let lineOfBusinessReadEndpoint = buildEndpoint(
    ENDPOINTS.clients.endpointString('LineOfBusinessDropdownItems'),
    selectedClient?.id
  );

  let servicesReadEndpoint = buildEndpoint(
    ENDPOINTS.clients.endpointString('ServicesDropdownItems'),
    selectedLineOfBusiness?.id
  );

  let tatsReadEndopoint = buildEndpoint(
    ENDPOINTS.clients.endpointString('tatsDropdownItems'),
    selectedLineOfBusiness?.id
  );

  let referralSourceReadEndpoint = buildQueryUrl(
    ENDPOINTS.users.endpointString('DropdownItems'),
    { clientId: selectedClient?.id }
  );

  const claimantFormContext = useNewClaimantFormContext();
  let showPhysicianForm =
    claimantFormContext?.newClaimantFormState?.showPhysicianForm;

  const createNewPhysicianHandle = () => {
    claimantFormContext.setNewClaimantFormState({
      showPhysicianForm: true,
    });
  };

  return (
    <PageCardSection
      className={props.display ? '' : 'hidden'}
      title="New Claimant Profile"
    >
      <Row>
        <Col></Col>
        <Col>
          <FormSubmitButton className="float-right">
            Save Profile
          </FormSubmitButton>
          <SecondaryButton onClick={cancelAction} className="float-right mr-2">
            Cancel
          </SecondaryButton>
        </Col>
      </Row>
      <Tabs
        transition={Fade}
        id="controlled-tab-example"
        activeKey={key}
        onSelect={(k) => setKey(k as string)}
        className="mb-3"
      >
        <SmartInputBlock
          name="referralRequestId"
          type={InputType.Text}
          hidden
        />
        <Tab eventKey="primary" title="Primary Information">
          <SmartInputBlock
            name="referral.referralDate"
            label="Referral Date"
            hidden
            required
            type={InputType.Text}
          />
          <Row>
            <SmartInputBlock
              disabled={true}
              name="referral.stradixNumber"
              label="Stradix #"
              type={InputType.Text}
            />
          </Row>
          <Row>
            <SmartInputBlock
              name="claim.client"
              label="Client"
              type={InputType.AsyncDropDown}
              readEndpoint={ENDPOINTS.clients.dropdownItems}
              fieldConfiguration={{
                dependentFieldNames: ['referral.evalType'],
              }}
              colProps={{ xs: ColSize.OneFourth }}
              required
            />
            <SmartInputBlock
              type={InputType.Text}
              name="claimant.firstName"
              label="First Name"
              required
              colProps={{ xs: ColSize.OneFourth }}
            />
            <SmartInputBlock
              type={InputType.Text}
              name="claimant.lastName"
              label="Last Name"
              colProps={{ xs: ColSize.OneFourth }}
              required
            />
            <SmartInputBlock
              name="claimant.gender"
              label="Gender"
              required
              type={InputType.EnumDropdown}
              data={gender}
              colProps={{ xs: ColSize.OneFourth }}
            />
          </Row>
          <Row>
            <SmartInputBlock
              name="referral.referralSource"
              label="Referral Source"
              required
              type={InputType.DropDown}
              readEndpoint={referralSourceReadEndpoint}
              useQuery
              otherData={selectedClient}
              colProps={{ xs: ColSize.OneFourth }}
              disabled={!userdata?.isInternal}
            />
            <SmartInputBlock
              name="referral.lineOfBusiness"
              label="Line of Business"
              required
              readEndpoint={lineOfBusinessReadEndpoint}
              type={InputType.DropDown}
              colProps={{ xs: ColSize.OneFourth }}
            />
            <SmartInputBlock
              name="referral.service"
              label="Service"
              required
              readEndpoint={servicesReadEndpoint}
              type={InputType.DropDown}
              colProps={{ xs: ColSize.OneFourth }}
            />
            <SmartInputBlock
              name="claim.claimNumber"
              label="Claim/Client Number"
              type={InputType.Text}
              required
              colProps={{ xs: ColSize.OneFourth }}
            />
          </Row>
          <Row>
            <SmartInputBlock
              name="referral.apSpecialty"
              label="Reviewer Specialty Requested"
              type={InputType.Text}
              colProps={{ xs: ColSize.OneFourth }}
            />
            <SmartInputBlock
              name="claim.benefitState"
              label="Benefit State"
              required={selectedJurisdictionalMatch?.value === true}
              type={InputType.EnumDropdown}
              data={state}
              colProps={{ xs: ColSize.OneFourth }}
            />
            <SmartInputBlock
              name="referral.jurisdictionalMatch"
              label="Jurisdictional Match Required"
              type={InputType.BoolDropDown}
              colProps={{ xs: ColSize.OneFourth }}
            />
            <Render condition={selectedJurisdictionalMatch?.value === true}>
              <SmartInputBlock
                name="referral.coSignOk"
                label="Co-Sign Okay"
                required={selectedJurisdictionalMatch?.value === true}
                type={InputType.BoolDropDown}
                colProps={{ xs: ColSize.OneFourth }}
              />
            </Render>
          </Row>
          <Row>
            <SmartInputBlock
              name="referral.dueDate"
              label="Due Date"
              required
              type={InputType.DatePicker}
              colProps={{ xs: ColSize.OneFourth }}
              isNowTime={true}
            />
            <SmartInputBlock
              name="referral.dueDate"
              label="Due Time"
              required
              type={InputType.TimePicker}
              colProps={{ xs: ColSize.OneFourth }}
            />
            <SmartInputBlock
              name="referral.tat"
              label="TAT"
              readEndpoint={tatsReadEndopoint}
              type={InputType.DropDown}
              colProps={{ xs: ColSize.OneFourth }}
            />
            <SmartInputBlock
              name="referral.timeFrame"
              label="Time Frame"
              type={InputType.EnumDropdown}
              data={timeFrame}
              colProps={{ xs: ColSize.OneFourth }}
            />
          </Row>
          <Row>
            <SmartInputBlock
              name="referral.referralDate"
              label="Referral Date"
              type={InputType.DatePicker}
              required
              value={Date.now}
              colProps={{ xs: ColSize.OneThird }}
            />
            <SmartInputBlock
              name="claim.dateOfInjury"
              label="Date of Injury"
              type={InputType.DatePicker}
              value={Date.now}
              colProps={{ xs: ColSize.OneThird }}
            />
            <SmartInputBlock
              name="claimant.dateOfBirth"
              label="Date of Birth"
              type={InputType.DatePicker}
              required
              value={Date.now}
              colProps={{ xs: ColSize.OneThird }}
            />
          </Row>
          <Row>
            <SmartInputBlock
              name="referral.specialInstructions"
              label="Comments/Special Instructions"
              type={InputType.TextArea}
              colProps={{ xs: ColSize.OneSixth }}
            />
            <SmartInputBlock
              name="referral.apContact"
              label="AP Contact"
              required
              type={InputType.EnumDropdown}
              data={apContact}
              colProps={{ xs: ColSize.OneSixth }}
            />
            <SmartInputBlock
              name="referral.apName"
              label="Attending Provider"
              type={InputType.Text}
              colProps={{ xs: ColSize.OneSixth }}
            />
            <SmartInputBlock
              name="referral.apPhoneNumber"
              label="AP Phone Number"
              type={InputType.PhoneNumber}
              colProps={{ xs: ColSize.OneSixth }}
            />
            <SmartInputBlock
              name="referral.apFaxNumber"
              label="AP Fax Number"
              type={InputType.PhoneNumber}
              colProps={{ xs: ColSize.OneSixth }}
            />
            <SmartInputBlock
              name="referral.apSpecialty"
              label="AP Specialty"
              type={InputType.Text}
              colProps={{ xs: ColSize.OneSixth }}
            />
          </Row>
        </Tab>
        <Tab eventKey="questions" title="Questions">
          <Row>
            <Col>
              <DefaultInputLabel
                label="Referral Questions"
                required
              />
              <SmartMultiSectionList
                listName="referral.referralQuestions"
                listObjectName="Question"
                initialValues={[]}
              >
                <QuestionSection />
              </SmartMultiSectionList>
            </Col>
          </Row>
        </Tab>
      </Tabs>
    </PageCardSection>
  );
};

type ListSectionProps = {
  formatInputName?: (name: string) => string;
  index?: number;
};

const defaultFormatName = (name: string) => name;

const QuestionSection: React.FC<ListSectionProps> = ({
  formatInputName = defaultFormatName,
}) => {
  const textName = formatInputName('value');

  return (
    <SmartInputBlock
      type={InputType.Text}
      name={textName}
      label="Question"
      required
      width={200}
    />
  );
};
