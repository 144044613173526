import { SecureComponent } from 'Components/Security';
import { Permission } from 'Models/Templates/Permission/Permission';
import React, { Fragment } from 'react';
import { AllAppointmentDocuments } from './AllAppointmentDocuments';
import { AllAppointmentReports } from './AllAppointmentReports';

export const AllAppointmentDocumentsReports: React.FC = () => {
  return (
    <Fragment>
      <SecureComponent permissions={[Permission.ViewAllDocuments]}>
        <AllAppointmentDocuments />
      </SecureComponent>
      <SecureComponent permissions={[Permission.ViewAllDocuments]}>
        <AllAppointmentReports />
      </SecureComponent>
    </Fragment>
  );
};
