import { Guidelines } from 'Features/Guidelines';
import { Permission } from 'Models/Templates/Permission/Permission';
import { DropdownSubRouteConfig, SubRoutes } from 'Navigation/NavigationTypes';

export enum DocumentsSubRoute {
  Guidelines = '/Guidelines',
}

export const DocumentsSubRoutes: SubRoutes = {
  [DocumentsSubRoute.Guidelines]: new DropdownSubRouteConfig(
    DocumentsSubRoute.Guidelines,
    'Guidelines',
    Guidelines,
    [Permission.ViewGuideline]
  ),
};
