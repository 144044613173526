import { isUndefined } from "lodash";
import * as React from "react";
import {
  getPropertyByString,
  isNullOrUndefined,
  setPropertyByString,
} from "Utilities";

export interface LocalState {
  [x: string]: any;
}

const LocalStateContext = React.createContext<LocalState | undefined>(
  undefined
);

type LocalStateProviderProps = {
  initialValue: any;
};

export const LocalStateProvider: React.FC<LocalStateProviderProps> = ({
  initialValue,
  children,
}) => {
  const [localState, setLocalState] = React.useState<any>(initialValue);

  React.useEffect(() => {
    setLocalState(initialValue);
  }, [initialValue]);

  return (
    <LocalStateContext.Provider
      value={{
        localState,
        setLocalState,
      }}
    >
      {children}
    </LocalStateContext.Provider>
  );
};

export function useLocalState<T>() {
  const localStateContext = React.useContext(LocalStateContext);
  if (localStateContext === undefined) {
    throw new Error("useLocalState must be used within a LocalStateProvider");
  }

  const { localState, setLocalState } = localStateContext;

  const typedLocalState = localState as T;
  const setTypedLocalState = (value: T | ((prevState: T) => T)) => {
    setLocalState(value);
  };

  return [typedLocalState, setTypedLocalState] as const;
}

export function useOptionalLocalState<T>() {
  const localStateContext = React.useContext(LocalStateContext);

  if (localStateContext !== undefined) {
    const { localState, setLocalState } = localStateContext;

    const typedLocalState = localState as T;
    const setTypedLocalState = (value: T | ((prevState: T) => T)) => {
      setLocalState(value);
    };

    return [typedLocalState, setTypedLocalState] as const;
  } else {
    return undefined;
  }
}

export function useLocalStateValueSetter<T = any>(name: string) {
  const [, setLocalState] = useLocalState();

  const setValue = (val: T) => {
    setLocalState((prev: any) => {
      var previousState = { ...prev };
      setPropertyByString(previousState, name, val);
      return previousState;
    });
  };

  return setValue;
}

export function useLocalStateValue<T = any>(name: string, defaultValue?: any) {
  const [localState] = useLocalState();
  const setValue = useLocalStateValueSetter<T>(name);

  var value = getPropertyByString(localState, name);
  value =
    isNullOrUndefined(value) && !isUndefined(defaultValue)
      ? defaultValue
      : value;

  return [value as T, setValue] as const;
}
