import 'Components/Grid/FilterBar/FilterBar.scss';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useFilterBarValue } from 'Context';
import { IFilterConfig } from 'Models';
import {
  fullClassName,
  isNullEmptyOrUndefined,
  toggledClass,
  useOnClickOutside,
  useToggler,
} from 'Utilities';
import { PrimaryButton } from 'Components/Form';
import { DropdownArrowIcon, Render } from 'Components/Display';
import { Popup } from '@progress/kendo-react-popup';
import { isArray } from 'lodash';

export type FilterBarPopupProps = {
  style?: React.CSSProperties;
};

export const DropdownFilterWrapper: React.FC<IFilterConfig> = ({
  name,
  displayName,
  children,
  popupProps,
}) => {
  const [dropdownValue] = useFilterBarValue(name);
  const [filterCount, setFilterCount] = useState(0);
  const [isOpen, toggleIsOpen] = useToggler(false);
  var menuToggleRef = useRef<any>(null);
  var menuRef = useRef<any>(null);

  useOnClickOutside([menuRef, menuToggleRef], (e: any) => {
    var path = e?.path;
    if (
      !(
        path &&
        path.some(
          (el: any) => el && el.classList?.contains('k-animation-container')
        )
      )
    ) {
      isOpen && toggleIsOpen();
    }
  });

  useEffect(() => {
    if (!isNullEmptyOrUndefined(dropdownValue)) {
      if (isArray(dropdownValue)) {
        setFilterCount(dropdownValue.length);
      } else {
        setFilterCount(1);
      }
    } else {
      setFilterCount(0);
    }
  }, [dropdownValue]);

  return (
    <Fragment>
      <div
        className="flex-end full-height position-relative"
        ref={(ref) => (menuToggleRef.current = ref)}
      >
        <PrimaryButton
          className="solid-button full-height"
          onClick={toggleIsOpen}
        >
          {displayName}
          <Render condition={filterCount > 0}>
            <div className="filter-count animate-fade-in">{filterCount}</div>
          </Render>
          <DropdownArrowIcon
            className={fullClassName(
              'filter-dropdown-arrow color-primary',
              toggledClass('with-filter-number', filterCount > 0)
            )}
          />
        </PrimaryButton>
      </div>
      <Popup
        className="animate-fade-in filter-dropdown-popup-wrap"
        anchor={menuToggleRef.current}
        anchorAlign={{ horizontal: 'right', vertical: 'bottom' }}
        popupAlign={{ horizontal: 'right', vertical: 'top' }}
        show={isOpen}
        animate={false}
        appendTo={document.getElementById('root') || undefined}
      >
        <div
          className="filter-dropdown-popup"
          ref={(ref) => (menuRef.current = ref)}
          style={popupProps?.style}
        >
          {children}
        </div>
      </Popup>
    </Fragment>
  );
};
