import { LoadingSection } from 'Components/Display';
import { AsyncStatus } from 'Models';
import * as React from 'react';
import { useParams } from 'react-router';
import {
  buildEndpoint,
  defaultDataTransform,
  useDataFetcher,
  useToggler,
} from 'Utilities';

export interface Entity {
  entityId: string;
  [x: string]: any;
}

export class EntityModel implements Entity {
  entityId!: string;
  [x: string]: any;
}

export enum EntityActionTypes {
  SetId = 'setId',
  SetEntity = 'setEntity',
}

export type EntityAction =
  | { type: EntityActionTypes.SetId; payload: string }
  | { type: EntityActionTypes.SetEntity; payload: any };
type EntityDispatch = (action: EntityAction) => void;

const EntityContext = React.createContext<Entity | undefined>(undefined);
const EntityDispatchContext = React.createContext<EntityDispatch | undefined>(
  undefined
);

function entityReducer(state: Entity, action: EntityAction): Entity {
  switch (action.type) {
    case EntityActionTypes.SetId: {
      return { ...state, entityId: action.payload };
    }
    case EntityActionTypes.SetEntity: {
      return { ...state, ...action.payload };
    }
    default: {
      throw new Error('Invalid action type');
    }
  }
}

type EntityProviderProps = {
  providedEntityId?: string;
  getEntityUrl?: string;
};

export const EntityProvider: React.FC<EntityProviderProps> = ({
  providedEntityId,
  getEntityUrl,
  children,
}) => {
  let { entityId = providedEntityId } = useParams<any>();

  const [state, dispatch] = React.useReducer(entityReducer, {
    entityId: entityId,
  });
  const [entity, fetchStatus, { getData, error }] = useDataFetcher<EntityModel>(
    buildEndpoint(getEntityUrl, entityId),
    new EntityModel(),
    defaultDataTransform,
    EntityModel,
    false
  );

  React.useEffect(() => {
    getData();
  }, [getEntityUrl]);

  React.useEffect(() => {
    entity.entityId = entityId;
    dispatch({ type: EntityActionTypes.SetEntity, payload: entity });
  }, [entity]);

  return (
    <EntityContext.Provider value={state}>
      <EntityDispatchContext.Provider value={dispatch}>
        <LoadingSection
          isLoading={fetchStatus === AsyncStatus.Pending}
          wrapperClass="full-size"
        >
          {children}
        </LoadingSection>
      </EntityDispatchContext.Provider>
    </EntityContext.Provider>
  );
};

export const useEntity = () => {
  const entityContext = React.useContext(EntityContext);
  if (entityContext === undefined) {
    throw new Error('useEntity must be used within a EntityProvider');
  }
  return entityContext;
};

export const useEntityDispatch = () => {
  const entityDispatchContext = React.useContext(EntityDispatchContext);
  if (entityDispatchContext === undefined) {
    throw new Error('useEntityDispatch must be used within a EntityProvider');
  }
  return entityDispatchContext;
};

export const useEntityContext = () => {
  const state = useEntity();
  const dispatch = useEntityDispatch();

  return [state, dispatch] as const;
};
