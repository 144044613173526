import { GridCellProps, GridColumn } from '@progress/kendo-react-grid';
import { ModalFormDrawer, PageSectionHeading } from 'Components/Display';
import {
  AsyncGrid,
  FileDownloadCell,
  getFormattedDisplayCell,
} from 'Components/Grid';
import {
  GridProvider,
  ModalFormProvider,
  useEntity,
  useGridState,
  useModalFormState,
  useToasts,
} from 'Context';
import {
  ActionsDropdownConfig,
  defaultGridProps,
  GridColumnWidth,
  ReferralRequestIdGridSearchModel,
  ReferralRequestDocumentModel,
  ReferralRequestDocumentModal,
} from 'Models';
import { Permission } from 'Models/Templates/Permission/Permission';
import { ENDPOINTS } from 'Models/Templates/Routes/Routes';
import React, { Fragment, useMemo, useState } from 'react';
import {
  ReferralRequestDocumentActionCell,
  ReferralRequestDocumentCombineForm,
  ReferralRequestDocumentDrawerFormContent,
} from '.';

export const ReferralRequestDocumentList: React.FC = () => {
  return (
    <Fragment>
      <GridProvider entityName="Referral Request Document">
        <CastAttachmentModalFormWrapper />
      </GridProvider>
    </Fragment>
  );
};

export const CastAttachmentModalFormWrapper: React.FC = () => {
  const toast = useToasts();
  const entity = useEntity();
  const { refreshGrid, entityName } = useGridState();

  const handleSuccess = () => {
    toast.success(`${entityName} saved!`);
    refreshGrid();
  };

  var formModel = new ReferralRequestDocumentModel(entity.id);

  return (
    <ModalFormProvider
      formModel={formModel}
      endpoint={ENDPOINTS.referralrequestdocuments.base}
      handleSuccess={handleSuccess}
    >
      <ReferralRequestDocumentGridWithModalDrawer />
    </ModalFormProvider>
  );
};

const ReferralRequestDocumentGridWithModalDrawer: React.FC = () => {
  const [isShowingHidden, setIsShowingHidden] = useState(false)
  const entity = useEntity();
  const [searchModel, setSearchModel] = useState(new ReferralRequestIdGridSearchModel(entity.id))
  const { setupAdd } = useModalFormState();
  const { refreshGrid, total } = useGridState();
  const canCombine = useMemo(() => total > 1, [total])

  const actionsConfig: ActionsDropdownConfig = {
    name: 'Actions',
    items: [
      {
        name: 'Upload Documents',
        onClick: setupAdd,
        permissions: [Permission.AddReferralDocument],
      },
      {
        name: 'Combine Documents',
        permissions: [Permission.CombineReferralsRequestDocument],
        modalConfig: {
          modalId: ReferralRequestDocumentModal.Combine,
          modalComponent: (
            <ReferralRequestDocumentCombineForm
            />
          ),
        },
        condition: canCombine
      },
      {
        name: 'Show Hidden files',
        permissions: [Permission.ViewReferralsRequestCombinedFiles],
        onClick: () => 
        {
          setIsShowingHidden(true)
          setSearchModel({...searchModel, showHiddenFiles: true})
          refreshGrid()
        },
        condition: !isShowingHidden
      },
      {
        name: 'Hide combined files',
        permissions: [Permission.ViewReferralsRequestCombinedFiles],
        onClick: () => 
        {
          setIsShowingHidden(false)
          setSearchModel({...searchModel, showHiddenFiles: false})
          refreshGrid()
        },
        condition: isShowingHidden
      },
    ],
  };

  return (
    <Fragment>
      <PageSectionHeading titleActionsConfig={actionsConfig}>
        Documents
      </PageSectionHeading>
      <AsyncGrid
        readEndpoint={ENDPOINTS.referralrequestdocuments.read}
        search={searchModel}
        withCardWrapper
        {...defaultGridProps}
      >
        <GridColumn
          field="fileName"
          title="File Name"
          cell={FileNameDownloadCell}
          width={GridColumnWidth.MedLarge}
        />
        <GridColumn
          field="size"
          title="File Size"
          cell={getFormattedDisplayCell('fileSizeDisplay')}
          width="auto"
        />
        <GridColumn
          field="uploadDate"
          title="Date Uploaded"
          cell={getFormattedDisplayCell('uploadDateDisplay')}
        />
        <GridColumn field="uploadedBy" title="Uploaded By" />
        <GridColumn
          field="id"
          title=" "
          cell={(props) => <ReferralRequestDocumentActionCell {...props} />}
        />
      </AsyncGrid>
      <ModalFormDrawer width={500} title="Case Attachment">
        <ReferralRequestDocumentDrawerFormContent />
      </ModalFormDrawer>
    </Fragment>
  );
};

export const FileNameDownloadCell: React.FC<GridCellProps> = ({ ...props }) => {
  return (
    <FileDownloadCell
      fileControllerEndpoint={ENDPOINTS.referralrequestdocuments.base}
      {...props}
    />
  );
};
