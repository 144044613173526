import { useProviderContext, useProviderUpdater } from "FeatureUtilities";
import * as React from "react";
import { useToasts } from "./ToastProvider";

export interface ModalConfig {
  id: string;
  isOpen: boolean;
}

export interface ModalConfigs {
  [id: string]: ModalConfig;
}

export interface MultiModalState {
  entityId?: string;
  modalItem?: any;
  modals?: ModalConfigs;
}

export enum MultiModalActionTypes {
  SetEntityId = "setEntityId",
  SetModalItem = "setModalItem",
  ToggleModal = "toggleModal",
}

export type MultiModalAction =
  | {
      type: MultiModalActionTypes.SetEntityId;
      payload: string | undefined;
    }
  | {
      type: MultiModalActionTypes.SetModalItem;
      payload: any;
    }
  | {
      type: MultiModalActionTypes.ToggleModal;
      payload: string;
    };

type MultiModalDispatch = (action: MultiModalAction) => void;

const MultiModalStateContext = React.createContext<MultiModalState | undefined>(
  undefined
);

const MultiModalDispatchContext = React.createContext<
  MultiModalDispatch | undefined
>(undefined);

function handleToggleAction(state: MultiModalState, modalId: string) {
  if (state.modals && state.modals[modalId] !== undefined) {
    return {
      ...state,
      modals: {
        ...state.modals,
        [modalId]: {
          ...state.modals[modalId],
          isOpen: !state.modals[modalId].isOpen,
        },
      },
    };
  } else {
    return {
      ...state,
      modals: {
        ...(state.modals || {}),
        [modalId]: {
          id: modalId,
          isOpen: true,
        },
      },
    };
  }
}

function modalReducer(
  state: MultiModalState,
  action: MultiModalAction
): MultiModalState {
  switch (action.type) {
    case MultiModalActionTypes.SetEntityId:
      return { ...state, entityId: action.payload };
    case MultiModalActionTypes.SetModalItem:
      return { ...state, modalItem: action.payload };
    case MultiModalActionTypes.ToggleModal: {
      const modalId = action.payload;
      return handleToggleAction(state, modalId);
    }
    default: {
      throw new Error("Invalid action type");
    }
  }
}

type MultiModalProviderProps = {
  modals?: ModalConfigs;
};

export const MultiModalProvider: React.FC<MultiModalProviderProps> = ({
  children,
  modals = {},
}) => {
  const [state, dispatch] = React.useReducer(modalReducer, { modals });

  return (
    <MultiModalStateContext.Provider value={state}>
      <MultiModalDispatchContext.Provider value={dispatch}>
        {children}
      </MultiModalDispatchContext.Provider>
    </MultiModalStateContext.Provider>
  );
};

export const useMultiModalState = () => {
  return useProviderContext<MultiModalState>(
    MultiModalStateContext,
    "MultiModal"
  );
};

export const useMultiModalDispatch = () => {
  return useProviderContext<MultiModalDispatch>(
    MultiModalDispatchContext,
    "MultiModal"
  );
};

export const useMultiModalUpdater = () => {
  const dispatch = useMultiModalDispatch();

  return useProviderUpdater<MultiModalActionTypes, MultiModalAction>(dispatch);
};

export const useMultiModalHelpers = () => {
  const { entityId, modalItem } = useMultiModalState();

  const sendUpdate = useMultiModalUpdater();

  const toggle = (modalId: string) => {
    sendUpdate(MultiModalActionTypes.ToggleModal, modalId);
  };

  const setUpAdd = (modalId: string, modalItem?: any) => {
    sendUpdate(MultiModalActionTypes.SetEntityId, undefined);
    sendUpdate(MultiModalActionTypes.SetModalItem, modalItem);
    toggle(modalId);
  };

  const setUpEdit = (modalId: string, entityId?: string, modalItem?: any) => {
    sendUpdate(MultiModalActionTypes.SetEntityId, entityId);
    sendUpdate(MultiModalActionTypes.SetModalItem, modalItem);
    toggle(modalId);
  };

  function setModalItem<T = any>(modalItem: T) {
    sendUpdate(MultiModalActionTypes.SetModalItem, modalItem);
  };

  return { toggle, setUpAdd, setUpEdit, setModalItem, entityId, modalItem };
};

export const useModalHelpers = (modalId: string) => {
  const { modals = {} } = useMultiModalState();
  const toast = useToasts();

  const isOpen = modals[modalId]?.isOpen || false;

  const {
    toggle: multiToggle,
    setUpAdd: multiSetUpAdd,
    setUpEdit: multiSetUpEdit,
    ...sharedValues
  } = useMultiModalHelpers();

  const toggle = () => {
    multiToggle(modalId);
  };

  const setUpAdd = (modalItem?: any) => {
    multiSetUpAdd(modalId, modalItem);
  };

  const setUpEdit = (entityId?: string, modalItem?: any) => {
    multiSetUpEdit(modalId, entityId, modalItem);
  };

  const simpleSuccessHandler = (successMessage: string) => {
    toast.success(successMessage);
    toggle();
  };

  return {
    toggle,
    isOpen,
    setUpAdd,
    setUpEdit,
    simpleSuccessHandler,
    ...sharedValues,
  };
};

export function useModalItem<T = any>() {
  const { modalItem } = useMultiModalState();

  return modalItem as T;
}