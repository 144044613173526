import {
  GridCellProps,
  GridColumn as Column,
} from '@progress/kendo-react-grid';
import { Tooltip } from '@progress/kendo-react-tooltip';
import {
  AsyncLoadingSection,
  DisplayBlock,
  ModalDrawerButtonRow,
  ModalFormDrawer,
} from 'Components/Display';
import { ModalFormCancelButton } from 'Components/Form';
import {
  ActionsEllipsisCell,
  FilterBarMainGrid,
  LinkCell,
  TimeStampCell,
} from 'Components/Grid';
import { GridProvider, ModalFormProvider, useModalFormState } from 'Context';
import {
  ActionsDropdownConfig,
  ClaimEscalation,
  defaultGridProps,
  FilterBarFilterType,
  FilterConfig,
  IFilterConfig,
  StaticFilterConfig,
} from 'Models';
import { Permission } from 'Models/Templates/Permission/Permission';
import { ENDPOINTS } from 'Models/Templates/Routes/Routes';
import moment from 'moment';
import { ClaimsSubRoute, ROUTES } from 'Navigation';
import React, { HTMLProps } from 'react';
import { Row } from 'reactstrap';
import { buildEndpoint, useDataFetcher, useGridSearch } from 'Utilities';

export const EscalationsExport: React.FC = () => {
  return <Escalations />;
};
export enum EscalationsExportGridIds {
  DetailedSearch = 'detailedSearch',
}

class EscalationsSearch {
  searchKey?: string;
  id?: string;
  caseDueDate?: string[];
  claimId?: string;
}

export const Escalations: React.FC = () => {
  return (
    <GridProvider entityName="Escalation">
      <EscalationsFilteredGrid />
    </GridProvider>
  );
};

export type EscalationsFilteredGridProps =
  HTMLProps<EscalationsFilteredGridProps> & {
    id?: string;
  };

export const EscalationsFilteredGrid: React.FC<
  EscalationsFilteredGridProps
> = ({ id, ...props }) => {
  const escalationsSearch = new EscalationsSearch();

  return (
    <ModalFormProvider formModel={escalationsSearch}>
      <EscalationsGrid />
    </ModalFormProvider>
  );
};

const EscalationsGrid: React.FC<EscalationsFilteredGridProps> = () => {
  const filters: IFilterConfig[] = [
    new FilterConfig(
      'searchKey',
      FilterBarFilterType.SearchText,
      'title, description, etc.'
    ),
    new FilterConfig(
      'caseDueDate',
      FilterBarFilterType.DatePicker,
      'Due date.'
    ),
    new StaticFilterConfig('claimId'),
  ];
  const [gridToggler] = useGridSearch();

  let endpoint = ENDPOINTS.claimescalations.read;

  return (
    <React.Fragment>
      <Tooltip
        openDelay={100}
        position="bottom"
        anchorElement="target"
        className="tooltip-max-width"
      >
        <FilterBarMainGrid
          isMainGrid
          filterBarProps={{
            filterConfigurations: filters,
            filterValues: { claimId: gridToggler.claimId },
          }}
          refreshToggle={gridToggler}
          readEndpoint={endpoint}
          {...defaultGridProps}
        >
          <Column
            field="claim.claimNumber"
            cell={ClaimNumberCell}
            title="Claim Number"
          />
          <Column field="claimantName" title="Name" />
          <Column
            field="dateCreated"
            title="Date Created"
            cell={TimeStampCell}
          />
          <Column field="caseDueDate" title="Due Date" cell={TimeStampCell} />
          <Column field="quantity" title="Number of Escalations" />
          <Column
            field="escalationDescription"
            title="Description"
            cell={descriptionCell}
          />
          <Column field="escalatedByName" title="Escalated by" />
        </FilterBarMainGrid>
      </Tooltip>
      <ModalFormDrawer width={500} title="Escalation">
        <SingleEscalationDrawerFormContent />
      </ModalFormDrawer>
    </React.Fragment>
  );
};

export const SingleEscalationDrawerFormContent: React.FC = () => {
  const { entityId, isOpen } = useModalFormState();

  const isAdd = entityId === undefined;

  let [claimEscalationModel, fetchStatus] = useDataFetcher<ClaimEscalation>(
    buildEndpoint(ENDPOINTS.claimescalations.getInfoModel, entityId),
    new ClaimEscalation(),
    ({ data }) => data,
    undefined,
    !isAdd && isOpen
  );

  return (
    <AsyncLoadingSection loadingStatus={fetchStatus} noIdleLoading>
      <Row>
        <DisplayBlock value={claimEscalationModel?.title} title="Title" />
        <DisplayBlock
          value={
            claimEscalationModel?.caseDueDate
              ? moment(claimEscalationModel?.caseDueDate).format(
                  'YYYY-MM-DD HH:mm'
                )
              : ''
          }
          title="Due Date"
        />
      </Row>
      <Row>
        <DisplayBlock value={claimEscalationModel?.cptCode} title="CPTCode" />
        <DisplayBlock
          value={claimEscalationModel?.diagnosis}
          title="Diagnosis"
        />
      </Row>
      <Row>
        <DisplayBlock
          value={claimEscalationModel?.escalatedByEmail}
          title="Escalated by email"
        />
        <DisplayBlock
          value={claimEscalationModel?.escalatedByName}
          title="Escalated by name"
        />
      </Row>
      <Row>
        <DisplayBlock
          value={claimEscalationModel?.escalationDescription}
          title="Description"
        />
      </Row>
      <Row>
        <DisplayBlock value={claimEscalationModel?.quantity} title="Quantity" />
        <DisplayBlock
          value={claimEscalationModel?.clientInstructions}
          title="Client Instruction"
        />
      </Row>
      <Row>
        <DisplayBlock value={claimEscalationModel?.icdCode} title="ICD Code" />
      </Row>

      <ModalDrawerButtonRow>
        <ModalFormCancelButton title="Close" />
      </ModalDrawerButtonRow>
    </AsyncLoadingSection>
  );
};

export const AppointmentDocumentActionCell: React.FC<GridCellProps> = ({
  dataItem,
  field = '',
}) => {
  const { toggle, setEntityId } = useModalFormState();

  const value = dataItem[field];

  const viewItem = () => {
    setEntityId(value);
    toggle();
  };

  const actionsConfig: ActionsDropdownConfig = {
    items: [
      {
        name: 'View',
        permissions: [Permission.ViewEscalation],
        onClick: viewItem,
      },
    ],
  };

  return (
    <ActionsEllipsisCell actionsConfig={actionsConfig} dataItem={dataItem} />
  );
};

const ClaimNumberCell: React.FC<GridCellProps> = (props: GridCellProps) => {
  return (
    <LinkCell
      endpoint={ROUTES.CLAIM}
      linkField="claimId"
      subRoute={ClaimsSubRoute.View}
      convertFunction={() => props.dataItem.claim.claimNumber}
      {...props}
    ></LinkCell>
  );
};

const descriptionCell: React.FC<GridCellProps> = (props: GridCellProps) => {
  return (
    <td title={props.dataItem.escalationDescription}>
      {props.dataItem.escalationDescription}
    </td>
  );
};
