import React from 'react';
import { useSubRoutes, SUBROUTES_MAP, ROUTES } from 'Navigation';

export const ExamplesMain: React.FC = () => {
  const mapSubRoutes = useSubRoutes();

  return (
    <div>
      {mapSubRoutes(SUBROUTES_MAP[ROUTES.EXAMPLES])}
    </div>    
  ); 
}