import './SmartFileUploadDrawerInput.scss';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import {
  ControlledModalFormDrawer,
  ErrorDisplay,
  FormErrorDisplay,
  ModalDrawerButtonRow,
  Render,
} from 'Components/Display';
import {
  DownloadFileText,
  EditDeleteCell,
  FileDownloadCell,
} from 'Components/Grid';
import {
  FormActionTypes,
  ToggleProvider,
  useFormState,
  useFormStateValue,
  useFormUpdater,
  useToggleState,
} from 'Context';
import { ENDPOINTS } from 'Models/Templates/Routes/Routes';
import React from 'react';
import { ButtonSize, PrimaryButton, SecondaryButton } from '../Buttons';
import { SmartFileUploader } from './SmartFileUploader';
import { GridColumnWidth } from 'Models';
import { DefaultInputLabel } from '../InputTypeRenderer';
import {
  buildEndpoint,
  buildQueryUrl,
  isNullEmptyOrUndefined,
  useApiWorker,
} from 'Utilities';
import { plural } from 'pluralize';
import { Col } from 'reactstrap';

const defaultEntityName = 'File';
const defaultFileListName = 'files';

export type SmartFileUploadDrawerInputProps = {
  entityName?: string;
  fileListName?: string;
  label?: string;
  fileEndpoint: string;
  required?: boolean;
  isSingleUpload?: boolean;
  useDiv?: boolean;
  withComments?: boolean;
};

export const SmartFileUploadDrawerInput: React.FC<
  SmartFileUploadDrawerInputProps
> = ({ label, required, useDiv = label === undefined, ...props }) => {
  const Wrapper = useDiv ? 'div' : Col;

  return (
    <ToggleProvider initialValue={false}>
      <Wrapper>
        <DefaultInputLabel
          label={label}
          required={required}
          className="mb-small"
        />
        <FileDisplay {...props} />
        <DrawerToggler {...props} />
        <FileUploadDrawer {...props} />
      </Wrapper>
    </ToggleProvider>
  );
};

const DrawerToggler: React.FC<Partial<SmartFileUploadDrawerInputProps>> = ({
  entityName = defaultEntityName,
  isSingleUpload,
  fileListName = defaultFileListName,
}) => {
  const [, toggle] = useToggleState();
  const pluralEntityName = plural(entityName);
  const isFileUploaded = useIsFileUploaded(isSingleUpload, fileListName);

  return (
    <Render condition={(isSingleUpload && !isFileUploaded) || !isSingleUpload}>
      <Col className="no-padding-margin">
        <SecondaryButton onClick={toggle} buttonSize={ButtonSize.Small}>
          Upload {isSingleUpload ? entityName : pluralEntityName}
        </SecondaryButton>
        <FormErrorDisplay
          errorNames={isSingleUpload ? ['fileId'] : [fileListName]}
        />
      </Col>
    </Render>
  );
};

const FileDisplay: React.FC<SmartFileUploadDrawerInputProps> = ({
  fileListName = defaultFileListName,
  isSingleUpload,
  entityName = defaultEntityName,
  fileEndpoint,
}) => {
  const { formValues } = useFormState();
  const [files, setFiles] = useFormStateValue(fileListName, []);
  const [, toggle] = useToggleState();
  const { clearFileFields, isFileUploaded, deleteTempFiles } =
    useFileUploadHelpers(isSingleUpload, fileListName, fileEndpoint);

  const onClickReplace = async () => {
    await deleteTempFiles();
    clearFileFields();
    toggle();
  };

  return isSingleUpload ? (
    <Render condition={isFileUploaded}>
      <div className="flex-vertical-center">
        <DownloadFileText
          fileControllerEndpoint={fileEndpoint}
          endpointActionName="downloadTemp"
          optionalParams={{
            fileId: formValues?.fileId,
            fileName: formValues?.fileName,
          }}
          filename={formValues?.fileName}
          className="mr-regular"
        />
        <SecondaryButton onClick={onClickReplace} buttonSize={ButtonSize.Small}>
          Replace {entityName}
        </SecondaryButton>
      </div>
    </Render>
  ) : (
    <Render condition={isFileUploaded}>
      <Grid data={files} className="temporary-attachment-grid">
        <GridColumn
          field="fileName"
          cell={(props) => (
            <FileDownloadCell
              fileControllerEndpoint={fileEndpoint}
              endpointActionName="downloadTemp"
              optionalParams={{
                fileId: props?.dataItem?.fileId,
                fileName: props.dataItem?.fileName,
              }}
              {...props}
            />
          )}
        />
        <GridColumn
          field="fileId"
          width={GridColumnWidth.AddEdit}
          cell={(props) => (
            <EditDeleteCell
              deleteEndpoint={buildEndpoint(fileEndpoint, 'DeleteTempBlob')}
              onDelete={(dataItem: any) =>
                setFiles([...files].filter((f) => f !== dataItem))
              }
              usePostForDelete
              useDeleteQueryParam
              {...props}
            />
          )}
        />
      </Grid>
    </Render>
  );
};

const FileUploadDrawer: React.FC<SmartFileUploadDrawerInputProps> = ({
  entityName = defaultEntityName,
  isSingleUpload,
  fileListName = defaultFileListName,
  fileEndpoint,
  withComments,
}) => {
  const { clearFileFields, deleteTempFiles } = useFileUploadHelpers(
    isSingleUpload,
    fileListName,
    fileEndpoint
  );
  const [isOpen, toggle] = useToggleState();
  const { formValues } = useFormState();

  const handleButtonClick = async (isSave?: boolean) => {
    if (isSingleUpload) {
      if (
        !isNullEmptyOrUndefined(formValues.fileId) &&
        (isNullEmptyOrUndefined(formValues.attachmentType.id) || !isSave)
      ) {
        await deleteTempFiles();
      }
    } else {
      if (!isSave) {
        await deleteTempFiles();
      }
    }

    if (!isSave) {
      clearFileFields();
    }

    toggle();
  };

  return (
    <ControlledModalFormDrawer
      isOpen={isOpen}
      toggle={toggle}
      title={`Upload ${isSingleUpload ? entityName : plural(entityName)}`}
    >
      <SmartFileUploader
        label={isSingleUpload ? entityName : plural(entityName)}
        required
        fileEndpoint={fileEndpoint}
        vertical
        withComments={withComments}
        allowMultiple={!isSingleUpload}
        name={fileListName}
      />
      <ModalDrawerButtonRow>
        <SecondaryButton
          onClick={async () => await handleButtonClick()}
          className="mr-regular"
        >
          Cancel
        </SecondaryButton>
        <PrimaryButton onClick={async () => await handleButtonClick(true)}>
          Save
        </PrimaryButton>
      </ModalDrawerButtonRow>
    </ControlledModalFormDrawer>
  );
};

export const useClearFileFields = (
  isSingleUpload = false,
  fileListName = defaultFileListName
) => {
  const sendUpdate = useFormUpdater();
  const { formValues } = useFormState();
  const [, setFileList] = useFormStateValue<any[]>(fileListName, []);

  const clearFileFields = () => {
    if (isSingleUpload) {
      sendUpdate(FormActionTypes.SetValues, {
        ...formValues,
        fileId: undefined,
        fileName: undefined,
        size: undefined,
        comments: undefined,
        attachmentType: undefined,
      });
    } else {
      setFileList([]);
    }
  };

  return clearFileFields;
};

export const useIsFileUploaded = (
  isSingleUpload = false,
  fileListName = defaultFileListName
) => {
  const { formValues } = useFormState();
  const [files] = useFormStateValue<any[]>(fileListName, []);

  return isSingleUpload
    ? !isNullEmptyOrUndefined(formValues?.fileId) &&
        !isNullEmptyOrUndefined(formValues?.attachmentType?.id)
    : !isNullEmptyOrUndefined(files) &&
        files.every((f) => !isNullEmptyOrUndefined(f?.attachmentType?.id));
};

export const useDeleteTempFiles = (
  isSingleUpload = false,
  fileListName = defaultFileListName,
  fileEndpoint = ''
) => {
  const { formValues } = useFormState();
  const [fileList] = useFormStateValue<any[]>(fileListName, []);
  const API = useApiWorker();
  const deleteUrl = buildEndpoint(fileEndpoint, 'DeleteTempBlob');
  const deleteUrlWithId = (id: string) => {
    return buildQueryUrl(deleteUrl, { id });
  };

  const deleteTempFiles = async () => {
    if (isSingleUpload) {
      await API.post(deleteUrlWithId(formValues.fileId));
    } else {
      fileList.map(async (f) => {
        await API.post(deleteUrlWithId(f?.fileId));
      });
    }
  };

  return deleteTempFiles;
};

export const useFileUploadHelpers = (
  isSingleUpload = false,
  fileListName = defaultFileListName,
  fileEndpoint = ''
) => {
  const clearFileFields = useClearFileFields(isSingleUpload, fileListName);
  const isFileUploaded = useIsFileUploaded(isSingleUpload, fileListName);
  const deleteTempFiles = useDeleteTempFiles(
    isSingleUpload,
    fileListName,
    fileEndpoint
  );

  return { clearFileFields, isFileUploaded, deleteTempFiles };
};
