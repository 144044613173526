import {
  AdminSubHeaderMainEditLayout,
  ControlledModalFormDrawer,
  PageCard,
  PageCardSection,
} from 'Components/Display';
import { ModalFormProvider, ToggleProvider, useEntity, useToasts, useToggleState } from 'Context';
import { addEditRoute, AddEditSubRoute, EntitiesSubRoute, entitiesSubRouteName, ReferralSubRoute, ROUTES } from 'Navigation';
import React, { Fragment, useEffect, useState } from 'react';
import AccessTime from '@mui/icons-material/AccessTime';
import {
  faUserMd,
  faBuilding,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Breadcrumb, BreadcrumbItem, Col, Row } from 'reactstrap';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { PrimaryButton } from 'Components/Form';
import { makeStyles } from '@mui/styles';
import { ProgressBar } from 'react-bootstrap';
import { SecureComponent } from 'Components/Security';
import { Permission } from 'Models/Templates/Permission/Permission';
import { AddEditAppointmentDrawer } from 'Features/AddEdit';
import { CloseReferralDialog } from './CloseCaseDialog';
import { buildRoute } from 'Utilities';

type AppointmentDrawerProps = {
  text: string
}

const AppointmentDrawer = (props: AppointmentDrawerProps) => {
  const [isOpen, toggle] = useToggleState();
  return (
    <>
      <PrimaryButton onClick={toggle}>
        {props.text}
      </PrimaryButton>
      <ControlledModalFormDrawer isOpen={isOpen} toggle={toggle} title='Assignment'>
        <AddEditAppointmentDrawer toggle={toggle} />
      </ControlledModalFormDrawer>
    </>
  )
}

export const ViewReferralCommercialBreadcrumbs: React.FC = () => {
  const entity = useEntity();
  const toast = useToasts();

  let referralEndpoint = buildRoute(
    ROUTES.REFERRAL,
    `${entity.referralId}`,
    ReferralSubRoute.View
  );

  const handleSuccess = () => {
    toast.success(`Case has been marked as closed`);
  };

  return (
    <Fragment>
      <Breadcrumb tag="nav" listTag="div" listClassName="d-flex">
        <BreadcrumbItem className="line-height-largest">
          <Link to={entitiesSubRouteName(EntitiesSubRoute.Referrals)}>
            Referrals
          </Link>
        </BreadcrumbItem>
        <BreadcrumbItem className="flex-grow-1 line-height-largest">
          <Link to={referralEndpoint}>{entity.name}</Link>
          <ModalFormProvider>
            <CloseReferralDialog />
          </ModalFormProvider>
        </BreadcrumbItem>
      </Breadcrumb>
    </Fragment>
  );
};

export const ReferralCommercialMain: React.FC = () => {
  const entity = useEntity();
  const isCommercial = entity.referralsRequestsCommercialModel?.id !== undefined;

  const useStyles = makeStyles({
    progressBar: {
      width: '100%',
      height: 25,
      fontSize: '.9rem',
      marginBottom: '1rem',
    },
    emptyChunk: {
      background: '#e9ecef',
      color: '#b2b1b1',
      borderRightStyle: 'solid',
      borderRightColor: 'white',
      borderRightWidth: 2,
    },
    filledChunk: {
      background: '#28a745',
      borderRightStyle: 'solid',
      borderRightColor: 'white',
      borderRightWidth: 2,
    },
    lastEmptyChunk: {
      background: '#e9ecef',
      color: '#b2b1b1',
    },
    lastFilledChunk: {
      background: '#28a745',
    },
  });

  const classes = useStyles();

  //Value represents "percentage" in ProgressBar
  const [value, setValue] = React.useState(0);

  //Assign Appointment Status value
  let appointmentStatusValue =
    entity.appointment !== undefined &&
      entity?.appointment?.appointmentStatus != null
      ? entity?.appointment?.appointmentStatus?.value
      : null;

  //setValue according current Appointment Status
  useEffect(() => {
    switch (appointmentStatusValue) {
      case 1: {
        setValue(25);
        break;
      }
      case 2: {
        setValue(50);
        break;
      }
      case 3: {
        setValue(75);
        break;
      }
      case 4: {
        setValue(50);
        break;
      }
      case 5: {
        setValue(100);
        break;
      }
      case 6: {
        setValue(100);
        break;
      }
      default: {
        break;
      }
    }
  }, [appointmentStatusValue]);

  //ProgressBar JSX Element
  let appointmentStatusBar = (
    <Fragment>
      <ProgressBar className={classes.progressBar}>
        <ProgressBar
          className={value > 0 ? classes.filledChunk : classes.emptyChunk}
          now={25}
          label="Open"
        />
        <ProgressBar
          className={value > 25 ? classes.filledChunk : classes.emptyChunk}
          now={25}
          label="Assigned/Under-Review"
        />
        <ProgressBar
          className={value > 50 ? classes.filledChunk : classes.emptyChunk}
          now={25}
          label="In QA"
        />
        <ProgressBar
          className={
            value > 75 ? classes.lastFilledChunk : classes.lastEmptyChunk
          }
          now={25}
          label="Closed"
        />
      </ProgressBar>
    </Fragment>
  );

  let appointmentDateBlock;
  let referralDueDateBlock;

  if (entity.appointment?.appointmentDate) {
    appointmentDateBlock = (
      <Fragment>
        <Col className="" xs="12" sm="6" lg="2">
          <table className="text-left">
            <tbody>
              <tr>
                <td>
                  <AccessTime className="color-secondary mr-2 header-size-regular " />
                </td>
                <td>
                  <b>Reviewer Due Date</b>
                  <br />
                  {moment(entity.appointment?.appointmentDate).format(
                    'MM/DD/YYYY hh:mm'
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </Col>

        <Col className="" xs="12" sm="6" lg="2">
          <table className="text-left">
            <tbody>
              <tr>
                <td>
                  <FontAwesomeIcon
                    icon={faUserMd}
                    className="color-secondary  mr-2  header-size-small "
                  />
                </td>
                <td>
                  <b>Professional</b>
                  <br />
                  {entity.appointment?.professionalName}
                </td>
              </tr>
            </tbody>
          </table>
        </Col>
        <SecureComponent permissions={[Permission.EditAppointment]}>
        <Col className="text-center" xs="12" sm="6" lg="3">
          <div className="text-wrap">
            <ToggleProvider initialValue={false}>
              <div>
                <AppointmentDrawer text='Re-Assign' />
              </div>
            </ToggleProvider>
          </div>
        </Col>
      </SecureComponent>
      </Fragment>
    );
  } else {
    appointmentDateBlock = (
      <SecureComponent permissions={[Permission.EditAppointment]}>
        <Col className="text-center" xs="12" sm="6" lg="3">
          <div className="text-wrap">
            <ToggleProvider initialValue={false}>
              <div>
                <AppointmentDrawer text='Assign' />
              </div>
            </ToggleProvider>
          </div>
        </Col>
      </SecureComponent>
    );
  }

  return (
    <Fragment>
      <ViewReferralCommercialBreadcrumbs />
      <div>{appointmentStatusBar}</div>
      <PageCard noBottomMargin className="mb-small">
        <PageCardSection short centerContent className="bg-blue-accent ">
          <Row className="justify-content-center">
            <Col className="text-center table-responsive" xs="12" sm="6" lg="2">
              <table className="text-left">
                <tbody>
                  <tr>
                    <td className="lg-1">
                      <FontAwesomeIcon
                        icon={faBuilding}
                        className="color-secondary  header-size-small mr-3"
                      />
                    </td>
                    <td className="lg-1">
                      <b>Client</b>
                      <br />
                      <div className="text-wrap">{entity.clientName}</div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </Col>
            {referralDueDateBlock}
            {appointmentDateBlock}
          </Row>
        </PageCardSection>
      </PageCard>
      <AdminSubHeaderMainEditLayout
        subRoute={isCommercial ? ROUTES.REFERRALCOMMERCIAL : ROUTES.REFERRAL}
        title={
          (isCommercial ? (`#${entity.referralsRequestsCommercialModel?.stradixNumber} - `) : '') +
          (entity?.name ?? '') +
          (entity?.name == null ? '' : ' - ') +
          (isCommercial ? 'Commercial Referral' : 'Referral')
        }
        route={!isCommercial ? addEditRoute(AddEditSubRoute.Referral, entity.entityId) :
          addEditRoute(AddEditSubRoute.ReferralsRequestCommercial, entity.referralsRequestsCommercialModel.id)}
        editPermissions={[Permission.EditReferral]}
      />
    </Fragment>
  );
};
