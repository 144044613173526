import React, { HTMLProps } from "react";
import "./NavigationBar.scss";
import { NavigationItem } from ".";
import { NavConfigItem } from "Navigation";
import { SecureComponent } from "Components/Security";

type NavigationBarProps = HTMLProps<HTMLDivElement> & {
  config?: NavConfigItem[];
  withNumbers?: boolean;
};

export const NavigationBar: React.FC<NavigationBarProps> = ({
  config,
  withNumbers,
  ...others
}) => {
  return (
    <div className={"navbar__content flex-normal"} {...others}>
      {config?.map((item, index) => (
        <SecureComponent key={index} permissions={item.permissions}>
          <NavigationItem
            withNumber={withNumbers}
            index={index + 1}
            {...item}
          />
        </SecureComponent>
      ))}
    </div>
  );
};
