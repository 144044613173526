import './Buttons.scss';
import React, { HTMLProps } from 'react';
import { Button, ButtonProps } from '@progress/kendo-react-buttons';
import { FormStatus } from 'Models/FormModels';
import { ENDPOINTS } from 'Models/Templates/Routes/Routes';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import {
  useFormState,
  useModalFormState,
  useModalHelpers,
  useToasts,
} from 'Context';
import { CircularProgress, SvgIconProps } from '@mui/material';
import {
  fullClassName,
  toggledClass,
  useApiWorker,
  buildQueryUrl,
  buildEndpoint,
} from 'Utilities';
import { Link, useHistory } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import EventIcon from '@mui/icons-material/Event';
import ViewIcon from  '@mui/icons-material/Visibility';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { Permission } from 'Models/Templates/Permission/Permission';
import { SecureComponent } from 'Components/Security';
import { Button as ReactButton } from 'reactstrap';
import download from 'downloadjs';
import DescriptionIcon from '@mui/icons-material/Description';

export enum ButtonStyle {
  Default = 'default',
  Bare = 'bare',
  Flat = 'flat',
  Outline = 'outline',
}

export enum ButtonSize {
  Normal = 'Normal',
  Small = 'Small',
}

export type ButtonCoreProps = ButtonProps & {
  buttonStyle?: ButtonStyle;
  buttonSize?: ButtonSize;
  url?: string;
  noPaddingMargin?: boolean;
  permissions?: Permission[];
};

export const ButtonCore: React.FC<ButtonCoreProps> = ({
  url,
  children,
  permissions,
  ...otherProps
}) => {
  return (
    <SecureComponent permissions={permissions}>
      {url ? (
        <Link to={url}>
          <ButtonCoreContent {...otherProps}>{children}</ButtonCoreContent>
        </Link>
      ) : (
        <ButtonCoreContent {...otherProps}>{children}</ButtonCoreContent>
      )}
    </SecureComponent>
  );
};

const ButtonCoreContent: React.FC<ButtonCoreProps> = ({
  buttonStyle = ButtonStyle.Default,
  children,
  className,
  url,
  buttonSize = ButtonSize.Normal,
  ...otherProps
}) => {
  const sizeClass = buttonSize === ButtonSize.Small ? 'small-button' : '';

  return (
    <Button
      className={fullClassName(className, sizeClass)}
      look={buttonStyle}
      {...otherProps}
    >
      {children}
    </Button>
  );
};

export const PrimaryButton: React.FC<ButtonCoreProps> = ({
  children,
  className,
  ...otherProps
}) => {
  return (
    <ButtonCore
      primary
      className={fullClassName('primary-button', className)}
      {...otherProps}
    >
      {children}
    </ButtonCore>
  );
};

export const LoadingButton: React.FC<
  ButtonCoreProps & { isLoading: boolean }
> = ({ isLoading, children, noPaddingMargin, disabled, ...otherProps }) => {
  return (
    <PrimaryButton disabled={disabled || isLoading} {...otherProps}>
      {isLoading && (
        <CircularProgress
          color="inherit"
          size={14}
          className={fullClassName(
            'color-light',
            toggledClass('mr-2', !noPaddingMargin)
          )}
        />
      )}
      {children}
    </PrimaryButton>
  );
};

export type FormSubmitButtonProps =
  React.ButtonHTMLAttributes<HTMLButtonElement> &
    ButtonProps & {
      /** Defines the custom endpoint to use */
      alternateEndpoint?: string;
      /** Use a custom success handler.
       *  @returns fn(response, originalSuccessHandler)  */
      alternateHandleSuccess?: any;
      alternateHandleError?: any;
    };

export const FormSubmitButton: React.FC<FormSubmitButtonProps> = ({
  children = 'Save',
  onClick,
  alternateEndpoint,
  alternateHandleSuccess,
  alternateHandleError,
  ...otherProps
}) => {
  const { formValues, formStatus, submitData } = useFormState();
  const isSubmitting = formStatus === FormStatus.Submitting;
  return (
    <LoadingButton
      buttonStyle={ButtonStyle.Default}
      isLoading={isSubmitting}
      onClick={(e) => {
        onClick && onClick(e);
        submitData(
          formValues,
          alternateEndpoint,
          alternateHandleSuccess,
          alternateHandleError
        );
      }}
      {...otherProps}
    >
      {children}
    </LoadingButton>
  );
};

export const SecondaryButton: React.FC<ButtonCoreProps> = ({
  children,
  className,
  ...otherProps
}) => {
  return (
    <ButtonCore
      primary
      buttonStyle={ButtonStyle.Outline}
      className={fullClassName('secondary-button', className)}
      {...otherProps}
    >
      {children}
    </ButtonCore>
  );
};

export type FilterButtonDataBlockProps = ButtonCoreProps & {
  label?: string;
  value?: string;
  isSelected?: boolean;
};

export const FilterButtonDataBlock: React.FC<FilterButtonDataBlockProps> = ({
  children,
  className,
  label = 'label',
  value = 'value',
  isSelected,
  ...otherProps
}) => {
  return (
    <ReactButton
      className={fullClassName(
        'filter-button-data-block',
        className,
        toggledClass('selected', isSelected)
      )}
      {...otherProps}
    >
      <span className="font-size-small font-secondary-bold color-secondary text-uppercase mb-tiny">
        {label}
      </span>
      <span className="header-size-regular color-primary-dark font-primary-bold">
        {value}
      </span>
      <div className="bottom-triangle" />
    </ReactButton>
  );
};

export type FilterButtonTabProps = ButtonCoreProps & {
  isSelected: boolean;
  label?: string;
};

export const FilterButtonTab: React.FC<FilterButtonTabProps> = ({
  children,
  className,
  isSelected,
  label = 'Label',
  ...otherProps
}) => {
  return (
    <ReactButton
      className={fullClassName(
        'filter-button-tab',
        className,
        toggledClass('selected', isSelected)
      )}
      {...otherProps}
    >
      {label}
      <div className="selected-underline" />
    </ReactButton>
  );
};

export const CancelButton: React.FC<ButtonCoreProps> = ({
  children = 'Cancel',
  ...allProps
}) => {
  return (
    <SecondaryButton className="mr-3" {...allProps}>
      {children}
    </SecondaryButton>
  );
};

export const CancelBackButton: React.FC<ButtonCoreProps> = ({
  children,
  ...allProps
}) => {
  let history = useHistory();

  return (
    <CancelButton
      onClick={() => {
        if (window.location.href.endsWith('#')) {
          history.goBack();
        }
        history.goBack();
      }}
      {...allProps}
    >
      {children}
    </CancelButton>
  );
};

export const ModalFormCancelButton: React.FC<ButtonCoreProps> = ({
  children,
  className,
  noPaddingMargin,
  ...otherProps
}) => {
  const { toggle } = useModalFormState();

  return (
    <ButtonCore
      primary={true}
      buttonStyle={ButtonStyle.Outline}
      className={fullClassName(
        toggledClass('mr-3', !noPaddingMargin),
        className
      )}
      onClick={toggle}
      {...otherProps}
    >
      Cancel
    </ButtonCore>
  );
};

export type MultiModalCancelButtonProps = ButtonCoreProps & {
  modalId: string;
};

export const MultiModalCancelButton: React.FC<MultiModalCancelButtonProps> = ({
  children,
  className,
  noPaddingMargin,
  modalId,
  ...otherProps
}) => {
  const { toggle } = useModalHelpers(modalId);

  return (
    <ButtonCore
      primary={true}
      buttonStyle={ButtonStyle.Outline}
      className={fullClassName(
        toggledClass('mr-3', !noPaddingMargin),
        className
      )}
      onClick={toggle}
      {...otherProps}
    >
      Cancel
    </ButtonCore>
  );
};

export const AddButton: React.FC<ButtonCoreProps> = ({
  children,
  className,
  noPaddingMargin,
  ...otherProps
}) => {
  return (
    <IconButton
      iconElement={AddCircleIcon}
      buttonStyle={ButtonStyle.Outline}
      className={fullClassName(
        toggledClass('ml-3', !noPaddingMargin),
        className
      )}
      primary
      {...otherProps}
    >
      {children}
    </IconButton>
  );
};

export const EditButton: React.FC<ButtonCoreProps> = ({
  children,
  ...otherProps
}) => {
  return (
    <IconButton
      iconElement={EditIcon}
      buttonStyle={ButtonStyle.Bare}
      primary
      {...otherProps}
    >
      {children}
    </IconButton>
  );
};

export const DeleteButton: React.FC<ButtonCoreProps> = ({
  children,
  className,
  ...otherProps
}) => {
  return (
    <IconButton
      iconElement={DeleteIcon}
      buttonStyle={ButtonStyle.Bare}
      primary
      {...otherProps}
    >
      {children}
    </IconButton>
  );
};

export const ModalFormAddButton: React.FC<ButtonCoreProps> = ({
  children,
  className,
  ...otherProps
}) => {
  const { toggle, setEntityId } = useModalFormState();

  const toggleAdd = () => {
    setEntityId(undefined);
    toggle();
  };

  return (
    <SecondaryButton
      className={'mt-3'}
      onClick={toggleAdd}
      buttonSize={ButtonSize.Small}
      {...otherProps}
    >
      {children}
    </SecondaryButton>
  );
};

export const IconButton: React.FC<ButtonCoreProps & { iconElement: any }> = ({
  children,
  iconElement,
  className,
  noPaddingMargin,
  ...otherProps
}) => {
  const Icon = iconElement || null;

  return (
    <ButtonCore className={className} {...otherProps}>
      <Icon />
      {children && (
        <div className={toggledClass('ml-1', !noPaddingMargin)}>{children}</div>
      )}
    </ButtonCore>
  );
};

export type TextButtonProps = HTMLProps<HTMLElement> & {
  url?: string;
  noBold?: boolean;
  small?: boolean;
  disabled?: boolean;
};

export const TextButton: React.FC<TextButtonProps> = ({
  className,
  children,
  url,
  ref,
  noBold,
  small,
  disabled,
  ...divProps
}) => {
  const classNames = fullClassName(
    'text-button',
    className,
    toggledClass('no-bold', noBold),
    toggledClass('font-size-small', small),
    toggledClass('disabled', disabled)
  );

  return url ? (
    <Link className={classNames} to={url} {...divProps}>
      {children}
    </Link>
  ) : (
    <span className={classNames} {...divProps}>
      {children}
    </span>
  );
};

export type TextIconButtonProps = TextButtonProps & {
  icon?: any;
  noBold?: boolean;
  iconSize?: 'inherit' | 'default' | 'small' | 'large';
  iconWrapperProps?: HTMLProps<HTMLSpanElement>;
  iconProps?: SvgIconProps;
};

export const TextIconButton: React.FC<TextIconButtonProps> = ({
  children,
  icon = null,
  noBold,
  iconSize,
  iconWrapperProps,
  iconProps,
  ...allProps
}) => {
  const Icon = icon;

  return (
    <TextButton className="flex-vertical-center" {...allProps}>
      <span {...iconWrapperProps}>
        <Icon size={iconSize} {...iconProps} />
      </span>
      {children && (
        <span
          className={fullClassName(
            'ml-1',
            toggledClass('font-secondary-important', noBold)
          )}
        >
          {children}
        </span>
      )}
    </TextButton>
  );
};

export const TextEditButton: React.FC<TextButtonProps> = ({
  children,
  ...allProps
}) => {
  return (
    <TextIconButton icon={EditIcon} {...allProps}>
      {children}
    </TextIconButton>
  );
};

export const TextDeleteButton: React.FC<TextButtonProps> = ({
  children,
  ...allProps
}) => {
  return (
    <TextIconButton icon={DeleteIcon} {...allProps}>
      {children}
    </TextIconButton>
  );
};

export const TextViewButton: React.FC<TextButtonProps> = ({
  children,
  ...allProps
}) => {
  return (
    <TextIconButton icon={ViewIcon} {...allProps}>
      {children}
    </TextIconButton>
  );
};

export const TextScheduleButton: React.FC<TextButtonProps> = ({
  children,
  ...allProps
}) => {
  return (
    <TextIconButton icon={EventIcon} {...allProps}>
      {children}
    </TextIconButton>
  );
};

export const TextNoteButton: React.FC<TextButtonProps> = ({
  children,
  ...allProps
}) => {
  return (
    <TextIconButton icon={DescriptionIcon} {...allProps}>
      {children}
    </TextIconButton>
  );
};
