import mapValues from 'lodash/mapValues';
import {
  AppointmentSubRoutes,
  ReferralsRequestSubRoutes,
  CaseManagementSubRoutes,
  ClaimantsSubRoutes,
  ClaimsSubRoutes,
  ConfigurationSubRoutes,
  DocumentsSubRoutes,
  EntitiesSubRoute,
  EntitiesSubRoutes,
  mapSubroutesToNavConfigItems,
  ReferralSubRoutes,
  NetworkDevelopmentSubRoutes,
  ROUTES,
  SubNavConfigMap,
  SubRoutes,
  QualityAssuranceSubRoutes,
  ReferralCommercialSubRoutes,
} from '.';
import { getSubRouteName } from './NavigationHelpers';
import { AddEditSubRoutes } from './SubRoutes/AddEditSubRoutes';
import { ExamplesSubRoutes } from './SubRoutes/ExamplesSubRoutes';
import { GuidelinesSubRoutes } from './SubRoutes/GuidelinesSubroutes';
import { UserManagementSubRoutes } from './SubRoutes/UserManagementSubRoutes';
import { ViewSubRoutes } from './SubRoutes/ViewSubRoutes';
import { CommercialSubRoutes } from './SubRoutes/CommercialSubRoutes';

type SubRoutesMapType = {
  [x: string]: SubRoutes;
};

export function entitiesSubRouteName(subRoute: EntitiesSubRoute) {
  return getSubRouteName(ROUTES.ENTITIES, subRoute);
}

export const SUBROUTES_MAP: SubRoutesMapType = {
  // top level subroutes
  [ROUTES.USER_MANAGEMENT]: UserManagementSubRoutes,
  [ROUTES.CONFIGURATION]: ConfigurationSubRoutes,
  [ROUTES.EXAMPLES]: ExamplesSubRoutes,
  [ROUTES.ADD_EDIT]: AddEditSubRoutes,
  [ROUTES.REFERRAL]: ReferralSubRoutes,
  [ROUTES.REFERRALCOMMERCIAL]: ReferralCommercialSubRoutes,
  [ROUTES.CLAIMANT]: ClaimantsSubRoutes,
  [ROUTES.VIEW]: ViewSubRoutes,
  [ROUTES.CLAIM]: ClaimsSubRoutes,
  [ROUTES.ENTITIES]: EntitiesSubRoutes,
  [ROUTES.DOCUMENTS]: DocumentsSubRoutes,
  [ROUTES.CASEMANAGEMENT]: CaseManagementSubRoutes,
  [ROUTES.APPOINTMENT]: AppointmentSubRoutes,
  [ROUTES.REFERRALSREQUEST]: ReferralsRequestSubRoutes,
  [ROUTES.NETWORK_DEVELOPMENT]: NetworkDevelopmentSubRoutes,
  [ROUTES.QA]: QualityAssuranceSubRoutes,
  [ROUTES.GUIDELINE]: GuidelinesSubRoutes,
  [ROUTES.COMMERCIAL]: CommercialSubRoutes
};

export const SUB_NAV_CONFIG_MAP: SubNavConfigMap = mapValues(
  SUBROUTES_MAP,
  (subRoutes) => mapSubroutesToNavConfigItems(subRoutes)
);
