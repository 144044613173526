import React, {
  HTMLProps,
  KeyboardEvent,
  useEffect,
  useRef,
  useState,
} from 'react';
import MaskedInput from 'react-text-mask';
import {
  fullClassName,
  useToggler,
  isNullEmptyOrUndefined,
  timeMask,
  toAmPmValue,
  AmPmValue,
  toTimeStringNoAmPm,
  compareTimes,
  copyTime,
} from 'Utilities';
import {
  TimePicker as KendoTimePicker,
  TimePickerProps as KendoTimePickerProps,
} from '@progress/kendo-react-dateinputs';
import moment from 'moment';
import { CustomTimePickerPopup } from 'FeatureUtilities';
import { Keys } from 'Models';
import {
  DateTimePickerProps,
  PickerType,
  PickerWrapper,
} from './PickerWrapper';

export type TimePickerProps = DateTimePickerProps & {
  timePickerProps?: KendoTimePickerProps;
};

export const TimePicker: React.FC<TimePickerProps> = ({
  value,
  onChange,
  timePickerProps,
  ...divProps
}) => {
  const toggleRef = useRef<any>(null);
  const amPmRef = useRef<any>(null);
  const [maskedValue, setMaskedValue] = useState<string | undefined>(
    toTimeStringNoAmPm(value)
  );
  const [amPmValue, setAmPmValue] = useState<AmPmValue>(toAmPmValue(value));
  const [pickerIsOpen, togglePicker, setPickerOpen] = useToggler();

  const handleSelectTime = (time: Date | null) => {
    var justTime = time ? copyTime(time) : null;
    onChange(justTime);
  };

  const isValueValidDate = (val?: string) => {
    return val && !val.includes('_');
  };

  const isValueValidDateOrEmpty = (val?: string) => {
    return isValueValidDate(val) || isNullEmptyOrUndefined(val);
  };

  const inputValuesToDate = (
    timeStringValue?: string,
    amPm = AmPmValue.Empty
  ) => {
    var fullTimeString = timeStringValue
      ? timeStringValue + (amPm.length > 0 ? ' ' + amPm : '')
      : '';
    var timeFormat = amPm.length > 0 ? 'hh:mm a' : 'hh:mm';
    var timeValue = timeStringValue
      ? moment(fullTimeString, timeFormat).toDate()
      : null;

    timeValue = timeValue ? copyTime(timeValue) : timeValue;
    return timeValue;
  };

  const handleKeyUp = (e: KeyboardEvent<HTMLInputElement>) => {
    const { key } = e;

    switch (key) {
      case Keys.Backspace:
      case Keys.Delete:
        if (isNullEmptyOrUndefined(e.currentTarget.value)) {
          setMaskedValue(e.currentTarget.value);
        }
        break;
    }
  };

  useEffect(() => {
    if (isValueValidDateOrEmpty(maskedValue)) {
      var timeValue = inputValuesToDate(maskedValue, amPmValue);

      if (!compareTimes(value, timeValue)) {
        onChange(timeValue);
      }
      if (timeValue !== null) {
        amPmRef.current && amPmRef.current.focus() && amPmRef.current.select();
      }
    }
  }, [maskedValue, amPmValue]);

  useEffect(() => {
    if (toTimeStringNoAmPm(value) !== maskedValue) {
      setMaskedValue(toTimeStringNoAmPm(value));
    }

    if (toAmPmValue(value) !== amPmValue) {
      setAmPmValue(toAmPmValue(value));
    }
  }, [value]);

  return (
    <PickerWrapper
      {...divProps}
      type={PickerType.Time}
      customInput={
        <MaskedInput
          mask={timeMask}
          value={maskedValue}
          onKeyUp={handleKeyUp}
          onChange={(e) => setMaskedValue(e.target.value)}
          placeholder="--:--"
          className="masked-time-input"
        />
      }
      customAmPmInput={
        <AmPmInput
          value={amPmValue}
          setValue={setAmPmValue}
          parentRef={amPmRef}
        />
      }
      popupProps={{
        style: { padding: 0 },
        contentWrapperClassName: 'animate-slide-down-small',
      }}
      onChange={onChange}
      withoutPopup
      popupIsOpen={pickerIsOpen}
      togglePopup={togglePicker}
      popupToggleRef={toggleRef}
      value={value}
      setPopupOpen={setPickerOpen}
    >
      <KendoTimePicker
        show={pickerIsOpen}
        value={value}
        onChange={(e: any) => handleSelectTime(e.value)}
        className="invisible-time-picker"
        nowButton={false}
        popupSettings={{ animate: false }}
        smoothScroll={false}
        tabIndex={-1}
        popup={({ anchorAlign, popupAlign, className, ...props }: any) => (
          <CustomTimePickerPopup
            toggleIsOpen={togglePicker}
            isOpen={pickerIsOpen}
            toggleRef={toggleRef}
            anchorAlign={{ horizontal: 'right', vertical: 'bottom' }}
            popupAlign={{ horizontal: 'right', vertical: 'top' }}
            className={fullClassName(className, 'time-picker-popup')}
            {...props}
          />
        )}
        cancelButton={false}
        {...timePickerProps}
      />
    </PickerWrapper>
  );
};

export type AmPmInputProps = HTMLProps<HTMLInputElement> & {
  setValue: React.Dispatch<React.SetStateAction<AmPmValue>>;
  parentRef: React.MutableRefObject<any>;
};

export const AmPmInput: React.FC<AmPmInputProps> = ({
  value,
  setValue,
  parentRef,
  ...inputProps
}) => {
  const toggleAmPmValue = () => {
    if (value === AmPmValue.Empty || value === AmPmValue.PM) {
      setValue(AmPmValue.AM);
      return;
    }

    setValue(AmPmValue.PM);
  };

  const handleKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    const { key } = e;

    switch (key) {
      case Keys.UpArrow:
      case Keys.DownArrow:
        e.stopPropagation();
        toggleAmPmValue();
        break;
      case Keys.A:
        e.stopPropagation();
        setValue(AmPmValue.AM);
        break;
      case Keys.P:
        e.stopPropagation();
        setValue(AmPmValue.PM);
        break;
      case Keys.Delete:
      case Keys.Backspace:
        e.stopPropagation();
        setValue(AmPmValue.Empty);
        break;
    }
  };

  return (
    <input
      {...inputProps}
      className="am-pm-input"
      onKeyDown={handleKeyPress}
      value={value}
      onChange={() => {}}
      placeholder="--"
      ref={(ref) => (parentRef.current = ref)}
    />
  );
};
