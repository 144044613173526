import React, { Fragment } from 'react';
import { ENDPOINTS } from 'Models/Templates/Routes/Routes';
import { FilterBarFilterType, FilterConfig, IFilterConfig } from 'Models';
import { FilterableAsyncEventCalendar, PageHeading } from 'Components/Display';
//import { DocketCalendarPopup } from "Features/CourtSetup/Scheduling/Docket";

export const ExamplesCalendar: React.FC<any> = () => {
  return (
    <Fragment>
      <PageHeading name="Docket Calendar" />
      <CalendarContent />
    </Fragment>
  );
};

export const CalendarContent: React.FC = () => {
  const filters: IFilterConfig[] = [
    new FilterConfig('username', FilterBarFilterType.SearchText, 'Username'),

    new FilterConfig(
      'user',
      FilterBarFilterType.Dropdown,
      'User',
      {
        readEndpoint: ENDPOINTS.users.dropdownItems,
      },
      undefined,
      true
    ),
  ];

  return (
    <FilterableAsyncEventCalendar
      filterBarProps={{
        filterConfigurations: filters,
        filterValues: {},
      }}
      readEndpoint={ENDPOINTS.clients.endpointString('getCalendarEvents')}
      //popupContent={DocketCalendarPopup}
    />
  );
};
