import { SubRoutes } from 'Navigation/NavigationTypes';
import { AdminSubRouteConfig } from 'Navigation/NavigationTypes';
import { Permission } from 'Models/Templates/Permission/Permission';
import { Clients, EvaluationTypes, Physicians, LineOfBusiness } from 'Features/Configuration';

export enum ConfigurationSubRoute {
  EvalTypes = '/EvaluationTypes',
  Clients = '/Clients',
  Physicians = '/Physicians',
  LineOfBusiness = '/LineOfBusiness'
}

export const ConfigurationSubRoutes: SubRoutes = {
  [ConfigurationSubRoute.EvalTypes]: new AdminSubRouteConfig(
    ConfigurationSubRoute.EvalTypes,
    'Evaluation Types',
    EvaluationTypes,
    true,
    [
      Permission.ViewEvalTypes,
      Permission.AddEvalTypes,
      Permission.EditEvalTypes,
    ]
  ),
  [ConfigurationSubRoute.Clients]: new AdminSubRouteConfig(
    ConfigurationSubRoute.Clients,
    'Clients',
    Clients,
    false,
    [Permission.ViewClient, Permission.AddClient, Permission.EditClient]
  ),
  [ConfigurationSubRoute.LineOfBusiness]: new AdminSubRouteConfig(
    ConfigurationSubRoute.LineOfBusiness,
    'Line Of Business',
    LineOfBusiness,
    false,
    [Permission.ViewLineOfBusiness, Permission.AddLineOfBusiness, Permission.EditLineOfBusiness]
  ),
  [ConfigurationSubRoute.Physicians]: new AdminSubRouteConfig(
    ConfigurationSubRoute.Physicians,
    'Physicians',
    Physicians,
    false,
    [
      Permission.ViewPhysician,
      Permission.AddPhysician,
      Permission.EditPhysician,
    ]
  ),
};
