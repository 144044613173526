import { ViewReferralRequest } from 'Features/ReferralsRequests/ViewReferralRequest';
import { Permission } from 'Models/Templates/Permission/Permission';
import {
  AdminSubRouteConfig,
  SubRoutes,
} from '../../Navigation/NavigationTypes';

export enum ReferralsRequestSubRoute {
  View = '/View',
}

export const ReferralsRequestSubRoutes: SubRoutes = {
  [ReferralsRequestSubRoute.View]: new AdminSubRouteConfig(
    ReferralsRequestSubRoute.View,
    'View',
    ViewReferralRequest,
    true,
    [Permission.ViewReferralsRequest]
  ),
};
