import { FileModel } from './FileModels';

export class ReferralRequestDocumentModel {
  constructor(id: string) {
    this.referralRequestId = id;
  }
  files?: FileModel[];
  referralRequestId?: string;
}

export class ReferralRequestIdGridSearchModel {
  constructor(id: string) {
    this.referralRequestId = id;
  }

  referralRequestId?: string;
  showHiddenFiles?: boolean;
}

export enum ReferralRequestDocumentModal {
  Combine = 'combineDocument'
}