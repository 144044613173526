import { useProviderContext, useProviderUpdater } from 'FeatureUtilities';
import * as React from 'react';
import { GridState } from './GridProvider';

export type MultiGridConfig = {
  [x: string]: GridState;
};

export interface MultiGridState {
  grids?: MultiGridConfig;
}

export enum MultiGridActionTypes {
  RegisterGrid = 'registerGrid',
}

export type MultiGridAction = {
  type: MultiGridActionTypes.RegisterGrid;
  payload: { id: string; gridState: GridState };
};

type MultiGridDispatch = (action: MultiGridAction) => void;

const MultiGridStateContext = React.createContext<MultiGridState | undefined>(
  undefined
);

const MultiGridDispatchContext = React.createContext<
  MultiGridDispatch | undefined
>(undefined);

function gridReducer(
  state: MultiGridState,
  action: MultiGridAction
): MultiGridState {
  switch (action.type) {
    case MultiGridActionTypes.RegisterGrid:
      return {
        ...state,
        grids: {
          ...state.grids,
          [action.payload.id]: {
            ...state.grids?.[action.payload.id],
            ...action.payload.gridState,
          },
        },
      };
    default: {
      throw new Error('Invalid action type');
    }
  }
}

type MultiGridProviderProps = {
  grids?: MultiGridConfig;
};

export const MultiGridProvider: React.FC<MultiGridProviderProps> = ({
  children,
  grids = {},
}) => {
  const [state, dispatch] = React.useReducer(gridReducer, { grids });

  return (
    <MultiGridStateContext.Provider value={state}>
      <MultiGridDispatchContext.Provider value={dispatch}>
        {children}
      </MultiGridDispatchContext.Provider>
    </MultiGridStateContext.Provider>
  );
};

export const useMultiGridState = () => {
  return useProviderContext<MultiGridState>(MultiGridStateContext, 'MultiGrid');
};

export const useMultiGridDispatch = () => {
  return useProviderContext<MultiGridDispatch>(
    MultiGridDispatchContext,
    'MultiGrid'
  );
};

export const useMultiGridUpdater = () => {
  const dispatch = useMultiGridDispatch();

  return useProviderUpdater<MultiGridActionTypes, MultiGridAction>(dispatch);
};

export const useRegisteredGridState = (id: string) => {
  const state = useMultiGridState();

  const registeredGridState = state.grids?.[id];
  if (registeredGridState === undefined) {
    throw new Error(
      `Grid with id: ${id} has not been registered with provider.`
    );
  }

  return registeredGridState;
};

export const useMultiGridHelpers = () => {
  const sendUpdate = useMultiGridUpdater();

  const registerGrid = (id: string, gridState: GridState) => {
    sendUpdate(MultiGridActionTypes.RegisterGrid, { id, gridState });
  };

  return { registerGrid };
};
