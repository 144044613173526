import './Grid.scss';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import {
  Grid,
  GridDataStateChangeEvent,
  GridProps,
} from '@progress/kendo-react-grid';
import { State } from '@progress/kendo-data-query';
import {
  fullClassName,
  isNullEmptyOrUndefined,
  toggledClass,
  useApiWorker,
  useSafeStateUpdate,
  withoutUndefinedProperties,
} from 'Utilities';
import {
  toDataSourceRequestString,
  translateDataSourceResultGroups,
} from '@progress/kendo-data-query';
import {
  LoadingMaskSection,
  Render,
  SingleSectionPageCard,
} from 'Components/Display';
import {
  GridProvider,
  useGridState,
  useOptionalGridState,
  useToasts,
} from 'Context';
import Axios from 'axios';
import { NoRecordsAlert } from './NoRecordsAlert';
import { isEqual } from 'lodash';

export type AsyncGridProps = Partial<GridProps> & {
  readEndpoint: string;
  exportEndpoint?: string;
  refreshToggle?: boolean;
  search?: any;
  condition?: boolean;
  onFetchSuccess?: (data: any) => void;
  withCardWrapper?: boolean;
  isMainGrid?: boolean;
  hideNoRecordsAlert?: boolean;
  leftAlignNoRecords?: boolean;
  onlyFetchWithFilters?: boolean;
  additionalFilters?: any;
  refreshOnSearchChange?: boolean;
  customNoRecordsDisplay?: React.ReactNode;
  withBorder?: boolean;
  enableGridRefresh?: boolean;
};

export const AsyncGridContent: React.FC<AsyncGridProps> = ({
  children,
  withCardWrapper,
  isMainGrid,
  className,
  hideNoRecordsAlert,
  leftAlignNoRecords,
  customNoRecordsDisplay,
  withBorder,
  ...otherProps
}) => {
  const gridState = useGridState();
  const { hasData, isFetching, dataState, ...gridProps } =
    useAsyncGridState(otherProps);

  const Wrapper = withCardWrapper ? SingleSectionPageCard : Fragment;
  const wrapperProps = withCardWrapper ? { centerContent: true } : undefined;
  const shouldShowCustomNoRecordsDisplay =
    !hasData && customNoRecordsDisplay !== undefined;
  const gridRef = useRef<Grid | null>(null);

  useEffect(() => {
    if (gridRef.current !== null) {
      var columnIds = gridRef.current.columns
        .filter((col) => col.id !== undefined && col.width === 'auto')
        .map((col) => col.id);
      gridRef.current.fitColumns &&
        gridRef.current.fitColumns(columnIds as string[]);
    }
  });

  return (
    <Wrapper {...wrapperProps}>
      <div className="full-width">
        <LoadingMaskSection isLoading={isFetching} noLabel>
          {hasData || shouldShowCustomNoRecordsDisplay ? (
            <Fragment>
              <Grid
                {...gridProps}
                {...otherProps}
                {...dataState}
                ref={(ref) => (gridRef.current = ref)}
                className={fullClassName(
                  className,
                  toggledClass('main-grid', isMainGrid),
                  toggledClass('with-border', withBorder),
                  toggledClass(
                    'with-custom-no-records',
                    shouldShowCustomNoRecordsDisplay
                  )
                )}
                scrollable={'scrollable'}
              >
                {children}
              </Grid>
              {shouldShowCustomNoRecordsDisplay && (
                <SingleSectionPageCard centerContent>
                  {customNoRecordsDisplay}
                </SingleSectionPageCard>
              )}
            </Fragment>
          ) : (
            <Render condition={!hideNoRecordsAlert}>
              <NoRecordsAlert leftAlignLabel={leftAlignNoRecords} />
            </Render>
          )}
        </LoadingMaskSection>
      </div>
    </Wrapper>
  );
};

export const AsyncGrid: React.FC<AsyncGridProps> = ({ ...gridProps }) => {
  const gridState = useOptionalGridState();
  var Wrapper = GridProvider;
  if (gridState !== undefined) {
    Wrapper = Fragment;
  }

  return (
    <Wrapper>
      <AsyncGridContent {...gridProps} />
    </Wrapper>
  );
};

export const MainGrid: React.FC<AsyncGridProps> = ({
  isMainGrid,
  resizable,
  ...allProps
}) => {
  return <AsyncGrid isMainGrid {...allProps} />;
};

export const useAsyncGridState = ({
  condition = true,
  search = {},
  additionalFilters = {},
  refreshToggle,
  readEndpoint,
  onFetchSuccess,
  onlyFetchWithFilters,
  refreshOnSearchChange,
  pageable,
  pageSize,
  group,
  ...otherProps
}: Partial<AsyncGridProps>) => {
  const gridState = useGridState();
  const API = useApiWorker();
  const toast = useToasts();
  const safeStateUpdate = useSafeStateUpdate();
  const pageNeedsReset = useRef<boolean>(false);
  const isInitialLoad = useRef<boolean>(true);

  const [isFetching, setIsFetching] = useState(false);
  const pageSettings = pageable
    ? { skip: 0, take: pageSize }
    : { skip: 0, take: pageSize ? pageSize : 50 };
  const [dataState, setDataState] = useState<State>({
    group,
    ...pageSettings,
  });
  const hasData = gridState.total > 0;
  const fullSearch = { ...search, ...additionalFilters };

  const toFirstPage = (state: State): State => {
    return { ...state, skip: 0 };
  };

  const hasSearchUpdated = () => {
    return (
      !isEqual(gridState.search, fullSearch) &&
      !(
        isNullEmptyOrUndefined(gridState.search) &&
        isNullEmptyOrUndefined(fullSearch)
      )
    );
  };

  const resetStateToFirstPage = (): boolean => {
    pageNeedsReset.current = false;

    if (isEqual(dataState, toFirstPage(dataState))) {
      return false;
    }

    setDataState(toFirstPage(dataState));
    return true;
  };

  const compareDataStatesWithoutSkip = (
    updatedDataState: State,
    currentDataState: State
  ) => {
    const { skip: updatedSkip, ...updatedDataStateNoSkip } = updatedDataState;
    const { skip: currentSkip, ...currentDataStateNoSkip } = currentDataState;
    return isEqual(updatedDataStateNoSkip, currentDataStateNoSkip);
  };

  const onDataStateChange = ({ dataState: data }: GridDataStateChangeEvent) => {
    if (
      !compareDataStatesWithoutSkip(
        withoutUndefinedProperties(data),
        withoutUndefinedProperties(dataState)
      )
    ) {
      setDataState(toFirstPage(data));
    } else {
      setDataState(data);
    }
  };

  const fetchData = async (fetchDataState: State = {}) => {
    var queryStr = toDataSourceRequestString(fetchDataState);
    const hasGroups = fetchDataState.group && fetchDataState.group.length;
    let cancelTokenSource = Axios.CancelToken.source();

    const stateUpdateOrCancel = (updateFunc?: () => any) => {
      safeStateUpdate(updateFunc, cancelTokenSource.cancel);
    };

    stateUpdateOrCancel(() => setIsFetching(true));
    try {
      const { data } = await API.post(
        `${readEndpoint}?${queryStr}`,
        fullSearch
      );
      onFetchSuccess && onFetchSuccess(data);
      const formattedData = hasGroups
        ? translateDataSourceResultGroups(data.data)
        : data.data;

      stateUpdateOrCancel(() => {
        gridState.setTotal(data.total);
        gridState.setData(formattedData);
      });
    } catch (err) {
      toast.error('Error fetching table data');
      console.log(err);
    }

    stateUpdateOrCancel(() => setIsFetching(false));
  };

  const fetchDataConditionally = () => {
    if (pageNeedsReset.current) {
      if (resetStateToFirstPage()) {
        return;
      }
    }

    if (condition && !isNullEmptyOrUndefined(gridState.state)) {
      if (
        onlyFetchWithFilters === undefined ||
        (onlyFetchWithFilters && !isNullEmptyOrUndefined(fullSearch))
      ) {
        fetchData(gridState.state);
        isInitialLoad.current = false;
      }
    }
  };

  useEffect(() => {
    fetchDataConditionally();
  }, [gridState.refreshValue, refreshToggle]);

  useEffect(() => {
    if (!isNullEmptyOrUndefined(gridState.state)) {
      fetchDataConditionally();
    }
  }, [gridState.state]);

  useEffect(() => {
    if (refreshOnSearchChange) {
      fetchDataConditionally();
    }
  }, [refreshOnSearchChange, gridState.search]);

  useEffect(() => {
    gridState.setState(dataState);
  }, [dataState]);

  useEffect(() => {
    if (hasSearchUpdated()) {
      if (!isInitialLoad.current) {
        pageNeedsReset.current = true;
      }
      gridState.setSearch(fullSearch);
    }
  }, [fullSearch]);

  return {
    hasData,
    isFetching,
    /* Grid Props */
    onDataStateChange,
    total: gridState.total,
    dataState: gridState.state,
    data: gridState.data,
  };
};
