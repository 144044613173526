import './Layout.scss';
import React, { Fragment } from 'react';
import { Header, Footer } from '.';
import { useGlobalState } from 'Context';
import { Render } from 'Components/Display';
import { Sidebar } from 'Components/Navigation';

export const Layout: React.FC = ({ children }) => {
  const { isUnauthorized } = useGlobalState();

  return (
    <Fragment>
      <div id="toast-container"></div>

      <div className="main">
        <header className="main__header">
          <Header />
        </header>
        <Sidebar />
        <main className={'main__content'}>
          <Render condition={!isUnauthorized}></Render>
          {children}
        </main>
        <footer className="main__footer">
          <Footer />
        </footer>
      </div>
    </Fragment>
  );
};
