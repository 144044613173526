import React, {  } from "react";
import {
  DateHeaderCellProps,
  SchedulerViewSlotProps,
  SchedulerViewSlot,
} from "@progress/kendo-react-scheduler";
import {
  fullClassName,
  toggledClass,
} from "Utilities";
import { formatDate } from "@telerik/kendo-intl";

export const CustomMonthDateHeaderCell: React.FC<DateHeaderCellProps> = ({
  date,
}) => {
  return (
    <div className="k-scheduler-cell k-heading-cell">
      <div className="k-link k-nav-day">
        {formatDate(date, { weekday: "short" })}
      </div>
    </div>
  );
};

export const CustomMonthViewSlot: React.FC<SchedulerViewSlotProps> = ({
  className,
  start,
  ...slotProps
}) => {
  var dateToRender = new Date(start);
  var todaysDate = new Date();
  dateToRender.setHours(0, 0, 0, 0);
  todaysDate.setHours(0, 0, 0, 0);
  const isToday = dateToRender.valueOf() === todaysDate.valueOf();

  return (
    <SchedulerViewSlot
      className={fullClassName(className, toggledClass("today-slot", isToday))}
      start={start}
      {...slotProps}
    />
  );
};
