import { useFilterBarValue, useFilterConfig } from "Context";
import { CoreEnum, DropdownItemModel, FilterBarFilterType } from "Models";

export const useFilterBarDropdownHelpers = (name: string) => {
  const [value, setValue] = useFilterBarValue(name);
  const filterConfig = useFilterConfig(name);

  const isEnumValue = filterConfig?.type === FilterBarFilterType.EnumDropdown;

  const isOptionChecked = (dropdownValue?: DropdownItemModel | CoreEnum) => {
    const isChecked = (value || []).some((x: DropdownItemModel | CoreEnum) => {
      return isEnumValue
        ? (x as CoreEnum).value === (dropdownValue as CoreEnum).value
        : (x as DropdownItemModel).id ===
            (dropdownValue as DropdownItemModel).id;
    });
    return isChecked;
  };

  const isItemSelected = (dropdownValue?: DropdownItemModel | CoreEnum) => {
    return isEnumValue
      ? (value as CoreEnum)?.value === (dropdownValue as CoreEnum).value
      : (value as DropdownItemModel)?.id ===
          (dropdownValue as DropdownItemModel).id;
  };

  const handleCheck = (
    checked: boolean,
    item: DropdownItemModel | CoreEnum
  ) => {
    if (checked) {
      var newValue = value !== undefined ? [...value] : [];
      setValue(newValue.concat(item));
    } else {
      setValue(
        value.filter((x: DropdownItemModel | CoreEnum) => {
          return isEnumValue
            ? (x as CoreEnum).value !== (item as CoreEnum).value
            : (x as DropdownItemModel).id !== (item as DropdownItemModel).id;
        })
      );
    }
  };

  const handleClickOption = (item: DropdownItemModel) => {
    setValue(item);
  };

  return { isOptionChecked, isItemSelected, handleCheck, handleClickOption };
};
