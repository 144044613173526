import { AsyncLoadingSection, Render } from 'Components/Display';
import { CustomCheckbox } from 'Components/Form';
import {
  DropdownItemModel,
  CoreEnum,
  AsyncStatus,
  InputType,
  DateDropdownItemModel,
} from 'Models';
import React, { Fragment } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { fullClassName, toggledClass } from 'Utilities';

export type DropdownOptionsProps = {
  options: DropdownItemModel[] | CoreEnum[] | DateDropdownItemModel[];
  dropdownFilter: string;
  setDropdownFilter: React.Dispatch<React.SetStateAction<string>>;
  loadingStatus?: AsyncStatus;
  isOptionChecked?: (dropdownValue?: DropdownItemModel | CoreEnum) => boolean;
  isItemSelected?: (dropdownValue?: DropdownItemModel | CoreEnum) => boolean;
  handleCheck?: (checked: boolean, item: DropdownItemModel | CoreEnum) => void;
  handleClickOption?: (item: DropdownItemModel) => void;
  multiSelect?: boolean;
  hideSearchBar?: boolean;
};

export const DropdownOptions: React.FC<DropdownOptionsProps> = ({
  dropdownFilter,
  setDropdownFilter,
  options = [],
  multiSelect,
  loadingStatus = AsyncStatus.Success,
  isOptionChecked,
  isItemSelected,
  handleCheck,
  handleClickOption,
  hideSearchBar,
}) => {
  return (
    <Fragment>
      <Render condition={!hideSearchBar}>
        <div className="p-small">
          <input
            className="dropdown-options-filter"
            type={InputType.Text}
            value={dropdownFilter}
            onChange={({ target }: any) => setDropdownFilter(target.value)}
            placeholder="Search"
          />
        </div>
      </Render>
      <div className="dropdown-options-container">
        <AsyncLoadingSection
          loadingStatus={loadingStatus}
          noIdleLoading
          noLabel
          noErrorDisplay
        >
          <Scrollbars
            autoHeight
            autoHeightMax={180}
            renderThumbVertical={(props) => (
              <div {...props} className="small-scroll-thumb" />
            )}
          >
            <div className="dropdown-options-wrapper">
              {options && options.length > 0 ? (
                (options as any[]).map((dropdownValue: any, index: number) => (
                  <div
                    key={index}
                    className={fullClassName(
                      'dropdown-option-wrapper',
                      toggledClass('with-checkbox', multiSelect),
                      toggledClass(
                        'active',
                        isItemSelected && isItemSelected(dropdownValue)
                      )
                    )}
                    onClick={
                      multiSelect
                        ? (e) => {
                            e.stopPropagation();
                            handleCheck &&
                              handleCheck(
                                !(
                                  isOptionChecked &&
                                  isOptionChecked(dropdownValue)
                                ),
                                dropdownValue
                              );
                          }
                        : () =>
                            handleClickOption &&
                            handleClickOption(dropdownValue)
                    }
                  >
                    {multiSelect ? (
                      <CustomCheckbox
                        className="dropdown-checkbox no-label-click"
                        checked={
                          isOptionChecked && isOptionChecked(dropdownValue)
                        }
                        value={
                          isOptionChecked && isOptionChecked(dropdownValue)
                        }
                        label={
                          dropdownValue?.name || dropdownValue?.displayName
                        }
                        onChange={(e) => {
                          e.syntheticEvent.stopPropagation();
                        }}
                      />
                    ) : (
                      <span>
                        {dropdownValue?.name || dropdownValue?.displayName}
                      </span>
                    )}
                  </div>
                ))
              ) : (
                <div className="color-border-gray flex-center height-50">
                  No Options Available
                </div>
              )}
            </div>
          </Scrollbars>
        </AsyncLoadingSection>
      </div>
    </Fragment>
  );
};
