import './DateTimePickers.scss';
import React, {
  FocusEventHandler,
  HTMLProps,
  JSXElementConstructor,
  KeyboardEvent,
  ReactElement,
  useEffect,
  useRef,
  useState,
} from 'react';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import {
  copyTime,
  fullClassName,
  toggledClass,
  useOnClickOutside,
  useToggler,
} from 'Utilities';
import { EasyPopup, EasyPopupProps, Render } from 'Components/Display';
import { InputType, Keys } from 'Models';

export type DateTimePickerProps = Omit<HTMLProps<HTMLDivElement>, 'value'> & {
  value?: Date | null;
  onChange?: any;
};

export enum PickerType {
  Date = 'date',
  Time = 'time',
  DateTime = 'dateTime',
}

export type PickerWrapperProps = Omit<HTMLProps<HTMLDivElement>, 'value'> & {
  type: PickerType;
  customInput: ReactElement<any, string | JSXElementConstructor<any>>;
  customAmPmInput?: ReactElement<
    any,
    string | JSXElementConstructor<any>
  > | null;
  hidePickerButton?: boolean;
  disabled?: boolean;
  withoutPopup?: boolean;
  popupProps?: Partial<EasyPopupProps>;
  togglePopup?: () => void;
  popupIsOpen?: boolean;
  setPopupOpen?: (isOpen: boolean) => void;
  onChange: any;
  onFocus?: FocusEventHandler;
  onBlur?: FocusEventHandler;
  popupToggleRef?: React.MutableRefObject<any>;
  value?: Date | null;
};

export const PickerWrapper: React.FC<PickerWrapperProps> = ({
  customInput,
  hidePickerButton,
  disabled,
  className,
  type,
  withoutPopup,
  children,
  popupProps,
  popupIsOpen,
  togglePopup,
  setPopupOpen,
  onChange,
  onFocus,
  onBlur,
  customAmPmInput = null,
  popupToggleRef,
  value,
  style,
  ...divProps
}) => {
  const {
    focused,
    inputWrapperRef,
    maskedInput,
    amPmInput,
    handleCalendarToggleClick,
    realToggleRef,
    realIsOpen,
    realToggle,
  } = useDateTimePickerWrapper({
    customInput,
    disabled,
    popupIsOpen,
    togglePopup,
    setPopupOpen,
    onChange,
    onFocus,
    onBlur,
    customAmPmInput,
    popupToggleRef,
    value,
    type,
  });

  return (
    <div
      className="flex-column position-relative"
      style={{ ...style }}
      {...divProps}
    >
      <div
        className={fullClassName(
          'field-value flex-vertical-center date-picker-wrap',
          toggledClass('focused', focused),
          toggledClass('hidden-picker-button', hidePickerButton),
          toggledClass('disabled', disabled),
          className
        )}
        ref={(ref) => (inputWrapperRef.current = ref)}
      >
        {maskedInput}
        {amPmInput}
        <div className="ml-auto">
          <Render condition={type !== PickerType.Time && !hidePickerButton}>
            <CalendarTodayIcon
              className="color-primary cursor-pointer date-picker-toggle"
              onClick={handleCalendarToggleClick}
            />
          </Render>
          <Render condition={type !== PickerType.Date && !hidePickerButton}>
            <AccessTimeIcon
              className="color-primary cursor-pointer date-picker-toggle"
              onClick={handleCalendarToggleClick}
            />
          </Render>
        </div>
      </div>
      {withoutPopup ? (
        children
      ) : (
        <EasyPopup
          className="date-picker-popup"
          togglerRef={inputWrapperRef}
          isOpen={realIsOpen}
          toggleIsOpen={realToggle}
          customAnchorAlign={{ horizontal: 'right', vertical: 'bottom' }}
          customPopupAlign={{ horizontal: 'right', vertical: 'top' }}
          animate={false}
          {...popupProps}
        >
          {children}
        </EasyPopup>
      )}
    </div>
  );
};

const useDateTimePickerWrapper = ({
  customInput,
  disabled,
  popupIsOpen,
  togglePopup,
  setPopupOpen,
  onChange,
  onFocus,
  onBlur,
  customAmPmInput = null,
  popupToggleRef,
  value,
  type,
}: Partial<PickerWrapperProps>) => {
  const toggleButtonRef = useRef<any>(null);
  const inputWrapperRef = useRef<any>(null);

  const [focused, setFocused] = useState(false);
  const [pickerOpen, togglePickerOpen, setPickerOpen] = useToggler();

  const realIsOpen = popupIsOpen === undefined ? pickerOpen : popupIsOpen;
  const realToggle = togglePopup === undefined ? togglePickerOpen : togglePopup;
  const realSetIsOpen =
    setPopupOpen === undefined ? setPickerOpen : setPopupOpen;
  const realToggleRef = popupToggleRef || toggleButtonRef;

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    const { key } = e;

    customInput &&
      customInput.props.onKeyDown &&
      customInput.props.onKeyDown(e);

    switch (key) {
      case Keys.Tab:
        // unfocus
        e.stopPropagation();
        setFocused(false);
        realSetIsOpen(false);
        break;
      case Keys.Space:
        // toggle calendar
        e.stopPropagation();
        e.preventDefault();
        realToggle();
        break;
      case Keys.T:
        // set date to today
        e.stopPropagation();
        var todayDate = new Date();
        switch (type) {
          case PickerType.Date:
            todayDate.setHours(0, 0, 0, 0);
            break;
          case PickerType.Time:
            todayDate = copyTime(todayDate);
            break;
        }
        onChange(todayDate);
        break;
      case Keys.Y:
        // set date to yesterday
        var yesterday = new Date();
        switch (type) {
          case PickerType.Date:
            e.stopPropagation();
            yesterday.setDate(yesterday.getDate() - 1);
            yesterday.setHours(0, 0, 0, 0);
            onChange(yesterday);
            break;
          case PickerType.Time:
            return;
        }
        break;
    }
  };

  const handleFocus = (e?: any) => {
    onFocus && onFocus(e);
    setFocused(true);
  };

  const handleBlur = (e?: any) => {
    onBlur && onBlur(e);
    setFocused(false);
  };

  const handleCalendarToggleClick = () => {
    if (disabled) return;
    realToggle();
    setFocused(!pickerOpen);
  };

  const handleAmPmInputFocus = (e: any) => {
    handleFocus(e);
    e && e.target && e.target.select();
  };

  const maskedInput = customInput
    ? React.cloneElement(customInput, {
        ...customInput.props,
        onFocus: handleFocus,
        onBlur: handleBlur,
        onKeyDown: handleKeyDown,
        disabled: disabled,
      })
    : null;

  const amPmInput = customAmPmInput
    ? React.cloneElement(customAmPmInput, {
        ...customAmPmInput.props,
        onFocus: handleAmPmInputFocus,
        onBlur: handleBlur,
        disabled: disabled,
      })
    : null;

  useEffect(() => {
    realSetIsOpen(false);
  }, [value]);

  useOnClickOutside(inputWrapperRef, () => {
    setFocused(false);
  });

  return {
    focused,
    inputWrapperRef,
    maskedInput,
    amPmInput,
    handleCalendarToggleClick,
    realToggleRef,
    realIsOpen,
    realToggle,
  };
};
