import './SmartCheckboxList.scss';
import React, { Fragment } from 'react';
import { useFormStateValue } from 'Context';
import { InputType } from 'Models/FormModels';
import { SmartInputBlock } from './SmartInputBlock';
import { FormColumn, Render } from 'Components/Display';
import { ColumnLayout } from 'Models/SharedModels';
import { Label } from 'reactstrap';
import { Checkbox } from '@progress/kendo-react-inputs';
import { ToggleableProps } from 'Models';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { fullClassName, toggledClass, useToggler } from 'Utilities';
import { Collapse } from 'react-collapse';

export type SmartCheckboxListProps = {
  name: string;
  labelField: string;
  checkedField: string;
  groupField: string;
  groupFields?: string[];
  tooltipField?: string;
  hideCheckAll?: boolean;
  defaultClosed?: boolean;
};

export const SmartCheckboxList: React.FC<SmartCheckboxListProps> = ({
  name,
  checkedField,
  hideCheckAll,
  ...otherProps
}) => {
  const [listValue, setListValue] = useFormStateValue(name);

  const handleCheckAll = ({ target }: any) => {
    const checked = target?.element?.current?.checked;
    const newList = listValue.map((x: any) => {
      var copied = { ...x };
      copied[checkedField] = checked;
      return copied;
    });
    setListValue(newList);
  };

  return (
    <Fragment>
      <Render condition={!hideCheckAll}>
        <SelectAllCheckbox
          value={getBulkCheckVal(listValue, checkedField)}
          handleChange={handleCheckAll}
        />
      </Render>
      <GroupedCheckBoxes
        name={name}
        checkedField={checkedField}
        {...otherProps}
      />
    </Fragment>
  );
};

type SelectAllCheckboxProps = {
  handleChange: (e: any) => void;
  value: boolean | null;
};

const SelectAllCheckbox: React.FC<SelectAllCheckboxProps> = ({
  handleChange,
  value,
}) => {
  return (
    <div className="flex-normal flex-vertical-center border-bottom-light pb-2">
      <Checkbox
        id="check-all"
        name="check-all"
        value={value}
        checked={value}
        onChange={handleChange}
        className="mr-2 flex-vertical-center"
      />
      <Label
        for="check-all"
        className="cursor-pointer no-user-select mb-0 flex-vertical-center"
      >
        <h4 className="color-primary-dark font-secondary-bold">All</h4>
      </Label>
    </div>
  );
};

const GroupedCheckBoxes: React.FC<SmartCheckboxListProps> = ({
  groupField,
  checkedField,
  labelField,
  tooltipField,
  name,
  defaultClosed,
}) => {
  const [listValue, setListValue] = useFormStateValue<any[]>(name);
  const groupedList: { [x: string]: any[] } =
    listValue?.reduce((map, val) => {
      (map[val[groupField]] = map[val[groupField]] || []).push(val);
      return map;
    }, {}) || {};

  const getInputName = (item: any) => {
    return `${name}[${listValue.findIndex(
      (val) => val === item
    )}].${checkedField}`;
  };

  const getHeaderName = (index: number) => {
    return `header-check${index}`;
  };

  const handleHeaderCheckboxChange = ({ target }: any, key: string) => {
    const checked = target?.element?.current?.checked;
    const newList = listValue.map((x: any) => {
      var copied = { ...x };
      if (copied[groupField] === key) {
        copied[checkedField] = checked;
      }
      return copied;
    });
    setListValue(newList);
  };

  return (
    <div className="checkbox-list">
      {Object.entries(groupedList).map(([key, value], mainIndex) => (
        <CheckboxList
          headerCheckValue={getBulkCheckVal(groupedList[key], checkedField)}
          headerName={getHeaderName(mainIndex)}
          handleHeaderCheckboxChange={(e) => handleHeaderCheckboxChange(e, key)}
          getInputName={getInputName}
          labelField={labelField}
          tooltipField={tooltipField}
          key={mainIndex}
          title={key}
          value={value}
          defaultClosed={defaultClosed}
        />
      ))}
    </div>
  );
};

export type CheckboxListProps = {
  headerCheckValue: boolean | null;
  headerName: string;
  getInputName: (item: any) => string;
  handleHeaderCheckboxChange: (e: any) => void;
  labelField: string;
  tooltipField?: string;
  title: string;
  value: any[];
  defaultClosed?: boolean;
};

export const CheckboxList: React.FC<CheckboxListProps> = ({
  headerCheckValue,
  headerName,
  getInputName,
  handleHeaderCheckboxChange,
  labelField,
  tooltipField = '',
  title,
  value,
  defaultClosed,
}) => {
  const [isOpen, toggleIsOpen] = useToggler(!defaultClosed);

  return (
    <FormColumn className="py-2 checkbox-list-column" hideSeparator>
      <div
        className={fullClassName(
          'flex-normal flex-vertical-center checkbox-section-header',
          toggledClass('open', isOpen)
        )}
      >
        <Checkbox
          id={headerName}
          name={headerName}
          value={headerCheckValue}
          checked={headerCheckValue}
          onChange={handleHeaderCheckboxChange}
          className="mr-2 flex-vertical-center"
        />
        <Label
          for={headerName}
          className="cursor-pointer no-user-select mb-0 flex-vertical-center"
        >
          <h5 className="color-primary-dark font-secondary-bold no-padding-margin">
            {title}
          </h5>
        </Label>
        <CollapseArrow isOpen={isOpen} toggle={toggleIsOpen} />
      </div>
      <Collapse isOpened={isOpen}>
        {value?.map((item: any, subIndex) => (
          <div key={subIndex} title={item[tooltipField]}>
            <SmartInputBlock
              name={getInputName(item)}
              label={item[labelField]}
              type={InputType.Checkbox}
              noPaddingMargin
            />
          </div>
        ))}
      </Collapse>
    </FormColumn>
  );
};

type CollapseArrowProps = ToggleableProps & {
  isOpen?: boolean;
};

const CollapseArrow: React.FC<CollapseArrowProps> = ({ toggle, isOpen }) => {
  return (
    <div
      className={fullClassName(
        'collapse-arrow-wrapper ml-2',
        toggledClass('open', isOpen)
      )}
      onClick={toggle}
    >
      <ExpandMoreIcon className="collapse-arrow" />
    </div>
  );
};

export const getBulkCheckVal = (list?: any[], checkedField = 'checked') => {
  var checked = list?.every((x) => x[checkedField])
    ? true
    : list?.some((x) => x[checkedField])
    ? null
    : false;
  return checked;
};
