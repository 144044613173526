import { makeStyles } from '@mui/styles';
import {
  PageCard,
  PageCardRow,
  PageCardRowSection,
  PageCardSection,
} from 'Components/Display';
import { useEntity } from 'Context';
import { GuidelineLink } from 'Models';
import { DocumentsSubRoute, getSubRouteName, ROUTES } from 'Navigation';
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem, Col, Row } from 'reactstrap';

export const ViewGuidelineBreadCrumb: React.FC = () => {
  let entity = useEntity();
  let guidelinesRoute = getSubRouteName(
    ROUTES.DOCUMENTS,
    DocumentsSubRoute.Guidelines
  );
  return (
    <Fragment>
      <Breadcrumb tag="nav" listTag="div">
        <BreadcrumbItem>
          <Link to={ROUTES.DASHBOARD}>Home</Link>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <Link to={guidelinesRoute}>Guidelines</Link>
        </BreadcrumbItem>
        <BreadcrumbItem>{entity.title}</BreadcrumbItem>
      </Breadcrumb>
    </Fragment>
  );
};

export const ViewGuideline: React.FC = () => {
  const entity = useEntity();
  let guidelineLinks = entity.guidelineLink as GuidelineLink[];

  let benefitState =
    entity.benefitState?.displayName != undefined
      ? entity.benefitState.displayName
      : '';

  const useStyles = makeStyles({
    root: {
      minWidth: 275,
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
  });

  const classes = useStyles();

  return (
    <Fragment>
      <PageCard className={classes.root}>
        <PageCardRow>
          <PageCardSection title="General">
            <Row>
              <Col xs="12" lg="6">
                <span className="field-label data-display">Benefit State:</span>
                <span className="field-value">{benefitState}</span>
              </Col>
            </Row>
            <Row>
              <Col>
                <span className="field-label data-display">Description:</span>
                <span className="field-value">{entity.description}</span>
              </Col>
            </Row>
          </PageCardSection>
        </PageCardRow>
      </PageCard>

      <PageCard>
        <PageCardRow>
          <PageCardSection title="Link To Guideline">
            <PageCardRowSection>
              <Row>
                <Col xs="2">
                  <th>Title</th>
                </Col>
                <Col xs="10">
                  <th>Link</th>
                </Col>
              </Row>
            </PageCardRowSection>

            {guidelineLinks?.map((key) => (
              <GuideLineLink link={key.link} />
            ))}
          </PageCardSection>
        </PageCardRow>
      </PageCard>
    </Fragment>
  );
};

const GuideLineLink: React.FC<GuidelineLink> = (
  link: GuidelineLink,
  ...props
) => {
  let title = link.link?.title;
  let url =
    link.link?.url?.startsWith('http://') ||
    link.link?.url?.startsWith('https://')
      ? link.link?.url
      : `http://${link.link?.url}`;
  return (
    <PageCardRowSection>
      <Row>
        <Col xs="2">
          <span className="field-label overflow-wrap-break-word">{title}</span>
        </Col>
        <Col xs="10">
          <a href={url} target="_blank" className="overflow-ellipsis">
            {url}
          </a>
        </Col>
      </Row>
    </PageCardRowSection>
  );
};
