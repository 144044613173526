import {
  GridCellProps,
  GridColumn as Column,
} from '@progress/kendo-react-grid';
import { AddButton } from 'Components/Form';
import {
  DateCell,
  EditDeleteCell,
  FilterBarMainGrid,
  GridButtonSection,
  LinkCell,
  TooltipCell,
} from 'Components/Grid';
import { SecureComponent } from 'Components/Security';
import { FormProvider, GridProvider } from 'Context';
import {
  defaultGridProps,
  DropdownFilterConfig,
  FilterBarFilterType,
  FilterConfig,
  IFilterConfig,
  StaticFilterConfig,
} from 'Models';
import { Permission } from 'Models/Templates/Permission/Permission';
import { ENDPOINTS } from 'Models/Templates/Routes/Routes';
import {
  addEditRoute,
  AddEditSubRoute,
  ReferralSubRoute,
  ROUTES,
} from 'Navigation';
import React, { HTMLProps } from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import {
  buildEndpoint,
  buildRoute,
  EMPTY_GUID,
  useGridSearch,
  useHasPermissions,
} from 'Utilities';
import { LightPageHeader } from '../../Components/Display';

class ReferralSearch {
  searchKey?: string;
  id?: string;
  claimId?: string;
  dueDate?: Date;
  referralDate?: Date;
}

export enum ReferralGridIds {
  DetailedSearch = 'detailedSearch',
}

export const Referrals: React.FC = () => {
  return (
    <GridProvider entityName="Referral">
      <Breadcrumb tag="nav" listTag="div">
        <BreadcrumbItem>
          <Link to={ROUTES.DASHBOARD}>Home</Link>
        </BreadcrumbItem>
        <BreadcrumbItem>Referrals</BreadcrumbItem>
      </Breadcrumb>
      <LightPageHeader tall noBorder className="page-header-shadow">
        <h1>Referrals</h1>
      </LightPageHeader>
      <ReferralsFilteredGrid />
    </GridProvider>
  );
};

export type ReferralsFilteredGridProps =
  HTMLProps<ReferralsFilteredGridProps> & {
    id?: string;
  };

export const ReferralsFilteredGrid: React.FC<ReferralsFilteredGridProps> = ({
  id,
  ...props
}) => {
  const referralSearch = new ReferralSearch();
  referralSearch.claimId = id;
  return (
    <FormProvider formModel={referralSearch}>
      <ReferralsGrid id={id} />
    </FormProvider>
  );
};

const ReferralsGrid: React.FC<ReferralsFilteredGridProps> = ({
  id,
  ...props
}) => {
  const filters: IFilterConfig[] = [
    new FilterConfig(
      'searchKey',
      FilterBarFilterType.SearchText,
      'Stradix Number, Claim Number, Claimant, Referral Number, Doctor'
    ),
    new FilterConfig(
      'viewAllClients',
      FilterBarFilterType.YesNoBooleanRadioGroup,
      'View All Clients',
      undefined,
      undefined,
      undefined,
      undefined,
      [Permission.ViewAllClients]
    ),
    new DropdownFilterConfig(
      'clients',
      FilterBarFilterType.AsyncDropdown,
      'Clients',
      {
        readEndpoint: ENDPOINTS.clients.dropdownItems,
        multiSelect: true,
      }
    ),
    new FilterConfig('dueDate', FilterBarFilterType.DateRange, 'Due Date'),
    new FilterConfig(
      'referralDate',
      FilterBarFilterType.DateRange,
      'Referral Date'
    ),
    new StaticFilterConfig('claimId'),
  ];

  const hasPermissions = useHasPermissions();

  const hasManagedReviewerPermissions = hasPermissions([
    Permission.ManagedReviewerFilters,
  ]);
  const hasAdminReviewerFiltersPermissions = hasPermissions([
    Permission.AdminReviewerFilters,
  ]);

  if (hasManagedReviewerPermissions || hasAdminReviewerFiltersPermissions) {
    filters.push(
      new FilterConfig(
        'reviewers',
        FilterBarFilterType.AsyncDropdown,
        'Reviewer',
        {
          readEndpoint:
            ENDPOINTS.managedreviewers.endpointString('DropdownItems'),
        },
        (value) => value.name,
        true
      )
    );
  }
  const [gridToggler] = useGridSearch();

  let endpoint = ENDPOINTS.referrals.read;
  return (
    <React.Fragment>
      <SecureComponent permissions={[Permission.AddReferral]}>
        <GridButtonSection>
          <AddButton
            url={buildRoute(
              ROUTES.ADD_EDIT,
              AddEditSubRoute.ReferralsRequest,
              EMPTY_GUID,
              id
            )}
          >
            New Referral Request
          </AddButton>
        </GridButtonSection>
      </SecureComponent>

      <FilterBarMainGrid
        isMainGrid
        filterBarProps={{
          filterConfigurations: filters,
          filterValues: { claimId: gridToggler.claimId },
        }}
        refreshToggle={gridToggler}
        readEndpoint={endpoint}
        exportEndpoint={buildEndpoint(
          ENDPOINTS.referrals.endpointString('ExportToCsv')
        )}
        {...defaultGridProps}
      >
        <Column field="stradixNumber" title="Stradix Number" width='auto' />
        <Column field="name" cell={referralNameCell} title="Name" />
        <Column field="clientName" title="Client" />
        <Column field="typeOfEval" title="Type of Eval" />
        <Column field="claimNumber" title="Claim Number" />
        <Column
          field="referralNumber"
          title="Referral Number"
          cell={ReferralNumberCell}
        />
        <Column
          field="escalationDescription"
          title="Description"
          cell={TooltipCell}
        />
        <Column field="dueDate" title="Due Date" cell={dotNetDateColumn} />
        <Column
          field="referralDate"
          title="Referral Date"
          cell={dotNetDateColumn}
        />
        <Column
          field="appointment.appointmentStatus.displayName"
          title="Status"
        />
        <Column field="id" width="120" title="Actions" cell={ActionsCellEdit} />
      </FilterBarMainGrid>
    </React.Fragment>
  );
};

const dotNetDateColumn: React.FC<GridCellProps> = (props: GridCellProps) => {
  return <DateCell {...props} dateFormat={'MM/DD/YYYY HH:mm'} />;
};

const ReferralNumberCell: React.FC<GridCellProps> = (props: GridCellProps) => {
  return (
    <LinkCell
      endpoint={ROUTES.REFERRAL}
      linkField="id"
      subRoute={ReferralSubRoute.Reports}
      {...props}
    ></LinkCell>
  );
};

const referralNameCell: React.FC<GridCellProps> = (props: GridCellProps) => {
  return (
    <LinkCell
      endpoint={ROUTES.REFERRAL}
      linkField="id"
      subRoute={ReferralSubRoute.View}
      {...props}
    ></LinkCell>
  );
};

const ActionsCellEdit: React.FC<GridCellProps> = (props: GridCellProps) => {
  return (
    <EditDeleteCell
      editRoute={addEditRoute(AddEditSubRoute.Referral)}
      editPermissions={[Permission.EditReferral]}
      deleteEndpoint={ENDPOINTS.referrals.base}
      deletePermissions={[Permission.DeleteReferrals]}
      viewPermissions={[Permission.ViewReferral]}
      viewRoute={buildEndpoint(ROUTES.REFERRAL)}
      showViewLink
      {...props}
    />
  );
};
