import "./CustomSwitch.scss";
import React from "react";
import { Switch, SwitchProps } from "@progress/kendo-react-inputs";

export const CustomSwitch: React.FC<SwitchProps> = ({
  value,
  ...switchProps
}) => {
  return (
    <div className="switch-wrapper">
      <Switch checked={value as boolean} value={value} {...switchProps} />
    </div>
  );
};
