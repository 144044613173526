import { InputBlockProps } from 'Components/Form';
import {
  SearchTextFilter,
  RadioEnumFilter,
  FilterBarDropdown,
  FilterBarDateRange,
  FilterBarAsyncDropdown,
  FilterBarPopupProps,
  YesNoBooleanFilter,
  FilterBarEnumDropdown,
  FilterBarDatePicker,
} from 'Components/Grid';
import { toStandardDateString, toTimeString } from 'Utilities';
import { v4 as uuidv4 } from 'uuid';
import { CoreEnum, DropdownItemModel } from './SharedModels';

export enum FilterBarFilterType {
  SearchText = 'searchText',
  Dropdown = 'dropdown',
  AsyncDropdown = 'asyncDropdown',
  EnumDropdown = 'enumDropdown',
  DatePicker = 'datePicker',
  DateRange = 'dateRange',
  EnumRadioGroup = 'enumRadioGroup',
  YesNoBooleanRadioGroup = 'yesNoBooleanRadioGroup',
  Static = 'static',
  StaticWithDisplay = 'staticWithDisplay',
}

export type FilterTypeMap = {
  [type in FilterBarFilterType]?: any;
};

export const FILTER_COMPONENT_MAP: FilterTypeMap = {
  [FilterBarFilterType.SearchText]: SearchTextFilter,
  [FilterBarFilterType.EnumRadioGroup]: RadioEnumFilter,
  [FilterBarFilterType.Dropdown]: FilterBarDropdown,
  [FilterBarFilterType.EnumDropdown]: FilterBarEnumDropdown,
  [FilterBarFilterType.DateRange]: FilterBarDateRange,
  [FilterBarFilterType.AsyncDropdown]: FilterBarAsyncDropdown,
  [FilterBarFilterType.YesNoBooleanRadioGroup]: YesNoBooleanFilter,
  [FilterBarFilterType.DatePicker]: FilterBarDatePicker,
};

export type FilterTypeChipRenderMap = {
  [type in FilterBarFilterType]?: (value: any, displayName?: string) => string;
};

export const DEFAULT_CHIP_RENDER_MAP: FilterTypeChipRenderMap = {
  [FilterBarFilterType.EnumRadioGroup]: (
    value: CoreEnum,
    displayName?: string
  ) => `${displayName}: ${value?.displayName}`,
  [FilterBarFilterType.Dropdown]: (value: DropdownItemModel) =>
    value?.name || '',
  [FilterBarFilterType.EnumDropdown]: (value: CoreEnum) =>
    value?.displayName || '',
  [FilterBarFilterType.AsyncDropdown]: (value: DropdownItemModel) =>
    value?.name || '',
  [FilterBarFilterType.YesNoBooleanRadioGroup]: (value: boolean) =>
    value ? 'Yes' : 'No',
  [FilterBarFilterType.DatePicker]: (value: string) =>
    toStandardDateString(new Date(value)),
};

export interface IDropdownQueryConfig {
  dependencyFilterName: string;
  queryParameterName: string;
}

export interface IFilterConfig {
  id: string;
  name: string;
  type: FilterBarFilterType;
  displayName: string;
  inputProps?: Partial<InputBlockProps>;
  popupProps?: FilterBarPopupProps;
  customChipRender?: (value: any, displayName?: string) => string;
  /* only relevant for dropdown types */
  multiSelect?: boolean;
  dropdownEndpointFilter?: IDropdownQueryConfig;
  permissions?: number[];
}

export interface IFilterValues {
  [x: string]: any;
}

export class FilterConfig implements IFilterConfig {
  constructor(
    name: string,
    type: FilterBarFilterType,
    displayName: string,
    inputProps?: Partial<InputBlockProps>,
    customChipRender = DEFAULT_CHIP_RENDER_MAP[type],
    multiSelect?: boolean,
    popupProps?: FilterBarPopupProps,
    permissions?: number[]
  ) {
    this.id = uuidv4();
    this.name = name;
    this.type = type;
    this.displayName = displayName;
    this.inputProps = inputProps;
    this.customChipRender = customChipRender;
    this.multiSelect = multiSelect;
    this.popupProps = popupProps;
    this.permissions = permissions;
  }

  id: string;
  name: string;
  type: FilterBarFilterType;
  displayName: string;
  inputProps?: Partial<InputBlockProps>;
  popupProps?: FilterBarPopupProps;
  customChipRender?: (value: any, displayName?: string) => string;
  multiSelect?: boolean;
  permissions?: number[];
}

export type OtherConfigItems = {
  inputProps?: Partial<InputBlockProps>;
  customChipRender?: (value: any, displayName?: string) => string;
  multiSelect?: boolean;
  popupProps?: FilterBarPopupProps;
  dropdownQueryConfig?: IDropdownQueryConfig;
  readEndpoint?: string;
};

export class DropdownFilterConfig implements IFilterConfig {
  constructor(
    name: string,
    type: FilterBarFilterType,
    displayName: string,
    {
      readEndpoint,
      inputProps = { readEndpoint },
      popupProps,
      customChipRender = DEFAULT_CHIP_RENDER_MAP[type],
      multiSelect,
      dropdownQueryConfig: dropdownEndpointFilter,
    }: OtherConfigItems,
    permissions?: number[]
  ) {
    this.id = uuidv4();
    this.name = name;
    this.type = type;
    this.displayName = displayName;
    this.inputProps = inputProps;
    this.customChipRender = customChipRender;
    this.multiSelect = multiSelect;
    this.popupProps = popupProps;
    this.dropdownEndpointFilter = dropdownEndpointFilter;
    this.permissions = permissions;
  }

  id: string;
  name: string;
  type: FilterBarFilterType;
  displayName: string;
  inputProps?: Partial<InputBlockProps>;
  popupProps?: FilterBarPopupProps;
  customChipRender?: (value: any, displayName?: string) => string;
  multiSelect?: boolean;
  dropdownEndpointFilter?: IDropdownQueryConfig;
  permissions?: number[];
}

export class FilterModelItem {
  constructor(id?: string, name?: string) {
    this.id = id;
    this.name = name;
  }
  id?: string;
  name?: string;
}

export class StaticFilterConfig extends FilterConfig {
  constructor(name: string) {
    super(name, FilterBarFilterType.Static, '');
  }
}

export class StaticWithDisplayFilterConfig extends FilterConfig {
  constructor(
    name: string,
    customChipRender?: (value: any, displayName?: string) => string
  ) {
    super(
      name,
      FilterBarFilterType.StaticWithDisplay,
      '',
      undefined,
      customChipRender
    );
  }
}

export class MultiSelectFilterConfig extends FilterConfig {
  constructor(
    name: string,
    type: FilterBarFilterType,
    displayName: string,
    inputProps?: Partial<InputBlockProps>,
    customChipRender = DEFAULT_CHIP_RENDER_MAP[type],
    popupProps?: FilterBarPopupProps
  ) {
    super(
      name,
      type,
      displayName,
      inputProps,
      customChipRender,
      true,
      popupProps
    );
  }
}
