import React from 'react';
import { SecureComponent } from 'Components/Security';
import {
  TextDeleteButton,
  TextEditButton,
  TextNoteButton,
  TextScheduleButton,
  TextViewButton,
} from 'Components/Form';
import { Render } from 'Components/Display';
import { GridCellProps } from '@progress/kendo-react-grid';
import {
  buildRoute,
  useApiWorker,
  buildEndpoint,
  buildQueryUrl,
  isNullEmptyOrUndefined,
} from 'Utilities';
import {
  useOptionalGridState,
  useModalFormState,
  useToasts,
  useModalHelpers,
} from 'Context';
import { GridRefreshableOptionalProps } from 'Models';
import { LinkCellProps } from './LinkCell';

type EditDeleteCellProps = GridCellProps &
  GridRefreshableOptionalProps & {
    editRoute?: string;
    subRoute?: string;
    editPermissions?: number[];
    deleteEndpoint?: string;
    deletePermissions?: number[];
    viewPermissions?: number[]
    isModalEdit?: boolean;
    showViewLink?: boolean;
    entityName?: string;
    onDelete?: (dataItem?: any) => void;
    useDeleteQueryParam?: boolean;
    usePostForDelete?: boolean;
    viewRoute?:string;
  };

type EditDeleteScheduleCellProps = GridCellProps &
  GridRefreshableOptionalProps & {
    editRoute?: string;
    editPermissions?: number[];
    deleteEndpoint?: string;
    deletePermissions?: number[];
    scheduleRoute?: string;
    schedulePermissions?: number[];
    isModalEdit?: boolean;
    entityName?: string;
    onDelete?: (dataItem?: any) => void;
    useDeleteQueryParam?: boolean;
    usePostForDelete?: boolean;
  };

type EditDeleteNotesCellProps = GridCellProps &
  GridRefreshableOptionalProps & {
    editRoute?: string;
    editPermissions?: number[];
    deleteEndpoint?: string;
    deletePermissions?: number[];
    notesRoute?: string;
    notesSubRoute?: string;
    notesPermissions?: number[];
    isModalEdit?: boolean;
    entityName?: string;
    onDelete?: (dataItem?: any) => void;
    useDeleteQueryParam?: boolean;
    usePostForDelete?: boolean;
  };

export const EditDeleteCell: React.FC<EditDeleteCellProps> = ({
  isModalEdit,
  editRoute = '',
  editPermissions,
  deleteEndpoint,
  deletePermissions,
  viewPermissions,
  showViewLink,
  viewRoute,
  ...otherProps
}) => {
  const { deleteItem, value } = useEditDeleteCell({
    deleteEndpoint,
    ...otherProps,
  });

  return (
    <td>
      <div className="flex-vertical-center flex-gap-small flex-end full-height my-auto">
        <Render condition={showViewLink && !!viewRoute && viewRoute?.length>0}>
        <SecureComponent permissions={viewPermissions}>
            <TextViewButton url={buildRoute(viewRoute, value,"View")} />
          </SecureComponent>
        </Render>
        <Render condition={!isModalEdit && editRoute.length > 0}>
          <SecureComponent permissions={editPermissions}>
            <TextEditButton url={buildRoute(editRoute, value)} />
          </SecureComponent>
        </Render>
        {isModalEdit && <ModalEditButton value={value} />}
        <Render
          condition={deleteEndpoint !== undefined && deleteEndpoint.length > 0}
        >
          <SecureComponent permissions={deletePermissions}>
            <TextDeleteButton onClick={deleteItem} />
          </SecureComponent>
        </Render>
      </div>
    </td>
  );
};

export const EditDeleteScheduleCell: React.FC<EditDeleteScheduleCellProps> = ({
  isModalEdit,
  editRoute = '',
  editPermissions,
  deleteEndpoint,
  deletePermissions,
  scheduleRoute = '',
  schedulePermissions,
  ...otherProps
}) => {
  const { deleteItem, value } = useEditDeleteCell({
    deleteEndpoint,
    ...otherProps,
  });

  return (
    <td>
      <div className="flex-vertical-center flex-gap-small flex-end full-height my-auto">
        <Render condition={!isModalEdit && editRoute.length > 0}>
          <SecureComponent permissions={editPermissions}>
            <TextNoteButton url={buildRoute(editRoute, value)} />
          </SecureComponent>
        </Render>
        {isModalEdit && <ModalEditButton value={value} />}
        <Render
          condition={deleteEndpoint !== undefined && deleteEndpoint.length > 0}
        >
          <SecureComponent permissions={deletePermissions}>
            <TextDeleteButton onClick={deleteItem} />
          </SecureComponent>
        </Render>
        <Render condition={!isModalEdit && editRoute.length > 0}>
          <SecureComponent permissions={editPermissions}>
            <TextScheduleButton url={buildRoute(editRoute, value)} />
          </SecureComponent>
        </Render>
        {isModalEdit && <ModalScheduleButton value={value} />}
      </div>
    </td>
  );
};

export const EditDeleteNotesCell: React.FC<
  EditDeleteNotesCellProps & GridCellProps & LinkCellProps
> = ({
  isModalEdit,
  editRoute = '',
  subRoute,
  editPermissions,
  deleteEndpoint,
  deletePermissions,
  notesRoute,
  notesSubRoute,
  notesPermissions,
  ...otherProps
}) => {
  const { deleteItem, value } = useEditDeleteCell({
    deleteEndpoint,
    ...otherProps,
  });
  const linkValue = otherProps.dataItem[otherProps.linkField];

  return (
    <td>
      <div className="flex-vertical-center flex-gap-small flex-end full-height my-auto">
        <Render condition={!isModalEdit && editRoute.length > 0}>
          <SecureComponent permissions={notesPermissions}>
            <TextNoteButton
              url={buildRoute(notesRoute, linkValue, notesSubRoute)}
            />
          </SecureComponent>
        </Render>
        <Render condition={!isModalEdit && editRoute.length > 0}>
          <SecureComponent permissions={editPermissions}>
            <TextEditButton url={buildRoute(editRoute, linkValue, subRoute)} />
          </SecureComponent>
        </Render>
        {isModalEdit && <ModalEditButton value={value} />}
        <Render
          condition={deleteEndpoint !== undefined && deleteEndpoint.length > 0}
        >
          <SecureComponent permissions={deletePermissions}>
            <TextDeleteButton onClick={deleteItem} />
          </SecureComponent>
        </Render>
      </div>
    </td>
  );
};

export const EditViewDeleteCell: React.FC<
  EditDeleteCellProps & GridCellProps & LinkCellProps
> = ({
  isModalEdit,
  editRoute = '',
  subRoute,
  editPermissions,
  deleteEndpoint,
  deletePermissions,
  ...otherProps
}) => {
  const { deleteItem, value } = useEditDeleteCell({
    deleteEndpoint,
    ...otherProps,
  });

  const linkValue = otherProps.dataItem[otherProps.linkField];

  return (
    <td>
      <div className="flex-vertical-center flex-gap-small flex-end full-height my-auto">
        <Render condition={!isModalEdit && editRoute.length > 0}>
          <SecureComponent permissions={editPermissions}>
            <TextEditButton url={buildRoute(editRoute, linkValue, subRoute)} />
          </SecureComponent>
        </Render>
        {isModalEdit && <ModalEditButton value={value} />}
        <Render
          condition={deleteEndpoint !== undefined && deleteEndpoint.length > 0}
        >
          <SecureComponent permissions={deletePermissions}>
            <TextDeleteButton onClick={deleteItem} />
          </SecureComponent>
        </Render>
      </div>
    </td>
  );
};

export type MultiModalEditDeleteCellProps = EditDeleteCellProps & {
  modalId: string;
};

export const MultiModalEditDeleteCell: React.FC<
  MultiModalEditDeleteCellProps
> = ({ modalId, deleteEndpoint, deletePermissions, ...otherProps }) => {
  const { setUpEdit } = useModalHelpers(modalId);
  const { deleteItem, value } = useEditDeleteCell({
    deleteEndpoint,
    ...otherProps,
  });

  return (
    <td>
      <div className="flex-vertical-center flex-gap-small flex-end full-height my-auto">
        <TextEditButton onClick={() => setUpEdit(value)} />
        <Render condition={!isNullEmptyOrUndefined(deleteEndpoint)}>
          <SecureComponent permissions={deletePermissions}>
            <TextDeleteButton onClick={deleteItem} />
          </SecureComponent>
        </Render>
      </div>
    </td>
  );
};

export const useEditDeleteCell = ({
  dataItem,
  field = '',
  refreshGrid,
  deleteEndpoint,
  useDeleteQueryParam,
  entityName,
  usePostForDelete,
  onDelete,
}: EditDeleteCellProps) => {
  const API = useApiWorker();
  const value = dataItem[field];
  const toast = useToasts();
  const gridState = useOptionalGridState();

  if (gridState !== undefined) {
    refreshGrid = gridState.refreshGrid;
  }

  const deleteItem = async () => {
    const errorHandler = ({ response }: any) => {
      if (response?.data?.errorMessage) {
        toast.error(response.data.errorMessage);
      }
    };

    if (
      deleteEndpoint &&
      window.confirm('Are you sure you want to delete this?')
    ) {
      const fullEndpoint = useDeleteQueryParam
        ? buildQueryUrl(deleteEndpoint, { id: value })
        : buildEndpoint(deleteEndpoint, value);

      var entity = gridState?.entityName ? gridState.entityName : entityName;
      if (entity === undefined) {
        entity = 'Item';
      }
      var response;

      if (!usePostForDelete) {
        response = await API.delete(fullEndpoint).catch(errorHandler);
      } else {
        response = await API.post(fullEndpoint).catch(errorHandler);
      }

      if (response && response?.hasOwnProperty('data')) {
        toast.success(`${entity} has been deleted`);
      } else if (response && response?.headers) {
        toast.error(response?.headers['exception']);
      }

      refreshGrid && refreshGrid();
      onDelete && onDelete(dataItem);
    }
  };

  return { deleteItem, value };
};

export const ModalEditButton: React.FC<{ value: any }> = ({ value }) => {
  const { setEntityId, toggle } = useModalFormState();

  const handleClick = () => {
    setEntityId(value);
    toggle();
  };

  return <TextEditButton onClick={handleClick} />;
};

export const ModalScheduleButton: React.FC<{ value: any }> = ({ value }) => {
  const { setEntityId, toggle } = useModalFormState();

  const handleClick = () => {
    setEntityId(value);
    toggle();
  };

  return <TextScheduleButton onClick={handleClick} />;
};
