import {
  AsyncLoadingSection,
  SingleSectionPageCard,
  TaskPageHeaderLayout,
} from 'Components/Display';
import {
  defaultFormatName,
  ListSectionProps,
  SmartMultiSectionList,
} from 'Components/Form';
import { SmartInputBlock } from 'Components/Form';
import { useEnums } from 'Context';
import { Claimant, CoreEnum } from 'Models';
import { InputType } from 'Models/FormModels';
import { ColSize } from 'Models/SharedModels';
import { ENDPOINTS } from 'Models/Templates/Routes/Routes';
import React, { Fragment } from 'react';
import { Row } from 'reactstrap';
import {
  useDataFetcher,
  buildRoute,
  addEditTitle,
  useIdParam,
  useAddEditSuccessHandler,
  defaultDataTransform,
  restrictSsnValue,
} from 'Utilities';

export const AddEditClaimant: React.FC = () => {
  const entityName = 'Claimant';
  const [id, isAdd] = useIdParam();
  const handleSuccess = useAddEditSuccessHandler(entityName, isAdd);
  const [data, loadingStatus] = useDataFetcher<Claimant>(
    buildRoute(ENDPOINTS.claimants.base, id),
    new Claimant(),
    defaultDataTransform,
    Claimant
  );

  return (
    <AsyncLoadingSection loadingStatus={loadingStatus} useMask>
      <TaskPageHeaderLayout
        title={addEditTitle(entityName, isAdd)}
        formProviderProps={{
          formModel: data,
          endpoint: ENDPOINTS.claimants.base,
          handleSuccess: handleSuccess,
        }}
      >
        <ClaimantsAddEditFormContent />
      </TaskPageHeaderLayout>
    </AsyncLoadingSection>
  );
};

const ClaimantsAddEditFormContent: React.FC = () => {
  type ListObject = {
    textValue?: string;
    dateValue?: Date;
  };
  const genderList: CoreEnum[] = [
    {
      displayName: 'Male',
      value: 0,
    },
  ];

  const initialListObjectValue: ListObject = {
    textValue: 'Initial Text Value',
    dateValue: new Date(),
  };

  const { gender, state } = useEnums();
  return (
    <Fragment>
      <SingleSectionPageCard cardTitle="Details" centerContent inputSection>
        <Row>
          <SmartInputBlock
            name="firstName"
            label="First Name"
            type={InputType.Text}
            colProps={{ xs: ColSize.OneThird }}
            required
          />
          <SmartInputBlock
            name="lastName"
            label="Last Name"
            type={InputType.Text}
            required
            colProps={{ xs: ColSize.OneThird }}
          />

          <SmartInputBlock
            name="gender"
            label="gender"
            type={InputType.EnumDropdown}
            data={gender}
            colProps={{ xs: ColSize.OneThird }}
            required
          />
        </Row>

        <Row>
          <SmartInputBlock
            name="ssn"
            label="SSN"
            type={InputType.SocialSecurityNumberCustom}
            restrictValues={(value) => restrictSsnValue(value)}
            colProps={{ xs: ColSize.OneThird }}
          />
          <SmartInputBlock
            name="phone"
            label="Phone"
            type={InputType.PhoneNumber}
            colProps={{ xs: ColSize.OneThird }}
          />
          <SmartInputBlock
            name="mobilePhone"
            label="Mobile Phone"
            type={InputType.PhoneNumber}
            colProps={{ xs: ColSize.OneThird }}
          />
        </Row>
        <Row>
          <SmartInputBlock
            name="phoneOther"
            label="Other Phone"
            type={InputType.PhoneNumber}
            colProps={{ xs: ColSize.OneThird }}
          />
          <SmartInputBlock
            name="dateOfBirth"
            label="Date of Birth"
            type={InputType.DatePicker}
            colProps={{ xs: ColSize.OneThird }}
          />
        </Row>

        <Row>
          <SmartInputBlock
            name="occupation"
            label="Occupation"
            type={InputType.Text}
            colProps={{ xs: ColSize.OneThird }}
          />
          <SmartInputBlock
            name="email"
            label="Email"
            type={InputType.Text}
            colProps={{ xs: ColSize.OneThird }}
          />
          <SmartInputBlock
            name="dwc"
            label="DWC"
            type={InputType.YesNoBooleanRadioGroup}
            colProps={{ xs: ColSize.OneThird }}
          />
        </Row>

        <Row>
          <SmartInputBlock
            name="attorneyName"
            label="Attorney Name"
            type={InputType.Text}
            colProps={{ xs: ColSize.OneThird }}
          />
          <SmartInputBlock
            name="attorneyAddressLine1"
            label="Attorney Address 1"
            type={InputType.Text}
            colProps={{ xs: ColSize.OneThird }}
          />
          <SmartInputBlock
            name="attorneyAddressLine2"
            label="Attorney Address 2"
            type={InputType.Text}
            colProps={{ xs: ColSize.OneThird }}
          />
        </Row>

        <Row>
          <SmartInputBlock
            name="attorneyCity"
            label="Attorney City"
            type={InputType.Text}
            colProps={{ xs: ColSize.OneThird }}
          />
          <SmartInputBlock
            name="attorneyState"
            label="Attorney State"
            type={InputType.EnumDropdown}
            data={state}
            colProps={{ xs: ColSize.OneThird }}
          />
          <SmartInputBlock
            name="attorneyZip"
            label="Attorney Zip"
            type={InputType.Text}
            colProps={{ xs: ColSize.OneThird }}
          />
        </Row>
        <Row>
          <div className="inner-form-header">
            <h4>Address Book</h4>
          </div>
        </Row>
        <Row>
          <SmartMultiSectionList
            listName="addresses"
            listObjectName="Addresses"
            initialValues={initialListObjectValue}
          >
            <ClaimantAddress />
          </SmartMultiSectionList>
        </Row>
        <Row>
          <div className="inner-form-header">
            <h4>Employment History</h4>
          </div>
        </Row>
        <Row>
          <SmartMultiSectionList
            listName="employers"
            listObjectName="Employer"
            initialValues={initialListObjectValue}
          >
            <ClaimantEmployer />
          </SmartMultiSectionList>
        </Row>
      </SingleSectionPageCard>
    </Fragment>
  );
};

export const ClaimantAddress: React.FC<ListSectionProps> = ({
  formatInputName = defaultFormatName,
}) => {
  const { state } = useEnums();

  return (
    <Fragment>
      <Row>
        <SmartInputBlock
          type={InputType.Text}
          name={formatInputName('address.line1')}
          label="Address Line 1"
          required
          width={200}
        />
        <SmartInputBlock
          type={InputType.Text}
          name={formatInputName('address.line2')}
          label="Address Line 2"
          required
          width={200}
        />
      </Row>
      <Row>
        <SmartInputBlock
          type={InputType.Text}
          name={formatInputName('address.city')}
          label="City"
          required
          width={200}
        />
        <SmartInputBlock
          name={formatInputName('address.state')}
          label="State"
          type={InputType.EnumDropdown}
          data={state}
          colProps={{ xs: ColSize.OneThird }}
        />
        <SmartInputBlock
          type={InputType.Text}
          name={formatInputName('address.zip')}
          label="Zip Code"
          required
          width={200}
        />
      </Row>
    </Fragment>
  );
};

export const ClaimantEmployer: React.FC<ListSectionProps> = ({
  formatInputName = defaultFormatName,
}) => {
  const { state } = useEnums();

  return (
    <Fragment>
      <Row>
        <SmartInputBlock
          type={InputType.Text}
          name={formatInputName('employer.name')}
          label="Employer Name"
          required
          width={200}
        />
        <SmartInputBlock
          type={InputType.Text}
          name={formatInputName('employer.jobDescription')}
          label="Job Description"
          width={200}
        />
        <SmartInputBlock
          type={InputType.PhoneNumber}
          name={formatInputName('employer.phone')}
          label="Phone"
          width={200}
        />
      </Row>
      <Row>
        <SmartInputBlock
          type={InputType.Text}
          name={formatInputName('employer.addressLine1')}
          label="Address Line 1"
          width={200}
        />
        <SmartInputBlock
          type={InputType.Text}
          name={formatInputName('employer.addressLine2')}
          label="Address Line 2"
          width={200}
        />
        <SmartInputBlock
          type={InputType.Text}
          name={formatInputName('employer.city')}
          label="City"
          width={200}
        />
      </Row>
      <Row>
        <SmartInputBlock
          name={formatInputName('employer.state')}
          label="State"
          type={InputType.EnumDropdown}
          data={state}
          colProps={{ xs: ColSize.OneThird }}
        />
        <SmartInputBlock
          type={InputType.Text}
          name={formatInputName('employer.zip')}
          label="Zip Code"
          width={200}
        />
      </Row>
    </Fragment>
  );
};
