import './SidebarSubLayout.scss';
import React from 'react';
import { toggledClass, HOTKEYS, fullClassName } from 'Utilities';
import { NavigationBar } from 'Components/Navigation';
import { useGlobalState, useGlobalDispatch, GlobalActionTypes } from 'Context';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { isMobile } from 'react-device-detect';
import { useHotkeys } from 'react-hotkeys-hook';
import { NavConfigItem, ROUTES, useSubNavigation } from 'Navigation';

export type SubLayoutProps = {
  parentRoute: ROUTES;
  hasHeader: boolean;
  hasTallHeader: boolean;
  hasSubHeader: boolean;
  hasShortcutNumbers: boolean;
  hasCaseTypes?: boolean;
};

export const SidebarSubLayout: React.FC<SubLayoutProps> = ({
  hasCaseTypes,
  ...layoutProps
}) => {
  return <NormalSidebarSubLayout {...layoutProps} />;
};

export const NormalSidebarSubLayout: React.FC<SubLayoutProps> = ({
  parentRoute,
  ...layoutProps
}) => {
  const navConfig = useSubNavigation(parentRoute);

  return <SidebarSubLayoutContent navConfig={navConfig} {...layoutProps} />;
};

type SidebarSubLayoutContentProps = Partial<SubLayoutProps> & {
  navConfig: NavConfigItem[];
};

export const SidebarSubLayoutContent: React.FC<SidebarSubLayoutContentProps> =
  ({
    navConfig,
    hasHeader,
    hasTallHeader,
    hasSubHeader,
    hasShortcutNumbers,
    children,
  }) => {
    const { isSidebarOpen } = useGlobalState();

    return (
      <div
        className={fullClassName(
          'sidebar-layout full-size',
          toggledClass('with-header', hasHeader && !hasTallHeader),
          toggledClass('with-tall-header', hasTallHeader),
          toggledClass('with-sub-header', hasSubHeader)
        )}
      >
        <aside
          className={fullClassName(
            'main__left flex-column',
            toggledClass('showing', isSidebarOpen)
          )}
        >
          <NavigationBar withNumbers={hasShortcutNumbers} config={navConfig} />
          <SidebarToggleButton {...{ isSidebarOpen }} />
        </aside>
        <div className="main-card">{children}</div>
      </div>
    );
  };

type SidebarToggleButtonProps = {
  isSidebarOpen: boolean;
};

const SidebarToggleButton: React.FC<SidebarToggleButtonProps> = ({
  isSidebarOpen,
}) => {
  const dispatch = useGlobalDispatch();

  const handleClick = () => {
    dispatch({ type: GlobalActionTypes.ToggleSidebar });
  };

  useHotkeys(HOTKEYS.TOGGLE_SIDEBAR, handleClick);

  return (
    <div
      className={fullClassName(
        'sidebar-toggler',
        toggledClass('close-icon', isSidebarOpen),
        toggledClass('mobile-toggler', isMobile)
      )}
      onClick={handleClick}
    >
      <NavigateNextIcon />
    </div>
  );
};
