import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { makeStyles } from '@mui/styles';
import {
  AsyncLoadingSection,
  DisplayBlock,
  MultiModalFormDrawer,
  Render,
} from 'Components/Display';
import {
  ButtonStyle,
  defaultFormatName,
  IconButton,
  ListSectionProps,
  SmartFileUploader,
  SmartInputBlock,
  SmartMultiSectionList,
} from 'Components/Form';
import { SecureComponent } from 'Components/Security';
import {
  FormActionTypes,
  useEntity,
  useEnums,
  useFormState,
  useFormStateValue,
  useFormUpdater,
  useGlobalContext,
  useGridState,
  useModalHelpers,
} from 'Context';
import {
  removeAllTabIndicators,
  toggleTabsRequiredIndicator,
} from 'FeatureUtilities/TabUtilities';
import { ColSize, InputType } from 'Models';
import {
  AppointmentReportModal,
  AppointmentReportModel,
} from 'Models/AppointmentReportModel';
import { Permission } from 'Models/Templates/Permission/Permission';
import { ENDPOINTS } from 'Models/Templates/Routes/Routes';
import moment from 'moment';
import React, { Fragment, useEffect, useState } from 'react';
import { Fade, Tab, Tabs } from 'react-bootstrap';
import { Col, Row } from 'reactstrap';
import {
  buildEndpoint,
  EMPTY_GUID,
  FORM_DATE_TIME_FORMAT,
  useDataFetcher,
  useHasPermissions,
} from 'Utilities';

export type AppointmentReportModalFormProps = {
  endpoint?: string;
  formModel?: AppointmentReportModel;
  modal?: string;
  attachmentType?: string;
};

export const AppointmentReportDrawerForm: React.FC<
  AppointmentReportModalFormProps
> = ({ formModel, ...props }) => {
  const entity = useEntity();
  formModel = new AppointmentReportModel(
    entity.appointment?.id,
    entity.referralDate
  );
  const { simpleSuccessHandler } = useModalHelpers(
    AppointmentReportModal.AttachmentsReport
  );
  const { refreshGrid } = useGridState();

  const handleSuccess = (cosa: any) => {
    simpleSuccessHandler('Appointment Report has been updated.');
    refreshGrid();
  };

  return (
    <MultiModalFormDrawer
      modalId={AppointmentReportModal.AttachmentsReport}
      submitButtonText="Save"
      formModel={formModel}
      endpoint={props.endpoint}
      handleSuccess={handleSuccess}
      width={900}
    >
      <AppointmentReportDrawerFormContent {...props} />
    </MultiModalFormDrawer>
  );
};

export const AppointmentReportDrawerFormContent: React.FC<
  AppointmentReportModalFormProps
> = ({ ...props }) => {
  const entity = useEntity();

  const { isOpen, entityId } = useModalHelpers(
    AppointmentReportModal.AttachmentsReport
  );

  const sendUpdate = useFormUpdater();
  const { formValues } = useFormState();
  const [templateFileAdd, setTemplateIsAdd] = useState(true);
  const [reportFileAdd, setReportIsAdd] = useState(true);
  const isAdd = entityId === undefined;
  const removeTemplateFile = () => {
    setTemplateIsAdd(true);
    sendUpdate(FormActionTypes.UpdateValue, {
      field: 'templateFile',
      value: null,
    });
    sendUpdate(FormActionTypes.UpdateValue, {
      field: 'templateFileId',
      value: null,
    });
  };

  const removeReportFile = () => {
    setReportIsAdd(true);
    sendUpdate(FormActionTypes.UpdateValue, {
      field: 'reportFile',
      value: null,
    });
    sendUpdate(FormActionTypes.UpdateValue, {
      field: 'reportFileId',
      value: null,
    });
  };

  let [appointmentReportModel, fetchStatus] =
    useDataFetcher<AppointmentReportModel>(
      buildEndpoint(
        ENDPOINTS.appointmentsreportsbundles.base,
        entityId ?? EMPTY_GUID
      ),
      new AppointmentReportModel(entity.appointment?.id),
      ({ data }) => data,
      undefined,
      isOpen
    );

  useEffect(() => {
    if (!isAdd) {
      sendUpdate(FormActionTypes.SetValues, appointmentReportModel);
    }
    setTemplateIsAdd(appointmentReportModel?.templateFile?.fileId == null);
    setReportIsAdd(appointmentReportModel?.reportFile?.fileId == null);
  }, [appointmentReportModel, sendUpdate]);

  type ListObject = {
    textValue?: string;
    dateValue?: Date;
  };

  const initialListObjectValue: ListObject = {
    textValue: 'Initial Text Value',
    dateValue: new Date(),
  };

  const [key, setKey] = useState(GetDefaultTabKey());

  const {
    firstLevelReviewStatus,
    appointmentReportContact,
    appointmentReportStatus,
    appointmentReportSendTo,
    appointmentReportSendToSecond,
    appointmentReportQaStatus,
    appointmentReportDetermination,
    appointmentReportSourceCriteria,
    irRatingUnit,
  } = useEnums();

  const context = useGlobalContext();
  const hasPermission = useHasPermissions();

  const isDoctorOrReviewer =
    context[0].currentUser?.isDoctor || context[0].currentUser?.isReviewer;

  const formState = useFormState();

  const [rejection, setRejection] = useFormStateValue('rejection');

  useEffect(() => {
    if (formState.formStatus == 'validationError') {
      removeAllTabIndicators();
      toggleTabsRequiredIndicator();
    }
  }, [formState, removeAllTabIndicators, toggleTabsRequiredIndicator]);

  let userName =
    context[0].currentUser?.displayName != null
      ? context[0].currentUser?.displayName
      : '';
  const shouldHideMultipleReviewOperations = () => {
    if (
      !hasPermission([Permission.AllowMultipleReviewOperations]) &&
      formState.formValues.reviewOperations?.length > 0
    ) {
      return true;
    }
    return false;
  };

  const useStyles = makeStyles({
    infoLabel: {
      display: 'block',
    },
  });
  const classes = useStyles();

  //Returned fields(hidden, disabled) depends on user permissions
  return (
    <AsyncLoadingSection loadingStatus={fetchStatus} noIdleLoading>
      <Render
        condition={
          !templateFileAdd && (formValues?.templateFile?.fileId || false)
        }
      >
        <Row>
          <IconButton
            iconElement={HighlightOffIcon}
            primary
            buttonStyle={ButtonStyle.Bare}
            onClick={removeTemplateFile}
          />
          <DisplayBlock
            value={formValues?.templateFile?.fileName}
            title="Template"
          />
        </Row>
      </Render>
      <Render
        condition={!reportFileAdd && (formValues?.reportFile?.fileId || false)}
      >
        <Row>
          <IconButton
            iconElement={HighlightOffIcon}
            primary
            buttonStyle={ButtonStyle.Bare}
            onClick={removeReportFile}
          />
          <DisplayBlock
            value={formValues?.reportFile?.fileName}
            title="Report"
          />
        </Row>
      </Render>
      <SecureComponent
        permissions={[Permission.UploadAppointmentReportTemplate]}
      >
        <SmartFileUploader
          name="templateFile"
          label="Template"
          fileEndpoint={ENDPOINTS.appointmentreportstemplates.base}
          isEdit={!templateFileAdd}
          vertical
          withComments={false}
          allowMultiple={false}
          multipleUploaders
        />
      </SecureComponent>
      <SmartFileUploader
        name="reportFile"
        label="Report"
        fileEndpoint={ENDPOINTS.appointmentreports.base}
        isEdit={!reportFileAdd}
        vertical
        withComments={false}
        allowMultiple={false}
        multipleUploaders
        required={isDoctorOrReviewer}
      />
      <Fragment>
        <Tabs
          transition={Fade}
          id="controlled-tab-example"
          activeKey={key}
          onSelect={(k) => setKey(k as string)}
          className="mb-3"
        >
          <Tab
            eventKey="primary"
            title="Primary Operations"
            tabClassName={SecureTab(Permission.ViewPrimaryOperations)}
          >
            <Col>
              <Row>
                <SmartInputBlock
                  name="title"
                  label="Title"
                  type={InputType.Text}
                  colProps={{ xs: ColSize.Full }}
                  required
                />
                <SmartInputBlock
                  name="rush"
                  label="Rush"
                  type={InputType.YesNoBooleanRadioGroup}
                  colProps={{ xs: ColSize.Half }}
                />
                <Render condition={!isDoctorOrReviewer}>
                  <SmartInputBlock
                    name="pageCount"
                    label="Page Count"
                    type={InputType.Number}
                    colProps={{ xs: ColSize.Half }}
                    required
                  />
                </Render>
                <SecureComponent
                  permissions={[Permission.UploadAppointmentReportTemplate]}
                >
                  <SmartInputBlock
                    name="escalation"
                    label="Escalation"
                    type={InputType.AsyncDropDown}
                    readEndpoint={buildEndpoint(
                      ENDPOINTS.claimescalations.dropdownItems,
                      entity.appointment?.id
                    )}
                    colProps={{ xs: ColSize.Half }}
                  />
                </SecureComponent>
                <Render condition={!isDoctorOrReviewer}>
                  <SmartInputBlock
                    name="firstLevelReviewStatus"
                    label="First Level Review Status"
                    type={InputType.EnumDropdown}
                    data={firstLevelReviewStatus}
                    colProps={{ xs: ColSize.Half }}
                  />
                </Render>
                <Col xs="12" lg="6">
                  <span className={`${classes.infoLabel} field-label`}>
                    Type Of Evaluation
                  </span>
                  <span className="field-value">{entity.typeOfEval}</span>
                </Col>
                <SecureComponent
                  permissions={[Permission.UploadAppointmentReportTemplate]}
                >
                  <SmartInputBlock
                    name="numberOfPrescribers"
                    label="Number Of Prescribers"
                    type={InputType.Number}
                    placeholder="0-10"
                    colProps={{ xs: ColSize.Half }}
                  />
                </SecureComponent>
                <SmartInputBlock
                  name="callAttempts"
                  label="Call Attempts"
                  type={InputType.Number}
                  placeholder="0-50"
                  colProps={{ xs: ColSize.Half }}
                />
                <SecureComponent
                  permissions={[Permission.UploadAppointmentReportTemplate]}
                >
                  <SmartInputBlock
                    name="numberOfDrugsForReview"
                    label="Number Of Drugs For Review"
                    type={InputType.Number}
                    placeholder="0-30"
                    colProps={{ xs: ColSize.Half }}
                  />
                </SecureComponent>
                <Render condition={!isDoctorOrReviewer}>
                  <SmartInputBlock
                    name="reviewerIrRatingUnit"
                    label="Reviewer Ir Rating Unit"
                    type={InputType.EnumDropdown}
                    data={irRatingUnit}
                    colProps={{ xs: ColSize.Half }}
                  />
                </Render>
                <SecureComponent
                  permissions={[Permission.UploadAppointmentReportTemplate]}
                >
                  <SmartInputBlock
                    name="contact"
                    label="Contact"
                    type={InputType.EnumDropdown}
                    data={appointmentReportContact}
                    colProps={{ xs: ColSize.Half }}
                  />
                </SecureComponent>
                <SmartInputBlock
                  name="guideline"
                  label="Guidelines To Use"
                  type={InputType.AsyncDropDown}
                  readEndpoint={ENDPOINTS.guidelines.endpointString(
                    'dropdownItems'
                  )}
                  colProps={{ xs: ColSize.Half }}
                />
                <Render condition={!isDoctorOrReviewer}>
                  <SmartInputBlock
                    name="status"
                    label="Status"
                    type={InputType.EnumDropdown}
                    data={appointmentReportStatus}
                    colProps={{ xs: ColSize.Half }}
                    required
                  />
                </Render>
                <Render condition={!isDoctorOrReviewer}>
                  <SmartInputBlock
                    name="sendTo"
                    label="Send To"
                    type={InputType.EnumDropdown}
                    data={appointmentReportSendTo}
                    colProps={{ xs: ColSize.Half }}
                  />
                </Render>
                <Render condition={!isDoctorOrReviewer}>
                  <SmartInputBlock
                    name="sendToSecond"
                    label="Send To Second Level"
                    type={InputType.EnumDropdown}
                    data={appointmentReportSendToSecond}
                    colProps={{ xs: ColSize.Half }}
                  />
                </Render>
                <Render condition={!isDoctorOrReviewer}>
                  <div className="col-6">
                    <div className="field-label"> Referral Date </div>
                    <div>
                      {entity?.referralDate
                        ? moment(entity?.referralDate).format(
                            FORM_DATE_TIME_FORMAT
                          )
                        : ''}
                    </div>
                  </div>
                </Render>
              </Row>
            </Col>
          </Tab>

          <Tab
            eventKey="reviewer"
            title="Doctor/Reviewer Operations"
            tabClassName={SecureTab(Permission.ViewDoctorReviewerOperations)}
          >
            <Col>
              <Row>
                <SmartInputBlock
                  name="reviewed"
                  label={` I ${entity?.appointment?.professionalName} am marking this report as reviewed`}
                  type={InputType.Checkbox}
                  colProps={{ xs: ColSize.Half }}
                />
                <SmartInputBlock
                  name="spentTime"
                  label="Time spent on this report"
                  type={InputType.Number}
                  colProps={{ xs: ColSize.Half }}
                />
                <SmartInputBlock
                  name="completedDate"
                  label="Date Completed"
                  type={InputType.DatePicker}
                  colProps={{ xs: ColSize.Half }}
                />
                <SmartInputBlock
                  name="completedDate"
                  label="Time Completed"
                  type={InputType.TimePicker}
                  colProps={{ xs: ColSize.Half }}
                />
                <SmartInputBlock
                  name="determinationDate"
                  label="Determination Date"
                  type={InputType.DatePicker}
                  colProps={{ xs: ColSize.Half }}
                />
                <SmartInputBlock
                  name="determinationDate"
                  label="Determination Time"
                  type={InputType.TimePicker}
                  colProps={{ xs: ColSize.Half }}
                />
                <SmartInputBlock
                  name="reviewedBy"
                  label="Reviewed By"
                  type={InputType.AsyncDropDown}
                  useQuery
                  otherparams={{ isReviewer: true }}
                  readEndpoint={ENDPOINTS.users.dropdownItems}
                  colProps={{ xs: ColSize.Half }}
                />
              </Row>
              <Row>
                <h5>I attest that</h5>
                <SmartInputBlock
                  name="correctCredentials"
                  label="I have the correct credentials to complete this review"
                  type={InputType.Checkbox}
                  colProps={{ xs: ColSize.Full }}
                />
                <SmartInputBlock
                  name="noConflicts"
                  label="I do not have a conflict of interest on this review"
                  type={InputType.Checkbox}
                  colProps={{ xs: ColSize.Full }}
                />
                <SmartInputBlock
                  name="rejection"
                  label="I am rejecting this review"
                  type={InputType.Checkbox}
                  colProps={{ xs: ColSize.Full }}
                />
                <SmartInputBlock
                  name="rejectionReason"
                  label="Reason for Rejection"
                  className="text-area-normal-height"
                  hidden={!rejection}
                  type={InputType.TextArea}
                  colProps={{ xs: ColSize.Full }}
                />
                <SmartInputBlock
                  name="attestingBy"
                  label="Doctor attesting"
                  type={InputType.AsyncDropDown}
                  useQuery
                  otherparams={{ isReviewer: true }}
                  readEndpoint={ENDPOINTS.users.dropdownItems}
                  colProps={{ xs: ColSize.Half }}
                />
                <SecureComponent
                  permissions={[Permission.ShowNumberOfRequests]}
                >
                  <SmartInputBlock
                    name="numberOfRequests"
                    label="Number of requests"
                    type={InputType.Number}
                    placeholder="1-45"
                    restrictValues={(value: number) => numberRange(value, 45)}
                    colProps={{ xs: ColSize.Half }}
                  />
                </SecureComponent>
              </Row>
              <Row>
                <div className="inner-form-header">
                  <h4>Review Operations</h4>
                </div>
              </Row>
              <Row>
                <SmartMultiSectionList
                  listName="reviewOperations"
                  listObjectName="Review Operations"
                  initialValues={initialListObjectValue}
                  deletePermissions={Permission.DeleteDoctorReviewerOperations}
                  hideButtons={shouldHideMultipleReviewOperations()}
                >
                  <ReviewOperations />
                </SmartMultiSectionList>
              </Row>
            </Col>
          </Tab>

          <Tab
            eventKey="writer"
            title="Writer Operations"
            tabClassName={SecureTab(Permission.ViewWriterOperations)}
          >
            <Col>
              <Row>
                <SmartInputBlock
                  name="writersReportTimeBilled"
                  label="Writers Report Time Billed"
                  type={InputType.Number}
                  colProps={{ xs: ColSize.Half }}
                />
                <SmartInputBlock
                  name="writerCompletedDate"
                  label="Writer Completed Date"
                  type={InputType.DatePicker}
                  colProps={{ xs: ColSize.Half }}
                />
                <SmartInputBlock
                  name="writerCompletedDate"
                  label="Writer Completed Time"
                  type={InputType.TimePicker}
                  colProps={{ xs: ColSize.Half }}
                />
                <SmartInputBlock
                  name="completedByWriter"
                  label="Completed By Writer"
                  type={InputType.Checkbox}
                  colProps={{ xs: ColSize.Full }}
                />
              </Row>
            </Col>
          </Tab>

          <Tab
            eventKey="qa"
            title="QA Operations"
            tabClassName={SecureTab(Permission.ViewQaOperations)}
          >
            <Col>
              <Row>
                <SmartInputBlock
                  name="determination"
                  label="Determination"
                  type={InputType.EnumDropdown}
                  data={appointmentReportDetermination}
                  colProps={{ xs: ColSize.Full }}
                />
                <SmartInputBlock
                  name="historyOfCondition"
                  label="History Of Condition"
                  type={InputType.TextArea}
                  colProps={{ xs: ColSize.Full }}
                />
                <SmartInputBlock
                  name="conclusion"
                  label="Conclusion"
                  type={InputType.TextArea}
                  colProps={{ xs: ColSize.Full }}
                />
                <SmartInputBlock
                  name="sourceCriteria"
                  label="Source/Criteria"
                  type={InputType.EnumDropdown}
                  data={appointmentReportSourceCriteria}
                  colProps={{ xs: ColSize.Full }}
                />
                <SmartInputBlock
                  name="citation"
                  label="Citation"
                  type={InputType.TextArea}
                  colProps={{ xs: ColSize.Full }}
                />
                <SmartInputBlock
                  name="citationGuidelines"
                  label="Citation Guidelines"
                  type={InputType.TextArea}
                  colProps={{ xs: ColSize.Full }}
                />
              </Row>
            </Col>
          </Tab>

          <Tab
            eventKey="medical"
            title="Medical Director QA"
            tabClassName={SecureTab(Permission.ViewMedicalDirectorQa)}
          >
            <Col>
              <Row>
                <SmartInputBlock
                  name="lastQaDateDirector"
                  label="Last Qa Director Date"
                  type={InputType.DatePicker}
                  colProps={{ xs: ColSize.Half }}
                />
                <SmartInputBlock
                  name="lastQaDateDirector"
                  label="Last Qa Director Time"
                  type={InputType.TimePicker}
                  colProps={{ xs: ColSize.Half }}
                />
                <SmartInputBlock
                  name="qaStatus"
                  label="QA Status"
                  type={InputType.EnumDropdown}
                  colProps={{ xs: ColSize.Half }}
                  data={appointmentReportQaStatus}
                />
                <SmartInputBlock
                  name="qaStatusDirector"
                  label="QA Status Director"
                  type={InputType.EnumDropdown}
                  colProps={{ xs: ColSize.Half }}
                  data={appointmentReportQaStatus}
                />
                <SmartInputBlock
                  name="reportAssignedToDirector"
                  label="Report Assigned To Director"
                  type={InputType.AsyncDropDown}
                  useQuery
                  otherparams={{ isMedicalDirector: true }}
                  readEndpoint={ENDPOINTS.users.dropdownItems}
                  colProps={{ xs: ColSize.Half }}
                />
              </Row>
            </Col>
          </Tab>
        </Tabs>
      </Fragment>
    </AsyncLoadingSection>
  );
};

export const ReportDurData: React.FC<ListSectionProps> = ({
  formatInputName = defaultFormatName,
}) => {
  const { drugStatus, followUpTimeFrame } = useEnums();

  return (
    <Fragment>
      <Row>
        <SmartInputBlock
          type={InputType.Text}
          name={formatInputName('drugListed')}
          label="Drug Listed"
          colProps={{ xs: ColSize.Half }}
        />
        <SmartInputBlock
          type={InputType.EnumDropdown}
          data={drugStatus}
          name={formatInputName('drugStatus')}
          label="Drug Status"
          colProps={{ xs: ColSize.Half }}
        />
        <SmartInputBlock
          type={InputType.EnumDropdown}
          data={followUpTimeFrame}
          name={formatInputName('followUpTimeFrame')}
          label="FollowUp Time Frame"
          colProps={{ xs: ColSize.Half }}
        />
      </Row>
    </Fragment>
  );
};

const numberRange = (value: number | null, upperLimit: number) => {
  if (typeof value == 'number') {
    if (value > upperLimit) {
      return upperLimit;
    }
    if (value < 1) {
      return 1;
    }
    return value;
  }
  return 1;
};

export const ReviewOperations: React.FC<ListSectionProps> = ({
  formatInputName = defaultFormatName,
}) => {
  const { urType, outcomeOfReview } = useEnums();
  return (
    <Fragment>
      <Row>
        <SmartInputBlock
          name={formatInputName('outcomeOfReview')}
          label="Outcome Of Review"
          type={InputType.EnumDropdown}
          data={outcomeOfReview}
          colProps={{ xs: ColSize.Full }}
        />
        <SmartInputBlock
          type={InputType.TextArea}
          name={formatInputName('reasonsForRequest')}
          label="Reasons For Request"
          required
          colProps={{ xs: ColSize.Full }}
        />
        <SmartInputBlock
          name={formatInputName('urType')}
          label="UR Type"
          type={InputType.EnumDropdown}
          data={urType}
          colProps={{ xs: ColSize.Full }}
        />
      </Row>
    </Fragment>
  );
};

export const AppointmentReportNotesForm: React.FC<
  AppointmentReportModalFormProps
> = ({ formModel, ...props }) => {
  const { simpleSuccessHandler } = useModalHelpers(
    AppointmentReportModal.Notes
  );
  const { refreshGrid } = useGridState();

  const handleSuccess = () => {
    simpleSuccessHandler(
      'Appointment Report Notes have been successfully updated.'
    );
    refreshGrid();
  };

  return (
    <MultiModalFormDrawer
      title={`${formModel?.title ?? "Report's"} Note`}
      modalId={AppointmentReportModal.Notes}
      submitPermissions={[Permission.AddEditAppointmentReportNote]}
      formModel={formModel}
      endpoint={props.endpoint}
      handleSuccess={handleSuccess}
    >
      <AppointmentReportNotesFormContent {...props} />
    </MultiModalFormDrawer>
  );
};

export const AppointmentReportNotesFormContent: React.FC = () => {
  const entity = useEntity();
  const { isOpen, entityId } = useModalHelpers(AppointmentReportModal.Notes);
  const sendUpdate = useFormUpdater();
  const isAdd = entityId === undefined;
  const hasPermission = useHasPermissions();

  let [appointmentReportModel, fetchStatus] =
    useDataFetcher<AppointmentReportModel>(
      buildEndpoint(
        ENDPOINTS.appointmentsreportsbundles.base,
        entityId ?? EMPTY_GUID
      ),
      new AppointmentReportModel(entity.appointment?.id),
      ({ data }) => data,
      undefined,
      isOpen
    );

  useEffect(() => {
    if (!isAdd) {
      sendUpdate(FormActionTypes.SetValues, appointmentReportModel);
    }
  }, [appointmentReportModel, sendUpdate]);

  return (
    <Row>
      <Col>
        <SecureComponent
          permissions={[Permission.AddEditAppointmentReportNote]}
        >
          <SmartInputBlock
            name="comments"
            label="Notes"
            type={InputType.TextArea}
            colProps={{ xs: ColSize.Full }}
          />
        </SecureComponent>
        <Render
          condition={
            hasPermission([Permission.ViewAppointmentReportNote]) &&
            !hasPermission([Permission.AddEditAppointmentReportNote])
          }
        >
          <DisplayBlock
            value={appointmentReportModel?.comments}
            title="Notes"
          />
        </Render>
      </Col>
    </Row>
  );
};

const SecureTab = (permission: number) => {
  const hasPermission = useHasPermissions();

  return hasPermission([permission]) ? '' : 'd-none';
};

const GetDefaultTabKey = () => {
  const hasPermission = useHasPermissions();

  if (hasPermission([Permission.ViewPrimaryOperations])) {
    return 'primary';
  }
  if (hasPermission([Permission.ViewDoctorReviewerOperations])) {
    return 'reviewer';
  }
  if (hasPermission([Permission.ViewWriterOperations])) {
    return 'writer';
  }
  if (hasPermission([Permission.ViewQaOperations])) {
    return 'qa';
  }
  if (hasPermission([Permission.ViewMedicalDirectorQa])) {
    return 'medical';
  }

  return '';
};

export const AppointmentReportGuidelinesForm: React.FC<
  AppointmentReportModalFormProps
> = ({ formModel, ...props }) => {
  return (
    <MultiModalFormDrawer
      title={'Guideline'}
      modalId={AppointmentReportModal.Guidelines}
      formModel={formModel}
      endpoint={props.endpoint}
    >
      <AppointmentReportGuidelinesFormContent {...props} />
    </MultiModalFormDrawer>
  );
};

export const AppointmentReportGuidelinesFormContent: React.FC = () => {
  const entity = useEntity();
  const { isOpen, entityId } = useModalHelpers(
    AppointmentReportModal.Guidelines
  );

  const useStyles = makeStyles({
    infoLabel: {
      display: 'block',
    },
  });
  const classes = useStyles();

  let [appointmentReportModel, fetchStatus] =
    useDataFetcher<AppointmentReportModel>(
      buildEndpoint(
        ENDPOINTS.appointmentsreportsbundles.base,
        entityId ?? EMPTY_GUID
      ),
      new AppointmentReportModel(entity.appointment?.id),
      ({ data }) => data,
      undefined,
      isOpen
    );

  let benefitState =
    appointmentReportModel?.guidelineModel?.benefitState?.displayName ?? '';

  let description = appointmentReportModel?.guidelineModel?.description ?? '';

  let guidelineLinks =
    appointmentReportModel?.guidelineModel?.guidelineLink ?? [];

  return (
    <Row>
      <Col>
        <h5>General</h5>
        <br />
        <DisplayBlock title="BENEFIT STATE" value={benefitState} />
        <DisplayBlock title="DESCRIPTION" value={description} />
        <br />
        <h5>Link to Guideline</h5>
        <br />
        {guidelineLinks.length > 0 &&
          guidelineLinks.map((link) => (
            <Col xs="12" key={link.id}>
              <span className={`${classes.infoLabel} field-label`}>
                {link.link?.title}
              </span>
              <a
                href={link.link?.url != undefined ? link.link?.url : ''}
                target="_blank"
              >
                {link.link?.url}
              </a>
              <hr />
            </Col>
          ))}
      </Col>
    </Row>
  );
};
