import {
  GridCellProps,
  GridColumn as Column,
} from "@progress/kendo-react-grid";
import { PageSectionHeading } from "Components/Display/Headings";
import {
  AsyncGrid,
  ShowAuditCommentsCell,
  TimeStampCell,
} from "Components/Grid";
import { defaultGridProps } from "Models/GridModels";
import React, { Fragment } from "react";
import { ENDPOINTS } from "Models/Templates/Routes/Routes";
import { buildEndpoint } from "Utilities";
import { Permission } from "Models/Templates/Permission/Permission";

export type AuditLogProps = {
  title?: string;
  admin?: boolean;
  financials?: boolean;
  eventDateMin?: Date;
  eventDateMax?: Date;
  comment?: string;
  parentIds?: string[];
  ids?: string[];
  entityEvents?: number[];
  entityEventType?: string | number;
  baseEntityEventType?: string | number;
  permissions?: Permission[];
  refreshToggle?: boolean;
  caseId?: string;
};

export const AuditLog: React.FC<AuditLogProps> = ({
  title,
  admin,
  financials,
  eventDateMax,
  eventDateMin,
  entityEventType,
  baseEntityEventType,
  entityEvents,
  parentIds,
  ids,
  comment,
  refreshToggle,
  caseId,
}) => {
  const search = {
    eventDateMin: eventDateMin,
    eventDateMax: eventDateMax,
    entityEventType: entityEventType,
    baseEntityEventType: baseEntityEventType,
    entityEvents: entityEvents,
    parentIds: parentIds,
    ids: ids,
    comment: comment,
    caseId: caseId,
  };

  return (
    <Fragment>
      {title && <PageSectionHeading name={title} />}
      <AsyncGrid
        readEndpoint={buildEndpoint(
          ENDPOINTS.audits.base,
          admin ? "admin" : financials ? "financials" : ""
        )}
        search={search}
        withCardWrapper
        refreshToggle={refreshToggle}
        {...defaultGridProps}
      >
        <Column
          field="timestamp"
          title="Timestamp"
          cell={TimeStampCell}
          width="auto"
        />
        <Column field="entityEventType" title="Entity" />
        <Column
          field="eventDescription"
          title="Event Description"
          width="auto"
        />
        {!admin && (
          <Column
            field="parentEntityEventType"
            title="Parent Entity"
            width="auto"
          />
        )}
        <Column field="userName" title="Name" width="auto" />
        <Column field="comments" title="Comments" cell={CommentsCell} />
      </AsyncGrid>
    </Fragment>
  );
};

export const CommentsCell: React.FC<GridCellProps> = (props) => (
  <ShowAuditCommentsCell {...props} />
);
