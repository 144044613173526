import React from 'react';
import './Footer.scss';
import { useGlobalState } from 'Context';

export const Footer: React.FC = () => {
  const { versionNumber, environmentName } = useGlobalState();

  return (
    <div className="footer__wrapper flex-center full-width">
      Stradix 2.0
      <span className="ml-tiny color-primary-light">v{versionNumber}</span>
      <span className="ml-tiny color-success font-secondary"></span>
    </div>
  );
};
