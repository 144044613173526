import {
  GridCellProps,
  GridColumn as Column,
} from '@progress/kendo-react-grid';
import { AddButton } from 'Components/Form';
import { SecureComponent } from 'Components/Security';
import { Permission } from 'Models/Templates/Permission/Permission';
import {
  addEditRoute,
  AddEditSubRoute,
  ClaimantsSubRoute,
  ROUTES,
} from 'Navigation';
import React from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { useGridSearch } from 'Utilities';
import { LightPageHeader } from '../../Components/Display';
import {
  DateCell,
  EditDeleteCell,
  FilterBarMainGrid,
  GridButtonSection,
  LinkCell,
} from '../../Components/Grid';
import { FormProvider, GridProvider } from '../../Context';
import {
  defaultGridProps,
  FilterBarFilterType,
  FilterConfig,
  IFilterConfig,
} from '../../Models';
import { ENDPOINTS } from '../../Models/Templates/Routes/Routes';

export enum ClaimantGridIds {
  DetailedSearch = 'detailedSearch',
}

export const Claimants: React.FC = () => {
  return (
    <GridProvider entityName="Claimant">
      <Breadcrumb tag="nav" listTag="div">
        <BreadcrumbItem>
          <Link to={ROUTES.DASHBOARD}>Home</Link>
        </BreadcrumbItem>
        <BreadcrumbItem>Claimants</BreadcrumbItem>
      </Breadcrumb>
      <LightPageHeader tall noBorder className="page-header-shadow">
        <h1>Claimants</h1>
      </LightPageHeader>
      <FilterBarDetailedSearchGrid />
    </GridProvider>
  );
};

class ClaimantSearch {
  searchKey?: string;
}

export const FilterBarDetailedSearchGrid: React.FC = () => {
  const claimantSearch = new ClaimantSearch();

  return (
    <FormProvider formModel={claimantSearch}>
      <ClaimantsGrid />
    </FormProvider>
  );
};

const ClaimantsGrid: React.FC = () => {
  const [gridToggler] = useGridSearch();
  const filters: IFilterConfig[] = [
    new FilterConfig(
      'searchKey',
      FilterBarFilterType.SearchText,
      ' Name, SSN, or @'
    ),
    new FilterConfig(
      'dateOfBirth',
      FilterBarFilterType.DatePicker,
      'Date Of Birth'
    ),
  ];
  return (
    <React.Fragment>
      <SecureComponent permissions={[Permission.AddClaimant]}>
        <GridButtonSection>
          <AddButton url={addEditRoute(AddEditSubRoute.Claimant)}>
            New Claimant
          </AddButton>
        </GridButtonSection>
      </SecureComponent>
      <FilterBarMainGrid
        isMainGrid
        filterBarProps={{
          filterConfigurations: filters,
          filterValues: [],
        }}
        refreshToggle={gridToggler}
        readEndpoint={ENDPOINTS.claimants.read}
        {...defaultGridProps}
      >
        <Column field="fullName" cell={NameCell} title="Claimant Name" />
        <Column field="email" title="Email Address" />
        <Column field="ssn" title="SSN" />
        <Column field="dateOfBirth" title="Date Of Birth" cell={DateColumn} />
        <Column field="id" width="80" title="Actions" cell={ActionsCell} />
      </FilterBarMainGrid>
    </React.Fragment>
  );
};

const ActionsCell: React.FC<GridCellProps> = (props: GridCellProps) => {
  return (
    <EditDeleteCell
      editRoute={addEditRoute(AddEditSubRoute.Claimant)}
      editPermissions={[Permission.EditClaimant]}
      deleteEndpoint={ENDPOINTS.claimants.base}
      deletePermissions={[Permission.DeleteClaimant]}
      {...props}
    />
  );
};

const NameCell: React.FC<GridCellProps> = (props: GridCellProps) => {
  return (
    <LinkCell
      endpoint={ROUTES.CLAIMANT}
      subRoute={ClaimantsSubRoute.View}
      linkField="id"
      {...props}
    ></LinkCell>
  );
};

const DateColumn: React.FC<GridCellProps> = (props: GridCellProps) => {
  return <DateCell {...props} dateFormat={'MM/DD/yyyy'} />;
};
