import React from "react";
import { Enumerations } from "Models/SharedModels";
import { useDataFetcher } from "Utilities";
import { ENDPOINTS } from "Models/Templates/Routes/Routes";
import { AsyncLoadingSection } from "Components/Display";

type SendEnumerationModel = {
  name: string;
  displayName: string;
  value: string | number;
  [x: string]: any;
};

type EnumsObjectType = {
  [x: string]: SendEnumerationModel[];
};

const EnumerationContext = React.createContext<Enumerations | undefined>(
  undefined
);

const responseToEnums = (response: any) => {
  var data: EnumsObjectType = response.data;
  var store: any = {};
  Object.entries(data).forEach((item) => {
    store[item[0]] = {};
    item[1].forEach((enumeration) => {
      var fieldKeys = Object.keys(enumeration.fields);
      store[item[0]][enumeration.name] = {};
      fieldKeys.forEach((key) => {
        store[item[0]][enumeration.name][key] = enumeration.fields[key];
      });
    });
  });
  return store;
};

export const EnumerationProvider: React.FC = ({ children }) => {
  const [enums, fetchStatus] = useDataFetcher<Enumerations | undefined>(
    ENDPOINTS.enumerations.base,
    undefined,
    responseToEnums
  );

  return (
    <EnumerationContext.Provider value={enums}>
      <AsyncLoadingSection
        loadingStatus={fetchStatus}
        loadingText="Initializing Settings..."
        fullView
      >
        {children}
      </AsyncLoadingSection>
    </EnumerationContext.Provider>
  );
};

export const useEnums = () => {
  const enumContext = React.useContext(EnumerationContext);
  if (enumContext === undefined) {
    throw new Error("useEnums must be used within a EnumerationProvider");
  }
  return enumContext;
};
