import './FilterBar.scss';
import React, { Fragment } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import {
  filterTypeHasChip,
  filterTypeShouldClear,
  getDateRangeNames,
  useFilterBarHelpers,
  useFilterBarState,
  useFilterBarValue,
} from 'Context';
import {
  DropdownItemModel,
  FilterBarFilterType,
  FILTER_COMPONENT_MAP,
  IFilterConfig,
} from 'Models';
import CloseIcon from '@mui/icons-material/Close';
import { Render } from 'Components/Display';
import {
  fullClassName,
  isNullEmptyOrUndefined,
  toggledClass,
  toLocalDateString,
} from 'Utilities';
import { TextButton } from 'Components/Form';
import { SecureComponent } from 'Components/Security';

export type FilterBarProps = {
  isOpen?: boolean;
};

export const FilterBar: React.FC<FilterBarProps> = ({ ...filterBarProps }) => {
  const { filterConfigurations = [], areFiltersApplied  } = useFilterBarState();

  return (
    <Fragment>
      <div className="collapsible-filter-bar">
        <input type="checkbox" id="collapsible-toggle" defaultChecked={filterBarProps?.isOpen ?? false} className="collapsible-toggle" />
        <label htmlFor="collapsible-toggle" className="collapsible-label">
          <SearchIcon className="filter-bar-search-icon" />&nbsp;
          <b>Filters</b>&nbsp;&nbsp;
          <span className="icon"></span>
        </label>
        <div className="collapsible-content">
          <div
            className='filter-bar-wrapper'
          >

            {filterConfigurations
              .sort(
                (a, b) =>
                  Number(b.type === FilterBarFilterType.SearchText) -
                  Number(a.type === FilterBarFilterType.SearchText)
              )
              .map((filter, index) => (
                <FilterRenderer key={index} {...filter} />
              ))}
          </div>
        </div>
      </div>

      <FilterChips />
    </Fragment>
  );
};

export const FilterRenderer: React.FC<IFilterConfig> = ({
  type,
  permissions,
  ...filterProps
}) => {
  const FilterComponent = FILTER_COMPONENT_MAP[type] || null;

  return FilterComponent ? (
    <SecureComponent permissions={permissions}>
      <FilterComponent {...filterProps} />
    </SecureComponent>
  ) : null;
};

export const FilterChips: React.FC = () => {
  const { filterConfigurations = [], areFiltersApplied } = useFilterBarState();
  const { clearAllFilters } = useFilterBarHelpers();

  return (
    <Render condition={areFiltersApplied}>
      <div className="filter-chips">
        <div className="flex-vertical-center flex-grow flex-wrap filter-chips-content">
          <div className="font-secondary-bold mr-2">Applied Filters:</div>
          {filterConfigurations
            .filter(({ type }) => filterTypeHasChip(type))
            .map((config, index) => (
              <FilterChip key={index} {...config} />
            ))}
        </div>
        <TextButton onClick={clearAllFilters}>Clear all</TextButton>
      </div>
    </Render>
  );
};

export const FilterChip: React.FC<IFilterConfig> = ({
  name,
  multiSelect,
  type,
  ...config
}) => {
  const [value] = useFilterBarValue(name);

  return (
    <Render condition={!isNullEmptyOrUndefined(value)}>
      {multiSelect ? (
        ((value as DropdownItemModel[]) || []).map((item) => (
          <FilterChipContent
            key={item.id}
            filterConfig={{ ...config, type, name }}
            value={item}
          />
        ))
      ) : (
        <FilterChipContent
          filterConfig={{ ...config, type, name }}
          value={value}
        />
      )}
    </Render>
  );
};

export type FilterChipContentProps = {
  filterConfig: IFilterConfig;
  value: any;
};

export const FilterChipContent: React.FC<FilterChipContentProps> = ({
  filterConfig,
  value,
}) => {
  const { customChipRender, id, displayName, name, type } = filterConfig;
  const { filterValues } = useFilterBarState();
  const { clearFilter } = useFilterBarHelpers();

  var chipDisplay = customChipRender
    ? customChipRender(value, displayName)
    : `${displayName}: ${value}`;

  if (type === FilterBarFilterType.DateRange) {
    const [fromDateName, toDateName] = getDateRangeNames(name);
    const fromDateDisplay = toLocalDateString(filterValues[fromDateName]);
    const toDateDisplay = toLocalDateString(filterValues[toDateName]);
    chipDisplay =
      fromDateDisplay === toDateDisplay
        ? `${displayName}: ${fromDateDisplay}`
        : `${displayName}: ${fromDateDisplay || '?'} - ${toDateDisplay || '?'}`;
  }

  const onClickFunction = filterTypeShouldClear(type)
    ? () => clearFilter(id, value)
    : undefined;

  return (
    <div className="filter-chip animate-slide-in" onClick={onClickFunction}>
      <span className="mr-2">{chipDisplay}</span>
      <Render condition={filterTypeShouldClear(type)}>
        <div className="filter-chip-close">
          <CloseIcon fontSize="inherit" />
        </div>
      </Render>
    </div>
  );
};
