export enum CalendarViewType {
  Day = "day",
  Week = "week",
  Month = "month",
}

export type CalendarEventModel = {
  id: string,
  start: Date,
  end: Date,
  title: string,
  isAllDay: boolean,
  description: string,
  recurrenceRule: string,
}

export const getViewDisplayName = (view: CalendarViewType | string) => {
  switch (view) {
    case CalendarViewType.Day:
      return "Day";
    case CalendarViewType.Week:
      return "Week";
    case CalendarViewType.Month:
    default:
      return "Month";
  }
};
