import { Input } from "@progress/kendo-react-inputs";
import { InputType } from "Models";
import React from "react";
import { InputBlockProps } from "Components/Form";

export type PasswordInputProps = InputBlockProps;

export const PasswordInput: React.FC<PasswordInputProps> = ({
    type,
  ...allProps
}) => {
  return <Input type={InputType.Password} {...allProps} />;
};
