import {
  AsyncLoadingSection,
} from 'Components/Display';
import { CancelButton, FormSubmitButton, SmartInputBlock } from 'Components/Form';
import { FormProvider, useEntity, useToasts } from 'Context';
import { useEnums } from 'Context/EnumerationProvider';
import { ToggleableProps } from 'Models';
import { Appointment } from 'Models/AppointmentModels';
import { InputType } from 'Models/FormModels';
import { ENDPOINTS } from 'Models/Templates/Routes/Routes';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Row } from 'reactstrap';
import {
  useDataFetcher,
  defaultDataTransform,
  buildQueryUrl,
  buildEndpoint,
} from 'Utilities';

type AddEditAppointmentDrawerProps = ToggleableProps & {
  
}
export const AddEditAppointmentDrawer: React.FC<AddEditAppointmentDrawerProps> = ({ toggle }) => {
  const entityName = 'Case';
  const entity = useEntity();
  const toast = useToasts();
  const history = useHistory();
  
  const [data, loadingStatus] = useDataFetcher<Appointment>(
    buildQueryUrl(buildEndpoint(ENDPOINTS.appointments.get), {
      id: entity.appointment.id || ''
    }),
    new Appointment(),
    defaultDataTransform,
    Appointment
  );

  const handleSuccess = () => {
      toast.success(`${entityName} updated`);
      history.goBack()
  }

  return (
    <AsyncLoadingSection loadingStatus={loadingStatus} useMask>
      <FormProvider {...{
        formModel: data,
        endpoint: ENDPOINTS.appointments.base,
        handleSuccess: handleSuccess
      }}>
        <AppointmentsAddEditFormContent toggle={toggle} />
      </FormProvider>
    </AsyncLoadingSection>
  );
};

const AppointmentsAddEditFormContent: React.FC<ToggleableProps> = ({ toggle }) => {
  const { appointmentStatus } = useEnums();

  return (
    <div style={{}}>
      <Row style={{ display: 'flex', justifyContent: 'center' }}>
        <SmartInputBlock
          name="writer"
          label="Writer"
          type={InputType.AsyncDropDown}
          useQuery
          otherparams={{ isWriter: true }}
          readEndpoint={ENDPOINTS.users.dropdownItems}
        />
      </Row><Row style={{ display: 'flex', justifyContent: 'center' }}>
        <SmartInputBlock
          type={InputType.DatePicker}
          name="appointmentDate"
          label="Reviewer Due Date"
          required
        />
      </Row><Row style={{ display: 'flex', justifyContent: 'center' }}>
        <SmartInputBlock
          type={InputType.TimePicker}
          name="appointmentDate"
          label="Reviewer Due Time"
          required
        />
      </Row><Row style={{ display: 'flex', justifyContent: 'center' }}>
        <SmartInputBlock
          name="professional"
          label="Reviewer"
          type={InputType.AsyncDropDown}
          useQuery
          otherparams={{ isReviewer: true }}
          readEndpoint={ENDPOINTS.users.dropdownItems}
          required
        />
      </Row><Row style={{ display: 'flex', justifyContent: 'center' }}>
        <SmartInputBlock
          name="appointmentStatus"
          label="Case Status"
          type={InputType.EnumDropdown}
          data={appointmentStatus}
          required
        />
      </Row>
      <Row style={{ display: 'flex', justifyContent: 'center' }}>
        <CancelButton
          onClick={toggle}
        >
        </CancelButton>
        <FormSubmitButton>
          Save
        </FormSubmitButton>
      </Row>
    </div>
  );
};
