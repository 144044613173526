export enum HOTKEYS {
  TOGGLE_SIDEBAR = '[',
  QUICK_SEARCH = '/',
  DETAILED_SEARCH = 'Shift+/',
  ENTER = 'enter',
}

export const BLANK_FIELD = '—';

export const DEFAULT_DROPDOWN_TEXTFIELD = 'name';
export const DEFAULT_DROPDOWN_VALUEFIELD = 'id';
export const DEFAULT_DROPDOWN_ITEM = { name: BLANK_FIELD };
export const DEFAULT_ENUM_DROPDOWN_ITEM = {
  value: undefined,
  displayName: BLANK_FIELD,
};

export const EMPTY_GUID = '00000000-0000-0000-0000-000000000000';

export const DEFAULT_DATETIME_FORMAT = 'MM/DD/YYYY hh:mm a';
export const DEFAULT_DATETIME24H_FORMAT = 'MM/DD/YYYY HH:mm';
export const DEFAULT_DATE_FORMAT = 'MM/DD/YYYY';
export const FINANCIAL_AMOUNT = '$__';

export const defaultDataTransform = ({ data }: any) => data;
export const defaultCaseDataTransform = (caseId: string) => {
  return ({ data }: any) => {
    return { ...data, caseId: caseId };
  };
};

export enum Widths {
  Small = '50px',
  Medium = '100px',
  Large = '200px',
  XL = '300px',
}

export const PDF_MIME_FILE_TYPE = 'application/pdf';
export const DOC_MIME_FILE_TYPE = 'application/msword';
export const DOCX_MIME_FILE_TYPE =
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
export const XLS_MIME_FILE_TYPE = 'application/vnd.ms-excel';
export const XLSX_MIME_FILE_TYPE =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

export const FILE_TYPE_MAP = {
  'application/pdf': '.pdf',
  'application/msword': '.doc',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
    '.docx',
  'application/vnd.ms-excel': '.xls',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': '.xlsx',
};
