import { NamedCoreEnum } from 'Models';

export class ClaimEscalation {
  claimId?: string;
  claimEscalationId?: string;
  caseDueDate?: Date;
  title?: string;
  clientInstructions?: string;
  cptCode?: string;
  diagnosis?: string;
  escalatedByEmail?: string;
  escalatedByName?: string;
  escalationDescription?: string;
  icdCode?: string;
  quantity?: string;
  externalReviewerId?: string;
}
