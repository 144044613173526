import './NavigationPillBar.scss';
import React from 'react';
import { DynamicNavigationPill, NavigationPill } from '.';
import {
  NavConfigItem,
  useNavigation,
  useSubNavigation,
  ROUTES,
} from 'Navigation';
import { SecureComponent } from 'Components/Security';
import { fullClassName, toggledClass, buildRoute } from 'Utilities';
import { Link, useRouteMatch } from 'react-router-dom';
import { Render } from 'Components/Display';
import { Nav, NavItem, NavLink } from 'reactstrap';

type NavigationPillBarProps = {
  pills: NavConfigItem[];
};

export const NavigationPillBar: React.FC<
  NavigationPillBarProps & React.HTMLProps<HTMLDivElement>
> = ({ pills, className, ...others }) => {
  const { navConfig } = useNavigation(pills);

  return (
    <div
      className={'pillbar__wrapper full-height ' + (className || '')}
      {...others}
    >
      {/* {navConfig.map((pill: NavConfigItem, index: any) => (
        <SecureComponent permissions={pill.permissions} key={index}>
          {pill.navItemsUrl === undefined ? (
            <NavigationPill {...pill} />
          ) : (
            <DynamicNavigationPill {...pill} />
          )}
        </SecureComponent>
      ))} */}
    </div>
  );
};

export type SecondaryNavigationPillBarProps = {
  parentRoute?: string;
};

export const SecondaryNavigationPillBar: React.FC<SecondaryNavigationPillBarProps> =
  ({ parentRoute }) => {
    const navConfig = useSubNavigation(parentRoute);
    const { url } = useRouteMatch();

    return (
      <Render condition={navConfig.length > 0}>
        <Nav tabs>
          {navConfig.map((pill: NavConfigItem, index: number) => (
            <SecureComponent permissions={pill.permissions} key={index}>
              <NavItem>
                <Link
                  to={
                    pill.fullRoute
                      ? buildRoute(url, pill.fullRoute)
                      : ROUTES.UNAUTHORIZED
                  }
                  className={fullClassName(
                    'nav-link',
                    toggledClass('active', pill.active)
                  )}
                >
                  <div className="flex-center">{pill.label}</div>
                </Link>
              </NavItem>
            </SecureComponent>
          ))}
        </Nav>
      </Render>
    );
  };
