import EditIcon from '@mui/icons-material/Edit';
import { makeStyles } from '@mui/styles';
import { PageCard, PageCardRow, PageCardSection } from 'Components/Display';
import { TextIconButton } from 'Components/Form';
import { SecureComponent } from 'Components/Security';
import { ModalFormProvider, useEntity, useToasts } from 'Context';
import { Permission } from 'Models/Templates/Permission/Permission';
import {
  addEditRoute,
  AddEditSubRoute,
  ClaimantsSubRoute,
  ClaimsSubRoute,
  EntitiesSubRoute,
  entitiesSubRouteName,
  ReferralSubRoute,
  ROUTES,
} from 'Navigation';
import React, { Fragment } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem, Col, Row } from 'reactstrap';
import { buildRoute, formatDateTimeFromDotNetFormat } from 'Utilities';
import { CloseReferralDialog } from '.';
import { ReferralsRequestCommercial } from 'Models';

export const ViewReferralCommercialBreadcrumbs: React.FC = () => {
  const entity = useEntity();
  const toast = useToasts();

  let claimantEndpoint = buildRoute(
    ROUTES.CLAIMANT,
    `${entity.claimantId}`,
    ClaimantsSubRoute.View
  );
  let claimEndpoint = buildRoute(
    ROUTES.CLAIM,
    `${entity.claimId}`,
    ClaimsSubRoute.View
  );
  let referralEndpoint = buildRoute(
    ROUTES.REFERRAL,
    `${entity.referralId}`,
    ReferralSubRoute.View
  );

  const handleSuccess = () => {
    toast.success(`Case has been marked as closed`);
  };

  return (
    <Fragment>
      <Breadcrumb tag="nav" listTag="div" listClassName="d-flex">
        <BreadcrumbItem className="line-height-largest">
          <Link to={entitiesSubRouteName(EntitiesSubRoute.Claimants)}>
            Claimants
          </Link>
        </BreadcrumbItem>
        <BreadcrumbItem className="line-height-largest">
          <Link to={claimantEndpoint}>{entity.claimantName}</Link>
        </BreadcrumbItem>
        <BreadcrumbItem className="line-height-largest">
          <Link to={claimEndpoint}>{entity.claimNumber}</Link>
        </BreadcrumbItem>
        <BreadcrumbItem className="flex-grow-1 line-height-largest">
          <Link to={referralEndpoint}>{entity.name}</Link>
          <ModalFormProvider>
            <CloseReferralDialog />
          </ModalFormProvider>
        </BreadcrumbItem>
      </Breadcrumb>
    </Fragment>
  );
};

export const ViewReferralsCommercial: React.FC = () => {
  const entity = useEntity();
  const isCommercial = entity.referralsRequestsCommercialModel?.id !== undefined;
  const commercialModel = entity.referralsRequestsCommercialModel as ReferralsRequestCommercial

  const useStyles = makeStyles({
    root: {
      minWidth: 275,
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
    overFlow: {
      overflowWrap: 'break-word',
    }
  });

  const history = useHistory();
  const editClaimant = () => {
    history.push(addEditRoute(AddEditSubRoute.Claimant, entity.claimantId));
  };

  const editClaim = () => {
    history.push(addEditRoute(AddEditSubRoute.Claims, entity.claimId));
  };

  const classes = useStyles();

  let hasExternalAdjuster =
    entity.externalAdjuster != null && entity.externalAdjuster != ''
      ? true
      : false;

  return (
    <Fragment>
      <PageCard className={classes.root}>
        <PageCardRow>
          <PageCardSection title="Information">
            <Row>
              <Col xs="12" lg="6">
                <span className="field-label data-display">Claimant:</span>
                <span className="field-value">
                  <Link
                    to={buildRoute(
                      ROUTES.CLAIMANT,
                      entity.claimantId,
                      ClaimantsSubRoute.View
                    )}
                  >
                    {entity.claimantName}
                  </Link>
                  <SecureComponent permissions={[Permission.EditClaimant]}>
                    <TextIconButton
                      icon={EditIcon}
                      className="d-inline ml-3"
                      onClick={editClaimant}
                    />
                  </SecureComponent>
                </span>
              </Col>
              <Col xs="12" lg="6">
                <span className="field-label data-display">Claim:</span>
                <span className="field-value">
                  <Link
                    to={buildRoute(
                      ROUTES.CLAIM,
                      entity.claimId,
                      ClaimsSubRoute.View
                    )}
                  >
                    {entity.claimNumber} ({entity.clientName})
                  </Link>
                  <SecureComponent permissions={[Permission.EditClaim]}>
                    <TextIconButton
                      icon={EditIcon}
                      className="d-inline ml-3"
                      onClick={editClaim}
                    />
                  </SecureComponent>
                </span>
              </Col>
            </Row>
            <Row>
              <Col xs="12" lg="6">
                <span className="field-label data-display">Referrer Name:</span>
                <span className="field-value">{entity.name}</span>
              </Col>
              <Col xs="12" lg="6">
                <span className="field-label data-display">Proxied By:</span>
                <span className="field-value">{entity?.referralsRequestsCommercialModel?.proxiedBy}</span>
              </Col>
            </Row>
            <Row>
              <Col xs="12" lg="6">
                <span className="field-label data-display">Referral Date:</span>
                <span className="field-value">
                  {entity.referralDate
                    ? formatDateTimeFromDotNetFormat(
                      entity.referralDate,
                      'MM/dd/yyyy'
                    )
                    : ''}
                </span>
              </Col>
            </Row>
            <Row>
              <Col xs="12" lg="6">
                <span className="field-label data-display">
                  Referral Number:
                </span>
                <span className="field-value">{entity.referralNumber}</span>
              </Col>
              <Col xs="12" lg="6">
                <span className="field-label data-display">
                  AP Speciality:
                </span>
                <span className="field-value">{entity?.referralsRequestsCommercialModel?.apSpecialty}</span>
              </Col>
            </Row>
            <Row>
              <Col xs="12" lg="6">
                <span className="field-label data-display">
                  AP Contact:
                </span>
                <span className="field-value">{entity?.referralsRequestsCommercialModel?.apContact?.displayName}</span>
              </Col>
              <Col xs="12" lg="6">
                <span className="field-label data-display">
                  AP Name:
                </span>
                <span className="field-value">{entity?.referralsRequestsCommercialModel?.apName}</span>
              </Col>
            </Row>
            <Row>
              <Col xs="12" lg="6">
                <span className="field-label data-display">
                  AP Phone:
                </span>
                <span className="field-value">{entity.referralsRequestsCommercialModel?.apPhoneNumber}</span>
              </Col>
              <Col xs="12" lg="6">
                <span className="field-label data-display">
                  AP Fax:
                </span>
                <span className="field-value">{entity.referralsRequestsCommercialModel?.apFaxNumber}</span>
              </Col>
            </Row>
            <Row>
              <Col xs="12" lg="6">
                <span className="field-label data-display">Due Date:</span>
                <span className="field-value">
                  {entity.dueDate
                    ? formatDateTimeFromDotNetFormat(
                      entity.dueDate.toString(),
                      'MM/dd/yyyy'
                    )
                    : ''}
                </span>
              </Col>
            </Row>

            <Row>
              <Col xs="12" lg="6">
                <span className="field-label data-display">
                  Line of Business:
                </span>
                <span className="field-value">{entity?.referralsRequestsCommercialModel?.lineOfBusiness}</span>
              </Col>
              <Col xs="12" lg="6">
                <span className="field-label data-display">Service</span>
                <span className="field-value">{entity?.referralsRequestsCommercialModel?.service}</span>
              </Col>
            </Row>

            <Row>
              <Col xs="12" lg="6">
                <span className="field-label data-display">
                  TAT:
                </span>
                <span className="field-value">{entity?.referralsRequestsCommercialModel?.tat}</span>
              </Col>
            </Row>

            <Row>
              {hasExternalAdjuster && (
                <Col xs="12" lg="6">
                  <span className="field-label data-display">
                    External Adjuster:
                  </span>
                  <span className="field-value">{entity.externalAdjuster}</span>
                </Col>
              )}
              {!hasExternalAdjuster && (
                <Col xs="12" lg="6">
                  <span className="field-label data-display">Adjuster:</span>
                  <span className="field-value">{entity.adjusterName}</span>
                </Col>
              )}
            </Row>
            <Row>
              <Col xs="12" lg="6">
                <span className='field-label data-display'>Questions:</span>
                <ul>
                  {commercialModel?.referralQuestions?.map((q, i) => (
                    <li key={i}>{q.value}</li>
                  ))}
                </ul>
              </Col>
            </Row>
          </PageCardSection>
        </PageCardRow>
      </PageCard>
    </Fragment>
  );
};
