import React from 'react';
import "./ModalCloseButton.scss";

type ModalCloseButtonProps = {
  toggle: () => void
}

export const ModalCloseButton: React.FC<ModalCloseButtonProps> = ({toggle}) => {
  return (
    <button onClick={toggle} className="close-button" aria-label="Close">
      <span aria-hidden={true}>×</span>
    </button>
  )
}
