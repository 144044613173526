import { ActionsSubRouteConfig, SubRoutes } from "Navigation/NavigationTypes";
import { Permission } from "Models/Templates/Permission/Permission";
import { withIdParam } from "Navigation/NavigationHelpers";

export enum ViewSubRoute {
  ViewDefenseAttorney = "/ViewDefenseAttorney"
}

export const ViewSubRoutes: SubRoutes = {
 
 };
