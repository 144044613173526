import { Permission } from 'Models/Templates/Permission/Permission';
import {
  AddEditSubRoute,
  CaseManagementSubRoute,
  DocumentsSubRoute,
  EntitiesSubRoute,
  NetworkDevelopmentSubRoute,
  QualityAssuranceSubRoute,
  ROUTES,
} from 'Navigation';
import { matchPath, RouteProps, useLocation } from 'react-router-dom';
import { buildRoute, useHasPermissions } from 'Utilities';

/**
 * Represents a Rule to be used in the Redirect configuration by the useRedirect()
 */
interface IRedirectRules {
  /**
   * The route that will have the permission validated
   */
  route: RouteProps;
  /**
   *  An array of Permissions required; user only need to have one permission to see the page.
   */
  only?: Permission[];
  /**
   * Path to redirect to when permissions are not met by the user.
   */
  redirectTo?: string;
  /**
   * Set to True to override the “reditectTo” path and redirect to UNAUTHORIZED page instead.
   */
  toUnauthorized?: boolean;
}

const idParam: string = '/:id';
const emptyGuidParam: string = '/00000000-0000-0000-0000-000000000000';
/**
 * Configure each redirect rule to be return in an array.
 * @returns IRedirectRules[]
 */
export const RedirectRules = (): IRedirectRules[] => {
  return [
    {
      route: { path: ROUTES.DASHBOARD },
      only: [Permission.ViewDashboard],
      redirectTo: buildRoute(
        ROUTES.CASEMANAGEMENT,
        CaseManagementSubRoute.OpenReferrals
      ),
    },
    {
      route: { path: buildRoute(ROUTES.ENTITIES, EntitiesSubRoute.Claimants) },
      only: [Permission.ViewClaimant],
      toUnauthorized: true,
    },
    {
      route: { path: buildRoute(ROUTES.ENTITIES, EntitiesSubRoute.Claims) },
      only: [Permission.ViewClaim],
      toUnauthorized: true,
    },
    {
      route: { path: buildRoute(ROUTES.ENTITIES, EntitiesSubRoute.Referrals) },
      only: [Permission.ViewReferral],
      toUnauthorized: true,
    },
    {
      route: {
        path: buildRoute(ROUTES.ENTITIES, EntitiesSubRoute.ReferralsRequests),
      },
      only: [Permission.ViewReferralsRequest],
      toUnauthorized: true,
    },
    {
      route: {
        path: buildRoute(ROUTES.ENTITIES, EntitiesSubRoute.Appointments),
      },
      only: [Permission.ViewAppointment],
      toUnauthorized: true,
    },
    {
      route: { path: buildRoute(ROUTES.ENTITIES, EntitiesSubRoute.Physicians) },
      only: [Permission.ViewPhysician],
      toUnauthorized: true,
    },
    {
      route: {
        path: buildRoute(
          ROUTES.CASEMANAGEMENT,
          CaseManagementSubRoute.AllClaimants
        ),
      },
      only: [Permission.ViewAllClaimants],
      toUnauthorized: true,
    },
    {
      route: {
        path: buildRoute(
          ROUTES.CASEMANAGEMENT,
          CaseManagementSubRoute.AssignedToWriters
        ),
      },
      only: [Permission.ViewAssignedToWriters],
      toUnauthorized: true,
    },
    {
      route: {
        path: buildRoute(
          ROUTES.CASEMANAGEMENT,
          CaseManagementSubRoute.ClosedReferrals
        ),
      },
      only: [Permission.ViewClosedCases],
      toUnauthorized: true,
    },
    {
      route: {
        path: buildRoute(
          ROUTES.CASEMANAGEMENT,
          CaseManagementSubRoute.OpenReferrals
        ),
      },
      only: [Permission.ViewOpenCases],
      toUnauthorized: true,
    },
    {
      route: {
        path: buildRoute(
          ROUTES.CASEMANAGEMENT,
          CaseManagementSubRoute.NewReferrals
        ),
      },
      only: [Permission.ViewNewReferrals],
      toUnauthorized: true,
    },
    {
      route: {
        path: buildRoute(
          ROUTES.CASEMANAGEMENT,
          CaseManagementSubRoute.PendingScheduling
        ),
      },
      only: [Permission.ViewPendingAppointments],
      toUnauthorized: true,
    },
    {
      route: {
        path: buildRoute(
          ROUTES.CASEMANAGEMENT,
          CaseManagementSubRoute.ReviewedReferrals
        ),
      },
      only: [Permission.ViewReviewedReferrals],
      toUnauthorized: true,
    },
    {
      route: {
        path: buildRoute(
          ROUTES.NETWORK_DEVELOPMENT,
          NetworkDevelopmentSubRoute.ReviewersListing
        ),
      },
      only: [Permission.ViewReviewers],
      toUnauthorized: true,
    },
    {
      route: {
        path: buildRoute(ROUTES.REVIEWER_CONVERT),
      },
      only: [Permission.ConvertReviewer],
      toUnauthorized: true,
    },
    {
      route: { path: ROUTES.INVOICES },
      only: [Permission.ViewInvoices],
      toUnauthorized: true,
    },
    {
      route: { path: buildRoute(ROUTES.INVOICES, '/Reviewers') },
      only: [Permission.ViewInvoices],
      toUnauthorized: true,
    },
    {
      route: { path: ROUTES.MY_INVOICES },
      only: [Permission.ViewMyInvoices],
      toUnauthorized: true,
    },
    {
      route: { path: ROUTES.ESCALATIONS_EXPORT },
      only: [Permission.ViewEscalationsExport],
      toUnauthorized: true,
    },
    {
      route: {
        path: buildRoute(ROUTES.QA, QualityAssuranceSubRoute.AuditQueue),
      },
      only: [Permission.ViewAuditQueue],
      toUnauthorized: true,
    },
    {
      route: {
        path: buildRoute(ROUTES.QA, QualityAssuranceSubRoute.AuditReport),
      },
      only: [Permission.ViewAuditReport],
      toUnauthorized: true,
    },
    {
      route: {
        path: buildRoute(ROUTES.DOCUMENTS, DocumentsSubRoute.Guidelines),
      },
      only: [Permission.ViewGuidelines],
      toUnauthorized: true,
    },
    {
      route: { path: buildRoute(ROUTES.CLAIMANT) },
      only: [Permission.ViewClaimant],
      toUnauthorized: true,
    },
    {
      route: { path: buildRoute(ROUTES.CLAIM) },
      only: [Permission.ViewClaim],
      toUnauthorized: true,
    },
    {
      route: { path: buildRoute(ROUTES.REFERRAL) },
      only: [Permission.ViewReferral],
      toUnauthorized: true,
    },
    {
      route: { path: buildRoute(ROUTES.REFERRALSREQUEST) },
      only: [Permission.ViewReferralsRequest],
      toUnauthorized: true,
    },
    {
      route: { path: buildRoute(ROUTES.APPOINTMENT) },
      only: [Permission.ViewAppointment],
      toUnauthorized: true,
    },
    //Add Entities Rules
    {
      route: {
        path:
          buildRoute(ROUTES.ADD_EDIT, AddEditSubRoute.Appointment) +
          emptyGuidParam,
      },
      only: [Permission.AddAppointment],
      toUnauthorized: true,
    },
    {
      route: {
        path: buildRoute(ROUTES.ADD_EDIT, AddEditSubRoute.Appointment),
      },
      only: [Permission.AddAppointment],
      toUnauthorized: true,
    },
    {
      route: {
        path:
          buildRoute(ROUTES.ADD_EDIT, AddEditSubRoute.ClaimEscalation) +
          emptyGuidParam,
      },
      only: [Permission.AddEscalation],
      toUnauthorized: true,
    },
    {
      route: {
        path: buildRoute(ROUTES.ADD_EDIT, AddEditSubRoute.ClaimEscalation),
      },
      only: [Permission.AddEscalation],
      toUnauthorized: true,
    },
    {
      route: {
        path:
          buildRoute(ROUTES.ADD_EDIT, AddEditSubRoute.Claimant) +
          emptyGuidParam,
      },
      only: [Permission.AddClaimant],
      toUnauthorized: true,
    },
    {
      route: {
        path: buildRoute(ROUTES.ADD_EDIT, AddEditSubRoute.Claimant),
      },
      only: [Permission.AddClaimant],
      toUnauthorized: true,
    },
    {
      route: {
        path:
          buildRoute(ROUTES.ADD_EDIT, AddEditSubRoute.Claims) + emptyGuidParam,
      },
      only: [Permission.AddClaim],
      toUnauthorized: true,
    },
    {
      route: {
        path: buildRoute(ROUTES.ADD_EDIT, AddEditSubRoute.Claims),
      },
      only: [Permission.AddClaim],
      toUnauthorized: true,
    },
    {
      route: {
        path:
          buildRoute(ROUTES.ADD_EDIT, AddEditSubRoute.Client) + emptyGuidParam,
      },
      only: [Permission.AddClient],
      toUnauthorized: true,
    },
    {
      route: {
        path: buildRoute(ROUTES.ADD_EDIT, AddEditSubRoute.Client),
      },
      only: [Permission.AddClient],
      toUnauthorized: true,
    },
    {
      route: {
        path:
          buildRoute(ROUTES.ADD_EDIT, AddEditSubRoute.Guideline) +
          emptyGuidParam,
      },
      only: [Permission.AddGuideline],
      toUnauthorized: true,
    },
    {
      route: {
        path: buildRoute(ROUTES.ADD_EDIT, AddEditSubRoute.Guideline),
      },
      only: [Permission.AddGuideline],
      toUnauthorized: true,
    },
    {
      route: {
        path:
          buildRoute(ROUTES.ADD_EDIT, AddEditSubRoute.InternalNotes) +
          emptyGuidParam +
          idParam,
      },
      only: [Permission.AddInternalNote],
      toUnauthorized: true,
    },
    {
      route: {
        path: buildRoute(ROUTES.ADD_EDIT, AddEditSubRoute.InternalNotes),
        exact: true,
      },
      only: [Permission.AddInternalNote],
      toUnauthorized: true,
    },
    {
      route: {
        path:
          buildRoute(ROUTES.ADD_EDIT, AddEditSubRoute.Notes) +
          emptyGuidParam +
          idParam,
      },
      only: [Permission.AddNote],
      toUnauthorized: true,
    },
    {
      route: {
        path: buildRoute(ROUTES.ADD_EDIT, AddEditSubRoute.Notes),
        exact: true,
      },
      only: [Permission.AddNote],
      toUnauthorized: true,
    },
    {
      route: {
        path:
          buildRoute(ROUTES.ADD_EDIT, AddEditSubRoute.Physician) +
          emptyGuidParam,
      },
      only: [Permission.AddPhysician],
      toUnauthorized: true,
    },
    {
      route: {
        path: buildRoute(ROUTES.ADD_EDIT, AddEditSubRoute.Physician),
      },
      only: [Permission.AddPhysician],
      toUnauthorized: true,
    },
    {
      route: {
        path:
          buildRoute(ROUTES.ADD_EDIT, AddEditSubRoute.Referral) +
          emptyGuidParam,
      },
      only: [Permission.AddReferral],
      toUnauthorized: true,
    },
    {
      route: {
        path: buildRoute(ROUTES.ADD_EDIT, AddEditSubRoute.Referral),
      },
      only: [Permission.AddReferral],
      toUnauthorized: true,
    },
    {
      route: {
        path:
          buildRoute(ROUTES.ADD_EDIT, AddEditSubRoute.ReferralsRequest) +
          emptyGuidParam,
      },
      only: [Permission.AddReferralsRequest],
      toUnauthorized: true,
    },
    {
      route: {
        path: buildRoute(ROUTES.ADD_EDIT, AddEditSubRoute.ReferralsRequest),
      },
      only: [Permission.AddReferralsRequest],
      toUnauthorized: true,
    },
    {
      route: {
        path:
          buildRoute(ROUTES.ADD_EDIT, AddEditSubRoute.Reviewer) +
          emptyGuidParam,
      },
      only: [Permission.AddReviewer],
      toUnauthorized: true,
    },
    {
      route: {
        path: buildRoute(ROUTES.ADD_EDIT, AddEditSubRoute.Reviewer),
      },
      only: [Permission.AddReviewer],
      toUnauthorized: true,
    },
    {
      route: {
        path:
          buildRoute(ROUTES.ADD_EDIT, AddEditSubRoute.Role) + emptyGuidParam,
      },
      only: [Permission.AddRole],
      toUnauthorized: true,
    },
    {
      route: {
        path: buildRoute(ROUTES.ADD_EDIT, AddEditSubRoute.Role),
      },
      only: [Permission.AddRole],
      toUnauthorized: true,
    },
    {
      route: {
        path:
          buildRoute(ROUTES.ADD_EDIT, AddEditSubRoute.User) + emptyGuidParam,
      },
      only: [Permission.AddUser],
      toUnauthorized: true,
    },
    {
      route: {
        path: buildRoute(ROUTES.ADD_EDIT, AddEditSubRoute.User),
      },
      only: [Permission.AddUser],
      toUnauthorized: true,
    },

    //Edit Entities Rules
    {
      route: {
        path:
          buildRoute(ROUTES.ADD_EDIT, AddEditSubRoute.Appointment) + idParam,
        strict: true,
        exact: true,
      },
      only: [Permission.EditAppointment],
      toUnauthorized: true,
    },
    {
      route: {
        path:
          buildRoute(ROUTES.ADD_EDIT, AddEditSubRoute.ClaimEscalation) +
          idParam,
        strict: true,
        exact: true,
      },
      only: [Permission.EditEscalation],
      toUnauthorized: true,
    },
    {
      route: {
        path: buildRoute(ROUTES.ADD_EDIT, AddEditSubRoute.Claimant) + idParam,
        strict: true,
        exact: true,
      },
      only: [Permission.EditClaimant],
      toUnauthorized: true,
    },
    {
      route: {
        path: buildRoute(ROUTES.ADD_EDIT, AddEditSubRoute.Claims) + idParam,
        strict: true,
        exact: true,
      },
      only: [Permission.EditClaim],
      toUnauthorized: true,
    },
    {
      route: {
        path: buildRoute(ROUTES.ADD_EDIT, AddEditSubRoute.Client) + idParam,
        strict: true,
        exact: true,
      },
      only: [Permission.EditClient],
      toUnauthorized: true,
    },
    {
      route: {
        path: buildRoute(ROUTES.ADD_EDIT, AddEditSubRoute.Guideline) + idParam,
        strict: true,
        exact: true,
      },
      only: [Permission.EditGuideline],
      toUnauthorized: true,
    },
    {
      route: {
        path:
          buildRoute(ROUTES.ADD_EDIT, AddEditSubRoute.InternalNotes) + idParam,
      },
      only: [Permission.EditInternalNote],
      toUnauthorized: true,
    },
    {
      route: {
        path: buildRoute(ROUTES.ADD_EDIT, AddEditSubRoute.Notes) + idParam,
        strict: true,
        exact: true,
      },
      only: [Permission.EditNote],
      toUnauthorized: true,
    },
    {
      route: {
        path: buildRoute(ROUTES.ADD_EDIT, AddEditSubRoute.Physician) + idParam,
        strict: true,
        exact: true,
      },
      only: [Permission.EditPhysician],
      toUnauthorized: true,
    },
    {
      route: {
        path: buildRoute(ROUTES.ADD_EDIT, AddEditSubRoute.Referral) + idParam,
        strict: true,
        exact: true,
      },
      only: [Permission.EditReferral],
      toUnauthorized: true,
    },
    {
      route: {
        path:
          buildRoute(ROUTES.ADD_EDIT, AddEditSubRoute.ReferralsRequest) +
          idParam,
        strict: true,
        exact: true,
      },
      only: [Permission.EditReferralsRequest],
      toUnauthorized: true,
    },
    {
      route: {
        path: buildRoute(ROUTES.ADD_EDIT, AddEditSubRoute.Reviewer) + idParam,
        strict: true,
        exact: true,
      },
      only: [Permission.EditReviewer],
      toUnauthorized: true,
    },
    {
      route: {
        path: buildRoute(ROUTES.ADD_EDIT, AddEditSubRoute.Role) + idParam,
        strict: true,
        exact: true,
      },
      only: [Permission.EditRole],
      toUnauthorized: true,
    },
    {
      route: {
        path: buildRoute(ROUTES.ADD_EDIT, AddEditSubRoute.User) + idParam,
        strict: true,
        exact: true,
      },
      only: [Permission.EditUser],
      toUnauthorized: true,
    },
  ];
};

/**
 * Redirects by permission. If the current location has a Rule and the permission requirements are not met
 * will redirect to path set in the rule.
 * NOTE: The component will use the first rule that match the path,
 * consult https://v5.reactrouter.com/web/api/matchPath for more information.
 * @returns \{redirect: the path to redirect to} | empty object
 */
export const useRedirect = (): {
  redirect?: string;
} => {
  const { pathname } = useLocation();
  // Internal redirect logic
  const rule = RedirectRules().find((rule) => matchPath(pathname, rule.route));
  const hasPermissions = useHasPermissions();

  const hasRule = rule !== undefined;
  const shouldRedirect = hasRule && !hasPermissions(rule?.only);
  if (shouldRedirect) {
    return {
      redirect: rule?.toUnauthorized ? ROUTES.UNAUTHORIZED : rule?.redirectTo,
    };
  }
  return {};
};
