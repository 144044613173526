import React from "react";
import { GridCellProps } from "@progress/kendo-react-grid";

export const CommaSeparatedCell: React.FC<GridCellProps> = ({ dataItem, field = "" }) => {
  const values: string[] = dataItem[field];
  const commaSeparatedValues: string = values.join(", ");

  return (
    <td>{commaSeparatedValues}</td>
  );
};