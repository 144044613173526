import React, { useEffect, useState } from 'react';
import {
  AsyncStatus,
  CoreEnum,
  DropdownItemModel,
  IFilterConfig,
} from 'Models';
import { buildEndpoint, buildQueryUrl, useSimpleDataFetcher } from 'Utilities';
import { DropdownFilterWrapper } from './DropdownFilterWrapper';
import { DropdownOptions, useFilterBarDropdownHelpers } from 'FeatureUtilities';
import { useFilterBarValue } from 'Context';

export const FilterBarEnumDropdown: React.FC<IFilterConfig> = ({
  name,
  inputProps,
  ...filterProps
}) => {
  if (inputProps === undefined || inputProps?.data === undefined) {
    throw new Error(
      'Must pass a NamedCoreEnum as data prop in inputProps of FilterConfig'
    );
  }

  const { data } = inputProps!;
  const dropdownData: CoreEnum[] = Object.values(data);

  const [dropdownFilter, setDropdownFilter] = useState('');
  const [filteredOptions, setFilteredOptions] = useState(dropdownData);

  useEffect(() => {
    setFilteredOptions(
      dropdownData.filter(({ displayName }) =>
        displayName?.toLowerCase()?.includes(dropdownFilter.toLowerCase())
      )
    );
  }, [dropdownFilter]);

  return (
    <DropdownFilterWrapper name={name} {...filterProps}>
      <FilterBarDropdownOptions
        dropdownFilter={dropdownFilter}
        setDropdownFilter={setDropdownFilter}
        options={filteredOptions}
        name={name}
        hideSearchBar={dropdownData.length < 10}
        {...filterProps}
      />
    </DropdownFilterWrapper>
  );
};

export const FilterBarDropdown: React.FC<IFilterConfig> = ({
  name,
  inputProps,
  dropdownEndpointFilter,
  ...filterProps
}) => {
  if (inputProps === undefined || inputProps?.readEndpoint === undefined) {
    throw new Error('Must define a readEndpoint for the dropdown');
  }

  const [queryFilter] = useFilterBarValue(
    dropdownEndpointFilter?.dependencyFilterName || '',
    null
  );

  const { readEndpoint } = inputProps!;
  var trueEndpoint =
    queryFilter !== null
      ? buildQueryUrl(readEndpoint, {
          [dropdownEndpointFilter?.queryParameterName || '']: queryFilter,
        })
      : readEndpoint;
  const [dropdownData, loadingStatus] = useSimpleDataFetcher<
    DropdownItemModel[]
  >(trueEndpoint, []);
  const [dropdownFilter, setDropdownFilter] = useState('');
  const [filteredOptions, setFilteredOptions] = useState(dropdownData);

  useEffect(() => {
    setFilteredOptions(
      dropdownData.filter(({ name }) =>
        name?.toLowerCase()?.includes(dropdownFilter.toLowerCase())
      )
    );
  }, [dropdownFilter]);

  useEffect(() => {
    setFilteredOptions(dropdownData || []);
  }, [dropdownData]);

  return (
    <DropdownFilterWrapper name={name} {...filterProps}>
      <FilterBarDropdownOptions
        dropdownFilter={dropdownFilter}
        setDropdownFilter={setDropdownFilter}
        options={filteredOptions}
        name={name}
        loadingStatus={loadingStatus}
        hideSearchBar={dropdownData.length < 10}
        {...filterProps}
      />
    </DropdownFilterWrapper>
  );
};

export const FilterBarAsyncDropdown: React.FC<IFilterConfig> = ({
  name,
  inputProps,
  ...filterProps
}) => {
  if (inputProps === undefined || inputProps?.readEndpoint === undefined) {
    throw new Error('Must define a readEndpoint for the dropdown');
  }
  const { readEndpoint, useQuery = false } = inputProps;
  const [dropdownFilter, setDropdownFilter] = useState('');

  var url = useQuery
    ? buildQueryUrl(readEndpoint, { filter: dropdownFilter })
    : buildEndpoint(readEndpoint, dropdownFilter);

  const [dropdownData, loadingStatus] = useSimpleDataFetcher<
    DropdownItemModel[]
  >(url, []);

  return (
    <DropdownFilterWrapper name={name} {...filterProps}>
      <FilterBarDropdownOptions
        dropdownFilter={dropdownFilter}
        setDropdownFilter={setDropdownFilter}
        options={dropdownData || []}
        name={name}
        loadingStatus={loadingStatus}
        hideSearchBar={inputProps?.hideSearchBar}
        {...filterProps}
      />
    </DropdownFilterWrapper>
  );
};

export type FilterBarDropdownOptionsProps = IFilterConfig & {
  options: DropdownItemModel[] | CoreEnum[];
  dropdownFilter: string;
  setDropdownFilter: React.Dispatch<React.SetStateAction<string>>;
  loadingStatus?: AsyncStatus;
  hideSearchBar?: boolean;
};

export const FilterBarDropdownOptions: React.FC<FilterBarDropdownOptionsProps> =
  ({ name, ...otherProps }) => {
    const { ...filterBarHelpers } = useFilterBarDropdownHelpers(name);

    return <DropdownOptions {...filterBarHelpers} {...otherProps} />;
  };
