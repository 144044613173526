import React from "react";
import { useFilterBarValue } from "Context";
import { IFilterConfig } from "Models";
import { DropdownFilterWrapper } from "./DropdownFilterWrapper";
import { Calendar } from "@progress/kendo-react-dateinputs";
import { toLocalDateString } from "Utilities";

export const FilterBarDatePicker: React.FC<IFilterConfig> = ({
  name,
  inputProps,
  ...filterProps
}) => {
  const [selectedDate, setSelectedDate] = useFilterBarValue(name);
  var dateValue = selectedDate ? new Date(selectedDate) : undefined;

  return (
    <DropdownFilterWrapper name={name} {...filterProps}>
      <Calendar
        focusedDate={dateValue}
        value={dateValue}
        onChange={(e) => setSelectedDate(toLocalDateString(e.value))}
        className="popup-calendar"
      />
    </DropdownFilterWrapper>
  );
};
