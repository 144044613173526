import "./SidebarSubLayout.scss";
import React from "react";
import { SubLayoutProps } from ".";
import { fullClassName, toggledClass } from "Utilities";

export type FullscreenSubLayoutProps = SubLayoutProps & {
  noPadding?: boolean;
};

export const FullscreenSubLayout: React.FC<FullscreenSubLayoutProps> = ({
  children,
  noPadding,
}) => {
  return (
    <div className="sidebar-layout full-size animate-slide-down">
      <div
        className={fullClassName(
          "main-card",
          toggledClass("no-padding-margin", noPadding)
        )}
      >
        {children}
      </div>
    </div>
  );
};
