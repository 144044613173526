import "./ErrorDisplay.scss";
import React, { Fragment, HTMLProps } from "react";
import { fullClassName, toggledClass } from "Utilities";
import { useFormState } from "Context";
import { PageAlert } from "./PageAlert";
import { AlertType } from "Models";

type ErrorDisplayProps = HTMLProps<HTMLDivElement> & {
  errors: string[];
  displayAsAlert?: boolean;
  noPaddingMargin?: boolean;
};

export const ErrorDisplay: React.FC<ErrorDisplayProps> = ({
  errors = [],
  className,
  displayAsAlert,
  noPaddingMargin,
  ...others
}) => {
  const errorContent = errors.map((error, index) => (
    <div
      key={index}
      className={fullClassName(
        "animate-slide-down",
        toggledClass("error-text", !displayAsAlert),
        toggledClass("mt-2", !noPaddingMargin),
        className
      )}
      {...others}
    >
      {error}
    </div>
  ));

  return displayAsAlert && errors.length > 0 ? (
    <PageAlert alert={{ type: AlertType.Danger, name: "", description: "" }}>
      {errorContent}
    </PageAlert>
  ) : (
    <Fragment>{errorContent}</Fragment>
  );
};

export type ListDescriptor = {
  listName: string;
  listObject: any;
  listLength: number;
};

type FormErrorDisplayProps = HTMLProps<HTMLDivElement> & {
  errorNames?: string[];
  listDescriptors?: ListDescriptor[];
  displayAsAlert?: boolean;
  noPaddingMargin?: boolean;
};

export const FormErrorDisplay: React.FC<FormErrorDisplayProps> = ({
  errorNames = [],
  listDescriptors,
  ...otherProps
}) => {
  const { formErrors } = useFormState();

  const errorList = errorNames
    ?.map((name) => formErrors[name.toLowerCase()])
    .reduce((acc, val) => acc.concat(val), []);

  const listErrors =
    listDescriptors
      ?.map(({ listName, listObject, listLength }) => {
        const formatErrorName = (name: string, index: number) =>
          `${listName}[${index}].${name}`.toLowerCase();
        const propertyNames = Object.getOwnPropertyNames(listObject);

        var listErrors: string[] = [];

        for (let i = 0; i < listLength; i++) {
          listErrors.concat(
            propertyNames
              .map((propName) => formErrors[formatErrorName(propName, i)])
              .reduce((acc, val) => acc.concat(val), [])
          );
        }

        return listErrors;
      })
      .reduce((acc, val) => acc.concat(val), []) || [];

  errorList.concat(listErrors);

  return (
    <ErrorDisplay
      errors={errorList.filter((x) => x !== undefined)}
      {...otherProps}
    />
  );
};
