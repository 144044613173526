import React, { Fragment, HTMLProps, useEffect } from 'react';
import { useFormStateValue } from 'Context';
import {
  ButtonCore,
  ButtonSize,
  ButtonStyle,
  TextIconButton,
} from '../Buttons';
import {
  CollapsibleSection,
  FlexWrapper,
  FormListSection,
  Render,
} from 'Components/Display';
import { fullClassName, toggledClass, useHasPermissions, useSafeStateUpdate } from 'Utilities';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { DraggableModel } from 'Models';
import { v4 as uuidv4 } from 'uuid';
import { Permission } from 'Models/Templates/Permission/Permission';

export type DragDropConfig = {
  draggable: boolean;
  noDroppable?: boolean;
  droppableId?: string;
  minDroppableHeight?: number;
};

export type SmartMultiSectionListProps = HTMLProps<HTMLDivElement> & {
  listName: string;
  listObjectName?: string;
  initialValues?: any;
  removeable?: boolean;
  hasInitialSection?: boolean;
  isDisabled?: boolean;
  noCollapse?: boolean;
  leftAlignDelete?: boolean;
  hideButtons?: boolean;
  includeTitles?: boolean;
  deleteByTitle?: boolean;
  extraButtons?: any;
  noSeparator?: boolean;
  usePrimaryButton?: boolean;
  noAnimation?: boolean;
  rightAlignDelete?: boolean;
  sectionClass?: string;
  listFilter?: (value: any, index: number) => boolean;
  dragDropConfig?: DragDropConfig;
  onRemoveSection?: (index: number, value: any) => void;
  alignDeleteWithInputRow?: boolean;
  smallTitle?: boolean;
  deletePermissions?: Permission;
};

export const defaultFormatName = (name: string) => name;

export const SmartMultiSectionList: React.FC<SmartMultiSectionListProps> = ({
  listName,
  listObjectName,
  initialValues,
  children,
  hasInitialSection,
  isDisabled,
  leftAlignDelete,
  deleteByTitle,
  extraButtons,
  noSeparator,
  usePrimaryButton,
  dragDropConfig,
  onRemoveSection,
  noAnimation,
  rightAlignDelete,
  sectionClass,
  listFilter = () => true,
  noCollapse = false,
  hideButtons = false,
  includeTitles = !noCollapse,
  removeable = true,
  className,
  alignDeleteWithInputRow,
  smallTitle,
  deletePermissions,
  ...otherProps
}) => {
  //local state
  const [objectList, setObjectList] = useFormStateValue<any[]>(listName, []);
  const safeStateUpdate = useSafeStateUpdate();

  //helper functions
  const formatInputName = (name: string, index: number) => {
    return `${listName}[${index}].${name}`;
  };

  const formatChildren = (index: number) => {
    //function we're providing to children to format input names
    const formatInputNameWithIndex = (name: string) =>
      formatInputName(name, index);

    //pass input name formatter function as props to each child
    return React.Children.toArray(children).map((child) =>
      React.cloneElement(child as React.ReactElement<any>, {
        formatInputName: formatInputNameWithIndex,
        index: index,
      })
    );
  };

  //add a new list section with initial values
  const addSection = () => {
    safeStateUpdate(() => {
      const newValue = initialValues !== undefined ? { ...initialValues } : {};
      const updatedList = objectList ? [...objectList, newValue] : [newValue];
      setObjectList(updatedList);
    });
  };

  //removes item from list at index
  const removeSection = (index: number, item: any) => {
    var filteredObjectList = [...objectList];
    filteredObjectList.splice(index, 1);
    safeStateUpdate(() => setObjectList(filteredObjectList));
    onRemoveSection && onRemoveSection(index, item);
  };

  const getFilteredIndex = (value: any) => {
    const index = objectList
      .filter((val, index) => listFilter(val, index))
      .findIndex((val) => val === value);
    return index;
  };

  const isSectionRemoveable = (index: number) => {
    return (
      removeable &&
      !isDisabled &&
      !(hasInitialSection && objectList.length <= 1 && index === 0)
    );
  };

  const getSectionTitle = (index: number) => {
    return includeTitles ? `${listObjectName} ${index + 1}` : '';
  };

  const isLastSection = (index: number) => {
    return index + 1 === objectList.length;
  };

  useEffect(() => {
    safeStateUpdate(() => {
      if (hasInitialSection && objectList.length === 0) {
        addSection();
      }
    });
  }, [objectList]);

  const SectionComponent = noCollapse ? FormListSection : CollapsibleSection;
  const SectionWrapper = dragDropConfig?.draggable
    ? DraggableSectionWrapper
    : Fragment;
  const sectionWrapperProps = (listSection: Partial<DraggableModel>) =>
    dragDropConfig?.draggable
      ? {
          id: listSection?.draggableId || uuidv4(),
          index: getFilteredIndex(listSection),
        }
      : {};
  const ListWrapper =
    dragDropConfig?.draggable && !dragDropConfig.noDroppable
      ? DroppableSectionWrapper
      : Fragment;
  const listWrapperProps =
    dragDropConfig?.draggable && !dragDropConfig.noDroppable
      ? {
          id: dragDropConfig.droppableId,
          minHeight: dragDropConfig.minDroppableHeight,
        }
      : {};

      const hasPermissions = useHasPermissions();

      const hasDeletePermissions = () => deletePermissions == null || hasPermissions([deletePermissions]);

  return (
    <div
      className={fullClassName('multi-section-list', className)}
      {...otherProps}
    >
      <ListWrapper {...listWrapperProps}>
        {objectList.map((listSection: any, index: number) => (
          <Render
            condition={listFilter(listSection, index)}
            key={listSection?.draggableId || index}
          >
            <SectionWrapper {...sectionWrapperProps(listSection)}>
              <SectionComponent
                className={fullClassName(
                  'list-section-wrapper',
                  sectionClass,
                  toggledClass('animate-slide-down', !noAnimation)
                )}
                noSeparator={noSeparator}
                deleteByTitle={deleteByTitle}
                leftAlignDelete={leftAlignDelete}
                rightAlignDelete={rightAlignDelete}
                removeable={isSectionRemoveable(index) && hasDeletePermissions()}
                onRemove={() => removeSection(index, listSection)}
                title={getSectionTitle(index)}
                isLast={isLastSection(index)}
                alignDeleteWithInputRow={alignDeleteWithInputRow}
                smallTitle={smallTitle}
              >
                {formatChildren(index)}
              </SectionComponent>
            </SectionWrapper>
          </Render>
        ))}
      </ListWrapper>
      <Render condition={!hideButtons}>
        <FlexWrapper className={objectList?.length ? 'mt-3' : ''}>
          <ButtonCore
            onClick={addSection}
            disabled={isDisabled}
            buttonSize={ButtonSize.Small}
            buttonStyle={
              usePrimaryButton ? ButtonStyle.Default : ButtonStyle.Outline
            }
            primary
          >
            Add {objectList.length === 0 ? '' : 'Another'} {listObjectName}
          </ButtonCore>
          {extraButtons}
        </FlexWrapper>
      </Render>
    </div>
  );
};

export type DraggableSectionWrapperProps = {
  id?: any;
  index?: number;
};

export const DraggableSectionWrapper: React.FC<DraggableSectionWrapperProps> =
  ({ children, id = uuidv4(), index = 0 }) => {
    return (
      <Draggable draggableId={id} index={index}>
        {(provided, snapshot) => (
          <div
            className={fullClassName(
              'flex-normal flex-vertical-center pt-regular bg-light pb-regular pr-small w-auto',
              toggledClass('draggable-shadow', snapshot.isDragging)
            )}
            ref={provided.innerRef}
            {...provided.draggableProps}
          >
            <TextIconButton
              icon={DragIndicatorIcon}
              className="mr-small pt-label pl-small"
              {...provided.dragHandleProps}
            />
            {children}
          </div>
        )}
      </Draggable>
    );
  };

export type DroppableSectionWrapperProps = {
  id?: any;
  minHeight?: number;
};

export const DroppableSectionWrapper: React.FC<DroppableSectionWrapperProps> =
  ({ children, id = uuidv4(), minHeight }) => {
    return (
      <Droppable droppableId={id}>
        {(provided, snapshot) => (
          <div
            className={toggledClass(
              'bg-dark-light w-auto',
              snapshot.isDraggingOver
            )}
            style={{ minHeight }}
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            {children}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    );
  };

export type ListSectionProps = {
  formatInputName?: (name: string) => string;
  index?: number;
};

export enum DeleteButtonPosition {
  Right = 'right',
  Left = 'left',
  ByTitle = 'byTitle',
  AlignedWithInput = 'alignedWithInput',
}

export type SmartFormListProps = {
  listName: string;
  listObjectName?: string;
  removeable?: boolean;
  hasInitialSection?: boolean;
  deleteButtonPosition?: DeleteButtonPosition;
  smallTitle?: boolean;
  sectionConfig?: Partial<SmartMultiSectionListProps>;
};

export const SmartFormList: React.FC<SmartFormListProps> = ({
  children,
  sectionConfig,
  deleteButtonPosition,
  smallTitle,
  ...multiSectionListProps
}) => {
  const getDeleteButtonPositionProps = () => {
    const deleteButtonProps: Partial<SmartMultiSectionListProps> = {};

    switch (deleteButtonPosition) {
      case DeleteButtonPosition.Right:
        deleteButtonProps.rightAlignDelete = true;
        break;
      case DeleteButtonPosition.AlignedWithInput:
        deleteButtonProps.alignDeleteWithInputRow = true;
        break;
      case DeleteButtonPosition.ByTitle:
        deleteButtonProps.deleteByTitle = true;
        deleteButtonProps.includeTitles = true;
        break;
      case DeleteButtonPosition.Left:
        deleteButtonProps.leftAlignDelete = true;
        break;
    }

    return deleteButtonProps;
  };

  return (
    <SmartMultiSectionList
      {...sectionConfig}
      {...multiSectionListProps}
      {...getDeleteButtonPositionProps()}
      smallTitle={smallTitle}
      noCollapse
    >
      {children}
    </SmartMultiSectionList>
  );
};
