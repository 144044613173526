import {
  GridCellProps,
  GridColumn as Column,
} from '@progress/kendo-react-grid';
import { LightPageHeader } from 'Components/Display';
import {
  DateCell,
  DateLinkCell,
  EditViewDeleteCell,
  FilterBarMainGrid,
  LinkCell,
} from 'Components/Grid';
import { FormProvider, GridProvider, useEnums, useGlobalContext } from 'Context';
import { ColumnSelectorProvider, useColumnSelectorState } from 'Context/ColumnSelectorProvider';
import {
  defaultGridProps,
  FilterBarFilterType,
  FilterConfig,
  IFilterConfig,
} from 'Models';
import { DefaultColumnsSelection } from 'Models/ColumnSelection';
import { Permission } from 'Models/Templates/Permission/Permission';
import { ENDPOINTS } from 'Models/Templates/Routes/Routes';
import moment from 'moment';
import { ClaimantsSubRoute, ClaimsSubRoute, ReferralSubRoute, ROUTES } from 'Navigation';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import {
  DEFAULT_DATETIME24H_FORMAT,
  buildEndpoint,
  useGridSearch,
} from 'Utilities';

class ReviewedReferralSearch {
  searchKey?: string;
  id?: string;
  client?: string;
  benefitState?: string;
  appointmentDate?: Date;
}

export enum ReviewedReferralsGridIds {
  DetailedSearch = 'detailedSearch',
}

export const ReviewedReferrals: React.FC = () => {
  return (
    <ColumnSelectorProvider
      columns={DefaultColumnsSelection}
      columnSelectorName='reviewedReferralsColumns'>
      <GridProvider entityName="Referral">
        <LightPageHeader tall noBorder className="page-header-shadow">
          <Row>
            <Col>
              <h1>Reviewed Referrals</h1>
            </Col>
          </Row>
        </LightPageHeader>
        <br />
        <Row>
          <Col></Col>
        </Row>
        <FilterBarDetailedSearchGrid />
      </GridProvider>
      </ColumnSelectorProvider>
  );
};

const FilterBarDetailedSearchGrid: React.FC = () => {
  const reviewedReferralSearch = new ReviewedReferralSearch();

  return (
    <FormProvider formModel={reviewedReferralSearch}>
      <ReviewedReferralsGrid />
    </FormProvider>
  );
};

const ReviewedReferralsGrid: React.FC = () => {
  const [gridToggler] = useGridSearch();
  const { state } = useEnums();
  const globalContext = useGlobalContext();
  const userdata = globalContext[0]?.currentUser;
  const columnSelectorState = useColumnSelectorState()

  const [ exportEndpoint, setExportEndpoint ] = useState(buildEndpoint(
    ENDPOINTS.reviewedreferrals.endpointString('ExportToCsv') + `${getSelectedColumnsQuery(columnSelectorState)}`
  ));

  useEffect(() => {
    setExportEndpoint(buildEndpoint(
      ENDPOINTS.reviewedreferrals.endpointString('ExportToCsv') + `${getSelectedColumnsQuery(columnSelectorState)}`
    ));
  }, [columnSelectorState]);

  let searchBarHint: string =
'Stradix Number, Claim Number, Claimant, Referral Number, Doctor'

  if (userdata != null && userdata.isAdmin) {
    searchBarHint = searchBarHint + ', External Adjuster';
  }
  
  const filters: IFilterConfig[] = [
    new FilterConfig(
      'searchKey',
      FilterBarFilterType.SearchText,
      searchBarHint
    ),
    new FilterConfig(
      'clients',
      FilterBarFilterType.AsyncDropdown,
      'Client',
      {
        readEndpoint: ENDPOINTS.clients.dropdownItems
      },
      (value) => value.name,
      true
    ),
    new FilterConfig(
      'benefitStates',
      FilterBarFilterType.EnumDropdown,
      'Benefit State',
      { data: state },
      (value => value.displayName),
      true
    ),
    new FilterConfig(
      'appointmentDate',
      FilterBarFilterType.DatePicker,
      'Appointment Date'
    ),
    new FilterConfig(
      'reviewers',
      FilterBarFilterType.AsyncDropdown,
      'Reviewer',
      {
        readEndpoint:
        `${ENDPOINTS.users.endpointString('DropdownItems')}?IsReviewer=true`,
        useQuery: true
      },
      (value) => value.name,
      true
    )
  ];

  return (
    <React.Fragment>
      <FilterBarMainGrid
        isMainGrid
        filterBarProps={{
          filterConfigurations: filters,
          filterValues: [],
          isColumnSelectorEnabled: true,
        }}
        refreshToggle={gridToggler}
        readEndpoint={ENDPOINTS.reviewedreferrals.read}
        exportEndpoint={buildEndpoint(
          exportEndpoint
        )}
        enableGridRefresh={true}
        {...defaultGridProps}
      >
        { columnSelectorState.columns.find(x => x.field === 'stradixNumber')?.checked && <Column field="stradixNumber" cell={referralNumberCell} title="Stradix Number" width='auto' />}
        { columnSelectorState.columns.every(x => !x.checked) && <Column field="id" title=" " className='hidden' />}
        { columnSelectorState.columns.find(x => x.field === 'clientName')?.checked && <Column field="client" title="Client" />}
        { columnSelectorState.columns.find(x => x.field === 'referralDate')?.checked && <Column field="referralDate" title="Referral Date" cell={dateFormatCell} />}
        { columnSelectorState.columns.find(x => x.field === 'reportDueDate')?.checked && <Column field="reportDueDate" title="Report Due Date" cell={dateFormatCell} />}
        { columnSelectorState.columns.find(x => x.field === 'referralDueDate')?.checked && <Column field="dueDate" title="Client Due Date" cell={dateFormatCell} />}
        { columnSelectorState.columns.find(x => x.field === 'claimantName')?.checked && <Column field="claimant" title="Claimant Name" cell={claimantCell} />}
        { columnSelectorState.columns.find(x => x.field === 'claimNumber')?.checked && <Column field="claimNumber" title="Claim Number" cell={claimNumberCell} />}
        { columnSelectorState.columns.find(x => x.field === 'benefitState')?.checked && <Column field="benefitState" title="Benefit State" />}
        { columnSelectorState.columns.find(x => x.field === 'referralNumber')?.checked && <Column field="referralNumber" title="Referral Number" cell={referralNumberCell} />}
        { columnSelectorState.columns.find(x => x.field === 'status')?.checked && <Column field="status" title="Status" />}
        { columnSelectorState.columns.find(x => x.field === 'typeOfEval')?.checked && <Column field="typeOfEval" title="Type of EVAL" />}
        { columnSelectorState.columns.find(x => x.field === 'appointmentDate')?.checked && <Column field="appointmentDate" title="Appointment Date" cell={linkDateColumn} />}
        { columnSelectorState.columns.find(x => x.field === 'treatingPhysician')?.checked && <Column field="treatingPhysician" title="Treating Physician" />}
        { columnSelectorState.columns.find(x => x.field === 'physicianSpecialty')?.checked && <Column field="physicianSpecialty" title="Physician Specialty" />}
        { columnSelectorState.columns.find(x => x.field === 'insuranceCarrier')?.checked && <Column field="insuranceCarrier" title="Insurance Carrier" />}
        { columnSelectorState.columns.find(x => x.field === 'referralSource')?.checked && <Column field="referralSource" title="Referral Source" />}
        { columnSelectorState.columns.find(x => x.field === 'adjusterName')?.checked && <Column field="adjusterName" title="Adjuster Name" />}
        { columnSelectorState.columns.find(x => x.field === 'doctor')?.checked && <Column field="doctor" title="Doctor" /> }
        { columnSelectorState.columns.find(x => x.field === 'closedDate')?.checked && <Column field="closedDate" title="Date Closed" cell={dateFormatCell} /> }
        { columnSelectorState.columns.find(x => x.field === 'checkMedicalsDate')?.checked && <Column field="checkMedicalsDate" title="Check Medicals Date" cell={dateFormatCell} /> }
        <Column field="id" width="120" title="Actions" cell={ActionsCellEdit} />
      </FilterBarMainGrid>
    </React.Fragment>
  );
};

const linkDateColumn: React.FC<GridCellProps> = (props: GridCellProps) => {
  return (
    <DateLinkCell
      endpoint={ROUTES.REFERRAL}
      linkField="referralId"
      subRoute={ReferralSubRoute.View}
      customClassName={
        moment(props.dataItem.appointmentDate)
          .startOf('day')
          .isSame(moment().startOf('day').format())
          ? 'due-today-cell'
          : ''
      }
      dateFormat={DEFAULT_DATETIME24H_FORMAT}
      {...props}
    />
  );
};

const claimNumberCell: React.FC<GridCellProps> = (props: GridCellProps) => {
  return (
    <LinkCell
      endpoint={ROUTES.CLAIM}
      linkField="claimId"
      subRoute={ClaimsSubRoute.View}
      {...props}
    ></LinkCell>
  );
};

const claimantCell: React.FC<GridCellProps> = (props: GridCellProps) => {
  return (
    <LinkCell
      endpoint={ROUTES.CLAIMANT}
      linkField="claimantId"
      subRoute={ClaimantsSubRoute.View}
      {...props}
    ></LinkCell>
  );
};

const referralNumberCell: React.FC<GridCellProps> = (props: GridCellProps) => {
  return (
    <LinkCell
      endpoint={ROUTES.REFERRAL}
      linkField="referralId"
      subRoute={ClaimsSubRoute.View}
      {...props}
      dataItem={!!props.dataItem ? props.dataItem : 'View'}
    ></LinkCell>
  );
};

const dateFormatCell: React.FC<GridCellProps> = (props: GridCellProps) => {
  return <DateCell {...props} dateFormat={'MM/DD/YYYY'} />;
};

const ActionsCellEdit: React.FC<GridCellProps> = (props: GridCellProps) => {
  return (
    <EditViewDeleteCell
      endpoint=""
      editRoute={ROUTES.REFERRAL}
      subRoute={ReferralSubRoute.View}
      linkField="referralId"
      editPermissions={[Permission.EditReferral]}
      deleteEndpoint={ENDPOINTS.referrals.base}
      deletePermissions={[Permission.DeleteReferrals]}
      {...props}
    />
  );
};

const getSelectedColumnsQuery = (columnSelectorState: any) => {
  const selectedColumns = columnSelectorState.columns.filter((x: any) => x.checked).map((x: any) => x.field);
  return '?' + selectedColumns.map((x: any) => `columns=${x}`).join('&');
}