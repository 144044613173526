import { FileModel } from './FileModels';
import { Reviewer } from './ReviewerModels';

export class ReviewerBoardCertificationDocumentModel extends FileModel {
  constructor(id: string | undefined) {
    super();
    this.reviewerId = id;
  }
  reviewerId?: string;
  reviewer?: Reviewer;
  certificationNumber?: string;
  expirationDate?: Date;
}

export class ReviewerIdBoardCertificationGridSearchModel {
  constructor(id: string | undefined) {
    this.reviewerId = id;
  }
  reviewerId?: string;
}

export enum ReviewerBoardCertificationDocumentModal {
  Forms = 'attachmentsForms',
  CertificationDocuments = 'attachmentsDocument',
}
