import { makeStyles } from '@mui/styles';
import { PageCard, PageCardRow, PageCardSection } from 'Components/Display';
import { useEntity } from 'Context';
import { EntitiesSubRoute, entitiesSubRouteName, ROUTES } from 'Navigation';
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem, Col, Row } from 'reactstrap';
import { formatDateTimeFromDotNetFormat } from 'Utilities';

export const ViewClaimantBreadCrumb: React.FC = () => {
  let entity = useEntity();

  let fullName = `${entity.lastName}, ${entity.firstName}`;

  return (
    <Fragment>
      <Breadcrumb tag="nav" listTag="div">
        <BreadcrumbItem>
          <Link to={entitiesSubRouteName(EntitiesSubRoute.Claimants)}>
            Claimants
          </Link>
        </BreadcrumbItem>
        <BreadcrumbItem>{fullName}</BreadcrumbItem>
      </Breadcrumb>
    </Fragment>
  );
};

export const ViewClaimant: React.FC = () => {
  const entity = useEntity();

  let fullName = `${entity.lastName}, ${entity.firstName}`;

  const useStyles = makeStyles({
    root: {
      minWidth: 275,
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
  });

  const classes = useStyles();

  let attorneyAddress = <Fragment>{entity.attorneyAddressLine1}</Fragment>;

  if (entity.attorneyAddressLine2) {
    attorneyAddress = (
      <Fragment>
        {entity.attorneyAddressLine1}, {entity.attorneyAddressLine2}
      </Fragment>
    );
  }
  return (
    <Fragment>
      <PageCard className={classes.root}>
        <PageCardRow>
          <PageCardSection title="Personal Information">
            <Row>
              <Col xs="12" lg="6">
                <span className="field-label data-display">Full Name:</span>
                <span className="field-value">{fullName}</span>
              </Col>
              <Col xs="12" lg="6">
                <span className="field-label data-display">Gender:</span>
                <span className="field-value">{entity.gender}</span>
              </Col>
            </Row>
            <Row>
              <Col xs="12" lg="6">
                <span className="field-label data-display">SSN:</span>
                <span className="field-value">{entity.ssn}</span>
              </Col>
              <Col xs="12" lg="6">
                <span className="field-label data-display">Phone:</span>
                <span className="field-value">{entity.phone}</span>
              </Col>
            </Row>
            <Row>
              <Col xs="12" lg="6">
                <span className="field-label data-display">Date of Birth:</span>
                <span className="field-value">
                  {entity.dateOfBirth
                    ? formatDateTimeFromDotNetFormat(
                        entity.dateOfBirth.toString(),
                        'MM/dd/yyyy'
                      )
                    : ''}
                </span>
              </Col>
              <Col xs="12" lg="6">
                <span className="field-label data-display">Occupation:</span>
                <span className="field-value">{entity.occupation}</span>
              </Col>
            </Row>
            <Row>
              <Col xs="12" lg="6">
                <span className="field-label data-display">Mobile Phone:</span>
                <span className="field-value">{entity.mobilePhone}</span>
              </Col>
            </Row>
            <Row>
              <Col xs="12" lg="6">
                <span className="field-label data-display">Email:</span>
                <span className="field-value">
                  <a href={'mailto:' + entity.email}>{entity.email}</a>
                </span>
              </Col>
              <Col xs="12" lg="6">
                <span className="field-label data-display">Other Phone:</span>
                <span className="field-value">{entity.phoneOther}</span>
              </Col>
            </Row>

            <Row>
              <Col xs="12" lg="6">
                <span className="field-label data-display">Fax:</span>
                <span className="field-value">{entity.fax}</span>
              </Col>
              <Col xs="12" lg="6"></Col>
            </Row>
          </PageCardSection>
        </PageCardRow>
        <PageCardRow>
          <PageCardSection title="Attorney Information">
            <Row>
              <Col xs="12" lg="6">
                <span className="field-label data-display">Attorney Name</span>
                <span className="field-value">{entity.attorneyName}</span>
              </Col>
              <Col xs="12" lg="6">
                <span className="field-label data-display">
                  Attorney Address:
                </span>
                <span className="field-value">
                  <span className="field-value">{attorneyAddress}</span>
                </span>
              </Col>
            </Row>
            <Row>
              <Col xs="12" lg="6">
                <span className="field-label data-display">Attorney City:</span>
                <span className="field-value">{entity.attorneyCity}</span>
              </Col>
              <Col xs="12" lg="6">
                <span className="field-label data-display">
                  Attorney State:
                </span>
                <span className="field-value">
                  {entity.attorneyState?.displayName} (
                  {entity.attorneyState?.value})
                </span>
              </Col>
              <Col xs="12" lg="6">
                <span className="field-label data-display">Attorney Zip:</span>
                <span className="field-value">{entity.attorneyZip}</span>
              </Col>
            </Row>
          </PageCardSection>
        </PageCardRow>
      </PageCard>
    </Fragment>
  );
};
