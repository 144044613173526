import * as React from "react";
import { useSafeStateUpdate } from "Utilities";
import {
  FormProvider,
  FormProviderProps,
  FormActionTypes,
  useFormUpdater,
} from "./FormProvider";

export interface ModalFormState {
  isOpen: boolean;
  toggle: () => void;
  entityId?: string;
  setEntityId: (id: string | undefined) => void;
  setupAdd: () => void;
}

export enum ModalFormActionTypes {
  SetToggle = "setToggle",
  ToggleModal = "toggleModal",
  SetEntityId = "setEntityId",
  SetEntityIdSetter = "setEntityIdSetter",
  SetSetupAdd = "setSetupAddSetter",
}

export type ModalFormAction =
  | { type: ModalFormActionTypes.SetToggle; payload: () => void }
  | { type: ModalFormActionTypes.ToggleModal }
  | { type: ModalFormActionTypes.SetEntityId; payload: string | undefined }
  | {
      type: ModalFormActionTypes.SetEntityIdSetter;
      payload: (id: string | undefined) => void;
    }
  | {
      type: ModalFormActionTypes.SetSetupAdd;
      payload: () => void;
    };
type ModalFormDispatch = (action: ModalFormAction) => void;

const ModalFormStateContext = React.createContext<ModalFormState | undefined>(
  undefined
);
const ModalFormDispatchContext = React.createContext<
  ModalFormDispatch | undefined
>(undefined);

function modalFormReducer(
  state: ModalFormState,
  action: ModalFormAction
): ModalFormState {
  switch (action.type) {
    case ModalFormActionTypes.SetToggle:
      return { ...state, toggle: action.payload };
    case ModalFormActionTypes.ToggleModal:
      return { ...state, isOpen: !state.isOpen };
    case ModalFormActionTypes.SetEntityId:
      return { ...state, entityId: action.payload };
    case ModalFormActionTypes.SetEntityIdSetter:
      return { ...state, setEntityId: action.payload };
    case ModalFormActionTypes.SetSetupAdd:
      return { ...state, setupAdd: action.payload };
    default: {
      throw new Error("Invalid action type");
    }
  }
}

type ModalFormProviderProps = FormProviderProps & {};

export const ModalFormProvider: React.FC<ModalFormProviderProps> = ({
  children,
  formModel: values,
  handleSuccess,
  ...otherProps
}) => {
  const [state, dispatch] = React.useReducer(modalFormReducer, {
    isOpen: false,
    toggle: () => {},
    setEntityId: (id: string | undefined) => {},
    setupAdd: () => {},
  });

  const successToggle = (r: any) => {
    handleSuccess && handleSuccess(r);
    dispatch({ type: ModalFormActionTypes.ToggleModal });
  };

  return (
    <FormProvider
      formModel={values}
      handleSuccess={successToggle}
      {...otherProps}
    >
      <ModalFormProviderContent
        state={state}
        dispatch={dispatch}
        formModel={values}
      >
        {children}
      </ModalFormProviderContent>
    </FormProvider>
  );
};

type ModalFormProviderContentProps = ModalFormProviderProps & {
  state: ModalFormState;
  dispatch: ModalFormDispatch;
};

const ModalFormProviderContent: React.FC<ModalFormProviderContentProps> = ({
  formModel: values,
  state,
  dispatch,
  children,
}) => {
  const sendUpdate = useFormUpdater();

  const toggle = React.useCallback(() => {
    sendUpdate(FormActionTypes.SetValues, values);
    dispatch({ type: ModalFormActionTypes.ToggleModal });
  }, []);

  const setEntityId = React.useCallback((id: string | undefined) => {
    dispatch({ type: ModalFormActionTypes.SetEntityId, payload: id });
  }, []);

  React.useEffect(() => {
    dispatch({
      type: ModalFormActionTypes.SetToggle,
      payload: toggle,
    });
  }, [toggle]);

  React.useEffect(() => {
    dispatch({
      type: ModalFormActionTypes.SetEntityIdSetter,
      payload: setEntityId,
    });
  }, [setEntityId]);

  React.useEffect(() => {
    sendUpdate(FormActionTypes.ClearErrors);
  });

  React.useEffect(() => {
    dispatch({
      type: ModalFormActionTypes.SetSetupAdd,
      payload: () => {
        setEntityId(undefined);
        toggle();
      },
    });
  }, []);

  return (
    <ModalFormStateContext.Provider value={state}>
      <ModalFormDispatchContext.Provider value={dispatch}>
        {children}
      </ModalFormDispatchContext.Provider>
    </ModalFormStateContext.Provider>
  );
};


export const useModalFormState = () => {
  const modalFormStateContext = React.useContext(ModalFormStateContext);
  if (modalFormStateContext === undefined) {
    throw new Error(
      "useModalFormState must be used within a ModalFormProvider"
    );
  }
  return modalFormStateContext;
};

export const useOptionalModalFormState = () => {
  const modalFormStateContext = React.useContext(ModalFormStateContext);
  return modalFormStateContext;
};

export const useModalFormDispatch = () => {
  const modalFormDispatchContext = React.useContext(ModalFormDispatchContext);
  if (modalFormDispatchContext === undefined) {
    throw new Error(
      "useModalFormDispatch must be used within a ModalFormProvider"
    );
  }
  return modalFormDispatchContext;
};

export const useModalFormContext = () => {
  const state = useModalFormState();
  const dispatch = useModalFormDispatch();

  const sendUpdate = React.useCallback(
    (type: ModalFormActionTypes, payload: any) => {
      dispatch({ type, payload });
    },
    [dispatch]
  );

  return [state, sendUpdate] as const;
};

export const useModalFormUpdater = () => {
  const safeStateUpdate = useSafeStateUpdate();
  const dispatch = useModalFormDispatch();

  return React.useCallback(
    (type: ModalFormActionTypes, payload: any) => {
      safeStateUpdate(() => {
        dispatch({ type, payload });
      });
    },
    [dispatch]
  );
};
