import "react-datepicker/dist/react-datepicker.css";
import React from "react";
import {
  NumericTextBox,
  NumericTextBoxProps,
} from "@progress/kendo-react-inputs";

const defaultNumericProps: NumericTextBoxProps = {
  spinners: false,
};

export const NumberInputWrapper: React.FC<NumericTextBoxProps> = ({
  ...allProps
}) => {
  return <NumericTextBox {...defaultNumericProps} {...allProps} />;
};

export const NumberInput: React.FC<NumericTextBoxProps> = ({ ...allProps }) => {
  return <NumberInputWrapper {...allProps} />;
};

export const CurrencyInput: React.FC<NumericTextBoxProps> = ({
  ...allProps
}) => {
  return (
    <NumberInputWrapper placeholder="$0.00" format="c2" min={0} {...allProps} />
  );
};

export const PercentageInput: React.FC<NumericTextBoxProps> = ({
  ...allProps
}) => {
  return (
    <NumberInputWrapper format="p" min={0} max={1} step={0.1} {...allProps} />
  );
};
