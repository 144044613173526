import {
  GridCellProps,
  GridColumn as Column,
} from '@progress/kendo-react-grid';
import {
  AdminSubHeaderMainLayout,
  CollapsibleAuditLog,
} from 'Components/Display';
import { AddButton } from 'Components/Form';
import { EditDeleteCell, MainGrid, GridButtonSection } from 'Components/Grid';
import { useEnums } from 'Context';
import { Permission } from 'Models/Templates/Permission/Permission';
import { addEditRoute, AddEditSubRoute } from 'Navigation';
import React from 'react';
import { ENDPOINTS } from 'Models/Templates/Routes/Routes';
import { defaultGridProps } from 'Models/GridModels';
import { SecureComponent } from 'Components/Security';

export const UserManagementRoles: React.FC = () => {
  const { entityEventType } = useEnums();

  return (
    <AdminSubHeaderMainLayout title="Roles">
      <SecureComponent permissions={[Permission.AddRole]}>
        <GridButtonSection>
          <AddButton url={addEditRoute(AddEditSubRoute.Role)}>
            New Role
          </AddButton>
        </GridButtonSection>
      </SecureComponent>
      <MainGrid readEndpoint={ENDPOINTS.roles.read} {...defaultGridProps}>
        <Column field="name" title="Name" />
        <Column field="id" title=" " cell={ActionsCell} />
      </MainGrid>
      <CollapsibleAuditLog
        title="Audit Log"
        entityEventType={entityEventType.Role?.value}
      />
    </AdminSubHeaderMainLayout>
  );
};

const ActionsCell: React.FC<GridCellProps> = (props: GridCellProps) => {
  return (
    <EditDeleteCell
      editRoute={addEditRoute(AddEditSubRoute.Role)}
      editPermissions={[Permission.EditRole]}
      deletePermissions={[Permission.DeleteRole]}
      deleteEndpoint={ENDPOINTS.roles.base}
      {...props}
    />
  );
};
