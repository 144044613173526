import React from 'react';
import { Redirect } from 'react-router-dom';
import { useRedirect } from './RedirectConfiguration';

export const RedirectHandler: React.FC = ({ children }) => {
  const { redirect } = useRedirect();
  if (redirect) {
    return <Redirect to={redirect} />;
  }
  return <>{children}</>;
};
