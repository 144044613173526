import React from 'react';
import { ActionsDropdownConfig } from 'Models';
import {
  GridActionsDropdown,
  GridActionsDropdownSpecificProps,
} from 'Components/Display';
import { GridCellProps } from '@progress/kendo-react-grid';

type ActionsEllipsisCellProps = Partial<GridCellProps> &
  GridActionsDropdownSpecificProps & {
    actionsConfig: ActionsDropdownConfig;
  };

export const ActionsEllipsisCell: React.FC<ActionsEllipsisCellProps> = ({
  actionsConfig,
  ...gridCellProps
}) => {
  return (
    <td>
      <GridActionsDropdown
        buttonWrapperClass="flex-end full-width"
        {...gridCellProps}
        {...actionsConfig}
      />
    </td>
  );
};
