import React, { KeyboardEvent, useEffect, useState } from 'react';
import MaskedInput from 'react-text-mask';
import {
  dateMask,
  toStandardDateString,
  isNullEmptyOrUndefined,
  compareDates,
} from 'Utilities';
import {
  Calendar,
  CalendarProps as KendoCalendarProps,
} from '@progress/kendo-react-dateinputs';
import {
  DateTimePickerProps,
  PickerType,
  PickerWrapper,
} from './PickerWrapper';
import { Keys } from 'Models';

export type DatePickerProps = DateTimePickerProps & {
  calendarProps?: KendoCalendarProps;
  isEndDate?: boolean;
  isNowTime?: boolean;
};

export const DatePicker: React.FC<DatePickerProps> = ({
  value,
  onChange,
  calendarProps,
  isEndDate,
  isNowTime,
  ...divProps
}) => {
  const [maskedValue, setMaskedValue] = useState<string>(
    toStandardDateString(value)
  );

  const handleKeyUp = (e: KeyboardEvent<HTMLInputElement>) => {
    const { key } = e;

    switch (key) {
      case Keys.Backspace:
      case Keys.Delete:
        if (isNullEmptyOrUndefined(e.currentTarget.value)) {
          setMaskedValue(e.currentTarget.value);
        }
        break;
    }
  };

  useEffect(() => {
    var isValidDateFormatOrEmpty =
      (maskedValue && !maskedValue.includes('_')) ||
      isNullEmptyOrUndefined(maskedValue);

    var dateFromMask = maskedValue ? new Date(maskedValue) : null;
    if (isEndDate) {
      dateFromMask?.setHours(23, 59, 59);
    }

    if (isValidDateFormatOrEmpty && !compareDates(value, dateFromMask)) {
      onChange(dateFromMask);
    }
  }, [maskedValue]);

  useEffect(() => {
    const standardDateStringValue = toStandardDateString(value);
    if (standardDateStringValue !== maskedValue) {
      setMaskedValue(standardDateStringValue);
    }
  }, [value]);

  return (
    <PickerWrapper
      {...divProps}
      type={PickerType.Date}
      customInput={
        <MaskedInput
          mask={dateMask}
          value={maskedValue}
          onKeyUp={handleKeyUp}
          onChange={(e) => { console.log(e.target.value);setMaskedValue(e.target.value)}}
          placeholder="MM/DD/YYYY"
          className="masked-date-input"
        />
      }
      popupProps={{
        style: { padding: 0 },
        contentWrapperClassName: 'animate-slide-down-small',
      }}
      onChange={onChange}
      value={value}
    >
      <Calendar
        focusedDate={value || new Date()}
        value={value}
        onChange={(e) => {
          var trueValue = e.value;
          if (isEndDate) {
            trueValue.setHours(23, 59, 59);
          }
          if (isNowTime) {
            const now = new Date();
            trueValue.setHours(now.getHours(), now.getMinutes(), now.getSeconds(), now.getMilliseconds());
          }
          onChange(trueValue);
        }}
        className="popup-calendar"
        min={new Date(1800, 1, 1)}
        max={new Date(2500, 1, 1)}
        smoothScroll={false}
        {...calendarProps}
      />
    </PickerWrapper>
  );
};
