import {
  DropdownArrowIcon,
  DropdownArrowIconUp,
  Render,
} from 'Components/Display';
import { NavConfigItem, ROUTES, useGetSecureSubNavItems } from 'Navigation';
import React, { useEffect, useMemo, useState } from 'react';
import { Accordion, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';
import { fullClassName, useHasPermissions, useToggler } from 'Utilities';
import './NavigationPillBar.scss';

export type NavigationPillProps = NavConfigItem &
  React.HTMLProps<HTMLDivElement>;

export const NavigationPill: React.FC<NavigationPillProps> = ({
  route,
  fullRoute,
  active,
  ...otherProps
}) => {
  const [dropdownItems, setDropdownItems] = useState<NavConfigItem[]>([]);
  const [hasSubRoutes, setHasSubRoutes] = useState<boolean>(false);
  const getSubNavItems = useGetSecureSubNavItems(undefined, true);
  const hasPermissions = useHasPermissions();
  const typedRoute: ROUTES = useMemo(() => route as ROUTES, [route]);
  useEffect(() => {
    if (typedRoute) {
      var unfilteredItems = getSubNavItems(typedRoute);
      setHasSubRoutes(unfilteredItems.length > 0);
      var items = unfilteredItems.filter(
        (x) => x.showInDropdown && hasPermissions(x.permissions)
      );
      setDropdownItems(items.length > 0 ? items : []);
    }
  }, [typedRoute]);

  return (
    <Render condition={!(hasSubRoutes && dropdownItems.length === 0)}>
      {dropdownItems.length > 0 ? (
        <NavPillAccordion
          dropdownItems={dropdownItems}
          route={route}
          {...otherProps}
        />
      ) : (
        <Link to={fullRoute || ROUTES.UNAUTHORIZED} className="full-height">
          <div className={'navbar__sidebar__item animate-slide-in'}>
            <NavPillContent {...otherProps} />
          </div>
        </Link>
      )}
    </Render>
  );
};

type NavPillContentProps = {
  label: string;
  icon?: React.ReactNode;
  isDropdown?: boolean;
};

const NavPillContent: React.FC<NavPillContentProps> = ({
  icon,
  label,
  isDropdown,
}) => {
  return (
    <div
      className={fullClassName(
        'nav-label-container flex-vertical-center header-size-tiny'
      )}
    >
      <div className="navbar__sidebar__item--icon">{icon}</div>
      {label}
      <Render condition={isDropdown}>
        <DropdownArrowIcon className="dropdown-arrow" />
      </Render>
    </div>
  );
};

type NavPillDropdownProps = NavigationPillProps & {
  dropdownItems: NavConfigItem[];
};

export const NavPillDropdown: React.FC<NavPillDropdownProps> = ({
  label,
  icon,
  route,
  dropdownItems,
}) => {
  const [isDropdownOpen, toggleDropdownOpen] = useToggler(false);

  return (
    <Dropdown
      className="navpill__wrapper flex-vertical-center"
      onMouseEnter={toggleDropdownOpen}
      onMouseLeave={toggleDropdownOpen}
      isOpen={isDropdownOpen}
      toggle={() => {}}
    >
      <DropdownToggle onClick={(e) => e.preventDefault()}>
        <NavPillContent
          label={label}
          icon={icon}
          isDropdown={dropdownItems && dropdownItems.length > 0}
        />
      </DropdownToggle>
      <DropdownMenu>
        {dropdownItems.map((item, index) => (
          <Link
            to={
              item.fullRoute ? `${route}${item.fullRoute}` : ROUTES.UNAUTHORIZED
            }
            key={index}
          >
            <DropdownItem className="flex-vertical-center">
              <div className="dropdown-icon">{item.icon}</div>
              {item.label}
            </DropdownItem>
          </Link>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
};

export const NavPillAccordion: React.FC<NavPillDropdownProps> = ({
  label,
  icon,
  route,
  dropdownItems,
}) => {
  const [isDropdownOpen, toggleDropdownOpen] = useToggler(false);
  return (
    <div className={'sidebar-menu'}>
      <Accordion>
        <Card>
          <Accordion.Toggle
            as={Card.Header}
            className={'navbar__sidebar__item animate-slide-in'}
            eventKey={label}
            onClick={toggleDropdownOpen}
          >
            <div>
              <div className="navbar__sidebar__item--icon">{icon}</div>
              {label}
              <Render condition={dropdownItems && dropdownItems.length > 0}>
                {isDropdownOpen ? (
                  <DropdownArrowIconUp className="dropdown-arrow" />
                ) : (
                  <DropdownArrowIcon className="dropdown-arrow" />
                )}
              </Render>
            </div>
          </Accordion.Toggle>
          {dropdownItems.map((item, index) => (
            <Link
              to={
                item.fullRoute
                  ? `${route}${item.fullRoute}`
                  : ROUTES.UNAUTHORIZED
              }
              key={index}
            >
              <Accordion.Collapse eventKey={label}>
                <Card.Body className={'navbar__sidebar__item animate-slide-in'}>
                  <NavPillContent label={item.label} icon={item.icon} />
                </Card.Body>
              </Accordion.Collapse>
            </Link>
          ))}
        </Card>
      </Accordion>
    </div>
  );
};
