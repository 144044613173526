import { Tooltip } from '@progress/kendo-react-tooltip';
import {
  AsyncLoadingSection,
  ConfirmationModal,
  ModalDrawerButtonRow,
  ModalFormDrawer,
  Render,
} from 'Components/Display';
import {
  ModalFormCancelButton,
  FormSubmitButton,
  SmartInputBlock,
  SecondaryButton,
} from 'Components/Form';
import { SecureComponent } from 'Components/Security';
import {
  useEntity,
  useModalFormState,
  useFormUpdater,
  FormActionTypes,
  useEntityContext,
  EntityActionTypes,
  useFormStateValue,
  useToasts,
  FormattedToast,
} from 'Context';
import { ColSize, InputType, Referral, ToastType } from 'Models';
import { Permission } from 'Models/Templates/Permission/Permission';
import { ENDPOINTS } from 'Models/Templates/Routes/Routes';
import React, { Fragment, useEffect } from 'react';
import {
  useDataFetcher,
  buildEndpoint,
  useToggler,
  useDataPoster,
} from 'Utilities';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast, ToastOptions } from 'react-toastify';

export const CloseReferralDialog: React.FC = () => {
  const entity = useEntity();

  const { toggle, setEntityId } = useModalFormState();

  const caseStatus = entity.caseStatus?.displayName;

  let allReviewed = entity.appointment?.appointmentReportBundles?.every(
    (report: any) => report.reviewed
  );
  let warningIndicator = allReviewed ? '' : 'warning';
  let missingReviews = allReviewed ? '' : 'Some reports have not been reviewed';
  const [toggler, setToggler] = useToggler();
  return (
    <Fragment>
      <Render condition={caseStatus != 'Closed'}>
        <Tooltip openDelay={100} position="bottom" anchorElement="target">
          <SecureComponent permissions={[Permission.CloseReferral]}>
            <SecondaryButton
              title={missingReviews}
              className="d-inline float-right buttons-container-button"
              onClick={toggle}
              icon={warningIndicator}
            >
              {' '}
              Complete Case
            </SecondaryButton>
          </SecureComponent>
        </Tooltip>
        <ModalFormDrawer width={500} title="Close Case">
          <CloseReferralDrawerContent />
        </ModalFormDrawer>
      </Render>
      <Render condition={caseStatus == 'Closed'}>
        <SecureComponent permissions={[Permission.ReopenReferral]}>
          <SecondaryButton
            title={missingReviews}
            className="d-inline float-right buttons-container-button"
            onClick={setToggler}
            icon={warningIndicator}
          >
            {' '}
            Reopen Case
          </SecondaryButton>

          <ConfirmReopenDialog isOpen={toggler} toggler={setToggler} />
        </SecureComponent>
      </Render>
    </Fragment>
  );
};

const ConfirmReopenDialog: React.FC<{
  isOpen: boolean;
  toggler: () => void;
}> = ({ isOpen, toggler }) => {
  const [ent, dispatcher] = useEntityContext();
  const entity = useEntity();

  const onSuccess = (response: any) => {
    ent.caseStatus = { displayName: 'Reopened', value: 3 };
    ent.appointment.appointmentStatus = { displayName: 'Re-opened', value: 4 };
    dispatcher({ type: EntityActionTypes.SetEntity, payload: ent });
  };

  const [postData] = useDataPoster(
    buildEndpoint(ENDPOINTS.referrals.endpointString('reopenReferral')),
    onSuccess
  );

  const onConfirm = async () => {
    postData({ id: entity.id });
  };

  return (
    <ConfirmationModal
      toggle={toggler}
      isOpen={isOpen}
      confirmText="Are you sure that you want to reopen the case? This will reset its status"
      onConfirm={onConfirm}
    ></ConfirmationModal>
  );
};

const CloseReferralDrawerContent: React.FC = () => {
  const { entityId, isOpen } = useModalFormState();

  const sendUpdate = useFormUpdater();
  const entity = useEntity();
  const closedDate = useFormStateValue('closedDate');
  const reviewed = useFormStateValue('reviewed');
  const systemToast = useToasts();
  const [ent, dispatcher] = useEntityContext();

  const addOrEditEndpoint = buildEndpoint(
    ENDPOINTS.referrals.endpointString('CloseReferral')
  );

  let [referralDocumentModel, fetchStatus] = useDataFetcher<Referral>(
    buildEndpoint(
      ENDPOINTS.referrals.endpointString('getCloseCommandQuery'),
      entity.id
    ),
    new Referral(),
    ({ data }) => data,
    undefined,
    isOpen
  );

  const onFormSubmit = (data?: any, handler?: any) => {
    handler(data);
    ent.caseStatus = { displayName: 'Closed', value: 2 };
    ent.appointment.appointmentStatus = { value: 5, displayName: 'Closed' };
    ent.closedDate = closedDate;
    ent.appointment.reviewed = reviewed;
    dispatcher({ type: EntityActionTypes.SetEntity, payload: ent });
  };

  const handleError = (data: { Errors: string; Value: string }) => {
    if (data.Errors) {
      if (data.Errors.startsWith('Validation Error: At least')) {
        toast.error(
          <div className="font-weight-bold">
            The case could not be closed because a suitable report could not be
            found that meets the validation criteria:
            <div className="mt-2 font-weight-normal">
              <ul>
                <li>
                  Has at least one report marked as reviewed, and with the
                  'Reviewed By' field filled in
                </li>
                <li>
                  The same report has the determination, history of condition,
                  conclusion, source criteria, citation, citation guidelines and
                  guideline filled in
                </li>
              </ul>
            </div>
          </div>,
          { autoClose: 10000 }
        );
      } else {
        systemToast.error(data.Errors);
      }
    }
  };
  useEffect(() => {
    sendUpdate(FormActionTypes.SetValues, referralDocumentModel);
  }, [referralDocumentModel, sendUpdate]);

  return (
    <AsyncLoadingSection loadingStatus={fetchStatus} noIdleLoading>
      <SmartInputBlock
        name="closedDate"
        label="Close Date"
        type={InputType.DatePicker}
        colProps={{ xs: ColSize.Half }}
      />
      <SmartInputBlock
        name="reviewed"
        label="Case Reviewed"
        type={InputType.Checkbox}
        colProps={{ xs: ColSize.Half }}
      />
      <ModalDrawerButtonRow>
        <ModalFormCancelButton />
        <FormSubmitButton
          alternateEndpoint={addOrEditEndpoint}
          alternateHandleSuccess={onFormSubmit}
          alternateHandleError={handleError}
        >
          Close Case
        </FormSubmitButton>
      </ModalDrawerButtonRow>
    </AsyncLoadingSection>
  );
};
