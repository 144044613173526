import {
  AsyncLoadingSection,
  SingleSectionPageCard,
  TaskPageHeaderLayout,
} from 'Components/Display';
import { SmartInputBlock } from 'Components/Form';
import { useFormState } from 'Context';
import { useEnums } from 'Context/EnumerationProvider';
import { Appointment } from 'Models/AppointmentModels';
import { InputType } from 'Models/FormModels';
import { ENDPOINTS } from 'Models/Templates/Routes/Routes';
import React, { Fragment, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Row } from 'reactstrap';
import {
  useDataFetcher,
  addEditTitle,
  useIdParam,
  useAddEditSuccessHandler,
  defaultDataTransform,
  buildQueryUrl,
  buildEndpoint,
} from 'Utilities';

export const AddEditAppointment: React.FC = () => {
  const entityName = 'Appointment';
  const [id, isAdd] = useIdParam();
  const { referralid } = useParams<any>();

  const handleSuccess = useAddEditSuccessHandler(entityName, isAdd);
  const [data, loadingStatus] = useDataFetcher<Appointment>(
    buildQueryUrl(buildEndpoint(ENDPOINTS.appointments.get), {
      id: id || '',
      referralId: referralid,
    }),
    new Appointment(),
    defaultDataTransform,
    Appointment
  );

  return (
    <AsyncLoadingSection loadingStatus={loadingStatus} useMask>
      <TaskPageHeaderLayout
        title={addEditTitle(entityName, isAdd)}
        formProviderProps={{
          formModel: data,
          endpoint: ENDPOINTS.appointments.base,
          handleSuccess: handleSuccess,
        }}
      >
        <AppointmentsAddEditFormContent />
      </TaskPageHeaderLayout>
    </AsyncLoadingSection>
  );
};

const AppointmentsAddEditFormContent: React.FC = () => {
  const { appointmentStatus } = useEnums();
  const { formValues } = useFormState();	

  return (
    <Fragment>
      <SingleSectionPageCard cardTitle="Details" centerContent inputSection>
        <Row>
          <SmartInputBlock
            name="writer"
            label="Writer"
            type={InputType.AsyncDropDown}
            useQuery
            otherparams={{ isWriter: true }}
            readEndpoint={ENDPOINTS.users.dropdownItems}
          />
          <SmartInputBlock
            type={InputType.DatePicker}
            name="appointmentDate"
            label="Appointment Date"
            required
          />
          <SmartInputBlock
            type={InputType.TimePicker}
            name="appointmentDate"
            label="Appointment Time"
            required
          />
          <SmartInputBlock
            name="professional"
            label="Professional"
            type={InputType.AsyncDropDown}
            useQuery
            otherparams={{ isReviewer: true }}
            readEndpoint={ENDPOINTS.users.dropdownItems}
            required
          />
          <SmartInputBlock
            name="appointmentStatus"
            label="Appointment Status"
            type={InputType.EnumDropdown}
            data={appointmentStatus}
            required
          />
        </Row>
        <Row>
        <SmartInputBlock
            type={InputType.DatePicker}
            name="reportDueDate"
            label="Due Date From Reviewer"
            required
          />
          <SmartInputBlock
            type={InputType.TimePicker}
            name="reportDueDate"
            label="Due Time From Reviewer"
            required
          />
          { formValues?.appointmentStatus?.displayName === 'Pending Medicals' && (
            <SmartInputBlock
              type={InputType.DatePicker}
              name="checkMedicalsDate"
              label="Check Medicals Date"
              required
            />
          )}
        </Row>
      </SingleSectionPageCard>
    </Fragment>
  );
};
