import { NamedCoreEnum } from './SharedModels';
import { FileModel } from './FileModels';

export class Reviewer {
    id?: string;
    firstName?: string;
    lastName?: string;
    licenseType?: NamedCoreEnum;
    primarySpecialty?: NamedCoreEnum;
    secondarySpecialty?: NamedCoreEnum;
    interestedProducts?: ReviewerProduct[];
    preferredMeansOfContact?: NamedCoreEnum;
    address?: ReviewerAddress;
    imeLocations?: ImeLocation[];
    contactNumber?: string;
    mobileNumber?: string;
    reviewersEmail?: string;
    faxNumber?: string;
    contactPerson?: string;
    contactPersonEmail?: string;
    note?: string;
    converted?: boolean
}

export class ReviewerProduct{
    id?: string;
    interestedProduct?: NamedCoreEnum;
    price?: number;
    quantity?: number;
}

export class ReviewerAddress{
    id?: string;
    line1?: string;
    line2?: string;
    city?: string;
    state?: NamedCoreEnum;
    zip?: string;
    fullAddressLine?: string;
}

export class ImeLocation{
    id?: string;
    line1?: string;
    line2?: string;
    city?: string;
    state?: NamedCoreEnum;
    zip?: string;
    fullAddressLine?: string;
}