import "react-datepicker/dist/react-datepicker.css";
import React from "react";
import {
  MaskedTextBoxProps,
} from "@progress/kendo-react-inputs";
import { CustomMaskedTextBox } from "./CustomMaskedTextBox";

export const SSNInput: React.FC<MaskedTextBoxProps> = ({
  mask,
  placeholder,
  ...allProps
}) => {
  return (
    <CustomMaskedTextBox
      mask="000-00-0000"
      {...allProps}
    />
  );
};
