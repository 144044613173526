import React from "react";
import { getValueFromChangeEvent, useFilterBarValue } from "Context";
import { IFilterConfig, InputType } from "Models";
import { InputBlock } from "Components/Form";
import { DropdownFilterWrapper } from "./DropdownFilterWrapper";

export const RadioEnumFilter: React.FC<IFilterConfig> = ({
  name,
  inputProps,
  ...filterProps
}) => {
  if (inputProps === undefined || inputProps?.data === undefined) {
    throw new Error(
      "Must pass a NamedCoreEnum as data prop in inputProps of FilterConfig"
    );
  }

  const [value, setValue] = useFilterBarValue(name);

  return (
    <DropdownFilterWrapper name={name} {...filterProps}>
      <div className="p-small">
        <InputBlock
          name={name}
          type={InputType.EnumRadioGroup}
          value={value}
          onChange={(e: any) =>
            setValue(getValueFromChangeEvent(e, InputType.EnumRadioGroup))
          }
          noPaddingMargin
          {...inputProps}
        />
      </div>
    </DropdownFilterWrapper>
  );
};
