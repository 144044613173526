import {
  GridCellProps,
  GridColumn as Column,
} from '@progress/kendo-react-grid';
import { PageCard, PageCardRow, PageCardSection } from 'Components/Display';
import {
  FormSubmitButton,
  PrimaryButton,
  SecondaryButton,
  SmartInputBlock,
} from 'Components/Form';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { DateCell, MainGrid } from 'Components/Grid';
import {
  FormProvider,
  GridProvider,
  useEnums,
  useFormState,
  useFormStateValue,
  useToasts,
} from 'Context';
import {
  removeAllTabIndicators,
  toggleTabsRequiredIndicator,
} from 'FeatureUtilities/TabUtilities';
import {
  ColSize,
  defaultGridProps,
  DropdownItemModel,
  InputType,
  Reviewer,
} from 'Models';
import { ENDPOINTS } from 'Models/Templates/Routes/Routes';
import {
  addEditRoute,
  AddEditSubRoute,
  NetworkDevelopmentSubRoute,
  ROUTES,
} from 'Navigation';
import React, { useEffect, useState } from 'react';
import { Fade, Tab, Tabs } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import {
  buildEndpoint,
  buildRoute,
  defaultDataTransform,
  toggledClass,
  useConvertReviewerSuccessHandler,
  useDataFetcher,
  useDataPoster,
  useIdParam,
  useToggler,
} from 'Utilities';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { makeStyles } from '@mui/styles';
class ConvertProposals {
  searchKey?: string;
}

export const ReviewerConvertMain: React.FC = () => {
  const [id, isAdd] = useIdParam();
  const entityName = 'ReferralRequestProposals';
  const proposalSearch = new ConvertProposals();

  const handleSuccess = useConvertReviewerSuccessHandler(entityName, isAdd);
  const history = useHistory();

  const [data, loadingStatus] = useDataFetcher<Reviewer>(
    buildRoute(ENDPOINTS.reviewers.endpointString('getConvertModel'), id),
    new Reviewer(),
    defaultDataTransform,
    Reviewer
  );
  const [showForm, setShowForm] = useToggler(true);

  const cancelAction = () => {
    history.push(
      buildRoute(
        ROUTES.NETWORK_DEVELOPMENT,
        NetworkDevelopmentSubRoute.ReviewersListing
      )
    );
  };

  return (
    <PageCard>
      <FormProvider
        //formModel
        endpoint={ENDPOINTS.reviewers.endpointString('ConvertToUser')}
        formModel={data}
        handleSuccess={handleSuccess}
      >
        <ConvertReviewerForm
          display={!data.converted}
          ReviewerId={id}
          visibilityToggler={setShowForm}
        />
      </FormProvider>
    </PageCard>
  );
};
class ConvertReviewerFormProps {
  display?: boolean;
  ReviewerId?: string;
  visibilityToggler: any;
}

const ConvertReviewerForm: React.FC<ConvertReviewerFormProps> = (
  props: ConvertReviewerFormProps
) => {
  const [key, setKey] = useState('primary');
  const {
    state,
    natureOfEval,
    referralType,
    firstLevelReviewStatus,
    urType,
    gender,
  } = useEnums();

  const dropdownData = [
    { text: 'Standard', value: 'Standard' },
    { text: 'Expedited', value: 'Expedited' },
  ];

  const textField = 'text';
  const dataItemKey = 'value';
  const formState = useFormState();
  const history = useHistory();

  useEffect(() => {
    if (formState.formStatus == 'validationError') {
      removeAllTabIndicators();
      toggleTabsRequiredIndicator();
    }
  }, [formState, removeAllTabIndicators, toggleTabsRequiredIndicator]);

  const useStyles = makeStyles({
    root: {
      marginTop: 'auto',
      marginBottom: '2.1rem',
    },
  });

  const classes = useStyles();

  const cancelAction = () => {
    history.push(
      buildRoute(
        ROUTES.NETWORK_DEVELOPMENT,
        NetworkDevelopmentSubRoute.ReviewersListing
      )
    );
  };

  const newPhysician = () => {
    history.push(addEditRoute(AddEditSubRoute.Physician));
  };

  const [selectedClient, setClient] = useFormStateValue<
    DropdownItemModel | undefined
  >('claim.client');

  return (
    <React.Fragment>
      <PageCardSection
        className={props.display ? '' : 'hidden'}
        title="Convert to User"
      >
        <Row>
          <Col>
            <FormSubmitButton className="float-right">
              Convert to User
            </FormSubmitButton>
            <SecondaryButton
              onClick={cancelAction}
              className="float-right mr-2"
            >
              Cancel
            </SecondaryButton>
          </Col>
        </Row>
        <Row>
          <SmartInputBlock
            name="firstName"
            label="First Name"
            required
            type={InputType.Text}
          />
          <SmartInputBlock
            name="lastName"
            label="Last Name"
            required
            type={InputType.Text}
          />
          <SmartInputBlock
            name="emailAddress"
            label="Email Address"
            required
            type={InputType.Text}
          />
        </Row>
        <Row>
          <SmartInputBlock
            name="username"
            label="Username"
            required
            type={InputType.Text}
          />

          <SmartInputBlock
            name="phoneNumber"
            label="Phone Number"
            type={InputType.PhoneNumber}
          />
        </Row>
      </PageCardSection>
      <PageCardSection
        className={!props.display ? '' : 'hidden'}
        title="Convert to User"
      >
        <Row>User already converted</Row>
      </PageCardSection>
    </React.Fragment>
  );
};
