import "./EventCalendar.scss";
import React, { useEffect } from "react";
import {
  Scheduler,
  MonthView,
  SchedulerProps,
  DayView,
  WeekView,
  SchedulerItemProps,
  SchedulerDateChangeEvent,
  SchedulerViewChangeEvent,
} from "@progress/kendo-react-scheduler";
import { useDataPoster } from "Utilities";
import { AsyncStatus, CalendarEventModel, CalendarViewType } from "Models";
import {
  FilterBarProvider,
  FilterBarProviderProps,
  LocalStateProvider,
  useDebouncedFilterValues,
  useLocalState,
} from "Context";
import { FilterBar } from "Components/Grid";
import { AsyncLoadingSection } from "../LoadingSection";
import {
  CustomWeekDayHeaderCell,
  CustomMonthDateHeaderCell,
  CustomMonthViewSlot,
  EventCalendarHeader,
} from ".";
import { CustomViewItem } from "./EventCalendarItems";

export type EventCalendarLocalState = {
  startDate?: Date;
  endDate?: Date;
  date?: Date;
  view?: string;
  loadingStatus?: AsyncStatus;
};

export type EventCalendarProps = SchedulerProps & {
  popupContent?: React.FC<SchedulerItemProps>;
  viewItemProps?: CustomViewItemProps
};

export const EventCalendar: React.FC<EventCalendarProps> = ({
  children,
  data,
  popupContent,
  defaultView = CalendarViewType.Month,
  viewItemProps,
  ...schedulerProps
}) => {

  return (
    <Scheduler
      {...schedulerProps}
      data={data}
      height="auto"
      header={EventCalendarHeader}
      defaultView={defaultView}
    >
      <DayView
        dateHeaderCell={CustomWeekDayHeaderCell}
        slotDuration={60}
        slotDivisions={4}
        item={(props) => (
          <CustomViewItem popupContent={popupContent} {...props} />
        )}
      />
      <WeekView
        dateHeaderCell={CustomWeekDayHeaderCell}
        slotDuration={60}
        slotDivisions={4}
        item={(props) => (
          <CustomViewItem popupContent={popupContent} {...props} />
        )}
      />
      <MonthView
        dateHeaderCell={CustomMonthDateHeaderCell}
        viewSlot={CustomMonthViewSlot}
        item={(props) => (
          <CustomViewItem popupContent={popupContent} {...props} {...viewItemProps} />
        )}
      />
    </Scheduler>
  );
};

export type AsyncEventCalendarProps = EventCalendarProps & {
  readEndpoint: string;
  filters?: any;
  refreshToggleValue?: boolean;
};

export const AsyncEventCalendar: React.FC<AsyncEventCalendarProps> = ({
  ...asyncEventCalendarProps
}) => {
  const [debouncedFilters] = useDebouncedFilterValues(500);

  return (
    <LocalStateProvider
      initialValue={{ view: CalendarViewType.Month, date: new Date() }}
    >
      <AsyncEventCalendarContent
        filters={debouncedFilters}
        {...asyncEventCalendarProps}
      />
    </LocalStateProvider>
  );
};

export const AsyncEventCalendarContent: React.FC<AsyncEventCalendarProps> = ({
  readEndpoint,
  filters,
  refreshToggleValue,
  ...calendarProps
}) => {
  const [
    { startDate, endDate, date, view },
    setLocalState,
  ] = useLocalState<EventCalendarLocalState>();
  const [getEvents, { response = [], postStatus }] = useDataPoster<
    CalendarEventModel[]
  >(readEndpoint);

  const updateDate = ({ value }: SchedulerDateChangeEvent) => {
    setLocalState((prev) => {
      return { ...prev, date: value };
    });
  };

  const updateView = ({ value }: SchedulerViewChangeEvent) => {
    setLocalState((prev) => {
      return { ...prev, view: value };
    });
  };

  useEffect(() => {
    if (startDate !== undefined && endDate !== undefined) {
      getEvents({ startDate, endDate, ...filters });
    }
  }, [readEndpoint, startDate, filters]);

  useEffect(() => {
    if (refreshToggleValue !== undefined) {
      getEvents({ ...filters, refreshToggleValue });
    }
  }, [readEndpoint, filters, refreshToggleValue ]);

  useEffect(() => {
    setLocalState((prev) => {
      return { ...prev, loadingStatus: postStatus };
    });
  }, [postStatus]);

  return (
    <div className="calendar-wrapper position-relative">
      <AsyncLoadingSection
        loadingStatus={postStatus}
        useMask
        noIdleLoading
        noLabel
      >
        <EventCalendar
          data={
            postStatus === AsyncStatus.Success
              ? response.map(({ start, end, ...e }) => {
                  return { start: new Date(start), end: new Date(end), ...e };
                })
              : []
          }
          defaultDate={date}
          defaultView={view}
          onDateChange={updateDate}
          onViewChange={updateView}
          {...calendarProps}
        />
      </AsyncLoadingSection>
    </div>
  );
};

export type FilterableAsyncEventCalendarProps = AsyncEventCalendarProps & {
  filterBarProps: FilterBarProviderProps;
};

export const FilterableAsyncEventCalendar: React.FC<FilterableAsyncEventCalendarProps> = ({
  filterBarProps,
  ...eventCalendarProps
}) => {
  return (
    <FilterBarProvider {...filterBarProps}>
      <FilterBar />
      <AsyncEventCalendar {...eventCalendarProps} />
    </FilterBarProvider>
  );
};

export type CustomViewItemProps = SchedulerItemProps & {
  popupContent?: React.FC<SchedulerItemProps>;
  hideTimeFrame?: boolean
};
