import { filterBy } from '@progress/kendo-data-query';
import {
  DropDownList,
  DropDownListProps,
} from '@progress/kendo-react-dropdowns';
import {
  CoreEnum,
  defaultDropdownResponseTransform,
  NamedCoreEnum,
} from 'Models';
import { AsyncStatus } from 'Models/SharedModels';
import React, { useEffect, useState } from 'react';
import {
  BLANK_FIELD,
  buildEndpoint,
  buildQueryUrl,
  DEFAULT_DROPDOWN_TEXTFIELD,
  DEFAULT_DROPDOWN_VALUEFIELD,
  fullClassName,
  isArray,
  toggledClass,
  useDataFetcher,
} from 'Utilities';

export const DropdownWrapper: React.FC<
  DataDropDownProps | AsyncDataDropDownProps
> = ({
  textField = DEFAULT_DROPDOWN_TEXTFIELD,
  dataItemKey = DEFAULT_DROPDOWN_VALUEFIELD,
  defaultItem = { [textField]: BLANK_FIELD },
  value = defaultItem,
  data,
  isBool,
  ...allProps
}) => {
  const valueRender = (element: any, value: any) => {
    const hasData = dataItemKey && value && value[dataItemKey];

    const itemChildren = (
      <span className={toggledClass('color-secondary-light', !hasData)}>
        {element.props.children}
      </span>
    );

    return React.cloneElement(element, { ...element.props }, itemChildren);
  };

  const itemRender = (li: any, { dataItem, selected }: any) => {
    const hasData = dataItemKey && dataItem[dataItemKey] !== undefined;
    const defaultItemClass = toggledClass('color-secondary', !hasData);
    const selectedItemClass = toggledClass(
      'color-primary',
      hasData && selected
    );
    const itemChildren = (
      <div
        className={fullClassName(
          'full-width',
          defaultItemClass,
          selectedItemClass
        )}
      >
        {li.props.children}
      </div>
    );

    return React.cloneElement(li, li.props, itemChildren);
  };

  return (
    <div title={value ? value[textField] : ''}>
      {isBool ? (
        <DataDropDownUseValue
          textField={textField}
          dataItemKey={dataItemKey}
          valueField={dataItemKey}
          value={value}
          itemRender={itemRender}
          valueRender={valueRender}
          defaultItem={defaultItem}
          popupSettings={{
            appendTo: document.getElementById('root') || undefined,
          }}
          data={data}
          {...allProps}
        />
      ) : (
        <DropDownList
          textField={textField}
          dataItemKey={dataItemKey}
          itemRender={itemRender}
          valueRender={valueRender}
          value={value}
          defaultItem={defaultItem}
          popupSettings={{
            appendTo: document.getElementById('root') || undefined,
          }}
          data={data}
          {...allProps}
        />
      )}
    </div>
  );
};

export type EnumDropdownProps = DataDropDownProps & {
  useValueAsDisplayName?: boolean;
};

export const EnumDropDown: React.FC<EnumDropdownProps> = ({
  data = {},
  textField,
  dataItemKey,
  useValueAsDisplayName,
  value,
  ...allProps
}) => {
  var dropdownData = Object.values(data as NamedCoreEnum);

  if (useValueAsDisplayName) {
    dropdownData = dropdownData.map(({ value }: CoreEnum) => {
      return { displayName: value, value } as CoreEnum;
    });
  }

  var actualValue =
    dropdownData.find((d) => d.value === (value as CoreEnum)?.value) || value;

  return (
    <DataDropDown
      textField="displayName"
      dataItemKey="value"
      data={dropdownData}
      value={actualValue}
      {...allProps}
    />
  );
};

export const BoolDropDown: React.FC<DataDropDownProps> = ({
  data = {},
  ...allProps
}) => {
  const dropdownData = [
    { text: 'Yes', value: true },
    { text: 'No', value: false },
  ];
  return (
    <DataDropDown
      textField="text"
      dataItemKey="value"
      data={dropdownData}
      {...allProps}
    />
  );
};

export type DataDropDownProps = DropDownListProps & {
  readEndpoint?: string;
  transformResponseData?: (r: any) => any;
  fetchCondition?: boolean;
  isBool?: boolean;
};

export const DataDropDown: React.FC<DataDropDownProps> = ({
  readEndpoint,
  data = [],
  transformResponseData,
  fetchCondition = true,
  filterable,
  ...otherProps
}) => {
  const [dropdownItems, setDropdownItems] = useState<any[]>(data);
  const [filteredDropdownItems, setFilteredDropdownItems] =
    useState<any[]>(data);

  const [dropdownData, fetchStatus] = useDataFetcher(
    readEndpoint!,
    data,
    transformResponseData || defaultDropdownResponseTransform,
    undefined,
    readEndpoint !== undefined && readEndpoint.length > 0 && fetchCondition
  );

  useEffect(() => {
    if (dropdownData !== undefined && isArray(dropdownData)) {
      setDropdownItems(dropdownData);
      setFilteredDropdownItems(dropdownData);
    } else {
      setDropdownItems([]);
      setFilteredDropdownItems([]);
    }
  }, [dropdownData]);

  useEffect(() => {
    if (readEndpoint === undefined) {
      setDropdownItems(data);
      setFilteredDropdownItems(data);
    }
  }, [data]);

  const filterChange = ({ filter }: any) => {
    const items = [...dropdownItems];
    setFilteredDropdownItems(filterBy(items, filter));
  };

  return (
    <DropdownWrapper
      data={filteredDropdownItems}
      onFilterChange={filterChange}
      loading={fetchStatus === AsyncStatus.Pending}
      filterable={filterable || (dropdownData && dropdownData?.length > 10)}
      {...otherProps}
    />
  );
};

export type AsyncDataDropDownProps = DropDownListProps & {
  readEndpoint: string;
  minFilterLength?: number;
  transformResponseData?: (r: any) => any;
  otherparams?: any;
  setFilter?: (filter: string | undefined) => void;
  useQuery?: boolean;
  fetchCondition?: boolean;
  isBool?: boolean;
};

export const AsyncDataDropDown: React.FC<AsyncDataDropDownProps> = ({
  readEndpoint,
  minFilterLength,
  transformResponseData,
  dataItemKey = DEFAULT_DROPDOWN_VALUEFIELD,
  otherparams,
  setFilter,
  filter,
  useQuery,
  fetchCondition = true,
  ...otherProps
}) => {
  const transformDropdownData = (response: any) => {
    var data = transformResponseData
      ? transformResponseData(response)
      : defaultDropdownResponseTransform(response);
    //filters out data that doesn't have our value key set to avoid undefined posts
    if (data && isArray(data)) {
      data = data.filter((x: any) => (dataItemKey && x[dataItemKey]) || x.id);
    }

    return data;
  };

  const [filterValue, setFilterValue] = useState('');
  var filterQueryObj = { filter: filter || filterValue };

  
  var url =
    otherparams && useQuery
      ? buildQueryUrl(readEndpoint, { ...otherparams, ...filterQueryObj })
      : useQuery
      ? buildQueryUrl(
          readEndpoint,
          { filter: filter || filterValue }
        )
      : otherparams ?
        buildQueryUrl(
          buildEndpoint(readEndpoint, filter || filterValue),
          otherparams
        )
      : buildEndpoint(readEndpoint, filter || filterValue);

  const [dropdownData, fetchStatus, { getData }] = useDataFetcher<any[]>(
    url,
    [],
    transformDropdownData,
    undefined,
    false
  );

  const filterChange = (e: any) => {
    if (setFilter) {
      setFilter(e.filter!.value);
    } else {
      setFilterValue(e.filter!.value);
    }
  };

  const listNoDataRender = (element: any) => {
    const noData =
      (filter || filterValue)?.length > 0
        ? 'No Data Found'
        : 'Start typing to search';

    return React.cloneElement(element, { ...element.props }, noData);
  };

  useEffect(() => {
    if (fetchCondition) {
      getData();
    }
  }, [filterValue, filter, fetchCondition]);

  return (
    <DropdownWrapper
      data={dropdownData}
      filterable={true}
      onFilterChange={filterChange}
      loading={fetchStatus === AsyncStatus.Pending}
      dataItemKey={dataItemKey}
      listNoDataRender={listNoDataRender}
      filter={filter || filterValue}
      {...otherProps}
    />
  );
};

const isPresent = (value: any) => {
  return value !== null && value !== undefined;
};

export type DataDropDownUseValueProps = {
  [x: string]: any;
};

export const DataDropDownUseValue: React.FC<DataDropDownUseValueProps> = ({
  ...props
}) => {
  const [component, setComponent] = useState<any>();
  const events = {
    onBlur: (event: any) => triggerEvent('onBlur', event),
    onFocus: (event: any) => triggerEvent('onFocus', event),
    onChange: (event: any) => triggerEvent('onChange', event),
    onPageChange: (event: any) => triggerEvent('onPageChange', event),
    onFilterChange: (event: any) => triggerEvent('onFilterChange', event),
  };

  const getValue = () => {
    if (component) {
      const value = component?.value;
      return isPresent(value) ? value[props.valueField] : value;
    }
  };

  const triggerEvent = (eventType: any, event: any) => {
    if (props[eventType]) {
      props[eventType].call(undefined, {
        ...event,
        value: getValue(),
        target: component,
      });
    }
  };

  const itemFromValue = (value: any) => {
    const { data = [], valueField } = props;
    return isPresent(value)
      ? data.find((item: any) => item[valueField] === value)
      : value;
  };

  return (
    <DropDownList
      {...props}
      value={itemFromValue(getValue())}
      defaultValue={itemFromValue(props.value)}
      ref={(component1: any) => {
        setComponent(component1);
      }}
      {...events}
    />
  );
};
