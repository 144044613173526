import { Address, Client, CoreEnum, User } from '.';
import { NamedCoreEnum } from './SharedModels';

export class ReferralsRequest {
  constructor() {
    this.physician = new Physician();
  }
  id?: string;
  firstName?: string;
  lastName?: string;
  referralNumber?: string;
  referralSource?: User;
  client?: Client;
  appointment?: string;
  typeOfEvaluation?: NamedCoreEnum;
  claimNumber?: string;
  status?: NamedCoreEnum;
  providerRequestingTreatment?: string;
  providerPhone?: string;
  ReviewerSpeciality?: string;
  benefitState?: NamedCoreEnum;
  dueDate?: Date;
  referralDate?: Date;
  dateOfInjury?: Date;
  dateOfBirth?: Date;
  specialInstructions?: string;
  contactAllowed?: boolean;
  firstLevelReviewStatus?: NamedCoreEnum;
  sendTo?: NamedCoreEnum;
  physician?: Physician;
}

export class Physician {
  constructor() {
    this.addresses = [];
  }
  id?: string;
  physicianSpeciality?: CoreEnum;
  practiceName?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  gender?: CoreEnum;
  phone?: string;
  mobilePhone?: string;
  fax?: string;
  phoneOther?: string;
  addresses: PhysicianAddress[];
}

export class PhysicianAddress {
  constructor() {
    this.address = new Address();
  }
  id?: string;
  physicianId?: string;
  address?: Address;
}
