import { Address, Client, CoreEnum, Physician, User } from '.';
import { NamedCoreEnum } from './SharedModels';

export class ReferralsRequestCommercial {
  constructor() {
    this.physician = new Physician();
  }
  id?: string;
  firstName?: string;
  lastName?: string;
  referralNumber?: string;
  referralSource?: User;
  client?: Client;
  lineOfBusiness?: CoreEnum;
  service?: CoreEnum;
  appointment?: string;
  claimNumber?: string;
  status?: NamedCoreEnum;
  ReviewerSpeciality?: string;
  benefitState?: NamedCoreEnum;
  dueDate?: Date;
  tat?: NamedCoreEnum;
  referralDate?: Date;
  dateOfInjury?: Date;
  dateOfBirth?: Date;
  specialInstructions?: string;
  apName?: string;
  apPhoneNumber?: string;
  apFaxNumber?: string;
  apSpecialty?: string;
  apContact?: NamedCoreEnum;
  timeFrame?: NamedCoreEnum;
  physician?: Physician;
  jurisdictionalMatch?: boolean;
  coSignOk?: boolean;
  referralQuestions?: Array<ReferralQuestion>
}

export type ReferralQuestion = {
  value: string
}