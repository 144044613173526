import { useEntity } from 'Context';
import { ROUTES, entitiesSubRouteName, EntitiesSubRoute } from 'Navigation';
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';

export const ViewReferralsRequestBreadcrumbs: React.FC = () => {
  const entity = useEntity();
  return (
    <Fragment>
      <Breadcrumb tag="nav" listTag="div">
        <BreadcrumbItem>
          <Link to={ROUTES.DASHBOARD}>Home</Link>
        </BreadcrumbItem>
        <BreadcrumbItem
          href={entitiesSubRouteName(EntitiesSubRoute.ReferralsRequests)}
        >
          <Link to={entitiesSubRouteName(EntitiesSubRoute.ReferralsRequests)}>
            Referrals Requests
          </Link>
        </BreadcrumbItem>
        <BreadcrumbItem>{entity.name}</BreadcrumbItem>
      </Breadcrumb>
    </Fragment>
  );
};
