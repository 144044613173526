import './DisplayBlock.scss';
import { isArray } from 'lodash';
import React from 'react';
import { Col, ColProps } from 'reactstrap';
import { fullClassName, toggledClass, Widths } from 'Utilities';
import { CollapsibleTextHeaderSection } from './CollapsibleSection';
import { Render } from './Render';
import { Link } from 'react-router-dom';

export type DisplayBlockProps =
  | Partial<ColProps>
  | {
      title: string;
      value: any;
      render?: (field: any) => any;
      useCol?: boolean;
      useRightMargin?: boolean;
      blurredOut?: boolean;
      width?: Widths;
      linkRoute?: string;
      alignWithInputs?: boolean;
    };

export const DisplayBlock: React.FC<DisplayBlockProps> = ({
  title,
  value,
  render,
  useCol = true,
  useRightMargin,
  blurredOut,
  width,
  linkRoute,
  alignWithInputs,
  ...colProps
}) => {
  const Wrapper = useCol && width === undefined ? Col : DivWrapper;
  const isEmptyValue =
    value !== 0 && (!value || (isArray(value) && value.length === 0));
  const isEmptyLinkRoute =
    linkRoute !== 0 &&
    (!linkRoute || (isArray(linkRoute) && linkRoute.length === 0));
  const fieldValue =
    isEmptyLinkRoute || isEmptyValue ? (
      value
    ) : (
      <LinkField value={value} linkRoute={linkRoute} />
    );

  return (
    <Wrapper
      className={fullClassName(
        'flex-column display-block',
        toggledClass('mr-3', useRightMargin),
        toggledClass('input-aligned', alignWithInputs)
      )}
      style={{ width }}
      {...colProps}
    >
      <div className="field-label">{title}</div>
      <div
        className={fullClassName(
          'field-value',
          toggledClass('color-secondary-light', isEmptyValue),
          blurredOut ? 'blur' : ''
        )}
      >
        {!isEmptyValue ? (render ? render(fieldValue) : fieldValue) : '—'}
      </div>
    </Wrapper>
  );
};

const LinkField: React.FC<{ value: string; linkRoute: string }> = ({
  value,
  linkRoute = '',
}) => {
  return <Link to={linkRoute}>{value}</Link>;
};

type DisplayListBlockProps =
  | Partial<ColProps>
  | {
      title: string;
      values?: string[];
      useCol?: boolean;
      lengthLimit?: number;
      pluralLabel?: string;
    };

export const DisplayListBlock: React.FC<DisplayListBlockProps> = ({
  values = [],
  lengthLimit,
  pluralLabel = '',
  ...displayBlockProps
}) => {
  const realValues: string[] = values.filter(
    (x: string) => x !== undefined && x.length > 0
  );
  const shownList = lengthLimit ? realValues.slice(0, lengthLimit) : realValues;
  const hiddenList = lengthLimit ? realValues.slice(lengthLimit) : [];
  const showText = pluralLabel
    ? `Show all ${realValues.length} ${pluralLabel}`
    : `Show all ${realValues.length}`;

  return (
    <DisplayBlock
      value={realValues}
      render={(list: string[]) => {
        return (
          <div className="flex-column">
            {shownList.map((val: string, index: number) => (
              <div className="mb-1" key={index}>
                {val}
              </div>
            ))}
            <Render
              condition={
                lengthLimit !== undefined && values.length > lengthLimit
              }
            >
              <CollapsibleTextHeaderSection
                className="mt-1"
                customShowText={showText}
                customHideText="Hide others"
                hideArrow
                defaultClosed
                noPaddingMargin
              >
                <div className="flex-column">
                  {hiddenList.map((val: string, index: number) => (
                    <div className="mb-1" key={index}>
                      {val}
                    </div>
                  ))}
                </div>
              </CollapsibleTextHeaderSection>
            </Render>
          </div>
        );
      }}
      {...displayBlockProps}
    />
  );
};

const DivWrapper: React.FC<any> = ({ children, ...otherProps }) => {
  return <div {...otherProps}>{children}</div>;
};
