import './InputBlock.scss';
import React, { Fragment, HTMLProps, useEffect, useRef, useState } from 'react';
import { InputType, INPUT_MAP } from 'Models/FormModels';
import { ErrorDisplay, Render } from 'Components/Display';
import { fullClassName, getTargetFromEvent, toggledClass } from 'Utilities';
import { InputBlockProps } from './InputBlock';
import { LabelRequired } from '.';

/*
  InputTypeRenderer
*/
export const InputTypeRenderer: React.FC<InputBlockProps> = ({
  label,
  type,
  labelProps,
  errors = [],
  hidden,
  value,
  required,
  className,
  rightFloatingLabel,
  ...otherProps
}) => {
  const Input = INPUT_MAP[type] || INPUT_MAP[InputType.Text];

  if (hidden) {
    return <Input type="hidden" {...otherProps} />;
  }

  switch (type) {
    case InputType.Checkbox:
      return (
        <Fragment>
          <InputWrapper
            type={type}
            label={label}
            value={value}
            errors={errors}
            className={fullClassName(className)}
            {...otherProps}
          />
          <ErrorDisplay errors={errors} />
        </Fragment>
      );
    default:
      return (
        <Fragment>
          <DefaultInputLabel
            label={label}
            required={required}
            {...labelProps}
          />
          <div className="position-relative">
            <InputWrapper
              type={type}
              value={value}
              errors={errors}
              className={className}
              {...otherProps}
            />
            {rightFloatingLabel && (
              <div
                className="position-absolute full-height flex-vertical-center pr-small"
                style={{ right: 0, bottom: 0, lineHeight: '21px' }}
              >
                {rightFloatingLabel}
              </div>
            )}
          </div>
          <ErrorDisplay errors={errors} />
        </Fragment>
      );
  }
};

/*
  DefaultInputLabel
*/
export type DefaultInputLabelProps = Partial<HTMLProps<HTMLDivElement>> & {
  label?: string;
  className?: string;
  required?: boolean;
};

export const DefaultInputLabel: React.FC<DefaultInputLabelProps> = ({
  label,
  className = '',
  required,
  ...otherProps
}) => {
  return (
    <Render condition={label !== undefined}>
      <div className={fullClassName('field-label', className)} {...otherProps}>
        {label}
        {required && <LabelRequired />}
      </div>
    </Render>
  );
};

const InputWrapper: React.FC<InputBlockProps> = ({
  type,
  className,
  errors,
  onFocus,
  onBlur,
  onChange,
  value,
  ...allProps
}) => {
  const Input = INPUT_MAP[type] || INPUT_MAP[InputType.Text];
  const [focused, setFocused] = useState(false);
  const handlesOwnFocus =
    type === InputType.OldDatePicker ||
    type === InputType.DateTimePicker ||
    type === InputType.OldTimePicker ||
    type === InputType.DatePicker ||
    type === InputType.TimePicker;

  const cursorPos = useRef<number>(0);
  var inputRef = useRef<any>(null);

  const handleChange = (e: any) => {
    cursorPos.current = e?.nativeEvent?.target?.selectionStart;
    onChange && onChange(e);
  };

  const handleFocus = (e: any) => {
    onFocus && onFocus(e);
    inputRef.current = getTargetFromEvent(e, type);
  };

  useEffect(() => {
    if (inputRef && inputRef.current) {
      inputRef.current.selectionStart = cursorPos.current;
      inputRef.current.selectionEnd = cursorPos.current;
    }
  }, [value]);

  return (
    <Input
      onFocus={(e: any) => {
        handleFocus(e);
        setFocused(true);
      }}
      onBlur={(e: any) => {
        onBlur && onBlur(e);
        setFocused(false);
      }}
      value={value}
      onChange={handleChange}
      className={fullClassName(
        className,
        toggledClass('with-error', errors && errors.length > 0),
        toggledClass('field-value', type !== InputType.Checkbox),
        toggledClass('focused', focused && !handlesOwnFocus),
        toggledClass('handles-focus', handlesOwnFocus)
      )}
      {...allProps}
    />
  );
};
