import { GridCellProps, GridColumn } from '@progress/kendo-react-grid';
import { ModalFormDrawer, PageSectionHeading } from 'Components/Display';
import {
  AsyncGrid,
  FileDownloadCell,
  getFormattedDisplayCell,
  getSeeMoreCell,
} from 'Components/Grid';
import {
  GridProvider,
  ModalFormProvider,
  useEntity,
  useGridState,
  useModalFormState,
  useToasts,
} from 'Context';
import {
  ActionsDropdownConfig,
  defaultGridProps,
  GridColumnWidth,
  ReferralIdGridSearchModel,
  ReferralRawDocumentModel,
} from 'Models';
import { Permission } from 'Models/Templates/Permission/Permission';
import { ENDPOINTS } from 'Models/Templates/Routes/Routes';
import React, { Fragment } from 'react';
import {
  ReferralRawDocumentActionCell,
  ReferralRawDocumentDrawerFormContent,
} from '.';

export const ReferralRawDocumentList: React.FC = () => {
  const entity = useEntity();

  return (
    <Fragment>
      <GridProvider entityName="Referral Raw Document">
        <CastAttachmentModalFormWrapper />
      </GridProvider>
    </Fragment>
  );
};

const CastAttachmentModalFormWrapper: React.FC = () => {
  const toast = useToasts();
  const entity = useEntity();
  const { refreshGrid, entityName } = useGridState();

  const handleSuccess = () => {
    toast.success(`${entityName} saved!`);
    refreshGrid();
  };

  var formModel = new ReferralRawDocumentModel(entity.id);

  return (
    <ModalFormProvider
      formModel={formModel}
      endpoint={ENDPOINTS.referralrawdocuments.base}
      handleSuccess={handleSuccess}
    >
      <ReferralRawDocumentGridWithModalDrawer />
    </ModalFormProvider>
  );
};

const ReferralRawDocumentGridWithModalDrawer: React.FC = () => {
  const entity = useEntity();
  const { setupAdd } = useModalFormState();
  const searchModel: ReferralIdGridSearchModel = new ReferralIdGridSearchModel(
    entity.id
  );

  const actionsConfig: ActionsDropdownConfig = {
    name: 'Actions',
    items: [
      {
        name: 'Upload Documents',
        onClick: setupAdd,
        permissions: [
          Permission.AddReferralRawDocument,
          Permission.UploadReferralRawDocument,
        ],
      },
    ],
  };

  return (
    <Fragment>
      <PageSectionHeading titleActionsConfig={actionsConfig}>
        Documents
      </PageSectionHeading>
      <AsyncGrid
        readEndpoint={ENDPOINTS.referralrawdocuments.read}
        search={searchModel}
        withCardWrapper
        {...defaultGridProps}
      >
        <GridColumn
          field="fileName"
          title="File Name"
          cell={FileNameDownloadCell}
          width={GridColumnWidth.MedLarge}
        />
        <GridColumn
          field="size"
          title="File Size"
          cell={getFormattedDisplayCell('fileSizeDisplay')}
          width="auto"
        />
        <GridColumn
          field="uploadDate"
          title="Date Uploaded"
          cell={getFormattedDisplayCell('uploadDateDisplay')}
        />
        <GridColumn
          field="comments"
          title="Comments"
          cell={getSeeMoreCell('Comments')}
          width={200}
        />
        <GridColumn field="uploadedBy" title="Uploaded By" />
        <GridColumn
          field="id"
          title=" "
          cell={(props) => <ReferralRawDocumentActionCell {...props} />}
        />
      </AsyncGrid>
      <ModalFormDrawer width={500} title="Case Attachment">
        <ReferralRawDocumentDrawerFormContent />
      </ModalFormDrawer>
    </Fragment>
  );
};

const FileNameDownloadCell: React.FC<GridCellProps> = ({ ...props }) => {
  return (
    <FileDownloadCell
      fileControllerEndpoint={ENDPOINTS.referralrawdocuments.base}
      {...props}
    />
  );
};
