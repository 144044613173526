import { DropdownItemModel, NamedCoreEnum } from "./SharedModels";

export class Note {
    id?: string;
    referralId?: string;
    title?: string;
    escalation?: DropdownItemModel;
    noteDate?: Date;
    description?: string;
    contactType?: NamedCoreEnum;
    contactName?: string;
    contactDate?: Date;
}

export class InternalNote {
    id?: string;
    referralId?: string;
    title?: string;
    dateCreated?: Date;
}