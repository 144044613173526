import { FilterBarFilterType, IFilterConfig, IFilterValues } from 'Models';
import * as React from 'react';
import {
  isNullEmptyOrUndefined,
  isNullOrUndefined,
  setPropertyByString,
  useDebounce,
  useToggler,
} from 'Utilities';
import { getFieldValue } from './FormProvider';

export interface FilterBarState {
  filterValues: IFilterValues;
  filterConfigurations: IFilterConfig[];
  areFiltersApplied?: boolean;
}

export enum FilterBarActionTypes {
  SetValues = 'setValues',
  UpdateValue = 'updateValue',
  SetFilterConfigurations = 'setFilterConfigurations',
  SetFiltersApplied = 'setFiltersApplied',
  UpdateFilterConfiguration = 'updateFilterConfiguration',
}

export type FilterBarAction =
  | { type: FilterBarActionTypes.SetValues; payload: any }
  | {
      type: FilterBarActionTypes.UpdateValue;
      payload: { field: string; value: any };
    }
  | {
      type: FilterBarActionTypes.SetFilterConfigurations;
      payload: IFilterConfig[];
    }
  | {
      type: FilterBarActionTypes.UpdateFilterConfiguration;
      payload: { field: string; value: any };
    }
  | {
      type: FilterBarActionTypes.SetFiltersApplied;
      payload: boolean;
    };
type FilterBarDispatch = (action: FilterBarAction) => void;

const FilterBarStateContext =
  React.createContext<FilterBarState | undefined>(undefined);
const FilterBarDispatchContext =
  React.createContext<FilterBarDispatch | undefined>(undefined);

function filterBarReducer(
  state: FilterBarState,
  action: FilterBarAction
): FilterBarState {
  switch (action.type) {
    case FilterBarActionTypes.SetValues: {
      return { ...state, filterValues: action.payload };
    }
    case FilterBarActionTypes.UpdateValue: {
      var tempValues: any = { ...state.filterValues };
      setPropertyByString(
        tempValues,
        action.payload.field,
        action.payload.value
      );
      return { ...state, filterValues: tempValues };
    }
    case FilterBarActionTypes.SetFiltersApplied: {
      return { ...state, areFiltersApplied: action.payload };
    }
    case FilterBarActionTypes.SetFilterConfigurations: {
      return { ...state, filterConfigurations: action.payload };
    }
    case FilterBarActionTypes.UpdateFilterConfiguration: {
      var newConfigurations = [...state.filterConfigurations];
      newConfigurations = newConfigurations.map((c) => {
        if (c.name === action.payload.field) {
          return {
            ...c,
            ...action.payload.value,
          };
        }

        return c;
      });
      return { ...state, filterConfigurations: newConfigurations };
    }
    default: {
      throw new Error('Invalid action type');
    }
  }
}

export type FilterBarProviderProps = {
  isColumnSelectorEnabled?: boolean;
  filterValues?: IFilterValues;
  filterConfigurations: IFilterConfig[];
  isOpen?: boolean;
};

export const FilterBarProvider: React.FC<FilterBarProviderProps> = ({
  filterValues = {},
  filterConfigurations,
  children,
  ...otherProps
}) => {
  var numSearchTextFilters = filterConfigurations.filter(
    (x) => x.type === FilterBarFilterType.SearchText
  ).length;

  if (numSearchTextFilters > 1) {
    throw new Error('There should be no more than 1 search text filter.');
  }

  const [state, dispatch] = React.useReducer(filterBarReducer, {
    filterValues,
    filterConfigurations,
  });

  React.useEffect(() => {
    const areFiltersApplied = Object.entries(state.filterValues).some(
      ([name, value]) =>
        !isNullEmptyOrUndefined(value) &&
        !state.filterConfigurations.some(
          (config) => config.name === name && !filterTypeHasChip(config.type)
        )
    );
    dispatch({
      type: FilterBarActionTypes.SetFiltersApplied,
      payload: areFiltersApplied,
    });
  }, [state.filterValues]);

  return (
    <FilterBarStateContext.Provider value={state}>
      <FilterBarDispatchContext.Provider value={dispatch}>
        {children}
      </FilterBarDispatchContext.Provider>
    </FilterBarStateContext.Provider>
  );
};

export const useFilterBarState = () => {
  const formStateContext = React.useContext(FilterBarStateContext);
  if (formStateContext === undefined) {
    throw new Error(
      'useFilterBarState must be used within a FilterBarProvider'
    );
  }
  return formStateContext;
};

export const useOptionalFilterBarState = () => {
  const formStateContext = React.useContext(FilterBarStateContext);
  return formStateContext;
};

export const useFilterBarDispatch = () => {
  const formDispatchContext = React.useContext(FilterBarDispatchContext);
  if (formDispatchContext === undefined) {
    throw new Error(
      'useFilterBarDispatch must be used within a FilterBarProvider'
    );
  }
  return formDispatchContext;
};

export const useFilterBarContext = () => {
  const state = useFilterBarState();
  const dispatch = useFilterBarDispatch();

  const sendUpdate = React.useCallback(
    (type: FilterBarActionTypes, payload?: any) => {
      dispatch({ type, payload } as FilterBarAction);
    },
    [dispatch]
  );

  return [state, sendUpdate] as const;
};

const useFilterBarUpdater = () => {
  const dispatch = useFilterBarDispatch();

  return React.useCallback(
    (type: FilterBarActionTypes, payload?: any) => {
      dispatch({ type, payload } as FilterBarAction);
    },
    [dispatch]
  );
};

export function useFilterBarValue<T = any>(name: string, initialValue?: T) {
  const { filterValues, filterConfigurations } = useFilterBarState();
  const sendUpdate = useFilterBarUpdater();

  var value = getFieldValue(filterValues, name);
  value = value === undefined ? initialValue : value;

  if (
    filterConfigurations.find((x) => x.name === name)?.type ===
    FilterBarFilterType.DateRange
  ) {
    const [fromDateName, toDateName] = getDateRangeNames(name);
    if (
      !isNullOrUndefined(filterValues[fromDateName]) ||
      !isNullOrUndefined(filterValues[toDateName])
    ) {
      value = {
        fromDateValue: filterValues[fromDateName],
        toDateValue: filterValues[toDateName],
      };
    } else {
      value = undefined;
    }
  }

  return [
    value as T,
    (value: T) =>
      sendUpdate(FilterBarActionTypes.UpdateValue, {
        field: name,
        value: value,
      }),
  ] as const;
}

export function useFilterBarValueSetter<T = any>(name: string) {
  const sendUpdate = useFilterBarUpdater();

  return (value: T) =>
    sendUpdate(FilterBarActionTypes.UpdateValue, { field: name, value: value });
}

export const filterTypeHasChip = (type?: FilterBarFilterType) => {
  switch (type) {
    case FilterBarFilterType.SearchText:
    case FilterBarFilterType.Static:
    case undefined:
      return false;
    default:
      return true;
  }
};

export const filterTypeShouldClear = (type?: FilterBarFilterType) => {
  switch (type) {
    case FilterBarFilterType.SearchText:
    case FilterBarFilterType.Static:
    case FilterBarFilterType.StaticWithDisplay:
    case undefined:
      return false;
    default:
      return true;
  }
};

export const getDateRangeNames = (name: string) => {
  return [`${name}From`, `${name}To`];
};

export const useFilterBarHelpers = () => {
  const { filterConfigurations, filterValues } = useFilterBarState();
  const sendUpdate = useFilterBarUpdater();

  const clearAllFilters = () => {
    const clearedFilters = Object.keys(filterValues)
      .filter(
        (key) =>
          !filterTypeShouldClear(
            filterConfigurations.find(
              (c) => c.name === key || getDateRangeNames(c.name).includes(key)
            )?.type
          )
      )
      .reduce((obj: any, key: string) => {
        obj[key] = filterValues[key];
        return obj;
      }, {});

    sendUpdate(FilterBarActionTypes.SetValues, clearedFilters);
  };

  const clearFilter = (id: string, value?: any) => {
    const config = filterConfigurations.find((x) => x.id === id);

    if (config === undefined) {
      throw new Error('Filter configuration not defined.');
    }

    var updatedValues = { ...filterValues };

    if (!config.multiSelect) {
      if (config.type === FilterBarFilterType.DateRange) {
        const [fromDateName, toDateName] = getDateRangeNames(config.name);
        delete updatedValues[fromDateName];
        delete updatedValues[toDateName];
      } else {
        delete updatedValues[config.name];
      }

      sendUpdate(FilterBarActionTypes.SetValues, updatedValues);
    } else {
      var typedArrayValue = [...updatedValues[config.name]];
      updatedValues[config.name] = typedArrayValue.filter((x) => x !== value);
      sendUpdate(FilterBarActionTypes.SetValues, updatedValues);
    }
  };

  const setFilterConfigs = (configs: IFilterConfig[]) => {
    sendUpdate(FilterBarActionTypes.SetFilterConfigurations, configs);
  };

  const updateFilterConfig = (name: string, config: Partial<IFilterConfig>) => {
    sendUpdate(FilterBarActionTypes.UpdateFilterConfiguration, {
      field: name,
      value: config,
    });
  };

  return { clearAllFilters, clearFilter, updateFilterConfig, setFilterConfigs };
};

export const useDebouncedFilterValues = (debounceLength?: number) => {
  const [initialLoad, setInitialLoad] = React.useState(true);
  const [gridToggler, toggleGrid] = useToggler();
  const { filterValues } = useFilterBarState();
  const debounceSearchModel = useDebounce(filterValues, debounceLength || 300);

  React.useEffect(() => {
    if (initialLoad) {
      setInitialLoad(false);
    } else {
      toggleGrid();
    }
  }, [debounceSearchModel]);

  return [debounceSearchModel, gridToggler] as const;
};

export const useFilterConfig = (filterName: string) => {
  const { filterConfigurations } = useFilterBarState();

  return filterConfigurations.find((x) => x.name === filterName);
};
