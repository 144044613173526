import {
  GridCellProps,
  GridColumn as Column,
} from '@progress/kendo-react-grid';
import {
  AsyncLoadingSection,
  LightPageHeader,
  MainArea,
  PageCard,
  PageCardRow,
  PageCardSection,
  TaskHeaderProps,
  TaskPageHeaderLayoutProps,
} from 'Components/Display';
import {
  ButtonCoreProps,
  CancelButton,
  FormSubmitButton,
  PrimaryButton,
  SecondaryButton,
  SmartInputBlock,
} from 'Components/Form';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { DateCell, LinkCell, MainGrid } from 'Components/Grid';
import {
  FormProvider,
  GridProvider,
  useEnums,
  useFormState,
  useFormStateValue,
  useToasts,
} from 'Context';
import {
  removeAllTabIndicators,
  toggleTabsRequiredIndicator,
} from 'FeatureUtilities/TabUtilities';
import {
  ColSize,
  defaultGridProps,
  DropdownItemModel,
  InputType,
  Physician,
  ReferralsRequest,
} from 'Models';
import { ENDPOINTS } from 'Models/Templates/Routes/Routes';
import { ClaimantsSubRoute, EntitiesSubRoute, ROUTES } from 'Navigation';
import React, {
  createContext,
  Fragment,
  useContext,
  useEffect,
  useState,
} from 'react';
import { Fade, Tab, Tabs } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import {
  buildEndpoint,
  buildQueryUrl,
  buildRoute,
  defaultDataTransform,
  restrictSsnValue,
  toggledClass,
  useAddClaimantuccessHandler,
  useDataFetcher,
  useDataPoster,
  useIdParam,
  useToggler,
} from 'Utilities';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { makeStyles } from '@mui/styles';
import { PhysiciansAddEditFormContent } from 'Features/AddEdit/AddEditPhysician';
import { SubHeaderLayout } from 'Components/SubLayouts';
import { SecureComponent } from 'Components/Security';
import { Permission } from 'Models/Templates/Permission/Permission';

// #region NewClaimantForm CONTEXT
type NewClaimantFormState = { showPhysicianForm: boolean };

type NewClaimantFormProviderProps = { children: React.ReactNode };

type NewClaimantFormContextType = {
  newClaimantFormState: NewClaimantFormState | undefined;
  setNewClaimantFormState: React.Dispatch<
    React.SetStateAction<NewClaimantFormState | undefined>
  >;
};

const NewClaimantFormContext = createContext<
  NewClaimantFormContextType | undefined
>(undefined);

const NewClaimantFormProvider: React.FC<NewClaimantFormProviderProps> = ({
  children,
}) => {
  const [newClaimantFormState, setNewClaimantFormState] = useState<
    NewClaimantFormState | undefined
  >(undefined);

  return (
    <NewClaimantFormContext.Provider
      value={{
        newClaimantFormState,
        setNewClaimantFormState,
      }}
    >
      {children}
    </NewClaimantFormContext.Provider>
  );
};

const useNewClaimantFormContext = () => {
  const claimantFormContext = useContext(NewClaimantFormContext);
  if (claimantFormContext === undefined)
    throw new Error(
      'useNewClaimantForm must be used within a NewClaimantFormProvider'
    );
  return claimantFormContext;
};

// #endregion

class ConvertProposals {
  searchKey?: string;
}

const useCustomActionSuccessHandler = (
  customToastMessage?: string,
  noToast = false
) => {
  const toast = useToasts();
  let history = useHistory();
  const handleSuccess = (response: any) => {
    let redirectUrl = buildRoute(
      ROUTES.REFERRAL,
      response.data.referralId,
      'View'
    );

    !noToast &&
      toast.success(
        customToastMessage ? customToastMessage : response.data.message
      );

    if (redirectUrl) {
      history.push(redirectUrl);
    } else {
      if (window.location.href.endsWith('#')) {
        history.goBack();
      }
      history.goBack();
    }
  };

  return handleSuccess;
};

export const ReferralsRequestProposalsMain: React.FC = () => {
  const [id, isAdd] = useIdParam();
  const entityName = 'ReferralRequestProposals';
  const proposalSearch = new ConvertProposals();

  const [showForm, setShowForm] = useToggler(false);
  const handleSuccess = useAddClaimantuccessHandler(entityName, isAdd);
  const history = useHistory();

  const [data] = useDataFetcher<ReferralsRequest>(
    buildRoute(
      ENDPOINTS.referralsRequests.endpointString('getConvertModel'),
      id
    ),
    new ReferralsRequest(),
    defaultDataTransform,
    ReferralsRequest
  );

  const cancelAction = () => {
    history.push(
      buildRoute(ROUTES.ENTITIES, EntitiesSubRoute.ReferralsRequests)
    );
  };

  return (
    <NewClaimantFormProvider>
      <PageCard>
        <PageCardRow>
          <PageCardSection
            title="Convert Referral to Claimant"
            className={showForm ? 'hidden' : ''}
          >
            <div
              style={{
                width: '90%',
                whiteSpace: 'normal',
                marginBottom: '30px',
                padding: '10px',
                backgroundColor: '#fff8ec',
                border: 'yellow 1px dashed',
              }}
            >
              The following are records that match the data from the Referral
              Request, you can select one of these to create the referral into
              one of these Claimants or click the Create <b>new Claimant</b>{' '}
              button to create a new Claimant Profile
            </div>
            <FormProvider formModel={proposalSearch}>
              <GridProvider>
                <MainGrid
                  hideNoRecordsAlert
                  withBorder={false}
                  customNoRecordsDisplay="No suitable matches found"
                  isMainGrid
                  readEndpoint={buildRoute(
                    ENDPOINTS.referralsRequests.endpointString(
                      'ConvertProposals'
                    ),
                    id
                  )}
                  {...defaultGridProps}
                >
                  <Column
                    field="firstName"
                    title="First Name"
                    cell={nameCell}
                  />
                  <Column field="lastName" title="Last Name" cell={nameCell} />
                  <Column field="associatedClients" title="Client(s)" />
                  <Column field="dob" title="Birthdate" cell={dateCell} />
                  <Column field="ssn" title="SSN" />
                  <Column
                    field="hasMatchingPolicy"
                    title="Policy Match Found"
                    cell={iconCell}
                  />
                  <Column field="id" title="Actions" cell={ActionsCell} />
                </MainGrid>
              </GridProvider>
              <br />
              <PrimaryButton
                onClick={setShowForm}
                className={toggledClass('hidden', showForm)}
              >
                Create New Claimant
              </PrimaryButton>
              <SecondaryButton onClick={cancelAction} className="ml-2">
                Cancel
              </SecondaryButton>
            </FormProvider>
          </PageCardSection>
        </PageCardRow>
        <PageCardRow>
          <FormProvider
            endpoint={ENDPOINTS.referralsRequests.endpointString(
              'ConvertToClaimant'
            )}
            formModel={data}
            handleSuccess={handleSuccess}
          >
            <NewClaimantForm
              referralRequestId={id}
              display={showForm}
              visibilityToggler={setShowForm}
            />
          </FormProvider>
        </PageCardRow>
      </PageCard>
    </NewClaimantFormProvider>
  );
};

const dateCell: React.FC<GridCellProps> = (props: GridCellProps) => {
  return <DateCell {...props} />;
};

const iconCell: React.FC<GridCellProps> = (props: GridCellProps) => {
  let checkMark: any;

  if (props.dataItem.hasMatchingPolicy) {
    checkMark = <FontAwesomeIcon icon={faCheck} />;
  }

  return (
    <td>
      <div className="text-center">{checkMark}</div>
    </td>
  );
};

const nameCell: React.FC<GridCellProps> = (props: GridCellProps) => {
  return (
    <LinkCell
      endpoint={ROUTES.CLAIMANT}
      subRoute={ClaimantsSubRoute.View}
      linkField="id"
      openInNewTab
      {...props}
    ></LinkCell>
  );
};

const ActionsCell: React.FC<GridCellProps> = (
  props: GridCellProps,
  proposalId: string
) => {
  let successHandler = useCustomActionSuccessHandler();
  const [postData] = useDataPoster(
    buildEndpoint(ENDPOINTS.referralsRequests.endpointString('acceptProposal')),
    successHandler,
    false
  );

  return (
    <td>
      <SecondaryButton
        className="small-button"
        onClick={() =>
          postData({
            id: props.dataItem.id,
            referralsRequestId: props.dataItem.referralRequestId,
          })
        }
      >
        Use this
      </SecondaryButton>
    </td>
  );
};

class NewClaimantFormProps {
  display?: boolean;
  referralRequestId?: string;
  visibilityToggler: any;
}

const NewClaimantForm: React.FC<NewClaimantFormProps> = (
  props: NewClaimantFormProps
) => {
  const [key, setKey] = useState('primary');
  const { state, natureOfEval, referralType, firstLevelReviewStatus, gender } =
    useEnums();

  const dropdownData = [
    { text: 'Standard', value: 'Standard' },
    { text: 'Expedited', value: 'Expedited' },
  ];

  const textField = 'text';
  const dataItemKey = 'value';
  const formState = useFormState();
  const history = useHistory();

  useEffect(() => {
    if (formState.formStatus == 'validationError') {
      removeAllTabIndicators();
      toggleTabsRequiredIndicator();
    }
  }, [formState, removeAllTabIndicators, toggleTabsRequiredIndicator]);

  let hasExternalAdjuster =
    formState.formValues.externalAdjuster != null ? true : false;

  let adjusterName =
    formState.formValues.adjusterName != null
      ? formState.formValues.adjusterName
      : '';

  const useStyles = makeStyles({
    root: {
      marginTop: 'auto',
      marginBottom: '2.1rem',
    },
    infoLabel: {
      display: 'block',
    },
  });

  const classes = useStyles();

  const cancelAction = () => {
    history.push(
      buildRoute(ROUTES.ENTITIES, EntitiesSubRoute.ReferralsRequests)
    );
  };

  const [selectedClient] = useFormStateValue<DropdownItemModel | undefined>(
    'claim.client'
  );

  let typeOfEvalReadEndpoint = buildEndpoint(
    ENDPOINTS.clients.endpointString('typeOfEvalDropdownItems'),
    selectedClient?.id
  );

  const claimantFormContext = useNewClaimantFormContext();
  let showPhysicianForm =
    claimantFormContext?.newClaimantFormState?.showPhysicianForm;

  const createNewPhysicianHandle = () => {
    claimantFormContext.setNewClaimantFormState({
      showPhysicianForm: true,
    });
  };

  return (
    <PageCardSection
      className={props.display ? '' : 'hidden'}
      title="New Claimant Profile"
    >
      <Row>
        <Col></Col>
        <Col>
          <FormSubmitButton className="float-right">
            Save Profile
          </FormSubmitButton>
          <SecondaryButton onClick={cancelAction} className="float-right mr-2">
            Cancel
          </SecondaryButton>
        </Col>
      </Row>
      <Tabs
        transition={Fade}
        id="controlled-tab-example"
        activeKey={key}
        onSelect={(k) => setKey(k as string)}
        className="mb-3"
      >
        <SmartInputBlock
          name="referralRequestId"
          type={InputType.Text}
          hidden
        />
        <Tab eventKey="primary" title="Primary Information">
          <SmartInputBlock
            name="referral.referralDate"
            label="Referral Date"
            hidden
            required
            type={InputType.Text}
          />
          <Row>
            <SmartInputBlock
              name="claim.client"
              label="Client"
              type={InputType.AsyncDropDown}
              readEndpoint={ENDPOINTS.clients.dropdownItems}
              fieldConfiguration={{
                dependentFieldNames: ['referral.evalType'],
              }}
              colProps={{ xs: ColSize.OneThird }}
              required
            />
            <SmartInputBlock
              name="referral.evalType"
              label="Type Of Evaluation"
              readEndpoint={typeOfEvalReadEndpoint}
              type={InputType.DropDown}
              colProps={{ xs: ColSize.OneThird }}
            />
            <SmartInputBlock
              name="referral.referralType"
              label="Referral Type"
              data={referralType}
              type={InputType.EnumDropdown}
              colProps={{ xs: ColSize.OneThird }}
            />
          </Row>
          <Row>
            <SmartInputBlock
              type={InputType.Text}
              name="claimant.firstName"
              label="First Name"
              required
              colProps={{ xs: ColSize.OneThird }}
            />
            <SmartInputBlock
              type={InputType.Text}
              name="claimant.lastName"
              label="Last Name"
              colProps={{ xs: ColSize.OneThird }}
              required
            />
            <SmartInputBlock
              type={InputType.Text}
              name="claimant.email"
              label="Email"
              colProps={{ xs: ColSize.OneThird }}
            />
          </Row>
          <Row>
            <SmartInputBlock
              type={InputType.DropDown}
              name="referral.timeFrame"
              label="Time Frame"
              data={dropdownData}
              dataItemKey={dataItemKey}
              textField={textField}
              colProps={{ xs: ColSize.OneThird }}
            />
            <SmartInputBlock
              name="claim.natureOfEval"
              label="Nature of Eval"
              data={natureOfEval}
              type={InputType.EnumDropdown}
              colProps={{ xs: ColSize.OneThird }}
            />
            <SmartInputBlock
              name="claimant.dwc"
              label="DWC 22/32 Complete"
              type={InputType.Checkbox}
              colProps={{ xs: ColSize.OneThird }}
            />
          </Row>
          <Row>
            <SmartInputBlock
              type={InputType.Text}
              name="referral.name"
              label="Referral Name"
              colProps={{ xs: ColSize.OneThird }}
              required
            />
            {hasExternalAdjuster && (
              <SmartInputBlock
                name="externalAdjuster"
                label="External Adjuster"
                type={InputType.Text}
                colProps={{ xs: ColSize.OneThird }}
              />
            )}
            {!hasExternalAdjuster && (
              <Col xs="12" lg="6">
                <span className={`${classes.infoLabel} field-label`}>
                  Adjuster
                </span>
                <br />
                <span className="field-value">{adjusterName}</span>
              </Col>
            )}
          </Row>
        </Tab>

        <Tab eventKey="personal" title="Personal Information">
          <Row>
            <Col>
              <div className="mb-3">
                <h4>Address</h4>
              </div>
            </Col>
          </Row>
          <Row>
            <SmartInputBlock
              type={InputType.Text}
              name="claimant.addresses[0].address.line1"
              label="Address Line 1"
              colProps={{ xs: ColSize.OneThird }}
            />
            <SmartInputBlock
              type={InputType.Text}
              name="claimant.addresses[0].address.line2"
              label="Address Line 2"
              colProps={{ xs: ColSize.OneThird }}
            />
          </Row>
          <Row>
            <SmartInputBlock
              type={InputType.Text}
              name="claimant.addresses[0].address.city"
              label="City"
              colProps={{ xs: ColSize.OneThird }}
            />
            <SmartInputBlock
              name="claimant.addresses[0].address.state"
              label="State"
              type={InputType.EnumDropdown}
              data={state}
              colProps={{ xs: ColSize.OneThird }}
            />
            <SmartInputBlock
              type={InputType.Text}
              name="claimant.addresses[0].address.zip"
              label="Zip Code"
              width={200}
              colProps={{ xs: ColSize.OneThird }}
            />
          </Row>
          <Row>
            <SmartInputBlock
              name="claimant.phone"
              label="Phone"
              type={InputType.PhoneNumber}
              colProps={{ xs: ColSize.OneThird }}
            />
            <SmartInputBlock
              name="claimant.mobilePhone"
              label="Mobile Phone"
              type={InputType.PhoneNumber}
              colProps={{ xs: ColSize.OneThird }}
            />
          </Row>
          <Row>
            <SmartInputBlock
              name="claimant.fax"
              label="Fax"
              type={InputType.PhoneNumber}
              colProps={{ xs: ColSize.OneThird }}
            />
            <SmartInputBlock
              name="claimant.phoneOther"
              label="Other"
              type={InputType.PhoneNumber}
              colProps={{ xs: ColSize.OneThird }}
            />
          </Row>

          <Row>
            <Col>
              <div className="mb-3">
                <h4>Information</h4>
              </div>
            </Col>
          </Row>

          <Row>
            <SmartInputBlock
              name="claimant.gender"
              label="Gender"
              type={InputType.EnumDropdown}
              data={gender}
              required
              colProps={{ xs: ColSize.OneThird }}
            />
            <SmartInputBlock
              name="claimant.ssn"
              label="SSN"
              type={InputType.SocialSecurityNumberCustom}
              restrictValues={(value) => restrictSsnValue(value)}
              colProps={{ xs: ColSize.OneThird }}
            />
          </Row>
          <Row>
            <SmartInputBlock
              name="claimant.dateOfBirth"
              label="Date of Birth"
              type={InputType.DatePicker}
              required
              colProps={{ xs: ColSize.OneThird }}
            />
            <SmartInputBlock
              name="claim.dateOfInjury"
              label="Date of Injury"
              required
              type={InputType.DatePicker}
              colProps={{ xs: ColSize.OneThird }}
            />
          </Row>
        </Tab>

        <Tab eventKey="physician" title="Physician">
          <Row>
            {!showPhysicianForm && (
              <>
                <SmartInputBlock
                  name="physician"
                  label="Physician"
                  type={InputType.AsyncDropDown}
                  readEndpoint={ENDPOINTS.physicians.endpointString(
                    'DropdownItems'
                  )}
                  colProps={{ xs: ColSize.OneThird }}
                />
                <SecureComponent permissions={[Permission.AddPhysician]}>
                  <SecondaryButton
                    onClick={createNewPhysicianHandle}
                    className={classes.root}
                  >
                    Create New Physician
                  </SecondaryButton>
                </SecureComponent>
              </>
            )}
          </Row>
          <Row>
            <NewPhysicianFromClaimantForm />
          </Row>
        </Tab>

        <Tab eventKey="employer" title="Employer">
          <Row>
            <SmartInputBlock
              type={InputType.Text}
              name={'claimant.employers[0].employer.name'}
              label="Employer Name"
              colProps={{ xs: ColSize.OneThird }}
            />
            <SmartInputBlock
              type={InputType.Text}
              name={'claimant.employers[0].employer.jobDescription'}
              label="Job Description"
              colProps={{ xs: ColSize.OneThird }}
            />
            <SmartInputBlock
              type={InputType.PhoneNumber}
              name={'claimant.employers[0].employer.phone'}
              label="Phone"
              colProps={{ xs: ColSize.OneThird }}
            />
          </Row>
          <Row>
            <SmartInputBlock
              type={InputType.Text}
              name={'claimant.employers[0].employer.addressLine1'}
              label="Address Line 1"
              colProps={{ xs: ColSize.OneThird }}
            />
            <SmartInputBlock
              type={InputType.Text}
              name={'claimant.employers[0].employer.addressLine2'}
              label="Address Line 2"
              colProps={{ xs: ColSize.OneThird }}
            />
            <SmartInputBlock
              type={InputType.Text}
              name={'claimant.employers[0].employer.city'}
              colProps={{ xs: ColSize.OneThird }}
              label="Employer City"
            />
          </Row>
          <Row>
            <SmartInputBlock
              name={'claimant.employers[0].employer.state'}
              label="Employer State"
              type={InputType.EnumDropdown}
              data={state}
              colProps={{ xs: ColSize.OneThird }}
            />
            <SmartInputBlock
              type={InputType.Text}
              name={'claimant.employers[0].employer.zip'}
              label="Zip Code"
              colProps={{ xs: ColSize.OneThird }}
              width={200}
            />
          </Row>
        </Tab>

        <Tab eventKey="insurance" title="Insurance">
          <Row>
            <SmartInputBlock
              type={InputType.Text}
              name="claim.insuranceCarrierName"
              required
              colProps={{ xs: ColSize.OneThird }}
              label="Carrier Name"
            />
            <SmartInputBlock
              type={InputType.Text}
              name="claimant.dwcClaimNumber"
              colProps={{ xs: ColSize.OneThird }}
              label="State Claim#"
            />
            <SmartInputBlock
              type={InputType.Text}
              name="claim.claimNumber"
              colProps={{ xs: ColSize.OneThird }}
              required
              label="Claim Number"
            />
          </Row>
          <Row>
            <SmartInputBlock
              name="claim.benefitState"
              label="Benefit State"
              type={InputType.EnumDropdown}
              data={state}
              colProps={{ xs: ColSize.OneThird }}
            />
          </Row>
        </Tab>

        <Tab eventKey="referral" title="Referral">
          <Row>
            <SmartInputBlock
              name="referral.referralSource"
              label="Referral Source"
              type={InputType.AsyncDropDown}
              readEndpoint={ENDPOINTS.users.endpointString(
                'referralSourceDropdownItems'
              )}
              colProps={{ xs: ColSize.OneThird }}
            />
            <SmartInputBlock
              type={InputType.EnumDropdown}
              name="referral.firstLevelReviewStatus"
              label="Status"
              data={firstLevelReviewStatus}
              colProps={{ xs: ColSize.OneThird }}
            />

            <SmartInputBlock
              type={InputType.Text}
              name="referral.referralNumber"
              label="Referral Number"
              colProps={{ xs: ColSize.OneThird }}
            />
          </Row>
          <Row>
            <SmartInputBlock
              type={InputType.Text}
              name="otherReferralSource"
              label="Other Referral Source"
              colProps={{ xs: ColSize.OneThird }}
            />
            <SmartInputBlock
              type={InputType.Text}
              name="contactPerson"
              colProps={{ xs: ColSize.OneThird }}
              label="Contact Person"
            />

            <SmartInputBlock
              type={InputType.PhoneNumber}
              name="contactPhone"
              colProps={{ xs: ColSize.OneThird }}
              label="Contact Phone"
            />
          </Row>

          <Row>
            <SmartInputBlock
              type={InputType.Text}
              name="contactEmail"
              colProps={{ xs: ColSize.OneThird }}
              label="Contact Email"
            />
            <SmartInputBlock
              type={InputType.Text}
              name="compensableInjury"
              colProps={{ xs: ColSize.OneThird }}
              label="Compensable Injury"
            />

            <SmartInputBlock
              type={InputType.Text}
              name="nonCompensableInjury"
              colProps={{ xs: ColSize.OneThird }}
              label="Non-Compensable Injury"
            />
          </Row>
          <Row>
            <SmartInputBlock
              type={InputType.Text}
              name="cptCode"
              colProps={{ xs: ColSize.OneThird }}
              label="CPT Code"
            />

            <SmartInputBlock
              type={InputType.Text}
              name="producerDescription"
              colProps={{ xs: ColSize.OneThird }}
              label="Description Of Producer"
            />
          </Row>
          <Row>
            <SmartInputBlock
              type={InputType.DatePicker}
              name="referral.dueDate"
              colProps={{ xs: ColSize.OneThird }}
              label="Due Date"
            />
          </Row>
        </Tab>

        <Tab eventKey="attorney" title="Attorney">
          <Row>
            <SmartInputBlock
              type={InputType.Text}
              name={'claimant.attorneyName'}
              label="attorney Name"
              colProps={{ xs: ColSize.OneThird }}
            />
            <SmartInputBlock
              type={InputType.Text}
              name={'claimant.attorneyAddressLine1'}
              label="Address Line 1"
              colProps={{ xs: ColSize.OneThird }}
            />
            <SmartInputBlock
              type={InputType.Text}
              name={'claimant.attorneyAddressLine2'}
              label="Address Line 2"
              colProps={{ xs: ColSize.OneThird }}
            />
          </Row>
          <Row>
            <SmartInputBlock
              type={InputType.Text}
              name={'claimant.attorneyCity'}
              colProps={{ xs: ColSize.OneThird }}
              label="Attorney City"
            />
            <SmartInputBlock
              name={'claimant.attorneyState'}
              label="Attorney State"
              type={InputType.EnumDropdown}
              data={state}
              colProps={{ xs: ColSize.OneThird }}
            />
            <SmartInputBlock
              type={InputType.Text}
              name={'claimant.attorneyZip'}
              colProps={{ xs: ColSize.OneThird }}
              label="Attorney Zip"
            />
          </Row>
        </Tab>

        <Tab eventKey="case" title="Case Operations">
          <Row>
            <SmartInputBlock
              type={InputType.Text}
              name="processedFileName"
              colProps={{ xs: ColSize.OneThird }}
              label="Processed File name"
            />
            <SmartInputBlock
              name="flurNurse"
              label="FLUR Nurse"
              type={InputType.AsyncDropDown}
              readEndpoint={ENDPOINTS.users.endpointString(
                'NursesListDropdownItems'
              )}
              colProps={{ xs: ColSize.OneThird }}
            />
          </Row>
        </Tab>
      </Tabs>
    </PageCardSection>
  );
};

const NewPhysicianFromClaimantForm: React.FC = () => {
  const entityName = 'Physician';
  const [id, isAdd] = useIdParam();

  const handleSuccess = useAddEditSuccessPhysicianHandler(entityName, isAdd);
  const [data, loadingStatus] = useDataFetcher<Physician>(
    buildQueryUrl(buildEndpoint(ENDPOINTS.physicians.get), {
      id: id,
    }),
    new Physician(),
    defaultDataTransform,
    Physician
  );

  const claimantFormContext = useNewClaimantFormContext();
  let showPhysicianForm =
    claimantFormContext?.newClaimantFormState?.showPhysicianForm;

  return (
    <Fragment>
      {showPhysicianForm && (
        <AsyncLoadingSection loadingStatus={loadingStatus} useMask>
          <PhysicianFromClaimantFormHeaderLayout
            title={'New Physician'}
            formProviderProps={{
              formModel: data,
              endpoint: ENDPOINTS.physicians.base,
              handleSuccess: handleSuccess,
            }}
          >
            <PhysiciansAddEditFormContent />
          </PhysicianFromClaimantFormHeaderLayout>
        </AsyncLoadingSection>
      )}
    </Fragment>
  );
};

const useAddEditSuccessPhysicianHandler = (
  entityName: string,
  isAdd: boolean,
  customToastMessage?: string,
  noToast = false
) => {
  const toast = useToasts();

  const claimantFormContext = useNewClaimantFormContext();

  const handleSuccess = () => {
    claimantFormContext.setNewClaimantFormState({
      showPhysicianForm: false,
    });

    !noToast &&
      toast.success(
        customToastMessage
          ? customToastMessage
          : `${entityName} ${isAdd ? 'added' : 'updated'}.`
      );
  };

  return handleSuccess;
};

const PhysicianFromClaimantFormHeaderLayout: React.FC<
  TaskPageHeaderLayoutProps
> = ({ children, formProviderProps, ...headerProps }) => {
  const layoutHeaderProps = { hasShortHeader: true, ...headerProps };

  return (
    <FormProvider {...formProviderProps}>
      <SubHeaderLayout
        headerComponent={NewPhysicianTaskHeader}
        headerProps={layoutHeaderProps}
      >
        <MainArea>{children}</MainArea>
      </SubHeaderLayout>
    </FormProvider>
  );
};

const NewPhysicianTaskHeader: React.FC<TaskHeaderProps> = ({
  children,
  title,
  saveButtonText = 'Save Physician',
  disableSaveButton,
  customButtons,
  className,
  tall,
}) => {
  return (
    <LightPageHeader tall={tall} className={className}>
      <div className="flex-grow">
        {title && <h4>{title}</h4>}
        {children}
      </div>
      {customButtons || (
        <Fragment>
          <NewPhysicianCancelButton className="mr-3" />
          <FormSubmitButton disabled={disableSaveButton} className="mr-3">
            {saveButtonText}
          </FormSubmitButton>
        </Fragment>
      )}
    </LightPageHeader>
  );
};

const NewPhysicianCancelButton: React.FC<ButtonCoreProps> = ({
  children,
  ...allProps
}) => {
  const claimantFormContext = useNewClaimantFormContext();

  const onClickCancelHandle = () => {
    claimantFormContext.setNewClaimantFormState({
      showPhysicianForm: false,
    });
  };

  return (
    <CancelButton onClick={onClickCancelHandle} {...allProps}>
      {children}
    </CancelButton>
  );
};
