import React from 'react';
import { GridCellProps } from '@progress/kendo-react-grid';
import { GridItemChangeType } from 'Models/GridModels';
import {
  SecondaryButton,
  LoadingButton,
  EditButton,
  DeleteButton,
} from 'Components/Form';
import { Permission } from 'Models/Templates/Permission/Permission';
import { SecureComponent } from 'Components/Security';

type CommandCellProps = GridCellProps & {
  isLoading?: boolean;
  updatePermission?: Permission;
  deletePermission?: Permission;
};

export const CommandCell: React.FC<CommandCellProps> = ({
  dataItem,
  dataIndex,
  field,
  rowType,
  onChange,
  isLoading = false,
  updatePermission,
  deletePermission,
}) => {
  const buttonClick = (e: any, command?: any) => {
    onChange &&
      onChange({
        dataItem,
        dataIndex,
        syntheticEvent: e,
        field,
        value: command,
      });
  };

  if (rowType !== 'data') {
    return null;
  }

  if (dataItem[field as string]) {
    return (
      <td className="button-cell">
        <div className="full-size flex-normal flex-vertical-center mr-4">
          <SecondaryButton
            className="mr-2"
            onClick={(e) => buttonClick(e, GridItemChangeType.Cancel)}
          >
            Cancel
          </SecondaryButton>
          <LoadingButton
            isLoading={isLoading}
            onClick={(e) =>
              buttonClick(
                e,
                dataItem.id
                  ? GridItemChangeType.Update
                  : GridItemChangeType.Save
              )
            }
          >
            Save
          </LoadingButton>
        </div>
      </td>
    );
  }

  return (
    <td className="button-cell">
      <div className="full-size flex-normal flex-vertical-center mr-4">
        <SecureComponent
          permissions={updatePermission !== undefined ? [updatePermission] : []}
        >
          <EditButton onClick={buttonClick} />
        </SecureComponent>
        <SecureComponent
          permissions={deletePermission !== undefined ? [deletePermission] : []}
        >
          <DeleteButton
            onClick={(e) =>
              window.confirm('Confirm deletion?') &&
              buttonClick(e, GridItemChangeType.Delete)
            }
          />
        </SecureComponent>
      </div>
    </td>
  );
};
