import {
  GridCellProps,
  GridColumn as Column,
} from '@progress/kendo-react-grid';
import {
  AdminSubHeaderMainLayout,
  CollapsibleAuditLog,
} from 'Components/Display';
import { AddButton } from 'Components/Form';
import {
  DateCell,
  EditDeleteCell,
  GridButtonSection,
  MainGrid,
  YesNoBooleanCell,
} from 'Components/Grid';
import { SecureComponent } from 'Components/Security';
import { GridProvider, useEnums } from 'Context';
import { defaultGridProps, GridColumnWidth } from 'Models/GridModels';
import { Permission } from 'Models/Templates/Permission/Permission';
import { ENDPOINTS } from 'Models/Templates/Routes/Routes';
import { addEditRoute, AddEditSubRoute } from 'Navigation';
import React from 'react';

export const Physicians: React.FC = () => {
  const { entityEventType } = useEnums();

  return (
    <AdminSubHeaderMainLayout title="Physicians">
      <GridButtonSection>
        <SecureComponent permissions={[Permission.AddPhysician]}>
          <AddButton url={addEditRoute(AddEditSubRoute.Physician)}>
            New Physician
          </AddButton>
        </SecureComponent>
      </GridButtonSection>
      <GridProvider entityName="Client">
        <MainGrid
          readEndpoint={ENDPOINTS.physicians.read}
          {...defaultGridProps}
        >
          <Column field="fullName" title="Name" />
          <Column field="email" title="Email" />
          <Column field="physicianSpeciality" title="Speciality" />
          <Column field="phone" title="Phone" />
          <Column field="practiceName" title="Practice" /> 
          <Column field="state" title="State" /> 
          <Column field="dateCreated" title="Created Date" cell={dotNetDateColumn} /> 
          <Column
            field="id"
            title=" "
            cell={ActionsCell}
            width={GridColumnWidth.EditDelete}
          />
        </MainGrid>
      </GridProvider>
      <CollapsibleAuditLog
        title="Audit Log"
        admin
        entityEventType={entityEventType.Physician?.value}
      />
    </AdminSubHeaderMainLayout>
  );
};

const dotNetDateColumn: React.FC<GridCellProps> = (props: GridCellProps) => {
  return <DateCell {...props} dateFormat={'MM/DD/YYYY'} />;
};

const ActionsCell: React.FC<GridCellProps> = ({ ...otherProps }) => {
  return (
    <EditDeleteCell
      editRoute={addEditRoute(AddEditSubRoute.Physician)}
      editPermissions={[Permission.EditPhysician]}
      deleteEndpoint={ENDPOINTS.physicians.base}
      deletePermissions={[Permission.DeletePhysician]}
      {...otherProps}
    />
  );
};
