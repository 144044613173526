import { NamedCoreEnum } from './SharedModels';
import { Client, UserItem } from 'Models';

export class Claim {
  id?: string;
  claimantName?: string;
  claimNumber?: string;
  client?: Client;
  dateOfInjury?: Date;
  adjuster?: UserItem;
  claimantId?: string;
}

export enum ClaimModals {
  EditClaim = 'editClaim',
}
