import { 
  AsyncLoadingSection, 
  SingleSectionPageCard, 
  TaskPageHeaderLayout 
} from "Components/Display";
import { 
  SmartInputBlock 
} from "Components/Form";
import { 
  ColSize, 
  InputType 
} from "Models";
import { 
  InternalNote
} from "Models/NotesModels";
import { 
  ENDPOINTS 
} from "Models/Templates/Routes/Routes";
import 
React, { 
  Fragment 
} from "react";
import { 
  useParams 
} from "react-router-dom";
import { Row } from "reactstrap";
import { 
  addEditTitle, 
  buildEndpoint, 
  buildQueryUrl, 
  defaultDataTransform, 
  useAddEditSuccessHandler, 
  useDataFetcher, 
  useIdParam 
} from "Utilities";

export const AddEditInternalNote: React.FC = () => {
  const entityName = 'Internal Note';
  const [id, isAdd] = useIdParam();
  const handleSuccess = useAddEditSuccessHandler(entityName, isAdd)
  const { referralid } = useParams<any>();

  const [data, loadingStatus] = useDataFetcher<InternalNote>(
    buildQueryUrl(buildEndpoint(ENDPOINTS.internalnotes.get), {
      id: id || '',
      referralId: referralid,
    }),
    new InternalNote(),
    defaultDataTransform,
    InternalNote
  );

  return (
    <AsyncLoadingSection loadingStatus={loadingStatus} useMask>
      <TaskPageHeaderLayout
        title={addEditTitle(entityName, isAdd)}
        formProviderProps={{
          formModel: data,
          endpoint: ENDPOINTS.internalnotes.base,
          handleSuccess: handleSuccess,
        }}
      >
        <InternalNotesAddEditFormContent />
      </TaskPageHeaderLayout>
    </AsyncLoadingSection>
  );
};
  
const InternalNotesAddEditFormContent: React.FC = () => {
  return (
    <Fragment>
      <SingleSectionPageCard cardTitle="" centerContent inputSection>
        <Row>
          <SmartInputBlock
            name="title"
            label="Title"
            type={InputType.Text}
            colProps={{ xs: ColSize.Half }}
            required
          />
        </Row>
        <Row>
          <SmartInputBlock
            name="description"
            label="Description"
            type={InputType.TextArea}
            colProps={{ xs: ColSize.Half }}
            required
          />
        </Row>
      </SingleSectionPageCard>
    </Fragment>
  );
};