import {
  PageSectionHeading,
  Render,
  SingleSectionPageCard,
} from 'Components/Display';
import { TextIconButton } from 'Components/Form/Buttons';
import { NoRecordsAlert } from 'Components/Grid';
import { SecureComponent } from 'Components/Security';
import { useEntity } from 'Context';
import { Permission } from 'Models/Templates/Permission/Permission';
import EditIcon from '@mui/icons-material/Edit';

import React, { Fragment } from 'react';
import { Col, Row } from 'reactstrap';
import { addEditRoute, AddEditSubRoute } from 'Navigation';
import { useHistory } from 'react-router-dom';

export const PhysicianInfo: React.FC = () => {
  const entity = useEntity();
  const hasData = entity?.physician != undefined;
  const history = useHistory();
  const editPhysician = () => {
    history.push(
      addEditRoute(AddEditSubRoute.Physician, entity.physicianInfoModel?.id)
    );
  };

  let editPhysicianButton;

  if(entity.physicianInfoModel!=null){
    editPhysicianButton = ( <SecureComponent permissions={[Permission.EditPhysician]}>
      <TextIconButton
        icon={EditIcon}
        className="d-inline ml-3"
        onClick={editPhysician}
      />
    </SecureComponent>);
  }

  return (
    <Fragment>
      <PageSectionHeading>
        Physician Info        
        {editPhysicianButton}
      </PageSectionHeading>
      <Render condition={hasData}>
        <SingleSectionPageCard>
          <Row>
            <Col xs="12" lg="6">
              <span className="field-label data-display">First Name:</span>
              <span className="field-value">
                {entity?.physicianInfoModel?.firstName}
              </span>
            </Col>
            <Col xs="12" lg="6">
              <span className="field-label data-display">Last Name:</span>
              <span className="field-value">
                {entity?.physicianInfoModel?.lastName}
              </span>
            </Col>
          </Row>
          <Row>
            <Col xs="12" lg="6">
              <span className="field-label data-display">Practice:</span>
              <span className="field-value">
                {entity?.physicianInfoModel?.practiceName}
              </span>
            </Col>
            <Col xs="12" lg="6">
              <span className="field-label data-display">Speciality:</span>
              <span className="field-value">
                {entity?.physicianInfoModel?.physicianSpeciality}
              </span>
            </Col>
          </Row>
          <Row>
            <Col xs="12" lg="6">
              <span className="field-label data-display">Phone:</span>
              <span className="field-value">
                {entity?.physicianInfoModel?.phone}
              </span>
            </Col>
            <Col xs="12" lg="6">
              <span className="field-label data-display">Mobile:</span>
              <span className="field-value">
                {entity?.physicianInfoModel?.mobilePhone}
              </span>
            </Col>
          </Row>
          <Row>
            <Col xs="12" lg="6">
              <span className="field-label data-display">Fax:</span>
              <span className="field-value">
                {entity?.physicianInfoModel?.fax}
              </span>
            </Col>
            <Col xs="12" lg="6">
              <span className="field-label data-display">Other Phone:</span>
              <span className="field-value">
                {entity?.physicianInfoModel?.phoneOther}
              </span>
            </Col>
          </Row>
          <Row>
            <Col xs="12" lg="6">
              <span className="field-label data-display">Email:</span>
              <span className="field-value">
                {entity?.physicianInfoModel?.email}
              </span>
            </Col>
            <Col xs="12" lg="6">
              <span className="field-label data-display">Gender:</span>
              <span className="field-value">
                {entity?.physicianInfoModel?.gender}
              </span>
            </Col>
          </Row>
        </SingleSectionPageCard>
      </Render>
      <Render condition={!hasData}>
        <NoRecordsAlert />
      </Render>
    </Fragment>
  );
};
