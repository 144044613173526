import "./ModalDrawer.scss";
import React, { Fragment } from "react";
import parse from "html-react-parser";

export type HtmlStringDisplayProps = {
  content: string;
};

export const HtmlStringDisplay: React.FC<HtmlStringDisplayProps> = ({
  content,
  ...otherProps
}) => {
  return <Fragment>{parse(content)}</Fragment>;
};
