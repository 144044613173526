import {
  AsyncLoadingSection,
  SingleSectionPageCard,
  TaskPageHeaderLayout,
} from 'Components/Display';
import { SmartInputBlock } from 'Components/Form';
import { useEnums } from 'Context';
import { ColSize, InputType } from 'Models';
import { Note } from 'Models/NotesModels';
import { ENDPOINTS } from 'Models/Templates/Routes/Routes';
import React, { Fragment, HTMLProps } from 'react';
import { useParams } from 'react-router-dom';
import { Row } from 'reactstrap';
import {
  addEditTitle,
  buildEndpoint,
  buildQueryUrl,
  defaultDataTransform,
  useAddEditSuccessHandler,
  useDataFetcher,
  useIdParam,
} from 'Utilities';

export const AddEditNote: React.FC = () => {
  const entityName = 'Call Note';
  const [id, isAdd] = useIdParam();
  const handleSuccess = useAddEditSuccessHandler(entityName, isAdd);
  const { referralid } = useParams<any>();

  const [data, loadingStatus] = useDataFetcher<Note>(
    buildQueryUrl(buildEndpoint(ENDPOINTS.notes.get), {
      id: id || '',
      referralId: referralid,
    }),
    new Note(),
    defaultDataTransform,
    Note
  );

  return (
    <AsyncLoadingSection loadingStatus={loadingStatus} useMask>
      <TaskPageHeaderLayout
        title={addEditTitle(entityName, isAdd)}
        formProviderProps={{
          formModel: data,
          endpoint: ENDPOINTS.notes.base,
          handleSuccess: handleSuccess,
        }}
      >
        <NotesAddEditFormContent referralId={data.referralId} />
      </TaskPageHeaderLayout>
    </AsyncLoadingSection>
  );
};

type NotesAddEditFormContentProps = HTMLProps<NotesAddEditFormContentProps> & {
  referralId?: string;
};

const NotesAddEditFormContent: React.FC<NotesAddEditFormContentProps> = ({
  referralId,
}) => {
  const { contactType } = useEnums();

  return (
    <Fragment>
      <SingleSectionPageCard cardTitle="" centerContent inputSection>
        <Row>
          <SmartInputBlock
            name="title"
            label="Title"
            type={InputType.Text}
            colProps={{ xs: ColSize.Half }}
            required
          />
        </Row>
        <Row>
          <SmartInputBlock
            name="claimEscalation"
            label="Escalation"
            type={InputType.AsyncDropDown}
            readEndpoint={buildEndpoint(
              ENDPOINTS.claimescalations.endpointString(
                'dropdownItemsByReferralId'
              ),
              referralId
            )}
            colProps={{ xs: ColSize.Half }}
          />
        </Row>
        <Row>
          <SmartInputBlock
            name="noteDate"
            label="Note Date"
            type={InputType.DatePicker}
            colProps={{ xs: ColSize.OneFourth }}
            required
          />
          <SmartInputBlock
            name="noteDate"
            label="Note Time"
            type={InputType.TimePicker}
            colProps={{ xs: ColSize.OneFourth }}
          />
        </Row>
        <Row>
          <SmartInputBlock
            name="description"
            label="Description"
            type={InputType.TextArea}
            colProps={{ xs: ColSize.Half }}
            required
          />
        </Row>
        <Row>
          <SmartInputBlock
            name="contactType"
            label="Contact Type"
            type={InputType.EnumDropdown}
            data={contactType}
            colProps={{ xs: ColSize.Half }}
            required
          />
        </Row>
        <Row>
          <SmartInputBlock
            name="contactName"
            label="Contact Name"
            type={InputType.Text}
            colProps={{ xs: ColSize.Half }}
            required
          />
        </Row>
        <Row>
          <SmartInputBlock
            name="contactDate"
            label="Contact Date"
            type={InputType.DatePicker}
            colProps={{ xs: ColSize.OneFourth }}
          />
          <SmartInputBlock
            name="contactDate"
            label="Contact Time"
            type={InputType.TimePicker}
            colProps={{ xs: ColSize.OneFourth }}
          />
        </Row>
      </SingleSectionPageCard>
    </Fragment>
  );
};
