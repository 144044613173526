import React from 'react';
import { SvgIconTypeMap } from '@mui/material/SvgIcon';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { fullClassName } from 'Utilities';
import './IndicatorChip.scss';

export type IndicatorChipProps = {
  IconComponent?: OverridableComponent<SvgIconTypeMap>;
  colorClass?: string;
  text: string;
};

export const IndicatorChip: React.FC<IndicatorChipProps> = ({
  IconComponent,
  colorClass,
  text,
}) => {
  return (
    <div
      className={fullClassName(
        'indicator-chip flex-center font-size-small px-small',
        colorClass
      )}
    >
      {IconComponent && (
        <IconComponent style={{ fontSize: 14 }} className="mr-tiny" />
      )}
      {text}
    </div>
  );
};

export const IndicatorChipCell: React.FC = ({ children }) => {
  return (
    <td>
      <div className="indicator-cell flex-gap-small">{children}</div>
    </td>
  );
};
