import React from 'react';
import { GridCellProps } from '@progress/kendo-react-grid';
import { Checkbox } from '@progress/kendo-react-inputs';

type BooleanCellProps = {
  convertFunction?: (val: any) => void;
};

export const BooleanCell: React.FC<BooleanCellProps & GridCellProps> = ({
  dataItem,
  dataIndex,
  field = '',
  onChange,
  convertFunction,
}) => {
  const handleChange = (e: any) => {
    onChange &&
      onChange({
        dataItem: dataItem,
        dataIndex: dataIndex,
        field: field,
        syntheticEvent: e.syntheticEvent,
        value: e.value,
      });
  };

  const value = dataItem[field];
  return (
    <td>
      {dataItem.inEdit ? (
        <Checkbox name={field} onChange={handleChange} value={value} />
      ) : convertFunction ? (
        convertFunction(value)
      ) : (
        value
      )}
    </td>
  );
};
