import { Input, TextArea } from '@progress/kendo-react-inputs';
import {
  OldDateTimePicker,
  OldDatePicker,
  OldTimePicker,
  NumberInput,
  CurrencyInput,
  PercentageInput,
  RichTextInput,
  DataDropDown,
  AsyncDataDropDown,
  PasswordInput,
  DataMultiSelect,
  CustomSwitch,
  CustomCheckbox,
  CustomRadioGroup,
  EnumDropDown,
  BoolDropDown,
  EnumMultiSelect,
  PhoneInput,
  SSNInput,
  SSNCustomInput,
  YesNoBooleanRadioGroup,
  CustomMaskedTextBox,
  DatePicker,
  TimePicker,
  AsyncDataMultiSelect,
} from 'Components/Form';

export type FormErrors = {
  [fieldName: string]: string[];
};

export enum FormStatus {
  Initial = 'initial',
  Submitting = 'submitting',
  Error = 'error',
  ValidationError = 'validationError',
  Success = 'success',
}

export enum InputType {
  Text = 'text',
  TextArea = 'textarea',
  Number = 'number',
  Currency = 'currency',
  Percentage = 'percentage',
  Checkbox = 'checkbox',
  MaskedText = 'maskedText',
  EnumRadioGroup = 'enumRadioGroup',
  YesNoBooleanRadioGroup = 'yesNoBooleanRadioGroup',
  Switch = 'switch',
  OldDatePicker = 'oldDatePicker',
  OldTimePicker = 'oldTimePicker',
  DatePicker = 'datePicker',
  DateTimePicker = 'dateTimePicker',
  TimePicker = 'timePicker',
  EnumDropdown = 'enumDropdown',
  BoolDropDown = 'boolDropdown',
  DropDown = 'dropdown',
  AsyncDropDown = 'asyncDropdown',
  RichText = 'richText',
  Password = 'password',
  MultiSelect = 'multiselect',
  AsyncMultiSelect = 'asyncMultiselect',
  EnumMultiSelect = 'enumMultiselect',
  PhoneNumber = 'phoneNumber',
  SocialSecurityNumber = 'socialSecurityNumber',
  SocialSecurityNumberCustom = 'socialSecurityNumberCustom',
}

export enum InputSize {
  Fifty = 50,
  SeventyFive = 75,
  OneHundred = 100,
  OneTwenty = 120,
  OneFifty = 150,
  OneSeventyFive = 175,
  TwoHundred = 200,
  TwoFifty = 250,
  ThreeHundred = 300,
  ThreeFifty = 350,
  FourHundred = 400,
  FourThirty = 430,
  FourFifty = 450,
  FiveHundred = 500,
}

export type InputTypeMap = {
  [type in InputType]?: any;
};

export const INPUT_MAP: InputTypeMap = {
  [InputType.Text]: Input,
  [InputType.TextArea]: TextArea,
  [InputType.Checkbox]: CustomCheckbox,
  [InputType.MaskedText]: CustomMaskedTextBox,
  [InputType.Number]: NumberInput,
  [InputType.Currency]: CurrencyInput,
  [InputType.Percentage]: PercentageInput,
  [InputType.EnumRadioGroup]: CustomRadioGroup,
  [InputType.YesNoBooleanRadioGroup]: YesNoBooleanRadioGroup,
  [InputType.OldDatePicker]: OldDatePicker,
  [InputType.OldTimePicker]: OldTimePicker,
  [InputType.DatePicker]: DatePicker,
  [InputType.TimePicker]: TimePicker,
  [InputType.DateTimePicker]: OldDateTimePicker,
  [InputType.EnumDropdown]: EnumDropDown,
  [InputType.BoolDropDown]: BoolDropDown,
  [InputType.DropDown]: DataDropDown,
  [InputType.AsyncDropDown]: AsyncDataDropDown,
  [InputType.MultiSelect]: DataMultiSelect,
  [InputType.AsyncMultiSelect]: AsyncDataMultiSelect,
  [InputType.EnumMultiSelect]: EnumMultiSelect,
  [InputType.RichText]: RichTextInput,
  [InputType.Password]: PasswordInput,
  [InputType.Switch]: CustomSwitch,
  [InputType.PhoneNumber]: PhoneInput,
  [InputType.SocialSecurityNumber]: SSNInput,
  [InputType.SocialSecurityNumberCustom]: SSNCustomInput,
};

export const defaultDropdownResponseTransform = ({ data }: any) => data || [];

export const hasRef = (type: InputType) => {
  switch (type) {
    case InputType.Text:
    case InputType.MaskedText:
    case InputType.Number:
    case InputType.Currency:
    case InputType.Percentage:
      return true;
    default:
      return false;
  }
};
