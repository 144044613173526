import React from "react";
import { IFilterConfig, InputType } from "Models";
import { DropdownFilterWrapper } from "./DropdownFilterWrapper";
import { InputBlock } from "Components/Form";
import { useFilterBarValue } from "Context";
import { BLANK_FIELD, isNullOrUndefined } from "Utilities";

export const FilterBarDateRange: React.FC<IFilterConfig> = ({
  name,
  ...filterConfig
}) => {
  const fromDateName = `${name}From`;
  const toDateName = `${name}To`;

  const [fromDateValue, setFromDateValue] = useFilterBarValue(fromDateName);
  const [toDateValue, setToDateValue] = useFilterBarValue(toDateName);

  return (
    <DropdownFilterWrapper name={name} {...filterConfig}>
      <div className="p-small flex-vertical-center filter-bar-date-range-wrap">
        <div>
          <InputBlock
            name={fromDateName}
            required
            type={InputType.DatePicker}
            noPaddingMargin
            onChange={(date: React.SetStateAction<Date>) => {
              setFromDateValue(date);
              if (isNullOrUndefined(toDateValue)) {
                setToDateValue(date);
              }
            }}
            value={fromDateValue}
          />
        </div>
        <div className="p-small">{BLANK_FIELD}</div>
        <div>
          <InputBlock
            name={toDateName}
            type={InputType.DatePicker}
            noPaddingMargin
            onChange={(date: React.SetStateAction<undefined>) =>
              setToDateValue(date)
            }
            value={toDateValue}
          />
        </div>
      </div>
    </DropdownFilterWrapper>
  );
};
