import Info from '@mui/icons-material/Info';
import { ErrorDisplay, Render } from 'Components/Display';
import { DefaultInputLabel } from 'Components/Form';
import {
  FormActionTypes,
  getFieldValue,
  getValueFromChangeEvent,
  useFormState,
  useFormUpdater,
} from 'Context';
import {
  FilePondFile,
  ProcessServerConfigFunction,
  RevertServerConfigFunction,
} from 'filepond';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import 'filepond/dist/filepond.min.css';
import { ColumnLayout, InputType } from 'Models';
import { METHODS } from 'Models/Templates/Routes/Routes';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { FilePond, FilePondProps, registerPlugin } from 'react-filepond';
import { Col, Row } from 'reactstrap';
import {
  buildEndpoint,
  buildQueryUrl,
  DOCX_MIME_FILE_TYPE,
  DOC_MIME_FILE_TYPE,
  FILE_TYPE_MAP,
  getPropertyByString,
  PDF_MIME_FILE_TYPE,
  toggledClass,
  useApiWorker,
  XLSX_MIME_FILE_TYPE,
  XLS_MIME_FILE_TYPE,
} from 'Utilities';
import { v4 as uuidv4 } from 'uuid';
import { SmartInputBlock } from './SmartInputBlock';

export type RenderAdditionalFormComponentsProp = (
  handleFileFieldChange: (e: any, type: InputType, fieldName: string) => void,
  formatFieldName: (name: string) => string
) => React.ReactElement;

type SmartFileUploaderProps = FilePondProps & {
  fileEndpoint: string;
  name?: string;
  vertical?: boolean;
  isEdit?: boolean;
  withComments?: boolean;
  multipleUploaders?: boolean;
  label: string;
  renderAdditionalFormComponents?: RenderAdditionalFormComponentsProp;
};
export const SmartFileUploader: React.FC<SmartFileUploaderProps> = ({
  fileEndpoint,
  name = 'files',
  label,
  required,
  allowMultiple,
  vertical,
  isEdit,
  withComments,
  multipleUploaders,
  renderAdditionalFormComponents,
  ...filePondProps
}) => {
  const { formValues, formErrors } = useFormState();
  const sendUpdate = useFormUpdater();
  const API = useApiWorker();
  var filePondRef = useRef<FilePond | null>(null);
  const [hasFiles, setHasFiles] = useState<boolean>(false);
  const [fileErrors, setFileErrors] = useState<string[]>([]);

  const uploadUrl = buildEndpoint(fileEndpoint, METHODS.Upload);
  const deleteUrl = buildEndpoint(fileEndpoint, METHODS.DeleteTempBlob);

  const commentsFieldname = 'comments';
  const allowedFileTypes = [
    PDF_MIME_FILE_TYPE,
    DOC_MIME_FILE_TYPE,
    DOCX_MIME_FILE_TYPE,
    XLS_MIME_FILE_TYPE,
    XLSX_MIME_FILE_TYPE,
  ];

  registerPlugin(FilePondPluginFileValidateType);
  useEffect(() => {
    var hasValues =
      (allowMultiple && getPropertyByString(formValues, name)?.length > 0) ||
      (multipleUploaders && formValues?.[name]?.fileId?.length > 0) ||
      (!allowMultiple && formValues?.fileId?.length > 0);

    if (hasFiles !== hasValues) {
      setHasFiles(hasValues);
    }
  }, [formValues]);
  useEffect(() => {
    const fileErrorValues = formErrors[formatFieldName('fileId')];

    setFileErrors(fileErrorValues);
  }, [formErrors]);

  useEffect(() => {
    if (filePondRef && !hasFiles) {
      filePondRef.current?.removeFiles();
    }
  }, [hasFiles]);

  const formatFieldName = (fieldName: string) => {
    return (
      allowMultiple
        ? `${name}[0].${fieldName}`
        : multipleUploaders
        ? `${name}.${fieldName}`
        : fieldName
    ).toLocaleLowerCase();
  };

  const formatFileFieldName = (fieldName: string) => {
    return multipleUploaders ? `${name}.${fieldName}` : fieldName;
  };

  const formatFile = ({ filename, fileSize, serverId }: FilePondFile) => {
    return {
      [formatFileFieldName('fileId')]: serverId,
      [formatFileFieldName('fileName')]: filename,
      [formatFileFieldName('size')]: fileSize,
    };
  };

  const getEmptyFileFields = () => {
    var fieldName = multipleUploaders ? `${name}.fileId` : 'fileId';
    return {
      ...formValues,
      [formatFileFieldName('fileId')]: undefined,
      [formatFileFieldName('fileName')]: undefined,
      [formatFileFieldName('size')]: undefined,
      [formatFileFieldName('comments')]: undefined,
    };
  };

  const clearFileFields = () => {
    var clearedValues = getEmptyFileFields();

    sendUpdate(FormActionTypes.SetValues, {
      field: name,
      value: clearedValues,
    });
  };

  const deleteUrlWithId = (id: string) => {
    return buildQueryUrl(deleteUrl, { id: id });
  };

  /* Handles chunking + posting temp file in chunks */
  const handleProcess: ProcessServerConfigFunction = async (
    fieldName,
    file,
    metadata,
    load,
    error,
    progress,
    abort
  ) => {
    var aborted = false;
    const handleAbort = () => {
      aborted = true;
      abort();
    };
    const CHUNK_SIZE = 1024 * 1024 * 10;
    var chunk = 0;
    const fileId = uuidv4();
    var numChunks = Math.ceil(file.size / CHUNK_SIZE);

    while (chunk * CHUNK_SIZE < file.size) {
      var blob = file.slice(CHUNK_SIZE * chunk, CHUNK_SIZE * (chunk + 1));
      const formData = new FormData();
      formData.append('contentType', file.type);
      formData.append('fileName', file.name);
      formData.append('chunk', `${chunk}`);
      formData.append('chunks', `${numChunks}`);
      formData.append('id', fileId);
      formData.append('file', blob);
      const response = await API.post(uploadUrl, formData);
      chunk++;
      progress(true, chunk, numChunks);
    }

    load(fileId);

    return {
      abort: handleAbort,
    };
  };

  /* Handles deleting temp file */
  const handleRevert: RevertServerConfigFunction = async (
    uniqueFileId,
    load,
    error
  ) => {
    try {
      await API.post(deleteUrlWithId(uniqueFileId));
    } catch (err) {
      error(err);
    }

    if (allowMultiple) {
      const filteredFiles = (getFieldValue(formValues, name) || []).filter(
        (file: any) => file.fileId !== uniqueFileId
      );

      sendUpdate(FormActionTypes.UpdateValue, {
        field: name,
        value: filteredFiles,
      });
    } else {
      clearFileFields();
    }

    load();
  };

  const handleProcessedFiles = () => {
    const files = filePondRef.current?.getFiles();

    if (allowMultiple) {
      sendUpdate(FormActionTypes.UpdateValue, {
        field: name,
        value: files?.map((file) => formatFile(file)),
      });
    } else {
      var fileValue = files ? formatFile(files[0]) : getEmptyFileFields();
      if (multipleUploaders) {
        for (const [key, value] of Object.entries(fileValue)) {
          sendUpdate(FormActionTypes.UpdateValue, {
            field: key,
            value: value,
          });
        }
      } else {
        sendUpdate(FormActionTypes.SetValues, {
          ...formValues,
          ...fileValue,
        });
      }
    }
  };

  const handleFileFieldChange = (
    e: any,
    type: InputType,
    fieldName: string
  ) => {
    const value = getValueFromChangeEvent(e, type);
    const fileList = getFieldValue(formValues, name);
    const newFileList = [
      ...(fileList || []).map((file: any) => {
        return { ...file, [fieldName]: value };
      }),
    ];

    sendUpdate(FormActionTypes.UpdateValue, {
      field: name,
      value: newFileList,
    });
  };

  const server = {
    process: handleProcess,
    revert: handleRevert,
  };

  return (
    <Fragment>
      <Row>
        <Render condition={!isEdit}>
          <Col xs={12} className="transition-all">
            <DefaultInputLabel label={label} required={required} />
            <Info className="color-info mr-space" />
            <span className="font-size-tiny">
              File types allowed for upload: *.pdf *.doc *.docx *.xls *.xlsx
            </span>
            <div
              className={toggledClass('with-errors', fileErrors?.length > 0)}
            >
              <FilePond
                name={name}
                server={server}
                onprocessfiles={handleProcessedFiles}
                ref={(ref) => (filePondRef.current = ref)}
                allowMultiple={allowMultiple}
                acceptedFileTypes={allowedFileTypes}
                fileValidateTypeLabelExpectedTypesMap={FILE_TYPE_MAP}
                {...filePondProps}
              />
            </div>
            <ErrorDisplay errors={fileErrors} />
          </Col>
        </Render>
        <Render condition={hasFiles}>
          <Col xs={12} className="transition-all animate-fade-in">
            <Render condition={withComments}>
              <SmartInputBlock
                name={formatFieldName(commentsFieldname)}
                onChangeOverride={
                  allowMultiple
                    ? (e: any) =>
                        handleFileFieldChange(
                          e,
                          InputType.TextArea,
                          commentsFieldname
                        )
                    : undefined
                }
                label="Comments"
                placeholder="Enter comments here..."
                type={InputType.TextArea}
                colProps={ColumnLayout.Full}
                noSidePadding
              />
            </Render>
          </Col>
          {renderAdditionalFormComponents &&
            renderAdditionalFormComponents(
              handleFileFieldChange,
              formatFieldName
            )}
        </Render>
      </Row>
    </Fragment>
  );
};
