import {
  GridCellProps,
  GridColumn,
  GridHeaderCellProps,
} from '@progress/kendo-react-grid';
import { Tooltip } from '@progress/kendo-react-tooltip';
import { LightPageHeader, PageSectionHeading } from 'Components/Display';
import { AddButton } from 'Components/Form';
import {
  DateCell,
  EditDeleteCell,
  FilterBarMainGrid,
  GridButtonSection,
} from 'Components/Grid';
import { SecureComponent } from 'Components/Security';
import { FormProvider, GridProvider } from 'Context';
import {
  defaultGridProps,
  FilterBarFilterType,
  FilterConfig,
  IFilterConfig,
  StaticFilterConfig,
} from 'Models';
import { Permission } from 'Models/Templates/Permission/Permission';
import { ENDPOINTS } from 'Models/Templates/Routes/Routes';
import { addEditRoute, AddEditSubRoute, ROUTES } from 'Navigation';
import React, { HTMLProps } from 'react';
import { buildRoute, EMPTY_GUID, useGridSearch } from 'Utilities';

class InternalNoteSearch {
  id?: string;
  searchKey?: string;
  referralId?: string;
}

export enum InternalNoteGridIds {
  DetailedSearch = 'detailedSearch',
}

export const InternalNotes: React.FC<InternalNotesFilteredGridProps> = ({
  id,
  ...props
}) => {
  return (
    <GridProvider entityName="Internal Note">
      <PageSectionHeading>Internal Notes</PageSectionHeading>
      <InternalNotesFilteredGrid id={id} />
    </GridProvider>
  );
};

export type InternalNotesFilteredGridProps =
  HTMLProps<InternalNotesFilteredGridProps> & {
    id?: string;
  };

export const InternalNotesFilteredGrid: React.FC<
  InternalNotesFilteredGridProps
> = ({ id, ...props }) => {
  const noteSearch = new InternalNoteSearch();
  noteSearch.referralId = id;
  return (
    <FormProvider formModel={noteSearch}>
      <InternalNotesGrid id={id} />
    </FormProvider>
  );
};

export const InternalNotesGrid: React.FC<InternalNotesFilteredGridProps> = ({
  id,
  ...props
}) => {
  const filters: IFilterConfig[] = [
    new FilterConfig('searchKey', FilterBarFilterType.SearchText, 'Title'),
    new StaticFilterConfig('referralId'),
  ];
  const [gridToggler] = useGridSearch();

  let endpoint = ENDPOINTS.internalnotes.read;

  return (
    <React.Fragment>
      <SecureComponent permissions={[Permission.AddInternalNote]}>
        <GridButtonSection>
          <AddButton
            url={buildRoute(
              ROUTES.ADD_EDIT,
              AddEditSubRoute.InternalNotes,
              EMPTY_GUID,
              id
            )}
          >
            New Internal Note
          </AddButton>
        </GridButtonSection>
      </SecureComponent>
      <Tooltip openDelay={100} position="bottom" anchorElement="target">
        <FilterBarMainGrid
          isMainGrid
          filterBarProps={{
            filterConfigurations: filters,
            filterValues: { referralId: gridToggler.referralId },
          }}
          refreshToggle={gridToggler}
          readEndpoint={endpoint}
          {...defaultGridProps}
        >
          <GridColumn
            field="dateCreated"
            title="Created Date"
            cell={dotNetDateColumn}
          />
          <GridColumn field="title" title="Title" />
          <GridColumn
            field="description"
            title="Description"
            cell={descriptionCell}
            headerCell={descriptionHeader}
          />
          <GridColumn
            field="id"
            width="120"
            title="Actions"
            cell={ActionsCell}
          />
        </FilterBarMainGrid>
      </Tooltip>
    </React.Fragment>
  );
};

const dotNetDateColumn: React.FC<GridCellProps> = (props: GridCellProps) => {
  return <DateCell {...props} dateFormat={'MM/DD/YYYY'} />;
};

const ActionsCell: React.FC<GridCellProps> = (props: GridCellProps) => {
  return (
    <EditDeleteCell
      editRoute={addEditRoute(AddEditSubRoute.InternalNotes)}
      editPermissions={[Permission.EditInternalNote]}
      deleteEndpoint={ENDPOINTS.internalnotes.base}
      deletePermissions={[Permission.DeleteInternalNote]}
      {...props}
    />
  );
};

const descriptionCell: React.FC<GridCellProps> = (props: GridCellProps) => {
  return (
    <td title={props.dataItem.description}>{props.dataItem.description}</td>
  );
};
const descriptionHeader: React.FC<GridHeaderCellProps> = (
  props: GridHeaderCellProps
) => {
  return (
    <a className="k-link">
      <span title={props.title}>{props.title}</span>
    </a>
  );
};
