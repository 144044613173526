import React from "react";
import { AuditLog, AuditLogProps } from "./AuditLog";
import { CollapsibleTextHeaderSection } from "./CollapsibleSection";
import { SecureComponent } from "Components/Security";
import { Permission } from "Models/Templates/Permission/Permission";

export const CollapsibleAuditLog: React.FC<AuditLogProps> = ({
  title = "Audit Log",
  ...auditLogProps
}) => {
  return (
    <SecureComponent permissions={[Permission.ViewAuditLogs]}>
      <CollapsibleTextHeaderSection
        defaultClosed
        className="collapsible-audit-log"
        title={title}
      >
        <AuditLog {...auditLogProps} />
      </CollapsibleTextHeaderSection>
    </SecureComponent>
  );
};
