import {
  GridCellProps,
  GridColumn as Column,
} from '@progress/kendo-react-grid';
import { AddButton } from 'Components/Form';
import {
  DateCell,
  EditDeleteCell,
  FilterBarMainGrid,
  GridButtonSection,
  LinkCell,
} from 'Components/Grid';
import { SecureComponent } from 'Components/Security';
import { FormProvider, GridProvider } from 'Context';
import { Permission } from 'Models/Templates/Permission/Permission';
import { ENDPOINTS } from 'Models/Templates/Routes/Routes';
import {
  addEditRoute,
  AddEditSubRoute,
  ClaimsSubRoute,
  ROUTES,
} from 'Navigation';
import React, { HTMLProps } from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { buildRoute, EMPTY_GUID, useGridSearch } from 'Utilities';
import { LightPageHeader } from '../../Components/Display';
import {
  defaultGridProps,
  FilterBarFilterType,
  FilterConfig,
  IFilterConfig,
  StaticFilterConfig,
} from '../../Models';

class ClaimSearch {
  searchKey?: string;
  id?: string;
  claimDate?: string[];
  client?: string[];
  claimantId?: string;
}
export enum ClaimGridIds {
  DetailedSearch = 'detailedSearch',
}

export const Claims: React.FC = () => {
  return (
    <GridProvider entityName="Claim">
      <Breadcrumb tag="nav" listTag="div">
        <BreadcrumbItem>
          <Link to={ROUTES.DASHBOARD}>Home</Link>
        </BreadcrumbItem>
        <BreadcrumbItem>Claims</BreadcrumbItem>
      </Breadcrumb>
      <LightPageHeader tall noBorder className="page-header-shadow">
        <h1>Claims</h1>
      </LightPageHeader>
      <ClaimsFilteredGrid />
    </GridProvider>
  );
};

export type ClaimsFilteredGridProps = HTMLProps<ClaimsFilteredGridProps> & {
  id?: string;
};

export const ClaimsFilteredGrid: React.FC<ClaimsFilteredGridProps> = ({
  id,
  ...props
}) => {
  const claimSearch = new ClaimSearch();

  claimSearch.claimantId = id;

  return (
    <FormProvider formModel={claimSearch}>
      <ClaimsGrid id={id} />
    </FormProvider>
  );
};

const ClaimsGrid: React.FC<ClaimsFilteredGridProps> = ({ id, ...props }) => {
  const filters: IFilterConfig[] = [
    new FilterConfig('searchKey', FilterBarFilterType.SearchText, 'Claim no.'),
    new FilterConfig('claimDate', FilterBarFilterType.DateRange, 'Claim Date'),
    new FilterConfig(
      'clients',
      FilterBarFilterType.AsyncDropdown,
      'Client',
      {
        readEndpoint: ENDPOINTS.clients.endpointString('DropdownItems'),
      },
      (value) => value.name,
      true
    ),
    new StaticFilterConfig('claimantId'),
  ];
  const [gridToggler] = useGridSearch();

  let endpoint = ENDPOINTS.claims.read;

  return (
    <React.Fragment>
      <SecureComponent permissions={[Permission.AddClaim]}>
        <GridButtonSection>
          <AddButton
            url={buildRoute(
              ROUTES.ADD_EDIT,
              AddEditSubRoute.Claims,
              EMPTY_GUID,
              id
            )}
          >
            New Claim
          </AddButton>
        </GridButtonSection>
      </SecureComponent>
      <FilterBarMainGrid
        isMainGrid
        filterBarProps={{
          filterConfigurations: filters,
          filterValues: { claimantId: gridToggler.claimantId },
        }}
        refreshToggle={gridToggler}
        readEndpoint={endpoint}
        {...defaultGridProps}
      >
        <Column
          field="claimNumber"
          cell={claimNumberCell}
          title="Claim Number"
        />
        <Column field="clientName" title="Client" />
        <Column field="claimDate" cell={dotNetDateColumn} title="Claim Date" />
        <Column
          field="dateOfInjury"
          cell={dotNetDateColumn}
          title="Date of Injury"
        />
        <Column field="adjusterName" title="Adjuster" />
        <Column field="id" width="80" title="Actions" cell={ActionsCell} />
      </FilterBarMainGrid>
    </React.Fragment>
  );
};

const ActionsCell: React.FC<GridCellProps> = (props: GridCellProps) => {
  return (
    <EditDeleteCell
      editRoute={addEditRoute(AddEditSubRoute.Claims)}
      editPermissions={[Permission.EditClaim]}
      deleteEndpoint={ENDPOINTS.claims.base}
      deletePermissions={[Permission.DeleteClaim]}
      {...props}
    />
  );
};
const dotNetDateColumn: React.FC<GridCellProps> = (props: GridCellProps) => {
  return <DateCell {...props} dateFormat={'MM/DD/YYYY'} />;
};

const claimNumberCell: React.FC<GridCellProps> = (props: GridCellProps) => {
  return (
    <LinkCell
      endpoint={ROUTES.CLAIM}
      linkField="id"
      subRoute={ClaimsSubRoute.View}
      {...props}
    ></LinkCell>
  );
};
