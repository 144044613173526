import './Dashboard.scss';
import React, { HTMLProps } from 'react';
import { PageCard } from '../PageCard';
import { fullClassName } from 'Utilities';
import AddCircleIcon from '@mui/icons-material/AddCircle';

export type DashboardCardProps = HTMLProps<HTMLDivElement> & {
  title: string;
  subTitle?: string;
};

export const DashboardCard: React.FC<DashboardCardProps> = ({
  title,
  children,
  className,
  subTitle,
  ...divProps
}) => {
  return (
    <PageCard
      className={fullClassName('dashboard-card', className)}
      noBottomMargin
      {...divProps}
    >
      <div className="px-20 pt-regular pb-20  w-100 h-100">
        <h4 className="font-primary-bold">{title}</h4>
        {subTitle && <h5 className="font-secondary-bold">{subTitle}</h5>}
        <div className=" pt-4 w-100 h-90">{children}</div>
      </div>
    </PageCard>
  );
};

export const AddNewDashboardCard: React.FC = () => {
  return (
    <div className="dashboard-card add-new-card-btn flex-center">
      <div className="flex-column flex-center">
        <AddCircleIcon
          className="color-primary mb-small"
          style={{ width: 32, height: 32 }}
        />
        <h3 className="btn-label">Add Another Widget</h3>
      </div>
    </div>
  );
};
