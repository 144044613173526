

  


export enum Permission { 
  Default = 1,
  ViewErrorLog = 3,
  FilePermission = 4,
  ViewAuditLogs = 151,
  ViewNumberGeneratorSettings = 251,
  EditNumberGeneratorSettings = 252,
  ViewFileStamps = 253,
  AddFileStamps = 254,
  EditFileStamps = 255,
  DeleteFileStamps = 256,
  ViewDynamicViews = 257,
  AddEditDynamicViews = 258,
  DeleteDynamicViews = 259,
  ViewTenantConfigurations = 260,
  EditTenantConfigurations = 261,
  AddNumberGeneratorSettings = 262,
  DeleteNumberGeneratorSettings = 263,
  ViewRole = 301,
  AddRole = 302,
  EditRole = 303,
  DeleteRole = 304,
  ViewUser = 305,
  AddUser = 306,
  EditUser = 307,
  DeleteUser = 308,
  ViewManagedReviewer = 309,
  AssignManagedReviewer = 310,
  UnassignedManagedReviewer = 311,
  CreateApiKeys = 401,
  EditApiKey = 402,
  DeleteApiKey = 403,
  ViewApiKeys = 404,
  RegenerateApiKey = 405,
  ResetApiSecret = 406,
  AddClaimant = 601,
  EditClaimant = 602,
  ViewClaimant = 603,
  DeleteClaimant = 621,
  AddReferral = 622,
  EditReferral = 623,
  ViewReferral = 624,
  DeleteReferrals = 625,
  UploadReferralDocument = 626,
  AddReferralDocument = 627,
  EditReferralDocument = 628,
  DeleteReferralDocument = 629,
  ViewReferralDocument = 630,
  CloseReferral = 631,
  UploadReferralRawDocument = 632,
  AddReferralRawDocument = 633,
  EditReferralRawDocument = 634,
  DeleteReferralRawDocument = 635,
  ViewReferralRawDocument = 636,
  ReopenReferral = 637,
  ViewAppointment = 641,
  CombineAppointmentDocument = 666,
  GetAppointmentCombinableDocuments = 667,
  ViewAppointmentCombinedFiles = 668,
  ViewAllDocuments = 669,
  AddAppointment = 642,
  EditAppointment = 643,
  DeleteAppointment = 644,
  UploadAppointmentDocument = 645,
  AddAppointmentDocument = 646,
  EditAppointmentDocument = 647,
  DeleteAppointmentDocument = 648,
  ViewAppointmentDocument = 649,
  AddEditAppointmentDocumentNote = 650,
  UploadAppointmentReport = 651,
  UploadAppointmentReportTemplate = 652,
  AddAppointmentReport = 653,
  EditAppointmentReport = 654,
  DeleteAppointmentReport = 655,
  ViewAppointmentReport = 656,
  AddEditAppointmentReportNote = 657,
  ViewAppointmentReportNote = 658,
  ViewAppointmentDocumentNote = 659,
  ShowNumberOfRequests = 660,
  ViewClient = 661,
  AddClient = 662,
  EditClient = 663,
  DeleteClient = 664,
  ViewAllClients = 665,
  ViewClaim = 681,
  AddClaim = 682,
  EditClaim = 683,
  DeleteClaim = 684,
  ViewReferralsRequest = 701,
  AddReferralsRequest = 702,
  EditReferralsRequest = 703,
  DeleteReferralsRequest = 704,
  ConvertReferralRequest = 705,
  ViewReferralsRequestDocument = 706,
  AddReferralsRequestDocument = 707,
  EditReferralsRequestDocument = 708,
  DeleteReferralsRequestDocument = 709,
  UploadReferralsRequestDocument = 710,
  AdjusterRestrictedFields = 711,
  ViewAllReferralSources = 712,
  CombineReferralsRequestDocument = 713,
  GetReferralsRequestCombinableDocuments = 714,
  ViewReferralsRequestCombinedFiles = 715,
  ViewReviewer = 721,
  AddReviewer = 722,
  EditReviewer = 723,
  DeleteReviewer = 724,
  ConvertReviewer = 725,
  UploadReviewerCredentialingForm = 726,
  AddReviewerCredentialingForm = 727,
  EditReviewerCredentialingForm = 728,
  DeleteReviewerCredentialingForm = 729,
  ViewReviewerCredentialingForm = 730,
  UploadReviewerLicenseDocument = 731,
  AddReviewerLicenseDocument = 732,
  EditReviewerLicenseDocument = 733,
  DeleteReviewerLicenseDocument = 734,
  ViewReviewerLicenseDocument = 735,
  AddBoardCertificationDocument = 736,
  ViewBoardCertificationDocument = 737,
  DeleteBoardCertificationDocument = 738,
  UploadBoardCertificationDocument = 739,
  EditBoardCertificationDocument = 740,
  ViewPhysician = 741,
  AddPhysician = 742,
  EditPhysician = 743,
  DeletePhysician = 744,
  ViewDashboard = 761,
  ViewDashboardNewReferralsTile = 762,
  ViewDashboardNewReferralsGraph = 763,
  ViewDashboardCompletedReferralsGraph = 764,
  ViewGuideline = 801,
  AddGuideline = 802,
  EditGuideline = 803,
  DeleteGuideline = 804,
  ViewEscalation = 1001,
  AddEscalation = 1002,
  EditEscalation = 1003,
  DeleteEscalation = 1004,
  AddNote = 826,
  EditNote = 827,
  DeleteNote = 828,
  ViewNote = 829,
  AddInternalNote = 830,
  EditInternalNote = 831,
  DeleteInternalNote = 832,
  ViewInternalNote = 833,
  EditAssignedToWriters = 834,
  DeleteAssignedToWriters = 835,
  ExportCsvInvoices = 836,
  ViewEvalTypes = 1501,
  AddEvalTypes = 1502,
  EditEvalTypes = 1503,
  DeleteEvalTypes = 1504,
  ViewLineOfBusiness = 1505,
  AddLineOfBusiness = 1506,
  EditLineOfBusiness = 1507,
  DeleteLineOfBusiness = 1508,
  DeleteAuditQueue = 2002,
  AddAuditReport = 2004,
  ExportCsvAuditReport = 2005,
  ManagedReviewerFilters = 1021,
  AdminReviewerFilters = 1022,
  ViewOpenCases = 2021,
  ViewClosedCases = 2022,
  ViewReviewedReferrals = 2023,
  ViewPendingAppointments = 2024,
  ViewNewReferrals = 2025,
  ViewAllClaimants = 2026,
  ViewAssignedToWriters = 2027,
  ViewReviewers = 2028,
  ViewMyInvoices = 2029,
  ViewInvoices = 2030,
  ViewEscalationsExport = 2031,
  ViewEntities = 2032,
  ViewAuditReport = 2033,
  ViewAuditQueue = 2034,
  ViewGuidelines = 2035,
  ViewPrimaryOperations = 2051,
  ViewDoctorReviewerOperations = 2052,
  ViewWriterOperations = 2053,
  ViewQaOperations = 2054,
  ViewMedicalDirectorQa = 2055,
  AllowMultipleReviewOperations = 2056,
  DeleteDoctorReviewerOperations = 2057,
  EditReportAfterReviewed = 3000,
  AddReferralsRequestCommercial = 4002,
  ViewReferralsRequestCommercial = 4001,
  ViewPendingAppointmentsCommercial = 4003
}

export class DomainPermission {
  constructor(
    Value: number,
    Name: string,
    GroupName: string,
    Description: string,
    DateAdded?: string,
    JiraTicket?: string
  ) {
    this.Value = Value;
    this.Name = Name;
    this.GroupName = GroupName;
    this.Description = Description;
    this.DateAdded = DateAdded;
    this.JiraTicket = JiraTicket;
  }
  Value?: number;
  Name?: string;
  GroupName?: string;
  Description?: string;
  DateAdded?: string;
  JiraTicket?: string; 
}

export const DomainPermissions = [
  new DomainPermission(1,     "Default",  "Non-Assignable",  "Default permission for starting a solution" ),
  new DomainPermission(3,   "View Error Log",  "Administration",  "Allows a user to view the Elmah error log." ),
  new DomainPermission(4,     "File Permission",  "Non-Assignable",  "A permission to prevent unauthorized/invalid access to Not Implemented File Controller methods." ),
  new DomainPermission(151,   "Audit Logs - View",  "Administration",  "Allows a user to view Audit Logs" ),
  new DomainPermission(251,   "Number Generator Settings - View",  "System",  "Allows a user to view the Number Generator Settings" ),
  new DomainPermission(252,   "Number Generator Settings - Edit",  "System",  "Allows a user to edit the Number Generator Settings" ),
  new DomainPermission(253,   "File Stamps - View",  "System",  "Allows a user to view File Stamps" ),
  new DomainPermission(254,   "File Stamps - Add",  "System",  "Allows a user to add File Stamps" ),
  new DomainPermission(255,   "File Stamps - Edit",  "System",  "Allows a user to edit File Stamps" ),
  new DomainPermission(256,   "File Stamps - Delete",  "System",  "Allows a user to delete File Stamps" ),
  new DomainPermission(257,   "Dynamic Views - View",  "System",  "Allows a user to view the Dynamic Views" ),
  new DomainPermission(258,   "Dynamic Views - Add/Edit",  "System",  "Allows a user to add and edit the Dynamic Views" ),
  new DomainPermission(259,   "Dynamic Views - Delete",  "System",  "Allows a user to delete the Dynamic Views" ),
  new DomainPermission(260,   "Tenant Configurations - View",  "System",  "Allows a user to view the Tenant Configurations" ),
  new DomainPermission(261,   "Tenant Configurations - Edit",  "System",  "Allows a user to edit the Tenant Configurations" ),
  new DomainPermission(262,   "Number Generator Settings - Add",  "System",  "Allows a user to add a Number Generator Setting" ),
  new DomainPermission(263,   "Number Generator Settings - Delete",  "System",  "Allows a user to delete a Number Generator Setting" ),
  new DomainPermission(301,   "Role - View",  "User Management",  "Allows a user to view Role" ),
  new DomainPermission(302,   "Role - Add",  "User Management",  "Allows a user to add Role" ),
  new DomainPermission(303,   "Role - Edit",  "User Management",  "Allows a user to edit Role" ),
  new DomainPermission(304,   "Role - Delete",  "User Management",  "Allows a user to delete Role" ),
  new DomainPermission(305,   "User - View",  "User Management",  "Allows a user to view a User" ),
  new DomainPermission(306,   "User - Add",  "User Management",  "Allows a user to add a User" ),
  new DomainPermission(307,   "User - Edit",  "User Management",  "Allows a user to edit a User" ),
  new DomainPermission(308,   "User - Delete",  "User Management",  "Allows a user to delete a User" ),
  new DomainPermission(309,   "Managed Reviewer - View",  "User Management",  "Allows a user to view the Managed Reviewers Admin Secttion " ),
  new DomainPermission(310,   "Managed Reviewer - Assign",  "User Management",  "Allows a user to Assign Managed Reviewers to a Doctor Admin" ),
  new DomainPermission(311,   "Managed Reviewer - Unassigned",  "User Management",  "Allows a user to Unassigned Managed Reviewers from a Doctor Admin" ),
  new DomainPermission(401,   "Simpat Tech API - Create API Keys",  "Integrations",  "Allows a user to create a Simpat Tech API Key" ),
  new DomainPermission(402,   "Simpat Tech API - Edit API Key",  "Integrations",  "Allows a user to edit a Simpat Tech API Key" ),
  new DomainPermission(403,   "Simpat Tech API - Delete API Key",  "Integrations",  "Allows a user to delete a Simpat Tech API Key" ),
  new DomainPermission(404,   "Simpat Tech API - View API Keys",  "Integrations",  "Allows a user to view Simpat Tech API Keys" ),
  new DomainPermission(405,   "Simpat Tech API - Regenerate API Key",  "Integrations",  "Allows a user to regenerate the Simpat Tech API Key" ),
  new DomainPermission(406,   "Simpat Tech API - Reset API Secret",  "Integrations",  "Allows a user to reset the Simpat Tech API Secret" ),
  new DomainPermission(601,   "Claimant - Create Claimant",  "Claimants",  "Allows a user to create a Claimant" ),
  new DomainPermission(602,   "Claimant - Edit a Claimant",  "Claimants",  "Allows a user to edit a Claimant" ),
  new DomainPermission(603,   "Claimant - View a Claimant",  "Claimants",  "Allows a user to view a Claimant" ),
  new DomainPermission(621,   "Claimant - Delete a Claimant",  "Claimants",  "Allows a user to delete a Claimant" ),
  new DomainPermission(622,   "Referral - Create Referrals",  "Referrals",  "Allows a user to create a Referrals" ),
  new DomainPermission(623,   "Referral - Edit a Referrals",  "Referrals",  "Allows a user to edit a Referrals" ),
  new DomainPermission(624,   "Referral - View a Referrals",  "Referrals",  "Allows a user to view a Referrals" ),
  new DomainPermission(625,   "Referral - Delete a Referrals",  "Referrals",  "Allows a user to delete a Referrals" ),
  new DomainPermission(626,   "Referral Document - Upload",  "Referrals",  "Allows a user to upload a Referral Document" ),
  new DomainPermission(627,   "Referral Document - Add",  "Referrals",  "Allows a user to add a Referral Document" ),
  new DomainPermission(628,   "Referral Document - Edit",  "Referrals",  "Allows a user to edit a Referral Document" ),
  new DomainPermission(629,   "Referral Document - Delete",  "Referrals",  "Allows a user to delete a Referral Document" ),
  new DomainPermission(630,   "Referral Document - View",  "Referrals",  "Allows a user to view a Referral Document" ),
  new DomainPermission(631,   "Referral  - Close Case",  "Referrals",  "Allows a user to Close a Case" ),
  new DomainPermission(632,   "Referral Raw Document - Upload",  "Referrals",  "Allows a user to upload a Referral Raw Document" ),
  new DomainPermission(633,   "Referral Raw Document - Add",  "Referrals",  "Allows a user to add a Referral Raw Document" ),
  new DomainPermission(634,   "Referral Raw Document - Edit",  "Referrals",  "Allows a user to edit a Referral Raw Document" ),
  new DomainPermission(635,   "Referral Raw Document - Delete",  "Referrals",  "Allows a user to delete a Referral Raw Document" ),
  new DomainPermission(636,   "Referral Raw Document - View",  "Referrals",  "Allows a user to view a Referral Raw Document" ),
  new DomainPermission(637,   "Referral  - Reopen Case",  "Referrals",  "Allows a user to reopen a Case" ),
  new DomainPermission(641,   "Appointment - View",  "Appointments",  "Allows a user to view Appointments" ),
  new DomainPermission(642,   "Appointment - Add",  "Appointments",  "Allows a user to add an Appointment" ),
  new DomainPermission(643,   "Appointment - Edit",  "Appointments",  "Allows a user to edit an Appointment" ),
  new DomainPermission(644,   "Appointment - Delete",  "Appointments",  "Allows a user to delete an Appointment" ),
  new DomainPermission(645,   "Appointment Document - Upload",  "Appointments",  "Allows a user to upload an Appointment Document" ),
  new DomainPermission(646,   "Appointment Document - Add",  "Appointments",  "Allows a user to add an Appointment Document" ),
  new DomainPermission(647,   "Appointment Document - Edit",  "Appointments",  "Allows a user to edit an Appointment Document" ),
  new DomainPermission(648,   "Appointment Document - Delete",  "Appointments",  "Allows a user to delete an Appointment Document" ),
  new DomainPermission(649,   "Appointment Document - View",  "Appointments",  "Allows a user to view an Appointment Document" ),
  new DomainPermission(650,   "Appointment Document Note - Add/Edit",  "Appointments",  "Allows a user to add/edit an Appointment Document Note" ),
  new DomainPermission(651,   "Appointment Report - Upload",  "Appointments",  "Allows a user to upload an Appointment Report" ),
  new DomainPermission(652,   "Appointment Report Template - Upload",  "Appointments",  "Allows a user to upload an Appointment Report Template" ),
  new DomainPermission(653,   "Appointment Report - Add",  "Appointments",  "Allows a user to add an Appointment Report" ),
  new DomainPermission(654,   "Appointment Report - Edit",  "Appointments",  "Allows a user to edit an Appointment Report" ),
  new DomainPermission(655,   "Appointment Report - Delete",  "Appointments",  "Allows a user to delete an Appointment Report" ),
  new DomainPermission(656,   "Appointment Report - View",  "Appointments",  "Allows a user to view an Appointment Report" ),
  new DomainPermission(657,   "Appointment Report Note - Add/Edit",  "Appointments",  "Allows a user to add/edit an Appointment Report Note" ),
  new DomainPermission(658,   "Appointment Report Note - View",  "Appointments",  "Allows a user to view an Appointment Report Note" ),
  new DomainPermission(659,   "Appointment Document Note - View",  "Appointments",  "Allows a user to view an Appointment Document Note" ),
  new DomainPermission(660,   "Appointment Report Number Of Requests - View",  "Appointments",  "Allows a user to view the Appointment Report's Number Of Requests field " ),
  new DomainPermission(661,   "Client - View",  "Clients",  "Allows a user to view Clients" ),
  new DomainPermission(662,   "Client - Add",  "Clients",  "Allows a user to add an Client" ),
  new DomainPermission(663,   "Client - Edit",  "Clients",  "Allows a user to edit an Client" ),
  new DomainPermission(664,   "Client - Delete",  "Clients",  "Allows a user to delete an Client" ),
  new DomainPermission(665,   "Client - View All (Admin)",  "Clients",  "Allows a user to be able to view information for All Clients" ),
  new DomainPermission(681,   "Claim - View",  "Claims",  "Allows a user to view Claims" ),
  new DomainPermission(682,   "Claim - Add",  "Claims",  "Allows a user to add an Claim" ),
  new DomainPermission(683,   "Claim - Edit",  "Claims",  "Allows a user to edit an Claim" ),
  new DomainPermission(684,   "Claim - Delete",  "Claims",  "Allows a user to delete an Claim" ),
  new DomainPermission(701,   "ReferralsRequest - View",  "ReferralsRequest",  "Allows a user to view ReferralsRequest" ),
  new DomainPermission(702,   "ReferralsRequest - Add",  "ReferralsRequest",  "Allows a user to add a ReferralsRequest" ),
  new DomainPermission(703,   "ReferralsRequest - Edit",  "ReferralsRequest",  "Allows a user to edit a ReferralsRequest" ),
  new DomainPermission(704,   "ReferralsRequest - Delete",  "ReferralsRequest",  "Allows a user to delete a ReferralsRequest" ),
  new DomainPermission(705,   "ReferralsRequest - Convert to Claimant",  "ReferralsRequest",  "Allows the user to convert a referral request into a claimant" ),
  new DomainPermission(706,   "Referral Request Document - View",  "ReferralsRequest",  "Allows a user to view Referral Request Document" ),
  new DomainPermission(707,   "Referral Request Document - Add",  "ReferralsRequest",  "Allows a user to add a Referral Request Document" ),
  new DomainPermission(708,   "Referral Request Document  - Edit",  "ReferralsRequest",  "Allows a user to edit a Referral Request Document" ),
  new DomainPermission(709,   "Referral Request Document  - Delete",  "ReferralsRequest",  "Allows a user to delete a Referral Request Document" ),
  new DomainPermission(710,   "Referral Request Document  - Upload",  "ReferralsRequest",  "Allows a user to upload a Referral Request Document" ),
  new DomainPermission(711,   "Adjuster Restricted Fields",  "ReferralsRequest",  "Allows a user to set the Adjuster Restricted Fields when Creating a Referral Request" ),
  new DomainPermission(712,   "ReferralsRequest - View All Referral Sources",  "ReferralsRequest",  "Allows a user to select a Referral Source from any client" ),
  new DomainPermission(721,   "Reviewer - View",  "Reviewers",  "Allows a user to view Reviewers" ),
  new DomainPermission(722,   "Reviewer - Add",  "Reviewers",  "Allows a user to add a Reviewer" ),
  new DomainPermission(723,   "Reviewer - Edit",  "Reviewers",  "Allows a user to edit a Reviewer" ),
  new DomainPermission(724,   "Reviewer - Delete",  "Reviewers",  "Allows a user to delete a Reviewer" ),
  new DomainPermission(725,   "Reviewer - Convert to User",  "Reviewers",  "Allows the user to convert a Reviewer into a User" ),
  new DomainPermission(726,   "Reviewer Credentialing Form - Upload",  "Reviewers",  "Allows a user to upload an Reviewer Credentialing Form" ),
  new DomainPermission(727,   "Reviewer Credentialing Form - Add",  "Reviewers",  "Allows a user to add an Reviewer Credentialing Form" ),
  new DomainPermission(728,   "Reviewer Credentialing Form - Edit",  "Reviewers",  "Allows a user to edit an Reviewer Credentialing Form" ),
  new DomainPermission(729,   "Reviewer Credentialing Form - Delete",  "Reviewers",  "Allows a user to delete an Reviewer Credentialing Form" ),
  new DomainPermission(730,   "Reviewer Credentialing Form - View",  "Reviewers",  "Allows a user to view an Reviewer Credentialing Form" ),
  new DomainPermission(731,   "Reviewer License Document - Upload",  "Reviewers",  "Allows a user to upload an Reviewer License Document" ),
  new DomainPermission(732,   "Reviewer License Document - Add",  "Reviewers",  "Allows a user to add an Reviewer License Document" ),
  new DomainPermission(733,   "Reviewer License Document - Edit",  "Reviewers",  "Allows a user to edit an Reviewer License Document" ),
  new DomainPermission(734,   "Reviewer License Document - Delete",  "Reviewers",  "Allows a user to delete an Reviewer License Document" ),
  new DomainPermission(735,   "Reviewer License Document - View",  "Reviewers",  "Allows a user to view an Reviewer License Document" ),
  new DomainPermission(736,   "Reviewer - Add Board Certification Document",  "Reviewers",  "Allows the user to upload Board Certification Documents" ),
  new DomainPermission(737,   "Reviewer - View Board Certification Document",  "Reviewers",  "Allows the user to view Board Certification Documents" ),
  new DomainPermission(738,   "Reviewer - Delete Board Certification Document",  "Reviewers",  "Allows the user to delete Board Certification Documents" ),
  new DomainPermission(739,   "Reviewer - Upload Board Certification Document",  "Reviewers",  "Allows the user to upload Board Certification Documents" ),
  new DomainPermission(740,   "Reviewer - Edit Board Certification Document",  "Reviewers",  "Allows the user to edit Board Certification Documents" ),
  new DomainPermission(741,   "Physician - View",  "Physicians",  "Allows a user to view Physicians" ),
  new DomainPermission(742,   "Physician - Add",  "Physicians",  "Allows a user to add an Physician" ),
  new DomainPermission(743,   "Physician - Edit",  "Physicians",  "Allows a user to edit an Physician" ),
  new DomainPermission(744,   "Physician - Delete",  "Physicians",  "Allows a user to delete an Physician" ),
  new DomainPermission(761,   "Dashboard - View",  "Dashboard",  "Allows a user to view the Dashboard main page" ),
  new DomainPermission(762,   "Dashboard New Referrals Tile - View",  "Dashboard",  "Allows a user to view New Referrals Tile in the Dashboard" ),
  new DomainPermission(763,   "Dashboard New Referrals Graph - View",  "Dashboard",  "Allows a user to view New Referrals Graph in the Dashboard" ),
  new DomainPermission(764,   "Dashboard Completed Referrals Graph - View",  "Dashboard",  "Allows a user to view Completed Referrals Graph in the Dashboard" ),
  new DomainPermission(801,   "Guideline - View",  "Guidelines",  "Allows a user to view Guidelines" ),
  new DomainPermission(802,   "Guideline - Add",  "Guidelines",  "Allows a user to add a Guideline" ),
  new DomainPermission(803,   "Guideline - Edit",  "Guidelines",  "Allows a user to edit a Guideline" ),
  new DomainPermission(804,   "Guideline - Delete",  "Guidelines",  "Allows a user to delete an Guideline" ),
  new DomainPermission(1001,   "Escalation - View",  "Escalations",  "Allows a user to view Escalations" ),
  new DomainPermission(1002,   "Escalation - Add",  "Escalations",  "Allows a user to add an Escalation" ),
  new DomainPermission(1003,   "Escalation - Edit",  "Escalations",  "Allows a user to edit an Escalation" ),
  new DomainPermission(1004,   "Escalation - Delete",  "Escalations",  "Allows a user to delete a Escalation" ),
  new DomainPermission(826,   "Notes - Add",  "Data Entry",  "Allows a user to add Notes" ),
  new DomainPermission(827,   "Notes - Edit",  "Data Entry",  "Allows a user to edit Notes" ),
  new DomainPermission(828,   "Notes - Delete",  "Data Entry",  "Allows a user to delete Notes" ),
  new DomainPermission(829,   "Notes - View",  "Data Entry",  "Allows a user to view Notes" ),
  new DomainPermission(830,   "Internal Notes - Add",  "Data Entry",  "Allows a user to add Internal Notes" ),
  new DomainPermission(831,   "Internal Notes - Edit",  "Data Entry",  "Allows a user to edit Internal Notes" ),
  new DomainPermission(832,   "Internal Notes - Delete",  "Data Entry",  "Allows a user to delete Internal Notes" ),
  new DomainPermission(833,   "Internal Notes - View",  "Data Entry",  "Allows a user to view Internal Notes" ),
  new DomainPermission(834,   "Assigned To Writers - Edit",  "Case Management",  "Allows a user to edit Assigned To Writers" ),
  new DomainPermission(835,   "Assigned To Writers - Delete",  "Case Management",  "Allows a user to delete Assigned To Writers" ),
  new DomainPermission(836,   "Invoices - Export CSV",  "Quality Assurance & Reports",  "Allows a user to export to CSV the Invoices grid" ),
  new DomainPermission(1501,   "Eval Types - View",  "Configuration",  "Allows a user to view Evaluation Type" ),
  new DomainPermission(1502,   "Eval Types - Add",  "Configuration",  "Allows a user to add a Evaluation Type" ),
  new DomainPermission(1503,   "Eval Types - Edit",  "Configuration",  "Allows a user to edit a Evaluation Type" ),
  new DomainPermission(1504,   "Eval Types - Delete",  "Configuration",  "Allows a user to delete an Evaluation Type" ),
  new DomainPermission(2002,   "Audit Queue - Delete",  "Quality Assurance & Reports",  "Allows a user to delete Audit Queue" ),
  new DomainPermission(2004,   "Audit Report - Add",  "Quality Assurance & Reports",  "Allows a user to add an Audit Report" ),
  new DomainPermission(2005,   "Audit Report - Export CSV",  "Quality Assurance & Reports",  "Allows a user to export to CSV the Audit Report grid" ),
  new DomainPermission(1021,   "Managed Reviewer Filters",  "Standalone Features",  "Allows a user to view the Reviewer filter in grids with their assigned Reviewers" ),
  new DomainPermission(1022,   "Managed Reviewer Filters - Admin",  "Standalone Features",  "Allows a user to view the Reviewer filter including all reviewers in the system" ),
  new DomainPermission(2021,   "Open Referrals - View",  "Menus",  "Allows a user to view Open Referrals menu item" ),
  new DomainPermission(2022,   "Closed Referrals - View",  "Menus",  "Allows a user to view Closed Referrals menu item" ),
  new DomainPermission(2023,   "Reviewed Referrals - View",  "Menus",  "Allows a user to view Reviewed Referrals menu item" ),
  new DomainPermission(2024,   "Pending Appointments - View",  "Menus",  "Allows a user to view Pending Appointments menu item" ),
  new DomainPermission(2025,   "New Referrals - View",  "Menus",  "Allows a user to view New Referrals menu item" ),
  new DomainPermission(2026,   "All Referrals - View",  "Menus",  "Allows a user to view All Referrals menu item" ),
  new DomainPermission(2027,   "Assigned To Writers - View",  "Menus",  "Allows a user to view Assigned To Writers" ),
  new DomainPermission(2028,   "Reviewers - View",  "Menus",  "Allows a user to view Reviewers menu item" ),
  new DomainPermission(2029,   "My Invoices - View",  "Menus",  "Allows a user to view My Invoices" ),
  new DomainPermission(2030,   "Invoices - View",  "Menus",  "Allows a user to view Invoices" ),
  new DomainPermission(2031,   "Escalations Export - View",  "Menus",  "Allows a user to view Escalation Exports menu item" ),
  new DomainPermission(2032,   "Entities - View",  "Menus",  "Allows a user to view Entities menu item" ),
  new DomainPermission(2033,   "Audit Report - View",  "Quality Assurance & Reports",  "Allows a user to view Audit Reports" ),
  new DomainPermission(2034,   "Audit Queue - View",  "Quality Assurance & Reports",  "Allows a user to view Audit Queue" ),
  new DomainPermission(2035,   "Guidelines - View",  "Menus",  "Allows a user to view the Guidelines menu item" ),
  new DomainPermission(2051,   "Primary Operations - View",  "Report Document Tabs",  "Allows a user to view the Primary Operations tab in the Report Document Drawer" ),
  new DomainPermission(2052,   "Doctor/Reviewer Operations - View",  "Report Document Tabs",  "Allows a user to view the Doctor/Reviewer Operations tab in the Report Document Drawer" ),
  new DomainPermission(2053,   "Writer Operations - View",  "Report Document Tabs",  "Allows a user to view the Writer Operations tab in the Report Document Drawer" ),
  new DomainPermission(2054,   "QA Operations - View",  "Report Document Tabs",  "Allows a user to view the QA Operations tab in the Report Document Drawer" ),
  new DomainPermission(2055,   "Medical Director QA - View",  "Report Document Tabs",  "Allows a user to view the Medical Director QA tab in the Report Document Drawer" ),
  new DomainPermission(2056,   "Allow Multiple Review Operations",  "Report Document Tabs",  "Allows a user to add more than one Review Operation in the Report Document Drawer" ),
  new DomainPermission(2057,   "Doctor/Reviewer Operations - Delete",  "Report Document Tabs",  "Allows a user to delete the Doctor/Reviewer Operations tab in the Report Document Drawer" ),
  new DomainPermission(3000,   "Appointment Report - Edit after reviewed",  "Appointments",  "Allows a user to Edit a report after it has been reviewed" )
];

