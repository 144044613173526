import ArticleIcon from '@mui/icons-material/Article';
import BookOnlineIcon from '@mui/icons-material/BookOnline';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import DynamicFormIcon from '@mui/icons-material/DynamicForm';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import { Appointments } from 'Features/Appointments';
import { Claimants } from 'Features/Claimants';
import { Claims } from 'Features/Claims';
import { Physicians } from 'Features/Configuration';
import { Referrals } from 'Features/Referrals';
import { ReferralsRequests } from 'Features/ReferralsRequests';
import { Permission } from 'Models/Templates/Permission/Permission';
import { DropdownSubRouteConfig, SubRoutes } from 'Navigation/NavigationTypes';
import React from 'react';

export enum EntitiesSubRoute {
  Referrals = '/Referrals',
  ReferralsRequests = '/ReferralRequests',
  Claimants = '/Claimants',
  Claims = '/Claims',
  Appointments = '/Appointments',
  Physicians = '/Physicians',
}

export const EntitiesSubRoutes: SubRoutes = {
  [EntitiesSubRoute.Referrals]: new DropdownSubRouteConfig(
    EntitiesSubRoute.Referrals,
    'Referrals',
    Referrals,
    [Permission.ViewReferral],
    false,
    {},
    <DynamicFormIcon />
  ),
  [EntitiesSubRoute.ReferralsRequests]: new DropdownSubRouteConfig(
    EntitiesSubRoute.ReferralsRequests,
    'Referrals Requests',
    ReferralsRequests,
    [Permission.ViewReferralsRequest],
    false,
    {},
    <NoteAddIcon />
  ),
  [EntitiesSubRoute.Claimants]: new DropdownSubRouteConfig(
    EntitiesSubRoute.Claimants,
    'Claimants',
    Claimants,
    [Permission.ViewClaimant],
    false,
    {},
    <ContactPageIcon />
  ),
  [EntitiesSubRoute.Appointments]: new DropdownSubRouteConfig(
    EntitiesSubRoute.Appointments,
    'Appointments',
    Appointments,
    [Permission.ViewAppointment],
    false,
    {},
    <BookOnlineIcon />
  ),
  [EntitiesSubRoute.Claims]: new DropdownSubRouteConfig(
    EntitiesSubRoute.Claims,
    'Claims',
    Claims,
    [Permission.ViewClaim],
    false,
    {},
    <ArticleIcon />
  ),
  [EntitiesSubRoute.Physicians]: new DropdownSubRouteConfig(
    EntitiesSubRoute.Physicians,
    'Physicians',
    Physicians,
    [Permission.ViewPhysician],
    false,
    {},
    <ArticleIcon />
  ),
};
