import React, { useEffect, useState } from 'react';
import Refresh from '@mui/icons-material/Refresh'
import { InputBlock } from 'Components/Form';
import { InputType } from 'Models';
import './AutoRefresh.scss';
import { DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import { CheckboxChangeEvent } from '@progress/kendo-react-inputs';

interface AutoRefreshProps {
    onRefresh?: () => void;
}

export const AutoRefresh: React.FC<AutoRefreshProps> = ({ onRefresh }) => {
    const options = [
        { id: 30, name: '30' },
        { id: 60, name: '60' }
    ]

    const [selectedInterval, setSelectedInterval] = useState({ id: 30, name: '30' })
    const [autoRefreshEnabled, setAutoRefreshEnabled] = useState(false)
    const [refreshButtonEnabled, setRefreshButtonEnabled] = useState(true)

    //autorefresh method, calls onRefresh when button is clicked, disables the button for 2 seconds
    const refresh = () => {
        if (onRefresh) {
            onRefresh()
            setRefreshButtonEnabled(false)
            setTimeout(() => {
                setRefreshButtonEnabled(true)
            }, 2000)
        }
    }

    useEffect(() => {
        // if selectedInterval.id is a number greater than zero, then create the interval, otherwise don't have it
        if (!selectedInterval.id) {
            return
        }
        const interval = setInterval(() => {
            if (onRefresh && autoRefreshEnabled) {
                onRefresh()
            }
        }, selectedInterval.id * 1000)

        return () => clearInterval(interval)
    }, [onRefresh, selectedInterval.id, autoRefreshEnabled])

    const handleIntervalChange = (e: DropDownListChangeEvent) => {
        setSelectedInterval(e.target.value);
    };

    const handleAutoRefreshChange = (e: CheckboxChangeEvent) => {
        setAutoRefreshEnabled(e.value);
    };

    return (
        <div className='auto-refresh-wrapper'>
            <div className='auto-refresh-container'>
                <div className={'refresh-icon' + (!refreshButtonEnabled ? ' disabled' : '')}>
                    <Refresh onClick={refresh} />
                </div>
                {autoRefreshEnabled &&
                    <InputBlock
                        disabled={!autoRefreshEnabled}
                        name='interval'
                        type={InputType.DropDown}
                        value={selectedInterval}
                        data={options}
                        className='interval-selector'
                        onChange={handleIntervalChange}
                    />
                }
                <InputBlock
                    name='enable-refresh'
                    label="Auto-refresh"
                    type={InputType.Checkbox}
                    value={autoRefreshEnabled}
                    onChange={handleAutoRefreshChange}

                />
            </div>
        </div>
    );
}
