import {
  MaskedTextBox,
  MaskedTextBoxProps,
} from '@progress/kendo-react-inputs';
import * as React from 'react';

export const SSNCustomInput: React.FC<MaskedTextBoxProps> = ({
  mask,
  placeholder,
  className,
  ...allProps
}) => {
  return <MaskedTextBox mask="AAA-AA-0000" {...allProps} />;
};
