import './PageCard.scss';
import React, { Fragment, HTMLProps } from 'react';
import {
  fullClassName,
  isNullEmptyOrUndefined,
  toggledClass,
} from 'Utilities/HelperFunctions';
import { ColumnConfig } from 'Models/SharedModels';
import { Col, Row } from 'reactstrap';
import { PageSectionHeading } from './Headings';
import { Render } from './Render';
import { ActionsDropdownConfig } from 'Models';
import {
  useFormHelpers,
  useFormState,
} from 'Context';
import { AsyncStatusesLoadingSection } from './LoadingSection';
import { Collapse } from 'react-collapse';
import { useToggler } from 'Utilities';
import { CollapsibleArrow } from './CollapsibleSection';
import {
  FormFilterChips,
  FormFilterChipsProps,
  TextButton,
} from 'Components/Form';

type PageCardProps = HTMLProps<HTMLDivElement> & {
  title?: string;
  titleActionsConfig?: ActionsDropdownConfig;
  titleButton?: any;
  flatBorder?: boolean;
  noSectionBorders?: boolean;
  leftTitleButton?: any;
  noBottomMargin?: boolean;
};

export const PageCard: React.FC<PageCardProps> = ({
  children,
  title,
  className,
  titleActionsConfig,
  titleButton,
  flatBorder,
  noSectionBorders,
  leftTitleButton,
  noBottomMargin,
  ...otherProps
}) => {
  return (
    <Fragment>
      <Render condition={title !== undefined}>
        <PageSectionHeading
          name={title}
          titleActionsConfig={titleActionsConfig}
          titleButton={titleButton}
          leftTitleButton={leftTitleButton}
        />
      </Render>
      <div
        className={fullClassName(
          'page-card bg-light',
          className,
          toggledClass('flat-border', flatBorder),
          toggledClass('no-section-borders', noSectionBorders),
          toggledClass('no-bottom-margin', noBottomMargin)
        )}
        {...otherProps}
      >
        {children}
      </div>
    </Fragment>
  );
};

export const PageCardRow: React.FC = ({ children }) => (
  <Row className="page-card-row">{children}</Row>
);

type PageCardSectionProps = {
  columnWidth?: ColumnConfig;
  title?: string;
  titleButtons?: any;
  short?: boolean;
  centerContent?: boolean;
  thin?: boolean;
  rightTitleButtons?: any;
  subTitle?: string;
  subTitleClassName?: string;
  noTitleMargin?: boolean;
  noTopPadding?: boolean;
  noBottomPadding?: boolean;
  inputSection?: boolean;
  largeBottomPadding?: boolean;
  className?: string;
};

export const PageCardSection: React.FC<PageCardSectionProps> = ({
  columnWidth,
  title,
  titleButtons,
  short,
  centerContent,
  children,
  thin,
  rightTitleButtons,
  subTitle,
  noTitleMargin,
  noTopPadding,
  noBottomPadding,
  inputSection,
  largeBottomPadding,
  className,
  subTitleClassName,
}) => {
  return (
    <Col
      className={fullClassName(
        'card-section',
        toggledClass('short', short),
        toggledClass('centered-content', centerContent),
        toggledClass('thin', thin),
        toggledClass('no-top-padding', noTopPadding),
        toggledClass('pb-0', noBottomPadding),
        toggledClass('input-section', inputSection),
        toggledClass('pb-large', largeBottomPadding),
        className
      )}
      {...columnWidth}
    >
      <Render
        condition={title || titleButtons || rightTitleButtons || subTitle}
      >
        <div
          className={fullClassName(
            'full-width flex-vertical-center card-section-title',
            toggledClass('mb-regular', !noTitleMargin)
          )}
        >
          <div className="full-width flex-vertical-center">
            <Render condition={title || titleButtons}>
              <div className="flex-grow">
                <div>
                  <h4
                    className={toggledClass(
                      'mb-tiny flex-vertical-center',
                      !isNullEmptyOrUndefined(subTitle)
                    )}
                  >
                    {title}
                    {titleButtons}
                  </h4>
                </div>
                <Render condition={!isNullEmptyOrUndefined(subTitle)}>
                  <div
                    className={fullClassName(
                      'font-secondary-bold',
                      subTitleClassName
                    )}
                  >
                    {subTitle}
                  </div>
                </Render>
              </div>
            </Render>
            <Render condition={rightTitleButtons}>{rightTitleButtons}</Render>
          </div>
        </div>
      </Render>
      <div className={fullClassName('card-section-content')}>{children}</div>
    </Col>
  );
};

export const PageCardRowSection: React.FC<PageCardSectionProps> = ({
  children,
  ...props
}) => {
  return (
    <PageCardRow>
      <PageCardSection {...props}>{children}</PageCardSection>
    </PageCardRow>
  );
};

export type SingleSectionPageCardProps = PageCardSectionProps &
  PageCardProps & {
    cardTitle?: string;
    titleButton?: any;
  };

export const SingleSectionPageCard: React.FC<SingleSectionPageCardProps> = ({
  children,
  cardTitle,
  titleButton,
  flatBorder,
  titleActionsConfig,
  ...cardSectionProps
}) => {
  return (
    <PageCard
      title={cardTitle}
      titleButton={titleButton}
      flatBorder={flatBorder}
      titleActionsConfig={titleActionsConfig}
    >
      <PageCardRow>
        <PageCardSection {...cardSectionProps}>{children}</PageCardSection>
      </PageCardRow>
    </PageCard>
  );
};

export type CollapsiblePageCardProps = PageCardProps & {
  defaultClosed?: boolean;
  collapsibleTitle?: string;
  collapsibleRightButtons?: any;
  collapsibleContent?: any;
  isOpen?: boolean;
  toggleIsOpen?: () => void;
};

export const CollapsiblePageCard: React.FC<CollapsiblePageCardProps> = ({
  children,
  defaultClosed,
  collapsibleTitle,
  collapsibleRightButtons,
  collapsibleContent,
  isOpen: propsIsOpen,
  toggleIsOpen: propsToggleIsOpen,
  ...pageCardProps
}) => {
  const [isOpen, toggleIsOpen] = useToggler(!defaultClosed);
  const realIsOpen = propsIsOpen !== undefined ? propsIsOpen : isOpen;
  const realToggle =
    propsToggleIsOpen !== undefined ? propsToggleIsOpen : toggleIsOpen;

  return (
    <PageCard {...pageCardProps}>
      <div
        className={fullClassName(
          'full-width flex-vertical-center min-height-45 px-regular position-relative align-items-stretch',
          toggledClass('border-bottom-light', isOpen)
        )}
      >
        <div
          onClick={realToggle}
          className="collapsible-title flex-vertical-center cursor-pointer no-user-select"
        >
          <CollapsibleArrow isOpen={realIsOpen} />
          {collapsibleTitle && (
            <span className="font-secondary-bold header-size-tiny mr-regular ml-small">
              {collapsibleTitle}
            </span>
          )}
        </div>
        {collapsibleContent}
        {collapsibleRightButtons && (
          <div className="ml-auto flex-vertical-center">
            {collapsibleRightButtons}
          </div>
        )}
      </div>
      <Collapse isOpened={realIsOpen}>{children}</Collapse>
    </PageCard>
  );
};

export type CollapsibleFormFiltersPageCardProps = CollapsiblePageCardProps & {
  filterChipsProps?: FormFilterChipsProps;
};

export const CollapsibleFormFiltersPageCard: React.FC<CollapsibleFormFiltersPageCardProps> =
  ({
    children,
    collapsibleRightButtons,
    filterChipsProps,
    ...collapsiblePageCardProps
  }) => {
    const { formValues } = useFormState();
    const { setFormValues } = useFormHelpers();

    const handleClearAll = (e: any) => {
      e.stopPropagation();
      setFormValues({});
      filterChipsProps &&
        filterChipsProps.onClear &&
        filterChipsProps.onClear();
    };

    return (
      <CollapsiblePageCard
        collapsibleRightButtons={
          <TextButton
            small
            onClick={handleClearAll}
            disabled={isNullEmptyOrUndefined(formValues)}
          >
            Clear all
          </TextButton>
        }
        collapsibleContent={
          <Render condition={!collapsiblePageCardProps.isOpen}>
            <FormFilterChips {...filterChipsProps} />
          </Render>
        }
        {...collapsiblePageCardProps}
      >
        {children}
      </CollapsiblePageCard>
    );
  };
