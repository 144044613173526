import { FileModel } from './FileModels';

export class ReferralDocumentModel extends FileModel {
  constructor(id: string) {
    super();
    this.referralId = id;
  }

  referralId?: string;
}

export class ReferralRawDocumentModel extends FileModel {
  constructor(id: string) {
    super();
    this.referralId = id;
  }

  referralId?: string;
}

export class ReferralIdGridSearchModel {
  constructor(id: string) {
    this.referralId = id;
  }

  referralId?: string;
}
