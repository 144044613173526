import { AdminSubHeaderMainEditLayout } from 'Components/Display';
import { useEntity } from 'Context';
import { Permission } from 'Models/Templates/Permission/Permission';
import { addEditRoute, AddEditSubRoute, ROUTES } from 'Navigation';
import React, { Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { ViewClaimantBreadCrumb } from '.';

export const ViewClaimantMain: React.FC = () => {
  const entity = useEntity();

  let fullName = `${entity.lastName}, ${entity.firstName}`;
  const history = useHistory();

  const redirectToAddedit = () => {
    history.push(addEditRoute(AddEditSubRoute.Claimant, entity.appointment.id));
  };

  return (
    <Fragment>
      <ViewClaimantBreadCrumb />
      <AdminSubHeaderMainEditLayout
        subRoute={ROUTES.CLAIMANT}
        title={fullName + ' - Claimant'}
        route={addEditRoute(AddEditSubRoute.Claimant, entity.entityId)}
        editPermissions={[Permission.EditClaimant]}
      />
    </Fragment>
  );
};
