import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import {
  AsyncLoadingSection,
  ControlledModalFormDrawer,
  DisplayBlock,
  Render,
  TaskPageHeaderLayout,
} from 'Components/Display';
import { Fade, Tab, Tabs } from 'react-bootstrap';
import {
  PageCard,
  PageCardRow,
  PageCardSection,
} from 'Components/Display/PageCard';
import { ButtonSize, ButtonStyle, DefaultInputLabel, IconButton, SecondaryButton, SmartCheckboxList, SmartFileUploader, SmartInputBlock } from 'Components/Form';
import { Client, ColSize } from 'Models';
import { InputType } from 'Models/FormModels';
import { CONTROLLERS, ENDPOINTS } from 'Models/Templates/Routes/Routes';
import { ADD_EDIT } from 'Navigation';
import React, { useState } from 'react';
import { Row } from 'reactstrap';
import {
  DOCX_MIME_FILE_TYPE,
  DOC_MIME_FILE_TYPE,
  addEditTitle,
  buildEndpoint,
  defaultDataTransform,
  useAddEditSuccessHandler,
  useDataFetcher,
  useIdParam,
} from 'Utilities';
import { FormActionTypes, ToggleProvider, useFormState, useFormUpdater, useToggleState } from 'Context';
import { DownloadFileText } from 'Components/Grid';

export const AddEditClient: React.FC = () => {
  const entityName = 'Client';
  const [id, isAdd] = useIdParam();
  const handleSuccess = useAddEditSuccessHandler(entityName, isAdd);
  const [data, loadingStatus] = useDataFetcher<Client>(
    buildEndpoint(ENDPOINTS.clients.base, id, ADD_EDIT),
    new Client(),
    defaultDataTransform,
    Client
  );

  return (
    <AsyncLoadingSection loadingStatus={loadingStatus} useMask>
      <TaskPageHeaderLayout
        title={addEditTitle(entityName, isAdd)}
        formProviderProps={{
          formModel: data,
          endpoint: ENDPOINTS.clients.base,
          handleSuccess: handleSuccess,
        }}
      >
        <ClientsAddEditFormContent />
      </TaskPageHeaderLayout>
    </AsyncLoadingSection>
  );
};

const HintDrawer = () => {
  const [isOpen, toggle] = useToggleState();
  return (
    <>
      <SecondaryButton onClick={toggle} buttonSize={ButtonSize.Small}>
        Show available tokens
      </SecondaryButton>
      <ControlledModalFormDrawer
        isOpen={isOpen}
        toggle={toggle}
        title='All Tokens'
      >
        <DefaultInputLabel
          label='Referral Tokens'
          className="mb-small"
        />
        <div><b>#ReferralDate#</b>: Referral date created in short date format.</div>
        <div><b>#ReferralNumber#</b>: Referral number.</div>
        <div><b>#DateOfInjury#</b>: Date of injury in short date format.</div>
        <div><b>#ReviewerSpecialty#</b>: Reviewer specialty.</div>
        <div><b>#SpecialInstructions#</b>: Special instructions.</div>
        <div><b>#ProviderPhone#</b>: Provider phone.</div>
        <div><b>#ProviderRequestingTreatment#</b>: Provider requesting treatment.</div>
        <div><b>#Adjuster#</b>: Adjuster name.</div>
        <div><b>#BenefitState#</b>: Benefit state display name.</div>
        <div><b>#Physician#</b>: Physician full name.</div>
        <div><b>#ReferralSource#</b>: Referral source name.</div>
        <div><b>#ClientName#</b>: Client name.</div>
        <div><b>#EvalType#</b>: Evaluation type title.</div>
        <div><b>#ClaimantName#</b>: Claimant’s first and last name.</div>
        <div><b>#ClaimNumber#</b>: Claim number.</div>
        <DefaultInputLabel
          label='Commercial Cases Tokens'
          className="mb-small"
          style={{marginTop: 10}}
        />
        <div><b>#CommercialApName#</b>: Commercial AP name.</div>
        <div><b>#CommercialApContact#</b>: Commercial AP contact display name.</div>
        <div><b>#CommercialApSpecialty#</b>: Commercial AP specialty.</div>
        <div><b>#CommercialApFaxNumber#</b>: Commercial AP fax number.</div>
        <div><b>#CommercialApPhoneNumber#</b>: Commercial AP phone number.</div>
        <div><b>#CommercialLineOfBusiness#</b>: Commercial line of business name.</div>
        <div><b>#CommercialService#</b>: Commercial service name.</div>
        <div><b>#CommercialTAT#</b>: Commercial TAT.</div>
        <div><b>#CommercialTimeFrame#</b>: Commercial time frame display name.</div>
        <div><b>#StradixNumber#</b>: Stradix number as a string.</div>
        <div><b>#CommercialQuestions#</b>: List of commercial referral questions.</div>
      </ControlledModalFormDrawer>
    </>
  )
}

const ClientsAddEditFormContent: React.FC = ({ ...otherProps }) => {
  const { formValues } = useFormState();
  const [id, isAdd] = useIdParam();
  const [key, setKey] = useState('primary');

  const [reportFileAdd, setReportIsAdd] = useState(formValues?.reportTemplate === null);
  const sendUpdate = useFormUpdater();

  const removeReportFile = () => {
    setReportIsAdd(true);
    sendUpdate(FormActionTypes.UpdateValue, {
      field: 'reportTemplate',
      value: null,
    });
    sendUpdate(FormActionTypes.UpdateValue, {
      field: 'reportTemplateId',
      value: null,
    });
  };

  return (
    <PageCard>
      <PageCardRow>
        <PageCardSection>
          <Row>
            <SmartInputBlock
              name="name"
              label="Name"
              type={InputType.Text}
              colProps={{ xs: ColSize.TwoThirds }}
              required
            />
            <SmartInputBlock
              name="isActive"
              label="Active"
              type={InputType.Checkbox}
              colProps={{ xs: ColSize.OneThird }}
              required
            />
          </Row>
          <Tabs
            transition={Fade}
            id="controlled-tab-example"
            activeKey={key}
            onSelect={(k) => setKey(k as string)}
            className="mb-3"
          >
            <Tab eventKey="primary" title="Evaluation Types">
              <SmartCheckboxList
                name="evalTypes"
                labelField="name"
                checkedField="isSelected"
                groupField="groupName"
                hideCheckAll
              />
            </Tab>
            <Tab eventKey="lob" title="Line Of Business">
              <SmartCheckboxList
                name="lineOfBusinesses"
                labelField="name"
                checkedField="isSelected"
                groupField="groupName"
                hideCheckAll
              />
            </Tab>
            <Tab eventKey="contactEmail" title="Settings">
              <SmartInputBlock
                name="contactEmail"
                label="Contact Email"
                type={InputType.Text}
                colProps={{ xs: ColSize.OneThird }}
              />
              <Render
                condition={!reportFileAdd && (formValues?.reportTemplate?.fileId || false)}
              >
                <div style={{ padding: '0 0 0 15px' }}>
                  <div className="field-label">Report Template</div>
                  <IconButton
                    iconElement={HighlightOffIcon}
                    primary
                    buttonStyle={ButtonStyle.Bare}
                    onClick={removeReportFile}
                  />
                  <span style={{ padding: '10px 0' }}>
                    <DownloadFileText
                      id={formValues?.reportTemplate?.id}
                      filename={formValues?.reportTemplate?.fileName}
                      fileControllerEndpoint={`${CONTROLLERS.Clients}/ReportTemplate`}
                    />
                  </span>
                </div>
                <div style={{ padding: '0 0 0 15px' }}>
                  <ToggleProvider initialValue={false}>
                    <div>
                      <HintDrawer></HintDrawer>
                    </div>
                  </ToggleProvider>
                </div>
              </Render>
              <div style={{ padding: '0 0 0 15px' }}>
                <SmartFileUploader
                  name="reportTemplate"
                  label="Report Template"
                  fileEndpoint={`${CONTROLLERS.Clients}/ReportTemplate`}
                  isEdit={!reportFileAdd}
                  vertical
                  withComments={false}
                  allowMultiple={false}
                  multipleUploaders
                  acceptedFileTypes={[DOC_MIME_FILE_TYPE, DOCX_MIME_FILE_TYPE]}
                />
              </div>

            </Tab>
          </Tabs>

        </PageCardSection>
      </PageCardRow>
      <SmartInputBlock name="id" type={InputType.Text} hidden />
    </PageCard>
  );
};
