import * as React from "react";
import { useSafeStateUpdate } from "Utilities";

export function useProviderContext<T>(
  Context: React.Context<T | undefined>,
  providerName: string
) {
  const stateContext = React.useContext(Context);
  if (stateContext === undefined) {
    throw new Error(
      `use${providerName}State must be used within a ${providerName}Provider`
    );
  }
  return stateContext;
}

export function useProviderUpdater<TActionTypes, TAction>(
  dispatch: (action: TAction) => void
) {
  const safeStateUpdate = useSafeStateUpdate();

  return React.useCallback(
    (type: TActionTypes, payload?: any) => {
      safeStateUpdate(() => {
        dispatch(({ type, payload } as unknown) as TAction);
      });
    },
    [dispatch]
  );
}
