import {
  AsyncLoadingSection,
  DisplayBlock,
  ModalDrawerButtonRow,
  Render,
} from 'Components/Display';
import {
  FormSubmitButton,
  ModalFormCancelButton,
  SmartFileUploader,
} from 'Components/Form';
import {
  FormActionTypes,
  useEntity,
  useFormUpdater,
  useModalFormState,
} from 'Context';
import { ENDPOINTS } from 'Models/Templates/Routes/Routes';
import React, { useEffect } from 'react';
import { Row } from 'reactstrap';
import { buildEndpoint, useDataFetcher } from 'Utilities';
import { ReferralRawDocumentModel } from 'Models';

export const ReferralRawDocumentDrawerFormContent: React.FC = () => {
  const entity = useEntity();
  const { entityId, isOpen } = useModalFormState();
  const sendUpdate = useFormUpdater();
  const isAdd = entityId === undefined;
  const addOrEditEndpoint = ENDPOINTS.referralrawdocuments.endpointString(
    isAdd ? 'add' : 'edit'
  );
  const allowMultiple = isAdd;

  let [referralRawDocumentModel, fetchStatus] =
    useDataFetcher<ReferralRawDocumentModel>(
      buildEndpoint(ENDPOINTS.referralrawdocuments.base, entityId),
      new ReferralRawDocumentModel(entity.id),
      ({ data }) => data,
      undefined,
      !isAdd && isOpen
    );

  useEffect(() => {
    if (!isAdd) {
      sendUpdate(FormActionTypes.SetValues, referralRawDocumentModel);
    }
  }, [referralRawDocumentModel, sendUpdate]);

  return (
    <AsyncLoadingSection loadingStatus={fetchStatus} noIdleLoading>
      <Render
        condition={!isAdd && referralRawDocumentModel?.fileId !== undefined}
      >
        <Row>
          <DisplayBlock
            value={referralRawDocumentModel?.fileName}
            title="File"
          />
        </Row>
      </Render>
      <SmartFileUploader
        label="Files"
        required
        fileEndpoint={ENDPOINTS.referralrawdocuments.base}
        isEdit={!isAdd}
        vertical
        withComments
        allowMultiple={allowMultiple}
      />
      <ModalDrawerButtonRow>
        <ModalFormCancelButton />
        <FormSubmitButton alternateEndpoint={addOrEditEndpoint}>
          Save Document
        </FormSubmitButton>
      </ModalDrawerButtonRow>
    </AsyncLoadingSection>
  );
};
