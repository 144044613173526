import React from "react";
import { asYesNo } from "Utilities";
import { BooleanCell } from "Components/Grid";
import { GridCellProps } from "@progress/kendo-react-grid";

export const YesNoBooleanCell: React.FC<GridCellProps> = (
  props: GridCellProps
) =>
  props.rowType === "data" ? (
    <BooleanCell convertFunction={asYesNo} {...props} />
  ) : null;
