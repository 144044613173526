import './ModalDrawer.scss';
import React, { useEffect } from 'react';
import { Drawer } from 'antd';
import CloseIcon from '@mui/icons-material/Close';
import { DrawerProps } from 'antd/lib/drawer';
import { ToggleableProps } from 'Models';
import {
  FormProvider,
  FormProviderProps,
  useFormHelpers,
  useModalFormState,
  useModalHelpers,
} from 'Context';
import { ModalDrawerButtonRow, Render } from '.';
import { fullClassName, toggledClass } from 'Utilities';
import { MultiModalCancelButton, FormSubmitButton } from 'Components/Form';
import { SecureComponent } from 'Components/Security';

export type ModalDrawerProps = DrawerProps &
  ToggleableProps & {
    isOpen: boolean;
    noBodyPadding?: boolean;
  };

export const ModalDrawer: React.FC<ModalDrawerProps> = ({
  title,
  isOpen,
  toggle,
  children,
  className,
  noBodyPadding,
  ...otherProps
}) => {
  return (
    <Render condition={isOpen}>
      <Drawer
        visible={isOpen}
        onClose={toggle}
        title={title}
        closeIcon={<CloseIcon />}
        className={fullClassName(
          className,
          toggledClass('no-body-padding', noBodyPadding)
        )}
        {...otherProps}
      >
        {children}
      </Drawer>
    </Render>
  );
};

export const ControlledModalFormDrawer: React.FC<ModalDrawerProps> = ({
  children,
  width = 500,
  isOpen,
  ...allProps
}) => {
  return (
    <Render condition={isOpen}>
      <ModalDrawer
        maskClosable={false}
        width={width}
        isOpen={isOpen}
        bodyStyle={{ marginBottom: 60 }}
        {...allProps}
      >
        {isOpen && children}
      </ModalDrawer>
    </Render>
  );
};

export type ModalFormDrawerProps = DrawerProps & {
  noBodyPadding?: boolean;
};

export const ModalFormDrawer: React.FC<ModalFormDrawerProps> = ({
  children,
  ...otherProps
}) => {
  const { toggle, isOpen } = useModalFormState();

  return (
    <ControlledModalFormDrawer isOpen={isOpen} toggle={toggle} {...otherProps}>
      {children}
    </ControlledModalFormDrawer>
  );
};

export type MultiModalDrawerProps = DrawerProps & {
  modalId: string;
  noBodyPadding?: boolean;
};

export const MultiModalDrawer: React.FC<MultiModalDrawerProps> = ({
  modalId,
  children,
  ...modalDrawerProps
}) => {
  const { toggle, isOpen } = useModalHelpers(modalId);
  return (
    <Render condition={isOpen}>
      <ModalDrawer
        toggle={toggle}
        isOpen={isOpen}
        bodyStyle={{ marginBottom: 60 }}
        {...modalDrawerProps}
      >
        {children}
      </ModalDrawer>
    </Render>
  );
};

export type MultiModalFormDrawerProps = ModalFormDrawerProps &
  FormProviderProps & {
    modalId: string;
    submitButtonText?: string;
    submitPermissions?: number[];
  };

export const MultiModalFormDrawer: React.FC<MultiModalFormDrawerProps> = ({
  modalId,
  submitButtonText = 'Save',
  children,
  formModel,
  endpoint,
  submitPermissions,
  handleSuccess,
  ...otherProps
}) => {
  const { toggle, isOpen } = useModalHelpers(modalId);

  return (
    <FormProvider
      formModel={formModel}
      endpoint={endpoint}
      handleSuccess={handleSuccess}
    >
      <ControlledModalFormDrawer
        isOpen={isOpen}
        toggle={toggle}
        {...otherProps}
      >
        <MultiModalFormErrorClearer modalId={modalId} />
        {children}
        <ModalDrawerButtonRow>
          <MultiModalCancelButton modalId={modalId} />
          <SecureComponent permissions={submitPermissions}>
            <FormSubmitButton>{submitButtonText}</FormSubmitButton>
          </SecureComponent>
        </ModalDrawerButtonRow>
      </ControlledModalFormDrawer>
    </FormProvider>
  );
};

const MultiModalFormErrorClearer: React.FC<MultiModalFormDrawerProps> = ({
  modalId,
}) => {
  const { isOpen } = useModalHelpers(modalId);
  const { clearFormErrors } = useFormHelpers();
  useEffect(() => {
    if (!isOpen) {
      clearFormErrors();
    }
  }, [isOpen]);

  return null;
};
