import { Appointment } from './AppointmentModels';
import { FileModel } from './FileModels';

export class AppointmentDocumentModel extends FileModel {
  constructor(id: string, referralDate?: Date) {
    super();
    this.appointmentId = id;
    this.documentDate = new Date();
    this.referralDate = referralDate;
  }
  appointmentId?: string;
  appointment?: Appointment;
  title?: string;
  pageCount?: number;
  autoProcessStatus?: string;
  documentDate?: Date;
  referralDate?: Date;
  completionDate?: Date;
  comments?: string;
  isNotes?: boolean;
}

export class AppointmentIdGridSearchModel {
  constructor(id: string) {
    this.appointmentId = id;
  }

  appointmentId?: string;
  showHiddenFiles?: boolean;
}

export enum AppointmentDocumentModal {
  Attachments = 'attachmentsDocument',
  Notes = 'notesDocument',
  Combine = 'combineDocument'
}

export class EntityDocumentCombineModel {
  constructor(id: string) {
    this.entityId = id;
  }
  entityId?: string;
  documentsToCombine?: CombinableDocumentModel[];
}

export class CombinableDocumentModel {
  id?: string;
  combinedToId?: string;
  appointmentId?: string;
  fileName?: string;
  isSelected?: boolean;
}