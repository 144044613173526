import {
  AsyncLoadingSection,
  SingleSectionPageCard,
  TaskPageHeaderLayout,
} from 'Components/Display';
import { SmartInputBlock } from 'Components/Form';
import { useEnums } from 'Context';
import { ClaimEscalation, CoreEnum } from 'Models';
import { InputType } from 'Models/FormModels';
import { ColSize } from 'Models/SharedModels';
import { ENDPOINTS } from 'Models/Templates/Routes/Routes';
import React, { Fragment } from 'react';
import { useParams } from 'react-router-dom';
import { Row } from 'reactstrap';
import {
  useDataFetcher,
  addEditTitle,
  useIdParam,
  useAddEditSuccessHandler,
  defaultDataTransform,
  buildEndpoint,
  buildQueryUrl,
} from 'Utilities';

export const AddEditClaimEscalation: React.FC = () => {
  const entityName = 'Claim Escalation';
  const [id, isAdd] = useIdParam();
  const handleSuccess = useAddEditSuccessHandler(entityName, isAdd);
  const { claimid } = useParams<any>();

  const [data, loadingStatus] = useDataFetcher<ClaimEscalation>(
    buildQueryUrl(buildEndpoint(ENDPOINTS.claimescalations.get), {
      id: id || '',
      claimId: claimid,
    }),

    new ClaimEscalation(),
    defaultDataTransform,
    ClaimEscalation
  );

  return (
    <AsyncLoadingSection loadingStatus={loadingStatus} useMask>
      <TaskPageHeaderLayout
        title={addEditTitle(entityName, isAdd)}
        formProviderProps={{
          formModel: data,
          endpoint: ENDPOINTS.claimescalations.base,
          handleSuccess: handleSuccess,
        }}
      >
        <ClaimsAddEditFormContent />
      </TaskPageHeaderLayout>
    </AsyncLoadingSection>
  );
};

const ClaimsAddEditFormContent: React.FC = () => {
  type ListObject = {
    textValue?: string;
    dateValue?: Date;
  };
  const genderList: CoreEnum[] = [
    {
      displayName: 'Male',
      value: 0,
    },
  ];

  const initialListObjectValue: ListObject = {
    textValue: 'Initial Text Value',
    dateValue: new Date(),
  };
  const { state } = useEnums();
  return (
    <Fragment>
      <SingleSectionPageCard cardTitle="Details" centerContent inputSection>
        <SmartInputBlock name="claimantId" type={InputType.Text} hidden />
        <Row>
          <SmartInputBlock
            name="title"
            label="Title"
            type={InputType.Text}
            colProps={{ xs: ColSize.OneThird }}
            required
          />
          <SmartInputBlock
            name="cptCode"
            label="CPT Code"
            type={InputType.Text}
            colProps={{ xs: ColSize.OneThird }}
          />
          <SmartInputBlock
            name="icdCode"
            label="ICD Code"
            type={InputType.Text}
            colProps={{ xs: ColSize.OneThird }}
          />
        </Row>
        <Row>
          <SmartInputBlock
            name="escalationDescription"
            label="Description"
            type={InputType.TextArea}
            required
            colProps={{ xs: ColSize.OneThird }}
          />
          <SmartInputBlock
            name="caseDueDate"
            label="Due Date"
            type={InputType.DateTimePicker}
            colProps={{ xs: ColSize.OneThird }}
          />
          <SmartInputBlock
            name="externalReviewId"
            label="External Review ID"
            type={InputType.Text}
            colProps={{ xs: ColSize.OneThird }}
          />
        </Row>
        <Row>
          <SmartInputBlock
            name="diagnosis"
            label="Diagnosis"
            type={InputType.Text}
            colProps={{ xs: ColSize.OneThird }}
          />
          <SmartInputBlock
            name="escalatedByEmail"
            label="Escalated by Email"
            type={InputType.Text}
            colProps={{ xs: ColSize.OneThird }}
          />
          <SmartInputBlock
            name="escalatedByName"
            label="Escalated by Name"
            type={InputType.Text}
            colProps={{ xs: ColSize.OneThird }}
          />
        </Row>
        <Row>
          <SmartInputBlock
            name="clientInstructions"
            label="Client Instructions"
            type={InputType.TextArea}
            colProps={{ xs: ColSize.OneThird }}
          />
          <SmartInputBlock
            name="quantity"
            label="Quantity"
            type={InputType.TextArea}
            colProps={{ xs: ColSize.OneThird }}
          />
        </Row>
      </SingleSectionPageCard>
    </Fragment>
  );
};
