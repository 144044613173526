import {
  GridCellProps,
  GridColumn as Column,
} from '@progress/kendo-react-grid';
import {
  AdminSubHeaderMainLayout,
  CollapsibleAuditLog,
} from 'Components/Display';
import { AddButton, SmartInputBlock } from 'Components/Form';
import { EditDeleteCell, MainGrid, GridButtonSection } from 'Components/Grid';
import { SecureComponent } from 'Components/Security';
import { FormProvider, useEnums } from 'Context';
import { InputType } from 'Models/FormModels';
import { defaultGridProps } from 'Models/GridModels';
import { Permission } from 'Models/Templates/Permission/Permission';
import { ENDPOINTS } from 'Models/Templates/Routes/Routes';
import { addEditRoute, AddEditSubRoute } from 'Navigation';
import React, { Fragment } from 'react';
import { useGridSearch } from 'Utilities';

class UserSearch {
  searchKey?: string;
}

export const UserManagementUsers: React.FC = () => {
  const { entityEventType } = useEnums();
  const searchModel = new UserSearch();

  return (
    <AdminSubHeaderMainLayout title="Users">
      <FormProvider formModel={searchModel}>
        <UserFields />
        <UsersGrid />
        <CollapsibleAuditLog
          title="Audit Log"
          entityEventType={entityEventType.User?.value}
        />
      </FormProvider>
    </AdminSubHeaderMainLayout>
  );
};

const UserFields: React.FC = () => {
  return (
    <div className="mb-3">
      <SmartInputBlock
        name="searchKey"
        label="Search"
        type={InputType.Text}
        placeholder="Username, Name, Email..."
        noPaddingMargin
      />
    </div>
  );
};

const UsersGrid: React.FC = () => {
  const [debounceSearchModel, gridToggler] = useGridSearch();

  return (
    <Fragment>
      <SecureComponent permissions={[Permission.AddUser]}>
        <GridButtonSection>
          <AddButton url={addEditRoute(AddEditSubRoute.User)}>
            New User
          </AddButton>
        </GridButtonSection>
      </SecureComponent>
      <MainGrid
        refreshToggle={gridToggler}
        readEndpoint={ENDPOINTS.users.read}
        search={debounceSearchModel}
        {...defaultGridProps}
      >
        <Column field="username" title="Username" />
        <Column field="firstName" title="First Name" />
        <Column field="lastName" title="Last Name" />
        <Column field="emailAddress" title="Email Address" />
        <Column field="lastLogin" title="Last Logged In" />
        <Column field="id" title=" " cell={ActionsCell} />
      </MainGrid>
    </Fragment>
  );
};

const ActionsCell: React.FC<GridCellProps> = (props: GridCellProps) => {
  return (
    <EditDeleteCell
      editRoute={addEditRoute(AddEditSubRoute.User)}
      editPermissions={[Permission.EditUser]}
      deleteEndpoint={ENDPOINTS.users.base}
      deletePermissions={[Permission.DeleteUser]}
      {...props}
    />
  );
};
