import { NamedCoreEnum } from './SharedModels';

export class Claimant {
  id?: string;
  firstName?: string;
  lastName?: string;
  gender?: NamedCoreEnum;
  ssn?: string;
  phone?: string;
  mobilePhone?: string;
  fax?: string;
  phoneOther?: string;
  dateOfBirth?: Date;
  occupation?: string;
  email?: string;
  dwc?: boolean;
  addresses?: ClaimantAddress[];
  employers?: ClaimantEmployer[];
  fullName?: string;
}

export class ClaimantAddress {
  id?: string;
  line1?: string;
  line2?: string;
  city?: string;
  state?: NamedCoreEnum;
  zip?: string;
  fullAddressLine?: string;
}
export enum ClaimantModals {
  EditClaimant = 'editEditClaimant',
}

export class ClaimantEmployer {
  name?: string;
  jobDescription?: string;
  phone?: string;
  addressLine1?: string;
  addressLine2?: string;
  addressState?: NamedCoreEnum;
  city?: string;
  zip?: string;
  state?:NamedCoreEnum;
}

export class ClaimantEmployerModel{
  id?: string ;
  employer?: ClaimantEmployer;
}


