import * as React from "react";
import { useSafeStateUpdate } from "Utilities";

export interface GridSelectionsState {
  selections: string[];
  selectAll: boolean;
  availableSelections: string[];
}

export enum GridSelectionsActionTypes {
  ToggleSelection = "updateSelection",
  ToggleAllSelections = "toggleAllSelections",
  SetAvailableSelections = "setAvailableSelections",
  UpdateSelectAll = "updateSelectAll",
  ClearSelections = "clearSelections",
  SetSelections = "setSelections",
}

export type GridSelectionsAction =
  | {
      type: GridSelectionsActionTypes.ToggleSelection;
      payload: string;
    }
  | { type: GridSelectionsActionTypes.ClearSelections }
  | {
      type: GridSelectionsActionTypes.UpdateSelectAll;
      payload: {
        availableSelections: string[];
        selections: string[];
      };
    }
  | { type: GridSelectionsActionTypes.ToggleAllSelections }
  | {
      type: GridSelectionsActionTypes.SetAvailableSelections;
      payload: string[];
    }
  | {
      type: GridSelectionsActionTypes.SetSelections;
      payload: string[];
    };
type GridSelectionsDispatch = (action: GridSelectionsAction) => void;

const GridSelectionsStateContext = React.createContext<
  GridSelectionsState | undefined
>(undefined);
const GridSelectionsDispatchContext = React.createContext<
  GridSelectionsDispatch | undefined
>(undefined);

function GridSelectionsReducer(
  state: GridSelectionsState,
  action: GridSelectionsAction
): GridSelectionsState {
  switch (action.type) {
    case GridSelectionsActionTypes.ToggleSelection: {
      return {
        ...state,
        selections: setSelection(action.payload, state.selections),
      };
    }
    case GridSelectionsActionTypes.UpdateSelectAll: {
      return {
        ...state,
        selectAll: updateSelectAll(state.availableSelections, state.selections),
      };
    }
    case GridSelectionsActionTypes.ToggleAllSelections: {
      return {
        ...state,
        selections: toggleAllSelections(
          state.selections,
          state.availableSelections
        ),
      };
    }
    case GridSelectionsActionTypes.SetAvailableSelections: {
      return { ...state, availableSelections: action.payload };
    }
    case GridSelectionsActionTypes.ClearSelections: {
      return { ...state, selections: [] };
    }
    case GridSelectionsActionTypes.SetSelections: {
      return { ...state, selections: action.payload };
    }
    default: {
      throw new Error("Invalid action type");
    }
  }
}

const toggleAllSelections = (
  selections: string[],
  availableSelections: string[]
) => {
  var currentSelections = [...selections];

  if (availableSelections.every((x) => selections.includes(x))) {
    currentSelections = [];
  } else {
    currentSelections = [...availableSelections];
  }

  return currentSelections;
};

const updateSelectAll = (
  availableIds: string[],
  currentSelections: string[]
) => {
  if (availableIds.length === 0) {
    return false;
  }

  if (availableIds.every((id) => currentSelections.includes(id))) {
    return true;
  } else {
    return false;
  }
};

const setSelection = (id: string, selections: any[]) => {
  var currentSelections = [...selections];
  if (selections.includes(id)) {
    currentSelections = currentSelections.filter((x) => x !== id);
  } else {
    currentSelections.push(id);
  }

  return currentSelections;
};

type GridSelectionsProviderProps = {
  initialValue?: any[];
};

export const GridSelectionsProvider: React.FC<GridSelectionsProviderProps> = ({
  children,
  initialValue = [],
}) => {
  const [state, dispatch] = React.useReducer(GridSelectionsReducer, {
    selections: initialValue,
    selectAll: false,
    availableSelections: [],
  });

  React.useEffect(() => {
    dispatch({
      type: GridSelectionsActionTypes.UpdateSelectAll,
      payload: {
        availableSelections: state.availableSelections,
        selections: state.selections,
      },
    });
  }, [state.availableSelections, state.selections]);

  return (
    <GridSelectionsStateContext.Provider value={state}>
      <GridSelectionsDispatchContext.Provider value={dispatch}>
        {children}
      </GridSelectionsDispatchContext.Provider>
    </GridSelectionsStateContext.Provider>
  );
};

export const useGridSelectionsState = () => {
  const state = React.useContext(GridSelectionsStateContext);
  if (state === undefined) {
    throw new Error(
      "useGridSelectionsState must be used within a GridSelectionsProvider"
    );
  }
  return state;
};

export const useGridSelectionsDispatch = () => {
  const dispatcher = React.useContext(GridSelectionsDispatchContext);
  if (dispatcher === undefined) {
    throw new Error(
      "useGridSelectionsDispatch must be used within a GridSelectionsProvider"
    );
  }
  return dispatcher;
};

export const useGridSelectionsContext = () => {
  const state = useGridSelectionsState();
  const dispatch = useGridSelectionsDispatch();

  const sendUpdate = React.useCallback(
    (type: GridSelectionsActionTypes, payload?: any) => {
      dispatch({ type, payload } as GridSelectionsAction);
    },
    [dispatch]
  );

  return [state, sendUpdate] as const;
};

export const useGridSelectionsUpdater = () => {
  const safeStateUpdate = useSafeStateUpdate();
  const dispatch = useGridSelectionsDispatch();

  return React.useCallback(
    (type: GridSelectionsActionTypes, payload?: any) => {
      safeStateUpdate(() => {
        dispatch({ type, payload } as GridSelectionsAction);
      });
    },
    [dispatch]
  );
};

export const useGridSelectionHelpers = () => {  
  const { selections } = useGridSelectionsState();
  const sendUpdate = useGridSelectionsUpdater();

  const clearSelections = () => {
    sendUpdate(GridSelectionsActionTypes.ClearSelections);
  };

  const headerSelectionChange = () => {
    sendUpdate(GridSelectionsActionTypes.ToggleAllSelections);
  };

  const setAvailableSelections = (ids: string[]) => {
    sendUpdate(GridSelectionsActionTypes.SetAvailableSelections, ids);
  };

  const toggleSelection = (id: string) => {
    sendUpdate(GridSelectionsActionTypes.ToggleSelection, id);
  };

  const isIdSelected = (id: string) => {
    return selections.includes(id);
  };

  const setSelections = (ids: string[]) => {
    sendUpdate(GridSelectionsActionTypes.SetSelections, ids);
  };

  return {
    clearSelections,
    headerSelectionChange,
    setAvailableSelections,
    toggleSelection,
    isIdSelected,
    setSelections,
  };
};
