import React, { Fragment } from 'react';
import { Route, RouteProps } from 'react-router-dom';
import {
  FullscreenSubLayout,
  SubHeaderLayoutProps,
  SubHeaderLayout,
} from 'Components/SubLayouts';
import { ROUTES } from 'Navigation';
import { RedirectHandler } from './RedirectHandler';

export type ProviderProps = {
  providerComponent?: React.FC<any>;
  providerProps?: any;
};

export type LayoutRouteProps = SubHeaderLayoutProps &
  RouteProps &
  ProviderProps & {
    component: React.FC<any>;
    layoutComponent?: React.FC<any>;
    layoutProps?: any;
    route?: ROUTES;
  };

export const LayoutRoute: React.FC<LayoutRouteProps> = ({
  component,
  layoutComponent,
  providerComponent,
  layoutProps,
  providerProps,
  headerComponent,
  headerProps,
  route,
  children,
  ...others
}) => {
  const hasHeader = headerComponent !== undefined;
  const hasTallHeader = headerProps?.hasTallHeader || false;
  const HeaderLayout = hasHeader ? SubHeaderLayout : Fragment;
  const headerLayoutProps = hasHeader
    ? { headerComponent, headerProps }
    : undefined;
  const hasComponent = component !== undefined;
  const Component = component;
  const SubLayout = layoutComponent || FullscreenSubLayout;
  const Provider = providerComponent || Fragment;

  const fullLayoutProps = {
    parentRoute: route,
    hasHeader: hasHeader,
    hasTallHeader: hasTallHeader,
    ...layoutProps,
  };

  return (
    <Route {...others}>
      <Provider {...providerProps}>
        <RedirectHandler>
          <HeaderLayout {...headerLayoutProps}>
            <SubLayout {...fullLayoutProps}>
              {hasComponent && <Component />}
              {!hasComponent && children}
            </SubLayout>
          </HeaderLayout>
        </RedirectHandler>
      </Provider>
    </Route>
  );
};
