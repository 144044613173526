import {GridCellProps} from "@progress/kendo-react-grid";
import { DateCell } from "Components/Grid";
import React from "react";
import {DEFAULT_DATETIME_FORMAT, DEFAULT_DATE_FORMAT} from "Utilities";

export const TimeStampCell: React.FC<GridCellProps> = (props) => (
  <DateCell dateFormat={DEFAULT_DATETIME_FORMAT} {...props} />
);

export const DateDisplayCell: React.FC<GridCellProps> = (props) => (
  <DateCell dateFormat={DEFAULT_DATE_FORMAT} {...props} />
);