import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import {
  AsyncLoadingSection,
  DisplayBlock,
  ModalDrawerButtonRow,
  MultiModalFormDrawer,
  Render,
} from 'Components/Display';
import {
  ButtonStyle,
  FormSubmitButton,
  IconButton,
  ModalFormCancelButton,
  SmartFileUploader,
  SmartInputBlock,
} from 'Components/Form';
import {
  FormActionTypes,
  useFormState,
  useFormUpdater,
  useGridState,
  useModalHelpers,
} from 'Context';
import {
  ColSize,
  InputType,
  ReviewerBoardCertificationDocumentModal,
  ReviewerBoardCertificationDocumentModel,
} from 'Models';
import {
  ReviewerDocumentModal,
  ReviewerDocumentModel,
} from 'Models/ReviewerFormDocumentModel';
import { 
  ENDPOINTS 
} from 'Models/Templates/Routes/Routes';
import React, { 
  Fragment, 
  useEffect, 
  useState 
} from 'react';
import { 
  Col, 
  Row 
} from 'reactstrap';
import { 
  buildEndpoint, 
  EMPTY_GUID, 
  useDataFetcher 
} from 'Utilities';

export type ReviewerBoardCertificationDocumentModalFormProps = {
  endpoint?: string;
  formModel?: ReviewerDocumentModel;
  reviewerId?: string;
};

export const ReviewerBoardCertificationDocumentDrawerForm: React.FC<
  ReviewerBoardCertificationDocumentModalFormProps
> = ({ formModel, reviewerId, ...props }) => {
  const { simpleSuccessHandler } = useModalHelpers(
    ReviewerDocumentModal.Documents
  );

  const { refreshGrid } = useGridState();

  const handleSuccess = () => {
    simpleSuccessHandler('Reviewer document has been updated.');
    refreshGrid();
  };

  return (
    <MultiModalFormDrawer
      modalId={ReviewerBoardCertificationDocumentModal.CertificationDocuments}
      submitButtonText="Save"
      formModel={formModel}
      endpoint={props.endpoint}
      handleSuccess={handleSuccess}
    >
      <ReviewerBoardCertificationDocumentDrawerFormContent
        reviewerId={reviewerId}
        {...props}
      />
    </MultiModalFormDrawer>
  );
};

export const ReviewerBoardCertificationDocumentDrawerFormContent: React.FC<
  ReviewerBoardCertificationDocumentModalFormProps
> = ({ reviewerId, ...props }) => {

  const { isOpen, entityId } = useModalHelpers(
    ReviewerBoardCertificationDocumentModal.CertificationDocuments
  );

  const sendUpdate = useFormUpdater();
  const { formValues } = useFormState();
  const [fileAdd, setIsAdd] = useState(entityId === undefined);
  const isAdd = entityId === undefined;

  const removeFile = () => {
    setIsAdd(!isAdd);
    sendUpdate(FormActionTypes.UpdateValue, { field: 'fileId', value: null });
    sendUpdate(FormActionTypes.UpdateValue, { field: 'fileName', value: null });
    sendUpdate(FormActionTypes.UpdateValue, { field: 'size', value: 0 });
  };

  let [reviewerDocumentModel, fetchStatus] =
    useDataFetcher<ReviewerBoardCertificationDocumentModel>(
      buildEndpoint(
        ENDPOINTS.reviewerBoardCertificationDocument.base,
        entityId ?? EMPTY_GUID
      ),
      new ReviewerBoardCertificationDocumentModel(reviewerId),
      ({ data }) => data,
      undefined,
      isOpen
    );

  useEffect(() => {
    if (!isAdd) {
      sendUpdate(FormActionTypes.SetValues, reviewerDocumentModel);
    }
  }, [reviewerDocumentModel, sendUpdate]);

  return (
    <AsyncLoadingSection loadingStatus={fetchStatus} noIdleLoading>
      <Render condition={!fileAdd && (formValues?.fileId || false)}>
        <Row>
          <IconButton
            iconElement={HighlightOffIcon}
            primary
            buttonStyle={ButtonStyle.Bare}
            onClick={removeFile}
          />
          <DisplayBlock value={formValues?.fileName} title="File" />
        </Row>
      </Render>
      <SmartFileUploader
        label="Files"
        fileEndpoint={ENDPOINTS.reviewerBoardCertificationDocument.base}
        isEdit={!fileAdd}
        vertical
        withComments={false}
        allowMultiple={false}
        renderAdditionalFormComponents={AdditionalFormComponentsProp}
      />
      <ModalDrawerButtonRow>
        <ModalFormCancelButton />
        <FormSubmitButton>Save</FormSubmitButton>
      </ModalDrawerButtonRow>
    </AsyncLoadingSection>
  );
};

const AdditionalFormComponentsProp = () => {
  return (
    <Fragment>
      <Col>
        <Row>
          <SmartInputBlock
            name="title"
            label="Title"
            type={InputType.Text}
            colProps={{ xs: ColSize.Full }}
            required
          />
          <SmartInputBlock
            name="certificationNumber"
            label="Certification Number"
            type={InputType.Text}
            colProps={{ xs: ColSize.Full }}
            required
          />
          <SmartInputBlock
            name="expirationDate"
            label="Expiration Date"
            type={InputType.DatePicker}
            required
          />
        </Row>
      </Col>
    </Fragment>
  );
};
