import {
  GridCellProps,
  GridColumn as Column,
} from '@progress/kendo-react-grid';
import {
  DateCell,
  DateLinkCell,
  EditDeleteScheduleCell,
  FilterBarMainGrid,
  LinkCell,
} from 'Components/Grid';
import { FormProvider, GridProvider, useEnums } from 'Context';
import {
  defaultGridProps,
  FilterBarFilterType,
  FilterConfig,
  IFilterConfig,
  StaticFilterConfig,
} from 'Models';
import { Permission } from 'Models/Templates/Permission/Permission';
import { ENDPOINTS } from 'Models/Templates/Routes/Routes';
import {
  AppointmentSubRoute,
  ClaimantsSubRoute,
  ClaimsSubRoute,
  ReferralCommercialSubRoute,
  ROUTES,
} from 'Navigation';
import React, { Fragment, HTMLProps } from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem, Col, Row } from 'reactstrap';
import { DEFAULT_DATE_FORMAT, EMPTY_GUID, buildEndpoint, useGridSearch } from 'Utilities';
import { LightPageHeader } from '../../Components/Display';

export const PendingScheduling: React.FC = () => {
  return (
    <Fragment>
      <Breadcrumb tag="nav" listTag="div">
        <BreadcrumbItem>
          <Link to={ROUTES.DASHBOARD}>Home</Link>
        </BreadcrumbItem>
        <BreadcrumbItem>Pending Assignment</BreadcrumbItem>
      </Breadcrumb>
      <LightPageHeader tall noBorder className="page-header-shadow">
        <h1>Pending Assignment</h1>
      </LightPageHeader>
      <br />
      <Row>
        <Col></Col>
      </Row>
      <AppointmentsFilteredGrid id={EMPTY_GUID} />
    </Fragment>
  );
};

class AppointmentSearch {
  searchKey?: string;
  referralId?: string;
  client?: string;
  benefitState?: string;
}

export enum AppointmentsGridIds {
  DetailedSearch = 'detailedSearch',
}

export type AppointmentsFilteredGridProps =
  HTMLProps<AppointmentsFilteredGridProps> & {
    id?: string;
  };

export const AppointmentsFilteredGrid: React.FC<
  AppointmentsFilteredGridProps
> = ({ id, ...props }) => {
  const appointmentSearch = new AppointmentSearch();
  appointmentSearch.referralId = id;
  return (
    <FormProvider formModel={appointmentSearch}>
      <GridProvider entityName="Appointment">
        <AppointmentsGrid id={id} />
      </GridProvider>
    </FormProvider>
  );
};

export const AppointmentsGrid: React.FC<AppointmentsFilteredGridProps> = ({
  id,
  ...props
}) => {
  const { state } = useEnums();

  const filters: IFilterConfig[] = [
    new FilterConfig(
      'searchKey',
      FilterBarFilterType.SearchText,
      'Stradix Number, Claim Number, Claimant, Referral Number, Doctor'
    ),
    new FilterConfig(
      'clients',
      FilterBarFilterType.AsyncDropdown,
      'Client',
      {
        readEndpoint: ENDPOINTS.clients.dropdownItems
      },
      (value) => value.name,
      true
    ),
    new FilterConfig(
      'benefitStates',
      FilterBarFilterType.EnumDropdown,
      'Benefit State',
      { data: state },
      (value => value.displayName),
      true
    ),
    new FilterConfig('dueDateFrom', FilterBarFilterType.DatePicker, 'Due Date From'),
    new FilterConfig('dueDateTo', FilterBarFilterType.DatePicker, 'Due Date To'),
    new FilterConfig('referralDateFrom', FilterBarFilterType.DatePicker, 'Referral Date From'),
    new FilterConfig('referralDateTo', FilterBarFilterType.DatePicker, 'Referral Date To'),
    new StaticFilterConfig('referralId'),
  ];
  const [gridToggler] = useGridSearch();

  let endpoint = ENDPOINTS.appointmentsCommercial.read;

  return (
    <React.Fragment>
      <FilterBarMainGrid
        isMainGrid
        filterBarProps={{
          filterConfigurations: filters,
          filterValues: { referralId: gridToggler.referralId }
        }}
        refreshToggle={gridToggler}
        readEndpoint={endpoint}
        exportEndpoint={buildEndpoint(
          ENDPOINTS.appointmentsCommercial.endpointString('ExportToCsv')
        )}
        {...defaultGridProps}
      >
        <Column
          field="stradixNumber"
          title="Stradix Number"
          cell={ReferralNumberCell}
        />
        <Column field="clientName" title="Client" />
        <Column field="service" title="Service" />
        <Column
          field="claimantName"
          title="Claimant Name"
          cell={ReferralNumberCell}
        />
        <Column
          field="claimNumber"
          title="Claim Number"
          cell={ReferralNumberCell} 
        />
        <Column field="requestedSpecialty" title="Requested Specialty" />
        <Column 
          field='assignmentDueDate'
          title='Due Date'
          cell={dateFormatCell}
        />
        <Column
          field="referralDate"
          title="Referral Date"
          cell={dateFormatCell}
        />
        <Column field="benefitState" title="Benefit State" />
        <Column field="id" width="120" title="Actions" cell={ActionsCell} />
      </FilterBarMainGrid>
    </React.Fragment>
  );
};

const ReferralNumberCell: React.FC<GridCellProps> = (props: GridCellProps) => {
  return (
    <LinkCell
      endpoint={ROUTES.REFERRALCOMMERCIAL}
      subRoute={ReferralCommercialSubRoute.View}
      linkField="referralId"
      {...props}
    ></LinkCell>
  );
};


const ClaimantCell: React.FC<GridCellProps> = (props: GridCellProps) => {
  return (
    <LinkCell
      endpoint={ROUTES.CLAIMANT}
      subRoute={ClaimantsSubRoute.View}
      linkField="claimantId"
      {...props}
    ></LinkCell>
  );
};

const ClaimCell: React.FC<GridCellProps> = (props: GridCellProps) => {
  return (
    <LinkCell
      endpoint={ROUTES.CLAIM}
      subRoute={ClaimsSubRoute.View}
      linkField="claimId"
      {...props}
    ></LinkCell>
  );
};


const LinkDateColumn: React.FC<GridCellProps> = (props: GridCellProps) => {
  return (
    <DateLinkCell
      endpoint={ROUTES.APPOINTMENT}
      linkField="id"
      subRoute={AppointmentSubRoute.View}
      dateFormat={DEFAULT_DATE_FORMAT}
      {...props}
    />
  );
};

const ActionsCell: React.FC<GridCellProps> = (props: GridCellProps) => {
  return (
    <EditDeleteScheduleCell
      deleteEndpoint={ENDPOINTS.appointmentsCommercial.base}
      deletePermissions={[Permission.DeleteAppointment]}
      {...props}
    />
  );
};

const dateFormatCell: React.FC<GridCellProps> = (props: GridCellProps) => {
  return <DateCell {...props} dateFormat={'MM/DD/YYYY'} />;
};