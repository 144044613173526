import React from "react";
import { useFilterBarValue } from "Context";
import { IFilterConfig } from "Models";

export const SearchTextFilter: React.FC<IFilterConfig> = ({
  name,
  displayName,
}) => {
  const [textValue, setTextValue] = useFilterBarValue(name, "");

  return (
    <input
      className="filter-bar-search-input"
      type="text"
      placeholder={displayName}
      value={textValue}
      onChange={(e) => setTextValue(e.target.value)}
    />
  );
};
