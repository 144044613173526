import { CoreEnum } from 'Models';
import { DropdownItemModel } from './SharedModels';

export class Report {
  columnConfigurations?: ReportColumnConfiguration[];
  filterConfigurations?: ReportFilterConfiguration[];
  id?: string;
  customTitle?: string;
  reportType?: CoreEnum;
  isActive?: boolean;
  roles?: ReportRoleModel[];
  subscriptionAvailable?: boolean;
  exportType?: CoreEnum;
  frequency?: CoreEnum;
  dayOfMonth?: number;
  lastDayOfMonth?: boolean;
  dayOfWeek?: CoreEnum;
  timeOfDay?: Date;
}

export class ReportRoleModel {
  roleId?: string;
  selected?: boolean;
  subscribed?: boolean;
  roleName?: string;
}

export class ReportColumn {
  constructor(configurationId?: string) {
    this.configurationId = configurationId;
  }
  id?: string;
  draggableId?: string;
  configurationId?: string;
  column?: number;
  defaultColumnHeader?: string;
  order?: number;
  customColumnHeader?: string;
  columnHeader?: string;
  hasCustomFormat?: boolean;
  formatDisplay?: string;
  columnOptions?: ColumnOptionDropdownItem[];
  selectedColumn?: ColumnOptionDropdownItem;
  headerAggregateOption?: CoreEnum;
  footerAggregateOption?: CoreEnum;
  booleanFormatOption?: CoreEnum;
  decimalFormatOption?: CoreEnum;
  dateTimeFormatOption?: CoreEnum;
}

export class ColumnOptionDropdownItem {
  columnType?: CoreEnum;
  value?: string;
  displayName?: string;
}

export class ReportFilter {
  constructor(configurationId?: string) {
    this.configurationId = configurationId;
  }
  id?: string;
  configurationId?: string;
  filter?: number;
  customFilterLabel?: string;
  excluded?: string;
  filterOptions?: FilterOptionDropdownItem[];
  selectedFilter?: FilterOptionDropdownItem;
  subscriptionFilterId?: string;
  subscriptionAvailable?: boolean;
  monthsAgo?: number;
  daysAgo?: number;
  hoursAgo?: number;
  useRuntime?: boolean;
  reportRuntimeOption?: CoreEnum;
}

export class FilterOptionDropdownItem {
  typeValue?: number;
  value?: string;
  displayName?: string;
  enumeration?: string;
  endpoint?: string;
}

export class ReportConfiguration {
  id?: string;
  name!: string;
  hasFilters?: boolean;
  reportType!: CoreEnum;
  filters?: ReportFilterConfiguration[];
  columns!: ReportColumnConfiguration[];
}

export type ReportColumnConfiguration = {
  isHyperlink: boolean;
  isDownloadlink: boolean;
  isLink: boolean;
  linkField: string;
  linkEndpoint: string;
  linkSubroute?: string;
  field: string;
  title: string;
  format: string;
  type: string;
  width: number;
  encoded: boolean;
  template: string;
  headerTemplate: string;
  footerTemplate: string;
  groupFooterTemplate: string;
  groupHeaderTemplate: string;
  groupHeaderColumnTemplate: string;
  hasBooleanFormat?: boolean;
  hasDecimalFormat?: boolean;
  hasDateTimeFormat?: boolean;
  aggregates: string[];
};

export type ReportFilterConfiguration = {
  type: string;
  name: string;
  filterName: string;
  filterValue: any;
  label: string;
  endpoint: string;
  enumeration: string;
  isMultiSelect: boolean;
};

export class CustomReportSearchModel {
  [x: string]: any;
}

export enum ReportFilterModal {
  EditReport = 'editReport',
  EditSubscriptionReport = 'editSubscriptionReport',
}

export class ReportSubscriptionFilter {
  constructor(configurationId?: string) {
    this.reportFilterConfigurationId = configurationId;
    this.hasCustomEntityEvents = true;
  }

  reportFilterConfigurationId?: string;
  reportId?: string;
  type?: CoreEnum;
  filter?: number;
  exportOption?: CoreEnum;
  yesNoValue?: boolean;
  booleanValue?: boolean;
  searchValue?: string;
  dateFilterValue?: Date;
  monthsAgo?: number;
  daysAgo?: number;
  hoursAgo?: number;
  useRuntime?: boolean;
  reportRuntimeOption?: CoreEnum;
  dropdownItem?: DropdownItemModel;
  enumValue?: any;
  hasCustomEntityEvents?: boolean;
}
