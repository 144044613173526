export enum SignaturesModal {
  ApplySignature = "applySignature",
  ReturnWithoutSigning = "returnWithoutSigning"
}

export class SignDocumentModalItem {
  warrantId?: string;
  documentId?: string;
  fileName?: string;
}
