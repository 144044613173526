import {
  GridCellProps,
  GridColumn as Column,
} from '@progress/kendo-react-grid';
import {
  DateCell,
  DateLinkCell,
  FilterBarMainGrid,
  LinkCell,
} from 'Components/Grid';
import { FormProvider, GridProvider } from 'Context';
import {
  defaultGridProps,
  FilterBarFilterType,
  FilterConfig,
  IFilterConfig,
  StaticFilterConfig,
} from 'Models';
import { ENDPOINTS } from 'Models/Templates/Routes/Routes';
import {
  AppointmentSubRoute,
  ClaimantsSubRoute,
  ClaimsSubRoute,
  ROUTES,
} from 'Navigation';
import React, { HTMLProps, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem, Col, Row } from 'reactstrap';
import { DEFAULT_DATE_FORMAT, buildEndpoint, useGridSearch } from 'Utilities';
import { LightPageHeader } from '../../Components/Display';
import { ColumnSelectorProvider, useColumnSelectorState } from 'Context/ColumnSelectorProvider';
import { DefaultColumnsSelection } from 'Models/ColumnSelection';

class ReferralSearch {
  searchKey?: string;
  id?: string;
  claimId?: string;
  dueDate?: Date;
  referralDate?: Date;
}

export enum ReferralGridIds {
  DetailedSearch = 'detailedSearch',
}

export const AllClaimants: React.FC = () => {
  return (
    <ColumnSelectorProvider
      columns={DefaultColumnsSelection}
      columnSelectorName='allReferralsColumns'
    >
      <GridProvider entityName="Referral">
        <Breadcrumb tag="nav" listTag="div">
          <BreadcrumbItem>
            <Link to={ROUTES.DASHBOARD}>Home</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>All Referrals</BreadcrumbItem>
        </Breadcrumb>
        <LightPageHeader tall noBorder className="page-header-shadow">
          <h1>All Referrals</h1>
        </LightPageHeader>
        <br />
        <Row>
          <Col></Col>
        </Row>
        <AllClaimantsFilteredGrid />
      </GridProvider>
    </ColumnSelectorProvider>
  );
};

export type ReferralsFilteredGridProps =
  HTMLProps<ReferralsFilteredGridProps> & {
    id?: string;
  };

export const AllClaimantsFilteredGrid: React.FC<ReferralsFilteredGridProps> = ({
  id,
  ...props
}) => {
  const allClaimantsSearch = new ReferralSearch();
  allClaimantsSearch.claimId = id;
  return (
    <FormProvider formModel={allClaimantsSearch}>
      <AllClaimantsGrid id={id} />
    </FormProvider>
  );
};

const AllClaimantsGrid: React.FC<ReferralsFilteredGridProps> = ({
  id,
  ...props
}) => {
  const columnSelectorState = useColumnSelectorState()

  const [ exportEndpoint, setExportEndpoint ] = useState(buildEndpoint(
    ENDPOINTS.referrals.endpointString('ExportToCsv') + `${getSelectedColumnsQuery(columnSelectorState)}`
  ));

  useEffect(() => {
    setExportEndpoint(buildEndpoint(
      ENDPOINTS.referrals.endpointString('ExportToCsv') + `${getSelectedColumnsQuery(columnSelectorState)}`
    ));
  }, [columnSelectorState]);

  const filters: IFilterConfig[] = [
    new FilterConfig(
      'searchKey',
      FilterBarFilterType.SearchText,
      'Stradix Number, Claim Number, Claimant, Referral Number, Doctor'
    ),
    new FilterConfig('dueDate', FilterBarFilterType.DateRange, 'Due Date'),
    new FilterConfig(
      'referralDate',
      FilterBarFilterType.DateRange,
      'Referral Date'
    ),
    new StaticFilterConfig('claimId'),
  ];
  const [gridToggler] = useGridSearch();

  return (
    <React.Fragment>
      <FilterBarMainGrid
        isMainGrid
        filterBarProps={{
          filterConfigurations: filters,
          filterValues: {},
          isColumnSelectorEnabled: true
        }}
        refreshToggle={gridToggler}
        readEndpoint={ENDPOINTS.referrals.read}
        exportEndpoint={exportEndpoint}
        enableGridRefresh={true}
        {...defaultGridProps}
      >
        { columnSelectorState.columns.find(x => x.field === 'stradixNumber')?.checked && <Column field="stradixNumber" cell={referralNumberCell} title="Stradix Number" width='auto' />}
        { columnSelectorState.columns.every(x => !x.checked) && <Column field="id" title=" " className='hidden' />}
        {columnSelectorState.columns.find(x => x.field === 'clientName')?.checked && <Column field="clientName" title="Client" />}
        {columnSelectorState.columns.find(x => x.field === 'referralDate')?.checked && <Column field="referralDate" title="Referral Date" cell={dateFormatCell} />}
        {columnSelectorState.columns.find(x => x.field === 'reportDueDate')?.checked && <Column field="reportDueDate" title="Report Due Date" cell={dateFormatCell} />}
        {columnSelectorState.columns.find(x => x.field === 'referralDueDate')?.checked && <Column field="dueDate" title="Client Due Date" cell={dateFormatCell} />}
        {columnSelectorState.columns.find(x => x.field === 'claimantName')?.checked && <Column field="claimantName" title="Claimant Name" cell={claimantCell} />}
        {columnSelectorState.columns.find(x => x.field === 'claimNumber')?.checked && <Column field="claimNumber" title="Claim Number" cell={claimNumberCell} />}
        {columnSelectorState.columns.find(x => x.field === 'benefitState')?.checked && <Column field="benefitState" title="Benefit State" />}
        {columnSelectorState.columns.find(x => x.field === 'referralNumber')?.checked && <Column field="referralNumber" title="Referral Number" cell={referralNumberCell} />}
        {columnSelectorState.columns.find(x => x.field === 'status')?.checked && <Column field="appointment.appointmentStatus.displayName" title="Status" />}
        {columnSelectorState.columns.find(x => x.field === 'typeOfEval')?.checked && <Column field="typeOfEval" title="Type of EVAL" />}
        {columnSelectorState.columns.find(x => x.field === 'appointmentDate')?.checked && <Column field="appointmentDate" title="Appointment Date" cell={linkDateColumn} />}
        {columnSelectorState.columns.find(x => x.field === 'treatingPhysician')?.checked && <Column field="treatingPhysician" title="Treating Physician" />}
        {columnSelectorState.columns.find(x => x.field === 'physicianSpecialty')?.checked && <Column field="physicianSpecialty" title="Physician Specialty" />}
        {columnSelectorState.columns.find(x => x.field === 'insuranceCarrier')?.checked && <Column field="insuranceCarrier" title="Insurance Carrier" />}
        {columnSelectorState.columns.find(x => x.field === 'referralSource')?.checked && <Column field="referralSourceName" title="Referral Source" />}
        {columnSelectorState.columns.find(x => x.field === 'adjusterName')?.checked && <Column field="adjusterName" title="Adjuster Name" />}
        {columnSelectorState.columns.find(x => x.field === 'doctor')?.checked && <Column field="doctor" title="Doctor" />}
        { columnSelectorState.columns.find(x => x.field === 'closedDate')?.checked && <Column field="closedDate" title="Date Closed" cell={dateFormatCell} /> }
        { columnSelectorState.columns.find(x => x.field === 'checkMedicalsDate')?.checked && <Column field="checkMedicalsDate" title="Check Medicals Date" cell={dateFormatCell} /> }
      </FilterBarMainGrid>
    </React.Fragment>
  );
};
// deconstruct so that I can use the dataItem property alone
const linkDateColumn: React.FC<GridCellProps> = (props: GridCellProps) => {
  return (
    <DateLinkCell
      endpoint={ROUTES.APPOINTMENT}
      linkField="appointmentId"
      subRoute={AppointmentSubRoute.View}
      dateFormat={DEFAULT_DATE_FORMAT}
      {...props}
    />
  );
};

const dateFormatCell: React.FC<GridCellProps> = (props: GridCellProps) => {
  return <DateCell {...props} dateFormat={DEFAULT_DATE_FORMAT} />;
};

const referralNumberCell: React.FC<GridCellProps> = (props: GridCellProps) => {
  return (
    <LinkCell
      endpoint={ROUTES.REFERRAL}
      linkField="referralId"
      subRoute={ClaimsSubRoute.View}
      {...props}
    ></LinkCell>
  );
};

const claimNumberCell: React.FC<GridCellProps> = (props: GridCellProps) => {
  return (
    <LinkCell
      endpoint={ROUTES.CLAIM}
      linkField="claimId"
      subRoute={ClaimsSubRoute.View}
      {...props}
    ></LinkCell>
  );
};

const claimantCell: React.FC<GridCellProps> = (props: GridCellProps) => {
  return (
    <LinkCell
      endpoint={ROUTES.CLAIMANT}
      linkField="claimantId"
      subRoute={ClaimantsSubRoute.View}
      {...props}
    ></LinkCell>
  );
};

const getSelectedColumnsQuery = (columnSelectorState: any) => {
  const selectedColumns = columnSelectorState.columns.filter((x: any) => x.checked).map((x: any) => x.field);
  return '?' + selectedColumns.map((x: any) => `columns=${x}`).join('&');
}