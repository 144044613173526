import React from 'react';
import { InputBlock, InputBlockProps } from 'Components/Form';

export type GridInputProps = InputBlockProps & {
  dataItem: any;
  name: string;
  onChange?: (e: any) => void;
};

export const GridInput: React.FC<GridInputProps> = ({
  dataItem,
  name,
  onChange,
  ...otherProps
}) => {
  const handleChange = (e: any) => {
    onChange &&
      onChange({
        dataItem: dataItem,
        field: name,
        syntheticEvent: e?.syntheticEvent,
        value: e?.value || e,
      });
  };

  return <InputBlock name={name} onChange={handleChange} {...otherProps} />;
};
