import { ViewGuideline } from 'Features/Guidelines';
import { Permission } from 'Models/Templates/Permission/Permission';
import { AdminSubRouteConfig, SubRoutes } from 'Navigation';

export enum GuidelineSubRoute {
  View = '/View',
}

export const GuidelinesSubRoutes: SubRoutes = {
  [GuidelineSubRoute.View]: new AdminSubRouteConfig(
    GuidelineSubRoute.View,
    'View',
    ViewGuideline,
    true,
    [Permission.ViewGuideline]
  ),
};
