import { MultiGridTabStrip, PageHeading } from 'Components/Display';
import {
  FilterBarMainGrid,
  getFormattedDisplayCell,
  YesNoBooleanCell,
} from 'Components/Grid';
import React, { Fragment } from 'react';
import { GridColumn as Column } from '@progress/kendo-react-grid';

import { ENDPOINTS } from 'Models/Templates/Routes/Routes';
import {
  defaultGridProps,
  FilterBarFilterType,
  FilterConfig,
  IFilterConfig,
} from 'Models';
import { MultiGridProvider, useEnums } from 'Context';

export enum ExampleGridIds {
  DetailedSearch = 'detailedSearch',
  OtherGrid = 'otherGrid',
}

export const ExamplesGrid: React.FC<any> = () => {
  return (
    <MultiGridProvider>
      <PageHeading name="Grid Sample" />
      <MultiGridTabStrip
        showGridTotals
        gridTabConfigs={[
          {
            gridId: ExampleGridIds.DetailedSearch,
            title: 'Search ',
            content: <FilterBarDetailedSearchGrid />,
          },
        ]}
      />
    </MultiGridProvider>
  );
};

export const FilterBarDetailedSearchGrid: React.FC = () => {
  const filters: IFilterConfig[] = [
    new FilterConfig(
      'searchTerm',
      FilterBarFilterType.SearchText,
      ' Name, ID, or #'
    ),
    new FilterConfig(
      'dateOfBirth',
      FilterBarFilterType.DateRange,
      'Date Of Birth'
    ),
    new FilterConfig(
      'user',
      FilterBarFilterType.Dropdown,
      'User',
      {
        readEndpoint: ENDPOINTS.users.dropdownItems,
      },
      undefined,
      true
    ),
    // new FilterConfig(
    //   'custodyStatus',
    //   FilterBarFilterType.EnumRadioGroup,
    //   'Is Active?',
    //   { data: custodyStatus },
    //   (value) => (value?.value === 'Yes' ? 'Active' : 'Inactive')
    // ),
    new FilterConfig(
      'hasActiveWarrant',
      FilterBarFilterType.YesNoBooleanRadioGroup,
      'Warrant?',
      undefined,
      (value) => (value ? 'Has Active Warrant' : 'No Active Warrant')
    ),
    // new FilterConfig(
    //   'ethnicity',
    //   FilterBarFilterType.EnumDropdown,
    //   'Ethnicity',
    //   { data: ethnicity },
    //   undefined,
    //   true
    // ),
  ];

  return (
    <FilterBarMainGrid
      filterBarProps={{
        filterConfigurations: filters,
        filterValues: [], //new DetailedSearchModel(),
      }}
      readEndpoint={ENDPOINTS.users.endpointString('FilterableCases')}
      isMainGrid
      {...defaultGridProps}
    >
      <Column field="defendantCID" title="CID" />
      <Column field="caseNumber" title="Case Number" />
      <Column
        field="defendantLastName"
        title="Name"
        cell={getFormattedDisplayCell('defendantName')}
      />
      <Column
        field="dateOfBirth"
        title="Date Of Birth"
        cell={getFormattedDisplayCell('dateOfBirthDisplay')}
      />
      <Column field="hasWarrant" title="Warrant" cell={YesNoBooleanCell} />
      <Column field="isInCustody" title="In Custody" cell={YesNoBooleanCell} />
    </FilterBarMainGrid>
  );
};
