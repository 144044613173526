import React, { useEffect, useState } from 'react';
import {
  Route,
  useHistory,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';
import {
  buildRoute,
  isNullEmptyOrUndefined,
  useHasPermissions,
} from 'Utilities';
import {
  NavConfigItem,
  ROUTES,
  ROUTE_PARAMETERS,
  SubRouteConfig,
  SubRoutes,
  SUBROUTES_MAP,
  SUB_NAV_CONFIG_MAP,
} from '.';
import { ADD_EDIT } from './NavigationTypes';
import { AddEditSubRoute } from './SubRoutes';

//helper functions
/*
  Maps a SubRoutes instance to a list of NavConfigItems,
  used for displaying sidebar or top level navigation items
*/
export const mapSubroutesToNavConfigItems = (
  subRoutes: SubRoutes
): NavConfigItem[] => {
  return Object.entries(subRoutes)
    .filter(([, subRoute]) => !subRoute.hideInSidebar)
    .map(([subRouteName, subRoute]) => {
      return { ...subRoute, subRouteName, active: false };
    });
};

/*
 * @param route
 * Builds the full route path recursively from default subroutes
 */
export const getFullDefaultRoute = (
  route: string,
  parentRoute?: string
): string => {
  var subRoutes = parentRoute
    ? SUBROUTES_MAP[parentRoute + route]
    : SUBROUTES_MAP[route];

  if (subRoutes !== undefined) {
    var defaultSubRoute =
      Object.values(subRoutes).find((x) => x.default) ||
      Object.values(subRoutes)[0];
    if (defaultSubRoute !== undefined) {
      return route + getFullDefaultRoute(defaultSubRoute.route, route);
    }
  }

  return route;
};

export function addEditRoute(subRoute: AddEditSubRoute, id?: string) {
  return buildRoute(ROUTES.ADD_EDIT, subRoute, id);
}

/*
  Creates a full route with route parameters, if any
*/
export const fullRoute = (route: ROUTES) => {
  const param = ROUTE_PARAMETERS[route];
  if (param) {
    return route + `/${param}`;
  }
  return route;
};

export function getSubRouteName(mainRoute: ROUTES | string, subRoute: string) {
  return mainRoute + subRoute;
}

export function getHolidayAddEditRoute(holidayId: string, id: string) {
  return `/api/AddEdit/HolidayDate/${id}/`;
}

/*
  Adds AddEdit/:id? to end of route
*/
export function toAddEditRoute(route: string) {
  return withIdParam(`${route}/${ADD_EDIT}`);
}

export function withIdParam(route: string) {
  return `${route}/:id?`;
}
/*
  Adds View to end of route
*/
export function toViewRoute(route: string) {
  return `${route}/View/:id`;
}

//hooks
/*
  For top level navigation items, sets default route to active,
  handles active changes, filters by permissions
*/
export const useNavigation = (navItems: NavConfigItem[]) => {
  const [navConfig, setNavConfig] = useState<NavConfigItem[]>(navItems || []);
  const { pathname } = useLocation();
  const hasPermissions = useHasPermissions();
  let history = useHistory();

  useEffect(() => {
    var tempConfig: NavConfigItem[] = [];
    var defaultRoute;
    if (pathname === '/') {
      tempConfig = navConfig.map((x: NavConfigItem) => {
        x.active = x.default || false;
        return x;
      });
      defaultRoute = tempConfig.find((x) => x.default);
    } else {
      tempConfig = navConfig.map((x: NavConfigItem) => {
        x.active = pathname.startsWith(x.route);
        return x;
      });
    }
    setNavConfig(
      tempConfig
        .filter((x) => hasPermissions(x.permissions))
        .map((x) => {
          x.fullRoute = getFullDefaultRoute(x.route);
          return x;
        })
    );
    if (defaultRoute) {
      history.push(getFullDefaultRoute(defaultRoute.route));
    }
  }, [pathname]);

  return { navConfig };
};

/*
  Returns function for fetching permitted sub navigation items for given parent route
*/
export const useGetSecureSubNavItems = (
  filter?: (x: NavConfigItem) => boolean,
  noPermissionsFilter = false
) => {
  const hasPermissions = useHasPermissions();
  const { pathname } = useLocation();

  const getSubNavItems = (parentRoute?: string) => {
    if (parentRoute) {
      return (
        SUB_NAV_CONFIG_MAP[parentRoute]
          ?.filter((x) =>
            noPermissionsFilter ? true : hasPermissions(x.permissions)
          )
          ?.filter(filter || ((x) => x))
          .map((x) => {
            x.fullRoute = getFullDefaultRoute(x.route, parentRoute);
            x.active =
              pathname.startsWith(parentRoute + x.route) ||
              pathname.endsWith(x.route);
            return x;
          }) || []
      );
    } else {
      return [];
    }
  };

  return getSubNavItems;
};

/*
  Keeps track of active sub nav items, redirecting to default on parent route change,
  filtering based on permissions
*/
export const useSubNavigation = (parentRoute?: string) => {
  const getSubNavItems = useGetSecureSubNavItems();
  const [navConfig, setNavConfig] = useState<NavConfigItem[]>(
    getSubNavItems(parentRoute)
  );
  const { pathname } = useLocation();

  useEffect(() => {
    var tempConfig: NavConfigItem[] = getSubNavItems(parentRoute);
    tempConfig = tempConfig.map((x: NavConfigItem) => {
      const routeParts = pathname
        .split('?')[0]
        .split('/')
        .filter((s) => !isNullEmptyOrUndefined(s));
      x.active = routeParts.includes(x.route.replace('/', ''));
      return x;
    });

    setNavConfig(tempConfig);
  }, [pathname]);

  return navConfig;
};

/*
  Same as above, but uses case type filtering
*/

/*
  Returns function for mapping SubRoutes instance to individual Route Components,
  filters by permissions
*/
export const useSubRoutes = (
  filter?: (configPair: [string, SubRouteConfig]) => boolean
) => {
  const hasPermissions = useHasPermissions();
  let { url } = useRouteMatch();

  const mapSubRoutesToRoutes = (subRoutes: SubRoutes) => {
    return Object.entries(subRoutes)
      .filter(
        ([, subRoute]) =>
          subRoute.component !== undefined &&
          hasPermissions(subRoute.permissions)
      )
      .filter(filter || ((x) => x))
      .map(([, subRoute], index) => {
        const { exact, ...otherRouteProps } = subRoute.routeProps;
        const Component = subRoute.component;

        return (
          <Route
            key={index}
            path={buildRoute(url, subRoute.route)}
            exact={exact !== undefined ? exact : true}
            {...otherRouteProps}
          >
            <Component />
          </Route>
        );
      });
  };

  return mapSubRoutesToRoutes;
};
