import React, { useEffect } from 'react';
import {
  SchedulerHeaderProps,
  SchedulerHeader,
  useSchedulerDateContext,
  useSchedulerActiveViewContext,
  useSchedulerDateRangeContext,
} from '@progress/kendo-react-scheduler';
import { formatDate } from '@telerik/kendo-intl';
import {
  ButtonSize,
  PopupCalendar,
  SecondaryButton,
  TextButton,
} from 'Components/Form';
import { CalendarViewType, DropdownType, getViewDisplayName } from 'Models';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { addDays, addMonths, addWeeks } from '@progress/kendo-date-math';
import { ActionsDropdown } from '../ActionsDropdown';
import { useOptionalLocalState } from 'Context';
import { EventCalendarLocalState } from './EventCalendar';

export const EventCalendarHeader: React.FC<SchedulerHeaderProps> = () => {
  const [schedulerDate, setSchedulerDate] = useSchedulerDateContext();

  const { start, end } = useSchedulerDateRangeContext();
  const localStateContext = useOptionalLocalState<EventCalendarLocalState>();

  useEffect(() => {
    if (localStateContext !== undefined) {
      const [{ startDate, endDate, date }, setLocalState] = localStateContext;
      if (date?.valueOf() !== schedulerDate.valueOf()) {
        setLocalState((prev) => {
          return { ...prev, date: schedulerDate };
        });
      }
      if (
        startDate?.valueOf() !== start.valueOf() ||
        endDate?.valueOf() !== end.valueOf()
      ) {
        setLocalState((prev) => {
          return { ...prev, startDate: start, endDate: end };
        });
      }
    }
  }, [start, end, schedulerDate]);

  return (
    <SchedulerHeader>
      <div className="flex-vertical-center">
        <PopupCalendar
          className="mr-3"
          value={schedulerDate}
          onChange={(e) => setSchedulerDate(e.value)}
        />
        <h3 className="pr-large calendar-date-display">
          {formatDate(schedulerDate, 'MMMM yyyy')}
        </h3>
        <SecondaryButton
          buttonSize={ButtonSize.Small}
          onClick={() => setSchedulerDate(new Date())}
          className="mr-4"
        >
          Today
        </SecondaryButton>
        <EventCalendarDateArrows />
        <EventCalendarViewDropdown />
      </div>
    </SchedulerHeader>
  );
};

export const EventCalendarDateArrows: React.FC = () => {
  const [date, setDate] = useSchedulerDateContext();
  const [viewType] = useSchedulerActiveViewContext();

  const handleClickBackArrow = () => {
    switch (viewType) {
      case CalendarViewType.Day:
        setDate(addDays(date, -1));
        break;
      case CalendarViewType.Week:
        setDate(addWeeks(date, -1));
        break;
      case CalendarViewType.Month:
        setDate(addMonths(date, -1));
        break;
    }
  };

  const handleClickForwardArrow = () => {
    switch (viewType) {
      case CalendarViewType.Day:
        setDate(addDays(date, 1));
        break;
      case CalendarViewType.Week:
        setDate(addWeeks(date, 1));
        break;
      case CalendarViewType.Month:
        setDate(addMonths(date, 1));
        break;
    }
  };

  return (
    <div className="date-arrows-wrapper flex-vertical-center flex-grow">
      <TextButton className="mr-3" onClick={handleClickBackArrow}>
        <ChevronLeftIcon />
      </TextButton>
      <TextButton onClick={handleClickForwardArrow}>
        <ChevronRightIcon />
      </TextButton>
    </div>
  );
};

export const EventCalendarViewDropdown: React.FC = () => {
  const [viewType, setViewType] = useSchedulerActiveViewContext();

  const dropdownItems = Object.values(CalendarViewType).map((view) => {
    return {
      name: getViewDisplayName(view),
      onClick: () => setViewType(view),
    };
  });

  return (
    <ActionsDropdown
      name={getViewDisplayName(viewType)}
      items={dropdownItems}
      type={DropdownType.PrimaryOutline}
      isSmall
    />
  );
};
