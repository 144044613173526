import './AlertsDisplay.scss';
import React from 'react';
import { Alert as AlertModel } from 'Models/SharedModels';
import { fullClassName } from 'Utilities';
import { AlertType } from '../../Models/SharedModels';
import ErrorIcon from '@mui/icons-material/Error';
import WarningIcon from '@mui/icons-material/Warning';
import InfoIcon from '@mui/icons-material/Info';

export type PageAlertProps = {
  alert: AlertModel;
  horizontalLayout?: boolean;
};

export const PageAlert: React.FC<PageAlertProps> = ({
  children,
  alert,
  horizontalLayout = false,
}) => {
  const alertClass = `page-alert-${alert.type}`;

  return (
    <div
      className={fullClassName(
        'page-alert mb-3 flex-normal flex-vertical-center animate-slide-in card-shadow-light font-secondary-bold bg-light',
        alertClass,
        horizontalLayout ? undefined : 'full-width'
      )}
    >
      <div className="alert-icon mr-small">
        <AlertIcon type={alert.type} />
      </div>
      {children}
    </div>
  );
};

export const AlertIcon: React.FC<{ type: AlertType }> = ({ type }) => {
  switch (type) {
    case AlertType.Danger:
      return <ErrorIcon className="color-danger" />;
    case AlertType.Warning:
      return <WarningIcon className="color-warning" />;
    case AlertType.Info:
      return <InfoIcon className="color-info" />;
    default:
      return <WarningIcon className="color-warning" />;
  }
};
