import React from 'react';
import { Permission } from 'Models/Templates/Permission/Permission';
import { DropdownSubRouteConfig, SubRoutes } from 'Navigation/NavigationTypes';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import { AuditQueue, AuditReport } from 'Features/QualityAssurance';

export enum QualityAssuranceSubRoute {
  AuditQueue = '/AuditQueue',
  AuditReport = '/AuditReport',
}

export const QualityAssuranceSubRoutes: SubRoutes = {
  [QualityAssuranceSubRoute.AuditQueue]: new DropdownSubRouteConfig(
    QualityAssuranceSubRoute.AuditQueue,
    'Audit Queue',
    AuditQueue,
    [Permission.ViewAuditQueue],
    false,
    {},
    <FormatAlignLeftIcon />
  ),
  [QualityAssuranceSubRoute.AuditReport]: new DropdownSubRouteConfig(
    QualityAssuranceSubRoute.AuditReport,
    'Audit Report',
    AuditReport,
    [Permission.ViewAuditReport],
    false,
    {},
    <FormatAlignLeftIcon />
  ),
};
