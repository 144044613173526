import React, { Fragment, useRef } from "react";
import {
  SchedulerItemContent,
  SchedulerItem,
  useSchedulerActiveViewContext,
} from "@progress/kendo-react-scheduler";
import {
  fullClassName,
  toggledClass,
  toTimeString,
  useToggler,
} from "Utilities";
import { CustomViewItemProps } from "./EventCalendar";
import { EasyPopup } from "../EasyPopup";
import { CalendarViewType } from "Models";
import { Render } from "../Render";

export const CustomViewItem: React.FC<CustomViewItemProps> = ({
  className,
  children,
  popupContent,
  ...viewItemProps
}) => {
  const [view] = useSchedulerActiveViewContext();
  const isMonth = view === CalendarViewType.Month;
  const isBeforeNow = viewItemProps.end.valueOf() < new Date().valueOf();
  const [isPopupOpen, toggleIsPopupOpen] = useToggler(false);
  const toggleRef = useRef<any>(null);
  const PopupComponent = popupContent!;

  return (
    <SchedulerItem
      className={fullClassName(
        className,
        isMonth ? "month-view-item" : "weekday-view-item",
        toggledClass("before-now", isBeforeNow)
      )}
      {...viewItemProps}
    >
      <SchedulerItemContent>
        <div
          className={fullClassName(
            "full-size pl-small pr-regular position-relative",
            isMonth ? "flex-vertical-center" : "flex-column"
          )}
          ref={(ref) => (toggleRef.current = ref)}
          onClick={toggleIsPopupOpen}
        >
          <ViewItemContent {...viewItemProps} />
          <div className="event-separator" />
        </div>
        {popupContent && (
          <EasyPopup
            isOpen={isPopupOpen}
            toggleIsOpen={toggleIsPopupOpen}
            togglerRef={toggleRef}
            caret
          >
            <PopupComponent {...viewItemProps} />
          </EasyPopup>
        )}
      </SchedulerItemContent>
    </SchedulerItem>
  );
};

export const ViewItemContent: React.FC<CustomViewItemProps> = ({
  description,
  start,
  end,
  title,
  hideTimeFrame
}) => {
  const [view] = useSchedulerActiveViewContext();
  const isMonth = view === CalendarViewType.Month;

  return (
    <Fragment>
      <Render condition={!isMonth}>
        <span className="font-secondary-bold pt-tiny">{description}</span>
      </Render>
      <Render condition={!hideTimeFrame}>
        <span className={toggledClass("flex-grow mr-tiny", isMonth)}>
          {toTimeString(start)}–{toTimeString(end)}
        </span>
      </Render>
      <span className="font-secondary-bold font-size-tiny">{title}</span>
    </Fragment>
  );
};
