import MoreVertIcon from '@mui/icons-material/MoreVert';
import { GridCellProps } from '@progress/kendo-react-grid';
import { Menu, MenuItemModel } from '@progress/kendo-react-layout';
import { Popup } from '@progress/kendo-react-popup';
import { TextButton } from 'Components/Form';
import { MultiModalProvider } from 'Context';
import { ActionsDropdownConfig } from 'Models';
import React, { useRef } from 'react';
import { fullClassName, useOnClickOutside, useToggler } from 'Utilities';
import {
  ActionsDropdownItemDisplay,
  MultiModalRenderer,
} from './ActionsDropdown';
import { Render } from './Render';

export type GridActionsDropdownSpecificProps = {
  buttonWrapperClass?: string;
};

export type GridActionsDropdownProps = Partial<GridCellProps> &
  ActionsDropdownConfig &
  GridActionsDropdownSpecificProps;

export const GridActionsDropdown: React.FC<GridActionsDropdownProps> = ({
  items,
  buttonWrapperClass,
  dataItem,
}) => {
  const [isOpen, toggleIsOpen] = useToggler(false);
  var menuToggleRef = useRef<any>(null);
  var menuRef = useRef<any>(null);

  useOnClickOutside([menuRef, menuToggleRef], () => {
    isOpen && toggleIsOpen();
  });

  const kendoItems: MenuItemModel[] = items
    .filter((i) => i.condition === undefined || i.condition)
    .map(({ onClick, divWrapper, modalConfig, ...dropdownItem }) => {
      const onClickAndToggle = (e: any) => {
        onClick && onClick(e);
        toggleIsOpen();
      };

      if (modalConfig && !modalConfig.modalItem && dataItem) {
        modalConfig.modalItem = dataItem;
      }

      const kendoItem: MenuItemModel = {
        linkRender: (props: any) => (
          <ActionsDropdownItemDisplay
            onClick={onClickAndToggle}
            divWrapper={true}
            modalConfig={modalConfig}
            {...dropdownItem}
          />
        ),
        disabled: dropdownItem.isDisabled,
      };

      return kendoItem;
    });

  return (
    <MultiModalProvider>
      <MultiModalRenderer items={items} />
      <Render condition={kendoItems.length > 0}>
        <div
          className={fullClassName(buttonWrapperClass)}
          ref={(ref) => (menuToggleRef.current = ref)}
        >
          <TextButton onClick={toggleIsOpen}>
            <MoreVertIcon />
          </TextButton>
        </div>
      </Render>
      <Popup
        className="animate-fade-in"
        anchor={menuToggleRef.current}
        anchorAlign={{ horizontal: 'right', vertical: 'bottom' }}
        popupAlign={{ horizontal: 'right', vertical: 'top' }}
        show={isOpen}
        animate={false}
        appendTo={document.getElementById('root') || undefined}
      >
        <span className="d-inline-block" ref={(ref) => (menuRef.current = ref)}>
          <Menu
            className="actions-dropdown flex-start"
            style={{ display: 'inline-block' }}
            items={kendoItems}
            onSelect={toggleIsOpen}
            vertical
          />
        </span>
      </Popup>
    </MultiModalProvider>
  );
};
