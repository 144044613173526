import React, { Fragment } from 'react';
import {
  isNullEmptyOrUndefined,
  restrictPercentageValue,
  validateEmail,
} from 'Utilities';
import { CONTROLLERS, ENDPOINTS } from 'Models/Templates/Routes/Routes';
import { useEnums, useFormState, FormProvider } from 'Context';
import { Row } from 'reactstrap';
import { InputType } from 'Models/FormModels';
import { SmartInputBlock } from '.';
import { FixedFormSubmitOrCancelBar } from 'Components/Display';
import {
  PageCard,
  PageCardRow,
  PageCardSection,
} from 'Components/Display/PageCard';
import {
  SmartMultiSectionList,
  ListSectionProps,
  defaultFormatName,
} from './SmartInputs/SmartMultiSectionList';
import { SmartFileUploadDrawerInput, SmartFileUploader } from './SmartInputs';
import { DropdownItemModel } from 'Models';

type ListObject = {
  textValue?: string;
  dateValue?: Date;
};

class ExampleType {
  constructor() {
    this.numberValue = 0;
    this.disabledTextValue = 'Disabled Value';
  }

  textValue?: string;
  numberValue?: number;
  dateValue?: Date;
  dateTimeValue?: Date;
  timeValue?: Date;
  dropdownValue?: any;
  checkboxValue?: boolean;
  listValue?: ListObject[];
  disabledTextValue?: string;
}

export const ExampleForm: React.FC = () => {
  const exampleModel: ExampleType = new ExampleType();

  return (
    <FormProvider formModel={exampleModel} endpoint={ENDPOINTS.users.base}>
      <ExampleFormContent />
    </FormProvider>
  );
};

const ExampleFormContent: React.FC = () => {
  const { formValues } = useFormState();

  const initialListObjectValue: ListObject = {
    textValue: 'Initial Text Value',
    dateValue: new Date(),
  };

  /* For Testing New Input Types */
  // React.useEffect(() => {
  //   console.log(formValues);
  // }, [formValues]);

  return (
    <PageCard title="Form Input Examples">
      <PageCardRow>
        <PageCardSection title="Text & Number Inputs">
          <Row>
            <SmartInputBlock
              type={InputType.Text}
              name="textValue"
              label="Text"
              width={200}
            />
            <SmartInputBlock
              type={InputType.Text}
              name="disabledTextValue"
              label="Text"
              disabled
              width={200}
            />
            <SmartInputBlock
              type={InputType.PhoneNumber}
              name="phoneNumberValue"
              label="Phone Number"
              width={200}
            />
            <SmartInputBlock
              type={InputType.SocialSecurityNumber}
              name="ssnValue"
              label="Social Security #"
              width={200}
            />
            <SmartInputBlock
              type={InputType.TextArea}
              name="textAreaValue"
              label="Text Area"
              width={200}
            />
            <SmartInputBlock
              type={InputType.Number}
              name="numberValue"
              label="Number"
              width={200}
            />
            <SmartInputBlock
              type={InputType.Currency}
              name="currencyValue"
              label="Currency"
              width={200}
            />
            <SmartInputBlock
              type={InputType.Percentage}
              name="percentValue"
              label="Percentage"
              restrictValues={restrictPercentageValue}
              width={200}
            />
          </Row>
        </PageCardSection>
      </PageCardRow>
      <PageCardRow>
        <PageCardSection title="Date & Time Inputs">
          <Row>
            {/* <SmartInputBlock
              type={InputType.DatePicker}
              name="oldDateValue"
              label="Old Date Picker"
            />
            <SmartInputBlock
              type={InputType.DateTimePicker}
              name="oldDateTimeValue"
              label="Old DateTime Picker"
            />
            <SmartInputBlock
              type={InputType.TimePicker}
              name="oldTimeValue"
              label="Old Time Picker"
            /> */}
            <SmartInputBlock
              type={InputType.DatePicker}
              name="dateValue"
              label="Date"
            />
            <SmartInputBlock
              type={InputType.TimePicker}
              name="timeValue"
              label="Time"
            />
          </Row>
        </PageCardSection>
      </PageCardRow>
      <PageCardRow>
        <PageCardSection title="Dropdown & Enum Inputs">
          <Row>
            {/* <SmartInputBlock
              type={InputType.EnumDropdown}
              name="dropdownValue"
              label="Dropdown"
              data={ethnicity}
              width={200}
            />
            <SmartInputBlock
              type={InputType.EnumDropdown}
              name="filteredDropdownValue"
              label="Filterable Dropdown"
              data={ethnicity}
              filterable
              width={200}
            /> */}
            <SmartInputBlock
              type={InputType.AsyncDropDown}
              name="offenseValue"
              label="Async Filterable Dropdown"
              useQuery
              readEndpoint={ENDPOINTS.users.dropdownItems}
              width={450}
            />
            <SmartInputBlock
              type={InputType.AsyncMultiSelect}
              name="usersValue"
              label="Mult-select Dropdown"
              useQuery
              readEndpoint={ENDPOINTS.users.dropdownItems}
              width={450}
              allowCustom
              validateCustomValues={(value: DropdownItemModel[]) =>
                value
                  .filter((d) => isNullEmptyOrUndefined(d.id))
                  .every((d) => validateEmail(d.name || ''))
              }
            />
          </Row>
          <Row>
            <SmartInputBlock
              type={InputType.YesNoBooleanRadioGroup}
              name="yesNoRadioValue"
              label="Yes / No Radio Group"
            />
            {/* <SmartInputBlock
              type={InputType.EnumRadioGroup}
              name="radioValue"
              label="Vertical Radio Group"
              data={ethnicity}
            />
            <SmartInputBlock
              type={InputType.EnumRadioGroup}
              name="horizontalRadioValue"
              label="Horizontal Radio Group"
              data={ethnicity}
              layout="horizontal"
            /> */}
          </Row>
        </PageCardSection>
      </PageCardRow>
      <PageCardRow>
        <PageCardSection title="Boolean Inputs">
          <Row>
            <SmartInputBlock
              type={InputType.Checkbox}
              name="checkboxValue"
              label="Checkbox"
            />
            <SmartInputBlock
              type={InputType.Switch}
              name="switchValue"
              label="Switch"
            />
            <SmartInputBlock
              type={InputType.Switch}
              name="dependentSwitchValue"
              label="Switch-controlled Input"
            />
            <SmartInputBlock
              type={InputType.Text}
              name="switchedInputValue"
              label=" "
              disabled={!formValues.dependentSwitchValue}
              width={200}
            />
          </Row>
        </PageCardSection>
      </PageCardRow>
      <PageCardRow>
        <PageCardSection title="Object List">
          <SmartMultiSectionList
            listName="listValue"
            listObjectName="Item"
            initialValues={initialListObjectValue}
          >
            <ExampleListSection />
          </SmartMultiSectionList>
        </PageCardSection>
      </PageCardRow>
      <PageCardRow>
        <PageCardSection title="File Upload">
          <SmartFileUploader
            name="files"
            fileEndpoint={CONTROLLERS.ReferralDocuments}
            label="Attachments"
            allowMultiple
            required
          />
          <Row>
            <SmartFileUploadDrawerInput
              label="Drawer Upload"
              fileEndpoint={ENDPOINTS.referraldocuments.base}
              required
              fileListName="drawerAttachments"
            />
          </Row>
        </PageCardSection>
      </PageCardRow>
      <FixedFormSubmitOrCancelBar />
    </PageCard>
  );
};

const ExampleListSection: React.FC<ListSectionProps> = ({
  formatInputName = defaultFormatName,
}) => {
  const textName = formatInputName('textValue'),
    dateName = formatInputName('dateValue');

  return (
    <Fragment>
      <Row>
        <SmartInputBlock
          type={InputType.Text}
          name={textName}
          label="Text"
          required
          width={200}
        />
        <SmartInputBlock
          type={InputType.DatePicker}
          name={dateName}
          label="Date"
          width={200}
        />
      </Row>
    </Fragment>
  );
};
