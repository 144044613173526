import { LightPageHeader } from 'Components/Display';
import {
  DateCell,
  EditDeleteNotesCell,
  FilterBarMainGrid,
  LinkCell,
} from 'Components/Grid';
import { FormProvider, GridProvider, useEnums } from 'Context';
import {
  defaultGridProps,
  FilterBarFilterType,
  FilterConfig,
  IFilterConfig,
} from 'Models';
import { ENDPOINTS } from 'Models/Templates/Routes/Routes';
import React from 'react';
import { buildEndpoint, useGridSearch } from 'Utilities';
import {
  GridCellProps,
  GridColumn as Column,
} from '@progress/kendo-react-grid';
import { ReferralSubRoute, ROUTES } from 'Navigation';
import { Col, Row } from 'reactstrap';
import { Permission } from 'Models/Templates/Permission/Permission';

class AssignedToWriterSearch {
  searchKey?: string;
  id?: string;
  client?: string;
  benefitState?: string;
  appointmentDate?: Date;
}

export enum AssignedToWritersGridIds {
  DetailedSearch = 'detailedSearch',
}

export const AssignedToWriters: React.FC = () => {
  return (
    <GridProvider entityName="Referral">
      <LightPageHeader tall noBorder className="page-header-shadow">
        <Row>
          <Col>
            <h1>Assigned To Writers</h1>
          </Col>
        </Row>
      </LightPageHeader>
      <br />
      <Row>
        <Col></Col>
      </Row>
      <FilterBarDetailedSearchGrid />
    </GridProvider>
  );
};

const FilterBarDetailedSearchGrid: React.FC = () => {
  const assignedToWritersSearch = new AssignedToWriterSearch();

  return (
    <FormProvider formModel={assignedToWritersSearch}>
      <AssignedToWriterssGrid />
    </FormProvider>
  );
};

const AssignedToWriterssGrid: React.FC = () => {
  const [gridToggler] = useGridSearch();
  const { state, appointmentStatus } = useEnums();

  const filters: IFilterConfig[] = [
    new FilterConfig(
      'searchKey',
      FilterBarFilterType.SearchText,
      'Stradix Number, Claim Number, Claimant, Referral Number, Doctor'
    ),
    new FilterConfig('client', FilterBarFilterType.AsyncDropdown, 'Client', {
      readEndpoint: ENDPOINTS.clients.dropdownItems,
    }),
    new FilterConfig(
      'professional',
      FilterBarFilterType.AsyncDropdown,
      'Professional',
      {
        readEndpoint: buildEndpoint(
          ENDPOINTS.users.endpointString('userByRolDropdownItems'),
          'Professional'
        ),
      }
    ),
    new FilterConfig(
      'appointmentStatus',
      FilterBarFilterType.EnumDropdown,
      'Appointment Status',
      { data: appointmentStatus }
    ),
    new FilterConfig(
      'benefitState',
      FilterBarFilterType.EnumDropdown,
      'Benefit State',
      { data: state }
    ),
    new FilterConfig(
      'TypeOfEval',
      FilterBarFilterType.AsyncDropdown,
      'Type Of Eval',
      {
        readEndpoint: buildEndpoint(
          ENDPOINTS.clients.endpointString('typeOfEvalDropdownItems')
        ),
      }
    ),
    new FilterConfig(
      'appointmentDate',
      FilterBarFilterType.DatePicker,
      'Appointment Date'
    ),
  ];

  return (
    <React.Fragment>
      <FilterBarMainGrid
        isMainGrid
        filterBarProps={{
          filterConfigurations: filters,
          filterValues: [],
        }}
        refreshToggle={gridToggler}
        readEndpoint={ENDPOINTS.assignedtowriters.read}
        enableGridRefresh={true}
        {...defaultGridProps}
      >
        <Column field="stradixNumber" title="Stradix Number" />
        <Column field="claimNumber" title="Claim Number" />
        <Column field="claimant" title="Claimant" cell={claimantCell} />
        <Column field="client" title="Client" />
        <Column field="doctor" title="Doctor" />
        <Column
          field="appointmentDate"
          title="Appointment Date"
          cell={DateFormatCell}
        />
        <Column
          field="dateOfBirth"
          title="Date Of Birth"
          cell={DateFormatCell}
        />
        <Column field="appointmentStatus" title="Appointment Status" />
        <Column field="benefitState" title="Benefit state" />
        <Column field="typeOfEval" title="Type of Eval" />
        <Column field="notes" title="Notes" />
        <Column field="referralSource" title="Referral Source" />
        <Column field="writer" title="Writer" />
        <Column field="id" width="120" title="Actions" cell={ActionsCellEdit} />
      </FilterBarMainGrid>
    </React.Fragment>
  );
};

const claimantCell: React.FC<GridCellProps> = (props: GridCellProps) => {
  return (
    <LinkCell
      endpoint={ROUTES.REFERRAL}
      linkField="referralId"
      subRoute={ReferralSubRoute.View}
      {...props}
    ></LinkCell>
  );
};

const DateFormatCell: React.FC<GridCellProps> = (props: GridCellProps) => {
  return <DateCell {...props} dateFormat={'MM/DD/YYYY'} />;
};

const ActionsCellEdit: React.FC<GridCellProps> = (props: GridCellProps) => {
  return (
    <EditDeleteNotesCell
      endpoint=""
      editRoute={ROUTES.REFERRAL}
      subRoute={ReferralSubRoute.View}
      linkField="referralId"
      editPermissions={[Permission.EditReferral]}
      deleteEndpoint={ENDPOINTS.referrals.base}
      deletePermissions={[Permission.DeleteReferrals]}
      notesRoute={ROUTES.REFERRAL}
      notesSubRoute={ReferralSubRoute.Notes}
      notesPermissions={[Permission.ViewReferral]}
      {...props}
    />
  );
};
