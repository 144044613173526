import React, { Fragment, useEffect, useState } from 'react';
import {
  GridColumn as Column,
  Grid,
  GridCellProps,
  GridProps,
} from '@progress/kendo-react-grid';
import { DataResult, State, process } from '@progress/kendo-data-query';
import { CommandCell } from '.';
import {
  InlineGridItem,
  GridItemChangeType,
  GridItemList,
  AsyncStatus,
} from 'Models';
import {
  useApiWorker,
  buildEndpoint,
  fullClassName,
  useSimpleDataFetcher,
} from 'Utilities';
import { useGridState, useToasts } from 'Context';
import { AddButton } from 'Components/Form';
import { LoadingMaskSection } from 'Components/Display';
import { NoRecordsAlert } from './NoRecordsAlert';
import { SecureComponent } from 'Components/Security';
import { Permission } from 'Models/Templates/Permission/Permission';

export type InlineGridProps = Partial<GridProps> & {
  readEndpoint: string;
  createEndpoint: string;
  updateEndpoint: string;
  deleteEndpoint: string;
  dataType?: string;
  children: any;
  newItem?: any;
  createPermission?: Permission;
  updatePermission?: Permission;
  deletePermission?: Permission;
};

export const InlineGrid: React.FC<InlineGridProps> = ({
  readEndpoint,
  deleteEndpoint,
  createEndpoint,
  updateEndpoint,
  dataType,
  children,
  newItem = {},
  className,
  createPermission,
  updatePermission,
  deletePermission,
  ...otherProps
}) => {
  const gridState = useGridState();
  const [dataState, setDataState] = useState<State>(
    otherProps.pageable ? { skip: 0, take: otherProps.pageSize } : { skip: 0 }
  );
  const [result, setResult] = useState<DataResult>();
  const [allData, setAllData] = useState<InlineGridItem[]>([]);

  const API = useApiWorker();
  const [isSaving, setIsSaving] = useState(false);
  const toast = useToasts();
  const [fetchedData, fetchStatus, { getData }] =
    useSimpleDataFetcher<GridItemList>(readEndpoint, new GridItemList(), false);

  const editField = 'inEdit';
  const entityName = dataType || gridState?.entityName || 'Item';

  useEffect(() => {
    getData();
  }, [gridState.refreshValue]);

  useEffect(() => {
    var initData =
      fetchedData?.data?.map((item) => {
        return { ...item, inEdit: false };
      }) || [];
    setResult(process(initData, dataState));
    setAllData(initData);
  }, [fetchedData]);

  const addNew = () => {
    const fullDataState = { ...dataState, skip: 0 };
    const gridData = [...allData];
    gridData.unshift({ ...newItem, inEdit: true, isActive: true });
    setAllData(gridData);
    setDataState(fullDataState);
    setResult(process(gridData, fullDataState));
  };

  const refreshingCall = async (
    url: string,
    dataItem: InlineGridItem,
    type: GridItemChangeType
  ) => {
    try {
      var response;
      setIsSaving(true);
      switch (type) {
        case GridItemChangeType.Delete:
          var deleteUrl = buildEndpoint(url, dataItem.id);
          response = await API.delete(deleteUrl);
          if (response?.data === false)
            toast.error(`Unable to delete ${entityName}`);
          break;
        case GridItemChangeType.Save:
          response = await API.post(url, dataItem);
          break;
        case GridItemChangeType.Update:
          response = await API.put(url, dataItem);
          break;
      }

      response?.data &&
        response.data != false &&
        toast.success(`${entityName} Successfully ${type}`);
      setIsSaving(false);

      if (gridState !== undefined) {
        gridState.refreshGrid();
      } else {
        getData();
      }
    } catch (error) {
      if (error.response?.data?.errors) {
        var arr = Object.values(error.response.data.errors);
        arr.map((item: any) => toast.error(item[0]));
      }
      setIsSaving(false);
    }
  };

  const itemChange = (e: any) => {
    var gridData = [...allData];
    if (e.field === editField) {
      switch (e.value) {
        case GridItemChangeType.Delete:
          gridData = gridData.filter((d) => d !== e.dataItem);
          refreshingCall(deleteEndpoint, e.dataItem, e.value);
          break;
        case GridItemChangeType.Save:
          refreshingCall(createEndpoint, e.dataItem, e.value);
          break;
        case GridItemChangeType.Update:
          refreshingCall(updateEndpoint, e.dataItem, e.value);
          break;
        case GridItemChangeType.Cancel:
          getData();
          break;
        default:
          e.dataItem[e.field] = true;
      }
    } else {
      e.dataItem[e.field] = e.value;
    }
    setResult(process(gridData, dataState));
  };

  const handleInlineDataStateChange = ({ data }: any) => {
    setDataState(data);
    setResult(process(allData, data));
  };

  return (
    <Fragment>
      <SecureComponent
        permissions={createPermission !== undefined ? [createPermission] : []}
      >
        <GridButtonSection>
          <AddButton onClick={addNew}>New {entityName}</AddButton>
        </GridButtonSection>
      </SecureComponent>
      <div>
        <LoadingMaskSection
          isLoading={fetchStatus === AsyncStatus.Pending}
          noLabel
        >
          {result && result.total > 0 ? (
            <Grid
              scrollable="none"
              {...otherProps}
              {...result}
              {...dataState}
              onDataStateChange={(e: any) => handleInlineDataStateChange(e)}
              onItemChange={itemChange}
              editField={editField}
              className={fullClassName(className, 'main-grid inline-grid')}
            >
              {children}
              <Column
                groupable={false}
                sortable={false}
                filterable={false}
                resizable={false}
                field={editField}
                title=" "
                width="180px"
                cell={(props: GridCellProps) => (
                  <CommandCell
                    isLoading={isSaving}
                    updatePermission={updatePermission}
                    deletePermission={deletePermission}
                    {...props}
                  />
                )}
              />
            </Grid>
          ) : (
            <NoRecordsAlert />
          )}
        </LoadingMaskSection>
      </div>
    </Fragment>
  );
};

export const GridButtonSection: React.FC = ({ children }) => {
  return <div className="flex-end mb-2 mt-3 align-items-center">{children}</div>;
};
