import { msalConfig } from './authConfig';
import React from 'react';
import ReactDOM from 'react-dom';
import { App } from './App';
import { BrowserRouter } from 'react-router-dom';
import { ScrollToTop } from './Components/Display';
import { ToastProvider, GlobalProvider, EnumerationProvider } from 'Context';
import { MsalProvider } from '@azure/msal-react';
import {
  PublicClientApplication,
  EventType,
  EventMessage,
  AuthenticationResult,
} from '@azure/msal-browser';
declare global {
  interface Window {
    CLIENT_ID: string;
    TENANT: string;
    REDIRECT_URI: string;
    API_ENDPOINT: string;
    VERSION_NUMBER: string;
    BASE_URL: string;
    ENVIRONMENT_NAME: string;
    API_SWAGGER_LINK: string;
    SIGNIN_POLICY: string;
    INSTANCE: string;
  }
}

export const msalInstance = new PublicClientApplication(msalConfig);

// Account selection logic is app dependent. Adjust as needed for different use cases.
const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback((event: EventMessage) => {
  if (
    event.eventType === EventType.LOGIN_SUCCESS &&
    event.payload &&
    msalInstance.getActiveAccount() == null
  ) {
    const payload = event.payload as AuthenticationResult;
    const account = payload.account;
    msalInstance.setActiveAccount(account);
  }
});

ReactDOM.render(
  <MsalProvider instance={msalInstance}>
    <BrowserRouter>
      <ToastProvider>
        <GlobalProvider>
          <EnumerationProvider>
            <ScrollToTop />
            <App />
          </EnumerationProvider>
        </GlobalProvider>
      </ToastProvider>
    </BrowserRouter>
  </MsalProvider>,
  document.getElementById('root')
);
