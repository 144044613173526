import '../Navigation/NavigationPillBar.scss';
import React from 'react';
import { Render } from 'Components/Display';
import { useHasPermissions } from 'Utilities';
import { Permission } from 'Models/Templates/Permission/Permission';

type SecurityProps = {
  permissions?: Permission[]
}

export const SecureComponent: React.FC<SecurityProps> = ({ permissions, children }) => {
  const hasPermissions = useHasPermissions();
  
  return (
    <Render condition={hasPermissions(permissions)}>
      {children}
    </Render>
  )
}