import { Guideline } from 'Models';
import { Appointment } from './AppointmentModels';
import { FileModel } from './FileModels';

export class AppointmentReportModel {
  constructor(id: string, referralDate?: Date) {
    this.appointmentId = id;
    this.templateFile = new FileModel();
    this.reportFile = new FileModel();
    this.referralDate = referralDate;
  }
  //#region Files
  templateFile?: FileModel;
  reportFile?: FileModel;
  //#endregion

  //#region Primary Operations
  appointmentId?: string;
  appointment?: Appointment;
  title?: string;
  pageCount?: number;
  rush?: string;
  firstLevelReviewStatus?: string;
  typeOfEvaluation?: string;
  appointmentReportDurData?: AppointmentReportDurData[];
  numberOfPrescribers?: number;
  callAttempts?: number;
  numberOfPrescribersPerformed?: number;
  numberOfDrugsForReview?: number;
  numberOdfrugsAgreedUpon?: number;
  numberOfDrugsWithNoContact?: number;
  numberOfDrugsAlreadyDiscontinued?: number;
  previousIrRatingUnit?: string;
  previousIrRater?: number;
  initialIrExamType?: string;
  reviewerIrRatingUnit?: string;
  reviewerIrDeterminationValue?: number;
  guidelinesToUse?: string;
  contact?: string;
  referralDate?: Date;
  dueDate?: Date;
  status?: string;
  sendTo?: string;
  sendToSecond?: string;
  comments?: string;
  guidelineId?: string;
  guideline?: Guideline;
  guidelineModel?: Guideline;
  //#endregion

  //#region Medical Director QA
  lastQaDateDirector?: Date;
  qaStatus?: string;
  qaStatusDirector?: string;
  reportAssignedToQa?: string;
  reportAssignedToDirector?: string;
  //#endregion

  //#region Quickbooks / API Operations
  downloadedViaApi?: boolean;
  availableViaApi?: boolean;
  //#endregion
}

export class AppointmentReportDurData {
  id?: string;
  drugListed?: string;
  drugStatus?: string;
  followUpTimeFrame?: string;
}

export enum AppointmentReportModal {
  AttachmentsTemplate = 'attachmentsTemplate',
  AttachmentsReport = 'attachmentsReport',
  Notes = 'notesReport',
  Guidelines = 'guidelines',
}

export enum ReportTypeField {
  TemplateField = 'templateFile',
  ReportField = 'reportFile',
}
