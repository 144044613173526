import { AppointmentDocumentsReports } from 'Features/Appointments';
import {
  ReferralRawDocumentList,
  ReferralNotes,
  ViewReferral,
  ReferralInternalNotes,
} from 'Features/Referrals';
import { ViewReferralsCommercial } from 'Features/Referrals/ViewReferralsCommercial';
import { Permission } from 'Models/Templates/Permission/Permission';
import { AdminSubRouteConfig, SubRoutes } from 'Navigation/NavigationTypes';

export enum ReferralCommercialSubRoute {
  View = '/View',
  Appointments = '/Appointments',
  Documents = '/Documents',
  Reports = '/Reports',
  Notes = '/Notes',
  InternalNotes = '/InternalNotes'
}

export const ReferralCommercialSubRoutes: SubRoutes = {
  [ReferralCommercialSubRoute.View]: new AdminSubRouteConfig(
    ReferralCommercialSubRoute.View,
    'View',
    ViewReferralsCommercial,
    true,
    [Permission.ViewReferral]
  ),
  [ReferralCommercialSubRoute.Reports]: new AdminSubRouteConfig(
    ReferralCommercialSubRoute.Reports,
    'Documents & Reports',
    AppointmentDocumentsReports,
    false,
    [Permission.ViewAppointmentDocument, Permission.ViewAppointmentReport]
  ),
  [ReferralCommercialSubRoute.Documents]: new AdminSubRouteConfig(
    ReferralCommercialSubRoute.Documents,
    'Raw Documents',
    ReferralRawDocumentList,
    false,
    [Permission.ViewReferralRawDocument]
  ),
  [ReferralCommercialSubRoute.Notes]: new AdminSubRouteConfig(
    ReferralCommercialSubRoute.Notes,
    'Call Notes',
    ReferralNotes,
    false,
    [Permission.ViewNote]
  ),
  [ReferralCommercialSubRoute.InternalNotes]: new AdminSubRouteConfig(
    ReferralCommercialSubRoute.InternalNotes,
    'Internal Notes',
    ReferralInternalNotes,
    false,
    [Permission.ViewInternalNote]
  ),
};
