import {
  Configuration,
  PopupRequest,
  RedirectRequest,
} from '@azure/msal-browser';

const development = process.env.NODE_ENV === 'development';
const tenant = development ? 'stradixb2c.onmicrosoft.com' : window.TENANT;
const signinPolicy = development ? 'b2c_1_login2' : window.SIGNIN_POLICY;
const instance = development
  ? 'https://stradixb2c.b2clogin.com'
  : window.INSTANCE;

const signInAuthority = `${instance}/${tenant}/${signinPolicy}`;

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
  auth: {
    authority: signInAuthority,
    clientId: development
      ? `${process.env.REACT_APP_CLIENT_ID}`
      : `${window.CLIENT_ID}`,
    knownAuthorities: ['stradixb2c.b2clogin.com', 'stradixprod.b2clogin.com', 'devstradixb2c.b2clogin.com'],
    redirectUri: development
      ? `${process.env.REACT_APP_REDIRECT_URI}`
      : `${window.REDIRECT_URI}`,
    postLogoutRedirectUri: development
      ? `${process.env.REACT_APP_REDIRECT_URI}`
      : `${window.REDIRECT_URI}`,
  },
  system: {
    tokenRenewalOffsetSeconds: 300,
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

export const loginRequest: RedirectRequest = {
  scopes: [
    development
      ? `${process.env.REACT_APP_CLIENT_ID}`
      : `${window.CLIENT_ID}`,
  ],
};
