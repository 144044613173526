import React from 'react';
import { GridCellProps } from '@progress/kendo-react-grid';
import { FileModel } from 'Models';
import { useToasts } from 'Context';
import {
  buildEndpoint,
  buildQueryUrl,
  fullClassName,
  useApiWorker,
} from 'Utilities';
import download from 'downloadjs';
import { ReportTypeField } from 'Models/AppointmentReportModel';

export type FileDownloadCellProps = {
  fileControllerEndpoint: string;
  optionalParams?: any;
  id?: string;
  filename?: string;
  display?: any;
  endpointActionName?: string;
  className?: string;
};

export const FileDownloadCell: React.FC<
  GridCellProps & FileDownloadCellProps
> = ({ dataItem, id, filename, ...downloadFileTextProps }) => {
  const rowModel = dataItem as FileModel;
  const rowModelTruthy = !!rowModel;

  const fileName = rowModelTruthy ? rowModel.fileName : filename || '';
  const entityId = rowModelTruthy ? rowModel.id : id || '';

  return (
    <td className="white-space-normal">
      <DownloadFileText
        id={entityId}
        filename={fileName}
        {...downloadFileTextProps}
      />
    </td>
  );
};

//Component to handle the download files depending the ReportTypeField
export const ReportsFilesDownloadCell: React.FC<
  GridCellProps & FileDownloadCellProps
> = ({ dataItem, id, filename, ...downloadFileTextProps }) => {
  const rowModel =
    downloadFileTextProps.field == ReportTypeField.TemplateField
      ? (dataItem.templateFile as FileModel)
      : (dataItem.reportFile as FileModel);

  const rowModelTruthy = !!rowModel;

  const fileName = rowModelTruthy ? rowModel.fileName : filename || '';
  const entityId = rowModelTruthy ? rowModel.id : id || '';

  return (
    <td className="white-space-normal">
      {entityId != null && (
        <DownloadFileText
          id={entityId}
          filename={fileName}
          {...downloadFileTextProps}
        />
      )}
      {entityId == null && <h4></h4>}
    </td>
  );
};

export const DownloadFileText: React.FC<FileDownloadCellProps> = ({
  id,
  optionalParams,
  fileControllerEndpoint,
  endpointActionName = 'download',
  filename,
  display,
  className,
}) => {
  const toast = useToasts();
  const API = useApiWorker();
  const downloadEndpoint = buildEndpoint(
    fileControllerEndpoint,
    endpointActionName
  );

  const downloadOrOpenFile = async () => {
    try {
      const { data, headers } = await API.get(
        buildQueryUrl(downloadEndpoint, { id, ...optionalParams }),
        {
          responseType: 'blob',
        }
      );

      const mimeType = headers['content-type'];

      if (mimeType === 'application/pdf') {
        var fileURL = URL.createObjectURL(data);
        window.open(fileURL, filename);
      } else {
        download(data, filename, mimeType);
      }
    } catch (err) {
      toast.error(err ? err.ErrorMessage : 'Error: Could not download file');
      console.log(err);
    }
  };

  return (
    <span
      className={fullClassName(className, 'cursor-pointer color-primary')}
      onClick={downloadOrOpenFile}
    >
      {display || filename}
    </span>
  );
};
