import { GridCellProps } from '@progress/kendo-react-grid';
import { ActionsEllipsisCell } from 'Components/Grid';
import { useGridState, useModalFormState, useToasts } from 'Context';
import { ActionsDropdownConfig } from 'Models';
import {
  ReviewerLicenseDocumentModal,
  ReviewerLicenseDocumentModel,
} from 'Models/ReviewerLicenseDocumentModel';
import { Permission } from 'Models/Templates/Permission/Permission';
import { ENDPOINTS } from 'Models/Templates/Routes/Routes';
import React from 'react';
import { buildEndpoint, useApiWorker } from 'Utilities';
import { ReviewerLicenseDocumentDrawerForm } from '.';

export const ReviewerLicenseDocumentsActionCell: React.FC<GridCellProps> = ({
  dataItem,
  field = '',
}) => {
  const API = useApiWorker();
  const toast = useToasts();
  const { refreshGrid } = useGridState();
  const { toggle, setEntityId } = useModalFormState();

  const value = dataItem[field];

  const editItem = () => {
    setEntityId(value);
    toggle();
  };

  const deleteItem = async () => {
    if (window.confirm('Are you sure you want to delete this document?')) {
      try {
        var deleteResponse = await API.delete(
          buildEndpoint(ENDPOINTS.reviewerLicenseDocuments.base, value)
        );
        if (deleteResponse.data.isSuccessStatusCode) {
          toast.success('Document has been removed.');
        } else if (deleteResponse.headers['exception'] === undefined) {
          toast.error('Fail removing document.');
        } else {
          toast.error(deleteResponse.headers['exception']);
        }
        refreshGrid && refreshGrid();
      } catch {
        toast.error('Fail removing document.');
      }
    }
  };

  const actionsConfig: ActionsDropdownConfig = {
    items: [
      {
        name: 'Edit',
        permissions: [Permission.EditReviewerLicenseDocument],
        modalConfig: {
          modalId: ReviewerLicenseDocumentModal.LicenseDocuments,
          entityId: value,
          modalComponent: (
            <ReviewerLicenseDocumentDrawerForm
              formModel={new ReviewerLicenseDocumentModel(value)}
              endpoint={ENDPOINTS.reviewerLicenseDocuments.base}
            />
          ),
        },
      },
      {
        name: 'Delete',
        onClick: deleteItem,
        permissions: [Permission.DeleteReviewerLicenseDocument],
      },
    ],
  };

  return (
    <ActionsEllipsisCell actionsConfig={actionsConfig} dataItem={dataItem} />
  );
};
