import React from "react";
import { GridCellProps } from "@progress/kendo-react-grid";
import { InputType } from "Models/FormModels";
import { NamedCoreEnum } from "Models/SharedModels";
import { GridInput } from "./GridInput";

type EnumerationCellProps = {
  coreEnum: NamedCoreEnum;
};

export const EnumerationCell: React.FC<
  EnumerationCellProps & GridCellProps
> = ({ dataItem, field, onChange, coreEnum }) => {
  const textField = "displayName";
  const dataItemKey = "value";

  const value = dataItem[field as string] || {displayName: undefined, value: undefined};
  return (
    <td>
      {dataItem.inEdit ? (
        <GridInput
          dataItem={dataItem}
          name={field as string}
          type={InputType.DropDown}
          onChange={onChange}
          value={value}
          data={Object.values(coreEnum)}
          dataItemKey={dataItemKey}
          textField={textField}
          noPaddingMargin
        />
      ) : (
        value[textField]
      )}
    </td>
  );
};
