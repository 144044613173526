import { AdminSubHeaderMainLayout } from 'Components/Display';
import { useEntity } from 'Context';
import { ROUTES } from 'Navigation';
import React, { Fragment } from 'react';
import { ViewGuidelineBreadCrumb } from '.';

export const ViewGuidelineMain: React.FC = () => {
  const entity = useEntity();
  return (
    <Fragment>
      <ViewGuidelineBreadCrumb />
      <AdminSubHeaderMainLayout
        subRoute={ROUTES.GUIDELINE}
        title={entity.title + ' - Guideline'}
      />
    </Fragment>
  );
};
