import { CancelBackButton, FormSubmitButton } from 'Components/Form';
import { SubHeaderLayout } from 'Components/SubLayouts';
import { FormProvider, FormProviderProps } from 'Context';
import { SUBROUTES_MAP, useSubRoutes } from 'Navigation';
import React, { HTMLProps } from 'react';
import { Col, Row } from 'reactstrap';
import { fullClassName, toggledClass } from 'Utilities';
import './LayoutComponents.scss';
import {
  EditSecondaryHeader,
  SecondaryHeader,
  TaskHeader,
  TaskHeaderProps,
} from './PageHeaders';

export const RowCol: React.FC<any> = ({ children, ...otherProps }) => {
  return (
    <Row>
      <Col {...otherProps}>{children}</Col>
    </Row>
  );
};

export const FormColumn: React.FC<{ hideSeparator?: boolean } & any> = ({
  hideSeparator,
  children,
  className,
  ...otherProps
}) => {
  return (
    <Col
      className={fullClassName(
        'form-column',
        toggledClass('right-separator', !hideSeparator),
        className
      )}
      {...otherProps}
    >
      {children}
    </Col>
  );
};

export const ButtonRow: React.FC<any> = ({
  className,
  children,
  ...otherProps
}) => {
  return (
    <div className={fullClassName('flex-end mt-4', className)} {...otherProps}>
      {children}
    </div>
  );
};

export const ModalDrawerButtonRow: React.FC<any> = ({
  className,
  children,
  ...otherProps
}) => {
  return (
    <div className={fullClassName('modal-drawer-button-row', className)}>
      {children}
    </div>
  );
};

export type FixedActionsBarProps = HTMLProps<HTMLElement> & {
  centered?: boolean;
};

export const FixedActionsBar: React.FC<FixedActionsBarProps> = ({
  className,
  centered = true,
  children,
}) => {
  return (
    <div
      className={fullClassName(
        'fixed-actions-bar',
        toggledClass('flex-center', centered),
        className
      )}
    >
      {children}
    </div>
  );
};

export type FixedFormSubmitOrCancelBarProps = HTMLProps<HTMLElement> & {
  submitLabel?: string;
  cancelLabel?: string;
};

export const FixedFormSubmitOrCancelBar: React.FC<
  FixedFormSubmitOrCancelBarProps
> = ({
  cancelLabel = 'Cancel',
  submitLabel = 'Save',
  children,
  ...otherProps
}) => {
  return (
    <FixedActionsBar {...otherProps}>
      <CancelBackButton>{cancelLabel}</CancelBackButton>
      <FormSubmitButton>{submitLabel}</FormSubmitButton>
    </FixedActionsBar>
  );
};

export const MainArea: React.FC<HTMLProps<HTMLDivElement>> = ({
  className,
  children,
}) => {
  return (
    <div className={fullClassName('main-area-padding main-area', className)}>
      {children}
    </div>
  );
};

export type TabbedHeaderLayoutProps = {
  subRoute?: string;
  header: any;
};

export const TabbedHeaderLayout: React.FC<TabbedHeaderLayoutProps> = ({
  subRoute = '',
  children,
  header,
}) => {
  const mapSubRoutes = useSubRoutes();
  const withNoSubroutes = subRoute.length === 0;

  return (
    <div
      className={fullClassName(
        'admin-sub-header-layout',
        toggledClass('with-no-subroutes', withNoSubroutes)
      )}
    >
      {React.cloneElement(header as React.ReactElement<any>, {
        className: 'admin-header',
      })}
      <MainArea className="admin-main-content">
        {withNoSubroutes ? children : mapSubRoutes(SUBROUTES_MAP[subRoute])}
      </MainArea>
    </div>
  );
};

export type AdminSubHeaderMainLayoutProps = {
  title: string;
  subRoute?: string;
  route?: string;
};

export type AdminSubHeaderMainEditLayoutProps =
  AdminSubHeaderMainLayoutProps & {
    editPermissions?: number[];
  };

export const AdminSubHeaderMainLayout: React.FC<
  AdminSubHeaderMainLayoutProps
> = ({ title, subRoute = '', children }) => {
  return (
    <TabbedHeaderLayout
      subRoute={subRoute}
      header={<SecondaryHeader title={title} parentRoute={subRoute} />}
    >
      {children}
    </TabbedHeaderLayout>
  );
};

export const AdminSubHeaderMainEditLayout: React.FC<
  AdminSubHeaderMainEditLayoutProps
> = ({ title, subRoute = '', children, route, editPermissions }) => {
  return (
    <TabbedHeaderLayout
      subRoute={subRoute}
      header={
        <EditSecondaryHeader
          title={title}
          parentRoute={subRoute}
          route={route}
          editPermissions={editPermissions}
        />
      }
    >
      {children}
    </TabbedHeaderLayout>
  );
};

export type DefendantTabbedHeaderLayoutProps = {
  subRoute?: string;
};

export type TaskPageHeaderLayoutProps = Partial<TaskHeaderProps> & {
  title: string;
  saveButtonText?: string;
  formProviderProps: FormProviderProps;
};

export type ViewPageHeaderLayoutProps = Partial<TaskHeaderProps> & {
  title: string;
};

export const TaskPageHeaderLayout: React.FC<TaskPageHeaderLayoutProps> = ({
  children,
  formProviderProps,
  ...headerProps
}) => {
  const layoutHeaderProps = { hasShortHeader: true, ...headerProps };

  return (
    <FormProvider {...formProviderProps}>
      <SubHeaderLayout
        headerComponent={TaskHeader}
        headerProps={layoutHeaderProps}
      >
        <MainArea>{children}</MainArea>
      </SubHeaderLayout>
    </FormProvider>
  );
};

export const InfoBox: React.FC<HTMLProps<HTMLDivElement>> = ({
  className,
  children,
}) => {
  return (
    <div
      className={fullClassName('info-box p-3 mb-3 d-inline-block', className)}
    >
      {children}
    </div>
  );
};

export const FlexWrapper: React.FC<HTMLProps<HTMLDivElement>> = ({
  children,
  className,
  ...props
}) => {
  return (
    <div
      className={fullClassName('flex-normal flex-wrap', className)}
      {...props}
    >
      {children}
    </div>
  );
};

export type HideableWrapperProps = {
  isHidden?: boolean;
};

export const HideableWrapper: React.FC<HideableWrapperProps> = ({
  isHidden,
  children,
}) => {
  return (
    <div
      className={toggledClass(
        'hide-offscreen animate-fade-in-from-none',
        isHidden
      )}
    >
      {children}
    </div>
  );
};
