import {
  AsyncLoadingSection,
  TaskPageHeaderLayout,
} from 'Components/Display';
import { Fade, Tab, Tabs } from 'react-bootstrap';
import {
  PageCard,
  PageCardRow,
  PageCardSection,
} from 'Components/Display/PageCard';
import { SmartCheckboxList, SmartInputBlock, SmartMultiSectionList } from 'Components/Form';
import { ColSize } from 'Models';
import { InputType } from 'Models/FormModels';
import { ENDPOINTS } from 'Models/Templates/Routes/Routes';
import { ADD_EDIT } from 'Navigation';
import React, { useState } from 'react';
import { Row } from 'reactstrap';
import {
  addEditTitle,
  buildEndpoint,
  defaultDataTransform,
  useAddEditSuccessHandler,
  useDataFetcher,
  useIdParam,
} from 'Utilities';
import { LineOfBusiness } from 'Models/LineOfBusinessModel';

export const AddEditLineOfBusiness: React.FC = () => {
  const entityName = 'LineOfBusiness';
  const [id, isAdd] = useIdParam();
  const handleSuccess = useAddEditSuccessHandler(entityName, isAdd);
  const [data, loadingStatus] = useDataFetcher<LineOfBusiness>(
    buildEndpoint(ENDPOINTS.lineOfBusiness.base, id, ADD_EDIT),
    new LineOfBusiness(),
    defaultDataTransform,
    LineOfBusiness
  );

  return (
    <AsyncLoadingSection loadingStatus={loadingStatus} useMask>
      <TaskPageHeaderLayout
        title={addEditTitle(entityName, isAdd)}
        formProviderProps={{
          formModel: data,
          endpoint: ENDPOINTS.lineOfBusiness.base,
          handleSuccess: handleSuccess,
        }}
      >
        <LineOfBusinessAddEditFormContent />
      </TaskPageHeaderLayout>
    </AsyncLoadingSection>
  );
};

const LineOfBusinessAddEditFormContent: React.FC = ({ ...otherProps }) => {
  const [key, setKey] = useState('primary');
  return (
    <PageCard>
      <PageCardRow>
        <PageCardSection>
          <Row>
            <SmartInputBlock
              name="name"
              label="Name"
              type={InputType.Text}
              colProps={{ xs: ColSize.TwoThirds }}
              required
            />
          </Row>
          <Tabs
            transition={Fade}
            id="controlled-tab-example"
            activeKey={key}
            onSelect={(k) => setKey(k as string)}
            className="mb-3"
          >
            <Tab eventKey="primary" title="Clients">
              <SmartCheckboxList
                name="clients"
                labelField="name"
                checkedField="isSelected"
                groupField="groupName"
                hideCheckAll
              />
            </Tab>
            <Tab eventKey="services" title="Services">
              <SmartMultiSectionList
                listName="services"
                listObjectName="Service"
                initialValues={[]}
              >
                <TatSection label='Service' />
              </SmartMultiSectionList>
            </Tab>
            <Tab eventKey="tats" title="Turn Around Times (TATs)">
              <SmartMultiSectionList
                listName="tats"
                listObjectName="TAT"
                initialValues={[]}
              >
                <TatSection label='TAT'/>
              </SmartMultiSectionList>
            </Tab>
          </Tabs>
        </PageCardSection>
      </PageCardRow>
      <SmartInputBlock name="id" type={InputType.Text} hidden />
    </PageCard>
  );
};

type ListSectionProps = {
  formatInputName?: (name: string) => string;
  index?: number;
  label: string;
};

const defaultFormatName = (name: string) => name;

const TatSection: React.FC<ListSectionProps> = ({
  formatInputName = defaultFormatName,
  label
}) => {
  const textName = formatInputName('value');

  return (
    <SmartInputBlock
      type={InputType.Text}
      name={textName}
      label={label}
      required
      width={200}
    />
  );
};