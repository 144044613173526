import React from 'react';
import faker from 'faker';
import querystring from 'query-string';
import { Position, ColorTheme } from 'Models/SharedModels';
import { InputType } from 'Models';
import { formatDate, parseDate } from '@telerik/kendo-intl';
import { EMPTY_GUID } from './Constants';
import { isDate, isEmpty } from 'lodash';
import { getFieldValue } from 'Context';

export function toggledClass(name: string, condition?: boolean) {
  return condition ? ' ' + name.trim() : '';
}

export function randomEnumElement(obj: any) {
  return faker.random.arrayElement(Object.keys(obj).map((key) => obj[key]));
}

export function setArrayTypes<T>(type: new () => T, array: any[]): T[] {
  var typedArray: T[] = [];
  typedArray = array.map((obj: any) => {
    var typedObj = new type();
    Object.assign(typedObj, obj);
    return typedObj;
  });
  return typedArray;
}

export function asYesNo(val?: boolean) {
  return val !== undefined && val !== null ? (val ? 'Yes' : 'No') : '—';
}

export function asActiveInactive(val?: boolean) {
  return val !== undefined && val !== null
    ? val
      ? 'Active'
      : 'Inactive'
    : '—';
}

export function asTrueFalse(val?: boolean) {
  return val !== undefined && val !== null ? (val ? 'True' : 'False') : '—';
}

export function asCheckedEmpty(val?: boolean) {
  return val !== undefined && val !== null ? (val ? '✓' : '') : '—';
}

export function asEnabledDisabled(val?: boolean) {
  return val !== undefined && val !== null
    ? val
      ? 'Enabled'
      : 'Disabled'
    : '—';
}

export function isUndefined(val?: any) {
  return val === undefined;
}

export function isNullOrUndefined(val?: any) {
  return isUndefined(val) || val === null;
}

export function isNullEmptyOrUndefined(val?: any): boolean {
  return (
    isNullOrUndefined(val) ||
    ((isArray(val) || typeof val === 'string') && val.length === 0) ||
    (!isDate(val) && isObject(val) && isEmpty(val)) ||
    (!isDate(val) &&
      isObject(val) &&
      Object.values(val).every((objVal) => isNullEmptyOrUndefined(objVal)))
  );
}

export function isNullUndefinedOrEmptyGuid(val?: any) {
  return isNullOrUndefined(val) || isEmptyGuid(val);
}

export function isEmptyGuid(value?: string) {
  return value === EMPTY_GUID;
}

export function buildRoute(...routes: (string | undefined)[]) {
  var fullRoute =
    '/' +
    routes
      .filter((r) => r && r.length > 0)
      .map((r) => {
        var noSlashes: string = r as string;
        if (noSlashes[0] === '/') {
          noSlashes = noSlashes?.substring(1);
        }
        if (noSlashes[noSlashes.length - 1] === '/') {
          noSlashes = noSlashes.substring(0, noSlashes.length - 1);
        }
        return noSlashes;
      })
      .join('/');
  return fullRoute;
}

export function buildEndpoint(...routes: (string | undefined)[]) {
  return buildRoute(...routes).substring(1);
}

export function buildNestedInputName(modelName: string, fieldName: string) {
  return modelName + '.' + fieldName;
}

export function buildQueryUrl<T = any>(baseUrl: string, queryparams: T) {
  var fullRoute = baseUrl;
  var useQuestion = !baseUrl.includes('?');
  for (let [key, value] of Object.entries(queryparams)) {
    if (key && value) {
      if (useQuestion) {
        fullRoute += '?' + key + '=' + value;
        useQuestion = false;
      } else {
        fullRoute += '&' + key + '=' + value;
      }
    }
  }

  return fullRoute;
}

export function getPositionClass(position?: Position) {
  switch (position) {
    case Position.Center:
      return 'flex-center';
    case Position.Left:
      return 'flex-vertical-center justify-content-start';
    case Position.Right:
      return 'flex-vertical-center justify-content-end';
    case Position.TopCenter:
      return 'flex-horizontal-center align-items-start';
    case Position.TopLeft:
      return 'flex-normal justify-content-start align-items-start';
    case Position.TopRight:
      return 'flex-normal justify-content-end align-items-start';
    case Position.BottomCenter:
      return 'flex-horizontal-center align-items-end';
    case Position.BottomLeft:
      return 'flex-normal justify-content-start align-items-end';
    case Position.BottomRight:
      return 'flex-normal justify-content-end align-items-end';
    default:
      return 'flex-center';
  }
}

export function getColorClass(theme?: ColorTheme) {
  switch (theme) {
    case ColorTheme.Dark:
      return 'color-light';
    case ColorTheme.Light:
      return 'color-primary-dark';
    default:
      return '';
  }
}

export function fullClassName(...classNames: (string | undefined)[]) {
  var filteredClasses = classNames.filter((c) => !isNullEmptyOrUndefined(c));
  return filteredClasses.length > 0
    ? filteredClasses.reduce((prev, curr) => prev + ' ' + (curr?.trim() || ''))
    : '';
}

export function getPropertyByString(obj: any, pathString: string) {
  pathString = pathString.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
  pathString = pathString.replace(/^\./, ''); // strip a leading dot
  var a = pathString.split('.');
  for (var i = 0, n = a.length; i < n; ++i) {
    var k = a[i];
    if (isObject(obj) && k in obj) {
      obj = obj[k];
    } else {
      return;
    }
  }
  return obj;
}

export function setPropertyByString(
  obj: any,
  pathString: string,
  newValue: any
) {
  pathString = pathString.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
  pathString = pathString.replace(/^\./, ''); // strip a leading dot
  var a = pathString.split('.');
  for (var i = 0, n = a.length; i < n; ++i) {
    var k = a[i];

    if (i === n - 1) {
      obj[k] = newValue;
      return;
    }

    if (isObject(obj) && k in obj) {
      if (obj[k] !== undefined && obj[k] !== null) {
        obj = obj[k];
      } else {
        obj[k] = {};
        obj = obj[k];
      }
    } else {
      obj[k] = {};
      obj = obj[k];
    }
  }
  return obj;
}

function isObject(o: any) {
  return o === Object(o);
}

export function getUriQueryStringParams(object: any) {
  if (object === undefined) return '';

  var params = querystring.stringify(object, { skipNull: true, encode: true });

  if (params === '') return '';

  return '&' + params;
}

export function restrictSsnValue(value: any) {
  if (typeof value == 'string') {
    const regExp: RegExp = /[^\dx_-]/;
    return value.replace(regExp, 'x');
  }
  return null;
}

export function restrictPercentageValue(value: any) {
  var restrictedValue = value;
  if (value && value > 1) {
    restrictedValue = 1;
  }

  return restrictedValue;
}

export function isArray(value: any) {
  return Array.isArray(value);
}

export function setPropertiesToLowercase(obj: any) {
  var key,
    keys = Object.keys(obj);
  var n = keys.length;
  var newobj: any = {};
  while (n--) {
    key = keys[n];
    newobj[key.toLowerCase()] = obj[key];
  }

  return newobj;
}

export function getTargetFromEvent(e: any, type: InputType) {
  switch (type) {
    case InputType.TextArea: {
      return e?.target?.element?.current;
    }
    default:
      return e?.target;
  }
}

export function addEditTitle(entityName: string, isAdd: boolean) {
  return `${isAdd ? 'Add' : 'Edit'} ${entityName}`;
}

export const toLocalDateString = (date?: Date | null) => {
  if (date) {
    var fullDateString = date.toLocaleString("en-US");
    var dateArray = fullDateString.split(',');
    return dateArray[0];
  } else {
    return '';
  }
};

export const toStandardDateString = (date?: Date | null) => {
  if (date) {
    return new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      day: '2-digit',
      month: '2-digit',
    })
      .format(date)
      .toString();
  } else {
    return '';
  }
};

export function dateMask(value: any) {
  const chars = value.split('').filter(isStringNumeric);
  const month: Array<any> = [/[0-1]/, chars[0] === '1' ? /[0-2]/ : /[1-9]/];

  const day: Array<any> = [
    /[0-3]/,
    chars[2] === '0'
      ? /[1-9]/
      : chars[2] === '1' || chars[2] === '2'
      ? /[0-9]/
      : /[01]/,
  ];

  return month
    .concat('/')
    .concat(day)
    .concat('/')
    .concat([/\d/, /\d/, /\d/, /\d/]);
}

export function monthMask(value: any) {
  const chars = value.split('').filter(isStringNumeric);
  const month: Array<any> = [/[0-1]/, chars[0] === '1' ? /[0-2]/ : /[1-9]/];

  return month;
}

export function dayMask(value: any) {
  const chars = value.split('').filter(isStringNumeric);
  const day: Array<any> = [
    /[0-3]/,
    chars[0] === '0'
      ? /[1-9]/
      : chars[0] === '1' || chars[0] === '2'
      ? /[0-9]/
      : /[01]/,
  ];

  return day;
}

export function yearMask() {
  return [/\d/, /\d/, /\d/, /\d/];
}

export function timeMask(value: any) {
  const chars = value.split('').filter(isStringNumeric);
  const hour: Array<any> = [/[0-1]/, chars[0] === '1' ? /[0-2]/ : /[1-9]/];

  const minute: Array<any> = [/[0-5]/, /[0-9]/];

  return hour.concat(':').concat(minute);
}

export function isStringNumeric(str: string) {
  return /^\d+$/.test(str);
}

export function timeOnlyMask(value: any) {
  const chars = value.split('');
  const hours: Array<any> = [/[0-1]/, chars[0] == '1' ? /[0-2]/ : /[1-9]/];

  const minutes: Array<any> = [/[0-5]/, /[0-9]/];

  return hours
    .concat(':')
    .concat(minutes)
    .concat([' ', /P|A|p|a/, /M|m/]);
}

export function toCurrencyString(value = 0) {
  return `$${value.toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })}`;
}

export const copyToken = (token: string) => {
  var tmp = document.createElement('input');
  tmp.value = token;
  document.body.appendChild(tmp);
  tmp.select();
  document.execCommand('copy');
  document.body.removeChild(tmp);
};

export function toTimeString(value?: Date | null) {
  return !value
    ? ''
    : formatDate(value, { time: 'short' }).toLowerCase().replace(' ', '');
}

export function toTimeStringNoAmPm(value?: Date | null) {
  return !value ? '' : formatDate(value, 'hh:mm');
}

enum DateTimeFormatLengths {
  StandardDotNetDate = 19,
}

export function formatDateTimeFromDotNetFormat(
  value?: string | Date | null,
  format?: string,
  inputFormat?: string
) {
  if (value instanceof Date) value = value.toString();
  if (typeof value === 'string')
    value = value.substr(0, DateTimeFormatLengths.StandardDotNetDate);

  format = format ? format : 'yyyy-MM-dd HH-mm';
  let parsedDate = value!!
    ? parseDate(
        value as string,
        inputFormat ? inputFormat : 'yyyy-MM-ddTHH:mm:ss'
      )
    : null;
  let result = parseDate!! ? formatDate(parsedDate as Date, format) : '';
  return result;
}

export enum AmPmValue {
  AM = 'AM',
  PM = 'PM',
  Empty = '',
}

export function toAmPmValue(value?: Date | null) {
  return !value ? AmPmValue.Empty : (formatDate(value, 'a') as AmPmValue);
}

export type Without<T, U> = { [P in Exclude<keyof T, keyof U>]?: never };
export type XOR<T, U> = T | U extends object
  ? (Without<T, U> & U) | (Without<U, T> & T)
  : T | U;

export const isValidDate = (d: any) => {
  return d instanceof Date && !isNaN(d.valueOf());
};

export const emailRenderer = (email: string) => {
  return <a href={`mailto: ${email}`}>{email}</a>;
};

export function distinctArray(nonUniqueArray: any[], propertyName: string) {
  const flag: any = {};
  const uniqueArray: any[] = [];
  if (Array.isArray(nonUniqueArray)) {
    nonUniqueArray.forEach((o) => {
      if (!flag[o?.[propertyName]]) {
        flag[o?.[propertyName]] = true;
        uniqueArray.push(o);
      }
    });
  }

  return uniqueArray;
}

export function takeUniqueComparison(
  primaryArray: any[],
  comparisonArray: any[],
  propertyName: string
) {
  const uniqueArray = primaryArray.filter(
    (o) => !comparisonArray.find((c) => c?.[propertyName] === o?.[propertyName])
  );
  return uniqueArray;
}

export function withoutUndefinedProperties(obj: any) {
  Object.keys(obj).forEach((key) =>
    obj[key] === undefined ? delete obj[key] : {}
  );
  return obj;
}

export function restrictNumber(value: number | null, min: number, max: number) {
  if (isNullOrUndefined(value)) {
    return value;
  }

  if (value && value < min) {
    return min;
  }

  if (value && value > max) {
    return max;
  }

  return value;
}

export function isTextInput(type: InputType) {
  switch (type) {
    case InputType.Text:
    case InputType.TextArea:
    case InputType.RichText:
    case InputType.Currency:
    case InputType.Number:
    case InputType.MaskedText:
    case InputType.Percentage:
    case InputType.PhoneNumber:
    case InputType.SocialSecurityNumber:
    case InputType.Password:
      return true;
    default:
      return false;
  }
}

export function isSizeableInput(type: InputType) {
  if (isTextInput(type)) {
    return true;
  }

  switch (type) {
    case InputType.DatePicker:
    case InputType.TimePicker:
    case InputType.AsyncDropDown:
    case InputType.BoolDropDown:
    case InputType.MultiSelect:
    case InputType.EnumDropdown:
    case InputType.EnumMultiSelect:
      return true;
    default:
      return false;
  }
}

export function sumProperty(list: any[] = [], propertyName?: string) {
  return list.reduce(
    (a, b) => a + ((propertyName ? getFieldValue(b, propertyName) : b) || 0),
    0
  );
}

export function getNumberOfLines(
  element: React.RefObject<HTMLDivElement>,
  lineHeight = 17.5
) {
  var elementHeight = element.current?.getBoundingClientRect().height || 0;
  var numLines = elementHeight / lineHeight;
  return numLines;
}

export function getNumberOfCharactersPerLine(
  element: React.RefObject<HTMLDivElement>,
  totalLength: number,
  lineHeight?: number
) {
  return Math.floor(totalLength / getNumberOfLines(element, lineHeight)) - 2;
}

export function validateEmail(email: string) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}
