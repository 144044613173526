import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import { SvgIconProps } from '@mui/material';
import React, { HTMLProps } from 'react';

export class DashboardTileProps {
  value?: number;
  text?: string;
  detialsPath?: string;
  icon?: any;
  iconSize?: 'inherit' | 'default' | 'small' | 'large';
  iconWrapperProps?: HTMLProps<HTMLSpanElement>;
  iconProps?: SvgIconProps;
}

export const DashboardTile: React.FC<DashboardTileProps> = ({
  value,
  text,
  detialsPath,
  icon = null,
  iconSize,
  iconProps,
}) => {
  const Icon = icon;
  iconProps = { className: 'custom-icon', ...iconProps };

  return (
    <div className="k-tile container">
      <div className="top-wrapper">
        <Icon size={iconSize} {...iconProps} />
        <div className="value-wrapper">
          <div className="value-font">{value}</div>
          <span>{text}</span>
        </div>
      </div>
      <div className="bottom-wrapper">
        <div className="link-wrapper">
          <a href={detialsPath}>
            View Details
            <ArrowCircleRightOutlinedIcon />
          </a>
        </div>
      </div>
    </div>
  );
};
