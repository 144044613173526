import { makeStyles } from '@mui/styles';
import { PageCard, PageCardRow, PageCardSection } from 'Components/Display';
import { useEntity } from 'Context';
import { ClaimantEmployerModel } from 'Models/ClaimantModels';
import React, { Fragment } from 'react';
import { Col, Row } from 'reactstrap';

export const EmployerInfo: React.FC = () => {
  const entity = useEntity();
  const employers = entity?.employers as ClaimantEmployerModel[];
  const useStyles = makeStyles({
    root: {
      minWidth: 275,
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
  });
  const classes = useStyles();
  if (employers?.length < 1) {
    return <b>No Employers to display</b>;
  }
  return (
    <Fragment>
      {employers?.map(function (model) {
        const addressLine1 = model.employer?.addressLine1 ?? '';
        const addressLine2 = model.employer?.addressLine2 ?? '';
        const employerName = model.employer?.name;
        const jobDescription = model.employer?.jobDescription;
        const city = model.employer?.city;
        const phone = model.employer?.phone;
        const state = model.employer?.state?.displayName;
        const zipCode = model.employer?.zip;
        return (
          <PageCard className={classes.root} key={model.id}>
            <PageCardRow>
              <PageCardSection title="Employer Info">
                <Row>
                  <Col xs="12" lg="4">
                    <span className="field-label data-display">Employer:</span>
                    <span className="field-value">{employerName}</span>
                  </Col>
                  <Col xs="12" lg="4">
                    <span className="field-label data-display">
                      Job Description:
                    </span>
                    <span className="field-value">{jobDescription}</span>
                  </Col>
                  <Col xs="12" lg="4">
                    <span className="field-label data-display">Phone:</span>
                    <span className="field-value">{phone}</span>
                  </Col>
                </Row>
                <Row>
                  <Col xs="12" lg="4">
                    <span className="field-label data-display">Address:</span>
                    <span className="field-value">
                      {`${addressLine1} ${addressLine2}`}
                    </span>
                  </Col>
                  <Col xs="12" lg="4">
                    <span className="field-label data-display">City:</span>
                    <span className="field-value">{city}</span>
                  </Col>
                  <Col xs="12" lg="4">
                    <span className="field-label data-display">State:</span>
                    <span className="field-value">{state}</span>
                  </Col>
                </Row>
                <Row>
                  <Col xs="12" lg="4">
                    <span className="field-label data-display">Zip Code:</span>
                    <span className="field-value">{zipCode}</span>
                  </Col>
                </Row>
              </PageCardSection>
            </PageCardRow>
          </PageCard>
        );
      })}
    </Fragment>
  );
};
