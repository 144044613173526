import { 
    GridCellProps 
} from "@progress/kendo-react-grid";
import { 
    ActionsEllipsisCell 
} from "Components/Grid";
import { 
    useGridState, 
    useModalFormState, 
    useToasts 
} from "Context";
import { 
    ActionsDropdownConfig 
} from "Models";
import { ReviewerDocumentModal, ReviewerDocumentModel } from "Models/ReviewerFormDocumentModel";
import { 
    Permission 
} from "Models/Templates/Permission/Permission";
import { 
    ENDPOINTS 
} from "Models/Templates/Routes/Routes";
import React from "react";
import { 
    buildEndpoint, 
    useApiWorker 
} from "Utilities";
import { ReviewerDocumentDrawerForm } from ".";

export const ReviewerDocumentActionCell: React.FC<GridCellProps> = ({
    dataItem,
    field = '',
}) => {
  const API = useApiWorker();
  const toast = useToasts();
  const { refreshGrid } = useGridState();
  const { toggle, setEntityId } = useModalFormState();

  const value = dataItem[field];

  const editItem = () => {
    setEntityId(value);
    toggle();
  };

  const deleteItem = async () => {
    if (window.confirm('Are you sure you want to delete the form?')) {
      try {
        var deleteResponse = await API.delete(
          buildEndpoint(ENDPOINTS.reviewercredentialingforms.base, value)
        );
        if (deleteResponse.data.isSuccessStatusCode) {
          toast.success('Form has been removed.');
        } else if (deleteResponse.headers['exception'] === undefined) {
          toast.error('Fail removing form.');
        } else {
          toast.error(deleteResponse.headers['exception']);
        }
        refreshGrid && refreshGrid();
      } catch {
        toast.error('Fail removing form.');
      }
    }
  };

  const actionsConfig: ActionsDropdownConfig = {
    items: [
      {
        name: 'Edit',
        permissions: [Permission.EditReviewerCredentialingForm],
        modalConfig: {
          modalId: ReviewerDocumentModal.Documents,
          entityId: value,
          modalComponent: (
            <ReviewerDocumentDrawerForm
              formModel={new ReviewerDocumentModel(value)}
              endpoint={ENDPOINTS.reviewercredentialingforms.base}
            />
          ),
        },
      },
      {
        name: 'Delete',
        onClick: deleteItem,
        permissions: [Permission.DeleteReviewerCredentialingForm],
      },
    ],
  };

  return (
    <ActionsEllipsisCell actionsConfig={actionsConfig} dataItem={dataItem} />
  );
};