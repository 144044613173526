import { FileModel } from './FileModels';
import { Reviewer } from './ReviewerModels';

export class ReviewerDocumentModel extends FileModel {
  constructor(id: string | undefined) {
    super();
    this.reviewerId = id;
  }
  reviewerId?: string;
  reviewer?: Reviewer;
  title?: string;
}

export class ReviewerIdGridSearchModel {
  constructor(id: string | undefined) {
    this.reviewerId = id;
  }
  reviewerId?: string;
}

export enum ReviewerDocumentModal {
  Forms = 'attachmentsForms',
  Documents = 'attachmentsDocument',
}