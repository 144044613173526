import './InputBlock.scss';
import React from 'react';
import { InputType } from 'Models/FormModels';
import { Col, ColProps } from 'reactstrap';
import { useFormValueType, toggledClass, fullClassName } from 'Utilities';
import { InputTypeRenderer } from './InputTypeRenderer';
import { ColumnLayout } from 'Models/SharedModels';

export type InputBlockProps = {
  label?: string;
  name: string;
  type: InputType;
  value?: any;
  labelProps?: any;
  colProps?: ColProps;
  useDiv?: boolean;
  errors?: string[];
  hidden?: boolean;
  required?: boolean;
  restrictValues?: (val: any) => any;
  noPaddingMargin?: boolean;
  noSidePadding?: boolean;
  noRightPadding?: boolean;
  noBottomPadding?: boolean;
  noTopPadding?: boolean;
  rightFloatingLabel?: string;
  [x: string]: any;
};

export const InputBlock: React.FC<InputBlockProps> = ({
  type,
  colProps,
  value,
  useDiv,
  restrictValues,
  noPaddingMargin,
  noSidePadding,
  noRightPadding,
  noBottomPadding,
  noTopPadding,
  ...otherProps
}) => {
  const typedValue = useFormValueType(type, value, restrictValues);

  const Wrapper = useDiv ? 'div' : Col;
  if (!useDiv && colProps === undefined) {
    colProps = { ...ColumnLayout.Auto };
  }

  return (
    <Wrapper
      className={fullClassName(
        toggledClass('input-block--wrapper', !otherProps.hidden),
        toggledClass('no-padding-margin', noPaddingMargin),
        toggledClass('no-side-padding', noSidePadding),
        toggledClass('no-right-padding', noRightPadding),
        toggledClass('no-bottom-padding', noBottomPadding),
        toggledClass('no-top-padding', noTopPadding)
      )}
      {...colProps}
    >
      <InputTypeRenderer type={type} value={typedValue} {...otherProps} />
    </Wrapper>
  );
};
