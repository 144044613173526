import React from 'react';
import { NavConfigItem, RouteParameterMap } from '.';

//icons
import CodeIcon from '@mui/icons-material/Code';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import { Permission } from 'Models/Templates/Permission/Permission';
import DeveloperBoardIcon from '@mui/icons-material/DeveloperBoard';
import SettingsEthernetIcon from '@mui/icons-material/SettingsEthernet';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import ArticleIcon from '@mui/icons-material/Article';
import PagesIcon from '@mui/icons-material/Pages';
import GridOnIcon from '@mui/icons-material/GridOn';
import BuildIcon from '@mui/icons-material/Build';
import ShareIcon from '@mui/icons-material/Share';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import ReceiptIcon from '@mui/icons-material/Receipt';

export enum ROUTES {
  DASHBOARD = '/Dashboard',
  SWAGGER = '/Swagger',
  API_SWAGGER = '/ApiSwagger',
  ELMAH = '/Elmah',
  UNAUTHORIZED = '/Unauthorized',
  USER_MANAGEMENT = '/UserManagement',
  ENUMERATIONS = '/Enumerations',
  EXAMPLES = '/Examples',
  QUICK_SEARCH = '/QuickSearch',
  INTEGRATIONS = '/Integrations',
  ADD_EDIT = '/AddEdit',
  CASE_ADD_EDIT = '/CaseAddEdit',
  DETAILED_SEARCH = '/DetailedSearch',
  VIEW = '/View',
  REFERRAL = '/Referral',
  REFERRALCOMMERCIAL = '/ReferralCommercial',
  CLAIMANT = '/Claimant',
  CASE_MANAGEMENT = '/CaseManagement',
  OPEN_CASES = '/CaseManagement/OpenCases',
  CLOSED_CASES = '/CaseManagement/ClosedCases',
  APPOINTMENT = '/Appointment',
  CLAIM = '/Claim',
  GUIDELINE = '/Guideline',
  REFERRALSREQUEST = '/ReferralsRequest',
  REFERRALREQUESTPROPOSALS = '/ReferralRequestProposals',
  REFERRALREQUESTPROPOSALSCOMMERCIAL = '/ReferralRequestProposalsCommercial',
  ENTITIES = '/Entities',
  DOCUMENTS = '/Documents',
  CASEMANAGEMENT = '/CaseManagement',
  CONFIGURATION = '/Configuration',
  NETWORK_DEVELOPMENT = '/NetworkDevelopment',
  CLAIM_ESCALATIONS = '/ClaimEscalations',
  REVIEWER = '/Reviewer',
  REVIEWER_CONVERT = '/ReviewerConvert',
  QA = '/QA',
  AUDIT_QUEUE = '/AuditQueue',
  ADD_AUDIT_REPORT = '/AddAuditReport',
  ESCALATIONS_EXPORT = '/EscalationsExport',
  INVOICES = '/Invoices',
  MY_INVOICES = '/MyInvoices',
  COMMERCIAL = '/Commercial',
}

export const ROUTE_PARAMETERS: RouteParameterMap = {
  [ROUTES.CASE_ADD_EDIT]: ':caseId',
  [ROUTES.REFERRAL]: ':entityId',
  [ROUTES.REFERRALCOMMERCIAL]: ':entityId',
  [ROUTES.CLAIMANT]: ':entityId',
  [ROUTES.CLAIM]: ':entityId',
  [ROUTES.APPOINTMENT]: ':entityId',
  [ROUTES.GUIDELINE]: ':entityId',
  [ROUTES.REFERRALSREQUEST]: ':entityId',
  [ROUTES.CLAIM_ESCALATIONS]: ':entityId',
};

export const MAIN_NAV_CONFIG: NavConfigItem[] = [
  {
    label: 'Dashboard',
    route: ROUTES.DASHBOARD,
    default: true,
    permissions: [Permission.ViewDashboard],
    icon: <ShowChartIcon />,
  },
  {
    label: 'Case Management',
    route: ROUTES.CASEMANAGEMENT,
    permissions: [
      Permission.ViewOpenCases,
      Permission.ViewClosedCases,
      Permission.ViewReviewedReferrals,
      Permission.ViewAssignedToWriters,
      Permission.ViewNewReferrals,
      Permission.ViewPendingAppointments,
      Permission.ViewAllClaimants,
    ],
    default: false,
    icon: <GridOnIcon />,
  },
  {
    label: 'Entities',
    route: ROUTES.ENTITIES,
    default: false,
    permissions: [Permission.ViewEntities],
    icon: <PagesIcon />,
  },
  {
    label: 'Network Development',
    route: ROUTES.NETWORK_DEVELOPMENT,
    default: false,
    permissions: [Permission.ViewReviewers],
    icon: <ShareIcon />,
  },
  {
    label: 'Invoices',
    route: ROUTES.INVOICES,
    default: false,
    permissions: [Permission.ViewInvoices],
    icon: <ReceiptIcon />,
  },
  {
    label: 'My Invoices',
    route: ROUTES.MY_INVOICES,
    default: false,
    permissions: [Permission.ViewMyInvoices],
    icon: <ReceiptIcon />,
  },
  {
    label: 'Escalations Export',
    route: ROUTES.ESCALATIONS_EXPORT,
    permissions: [Permission.ViewEscalationsExport],
    default: false,
  },
  {
    label: 'QA',
    route: ROUTES.QA,
    permissions: [Permission.ViewAuditQueue, Permission.ViewAuditReport],
    default: false,
    icon: <FormatAlignLeftIcon />,
  },
  {
    label: 'Documents',
    route: ROUTES.DOCUMENTS,
    default: false,
    permissions: [Permission.ViewGuidelines],
    icon: <ArticleIcon />,
  },
  {
    label: 'Commercial View',
    route: ROUTES.COMMERCIAL,
    permissions: [
      Permission.AddReferralsRequestCommercial,
    ],
    default: false,
    icon: <GridOnIcon />,
  }
];

export const SETTINGS_NAV_CONFIG: NavConfigItem[] = [
  {
    label: 'User Management',
    icon: <PeopleOutlineIcon />,
    route: ROUTES.USER_MANAGEMENT,
    permissions: [Permission.ViewUser, Permission.AddUser, Permission.EditUser]
  },
  {
    label: 'Configuration',
    icon: <BuildIcon />,
    route: ROUTES.CONFIGURATION,
    permissions: [Permission.AddEvalTypes, Permission.EditEvalTypes,Permission.AddClient, Permission.EditClient, Permission.AddPhysician, Permission.EditPhysician]
  },
  {
    label: 'Development API',
    icon: <CodeIcon />,
    route: ROUTES.SWAGGER,
    permissions: [Permission.ViewApiKeys]
  },
  {
    label: 'Integration Swagger',
    icon: <SettingsEthernetIcon />,
    route: ROUTES.API_SWAGGER,
    permissions: [Permission.ViewApiKeys]
  },
  {
    label: 'Elmah',
    icon: <ErrorOutlineIcon />,
    route: ROUTES.ELMAH,
    permissions: [Permission.ViewErrorLog],
  },
  {
    label: 'Examples',
    icon: <DeveloperBoardIcon />,
    route: ROUTES.EXAMPLES,
    permissions: [Permission.ViewApiKeys]
  },
];
