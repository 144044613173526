import { GridCellProps, GridColumn } from '@progress/kendo-react-grid';
import { LightPageHeader } from 'Components/Display';
import {
  DateCell,
  FilterBarMainGrid,
  LinkCell,
  ReportsFilesDownloadCell,
} from 'Components/Grid';
import { FormProvider, GridProvider, useEnums } from 'Context';
import {
  defaultGridProps,
  emptyGuid,
  FilterBarFilterType,
  FilterConfig,
  IFilterConfig,
} from 'Models';
import { ReportTypeField } from 'Models/AppointmentReportModel';
import { ENDPOINTS } from 'Models/Templates/Routes/Routes';
import { ReferralSubRoute, ROUTES } from 'Navigation';
import React from 'react';
import { Col, Row } from 'reactstrap';
import { buildEndpoint, buildRoute, useGridSearch } from 'Utilities';

class AuditQueueSearch {
  searchKey?: string;
  id?: string;
  client?: string;
  benefitState?: string;
  appointmentDate?: Date;
}

export enum AuditQueueGridIds {
  DetailedSearch = 'detailedSearch',
}

export const AuditQueue: React.FC = () => {
  return (
    <GridProvider entityName="Referral Report">
      <LightPageHeader tall noBorder className="page-header-shadow">
        <Row>
          <Col>
            <h1>Audit Queue</h1>
          </Col>
        </Row>
      </LightPageHeader>
      <br />
      <Row>
        <Col></Col>
      </Row>
      <FilterBarDetailedSearchGrid />
    </GridProvider>
  );
};

const FilterBarDetailedSearchGrid: React.FC = () => {
  const auditQueueSearch = new AuditQueueSearch();

  return (
    <FormProvider formModel={auditQueueSearch}>
      <AuditQueueGrid />
    </FormProvider>
  );
};

const AuditQueueGrid: React.FC = () => {
  const [gridToggler] = useGridSearch();
  const { state } = useEnums();

  const filters: IFilterConfig[] = [
    new FilterConfig(
      'searchKey',
      FilterBarFilterType.SearchText,
      'Name, Claim Number'
    ),
    new FilterConfig('client', FilterBarFilterType.AsyncDropdown, 'Client', {
      readEndpoint: ENDPOINTS.clients.dropdownItems,
    }),
    new FilterConfig(
      'reviewedBy',
      FilterBarFilterType.AsyncDropdown,
      'Reviewed By',
      {
        readEndpoint: buildEndpoint(
          ENDPOINTS.users.endpointString('userByRolDropdownItems'),
          'reviewer'
        ),
      }
    ),
    new FilterConfig(
      'benefitState',
      FilterBarFilterType.EnumDropdown,
      'Benefit State',
      { data: state }
    ),
    new FilterConfig(
      'TypeOfEval',
      FilterBarFilterType.AsyncDropdown,
      'Type Of Eval',
      {
        readEndpoint: buildEndpoint(
          ENDPOINTS.clients.endpointString('typeOfEvalDropdownItems'),
          emptyGuid
        ),
      }
    ),
    new FilterConfig(
      'completedDate',
      FilterBarFilterType.DateRange,
      'Date Completed'
    ),
  ];
  return (
    <React.Fragment>
      <FilterBarMainGrid
        isMainGrid
        filterBarProps={{
          filterConfigurations: filters,
          filterValues: [],
        }}
        refreshToggle={gridToggler}
        readEndpoint={ENDPOINTS.auditqueue.read}
        {...defaultGridProps}
      >
        <GridColumn field="claimNumber" title="Claim Number" />
        <GridColumn field="claimant" title="Claimant" cell={claimantCell} />
        <GridColumn field="client" title="Client" />
        <GridColumn field="typeOfEval" title="Type of Eval" />
        <GridColumn field="benefitState" title="Benefit state" />
        <GridColumn
          field="reportFile"
          title="Report"
          cell={ReportsDownloadCell}
        />
        <GridColumn field="reviewedBy" title="Reviewed By" />
        <GridColumn
          field="completedDate"
          title="Date Completed"
          cell={DateFormatCell}
        />
        <GridColumn field="qaStatus" title="QA Status" />
        <GridColumn
          field="closedDate"
          title="Date Closed"
          cell={DateFormatCell}
        />
        <GridColumn field="id" title="Action" cell={UpdateStatusCell} />
      </FilterBarMainGrid>
    </React.Fragment>
  );
};

//Component to handle what kind of file is going to be downloaded
export const ReportsDownloadCell: React.FC<GridCellProps> = ({ ...props }) => {
  let endpoint =
    props.field == ReportTypeField.TemplateField
      ? ENDPOINTS.appointmentreportstemplates.base
      : ENDPOINTS.appointmentreports.base;
  return (
    <ReportsFilesDownloadCell fileControllerEndpoint={endpoint} {...props} />
  );
};

const claimantCell: React.FC<GridCellProps> = (props: GridCellProps) => {
  return (
    <LinkCell
      endpoint={ROUTES.REFERRAL}
      linkField="referralId"
      subRoute={ReferralSubRoute.View}
      {...props}
    ></LinkCell>
  );
};

const DateFormatCell: React.FC<GridCellProps> = (props: GridCellProps) => {
  return <DateCell {...props} dateFormat={'MM/DD/YYYY'} />;
};

const UpdateStatusCell: React.FC<GridCellProps> = (props: GridCellProps) => {
  return (
    <LinkCell
      {...props}
      endpoint={buildRoute(ROUTES.ADD_AUDIT_REPORT)}
      linkField="id"
      convertFunction={() => 'Update Status'}
    />
  );
};
