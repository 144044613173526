import React from 'react';
import { GridCellProps } from '@progress/kendo-react-grid';
import { DEFAULT_DATETIME_FORMAT, useToggler } from 'Utilities';
import { ModalDrawer, HtmlStringDisplay } from 'Components/Display';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { ButtonStyle, IconButton } from 'Components/Form';
import moment from 'moment';

export const ShowAuditCommentsCell: React.FC<GridCellProps> = ({
  dataItem,
  ...otherProps
}) => {
  const [showAll, toggleShowAll] = useToggler(false);
  const value: string = dataItem ? dataItem['displayComments'] : '';

  return (
    <td className="button-cell">
      <IconButton
        primary
        buttonStyle={ButtonStyle.Bare}
        iconElement={VisibilityIcon}
        onClick={toggleShowAll}
      />
      <ModalDrawer
        title={<DrawerTitle dataItem={dataItem} {...otherProps} />}
        toggle={toggleShowAll}
        isOpen={showAll}
        width={600}
      >
        <HtmlStringDisplay content={value} />
      </ModalDrawer>
    </td>
  );
};

const DrawerTitle: React.FC<GridCellProps> = ({ dataItem }) => {
  const eventDescription = dataItem ? dataItem['eventDescription'] : '';
  const userName = dataItem ? dataItem['userName'] : '';
  const timeStamp = dataItem ? dataItem['timestamp'] : '';
  const timeStampDisplay = moment(timeStamp).format(DEFAULT_DATETIME_FORMAT);

  return (
    <div className="flex-column">
      <div className="font-primary-bold mb-2">{eventDescription}</div>
      <div className="font-size-regular">
        <span className="font-secondary">By</span>{' '}
        <span className="font-secondary-bold">{userName}</span> -{' '}
        <span className="color-secondary font-secondary">
          {timeStampDisplay}
        </span>
      </div>
    </div>
  );
};
