import 'react-datepicker/dist/react-datepicker.css';
import React, { useRef, useState } from 'react';
import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker';
import MaskedInput from 'react-text-mask';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import {
  fullClassName,
  toggledClass,
  useOnClickOutside,
  dateMask,
  BLANK_FIELD,
} from 'Utilities';
import { Render } from 'Components/Display';

export type OldDateTimePickerProps = OldPickerWrapperProps & {
  value?: Date | null;
};

export type OldPickerWrapperProps = ReactDatePickerProps & {
  hidePickerButton?: boolean;
};

const defaultPickerProps: Partial<ReactDatePickerProps> = {
  closeOnScroll: true,
  todayButton: 'Today',
  disabledKeyboardNavigation: true,
};

const simpleDateMask = [
  /\d/,
  /\d/,
  '/',
  /\d/,
  /\d/,
  '/',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];
const timeMask = [/\d/, /\d/, ':', /\d/, /\d/, ' ', /P|A|p|a/, /M|m/];
const dateTimeMask = [...simpleDateMask, ' ', ...timeMask];

export const OldPickerWrapper: React.FC<OldPickerWrapperProps> = ({
  onFocus,
  onBlur,
  onChange,
  className,
  showTimeSelectOnly,
  disabled,
  hidePickerButton,
  ...allProps
}) => {
  const [focused, setFocused] = useState(false);
  const [calendarOpen, setCalendarOpen] = useState(false);
  const pickerElement = useRef<any>(null);
  useOnClickOutside(pickerElement, () => {
    setFocused(false);
    setCalendarOpen(false);
  });

  const handleKeyDown = (e: any) => {
    const { keyCode } = e;

    switch (keyCode) {
      // TAB - close calendar, unfocus
      case 9:
        setCalendarOpen(false);
        setFocused(false);
        break;
      // SPACE - open calendar
      case 32:
        e.stopPropagation();
        setCalendarOpen((prev) => !prev);
        break;
      // T - set date to today
      case 84:
        onChange(new Date(), undefined);
        break;
      // Y - set date to yesterday
      case 89:
        var yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        onChange(yesterday, undefined);
        break;
    }
  };

  const handleFocus = (e?: any) => {
    onFocus && onFocus(e);
    setFocused(true);
  };

  const handleBlur = (e?: any) => {
    onBlur && onBlur(e);
    setFocused(false);
    setCalendarOpen(false);
  };

  const handleCalendarToggleClick = () => {
    if (disabled) return;
    setCalendarOpen((prev) => !prev);
    setFocused(!calendarOpen);
  };

  const handleClickOutside = () => {
    setFocused(false);
    setCalendarOpen(false);
  };

  return (
    <div
      className={fullClassName(
        'field-value date-picker-wrap flex-vertical-center',
        toggledClass('focused', focused),
        toggledClass('hidden-picker-button', hidePickerButton),
        className
      )}
      ref={(ref) => (pickerElement.current = ref)}
    >
      <ReactDatePicker
        onKeyDown={handleKeyDown}
        onFocus={handleFocus}
        onBlur={handleBlur}
        open={calendarOpen}
        onClickOutside={handleClickOutside}
        onChange={onChange}
        disabled={disabled}
        showTimeSelectOnly={showTimeSelectOnly}
        popperModifiers={{
          preventOverflow: {
            boundariesElement: 'viewport',
          },
        }}
        className={className}
        {...allProps}
      />
      <Render condition={!showTimeSelectOnly && !hidePickerButton}>
        <CalendarTodayIcon
          className="color-primary cursor-pointer date-picker-toggle"
          onClick={handleCalendarToggleClick}
        />
      </Render>
      <Render condition={showTimeSelectOnly && !hidePickerButton}>
        <AccessTimeIcon
          className="color-primary cursor-pointer date-picker-toggle"
          onClick={handleCalendarToggleClick}
        />
      </Render>
    </div>
  );
};

export const OldDateTimePicker: React.FC<OldDateTimePickerProps> = ({
  value,
  onFocus,
  onBlur,
  className,
  ...otherProps
}) => {
  return (
    <OldPickerWrapper
      selected={value}
      showTimeSelect
      dateFormat="MM/dd/yyyy hh:mm aa"
      placeholderText="mm/dd/yyyy hh:mm"
      customInput={
        <MaskedInput mask={dateTimeMask} onFocus={onFocus} onBlur={onBlur} />
      }
      onFocus={onFocus}
      onBlur={onBlur}
      value={value}
      className={fullClassName('date-time-picker-wrap', className)}
      {...defaultPickerProps}
      {...otherProps}
    />
  );
};

export const OldDatePicker: React.FC<OldDateTimePickerProps> = ({
  value,
  onFocus,
  onBlur,
  className,
  ...otherProps
}) => {
  return (
    <OldPickerWrapper
      customInput={
        <MaskedInput mask={dateMask} onFocus={onFocus} onBlur={onBlur} />
      }
      placeholderText="mm/dd/yyyy"
      selected={value}
      onFocus={onFocus}
      onBlur={onBlur}
      value={value}
      className={fullClassName('date-only-picker-wrap', className)}
      {...defaultPickerProps}
      {...otherProps}
    />
  );
};

export const OldTimePicker: React.FC<OldDateTimePickerProps> = ({
  value,
  onFocus,
  onBlur,
  onChange,
  className,
  ...otherProps
}) => {
  return (
    <OldPickerWrapper
      selected={value}
      onChange={onChange}
      showTimeSelect
      showTimeSelectOnly
      timeIntervals={15}
      timeCaption="Time"
      dateFormat="h:mm aa"
      placeholderText="hh:mm"
      onFocus={onFocus}
      onBlur={onBlur}
      className={fullClassName('time-picker-wrap', className)}
      {...defaultPickerProps}
      {...otherProps}
    />
  );
};

export const DateRangeSeparator: React.FC = () => {
  return <div className="checkbox-wrapper date-range-separator pl-2">–</div>;
};
