import axios, {
  AxiosRequestConfig,
  AxiosResponse,
  CancelTokenSource,
} from 'axios';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { InteractionRequiredAuthError } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import {
  ConfirmationConfig,
  GlobalActionTypes,
  useFormState,
  useGlobalDispatch,
  useGlobalState,
  useToasts,
} from 'Context';
import { isArray, isDate } from 'lodash';
import { CoreEnum, DropDownItemModel } from 'Models';
import { FormErrors, FormStatus, InputType } from 'Models/FormModels';
import { AsyncStatus } from 'Models/SharedModels';
import { Permission } from 'Models/Templates/Permission/Permission';
import { NetworkDevelopmentSubRoute, ROUTES } from 'Navigation';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { BLANK_FIELD, defaultDataTransform, EMPTY_GUID } from './Constants';
import {
  buildRoute,
  isNullOrUndefined,
  isValidDate,
  setPropertiesToLowercase,
} from './HelperFunctions';

export const useApiWorker = () => {
  const { baseUrl, token } = useGlobalState();
  const { instance } = useMsal();
  const dispatch = useGlobalDispatch();
  const account = instance.getActiveAccount();
  var authToken = token;

  const axiosConfig = {
    baseURL: baseUrl + 'api/',
    headers: {
      Accept: '*/*',
      'Content-Type': 'application/json',
    },
  };

  const apiWorker = axios.create(axiosConfig);

  apiWorker.interceptors.request.use(
    async (config) => {
      if (account) {
        instance
          .acquireTokenSilent({
            scopes: [
              process.env.NODE_ENV === 'development'
                ? `${process.env.REACT_APP_CLIENT_ID}`
                : `${window.CLIENT_ID}`,
            ],
            account: account,
          })
          .catch((error) => {
            console.error('AQUIRE TOKEN ERROR: ', error);
            if (error instanceof InteractionRequiredAuthError) {
              // fallback to interaction when silent call fails
              console.log('ATTEMPTING REDIRECT TO RESOLVE');
              instance.acquireTokenRedirect({
                scopes: [
                  process.env.NODE_ENV === 'development'
                    ? `${process.env.REACT_APP_CLIENT_ID}`
                    : `${window.CLIENT_ID}`,
                ],
                account: account,
              });
            } else {
              instance.logout();
            }
          })
          .then((response) => {
            if (response) {
              if (response.accessToken !== authToken) {
                authToken = response.accessToken;
                dispatch({
                  type: GlobalActionTypes.SetToken,
                  payload: `${response.accessToken}`,
                });
              }
            }
          });
      }
      return {
        ...config,
        headers: { Authorization: `Bearer ${authToken}`, ...config.headers },
      };
    },
    (error) => {
      Promise.reject(error);
    }
  );

  return apiWorker;
};

export function useIsMountedRef() {
  const isMountedRef = useRef<any>(null);
  useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  });
  return isMountedRef;
}

export function useSafeStateUpdate() {
  const isMountedRef = useIsMountedRef();

  return (updateFunc?: () => any, onUnMount?: () => any) => {
    isMountedRef.current && updateFunc && updateFunc();
    !isMountedRef.current && onUnMount && onUnMount();
  };
}

export function useDataFetcher<T>(
  url: string,
  initialData: T,
  transformData?: (response: any) => T,
  type?: new () => T,
  condition = true
) {
  const API = useApiWorker();
  const [error, setError] = useState<any>(null);
  const [fetchStatus, setFetchStatus] = useState(AsyncStatus.Idle);
  const [data, setData] = useState<T>(initialData);
  const safeStateUpdate = useSafeStateUpdate();

  const getData = useCallback(
    async (cancelTokenSource?: CancelTokenSource) => {
      safeStateUpdate(() => setFetchStatus(AsyncStatus.Pending));

      try {
        var response = await API.get(url, {
          cancelToken: cancelTokenSource?.token,
        });

        if (response !== undefined) {
          var formattedResponse = transformData
            ? transformData(response)
            : response;

          if (type !== undefined) {
            var typedObj: any = new type();
            Object.assign(typedObj, formattedResponse);
            safeStateUpdate(() => setData(typedObj));
          } else {
            var anyObj: any = isArray(formattedResponse)
              ? [...formattedResponse]
              : { ...formattedResponse };
            safeStateUpdate(() => setData(anyObj as T));
          }

          safeStateUpdate(() => setFetchStatus(AsyncStatus.Success));

          return formattedResponse;
        } else {
          safeStateUpdate(() => {
            setError('Response is undefined');
            setFetchStatus(AsyncStatus.Error);
          });

          return null;
        }
      } catch (err) {
        if (axios.isCancel(err)) {
          safeStateUpdate(() => setFetchStatus(AsyncStatus.Cancelled));
          // console.log(`Request cancelled: ${url}`);
        }

        safeStateUpdate(() => {
          setError(err);
          setFetchStatus(AsyncStatus.Error);
        });

        return null;
      }
    },
    [url, condition, type]
  );

  useEffect(() => {
    let cancelTokenSource = axios.CancelToken.source();
    if (condition) {
      getData(cancelTokenSource);
    } else {
      setFetchStatus(AsyncStatus.Idle);
    }

    return () => cancelTokenSource.cancel();
  }, [url, condition, getData]);

  return [data, fetchStatus, { getData, error }] as const;
}

export function useSimpleDataFetcher<T>(
  url: string,
  initialData: T,
  condition?: boolean
) {
  return useDataFetcher<T>(
    url,
    initialData,
    defaultDataTransform,
    undefined,
    condition
  );
}

export function useDataPoster<T = any>(
  url: string,
  handleResponse?: (resp: any) => any,
  usePut = false
) {
  const API = useApiWorker();
  const [response, setResponse] = useState<T>();
  const [error, setError] = useState<any>(null);
  const [postStatus, setPostStatus] = useState(AsyncStatus.Idle);
  const safeStateUpdate = useSafeStateUpdate();

  const postData = useCallback(
    async (data: any, cancelTokenSource?: CancelTokenSource) => {
      safeStateUpdate(() => setPostStatus(AsyncStatus.Pending));

      var resp: AxiosResponse<T>;
      try {
        const postOrPut = usePut ? API.put : API.post;
        resp = await postOrPut(url, data, {
          cancelToken: cancelTokenSource?.token,
        });

        if (resp !== undefined) {
          safeStateUpdate(() => {
            handleResponse && handleResponse(resp);
            setResponse(resp.data);
            setPostStatus(AsyncStatus.Success);
          });
        } else {
          safeStateUpdate(() => {
            setError('Response is undefined');
            setPostStatus(AsyncStatus.Error);
          });
        }
        return resp;
      } catch (err) {
        if (axios.isCancel(err)) {
          safeStateUpdate(() => setPostStatus(AsyncStatus.Cancelled));
        }

        safeStateUpdate(() => {
          setError(err);
          setPostStatus(AsyncStatus.Error);
        });
        return err;
      }
    },
    [url]
  );

  return [postData, { response, postStatus, error }] as const;
}

export const useToggler = (initialState = false) => {
  const [boolState, setBoolState] = useState(initialState);
  const toggle = () => {
    setBoolState((prev) => !prev);
  };

  return [boolState, toggle, setBoolState] as const;
};

export const blobErrorFileReader = (data: Blob) => {
  const fileReader = new FileReader();
  return new Promise<any>((resolve, reject) => {
    fileReader.onerror = () => {
      fileReader.abort();
      reject(new Error('Problem parsing file'));
    };
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.readAsText(data);
  });
};

export const useFormSubmitter = (
  endpoint: string,
  handleSuccess?: (r: any) => any,
  handleError?: (r: any) => any,
  transformResponse?: (r: any) => any,
  axiosConfig?: AxiosRequestConfig,
  confirmationConfig?: ConfirmationConfig
) => {
  const [formStatus, setFormStatus] = useState<FormStatus>(FormStatus.Initial);
  const [response, setResponse] = useState<any>(null);
  const [errors, setErrors] = useState<FormErrors>({});
  const safeStateUpdate = useSafeStateUpdate();
  const API = useApiWorker();
  const toast = useToasts();

  const submitData = useCallback(
    async (
      formData: any,
      alternateEndpoint?: string,
      alternateHandleSuccess?: (r: any, originalHandler?: any) => any,
      alternateHandleError?: (r: any, originalHandler?: any) => any
    ) => {
      let cancelTokenSource = axios.CancelToken.source();
      const stateUpdateOrCancel = (updateFunc?: () => any) => {
        safeStateUpdate(updateFunc, cancelTokenSource.cancel);
      };
      if (
        confirmationConfig &&
        confirmationConfig.shouldDisplay &&
        !window.confirm(confirmationConfig.confirmationLanguage)
      ) {
        return;
      }

      stateUpdateOrCancel(() => setFormStatus(FormStatus.Submitting));
      try {
        const postResponse = await API.post(
          alternateEndpoint ?? endpoint,
          formData,
          {
            cancelToken: cancelTokenSource.token,
            ...axiosConfig,
          }
        );

        const { data } = postResponse;

        //validation errors
        if (data && data.modelState) {
          stateUpdateOrCancel(() => {
            setErrors(data.modelState);
            setFormStatus(FormStatus.ValidationError);
          });
        }

        //successful post
        if (
          data !== undefined ||
          (transformResponse && postResponse !== undefined)
        ) {
          stateUpdateOrCancel(() => {
            const finalData = transformResponse
              ? transformResponse(postResponse)
              : data;
            setResponse(finalData);
            setFormStatus(FormStatus.Success);
            if (alternateHandleSuccess)
              alternateHandleSuccess(finalData, handleSuccess);
            else handleSuccess && handleSuccess(finalData);
          });
        }
      } catch ({ response }) {
        //errors with request
        const { data }: any = response || {};

        if (data && axiosConfig?.responseType == 'blob') {
          const file = await blobErrorFileReader(data);
          // Parse content and retrieve 'message'
          const message = JSON.parse(file);
          stateUpdateOrCancel(() => {
            setErrors(setPropertiesToLowercase(message.errors));
            setFormStatus(FormStatus.ValidationError);
            toast.error('Please address the highlighted fields.');
          });
        } else if (data) {
          if (data.ErrorMesage) {
            console.log(data.ErrorMessage);
            toast.error(data.ErrorMessage);
          }

          if (data.errors) {
            stateUpdateOrCancel(() => {
              setErrors(setPropertiesToLowercase(data.errors));
              setFormStatus(FormStatus.ValidationError);
              toast.error('Please address the highlighted fields.');
            });
          } else {
            if (alternateHandleError) {
              alternateHandleError(data);
            } else {
              toast.error(
                'An unhandled error occurred, please review the application log.'
              );
            }

            stateUpdateOrCancel(() => setFormStatus(FormStatus.Error));
          }

          stateUpdateOrCancel(() => {
            handleError && handleError(data);
          });
        } else {
          if (handleError) {
            handleError('An Error occurred processing the request');
            stateUpdateOrCancel(() => setFormStatus(FormStatus.Error));
          }
        }
      }
    },
    [
      handleSuccess,
      handleError,
      transformResponse,
      endpoint,
      confirmationConfig,
    ]
  );

  return [submitData, formStatus, { response, errors }] as const;
};

export const useHasPermissions = () => {
  const { currentUser } = useGlobalState();
  //const { caseId } = useGlobalCaseState();

  const hasPermissions = useCallback(
    (permissions?: Permission[]) => {
      return currentUser?.hasPermissions(permissions || [], 1);
    },
    [currentUser]
  );

  return hasPermissions;
};

export function useUrlSearchParams() {
  let location = useLocation();
  return new URLSearchParams(location.search);
}

// given an enum or string-valued object,
// extracts url param values into an object indexed by those strings
export function useUrlSearchParamsObject<TObjectType = any>(valueNames: any) {
  const urlSearchParams = useUrlSearchParams();
  return Object.values<string>(valueNames).reduce(
    (current: any, name: string) => {
      current[name] = urlSearchParams.get(name);
      return current;
    },
    {}
  ) as TObjectType;
}

export function useUrlLastSegment() {
  const currentPathname = useLocation().pathname;
  return currentPathname.split('/').pop();
}

export const getFormValue = (
  type: InputType,
  value: any,
  restrictValues?: (val: any) => any
) => {
  var val = value;
  switch (type) {
    case InputType.DatePicker:
    case InputType.DateTimePicker:
    case InputType.TimePicker: {
      if (!isNullOrUndefined(value)) {
        val = isDate(value) ? value : new Date(value);

        if (!isValidDate(val)) {
          val = null;
        }
      } else {
        val = null;
      }
      break;
    }
    case InputType.OldDatePicker:
    case InputType.OldTimePicker: {
      if (!isNullOrUndefined(value)) {
        val = isDate(value) ? value : new Date(value);

        if (!isValidDate(val)) {
          val = null;
        }
      } else {
        val = null;
      }
      break;
    }
    case InputType.Switch:
      val = value ? true : false;
      break;
    case InputType.Checkbox:
      if (value === undefined) {
        val = false;
      } else if (value === null) {
        val = null;
      } else {
        val = value ? true : false;
      }
      break;
    case InputType.Text:
    case InputType.TextArea:
    case InputType.MaskedText:
    case InputType.RichText: {
      if (isNullOrUndefined(value)) {
        val = '';
      } else {
        val = value;
      }
      break;
    }
    case InputType.Currency:
    case InputType.Number:
    case InputType.Percentage: {
      if (isNullOrUndefined(value)) {
        val = null;
      } else {
        val = value;
      }
      break;
    }
    case InputType.AsyncDropDown:
    case InputType.DropDown:
      val =
        value === undefined ||
        (value as DropDownItemModel)?.name === BLANK_FIELD
          ? null
          : value;
      break;
    case InputType.EnumDropdown:
      val =
        value === undefined || (value as CoreEnum)?.displayName === BLANK_FIELD
          ? null
          : value;
      break;
    case InputType.EnumRadioGroup:
    case InputType.YesNoBooleanRadioGroup: {
      val = value === undefined ? null : value;
      break;
    }
    default:
      val = value === null ? undefined : value;
      break;
  }

  return (restrictValues && restrictValues(val)) || val;
};

export const useFormValueType = (
  type: InputType,
  value: any,
  restrictValues?: (val: any) => any
) => {
  const [typedValue, setTypedValue] = useState<any>(
    getFormValue(type, value, restrictValues)
  );

  useEffect(() => {
    const formValue = getFormValue(type, value, restrictValues);
    setTypedValue(formValue);
  }, [value, type]);

  return useMemo(() => {
    return typedValue;
  }, [typedValue]);
};

// Hook
export const useDebounce = (value: any, delay = 300) => {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay] // Only re-call effect if value or delay changes
  );

  return debouncedValue;
};

// Hook
export function useLocalStorage<T = any>(key: string, initialValue: T) {
  // Prevent build error "window is undefined" but keep keep working
  const isServer = typeof window === 'undefined';
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    // Get from local storage then
    // parse stored json or return initialValue
    if (isServer) {
      return initialValue;
    }
    try {
      const item = window.localStorage.getItem(key);
      return item ? (JSON.parse(item) as T) : initialValue;
    } catch (error) {
      console.log(error);
      return initialValue;
    }
  });
  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = (value: T) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      // Save state
      setStoredValue(valueToStore);
      // Save to local storage
      if (!isServer) {
        window.localStorage.setItem(key, JSON.stringify(valueToStore));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getValue = () => {
    const item = window.localStorage.getItem(key);
    return item ? (JSON.parse(item) as T) : initialValue;
  };

  return [storedValue, setValue, getValue] as const;
}

export const useApiDelete = () => {
  const API = useApiWorker();

  const sendDeleteRequest = async (
    deleteEndpoint: string,
    values?: any,
    confirmationMessage?: string,
    onSuccess?: (response: any) => void,
    onError?: (response: any) => void
  ) => {
    if (
      deleteEndpoint &&
      ((confirmationMessage && window.confirm(confirmationMessage)) ||
        confirmationMessage === undefined)
    ) {
      try {
        const response = await API.post(deleteEndpoint, values);
        onSuccess && onSuccess(response);
      } catch (error) {
        onError && onError(error);
      }
    }
  };

  return sendDeleteRequest;
};

// Hook
export function useOnClickOutside(
  ref: any,
  handler: any,
  discludePopups?: boolean
) {
  const refContainsTarget = (ref: any, event: any) => {
    return (
      ref === null || ref.current === null || ref.current.contains(event.target)
    );
  };

  useEffect(() => {
    const listener = (event: any) => {
      // Do nothing if clicking ref's element or descendent elements
      if (isArray(ref)) {
        if (ref.some((r) => refContainsTarget(r, event))) {
          return;
        }
      } else if (refContainsTarget(ref, event)) {
        return;
      }

      if (
        discludePopups &&
        event?.path &&
        event?.path.some(
          (el: any) => el && el.classList?.contains('k-animation-container')
        )
      ) {
        return;
      }

      handler(event);
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, handler]);
}

export function useOnClickOutsidePopup(ref: any, handler: any) {
  useOnClickOutside(ref, handler, true);
}

export const useIdParam = () => {
  const { id = EMPTY_GUID } = useParams<any>();
  const isAdd = id === EMPTY_GUID;

  return [id, isAdd];
};

export const useCaseViewParams = () => {
  const [id] = useIdParam();
  const { caseId } = useCaseState();

  if (caseId === EMPTY_GUID) {
    throw new Error('Invalid Case ID.');
  }

  return [caseId, id];
};

export const useCaseAddEditParams = () => {
  const [id, isAdd] = useIdParam();
  const { caseId } = useCaseState();

  if (caseId === EMPTY_GUID) {
    throw new Error('Invalid Case ID.');
  }

  return [caseId, id, isAdd];
};

export const useAddEditSuccessHandler = (
  entityName: string,
  isAdd: boolean,
  redirectUrl?: string,
  customToastMessage?: string,
  noToast = false
) => {
  const toast = useToasts();
  let history = useHistory();

  const handleSuccess = () => {
    !noToast &&
      toast.success(
        customToastMessage
          ? customToastMessage
          : `${entityName} ${isAdd ? 'added' : 'updated'}.`
      );

    if (redirectUrl) {
      history.push(redirectUrl);
    } else {
      if (window.location.href.endsWith('#')) {
        history.goBack();
      }
      history.goBack();
    }
  };

  return handleSuccess;
};

export const useAddReviewerSuccessHandler = (
  entityName: string,
  isAdd: boolean,
  redirectUrl?: string,
  customToastMessage?: string,
  noToast = false
) => {
  const toast = useToasts();
  let history = useHistory();

  const handleSuccess = (resultingId?: any) => {
    !noToast &&
      toast.success(
        customToastMessage
          ? customToastMessage
          : `Reviewer Succesfully created.`
      );

    if (isAdd) {
      history.push(buildRoute(ROUTES.ADD_EDIT, ROUTES.REVIEWER, resultingId));
    } else {
      history.push(buildRoute(ROUTES.NETWORK_DEVELOPMENT + '/Reviewers'));
    }
  };

  return handleSuccess;
};

export const useAddClaimantuccessHandler = (
  entityName: string,
  isAdd: boolean,
  redirectUrl?: string,
  customToastMessage?: string,
  noToast = false
) => {
  const toast = useToasts();
  let history = useHistory();

  const handleSuccess = (resultingId?: any) => {
    !noToast &&
      toast.success(
        customToastMessage
          ? customToastMessage
          : `Claimant Succesfully created.`
      );

    if (redirectUrl) {
      history.push(redirectUrl);
    } else {
      history.push(buildRoute(ROUTES.CLAIMANT, resultingId, 'View'));
    }
  };

  return handleSuccess;
};

export const useConvertReviewerSuccessHandler = (
  entityName: string,
  isAdd: boolean,
  redirectUrl?: string,
  customToastMessage?: string,
  noToast = false
) => {
  const toast = useToasts();
  let history = useHistory();

  const handleSuccess = (resultingId?: any) => {
    !noToast &&
      toast.success(
        customToastMessage ? customToastMessage : `User Succesfully created.`
      );

    if (redirectUrl) {
      history.push(redirectUrl);
    } else {
      history.push(
        buildRoute(
          ROUTES.NETWORK_DEVELOPMENT,
          NetworkDevelopmentSubRoute.ReviewersListing
        )
      );
    }
  };

  return handleSuccess;
};

export const useForceUpdate = () => {
  const [, setForceUpdateValue] = useState(0);
  return () => setForceUpdateValue((value) => ++value);
};

export const useSubGrid = () => {
  const forceUpdate = useForceUpdate();
  const expandField = 'expanded';

  const onExpandChange = (event: any) => {
    event.dataItem.expanded = event.value;
    forceUpdate();
  };

  return { expandField, onExpandChange };
};

export const useGridSearch = (debounceLength?: number) => {
  const [initialLoad, setInitialLoad] = useState(true);
  const [gridToggler, toggleGrid] = useToggler();
  const { formValues } = useFormState();
  const debounceSearchModel = useDebounce(formValues, debounceLength || 300);

  useEffect(() => {
    if (initialLoad) {
      setInitialLoad(false);
    } else {
      toggleGrid();
    }
  }, [debounceSearchModel]);

  return [debounceSearchModel, gridToggler] as const;
};

export function useGridRefreshEffect(...dependencies: any[]) {
  const isInitialLoad = useRef<boolean>(true);
  const [gridToggleValue, toggleGrid] = useToggler();

  useEffect(() => {
    if (!isInitialLoad.current) {
      toggleGrid();
    }
    isInitialLoad.current = false;
  }, [...dependencies]);

  return [gridToggleValue, toggleGrid] as const;
}

function useCaseState(): { caseId: any } {
  const res = {
    caseId: 0,
  };
  return res;
}
