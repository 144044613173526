import { EnumTypeSample } from 'Navigation';
import React from 'react';
import { SecureProps } from './PropInterfaceModels';

export type DropdownItemModalConfig = {
  modalComponent: React.ReactNode | React.ReactNodeArray;
  modalId: string;
  entityId?: string;
  modalItem?: any;
};

export type ActionsDropdownItem = SecureProps & {
  name: string;
  url?: string;
  isUrlRelative?: boolean;
  openInNewTab?: boolean;
  onClick?: (event: any) => void;
  childItems?: ActionsDropdownItem[];
  condition?: boolean;
  isDisabled?: boolean;
  divWrapper?: boolean;
  caseTypes?: EnumTypeSample[];
  modalConfig?: DropdownItemModalConfig;
};

export type ActionsDropdownConfig = {
  name?: string;
  items: ActionsDropdownItem[];
  type?: DropdownType;
  caseType?: EnumTypeSample;
};

export enum DropdownType {
  Outline = 'outline',
  Solid = 'solid',
  PrimarySolid = 'primarySolid',
  Ellipsis = 'ellipsis',
  PrimaryOutline = 'primaryOutline',
}

export class DropDownItemModel {
  id?: string;
  name?: string;
}
