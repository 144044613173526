import { GridCellProps } from '@progress/kendo-react-grid';
import { ActionsEllipsisCell } from 'Components/Grid';
import { useGridState, useToasts } from 'Context';
import {
  ActionsDropdownConfig,
  AppointmentDocumentModal,
  AppointmentDocumentModel,
} from 'Models';
import { Permission } from 'Models/Templates/Permission/Permission';
import { ENDPOINTS } from 'Models/Templates/Routes/Routes';
import React from 'react';
import { buildEndpoint, useApiWorker } from 'Utilities';
import { AppointmentDocumentDrawerForm, AppointmentDocumentNotesForm } from '.';

export const AppointmentDocumentActionCell: React.FC<GridCellProps> = ({
  dataItem,
  field = '',
}) => {
  const API = useApiWorker();
  const toast = useToasts();
  const { refreshGrid } = useGridState();
  const value = dataItem[field];

  const deleteItem = async () => {
    if (window.confirm('Are you sure you want to delete document?')) {
      try {
        var deleteResponse = await API.delete(
          buildEndpoint(ENDPOINTS.appointmentdocuments.base, value)
        );
        if (deleteResponse.data.isSuccessStatusCode) {
          toast.success('Document has been removed.');
        } else if (deleteResponse.headers['exception'] === undefined) {
          toast.error('Fail removing document.');
        } else {
          toast.error(deleteResponse.headers['exception']);
        }
        refreshGrid && refreshGrid();
      } catch {
        toast.error('Fail removing document.');
      }
    }
  };

  const actionsConfig: ActionsDropdownConfig = {
    items: [
      {
        name: 'Edit',
        permissions: [Permission.EditAppointmentDocument],
        modalConfig: {
          modalId: AppointmentDocumentModal.Attachments,
          entityId: value,
          modalComponent: (
            <AppointmentDocumentDrawerForm
              formModel={new AppointmentDocumentModel(value)}
              endpoint={ENDPOINTS.appointmentdocuments.base}
            />
          ),
        },
      },
      {
        name: 'Notes',
        permissions: [
          Permission.AddEditAppointmentDocumentNote,
          Permission.ViewAppointmentDocumentNote,
        ],
        modalConfig: {
          modalId: AppointmentDocumentModal.Notes,
          entityId: value,
          modalComponent: (
            <AppointmentDocumentNotesForm
              formModel={new AppointmentDocumentModel(value)}
              endpoint={ENDPOINTS.appointmentdocuments.base}
            />
          ),
        },
      },
      {
        name: 'Delete',
        onClick: deleteItem,
        permissions: [Permission.DeleteAppointmentDocument],
      },
    ],
  };

  return (
    <ActionsEllipsisCell actionsConfig={actionsConfig} dataItem={dataItem} />
  );
};
