import { makeStyles } from '@mui/styles';
import {
  AsyncLoadingSection,
  SingleSectionPageCard,
  TaskPageHeaderLayout,
} from 'Components/Display';
import {
  defaultFormatName,
  ListSectionProps,
  SmartInputBlock,
  SmartMultiSectionList,
} from 'Components/Form';
import { useEnums } from 'Context/EnumerationProvider';
import { Address, ColSize, Physician } from 'Models';
import { InputType } from 'Models/FormModels';
import { ENDPOINTS } from 'Models/Templates/Routes/Routes';
import React, { Fragment } from 'react';
import { Row } from 'reactstrap';
import {
  useDataFetcher,
  addEditTitle,
  useIdParam,
  useAddEditSuccessHandler,
  defaultDataTransform,
  buildQueryUrl,
  buildEndpoint,
} from 'Utilities';

export const AddEditPhysician: React.FC = () => {
  const entityName = 'Physician';
  const [id, isAdd] = useIdParam();

  const handleSuccess = useAddEditSuccessHandler(entityName, isAdd);
  const [data, loadingStatus] = useDataFetcher<Physician>(
    buildQueryUrl(buildEndpoint(ENDPOINTS.physicians.get), {
      id: id,
    }),
    new Physician(),
    defaultDataTransform,
    Physician
  );

  return (
    <AsyncLoadingSection loadingStatus={loadingStatus} useMask>
      <TaskPageHeaderLayout
        title={addEditTitle(entityName, isAdd)}
        formProviderProps={{
          formModel: data,
          endpoint: ENDPOINTS.physicians.base,
          handleSuccess: handleSuccess,
        }}
      >
        <PhysiciansAddEditFormContent />
      </TaskPageHeaderLayout>
    </AsyncLoadingSection>
  );
};

export const PhysiciansAddEditFormContent: React.FC = () => {
  const { physicianSpeciality, gender } = useEnums();

  type ListObject = {
    address?: Address;
  };

  const initialListObjectValue: ListObject = {
    address: new Address(),
  };

  const useStyles = makeStyles({
    addresses: {
      marginBottom: '2.1rem',
    },
  });

  const classes = useStyles();

  return (
    <Fragment>
      <SingleSectionPageCard cardTitle="" centerContent inputSection>
        <Row>
          <SmartInputBlock
            name="firstName"
            label="Treating Physician First Name"
            type={InputType.Text}
            colProps={{ xs: ColSize.OneThird }}
            required
          />
          <SmartInputBlock
            name="lastName"
            label="Treating Physician Last Name"
            type={InputType.Text}
            colProps={{ xs: ColSize.OneThird }}
            required
          />
          <SmartInputBlock
            name="physicianSpeciality"
            label="Speciality"
            type={InputType.EnumDropdown}
            data={physicianSpeciality}
            colProps={{ xs: ColSize.OneThird }}
          />
          <SmartInputBlock
            name="gender"
            label="gender"
            type={InputType.EnumDropdown}
            data={gender}
            colProps={{ xs: ColSize.OneThird }}
          />
          <SmartInputBlock
            name="email"
            label="Email"
            type={InputType.Text}
            colProps={{ xs: ColSize.OneThird }}
          />
          <SmartInputBlock
            name="practiceName"
            label="Practice Name"
            type={InputType.Text}
            colProps={{ xs: ColSize.OneThird }}
          />
          <SmartInputBlock
            name="phone"
            label="Phone"
            type={InputType.PhoneNumber}
            colProps={{ xs: ColSize.OneThird }}
          />
          <SmartInputBlock
            name="fax"
            label="Fax"
            type={InputType.PhoneNumber}
            colProps={{ xs: ColSize.OneThird }}
          />
        </Row>
        <Row>
          <div className="inner-form-header">
            <h4>Address Book</h4>
          </div>
        </Row>
        <Row>
          <SmartMultiSectionList
            listName="addresses"
            listObjectName="Addresses"
            initialValues={initialListObjectValue}
            className={classes.addresses}
          >
            <PhysiciansAddress />
          </SmartMultiSectionList>
        </Row>
      </SingleSectionPageCard>
    </Fragment>
  );
};

export const PhysiciansAddress: React.FC<ListSectionProps> = ({
  formatInputName = defaultFormatName,
}) => {
  const { state } = useEnums();

  return (
    <Fragment>
      <Row>
        <SmartInputBlock
          type={InputType.Text}
          name={formatInputName('address.line1')}
          label="Address Line 1"
          width={200}
        />
        <SmartInputBlock
          type={InputType.Text}
          name={formatInputName('address.line2')}
          label="Address Line 2"
          width={200}
        />
      </Row>
      <Row>
        <SmartInputBlock
          type={InputType.Text}
          name={formatInputName('address.city')}
          label="City"
          required
          width={200}
        />
        <SmartInputBlock
          name={formatInputName('address.state')}
          label="State"
          type={InputType.EnumDropdown}
          data={state}
          colProps={{ xs: ColSize.OneThird }}
        />
        <SmartInputBlock
          type={InputType.Text}
          name={formatInputName('address.zip')}
          label="Zip Code"
          required
          width={200}
        />
      </Row>
    </Fragment>
  );
};
