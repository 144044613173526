import React from "react";
import { GridCellProps } from "@progress/kendo-react-grid";

type FormattedDisplayCellProps = GridCellProps & {
  displayField: string;
};

export const FormattedDisplayCell: React.FC<FormattedDisplayCellProps> = ({
  dataItem,
  displayField,
  className,
}) => {
  const displayValue = dataItem[displayField];

  return <td className={className}>{displayValue}</td>;
};

export const getWrappableDisplayCell = (displayField: string) => {
  return getFormattedDisplayCell(displayField, {
    className: "whitespace-preline",
  });
};

export const getFormattedDisplayCell = (
  displayField: string,
  cellProps: Partial<GridCellProps> = {}
) => {
  return (props: GridCellProps) =>
    props.rowType === "data" ? (
      <FormattedDisplayCell
        {...props}
        {...cellProps}
        displayField={displayField}
      />
    ) : null;
};
