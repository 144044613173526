export const removeAllTabIndicators = () => {
  document.querySelectorAll('.tabInvalidIndicator').forEach((el) => {
    el.remove();
  });
};

export const toggleTabsRequiredIndicator = () => {
  return document.querySelectorAll('.with-error').forEach((el) => {
    const tab = el.closest('div[role=tabpanel]');
    const controllerTab = tab?.getAttribute('aria-labelledby');

    if (controllerTab) {
      const targetTab = document.querySelector(`#${controllerTab}`);
      if (targetTab && !targetTab.querySelector('span')) {
        const errorIndicator = document.createElement('span');
        errorIndicator.className = 'tabInvalidIndicator';
        targetTab.prepend(errorIndicator);
      }
    }
  });
};
