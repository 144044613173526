import EditIcon from '@mui/icons-material/Edit';
import { makeStyles } from '@mui/styles';
import { PageCard, PageCardRow, PageCardSection } from 'Components/Display';
import { TextIconButton } from 'Components/Form';
import { SecureComponent } from 'Components/Security';
import { FormProvider, useEntity } from 'Context';
import { Permission } from 'Models/Templates/Permission/Permission';
import React, { Fragment } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem, Col, Row } from 'reactstrap';
import { buildRoute, formatDateTimeFromDotNetFormat } from 'Utilities';
import {
  addEditRoute,
  AddEditSubRoute,
  ClaimantsSubRoute,
  ClaimsSubRoute,
  EntitiesSubRoute,
  entitiesSubRouteName,
  ROUTES,
} from '../../Navigation';

export const ViewClaimBreadcrumbs: React.FC = () => {
  const entity = useEntity();

  let claimantEndpoint = buildRoute(
    ROUTES.CLAIMANT,
    `${entity.claimantId}`,
    ClaimantsSubRoute.View
  );
  let claimEndpoint = buildRoute(
    ROUTES.CLAIM,
    `${entity.claimId}`,
    ClaimsSubRoute.View
  );

  let claimantName =
    entity.claimant != undefined
      ? `${entity.claimant.lastName}, ${entity.claimant.firstName}`
      : '';

  return (
    <Fragment>
      <Breadcrumb tag="nav" listTag="div">
        <BreadcrumbItem>
          <Link to={entitiesSubRouteName(EntitiesSubRoute.Claimants)}>
            Claimants
          </Link>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <Link to={claimantEndpoint}>{claimantName}</Link>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <Link to={claimEndpoint}>{entity.claimNumber}</Link>
        </BreadcrumbItem>
      </Breadcrumb>
    </Fragment>
  );
};

export const ViewClaim: React.FC = () => {
  const entity = useEntity();
  let clientName = entity.client != undefined ? entity.client.name : '';

  const useStyles = makeStyles({
    root: {
      minWidth: 275,
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
  });

  const classes = useStyles();

  let insuranceCarrierAddress;

  if (entity.insuranceCarrierAddress) {
    insuranceCarrierAddress = (
      <Fragment>
        {entity.insuranceCarrierAddress.fullAddressLine}
        {', '}
        {entity.insuranceCarrierAddress.city}
        {', '}
        {entity.insuranceCarrierAddress?.state?.value}
      </Fragment>
    );
  }
  const history = useHistory();
  const editClaimant = () => {
    history.push(addEditRoute(AddEditSubRoute.Claimant, entity.claimant?.id));
  };

  return (
    <Fragment>
      <FormProvider formModel={entity}>
        <PageCard className={classes.root}>
          <Row className="page-card-row border-bottom-0">
            <PageCardSection title="Information">
              <Row>
                <Col xs="12" lg="6">
                  <span className="field-label data-display">Client:</span>
                  <span className="field-value">{clientName}</span>
                </Col>
                <Col xs="12" lg="6">
                  <span className="field-label data-display">Claimant:</span>
                  <span className="field-value">
                    <Link
                      to={buildRoute(
                        ROUTES.CLAIMANT,
                        entity.claimant?.id,
                        ClaimantsSubRoute.View
                      )}
                    >
                      {entity.claimant?.firstName +
                        ' ' +
                        entity.claimant?.lastName}
                    </Link>
                    <SecureComponent permissions={[Permission.EditClaimant]}>
                      <TextIconButton
                        icon={EditIcon}
                        className="d-inline ml-3"
                        onClick={editClaimant}
                      />
                    </SecureComponent>
                  </span>
                </Col>
              </Row>
              <Row>
                <Col xs="12" lg="6">
                  <span className="field-label data-display">
                    Claim Number:
                  </span>
                  <span className="field-value">{entity.claimNumber}</span>
                </Col>
                <Col xs="12" lg="6">
                  <span className="field-label data-display">
                    Benefit State:
                  </span>
                  <span className="field-value">
                    {entity.benefitState ? entity.benefitState.value : ''}
                  </span>
                </Col>
              </Row>
              <Row>
                <Col>
                  <span className="field-label data-display">
                    Date of Injury:
                  </span>
                  <span className="field-value">
                    {entity.dateOfInjury
                      ? formatDateTimeFromDotNetFormat(
                          entity.dateOfInjury.toString(),
                          'MM/dd/yyyy'
                        )
                      : ''}
                  </span>
                </Col>
              </Row>
            </PageCardSection>
          </Row>
          <PageCardRow>
            <PageCardSection title="Insurance Carrier Information">
              <Row>
                <Col xs="12" lg="6">
                  <span className="field-label data-display">Name:</span>
                  <span className="field-value">
                    {entity.insuranceCarrierName}
                  </span>
                </Col>
                <Col xs="12" lg="6">
                  <span className="field-label data-display">Email:</span>
                  <span className="field-value">
                    {entity.insuranceCarrierEmail}
                  </span>
                </Col>
              </Row>

              <Row>
                <Col xs="12" lg="6">
                  <span className="field-label data-display">Phone:</span>
                  <span className="field-value">
                    {entity.insuranceCarrierPhone}
                  </span>
                </Col>
                <Col xs="12" lg="6">
                  <span className="field-label data-display">Fax:</span>
                  <span className="field-value">
                    {entity.insuranceCarrierFax}
                  </span>
                </Col>
              </Row>
              <Row>
                <Col xs="12" lg="6">
                  <span className="field-label data-display">Address:</span>
                  <span className="field-value">{insuranceCarrierAddress}</span>
                </Col>
                <Col xs="12" lg="6"></Col>
              </Row>
            </PageCardSection>
          </PageCardRow>
        </PageCard>
      </FormProvider>
    </Fragment>
  );
};
